import { FaRegPauseCircle } from "@react-icons/all-files/fa/FaRegPauseCircle";
import { FaRegPlayCircle } from "@react-icons/all-files/fa/FaRegPlayCircle";
import React, { useRef, useState } from 'react';
import ReactPlayer from "react-player";
import ReactStars from "react-stars";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";

// const reviewVideosUrls = [
//     "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/feedback_videos/feedback_1.mp4",
//     "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/feedback_videos/feedback_2.mp4",
//     "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/feedback_videos/feedback_3.mp4",
//     "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/feedback_videos/feedback_4.mp4",
//     "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/feedback_videos/feedback_5.mp4",
//     "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/feedback_videos/feedback_6.mp4",
// ]

const reviewVideosInfo = [
    {
        location: "Delhi NCR",
        url: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/feedback_videos/feedback_2.mp4"
    },
    {
        location: "Delhi NCR",
        url: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/feedback_videos/feedback_6.mp4"
    },
    {
        location: "Delhi NCR",
        url: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/feedback_videos/feedback_4.mp4"
    },
    {
        location: "Delhi NCR",
        url: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/feedback_videos/feedback_5.mp4"
    },
]


const ReviewVideos = ({ changeView }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const playerRef = useRef(null);

    return (
        <Swiper
            loop={true}
            speed={600}
            autoplay={{ delay: 1000, disableOnInteraction: false }}
            slidesPerView={changeView ? 3.5 : 1}
            spaceBetween={40}
            className="h-[450px] w-full py-1"
        >
            {reviewVideosInfo.map((info, index) => (
                <SwiperSlide key={index}>
                    <ReactPlayer
                        ref={playerRef}
                        className='w-full react-player'
                        url={info.url}
                        width='100%'
                        height='100%'
                        playing={isPlaying === info.url}
                    />
                    {/* {!isPlaying && (
                        <div
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                background: 'rgba(0, 0, 0, 0.5)',
                                padding: '10px',
                                borderRadius: '50%',
                                cursor: 'pointer'
                            }}
                            onClick={togglePlayPause}
                        >
                            Play
                        </div>
                    )} */}
                    {isPlaying !== info.url && (
                        <>
                            <div className="absolute bottom-0 left-0 p-2 text-white bg-black bg-opacity-25 rounded-lg flex w-full justify-between items-center">
                                <h2 className="text-lg font-semibold">{info.location}</h2>
                                <ReactStars
                                    count={5}
                                    value={5}
                                    size={25}
                                    color1={'#D3D3D3'}
                                    color2={'#ffd700'}
                                    edit={false}
                                    half={true} />
                            </div>
                            <button className='absolute top-[45%] left-[45%] text-white bg-black bg-opacity-25 rounded-full p-2' onClick={() => { setIsPlaying(info.url) }}><FaRegPlayCircle size={40} /></button>
                        </>
                    )}
                    {isPlaying === info.url && (
                        <button className='absolute bottom-0 left-0 text-white bg-black bg-opacity-25 w-full rounded-lg p-2' onClick={() => { setIsPlaying(false) }}><FaRegPauseCircle size={40} className='mx-auto' /></button>
                    )}
                </SwiperSlide>
            ))}
        </Swiper>
    )
}

export default ReviewVideos