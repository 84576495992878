import { BiArrowBack } from "@react-icons/all-files/bi/BiArrowBack";
import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ImageSlider from "../../ImageSlider";
//import dotenv from 'dotenv'

const images = [
  {
    id: 1,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-1.webp",
    alt: "Image 1",
  },
  {
    id: 2,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-2.webp",
    alt: "Image 2",
  },
  {
    id: 3,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-3.webp",
    alt: "Image 3",
  },
  {
    id: 4,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-4.webp",
    alt: "Image 4",
  },
  {
    id: 5,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-5.webp",
    alt: "Image 5",
  },
  {
    id: 6,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-6.webp",
    alt: "Image 6",
  },
  {
    id: 7,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-7.webp",
    alt: "Image 7",
  },
  {
    id: 8,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-8.webp",
    alt: "Image 8",
  },
];

const HomePageForm = (props) => {
  const navigate = useNavigate();
  const [selectedService, setSelectedService] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [couponValue, setCouponValue] = useState(0);
  const [couponErrorMsg, setCouponErrorMsg] = useState("");
  const [couponNotValid, setCouponNotValid] = useState(false);
  const [couponValid, setCouponValid] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [countdown, setCountdown] = useState(true);
  const [serviceDropdown, setServiceDropdown] = useState(false);
  const [selectedCarousel, setSelectedCarousel] = useState(1);
  const [timeSlot, setTimeSlot] = useState(1);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [serviceChoice, setServiceChoice] = useState("Bath and Brush");
  const [pet, setPet] = useState("Dog");
  const [petChoice, setpetChoice] = useState(1);
  const location = useLocation().pathname;
  const [overallChoice, setOverallChoice] = useState({
    choice: "",
    pet: "",
    date: "",
    time: "10AM-02PM",
    name: "",
    address: "",
    phone: "",
    latitude: 0,
    longitude: 0,
  });
  const [newNameValidation, setNewNameValidation] = useState(true);
  const [newPhoneValidation, setNewPhoneValidation] = useState(true);
  const [newAddressValidation, setNewAddressValidation] = useState(true);
  const [newAddressValidation1, setNewAddressValidation1] = useState(true);
  const [newDateValidation, setNewDateValidation] = useState(true);
  const [representationDate, setRepresentationDate] = useState("");
  const [representationDay, setRepresentationDay] = useState("");
  const [isVerified, setIsVerified] = useState(null);
  const [showEnterOtp, setShowEnterOtp] = useState(false);
  const [isWrongOtp, setIsWrongOtp] = useState(false);
  const [correctOTP, setCorrectOTP] = useState(true);
  const baseUrl =
    "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api";
  const [token, setToken] = useState("");
  // const [bookingId, setBookingId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api
  const newDateRef = useRef("");
  const newServiceRef = useRef("");
  const newTimeServiceRef = useRef("");
  const newNameRef = useRef("");
  const newAddressRef = useRef("");
  const newAddressRef1 = useRef("");
  const newPhoneRef = useRef("");
  const groomingOtpRef = useRef("");

  const [paymentData, setPaymentData] = useState({});
  const [flag, setFlag] = useState(false);
  // const { coords } = useGeolocated();
  const [address, setAddress] = useState("");
  const [startValue, setStartValue] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [startAddress, setStartAddress] = useState(false);
  const [lat, setLat] = useState(0);
  const [lon, setLon] = useState(0);
  const [nameValue, setnameValue] = useState(false);
  const [nameFill, setNameFill] = useState("");
  const [phoneValue, setPhoneValue] = useState(false);
  const [phoneFill, setPhoneFill] = useState(null);
  const [addressValue, setAddressValue] = useState(false);
  const [city, setCity] = useState("");
  const [adressFill, setAdressFill] = useState("");
  const [phone, setPhone] = useState("");
  const [breed, setBreed] = useState();
  const [price1, setPrice] = useState(799);
  let price = 0;
  var currentDate = new Date();
  var year = currentDate.getFullYear();
  var month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
  var day = ("0" + currentDate.getDate()).slice(-2);
  var formattedDate = year + "-" + month + "-" + day;
  function setSelectedServiceHandler(service) {
    setSelectedService(service);
    setServiceDropdown(false);
  }



  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  // useEffect(() => {
  //   // This effect will run whenever bookingId changes
  //   console.log("Updated Booking ID:", bookingId);
  // }, [bookingId]);

  async function displayRazorpay(
    serviceAmount,
    inputPhone,
    SelectedService,
    DateOfReg,
    InputAddress
  ) {
    setIsLoading(false);

    // console.log("display");
    // console.log(bookingId);

    const bookingId = localStorage.getItem("bookingId");
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // creating a new order
    const result = await axios.post(
      "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/generateGroomingRazorPayOrderId",
      {
        amount: serviceAmount,
      }
    );

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }

    // Getting the order details back
    const { amount, id: order_id, currency } = result.data;

    const options = {
      key: process.env.REACT_APP_KEY, // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: "Petmojo",
      description: "Pet Grooming Payment",
      //image: { logo },
      order_id: order_id,
      handler: async function (response) {
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };

        console.log("Grooming Page Function: ", data);

        const result = await axios.post(
          "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/generateGroomingRazorPaySuccess",
          data
        );
        //alert(result.data.msg);
        const Data = {
          ...result.data,
          SelectedService,
          DateOfReg,
          InputAddress,
        };

        const actualAmount = serviceAmount / 100;

        const dataToStore = {
          Date: DateOfReg,
          Address: InputAddress,
          Contact_No: inputPhone.toString(),
          Amount_Paid: actualAmount.toString(),
          Selected_Service: SelectedService,
          Order_id: order_id,
        };

        await axios
          .post(
            "https://api.apispreadsheets.com/data/hq3CiEAYcPfNXIJE/",
            dataToStore
          )
          .then((response) => {
            console.log(response);
            console.log("The grooming payment has been completed!");
          })
          .catch((err) => {
            console.log(err);
            console.log(
              "There was some error in submitting the grooming payment to backend!"
            );
          });

        const data2 = {
          bookingId: bookingId,
          transactionId: order_id,
          amount: actualAmount,
          type: "User",
        };

        data2["isFromWebsite"] = true;
        // {
        //     "bookingId" : "63d554eb156e83edfd5d1144",
        //     "transactionId" : "order_JCB9CaLSarOaTj",
        //     "amount":799,
        //     "type": "User"
        // }

        await axios
          .patch(
            "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/service/postGroomingPayment",
            data2,
            {
              "Content-Type": "application/json",
              headers: {
                Authorization:
                  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjYzZWY5ZWZkNTc1MGY5Y2Q4MmVmYmZmNyJ9.QOz1wBekeflAqD2ktWjDQLubM4snuMMDFV0Lk97z5UY",
              },
            }
          )
          .then((response) => {
            console.log(response);
            console.log(
              "The grooming payment has been completed and info has been saved in the database!"
            );
          })
          .catch((err) => {
            console.log(err);
            console.log(
              "There was some error in saving the grooming payment to database!"
            );
          });

        setPaymentData(Data);
        setFlag(true);
      },
      // prefill: {
      //     name: "Petmojo",
      //     email: "customercare@petmojo.in",
      //     contact: "+91 9009004473",
      // },
      notes: {
        address: "Petmojo",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    //console.log(paymentObject);
    paymentObject.open();
  }
  const [showDialog, setShowDialog] = useState(false);
  const checkPhone = (phoneString) => {
    if (
      phoneString.length > 10 &&
      (phoneString.startsWith("+91") || phoneString.startsWith("91"))
    ) {
      phoneString = phoneString.slice(-10);
      setPhone(phoneString);
      setShowDialog(true);
    }
    return phoneString;
  };
  const groomingFormDesktopHandler1 = () => {
    if (serviceChoice === "") {
      console.log("Cant move to next card!");
    }
    //    if(nameFill==""){
    //     setNewNameValidation(false);
    //   }
    //  if(phoneFill==null || phoneFill==""){
    //     setNewPhoneValidation(false);
    //   }
    else {
      setOverallChoice({
        pet: pet,
        choice: serviceChoice,
        name: "",
        address: "",
        phone: "",
        time: "",
        date: "",
        // latitude:lat,
        // longitude:lon
      });
      setSelectedCarousel(2);
      console.log(overallChoice);
    }
  };

  const groomingFormDesktopHandler2 = () => {
    overallChoice.date = newDateRef.current.value;
    if (
      overallChoice.date == "" ||
      nameFill == "" ||
      phoneFill == "" ||
      phoneFill == null
    ) {
      console.log("Cant move to next card!");
      console.log(overallChoice);
    }
    if (nameFill == "") {
      setNewNameValidation(false);
    }
    if (phoneFill == null || phoneFill == "") {
      setNewPhoneValidation(false);
    } else {
      let time = "";
      if (timeSlot === 1) {
        time = "10AM-02PM";
      } else if (timeSlot === 2) {
        time = "02PM-06PM";
      } else if (timeSlot === 3) {
        time = "06PM-10PM";
      }

      setSelectedDate(newDateRef.current.value);
      setSelectedTime(time);
      //overallChoice.time

      setOverallChoice({
        choice: serviceChoice,
        pet: petChoice === 1 ? "Dog" : "Cat",
        date: newDateRef.current.value,
        time: time,
        name: "",
        address: "",
        phone: "",
        // latitude:lat,
        // longitude:lon
      });
      setSelectedCarousel(3);
      console.log(overallChoice);
    }
  };

  const getmobileOtp = useCallback(async () => {
    try {
      let phoneString = checkPhone(newPhoneRef.current.value.toString());
      const res = await axios.post(`${baseUrl}/auth/sendMobileOTP`, {
        phoneNumber: phoneString,
      });
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  }, []);
  const changeName = () => {
    document.getElementById("send-otp-desk").innerHTML = "Resend OTP";
  };
  const verifyMobileOTP = useCallback(async () => {
    try {
      let phoneString = checkPhone(newPhoneRef.current.value.toString());
      const res = await axios.post(
        "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/auth/verifyMobileOTP",
        {
          otp: groomingOtpRef.current.value.toString(),
          phoneNumber: phoneString,
          type: "User",
        }
      );
      console.log(res.status);
      console.log(res.data);

      if (res.status === 200) {
        setIsVerified(true);
        setToken(res.data.token);
        setCountdown(false);
        setCorrectOTP(true);
      }
    } catch (error) {
      console.log(error.response.status);
      console.log(error);
      if (error.response.status === 400) {
        setIsVerified(false);
        setIsWrongOtp(true);
        setCorrectOTP(false);
      }
    }
  }, []);
  const indicateAlert = () => {
    if (!correctOTP) {
      document.getElementById("send-otp-desk").style.borderColor = "red";
    }
  };
  const backArrow = () => {
    setSelectedCarousel(selectedCarousel - 1);
  };
  const decrementSeconds = () => {
    // if(seconds===0){
    //     document.getElementById("resend-countdown").style.display="none"
    // }
    // else{
    setTimeout(() => {
      if (seconds == 0) {
        document.getElementById("countdown-seconds").style.display = "none";
      } else {
        setSeconds(seconds - 1);
      }
    }, 1000);
    // }
  };
  const paymentGatewayHit = async (data) => {
    const path = "grooming";
    const inputPhone = data.ContactNo;
    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${date.getMonth() + 1
      }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;
    const inputAddress = newAddressRef.current.value;

    var amount = 0;

    if (data.Services === "Bath and Brush") {
      //kartik
      amount = 799;
    } else if (data.Services === "Bath & Brush(with tick treatment)") {
      amount = 1199;
    } else if (data.Services === "Haircut and Styling") {
      amount = 1249;
    } else if (data.Services === "Bath, Full haircut & Styling") {
      amount = 1649;
    } else if (data.Services === "Massage, Bath, Full haircut & Styling") {
      amount = 1949;
    }

    // setPrice(amount);
    price = amount;
    data["Amount"] = amount;

    // axios
    //   .post(
    //     "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/saveGroomingData",
    //     data
    //   )
    //   .then((response) => {
    //     console.log(response);
    //     console.log("The grooming form is being submitted...");
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     console.log("There was some error in submitting the grooming form...");
    //   });

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: `${path} Registration Fired From Desktop Service Page Form`,
    });

    displayRazorpay(
      amount - couponValue,
      inputPhone,
      selectedService,
      dateOfReg,
      inputAddress
    );

    console.log(data);
  };

  const paymentInitiationHandler = async () => {
    const phone = phoneFill;
    const phoneString = checkPhone(phone.toString());
    const name = nameFill;
    const address = newAddressRef.current.value;

    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${date.getMonth() + 1
      }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;

    const enteredYear = parseInt(selectedDate.substring(0, 4));
    const enteredMonth = parseInt(selectedDate.substring(5, 7));
    const enteredDay = parseInt(selectedDate.substring(8, 10));

    if (phoneString.length !== 10 || name === "" || address === "") {
      console.log("cannot proceed further!");
    } else {
      // if(enteredMonth === date.getMonth()+1){
      //     if(enteredDay <= date.getDate()){
      //       setNewDateValidation(false);
      //       return;
      //     }
      // }

      setIsLoading(true);

      setOverallChoice({
        choice: serviceChoice,
        name: name,
        address: address,
        phone: phone,
        date: selectedDate,
        time: selectedTime,
        pet: petChoice === 1 ? "Dog" : "Cat",
        latitude: lat,
        longitude: lon,
      });

      let UTM_Source,
        UTM_Medium,
        UTM_Campaign = "";

      const urlParams = new URLSearchParams(window.location.search);
      UTM_Source = urlParams.get("utm_source");
      UTM_Medium = urlParams.get("utm_medium");
      UTM_Campaign = urlParams.get("utm_campaign");

      const data = {
        ContactNo: phone,
        Services: serviceChoice,
        Date: selectedDate,
        Time: selectedTime,
        Breed: breed?.label,
        Email: "",
        FormType: "Direct Form",
        DateOfRegistration: dateOfReg,
        TimeOfRegistration: timeOfReg,
        Address: address,
        Name: name,
        Pet: pet,
        latitude: lat,
        longitude: lon,
        UTM_Source: UTM_Source || "NA",
        UTM_Medium: UTM_Medium || "NA",
        UTM_Campaign: UTM_Campaign || "NA",
      };

      data["paymentStatus"] = 2;
      // ContactNo: inputPhone,
      // Services: selectedService,
      // Date: inputDate,
      // Time: inputTime,
      // Address: inputAddress,
      // DateOfRegistration: dateOfReg,
      // TimeOfRegistration: timeOfReg,
      // Email: inputEmail,
      // FormType: "Direct Form"

      var amount = 0;

      if (serviceChoice === "Bath and Brush") {
        //kartik
        amount = 799;
      } else if (serviceChoice === "Bath & Brush(with tick treatment)") {
        amount = 1199;
      } else if (serviceChoice === "Haircut and Styling") {
        amount = 1249;
      } else if (serviceChoice === "Bath, Full haircut & Styling") {
        amount = 1649;
      } else if (serviceChoice === "Massage, Bath, Full haircut & Styling") {
        amount = 1949;
      }
      // setPrice(amount);

      // let UTM_Source, UTM_Medium, UTM_Campaign = "";

      // const urlParams = new URLSearchParams(window.location.search);
      // UTM_Source = urlParams.get('utm_source');
      // UTM_Medium = urlParams.get('utm_medium');
      // UTM_Campaign = urlParams.get('utm_campaign')

      price = amount;
      const data2 = {
        fullName: name,
        address: address,
        phone: phone,
        Breed: breed?.label,
        startDate: selectedDate,
        sessionTime: selectedTime,
        latitude: lat,
        longitude: lon,
        package: {
          description: serviceChoice,
          price: amount - couponValue,
          frequency: 1,
        },
        type: "User",
        UTM_Source: UTM_Source || "",
        UTM_Medium: UTM_Medium || "",
        UTM_Campaign: UTM_Campaign || "",
      };

      data2["Amount"] = amount;
      await axios
        .post(
          "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/websiteDogGroomingBookingService",
          data2,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: token,
            },
          }
        )
        .then((response) => {
          console.log(response);
          console.log("checking");

          // setBookingId(response.data.bookingId);
          localStorage.setItem("bookingId", response.data.bookingId);
          console.log("The grooming data is submitted to the database.");
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          console.log("There was some error in saving the data in database.");
        });
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: `Grooming Registration Fired From Desktop Service Page Form`,
      });
      paymentGatewayHit(data);
      return;
    }

    if (phoneString.length !== 10) {
      setNewPhoneValidation(false);
    }
    if (name === "") {
      setNewNameValidation(false);
    }
    if (address === "") {
      setNewAddressValidation(false);
    }
  };
  // const displayLocation=()=>{
  //     console.log("Latitude:",lat);
  //     console.log("Longitude:",lon);
  //     console.log(address);
  //     // var ad=toString(lat);
  //     // console.log(ad)
  // }

  const bookNowPayLaterHandler = async () => {
    const phone = phoneFill;
    const phoneString = checkPhone(phone.toString());
    const name = nameFill;
    const address = newAddressRef.current.value;

    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${date.getMonth() + 1
      }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;

    const enteredYear = parseInt(selectedDate.substring(0, 4));
    const enteredMonth = parseInt(selectedDate.substring(5, 7));
    const enteredDay = parseInt(selectedDate.substring(8, 10));

    if (phoneString.length !== 10 || name === "" || address === "") {
      console.log("cannot proceed further!");
    } else {
      // if(enteredMonth === date.getMonth()+1){
      //     if(enteredDay <= date.getDate()){
      //       setNewDateValidation(false);
      //       return;
      //     }
      // }

      setIsLoading(true);

      setOverallChoice({
        choice: serviceChoice,
        name: name,
        address: address,
        phone: phoneString,
        date: selectedDate,
        time: selectedTime,
        pet: petChoice === 1 ? "Dog" : "Cat",
        latitude: lat,
        longitude: lon,
      });

      let UTM_Source,
        UTM_Medium,
        UTM_Campaign = "";

      const urlParams = new URLSearchParams(window.location.search);
      UTM_Source = urlParams.get("utm_source");
      UTM_Medium = urlParams.get("utm_medium");
      UTM_Campaign = urlParams.get("utm_campaign");

      console.log(breed);

      const data = {
        ContactNo: phoneString,
        Services: serviceChoice,
        Breed: breed?.label,
        Date: selectedDate,
        Time: selectedTime,
        Email: "",
        FormType: "Direct Form",
        DateOfRegistration: dateOfReg,
        TimeOfRegistration: timeOfReg,
        Address: address,
        Name: name,
        latitude: lat,
        longitude: lon,
        UTM_Source: UTM_Source || "",
        UTM_Medium: UTM_Medium || "",
        UTM_Campaign: UTM_Campaign || "",
        // paymentStatus:2,
      };

      var amount = 0;

      if (serviceChoice === "Bath and Brush") {
        //kartik
        amount = 799;
      } else if (serviceChoice === "Bath & Brush(with tick treatment)") {
        amount = 1199;
      } else if (serviceChoice === "Haircut and Styling") {
        amount = 1249;
      } else if (serviceChoice === "Bath, Full haircut & Styling") {
        amount = 1649;
      } else if (serviceChoice === "Massage, Bath, Full haircut & Styling") {
        amount = 1949;
      }
      // setPrice(amount);
      price = amount;
      data["Amount"] = amount;
      data["paymentStatus"] = 2;
      axios
        .post(
          "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/saveGroomingData",
          data
        )
        .then((response) => {
          console.log(response);
          console.log("The grooming form is being submitted...");
        })
        .catch((err) => {
          console.log(err);
          console.log(
            "There was some error in submitting the grooming form..."
          );
        });

      // let UTM_Source, UTM_Medium, UTM_Campaign = "";

      // const urlParams = new URLSearchParams(window.location.search);
      // UTM_Source = urlParams.get('utm_source');
      // UTM_Medium = urlParams.get('utm_medium');
      // UTM_Campaign = urlParams.get('utm_campaign')
      const data2 = {
        fullName: name,
        address: address,
        Breed: breed?.label,
        phone: phoneString,
        startDate: selectedDate,
        sessionTime: selectedTime,
        latitude: lat,
        longitude: lon,
        package: {
          description: serviceChoice,
          amount: amount - couponValue,
          frequency: 1,
        },
        type: "User",
        isCouponCodeUsed: couponValue === 0 ? false : true,
        couponCode: couponValue !== 0 ? couponCode : "",
        UTM_Source: UTM_Source || "",
        UTM_Medium: UTM_Medium || "",
        UTM_Campaign: UTM_Campaign || "",
      };

      console.log(data2);

      data2["Amount"] = amount;
      await axios
        .post(
          "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/websiteDogGroomingBookingService",
          data2,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: token,
            },
          }
        )
        .then((response) => {
          console.log(response);
          console.log("The grooming data is submitted to the database.");
        })
        .catch((err) => {
          console.log(err);
          console.log("There was some error in saving the data in database.");
        });

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: `Grooming Registration Fired From Desktop Service Page Form`,
      });

      navigate("/thankPageGroom");
    }

    if (phoneString.length !== 10) {
      setNewPhoneValidation(false);
    }
    if (name === "") {
      setNewNameValidation(false);
    }
    if (address === "") {
      setNewAddressValidation(false);
    }
  };

  useEffect(() => {
    if (flag) navigate("/ordersummary", { state: paymentData });
  }, [paymentData, flag]);

  const applyMOJO = async () => {
    applyCoupon("MOJO5");
    setCouponCode("MOJO5");
  }
  const applyCoupon = async (cd) => {
    await axios
      .post(
        "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/websiteVerifyCoupon",
        {
          name: cd ?? couponCode,
          serviceType: 2,
          package: serviceChoice,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: token,
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response.data.value) {
          setCouponValue(response.data.value);
          setCouponErrorMsg("");
          setCouponNotValid(false);
          setCouponValid(true);
        } else {
          setCouponValid(false);
          setCouponNotValid(true);
          setCouponErrorMsg(response.data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const params = {};
    for (let param of searchParams.entries()) {
      params[param[0]] = param[1];
    }
    if (
      params["amp;utm_campaign"] === "Grooming 499" &&
      params["amp;utm_medium"] === "Whatsapp Campaign" &&
      params["utm_source"] === "Whatsapp"
    ) {
      setCouponCode("WLCMBK");
      applyCoupon("WLCMBK");
    }
  }, []);

  const date = new Date();

  // declaration of refs
  const phoneRef = useRef("");
  const emailRef = useRef("");
  const dateRef = useRef("");
  const addressRef = useRef("");
  const timeRef = useRef("");
  const [addressFill, setAddressFill] = useState(false);

  useEffect(() => {
    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${date.getMonth() + 1
      }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;

    var MonthName;
    if (date.getMonth() + 1 == 1) {
      MonthName = "January";
    } else if (date.getMonth() + 1 == 2) {
      MonthName = "February";
    } else if (date.getMonth() + 1 == 3) {
      MonthName = "March";
    } else if (date.getMonth() + 1 == 4) {
      MonthName = "April";
    } else if (date.getMonth() + 1 == 5) {
      MonthName = "May";
    } else if (date.getMonth() + 1 == 6) {
      MonthName = "June";
    } else if (date.getMonth() + 1 == 7) {
      MonthName = "July";
    } else if (date.getMonth() + 1 == 8) {
      MonthName = "August";
    } else if (date.getMonth() + 1 == 9) {
      MonthName = "September";
    } else if (date.getMonth() + 1 == 10) {
      MonthName = "October";
    } else if (date.getMonth() + 1 == 11) {
      MonthName = "November";
    } else if (date.getMonth() + 1 == 12) {
      MonthName = "December";
    }

    var DayName;
    if (date.getDay() + 1 == 1) DayName = "Sunday";
    else if (date.getDay() + 1 == 2) DayName = "Monday";
    else if (date.getDay() + 1 == 3) DayName = "Tuesday";
    else if (date.getDay() + 1 == 4) DayName = "Wednesday";
    else if (date.getDay() + 1 == 5) DayName = "Thursday";
    else if (date.getDay() + 1 == 6) DayName = "Friday";
    else if (date.getDay() + 1 == 7) DayName = "Saturday";

    const repDate =
      MonthName + " " + date.getDate() + ", " + date.getFullYear() + " | ";
    const repDay = DayName;
    setRepresentationDate(repDate);
    setRepresentationDay(repDay);
  }, []);
  useEffect(() => {
    indicateAlert();
  }, [correctOTP]);

  useEffect(() => {
    decrementSeconds();
  }, [seconds]);

  const [loading, setLoading] = useState(false);
  const handleLocationAccess = () => {
    setLoading(true);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setLat(latitude);
          setLon(longitude);

          fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCeoWpdd6YOVu3wEqqA0qpY6P3B5rUumm8`
          )
            .then((response) => response.json())
            .then((data) => {
              console.log(data);
              setAddress(data.results[0].formatted_address);
              setLoading(false);
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
        },
        (error) => {
          console.error(error);
          setLoading(false);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      setLoading(false);
    }
  };

  // const getLocation=()=>{
  //     if (coords) {
  //         const { latitude, longitude } = coords;
  //         const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCeoWpdd6YOVu3wEqqA0qpY6P3B5rUumm8`;

  //         fetch(url)
  //           .then(response => response.json())
  //           .then(data => {
  //             const { results } = data;
  //             if (results && results.length > 0) {
  //               setAddress(results[0].formatted_address);
  //               console.log(address)
  //             }
  //           })
  //           .catch(error => {
  //             console.log('Error:', error);
  //           });
  //       }
  //       else{
  //         console.log("Location Access denied!")
  //       }
  // }
  // useEffect(() => {
  //   if (coords) {
  //     const { latitude, longitude } = coords;
  //     const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCeoWpdd6YOVu3wEqqA0qpY6P3B5rUumm8`;

  //     fetch(url)
  //       .then(response => response.json())
  //       .then(data => {
  //         const { results } = data;
  //         if (results && results.length > 0) {
  //           setAddress(results[0].formatted_address);
  //           console.log(address)
  //         }
  //       })
  //       .catch(error => {
  //         console.log('Error:', error);
  //       });
  //   }
  // }, [coords]);

  return (
    <div
      className="home-page-desktop-intro-form-container2"
      style={{
        width: location == "/" ? "100%" : "",
        marginTop: location == "/" ? "0px" : "",
        border: location == "/" ? "0px" : "",
      }}
    >
      {location == "/grooming" && (
        <div className="walking-page-desktop-intro-form-header">
          <div style={{ display: "flex", alignItems: "start" }}>
            <div
              style={{
                width: selectedCarousel === 1 ? "0%" : "8%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <BiArrowBack
                style={{ display: selectedCarousel === 1 ? "none" : "block" }}
                onClick={backArrow}
              />
            </div>
            <div style={{ width: selectedCarousel === 1 ? "100%" : "90%" }}>
              Book Your Grooming Session Here!{" "}
              <span style={{ color: "#FF5E95" }}>
                FLAT 50% OFF on all Grooming Packages
              </span>
            </div>
          </div>
        </div>
      )}

      {selectedCarousel == 1 ? (
        <div className="grooming-new-form-section1-main-container">
          <div className="grooming-new-form-section1-main">
            <div
              className="grooming-new-form-section1-left"
              style={{ width: "50%" }}
            >
              <div className="">
                <span style={{ color: "#5E5E5E", fontWeight: "700" }}>
                  Type of Pet
                </span>
                <div
                  style={{
                    display: "flex",
                    padding: "10px",
                    width: "100%",
                    gap: "10%",
                  }}
                  className=""
                >
                  <div
                    className="option"
                    id="one"
                    style={{ display: "flex" }}
                    onClick={() => {
                      setpetChoice(1);
                    }}
                  >
                    <div className="heading" style={{ marginBottom: "5px" }}>
                      <div
                        className="left"
                        onClick={() => {
                          // console.log("h")
                          setPet("Dog");
                          setOverallChoice({ pet: "Dog" });
                        }}
                      >
                        <div className="selection-circle">
                          {petChoice == 1 && <div className="dot"></div>}
                        </div>
                        <div style={{ display: "flex", gap: "2px" }}>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Dog.png"
                            alt=""
                            style={{ width: "25px", height: "25px" }}
                          />
                          <span style={{ fontWeight: "500" }}>
                            Dog{" "}
                            <small style={{ fontSize: "13px" }}>
                              {" "}
                              <br />
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="option"
                    id="two"
                    style={{ display: "flex" }}
                    onClick={() => {
                      setpetChoice(2);
                    }}
                  >
                    <div className="heading" style={{ marginBottom: "5px" }}>
                      <div
                        className="left"
                        onClick={() => {
                          // console.log("g");

                          setPet("Cat");
                          setOverallChoice({ pet: "Cat" });
                        }}
                      >
                        <div className="selection-circle">
                          {petChoice == 2 && <div className="dot"></div>}
                        </div>
                        <div style={{ display: "flex", gap: "2px" }}>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Cat.png"
                            alt=""
                            style={{ width: "25px", height: "25px" }}
                          />
                          <span style={{ fontWeight: "500" }}>
                            Cat{" "}
                            <small style={{ fontSize: "13px" }}>
                              {" "}
                              <br />
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grooming-new-form-section1-left-head">
                Select service of your choice
              </div>
              <div
                className="grooming-new-form-section1-left-container"
                style={{ fontSize: "12px", display: "flex" }}
              >
                <div
                  className="service-list"
                  style={{ paddingRight: "1rem", fontSize: "0.8rem" }}
                >
                  <div
                    className={
                      serviceChoice === "Bath and Brush"
                        ? "grooming-new-form-section1-left-choice-container-active"
                        : "grooming-new-form-section1-left-choice-container"
                    }
                  >
                    <div
                      className="grooming-new-form-section1-left-choice-name-container"
                      onClick={() => setServiceChoice("Bath and Brush")}
                    >
                      <div className="selection-circle">
                        {serviceChoice == "Bath and Brush" && (
                          <div className="dot"></div>
                        )}
                      </div>
                      {/* <input onClick={() => setServiceChoice("BATH & BRUSH")} type="radio" id="bath&brush" className='radio-grooming-form-desktop'></input> */}
                      <div
                        onClick={() => (
                          setServiceChoice("Bath and Brush"),
                          setPrice(799),
                          setOverallChoice({ choice: "Bath and Brush" })
                        )}
                        htmlFor="bath&brush"
                      >
                        <span className="grooming-new-form-section1-left-choice-name">
                          Bath and Brush
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      serviceChoice === "Bath & Brush(with tick treatment)"
                        ? "grooming-new-form-section1-left-choice-container-active"
                        : "grooming-new-form-section1-left-choice-container"
                    }
                  >
                    <div
                      className="grooming-new-form-section1-left-choice-name-container"
                      onClick={() =>
                        setServiceChoice("Bath & Brush(with tick treatment)")
                      }
                    >
                      <div className="selection-circle">
                        {serviceChoice ==
                          "Bath & Brush(with tick treatment)" && (
                            <div className="dot"></div>
                          )}
                      </div>
                      {/* <input onClick={() => setServiceChoice("Bath & Brush(with tick treatment)")} type="radio" id="bath&brushTicks" className='radio-grooming-form-desktop'></input> */}
                      <div
                        onClick={() => (
                          setServiceChoice(
                            "Bath & Brush(with tick treatment)"
                          ),
                          setOverallChoice({
                            choice: "Bath & Brush(with tick treatment)",
                          }),
                          setPrice(1199)
                        )}
                        htmlFor="bath&brushTicks"
                      >
                        <span className="grooming-new-form-section1-left-choice-name">
                          Bath & Brush(with tick treatment)
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      serviceChoice === "Haircut and Styling"
                        ? "grooming-new-form-section1-left-choice-container-active"
                        : "grooming-new-form-section1-left-choice-container"
                    }
                  >
                    <div
                      className="grooming-new-form-section1-left-choice-name-container"
                      onClick={() => setServiceChoice("Haircut and Styling")}
                    >
                      <div className="selection-circle">
                        {serviceChoice == "Haircut and Styling" && (
                          <div className="dot"></div>
                        )}
                      </div>
                      {/* <input onClick={() => setServiceChoice("Haircut and Styling")} type="radio" id="haircutstyling" className='radio-grooming-form-desktop'></input> */}
                      <div
                        onClick={() => (
                          setServiceChoice("Haircut and Styling"),
                          setOverallChoice({ choice: "Haircut and Styling" }),
                          setPrice(1249)
                        )}
                        htmlFor="haircutstyling"
                      >
                        <span className="grooming-new-form-section1-left-choice-name">
                          Haircut and Styling
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      serviceChoice === "Bath, Full haircut & Styling"
                        ? "grooming-new-form-section1-left-choice-container-active"
                        : "grooming-new-form-section1-left-choice-container"
                    }
                  >
                    <div
                      className="grooming-new-form-section1-left-choice-name-container"
                      onClick={() => setServiceChoice("Bath, Full haircut & Styling")}
                    >
                      <div className="selection-circle">
                        {serviceChoice == "Bath, Full haircut & Styling" && (
                          <div className="dot"></div>
                        )}
                      </div>
                      {/* <input onClick={() => setServiceChoice("Bath, Full haircut & Styling")} type="radio" id="bathhaircut" className='radio-grooming-form-desktop'></input> */}
                      <div
                        onClick={() => (
                          setServiceChoice("Bath, Full haircut & Styling"),
                          setOverallChoice({ choice: "Bath, Full haircut & Styling" }),
                          setPrice(1649)
                        )}
                        htmlFor="bathhaircut"
                      >
                        <span className="grooming-new-form-section1-left-choice-name">
                          Bath, Full haircut & Styling
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      serviceChoice ===
                        "Massage, Bath, Full haircut & Styling"
                        ? "grooming-new-form-section1-left-choice-container-active"
                        : "grooming-new-form-section1-left-choice-container"
                    }
                  >
                    <div
                      className="grooming-new-form-section1-left-choice-name-container"
                      onClick={() =>
                        setServiceChoice(
                          "Massage, Bath, Full haircut & Styling"
                        )
                      }
                    >
                      <div className="selection-circle">
                        {serviceChoice ==
                          "Massage, Bath, Full haircut & Styling" && (
                            <div className="dot"></div>
                          )}
                      </div>
                      {/* <input onClick={() => setServiceChoice("Bath, Full haircut & Styling")} type="radio" id="bathhaircut" className='radio-grooming-form-desktop'></input> */}
                      <div
                        onClick={() => (
                          setServiceChoice(
                            "Massage, Bath, Full haircut & Styling"
                          ),
                          setOverallChoice({
                            choice: "Massage, Bath, Full haircut & Styling",
                          }),
                          setPrice(1949)
                        )}
                        htmlFor="bathhaircut"
                      >
                        <span
                          className="grooming-new-form-section1-left-choice-name"
                          style={{ paddingRight: "5px" }}
                        >
                          MASSAGE, BATH, FULL Haircut and Styling
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="price-List" style={{ fontSize: "0.8rem" }}>
                  <div
                    className="grooming-new-form-section1-left-choice-price"
                    style={{ padding: "5px 0px" }}
                  >
                    <span style={{ textDecoration: "line-through" }}>
                      Rs. 1598
                    </span>{" "}
                    <span style={{ fontWeight: "bold", paddingLeft: "0.4rem" }}>
                      ₹799/- Only
                    </span>
                  </div>

                  <div
                    className="grooming-new-form-section1-left-choice-price"
                    style={{ padding: "5px 0px" }}
                  >
                    <span style={{ textDecoration: "line-through" }}>
                      Rs. 2398
                    </span>{" "}
                    <span style={{ fontWeight: "bold", paddingLeft: "0.4rem" }}>
                      ₹1199/- Only
                    </span>
                  </div>

                  <div
                    className="grooming-new-form-section1-left-choice-price"
                    style={{ padding: "5px 0px" }}
                  >
                    <span style={{ textDecoration: "line-through" }}>
                      Rs. 2498
                    </span>{" "}
                    <span style={{ fontWeight: "bold", paddingLeft: "0.4rem" }}>
                      ₹1249/- Only
                    </span>
                  </div>

                  <div
                    className="grooming-new-form-section1-left-choice-price"
                    style={{ padding: "5px 0px" }}
                  >
                    <span style={{ textDecoration: "line-through" }}>
                      Rs. 3298
                    </span>{" "}
                    <span style={{ fontWeight: "bold", paddingLeft: "0.4rem" }}>
                      ₹1649/- Only
                    </span>
                  </div>

                  <div
                    className="grooming-new-form-section1-left-choice-price"
                    style={{ padding: "5px 0px" }}
                  >
                    <span style={{ textDecoration: "line-through" }}>
                      Rs. 3898
                    </span>{" "}
                    <span style={{ fontWeight: "bold", paddingLeft: "0.4rem" }}>
                      ₹1949/- Only
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="grooming-new-form-section1-right">
              <div style={{ width: "100%" }}>
                <ImageSlider images={images} />
              </div>


              <div
                className="grooming-new-form-section1-right-next-button-container"
                style={{ marginTop: "40px" }}
              >
                <div
                  onClick={() => {
                    groomingFormDesktopHandler1();
                    // displayLocation();
                  }}
                  className="grooming-new-form-section1-right-next-button"
                  style={{ width: "100%", color: "black" }}
                >
                  Next
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <div
              className="grooming-new-form-section1-right-include-text"
              style={{ width: "100%", textAlign: "start" }}
            >
              Includes
            </div>
            {serviceChoice === "Bath and Brush" ? (
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "5px",
                }}
              >
                {/* Includes Bath, Shampoo, Blow Dry, Brushing, Nail Trim, Ear Cleaning or Flushing, Scissoring/Trimming Feet Hair, Teeth Brushing */}
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Includes Bath</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Shampoo</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Blow Dry</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Brushing</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Nail Trim</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>
                    Ear Cleaning or Flushing
                  </div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>
                    Scissoring/Trimming Feet Hair
                  </div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Teeth Brushing</div>
                </div>
              </div>
            ) : null}
            {serviceChoice === "Bath & Brush(with tick treatment)" ? (
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "5px",
                }}
              >
                {/* Includes Bath, Nail Trim, Brushing, Shampoo(vet approved), Scrubbing, Coat Conditioner, Ear Cleaning or Flushing, Blow Dry, Plus Tick Treatment */}
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Includes Bath</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Nail Trim</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Brushing</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Shampoo(vet approved)</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Scrubbing</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Coat Conditioner</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>
                    Ear Cleaning or Flushing
                  </div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Blow Dry</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Plus Tick Treatment</div>
                </div>
              </div>
            ) : null}
            {serviceChoice === "Haircut and Styling" ? (
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "5px",
                }}
              >
                {/* Includes Full Haircut, Breed Specific Haircut, Fanny Hair Trim, Paw Hair Trim, Tail Hair Shaping, Brushing */}
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Includes Full Haircut</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>
                    {" "}
                    Breed Specific Haircut
                  </div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Fanny Hair Trim</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Paw Hair Trim</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Tail Hair Shaping</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Brushing</div>
                </div>
              </div>
            ) : null}
            {serviceChoice === "Bath, Full haircut & Styling" ? (
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "5px",
                }}
              >
                {/* Includes Bath, Nail Trim, Brushing, Shampoo, Scrubbing, Coat Conditioner, Ear Cleaning or Flushing, Blow Dry, Full Haircut, Breed Specific Haircut, Fanny Hair Trim, Paw Hair Trim, Tail Hair Shaping */}
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Includes Bath</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Nail Trim</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Brushing</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Shampoo</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Scrubbing</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Coat Conditioner</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>
                    Ear Cleaning or Flushing
                  </div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Blow Dry</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Full Haircut</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Breed Specific Haircut</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}> Fanny Hair Trim</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Paw Hair Trim</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Tail Hair Shaping</div>
                </div>
              </div>
            ) : null}
            {serviceChoice === "Massage, Bath, Full haircut & Styling" ? (
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "5px",
                }}
              >
                {/* Includes  Oil massage of Feet and back, Bath, Nail Trim, Brushing, Shampoo, Scrubbing, Coat Conditioner, Ear Cleaning or Flushing, Blow Dry, Full Haircut, Breed Specific Haircut, Fanny Hair Trim, Paw Hair Trim, Tail Hair Shaping */}
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>
                    Includes Oil massage of Feet and back
                  </div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}> Bath</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Nail Trim</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Brushing</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Shampoo</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Scrubbing</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Coat Conditioner</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>
                    Ear Cleaning or Flushing
                  </div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Blow Dry</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Full Haircut</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Breed Specific Haircut</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}> Fanny Hair Trim</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Paw Hair Trim</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Tail Hair Shaping</div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grooming-new-form-section1-carousel-container">
            <div className="grooming-new-form-section1-carousel-active"></div>
            <div className="grooming-new-form-section1-carousel"></div>
            <div className="grooming-new-form-section1-carousel"></div>
          </div>
        </div>
      ) : null}

      {selectedCarousel == 2 ? (
        <div className="grooming-new-form-section1-main-container">
          <div className="grooming-new-form-section1-main">
            <div className="grooming-new-form-section2-left">
              <div className="grooming-new-form-section3-right-ph-section">
                <div className="grooming-new-form-validation-container-desktop">
                  <div className="grooming-new-form-section3-right-ph-text">
                    Enter Name
                  </div>
                  {newNameValidation ? null : (
                    <div className="home-page-desktop-phone-validation-text">
                      Required
                    </div>
                  )}
                </div>

                <div className="grooming-new-form-section3-right-ph-input-contact">
                  <input
                    onChange={() => {
                      setNewNameValidation(true);
                      setnameValue(true);
                      setNameFill(newNameRef.current.value);
                    }}
                    ref={newNameRef}
                    className="grooming-new-form-desktop-input-container-type1"
                    placeholder="Enter your name"
                    type="text"
                    style={{ padding: "10px" }}
                    value={nameValue ? nameFill : ""}
                  ></input>
                </div>
              </div>

              <div
                className="grooming-new-form-section2-left-date-container"
                style={{ marginTop: "20px" }}
              >
                <div className="grooming-new-form-validation-container-desktop">
                  <div className="grooming-new-form-section3-right-ph-text">
                    Session Date
                  </div>
                  {newDateValidation ? null : (
                    <div className="home-page-desktop-phone-validation-text">
                      Required
                    </div>
                  )}
                </div>
                <div
                  className="grooming-new-form-desktop-input-space1"
                  style={{ position: "relative" }}
                >
                  <input
                    ref={newDateRef}
                    className="grooming-new-form-desktop-input-container-type1"
                    onChange={() => {
                      setStartValue(true);
                      setStartDate(newDateRef.current.value);
                    }}
                    placeholder="DD/MM/YY"
                    type="date"
                    style={{ padding: "10px", fontSize: "16px" }}
                    value={startValue ? startDate : formattedDate}
                  ></input>
                </div>
              </div>

              <div className="grooming-new-form-section2-left-date-text-container">
                {representationDate}{" "}
                <span className="pink-text">{representationDay}</span>
              </div>
            </div>
            <div className="grooming-new-form-section1-right">
              <div className="grooming-new-form-section3-right-ph-section-2">
                <div className="grooming-new-form-section3-right-ph-section-Phone-container">
                  <div>
                    <div className="grooming-new-form-validation-container-desktop">
                      <div className="grooming-new-form-section3-right-ph-text">
                        Phone Number
                      </div>
                      {newPhoneValidation ? null : (
                        <div className="home-page-desktop-phone-validation-text">
                          Required
                        </div>
                      )}
                    </div>
                    <div
                      className="grooming-new-form-section3-right-ph-input-contact"
                      style={{}}
                    >
                      {/* <div> */}
                      <input
                        disabled={isVerified}
                        onChange={() => {
                          setNewPhoneValidation(true);
                          setPhoneValue(true);
                          setPhoneFill(newPhoneRef.current.value);
                        }}
                        ref={newPhoneRef}
                        className="grooming-new-form-desktop-input-container-type1"
                        // {isVerified ? 'grooming-new-form-desktop-input-container-type1-verified' :'grooming-new-form-desktop-input-container-type1'}

                        placeholder="Contact number"
                        type="number"
                        required
                        style={{ padding: "10px", width: "100%" }}
                        value={phoneValue ? phoneFill : null}
                      ></input>
                      {/* </div> */}
                      {/* <div className='resend-otp-desktop' style={{display:"flex",flexDirection:"column"}}>
                                            {
                                                isVerified === true ? (null) : (
                                                    <>
                                                        <button id='send-otp-desk' disabled={isVerified} onClick={() => { getmobileOtp(); setShowEnterOtp(true) ; changeName();setSeconds(33) }} className='send-otp-button-desktop-container' style={{display:"block",borderColor:correctOTP?"white":"red",backgroundColor:phoneFill?.length>=10?"rgb(69, 163, 165)":"",color:phoneFill?.length>=10?"white":"",borderRadius:"10px"}} >
                                                        
                                                        Send OTP 
                                                    </button>
                                                    <div>
                                                    {seconds!=0 && countdown?<div className='countdown-seconds' style={{fontSize:"12px",textAlign:"center"}}>{seconds} sec</div>:null}
                                                    </div>
                                                    
                                                    </>
                                                )
                                            }
                                        </div> */}
                    </div>
                    {/* {seconds!=0?<div className='countdown-seconds' style={{fontSize:"12px",textAlign:"center"}}>{seconds} sec</div>:null} */}
                  </div>

                  {/* {  showEnterOtp && (newPhoneRef.current.value.toString().length === 10 || showDialog) && (<div className="enterotp">
                                    <div className='enterotp-heading' style={{marginBottom:"5px"}}>
                                        <div>Enter OTP</div>
                                        {

                                            (isWrongOtp && !isVerified) ? (
                                                <div className='home-page-walking-form-mobile-feedback'>
                                                    Wrong OTP
                                                </div>
                                            ) : null

                                        }
                                    </div>
                                    <input  ref={groomingOtpRef} onClick={() => { setIsWrongOtp(false) }} disabled={isVerified} type="number" placeholder='Enter Otp'
                                    style={{padding:"10px"}} className='grooming-new-form-desktop-input-container-type1'/>
                                            <button
                                            style={{padding:"5px",margin:"0px",height:"40px",border:"2px solid #45A5A3",backgroundColor:phoneFill?.length>=10?"rgb(69, 163, 165)":"",color:phoneFill?.length>=10?"white":""}}
                                            disabled={isVerified}
                                            onClick={() => { verifyMobileOTP();indicateAlert() }}
                                            >
                                                {isVerified ? (
                          <>
                         
                            <img src={greenTick} width={15} />{" "}
                            <span style={{ marginLeft: "5px",color:"white" }}>Verified</span>
                           </>
                        ) : (
                          "Verify"
                        )}
                        </button>
                                     
                                </div>)} */}
                </div>
              </div>
              <div
                className="grooming-new-form-section2-right-time-slot-container"
                style={{ marginTop: "20px", width: "100%" }}
              >
                <div>Time Slot</div>
                <div
                  className="grooming-new-form-section2-right-time-slots"
                  style={{ justifyContent: "flex-start" }}
                >
                  <div
                    onClick={() => setTimeSlot(1)}
                    className={
                      timeSlot == 1
                        ? "grooming-new-form-section2-right-time-slot-active"
                        : "grooming-new-form-section2-right-time-slot"
                    }
                  >
                    10AM-02PM
                  </div>
                  <div
                    onClick={() => setTimeSlot(2)}
                    className={
                      timeSlot == 2
                        ? "grooming-new-form-section2-right-time-slot-active"
                        : "grooming-new-form-section2-right-time-slot"
                    }
                  >
                    02PM-06PM
                  </div>
                  <div
                    onClick={() => setTimeSlot(3)}
                    className={
                      timeSlot == 3
                        ? "grooming-new-form-section2-right-time-slot-active"
                        : "grooming-new-form-section2-right-time-slot"
                    }
                  >
                    06PM-10PM
                  </div>
                </div>
              </div>
              <div
                className="grooming-new-form-section2-right-next-button-container"
                style={{ justifyContent: "flex-end" }}
              >
                <div
                  onClick={() => {
                    groomingFormDesktopHandler2();
                  }}
                  className="grooming-new-form-section1-right-next-button"
                  style={{ width: "100%", color: "black" }}
                >
                  Next
                </div>
              </div>
            </div>
          </div>

          <div className="grooming-new-form-section1-carousel-container">
            <div className="grooming-new-form-section1-carousel-active"></div>
            <div className="grooming-new-form-section1-carousel-active"></div>
            <div className="grooming-new-form-section1-carousel"></div>
          </div>
        </div>
      ) : null}

      {selectedCarousel == 3 ? (
        <div className="grooming-new-form-section1-main-container">
          <div className="grooming-new-form-section1-main">
            <div className="grooming-new-form-section3-left">
              {/* <div className="grooming-new-form-section3-right-ph-section">
                <div className="grooming-new-form-validation-container-desktop">
                  <div className="grooming-new-form-section3-right-ph-text">
                    Enter Name
                  </div>
                  {newNameValidation ? null : (
                    <div className="home-page-desktop-phone-validation-text">
                      Required
                    </div>
                  )}
                </div>

                <div className="grooming-new-form-section3-right-ph-input-contact">
                  <input
                    onChange={() => {
                      setNewNameValidation(true);
                      setnameValue(true);
                      setNameFill(newNameRef.current.value);
                    }}
                    ref={newNameRef}
                    className="grooming-new-form-desktop-input-container-type1"
                    placeholder="Enter your name"
                    type="text"
                    style={{ padding: "10px" }}
                    value={nameValue ? nameFill : ""}
                  ></input>
                </div>
              </div> */}


              {/* <div className="grooming-new-form-section3-right-address-section">
                <div className="grooming-new-form-validation-container-desktop">
                  <div className="grooming-new-form-section3-right-ph-text">
                    Select Breed of pet
                  </div>
                  
                </div>
                <div className="grooming-new-form-section3-right-ph-input-contact" style={{width:"10rem"}}>
                 
                  <BreedAutocomplete selectedBreed={breed} setSelectedBreed={setBreed} />
                </div>
              </div>  */}

              <div className="grooming-new-form-section3-right-address-section">
                <div className="grooming-new-form-validation-container-desktop">
                  <div className="grooming-new-form-section3-right-ph-text">
                    Your Location
                  </div>
                  {newAddressValidation ? null : (
                    <div className="home-page-desktop-phone-validation-text">
                      Required
                    </div>
                  )}
                </div>
                <div className="grooming-new-form-section3-right-ph-input-contact">
                  <input
                    onChange={() => {
                      setNewAddressValidation(true);
                      setAddress(newAddressRef.current.value);
                    }}
                    onFocus={handleLocationAccess}
                    ref={newAddressRef}
                    className="grooming-new-form-desktop-input-container-type1 "
                    placeholder="Enter Your Home Address"
                    type="text"
                    style={{ padding: "10px" }}
                    value={loading ? "Loading..." : address}
                  ></input>
                </div>
              </div>

              {/* <div className='grooming-new-form-section3-right-address-section'>
                            <div className='grooming-new-form-validation-container-desktop'>
                                <div className='grooming-new-form-section3-right-ph-text'>Complete Address</div>
                                {
                                    newAddressValidation1 ? null : (
                                        <div className='home-page-desktop-phone-validation-text'>Required</div>
                                    )
                                }
                            </div>
                            <div className='grooming-new-form-section3-right-ph-input-contact'>
                                <input onChange={() => {setNewAddressValidation1(true);
                                setAddressValue(true);
                                setAddressFill(newAddressRef1.current.value)
                                }} ref={newAddressRef1} className='grooming-new-form-desktop-input-container-type1' placeholder='Enter Your Home Address' type="text" style={{padding:"10px"}} value={addressValue?addressFill:""} ></input>
                            </div>
                        </div>   */}
            </div>
            <div
              className="grooming-new-form-section3-right"
              style={{ width: "40%" }}
            >
              {/* <div className="grooming-new-form-section3-right-ph-section-2">
                <div className="grooming-new-form-section3-right-ph-section-Phone-container">
                  <div>
                    <div className="grooming-new-form-validation-container-desktop">
                      <div className="grooming-new-form-section3-right-ph-text">
                        Phone Number
                      </div>
                      {newPhoneValidation ? null : (
                        <div className="home-page-desktop-phone-validation-text">
                          Required
                        </div>
                      )}
                    </div>
                    <div
                      className="grooming-new-form-section3-right-ph-input-contact"
                      style={{}}
                    >
                      <div>
                      <input
                        disabled={isVerified}
                        onChange={() => {
                          setNewPhoneValidation(true);
                          setPhoneValue(true);
                          setPhoneFill(newPhoneRef.current.value);
                        }}
                        ref={newPhoneRef}
                        className="grooming-new-form-desktop-input-container-type1"

                        placeholder="Contact number"
                        type="number"
                        required
                        style={{ padding: "10px", width: "100%" }}
                        value={phoneValue ? phoneFill : null}
                      ></input>
                      </div>
                      <div className='resend-otp-desktop' style={{display:"flex",flexDirection:"column"}}>
                                            {
                                                isVerified === true ? (null) : (
                                                    <>
                                                        <button id='send-otp-desk' disabled={isVerified} onClick={() => { getmobileOtp(); setShowEnterOtp(true) ; changeName();setSeconds(33) }} className='send-otp-button-desktop-container' style={{display:"block",borderColor:correctOTP?"white":"red",backgroundColor:phoneFill?.length>=10?"rgb(69, 163, 165)":"",color:phoneFill?.length>=10?"white":"",borderRadius:"10px"}} >
                                                        
                                                        Send OTP 
                                                    </button>
                                                    <div>
                                                    {seconds!=0 && countdown?<div className='countdown-seconds' style={{fontSize:"12px",textAlign:"center"}}>{seconds} sec</div>:null}
                                                    </div>
                                                    
                                                    </>
                                                )
                                            }
                                        </div>
                    </div>
                    {seconds!=0?<div className='countdown-seconds' style={{fontSize:"12px",textAlign:"center"}}>{seconds} sec</div>:null}
                  </div>

                  {  showEnterOtp && (newPhoneRef.current.value.toString().length === 10 || showDialog) && (<div className="enterotp">
                                    <div className='enterotp-heading' style={{marginBottom:"5px"}}>
                                        <div>Enter OTP</div>
                                        {

                                            (isWrongOtp && !isVerified) ? (
                                                <div className='home-page-walking-form-mobile-feedback'>
                                                    Wrong OTP
                                                </div>
                                            ) : null

                                        }
                                    </div>
                                    <input  ref={groomingOtpRef} onClick={() => { setIsWrongOtp(false) }} disabled={isVerified} type="number" placeholder='Enter Otp'
                                    style={{padding:"10px"}} className='grooming-new-form-desktop-input-container-type1'/>
                                            <button
                                            style={{padding:"5px",margin:"0px",height:"40px",border:"2px solid #45A5A3",backgroundColor:phoneFill?.length>=10?"rgb(69, 163, 165)":"",color:phoneFill?.length>=10?"white":""}}
                                            disabled={isVerified}
                                            onClick={() => { verifyMobileOTP();indicateAlert() }}
                                            >
                                                {isVerified ? (
                          <>
                         
                            <img src={greenTick} width={15} />{" "}
                            <span style={{ marginLeft: "5px",color:"white" }}>Verified</span>
                           </>
                        ) : (
                          "Verify"
                        )}
                        </button>
                                     
                                </div>)}
                </div>
              </div> */}
              {/* <div className="grooming-new-form-section3-right-address-section">
                <div className="grooming-new-form-validation-container-desktop">
                  <div className="grooming-new-form-section3-right-ph-text">
                    Coupon Code
                    <span style={{ fontStyle: "italic" }}>(If Available)</span>
                  </div>
                  {couponValid && (
                    <div style={{ color: "#618E41", fontSize: "15px" }}>
                      Code applied successfully
                    </div>
                  )}
                  {couponNotValid && (
                    <div style={{ color: "red", fontSize: "15px" }}>
                      Invalid Code
                    </div>
                  )}
                </div>
                <div className="grooming-new-form-section3-right-ph-input-contact">
                  <input
                    id="coupon"
                    type="text"
                    className="grooming-new-form-desktop-input-container-type1"
                    onChange={(e) => {
                      setCouponCode(e.target.value);
                    }}
                    value={couponCode}
                  />
                  {!couponValid && !couponNotValid && (
                    <div
                      style={{ padding: "1rem" }}
                      className="coupon-apply-button"
                      onClick={()=>applyCoupon()}
                    >
                      <span style={{ paddingRight: "0.5rem" }} >Apply</span>{" "}
                      <FaArrowAltCircleRight className="coupon-apply-button-icon" />
                    </div>
                  )}

                  {couponValid && (
                    <>
                      <div
                        className="coupon-applied-text"
                        style={{ color: "#618E41;" }}
                      >
                        <span style={{ marginRight: "8px" }}>
                          ₹ {couponValue} /- OFF
                        </span>{" "}
                        <FaCheck className="check-mark" />
                      </div>
                    </>
                  )}
                  {couponNotValid && (
                    <div className="coupon-not-valid">
                      <FaXmark className="x-mark" />
                    </div>
                  )}
                </div>
                <div className="grooming-new-form-section3-right-ph-input-contact">
                  <button
                    id="coupon"
                    hidden={couponCode.trim()!==""}
                    type="text"
                    className="grooming-new-form-desktop-input-container-type1-button"
                    style={{width:"70%",borderStyle:"dotted", font:"DM Sans", fontStyle:"italic", fontSize:"1.1rem"}}
                    onClick={()=>applyMOJO()}
                  >Save <span style={{color:"green"}}>5%</span>  more with <span style={{color:"#FF5E95", font:"Lato", fontWeight:"800"}}> MOJO5</span></button>
                </div> 
              </div> */}
              <div className="grooming-form-tagline-text"
              //    hidden={couponCode.trim()===""}
              >
                <center style={{ fontSize: "1.5rem", marginTop: "1rem" }}>
                  Total Payable :
                  {!couponValid && !couponNotValid && <span> ₹{price1}</span>}
                  {couponValid && (
                    <span>
                      <span style={{ textDecoration: "line-through" }}>
                        ₹{price1}
                      </span>
                      <span
                        style={{
                          color: "#618E41",
                          paddingLeft: "0.5rem",
                          fontWeight: "800",
                        }}
                      >
                        {" "}
                        ₹{price1 - couponValue}
                      </span>
                    </span>
                  )}
                  {couponNotValid && <span>₹{price1}</span>}
                </center>
              </div>
              {/* {isLoading ? (
                <div className="grooming-page-loading-spinner-container-desktop">
                  <LoadingSpinner />
                </div>
              ) : (
                <div className="grooming-new-form-section1-right-pay-button-container">
                  <button
                    // disabled={isVerified === false || isVerified === null}
                    onClick={() => paymentInitiationHandler()}
                      className="grooming-new-form-section1-right-pay-button"
                      style={{color:"black"}}
                    // style={{backgroundColor:!isVerified?"rgba(131, 145, 161, 1)":"",border:!isVerified?"2px solid rgba(131, 145, 161, 1)":""}}
                  >
                    Pay Now
                  </button>
                  <button
                    //  disabled={isVerified === false || isVerified === null}
                    onClick={() => bookNowPayLaterHandler()}
                    className="grooming-new-form-section1-right-pay-later-button"
                    // style={{backgroundColor:!isVerified?"white":"",border:!isVerified?"2px solid rgba(131, 145, 161, 1)":"",color:!isVerified?"rgba(131, 145, 161, 1)":""}}
                  >
                    Book Now & Pay Later
                  </button>
                </div>
              )} */}
            </div>
          </div>
          <div className="grooming-form-tagline-text">
            <center>
              Paid Sessions are given time & elite groomers preference!
            </center>
          </div>
          <div className="grooming-new-form-section1-right-pay-button-container2">
            <button
              // disabled={isVerified === false || isVerified === null}
              onClick={() => paymentInitiationHandler()}
              className="grooming-new-form-section1-right-pay-button"
              style={{ color: "black", width: "80%" }}
            // style={{backgroundColor:!isVerified?"rgba(131, 145, 161, 1)":"",border:!isVerified?"2px solid rgba(131, 145, 161, 1)":""}}
            >
              Pay Now
            </button>
            {/* <button
              //  disabled={isVerified === false || isVerified === null}
              onClick={() => bookNowPayLaterHandler()}
              className="grooming-new-form-section1-right-pay-later-button"
              // style={{backgroundColor:!isVerified?"white":"",border:!isVerified?"2px solid rgba(131, 145, 161, 1)":"",color:!isVerified?"rgba(131, 145, 161, 1)":""}}
            >
              Book Now & Pay Later
            </button> */}
          </div>

          <div className="grooming-new-form-section1-carousel-container">
            <div className="grooming-new-form-section1-carousel-active"></div>
            <div className="grooming-new-form-section1-carousel-active"></div>
            <div className="grooming-new-form-section1-carousel-active"></div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default HomePageForm;

//displayRazorpay()

//onClick={() => groomingDataSubmitHandler("grooming")}

// /payment/success:

//     request body:
// {
//     orderCreationId: order_id,
//     razorpayPaymentId: response.razorpay_payment_id,
//     razorpayOrderId: response.razorpay_order_id,
//     razorpaySignature: response.razorpay_signature,
// }
//     response body:
//         {
//             msg: "success",
//             orderId: razorpayOrderId,
//             paymentId: razorpayPaymentId,
//         }

// /payment/orders:
//         request body:
//             {
//                 'amount': serviceAmount
//             }

//         response body:

//                 {
//                     id: 'order_Kjsc3E9EEaiS13',
//                     entity: 'order',
//                     amount: 100,
//                     amount_paid: 0,
//                     amount_due: 100,
//                     currency: 'INR',
//                     receipt: 'receipt_order_74394',
//                     offer_id: null,
//                     status: 'created',
//                     attempts: 0,
//                     notes: [],
//                     created_at: 1669293764
//                 }
