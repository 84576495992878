import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReviewCard from "./ReviewCard";
import ReviewVideos from "./ReviewVideos";
import axios from "axios";
import deskBg from '../../images/reviewDesktopBg.png';
import mobileBg from '../../images/reviewMobileBg.png';
import NavigationDesktop from "../../components-desktop/Navigation/NavigationDesktop";
import NavigationBar from "../../components/Navigation/navigationBar/NavigationBar";
import ReviewFooter from "./ReviewFooter";
import { Helmet } from "react-helmet-async";

const baseUrl = "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api"

// const sampleReviews = [
//   {
//     name: "Harsh Mahajan",
//     rating: 5,
//     review: "I booked training for my puppy when he was 2 months old... After a few sessions, he got very good at listening to me.",
//     comments: [{ name: 'Chirag Sharma', comment: 'Yes, I was assigned a trainer after booking my first training service whose name was Shubham. He was so calm and my petwas feeling more joyfull and energetic with him while training. I am very thankful that petmojo gave me my best active pet.' }, { name: 'Chirag Sharma', comment: 'Yes, I was assigned a trainer after booking my first training service whose name was Shubham. He was so calm and my petwas feeling more joyfull and energetic with him while training. I am very thankful that petmojo gave me my best active pet.' }],
//     phone: "1234567890"
//   },
//   {
//     name: "Sarah Smith",
//     rating: 4.5,
//     review: "The trainer was excellent at handling my hyperactive dog. The training sessions were productive and enjoyable.",
//     comments: [],
//     phone: "9876543210"
//   },
//   {
//     name: "John Doe",
//     rating: 3.5,
//     review: "Overall good experience. My dog showed some improvement, but there were a few areas that could have been better.",
//     comments: [],
//     phone: "5678901234"
//   },
//   {
//     name: "Emily Johnson",
//     rating: 5,
//     review: "Amazing trainer! My dog learned so much in just a few sessions. Highly recommend!",
//     comments: [],
//     phone: "6789012345"
//   }
// ];

function Review({ changeView }) {

  const [allReviews, setAllReviews] = useState([]);

  const navigate = useNavigate();

  const getAllReviews = async () => {
    try {
      const res = await axios.post(`${baseUrl}/website/getReviews`);
      setAllReviews(res.data.allReviews);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllReviews();
  }, []);

  return (
    <div className="w-screen h-screen relative overflow-y-auto" style={{ backgroundImage: `url(${changeView ? deskBg : mobileBg})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
      <Helmet>
        <title>
          Petmojo Reviews | See What Our Clients Say
        </title>
        <meta
          name="description"
          content="Read honest Petmojo reviews from our satisfied clients. Check out our testimonials and see how we deliver exceptional pet care!"
        />
      </Helmet>
      {changeView ? (
        <NavigationDesktop
          submissionType={"none"}
          submissionText={"Download Our App"} />
      ) : (
        <NavigationBar btn={"training"} homePage={true} page={"white"} />
      )}
      <div className="mx-auto max-w-7xl z-1 mb-10">
        <div className="w-full flex flex-col px-5 md:px-1 py-5 gap-16">
          <div className="text-center flex flex-col gap-8">
            <h1 className="mx-auto text-white text-[26px] md:text-5xl font-semibold">Our <span className="text-[#85EA62]">Happy</span> Customers</h1>
            <ReviewVideos changeView={changeView} />
          </div>
          <button className="w-full max-w-md mx-auto py-2 rounded-lg text-white bg-brand font-bold" onClick={() => navigate("/add-reviews")}>+ Add a Review</button>
          <div className="flex flex-col gap-5">
            {allReviews?.map((review, index) => (
              <ReviewCard key={index} id={review._id} name={review.name} rating={review.rating} review={review.review} comments={review.commentId} />
            ))}
          </div>
        </div>
      </div>
      <div className="bg-white ">
        <ReviewFooter changeView={changeView} />
      </div>
    </div>
  );
}

export default Review;
