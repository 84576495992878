import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { BiArrowBack } from "@react-icons/all-files/bi/BiArrowBack";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import DogImage from "../../../images/dog.png";
import dog10 from "../../../images/dog10.png";
import dog3 from "../../../images/dog3.png";
import dog4 from "../../../images/dog4.png";
import dog5 from "../../../images/dog5.png";
import dog6 from "../../../images/dog6.png";
import dog7 from "../../../images/dog7.png";
import dog8 from "../../../images/dog8.png";
import dog9 from "../../../images/dog9.png";
import dogBath from "../../../images/dogBath.png";
import dropDown from "../../../images/Dropdown Arrow.png";
import ImageSlider from "../../ImageSlider";
const images = [
  {
    id: 1,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-1.webp",
    alt: "Image 1",
  },
  {
    id: 2,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-2.webp",
    alt: "Image 2",
  },
  {
    id: 3,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-3.webp",
    alt: "Image 3",
  },
  {
    id: 4,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-4.webp",
    alt: "Image 4",
  },
  {
    id: 5,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-5.webp",
    alt: "Image 5",
  },
  {
    id: 6,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-6.webp",
    alt: "Image 6",
  },
  {
    id: 7,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-7.webp",
    alt: "Image 7",
  },
  {
    id: 8,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-8.webp",
    alt: "Image 8",
  },
];

const HomePageForm = (props) => {
  const navigate = useNavigate();
  const [selectedService, setSelectedService] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [couponValue, setCouponValue] = useState(0);
  const [couponErrorMsg, setCouponErrorMsg] = useState("");
  const [couponNotValid, setCouponNotValid] = useState(false);
  const [couponValid, setCouponValid] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [countdown, setCountdown] = useState(true);
  const [serviceDropdown, setServiceDropdown] = useState(false);
  const [selectedCarousel, setSelectedCarousel] = useState(1);
  const [timeSlot, setTimeSlot] = useState(1);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [serviceChoice, setServiceChoice] = useState(
    "Bath & Brush Subscription(3 sessions)"
  );
  const [pet, setPet] = useState("Dog");
  const [petChoice, setpetChoice] = useState(1);
  const location = useLocation().pathname;
  const [overallChoice, setOverallChoice] = useState({
    choice: "",
    pet: "",
    date: "",
    time: "10AM-02PM",
    name: "",
    address: "",
    phone: "",
    latitude: 0,
    longitude: 0,
  });
  const [newNameValidation, setNewNameValidation] = useState(true);
  const [newPhoneValidation, setNewPhoneValidation] = useState(true);
  const [newAddressValidation, setNewAddressValidation] = useState(true);
  const [newAddressValidation1, setNewAddressValidation1] = useState(true);
  const [newDateValidation, setNewDateValidation] = useState(true);
  const [representationDate, setRepresentationDate] = useState("");
  const [representationDay, setRepresentationDay] = useState("");
  const [isVerified, setIsVerified] = useState(null);
  const [showEnterOtp, setShowEnterOtp] = useState(false);
  const [isWrongOtp, setIsWrongOtp] = useState(false);
  const [correctOTP, setCorrectOTP] = useState(true);
  const baseUrl =
    "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api";
  const [token, setToken] = useState("");
  const [bookingId, setBookingId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const newDateRef = useRef("");
  const newServiceRef = useRef("");
  const newTimeServiceRef = useRef("");
  const newNameRef = useRef("");
  const newAddressRef = useRef("");
  const newAddressRef1 = useRef("");
  const newPhoneRef = useRef("");
  const groomingOtpRef = useRef("");

  const [paymentData, setPaymentData] = useState({});
  const [flag, setFlag] = useState(false);

  const [address, setAddress] = useState("");
  const [startValue, setStartValue] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [startAddress, setStartAddress] = useState(false);
  const [lat, setLat] = useState(0);
  const [lon, setLon] = useState(0);
  const [nameValue, setnameValue] = useState(false);
  const [nameFill, setNameFill] = useState("");
  const [phoneValue, setPhoneValue] = useState(false);
  const [phoneFill, setPhoneFill] = useState(null);
  const [addressValue, setAddressValue] = useState(false);
  const [city, setCity] = useState("");
  const [adressFill, setAdressFill] = useState("");
  const [phone, setPhone] = useState("");
  const [price1, setPrice] = useState(799);
  const [selectedOption, setSelectedOption] = useState("option1");
  const options = ["Option 1", "Option 2", "Option 3", "Option 4"];
  const [isClicked1, setIsClicked1] = useState(false);
  const [isClicked2, setIsClicked2] = useState(true);
  const [isClicked3, setIsClicked3] = useState(false);
  const [isClicked4, setIsClicked4] = useState(false);
  const [isClicked5, setIsClicked5] = useState(false);
  const [isClicked6, setIsClicked6] = useState(false);
  const [isClicked7, setIsClicked7] = useState(true);
  const [isClicked8, setIsClicked8] = useState(false);
  const [isClicked9, setIsClicked9] = useState(false);
  const [isClicked10, setIsClicked10] = useState(false);
  const [isClicked11, setIsClicked11] = useState(true);
  const [isClicked12, setIsClicked12] = useState(false);
  const [isClicked13, setIsClicked13] = useState(false);
  const [sessionNumber, setSessionNumber] = useState(3);
  const [packageAmount, setPackageAmount] = useState(699);
  const [saveAmount, setSaveAmount] = useState(300);
  const [amount, setAmount] = useState(1);

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);

    switch (event.target.value) {
      case "option1":
        {
          handleDivClick2();
          setIsClicked2(true);
          setPackageAmount(699);
        }

        break;
      case "option2":
        {
          handleDivClick7();
          setIsClicked7(true);
          setPackageAmount(1149);
        }

        break;
      case "option3":
        {
          handleDivClick11();
          setIsClicked11(true);
          setPackageAmount(1549);
        }
        break;
      default:
    }
  };
  let price = 0;
  var currentDate = new Date();
  var year = currentDate.getFullYear();
  var month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
  var day = ("0" + currentDate.getDate()).slice(-2);
  var formattedDate = year + "-" + month + "-" + day;
  function setSelectedServiceHandler(service) {
    setSelectedService(service);
    setServiceDropdown(false);
  }

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay(
    serviceAmount,
    inputPhone,
    SelectedService,
    DateOfReg,
    InputAddress
  ) {
    setIsLoading(false);

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // creating a new order
    const result = await axios.post(
      "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/generateGroomingRazorPayOrderId",
      {
        amount: serviceAmount,
      }
    );

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }

    // Getting the order details back
    const { amount, id: order_id, currency } = result.data;

    const options = {
      key: process.env.REACT_APP_KEY, // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: "Petmojo",
      description: "Pet Grooming Payment",
      //image: { logo },
      order_id: order_id,
      handler: async function (response) {
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };

        //console.log("Grooming Page Function: ", data);

        const result = await axios.post(
          "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/generateGroomingRazorPaySuccess",
          data
        );
        //alert(result.data.msg);
        const Data = {
          ...result.data,
          SelectedService,
          DateOfReg,
          InputAddress,
        };

        const actualAmount = serviceAmount / 100;

        const dataToStore = {
          Date: DateOfReg,
          Address: InputAddress,
          Contact_No: inputPhone.toString(),
          Amount_Paid: actualAmount.toString(),
          Selected_Service: SelectedService,
          Order_id: order_id,
        };

        await axios
          .post(
            "https://api.apispreadsheets.com/data/hq3CiEAYcPfNXIJE/",
            dataToStore
          )
          .then((response) => {
            console.log(response);
            console.log("The grooming payment has been completed!");
          })
          .catch((err) => {
            console.log(err);
            console.log(
              "There was some error in submitting the grooming payment to backend!"
            );
          });

        const data2 = {
          bookingId: bookingId,
          transactionId: order_id,
          amount: actualAmount,
          type: "User",
        };

        data2["isFromWebsite"] = true;
        // {
        //     "bookingId" : "63d554eb156e83edfd5d1144",
        //     "transactionId" : "order_JCB9CaLSarOaTj",
        //     "amount":799,
        //     "type": "User"
        // }

        await axios
          .patch(
            "https://6xdmb7fadb.execute-api.ap-south-1.amazonaws.com/production/api/service/postGroomingPayment",
            data2,
            {
              "Content-Type": "application/json",
              headers: {
                Authorization:
                  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjYzZWY5ZWZkNTc1MGY5Y2Q4MmVmYmZmNyJ9.QOz1wBekeflAqD2ktWjDQLubM4snuMMDFV0Lk97z5UY",
              },
            }
          )
          .then((response) => {
            console.log(response);
            console.log(
              "The grooming payment has been completed and info has been saved in the database!"
            );
          })
          .catch((err) => {
            console.log(err);
            console.log(
              "There was some error in saving the grooming payment to database!"
            );
          });

        setPaymentData(Data);
        setFlag(true);
      },
      // prefill: {
      //     name: "Petmojo",
      //     email: "customercare@petmojo.in",
      //     contact: "+91 9009004473",
      // },
      notes: {
        address: "Petmojo",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    //console.log(paymentObject);
    paymentObject.open();
  }
  const [showDialog, setShowDialog] = useState(false);
  const checkPhone = (phoneString) => {
    if (
      phoneString.length > 10 &&
      (phoneString.startsWith("+91") || phoneString.startsWith("91"))
    ) {
      phoneString = phoneString.slice(-10);
      setPhone(phoneString);
      setShowDialog(true);
    }
    return phoneString;
  };
  const groomingFormDesktopHandler1 = () => {
    // if (serviceChoice === "") {
    //   console.log("Cant move to next card!");
    // }

    setOverallChoice({
      pet: pet,
      choice: serviceChoice,
      name: "",
      address: "",
      phone: "",
      time: "",
      date: "",
    });
    setSelectedCarousel(2);
    console.log(overallChoice);
  };

  const groomingFormDesktopHandler2 = () => {
    overallChoice.date = newDateRef.current.value;
    if (
      overallChoice.date == "" ||
      nameFill == "" ||
      phoneFill == "" ||
      phoneFill == null
    ) {
      console.log("Cant move to next card!");
      console.log(overallChoice);
    }
    if (nameFill == "") {
      setNewNameValidation(false);
    }
    if (phoneFill == null || phoneFill == "") {
      setNewPhoneValidation(false);
    } else {
      let time = "";
      if (timeSlot === 1) {
        time = "10AM-02PM";
      } else if (timeSlot === 2) {
        time = "02PM-06PM";
      } else if (timeSlot === 3) {
        time = "06PM-10PM";
      }

      setSelectedDate(newDateRef.current.value);
      setSelectedTime(time);
      //overallChoice.time

      setOverallChoice({
        choice: serviceChoice,
        pet: petChoice === 1 ? "Dog" : "Cat",
        date: newDateRef.current.value,
        time: time,
        name: "",
        address: "",
        phone: "",
        // latitude:lat,
        // longitude:lon
      });
      setSelectedCarousel(3);
      console.log(overallChoice);
    }
  };

  const getmobileOtp = useCallback(async () => {
    try {
      let phoneString = checkPhone(newPhoneRef.current.value.toString());
      const res = await axios.post(`${baseUrl}/auth/sendMobileOTP`, {
        phoneNumber: phoneString,
      });
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  }, []);
  const changeName = () => {
    document.getElementById("send-otp-desk").innerHTML = "Resend OTP";
  };
  const verifyMobileOTP = useCallback(async () => {
    try {
      let phoneString = checkPhone(newPhoneRef.current.value.toString());
      const res = await axios.post(
        "https://6xdmb7fadb.execute-api.ap-south-1.amazonaws.com/production/api/auth/sendMobileOTP",
        {
          otp: groomingOtpRef.current.value.toString(),
          phoneNumber: phoneString,
          type: "User",
        }
      );
      console.log(res.status);
      console.log(res.data);

      if (res.status === 200) {
        setIsVerified(true);
        setToken(res.data.token);
        setCountdown(false);
        setCorrectOTP(true);
      }
    } catch (error) {
      console.log(error.response.status);
      console.log(error);
      if (error.response.status === 400) {
        setIsVerified(false);
        setIsWrongOtp(true);
        setCorrectOTP(false);
      }
    }
  }, []);
  const indicateAlert = () => {
    if (!correctOTP) {
      document.getElementById("send-otp-desk").style.borderColor = "red";
    }
  };
  const backArrow = () => {
    setSelectedCarousel(selectedCarousel - 1);
  };
  const decrementSeconds = () => {
    setTimeout(() => {
      if (seconds == 0) {
        document.getElementById("countdown-seconds").style.display = "none";
      } else {
        setSeconds(seconds - 1);
      }
    }, 1000);
    // }
  };
  const paymentGatewayHit = async (data) => {
    const path = "grooming";
    const inputPhone = data.ContactNo;
    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${date.getMonth() + 1
      }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;
    const inputAddress = newAddressRef.current.value;

    var amount = sessionNumber * packageAmount;

    // setPrice(amount);
    console.log(amount);
    price = amount;
    data["Amount"] = amount;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: `${path} Registration Fired From Desktop Service Page Form`,
    });

    displayRazorpay(
      amount - couponValue,
      inputPhone,
      selectedService,
      dateOfReg,
      inputAddress
    );

    console.log(data);
  };

  const paymentInitiationHandler = async () => {
    const phone = phoneFill;
    const phoneString = checkPhone(phone.toString());
    const name = nameFill;
    const address = newAddressRef.current.value;

    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${date.getMonth() + 1
      }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;

    const enteredYear = parseInt(selectedDate.substring(0, 4));
    const enteredMonth = parseInt(selectedDate.substring(5, 7));
    const enteredDay = parseInt(selectedDate.substring(8, 10));

    if (phoneString.length !== 10 || name === "" || address === "") {
      console.log("cannot proceed further!");
    } else {
      setIsLoading(true);

      setOverallChoice({
        choice: serviceChoice,
        name: name,
        address: address,
        phone: phone,
        date: selectedDate,
        time: selectedTime,
        pet: petChoice === 1 ? "Dog" : "Cat",
        latitude: lat,
        longitude: lon,
      });

      let UTM_Source,
        UTM_Medium,
        UTM_Campaign = "";

      const urlParams = new URLSearchParams(window.location.search);
      UTM_Source = urlParams.get("utm_source");
      UTM_Medium = urlParams.get("utm_medium");
      UTM_Campaign = urlParams.get("utm_campaign");

      const data = {
        ContactNo: phone,
        Services: serviceChoice,
        Date: selectedDate,
        Time: selectedTime,
        Email: "",
        FormType: "Direct Form",
        DateOfRegistration: dateOfReg,
        TimeOfRegistration: timeOfReg,
        Address: address,
        Name: name,
        Pet: pet,
        latitude: lat,
        longitude: lon,
        UTM_Source: UTM_Source || "NA",
        UTM_Medium: UTM_Medium || "NA",
        UTM_Campaign: UTM_Campaign || "NA",
      };

      data["paymentStatus"] = 2;

      var amount = sessionNumber * packageAmount;

      price = amount;
      const data2 = {
        fullName: name,
        address: address,
        phone: phone,
        startDate: selectedDate,
        sessionTime: selectedTime,
        latitude: lat,
        longitude: lon,
        package: {
          description: serviceChoice,
          price: amount,
          frequency: 3,
        },
        type: "User",
        UTM_Source: UTM_Source || "",
        UTM_Medium: UTM_Medium || "",
        UTM_Campaign: UTM_Campaign || "",
        isSubscription: true,
      };

      data2["Amount"] = amount;
      await axios
        .post(
          "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/websiteDogGroomingBookingService",
          data2,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: token,
            },
          }
        )
        .then((response) => {
          console.log(response);
          setBookingId(response.bookingId);
          console.log("The grooming data is submitted to the database.");
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          console.log("There was some error in saving the data in database.");
        });
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: `Grooming Registration Fired From Desktop Service Page Form`,
      });
      paymentGatewayHit(data);
      return;
    }

    if (phoneString.length !== 10) {
      setNewPhoneValidation(false);
    }
    if (name === "") {
      setNewNameValidation(false);
    }
    if (address === "") {
      setNewAddressValidation(false);
    }
  };

  const bookNowPayLaterHandler = async () => {
    const phone = phoneFill;
    const phoneString = checkPhone(phone.toString());
    const name = nameFill;
    const address = newAddressRef.current.value;

    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${date.getMonth() + 1
      }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;

    const enteredYear = parseInt(selectedDate.substring(0, 4));
    const enteredMonth = parseInt(selectedDate.substring(5, 7));
    const enteredDay = parseInt(selectedDate.substring(8, 10));

    if (phoneString.length !== 10 || name === "" || address === "") {
      console.log("cannot proceed further!");
    } else {
      setIsLoading(true);

      setOverallChoice({
        choice: serviceChoice,
        name: name,
        address: address,
        phone: phoneString,
        date: selectedDate,
        time: selectedTime,
        pet: petChoice === 1 ? "Dog" : "Cat",
        latitude: lat,
        longitude: lon,
      });

      let UTM_Source,
        UTM_Medium,
        UTM_Campaign = "";

      const urlParams = new URLSearchParams(window.location.search);
      UTM_Source = urlParams.get("utm_source");
      UTM_Medium = urlParams.get("utm_medium");
      UTM_Campaign = urlParams.get("utm_campaign");

      const data = {
        ContactNo: phoneString,
        Services: serviceChoice,
        Date: selectedDate,
        Time: selectedTime,
        Email: "",
        FormType: "Direct Form",
        DateOfRegistration: dateOfReg,
        TimeOfRegistration: timeOfReg,
        Address: address,
        Name: name,
        latitude: lat,
        longitude: lon,
        UTM_Source: UTM_Source || "",
        UTM_Medium: UTM_Medium || "",
        UTM_Campaign: UTM_Campaign || "",
        // paymentStatus:2,
      };

      var amount = sessionNumber * packageAmount;

      console.log(price);
      data["Amount"] = amount;
      console.log(data.Amount);
      data["paymentStatus"] = 2;
      axios
        .post(
          "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/saveGroomingData",
          data
        )
        .then((response) => {
          console.log(response);
          console.log("The grooming form is being submitted...");
        })
        .catch((err) => {
          console.log(err);
          console.log(
            "There was some error in submitting the grooming form..."
          );
        });

      const data2 = {
        fullName: name,
        address: address,
        phone: phoneString,
        startDate: selectedDate,
        sessionTime: selectedTime,
        latitude: lat,
        longitude: lon,
        package: {
          description: serviceChoice,
          price: amount,
          frequency: 3,
        },
        type: "User",
        isCouponCodeUsed: couponValue === 0 ? false : true,
        couponCode: couponValue !== 0 ? couponCode : "",
        UTM_Source: UTM_Source || "",
        UTM_Medium: UTM_Medium || "",
        UTM_Campaign: UTM_Campaign || "",
        isSubscription: true,
      };

      console.log(data2);

      data2["Amount"] = amount;
      await axios
        .post(
          "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/websiteDogGroomingBookingService",
          data2,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: token,
            },
          }
        )
        .then((response) => {
          console.log(response);
          console.log("The grooming data is submitted to the database.");
        })
        .catch((err) => {
          console.log(err);
          console.log("There was some error in saving the data in database.");
        });

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: `Grooming Registration Fired From Desktop Service Page Form`,
      });

      navigate("/thankPageGroom");
    }

    if (phoneString.length !== 10) {
      setNewPhoneValidation(false);
    }
    if (name === "") {
      setNewNameValidation(false);
    }
    if (address === "") {
      setNewAddressValidation(false);
    }
  };

  useEffect(() => {
    if (flag) navigate("/ordersummary", { state: paymentData });
  }, [paymentData, flag]);

  const applyCoupon = async () => {
    await axios
      .post(
        "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/websiteVerifyCoupon",
        {
          name: couponCode,
          serviceType: 2,
          package: "Pet Hotel",
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: token,
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response.data.value) {
          setCouponValue(response.data.value);
          setCouponErrorMsg("");
          setCouponNotValid(false);
          setCouponValid(true);
        } else {
          setCouponValid(false);
          setCouponNotValid(true);
          setCouponErrorMsg(response.data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const date = new Date();

  // declaration of refs
  const phoneRef = useRef("");
  const emailRef = useRef("");
  const dateRef = useRef("");
  const addressRef = useRef("");
  const timeRef = useRef("");
  const [addressFill, setAddressFill] = useState(false);

  useEffect(() => {
    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${date.getMonth() + 1
      }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;

    var MonthName;
    if (date.getMonth() + 1 == 1) {
      MonthName = "January";
    } else if (date.getMonth() + 1 == 2) {
      MonthName = "February";
    } else if (date.getMonth() + 1 == 3) {
      MonthName = "March";
    } else if (date.getMonth() + 1 == 4) {
      MonthName = "April";
    } else if (date.getMonth() + 1 == 5) {
      MonthName = "May";
    } else if (date.getMonth() + 1 == 6) {
      MonthName = "June";
    } else if (date.getMonth() + 1 == 7) {
      MonthName = "July";
    } else if (date.getMonth() + 1 == 8) {
      MonthName = "August";
    } else if (date.getMonth() + 1 == 9) {
      MonthName = "September";
    } else if (date.getMonth() + 1 == 10) {
      MonthName = "October";
    } else if (date.getMonth() + 1 == 11) {
      MonthName = "November";
    } else if (date.getMonth() + 1 == 12) {
      MonthName = "December";
    }

    var DayName;
    if (date.getDay() + 1 == 1) DayName = "Sunday";
    else if (date.getDay() + 1 == 2) DayName = "Monday";
    else if (date.getDay() + 1 == 3) DayName = "Tuesday";
    else if (date.getDay() + 1 == 4) DayName = "Wednesday";
    else if (date.getDay() + 1 == 5) DayName = "Thursday";
    else if (date.getDay() + 1 == 6) DayName = "Friday";
    else if (date.getDay() + 1 == 7) DayName = "Saturday";

    const repDate =
      MonthName + " " + date.getDate() + ", " + date.getFullYear() + " | ";
    const repDay = DayName;
    setRepresentationDate(repDate);
    setRepresentationDay(repDay);
  }, []);
  useEffect(() => {
    indicateAlert();
  }, [correctOTP]);

  useEffect(() => {
    decrementSeconds();
  }, [seconds]);
  const handleLocationAccess = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setLat(latitude);
          setLon(longitude);
          // Make an API call to get the address based on the user's location
          fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCeoWpdd6YOVu3wEqqA0qpY6P3B5rUumm8`
          )
            .then((response) => response.json())
            .then((data) => {
              console.log(data);
              setAddress(data.results[9].formatted_address);
            })
            .catch((error) => console.error(error));
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const handleDivClick1 = () => {
    setIsClicked1(!isClicked1);
    setIsClicked2(false);
    setIsClicked3(false);
    setIsClicked4(false);
    setIsClicked5(false);
    setSessionNumber(1);
    setPackageAmount(799);
    setSaveAmount(0);
    setAmount(799);
    setServiceChoice("Bath & Brush Subscription(1 session)");
  };

  const handleDivClick2 = () => {
    setIsClicked2(!isClicked2);
    setIsClicked1(false);
    setIsClicked3(false);
    setIsClicked4(false);
    setIsClicked5(false);
    setSessionNumber(3);
    setPackageAmount(699);
    setSaveAmount(300);
    setServiceChoice("Bath & Brush Subscription(3 sessions)");
  };

  const handleDivClick3 = () => {
    setIsClicked3(!isClicked3);
    setIsClicked1(false);
    setIsClicked2(false);
    setIsClicked4(false);
    setIsClicked5(false);
    setSessionNumber(6);
    setPackageAmount(649);
    setSaveAmount(900);
    setServiceChoice("Bath & Brush Subscription(6 sessions)");
  };

  const handleDivClick4 = () => {
    setIsClicked4(!isClicked4);
    setIsClicked1(false);
    setIsClicked2(false);
    setIsClicked3(false);
    setIsClicked5(false);
    setSessionNumber(12);
    setPackageAmount(599);
    setSaveAmount(2400);
    setServiceChoice("Bath & Brush Subscription(12 sessions)");
  };

  const handleDivClick5 = () => {
    setIsClicked5(!isClicked5);
    setIsClicked1(false);
    setIsClicked2(false);
    setIsClicked4(false);
    setIsClicked3(false);
    setSessionNumber(24);
    setPackageAmount(475);
    setSaveAmount(7776);
    setServiceChoice("Bath & Brush Subscription(24 sessions)");
  };
  const handleDivClick6 = () => {
    setIsClicked6(!isClicked6);
    setIsClicked7(false);
    setIsClicked8(false);
    setIsClicked9(false);
    setSessionNumber(1);
    setPackageAmount(1249);
    setSaveAmount(0);
    setServiceChoice("Haircut & Styling(1 session)");
  };

  const handleDivClick7 = () => {
    setIsClicked7(!isClicked7);
    setIsClicked6(false);
    setIsClicked8(false);
    setIsClicked9(false);
    setSessionNumber(3);
    setPackageAmount(1149);
    setSaveAmount(300);
    setServiceChoice("Haircut & Styling(3 sessions)");
  };
  const handleDivClick8 = () => {
    setIsClicked8(!isClicked8);
    setIsClicked6(false);
    setIsClicked7(false);
    setIsClicked9(false);
    setSessionNumber(6);
    setPackageAmount(1049);
    setSaveAmount(1200);
    setServiceChoice("Haircut & Styling(6 sessions)");
  };
  const handleDivClick9 = () => {
    setIsClicked9(!isClicked9);
    setIsClicked7(false);
    setIsClicked8(false);
    setIsClicked6(false);
    setSessionNumber(12);
    setPackageAmount(949);
    setSaveAmount(3600);
    setServiceChoice("Haircut & Styling(12 sessions)");
  };

  const handleDivClick10 = () => {
    setIsClicked10(!isClicked10);
    setIsClicked11(false);
    setIsClicked12(false);
    setIsClicked13(false);
    setSessionNumber(1);
    setPackageAmount(1649);
    setSaveAmount(0);
    setServiceChoice("Bath, Full haircut & Styling(1 session)");
  };

  const handleDivClick11 = () => {
    setIsClicked11(!isClicked11);
    setIsClicked10(false);
    setIsClicked13(false);
    setIsClicked12(false);
    setSessionNumber(3);
    setPackageAmount(1549);
    setSaveAmount(300);
    setServiceChoice("Bath, Full haircut & Styling(3 sessions)");
  };

  const handleDivClick12 = () => {
    setIsClicked12(!isClicked12);
    setIsClicked10(false);
    setIsClicked11(false);
    setIsClicked13(false);
    setSessionNumber(6);
    setPackageAmount(1449);
    setSaveAmount(1200);
    setServiceChoice("Bath, Full haircut & Styling(6 sessions)");
  };

  const handleDivClick13 = () => {
    setIsClicked13(!isClicked13);
    setIsClicked10(false);
    setIsClicked11(false);
    setIsClicked12(false);
    setSessionNumber(12);
    setPackageAmount(1349);
    setSaveAmount(3600);
    setServiceChoice("Bath, Full haircut & Styling(12 sessions)");
  };

  return (
    <div
      className="home-page-desktop-intro-form-container2"
      style={{
        width: location == "/" ? "100%" : "",
        marginTop: location == "/" ? "0px" : "",
        border: location == "/" ? "0px" : "",
      }}
    >
      {location == "/grooming-subscription" && (
        <div className="walking-page-desktop-intro-form-header">
          <div style={{ display: "flex", alignItems: "start" }}>
            <div
              style={{
                width: selectedCarousel === 1 ? "0%" : "8%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <BiArrowBack
                style={{ display: selectedCarousel === 1 ? "none" : "block" }}
                onClick={backArrow}
              />
            </div>
            <div style={{ width: selectedCarousel === 1 ? "100%" : "90%" }}>
              Book your Grooming Subscription Here!{" "}
              {/* <span style={{ color: "#FF5E95" }}>
                FLAT 50% OFF on all Grooming Packages
              </span> */}
            </div>
          </div>
        </div>
      )}

      {selectedCarousel == 1 ? (
        <div className="grooming-new-form-section1-main-container">
          <div className="grooming-new-form-section1-main">
            <div
              className="grooming-new-form-section1-left"
              style={{ width: "50%" }}
            >
              <div className="">
                <span style={{ color: "#5E5E5E", fontWeight: "700" }}>
                  Type of Pet
                </span>
                <div
                  style={{
                    display: "flex",
                    padding: "10px",
                    width: "100%",
                    gap: "10%",
                  }}
                  className=""
                >
                  <div
                    className="option"
                    id="one"
                    style={{ display: "flex" }}
                    onClick={() => {
                      setpetChoice(1);
                    }}
                  >
                    <div className="heading" style={{ marginBottom: "5px" }}>
                      <div
                        className="left"
                        onClick={() => {
                          // console.log("h")
                          setPet("Dog");
                          setOverallChoice({ pet: "Dog" });
                        }}
                      >
                        <div className="selection-circle">
                          {petChoice == 1 && <div className="dot"></div>}
                        </div>
                        <div style={{ display: "flex", gap: "2px" }}>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Dog.png"
                            alt=""
                            style={{ width: "25px", height: "25px" }}
                          />
                          <span style={{ fontWeight: "500" }}>
                            Dog{" "}
                            <small style={{ fontSize: "13px" }}>
                              {" "}
                              <br />
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="option"
                    id="two"
                    style={{ display: "flex" }}
                    onClick={() => {
                      setpetChoice(2);
                    }}
                  >
                    <div className="heading" style={{ marginBottom: "5px" }}>
                      <div
                        className="left"
                        onClick={() => {
                          // console.log("g");

                          setPet("Cat");
                          setOverallChoice({ pet: "Cat" });
                        }}
                      >
                        <div className="selection-circle">
                          {petChoice == 2 && <div className="dot"></div>}
                        </div>
                        <div style={{ display: "flex", gap: "2px" }}>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Cat.png"
                            alt=""
                            style={{ width: "25px", height: "25px" }}
                          />
                          <span style={{ fontWeight: "500" }}>
                            Cat{" "}
                            <small style={{ fontSize: "13px" }}>
                              {" "}
                              <br />
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* dropdown menu */}
              <div>
                <select
                  id="dropdown"
                  value={selectedOption}
                  onChange={handleSelectChange}
                  style={{
                    width: "27rem",
                    height: "2.5rem",
                    borderRadius: "0.5rem",
                    border: "1px solid #CCC",
                    padding: "0 1rem 0 1rem",
                    fontSize: "1rem",
                    color: "var(--Brand, #FF5E95)",
                    marginBottom: "1rem 0",
                    appearance: "none", // Hide default dropdown arrow
                    // backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3E%3Cpath d='M8.221 11.778a1 1 0 0 1-1.414 0L1.343 5.436A1 1 0 1 1 2.757 4.022L8 9.267l4.243-4.244a1 1 0 1 1 1.414 1.414L8.707 11.78a1 1 0 0 1-1.414 0z'/%3E%3C/svg%3E")`,
                    backgroundImage: `url(${dropDown})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "right 1rem top 50%", // Adjust padding here
                    backgroundSize: "1rem",
                  }}
                >
                  <option value="option1">Bath & Brush Subscription</option>
                  <option value="option2">Haircut & Styling</option>
                  <option value="option3">Bath, Full haircut & Styling</option>
                  {/* <option value="Option 4">Option 4</option> */}
                </select>

                {selectedOption === "option1" && (
                  <div>
                    {/* Content for Option 1 */}
                    <div
                      className="subscription_packages_grooming"
                      style={{
                        padding: "1rem 0",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        className="subscription_box"
                        onClick={handleDivClick1}
                        style={{
                          width: "6rem",
                          height: "8.9rem",
                          border: isClicked1
                            ? "1px solid #FF5E94"
                            : "1px solid #CCC",
                          borderRadius: "1rem",
                          marginRight: "1rem",
                          alignItems: "center",
                          // justifyContent:"center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {isClicked1 ? (
                          <div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#FF5E94",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                color: "#fff",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 8px",
                              }}
                            >
                              You Save ₹799
                            </div>
                            {/* <img src={PinkTick} style={{width:"1rem", height:"1rem", }}/> */}
                          </div>
                        ) : (
                          <div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#ccc",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                color: " #5C5C5C",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                              }}
                            >
                              You Save ₹799
                            </div>
                          </div>
                        )}
                        <img
                          src={DogImage}
                          style={{
                            height: "2.5rem",
                            width: "2.5rem",
                            margin: "0.5rem 0 0.5rem 0",
                            // margin: "0.4rem 2.2rem 0.5rem 2rem",
                          }}
                        />
                        <div
                          className="subscription_box_number"
                          style={{
                            color: "#3f414e",
                            fontFamily: "Lato",
                            fontSize: "0.8rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            // margin: "0 1.2rem",
                          }}
                        >
                          1 Session
                        </div>
                        <div
                          className="subscription_box_amount"
                          style={{
                            color: "#3f414e",
                            fontFamily: "Lato",
                            fontSize: "1.2rem",
                            fontStyle: "normal",
                            fontWeight: "800",
                            lineHeight: "normal",
                            // margin: "0 1.2rem",
                          }}
                        >
                          ₹ 799/-
                        </div>
                        <div
                          className="subscription_box_session"
                          style={{
                            color: "#fd7a98",
                            fontFamily: "Lato",
                            fontSize: "0.8rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            // margin: "0 1rem",
                          }}
                        >
                          Per Session
                        </div>
                      </div>

                      <div
                        className="subscription_box"
                        onClick={handleDivClick2}
                        style={{
                          width: "6rem",
                          height: "8.9rem",
                          border: isClicked2
                            ? "1px solid #FF5E94"
                            : "1px solid #CCC",
                          borderRadius: "1rem",
                          marginRight: "1rem",
                          alignItems: "center",
                          // justifyContent:"center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {isClicked2 ? (
                          <div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#FF5E94",
                              display: "flex",
                              flexDirection: "row",

                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                color: "#fff",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 8px",
                              }}
                            >
                              You Save ₹899
                            </div>
                          </div>
                        ) : (
                          <div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#ccc",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                color: "#5C5C5C",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                              }}
                            >
                              You Save ₹899
                            </div>
                          </div>
                        )}
                        <img
                          src={dogBath}
                          style={{
                            height: "2.5rem",
                            width: "2.5rem",
                            // margin: "0.4rem 2rem 0.5rem 1.7rem",
                            margin: "0.5rem 0 0.5rem 0",
                          }}
                        />
                        <div
                          className="subscription_box_number"
                          style={{
                            color: "#3f414e",
                            fontFamily: "Lato",
                            fontSize: "0.8rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            // margin: "0 1.2rem",
                          }}
                        >
                          3 Session
                        </div>
                        <div
                          className="subscription_box_amount"
                          style={{
                            color: "#3f414e",
                            fontFamily: "Lato",
                            fontSize: "1.2rem",
                            fontStyle: "normal",
                            fontWeight: "800",
                            lineHeight: "normal",
                            // margin: "0 1.2rem",
                          }}
                        >
                          ₹ 699/-
                        </div>
                        <div
                          className="subscription_box_session"
                          style={{
                            color: "#fd7a98",
                            fontFamily: "Lato",
                            fontSize: "0.8rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            // margin: "0 1rem",
                          }}
                        >
                          Per Session
                        </div>
                      </div>

                      <div
                        className="subscription_box"
                        onClick={handleDivClick3}
                        style={{
                          width: "6rem",
                          height: "8.9rem",
                          border: isClicked3
                            ? "1px solid #FF5E94"
                            : "1px solid #CCC",
                          borderRadius: "1rem",
                          marginRight: "1rem",
                          alignItems: "center",
                          // justifyContent:"center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {isClicked3 ? (
                          <div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#FF5E94",
                              display: "flex",
                              flexDirection: "row",

                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                color: "#fff",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 8px",
                              }}
                            >
                              You Save ₹949
                            </div>
                          </div>
                        ) : (
                          <div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#CCC",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                color: "#5C5C5C",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 8px",
                              }}
                            >
                              You Save ₹949
                            </div>
                          </div>
                        )}

                        <img
                          src={dog3}
                          style={{
                            height: "2.5rem",
                            width: "2.5rem",
                            margin: "0.5rem 0 0.5rem 0",
                            // margin: "0.4rem 2.2rem 0.5rem 1.7rem",
                          }}
                        />
                        <div
                          className="subscription_box_number"
                          style={{
                            color: "#3f414e",
                            fontFamily: "Lato",
                            fontSize: "0.8rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            // margin: "0 1.2rem",
                          }}
                        >
                          6 Session
                        </div>
                        <div
                          className="subscription_box_amount"
                          style={{
                            color: "#3f414e",
                            fontFamily: "Lato",
                            fontSize: "1.2rem",
                            fontStyle: "normal",
                            fontWeight: "800",
                            lineHeight: "normal",
                            // margin: "0 1.2rem",
                          }}
                        >
                          ₹ 649/-
                        </div>
                        <div
                          className="subscription_box_session"
                          style={{
                            color: "#fd7a98",
                            fontFamily: "Lato",
                            fontSize: "0.8rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            // margin: "0 1rem",
                          }}
                        >
                          Per Session
                        </div>
                      </div>

                      <div
                        className="subscription_box"
                        onClick={handleDivClick4}
                        style={{
                          width: "6rem",
                          height: "8.9rem",
                          border: isClicked4
                            ? "1px solid #FF5E94"
                            : "1px solid #CCC",
                          borderRadius: "1rem",
                          marginRight: "1rem",
                          alignItems: "center",
                          // justifyContent:"center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {isClicked4 ? (
                          <div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#FF5E94",
                              display: "flex",
                              flexDirection: "row",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                color: "#fff",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 8px",
                              }}
                            >
                              You Save ₹999
                            </div>
                          </div>
                        ) : (
                          <div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#ccc",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                color: " #5C5C5C",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                              }}
                            >
                              You Save ₹999
                            </div>
                          </div>
                        )}

                        <img
                          src={dog4}
                          style={{
                            height: "2.5rem",
                            width: "2.5rem",
                            margin: "0.5rem 0 0.5rem 0",
                            // margin: "0.4rem 2.2rem 0.5rem 1.7rem",
                          }}
                        />
                        <div
                          className="subscription_box_number"
                          style={{
                            color: "#3f414e",
                            fontFamily: "Lato",
                            fontSize: "0.8rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            // margin: "0 1.2rem",
                          }}
                        >
                          12 Session
                        </div>
                        <div
                          className="subscription_box_amount"
                          style={{
                            color: "#3f414e",
                            fontFamily: "Lato",
                            fontSize: "1.2rem",
                            fontStyle: "normal",
                            fontWeight: "800",
                            lineHeight: "normal",
                            // margin: "0 1.2rem",
                          }}
                        >
                          ₹ 599/-
                        </div>
                        <div
                          className="subscription_box_session"
                          style={{
                            color: "#fd7a98",
                            fontFamily: "Lato",
                            fontSize: "0.8rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            // margin: "0 1rem",
                          }}
                        >
                          Per Session
                        </div>
                      </div>
                    </div>
                    <div
                      className="subscription_packages_grooming"
                      style={{
                        padding: "1rem 0",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        className="subscription_box"
                        onClick={handleDivClick5}
                        style={{
                          width: "6rem",
                          height: "8.9rem",
                          border: isClicked5
                            ? "1px solid #FF5E94"
                            : "1px solid #CCC",
                          borderRadius: "1rem",
                          marginRight: "1rem",
                          alignItems: "center",
                          // justifyContent:"center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {isClicked5 ? (
                          <div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#FF5E94",
                              display: "flex",
                              flexDirection: "row",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                color: "#fff",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 2px",
                              }}
                            >
                              You Save ₹1,199
                            </div>
                          </div>
                        ) : (
                          <div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#ccc",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                color: "#5C5C5C",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                              }}
                            >
                              You Save ₹1,199
                            </div>
                          </div>
                        )}

                        <img
                          src={dog5}
                          style={{
                            height: "2.5rem",
                            width: "2.5rem",
                            margin: "0.5rem 0 0.5rem 0",
                          }}
                        />
                        <div
                          className="subscription_box_number"
                          style={{
                            color: "#3f414e",
                            fontFamily: "Lato",
                            fontSize: "0.8rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            // margin: "0 1.2rem",
                          }}
                        >
                          24 Session
                        </div>
                        <div
                          className="subscription_box_amount"
                          style={{
                            color: "#3f414e",
                            fontFamily: "Lato",
                            fontSize: "1.2rem",
                            fontStyle: "normal",
                            fontWeight: "800",
                            lineHeight: "normal",
                            // margin: "0 1.2rem",
                          }}
                        >
                          ₹ 475/-
                        </div>
                        <div
                          className="subscription_box_session"
                          style={{
                            color: "#fd7a98",
                            fontFamily: "Lato",
                            fontSize: "0.8rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            // margin: "0 1rem",
                          }}
                        >
                          Per Session
                        </div>
                      </div>

                      <div
                        className="subscription_box"
                        style={{
                          width: "19rem",
                          height: "8.9rem",
                          border: "1px solid #CCC",
                          borderRadius: "1rem",
                          marginRight: "1rem",
                          marginLeft: "1rem",
                          background: "#FAFAFA",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div className="total_img" style={{ flex: "0.4" }}>
                          <div
                            className="subscription_box_number"
                            style={{
                              color: "#3f414e",
                              fontFamily: "Lato",
                              fontSize: "1rem",
                              fontStyle: "normal",
                              fontWeight: "700",
                              lineHeight: "normal",
                              margin: "1rem 0.5rem 0.5rem 1rem",
                            }}
                          >
                            {sessionNumber} Session
                          </div>
                          <img
                            src={dogBath}
                            style={{
                              height: "4.5rem",
                              width: "4.5rem",
                              margin: "0.5rem 2rem 0.5rem 1rem",
                            }}
                          />
                        </div>

                        <div className="total_calc" style={{ flex: "0.6", marginLeft: "2rem" }}>
                          <div
                            className="subscription_box_number"
                            style={{
                              color: "#727272",
                              fontFamily: "Lato",
                              fontSize: "1rem",
                              fontStyle: "normal",
                              fontWeight: "800",
                              lineHeight: "normal",
                              margin: "1.5rem 0rem 0.5rem 0rem",
                              textDecoration: "strikethrough",
                            }}
                          >
                            1 Session ₹799 x {sessionNumber}
                          </div>
                          <div
                            className="subscription_box_number"
                            style={{
                              color: "#727272",
                              fontFamily: "Lato",
                              fontSize: "1rem",
                              fontStyle: "normal",
                              fontWeight: "700",
                              lineHeight: "normal",
                              margin: "0.5rem 0rem ",
                            }}
                          >
                            <span style={{ color: "#11BC18" }}>
                              1 Session ₹{packageAmount}
                            </span>{" "}
                            x {sessionNumber}
                          </div>
                          <hr style={{ width: "8rem", margin: "0 0rem" }} />
                          <div
                            className="subscription_box_session"
                            style={{
                              color: "#fd7a98",
                              fontFamily: "Lato",
                              fontSize: "1rem",
                              fontStyle: "normal",
                              fontWeight: "800",
                              lineHeight: "normal",
                              margin: "0.5rem -1rem",
                            }}
                          >
                            You Save extra :{" "}
                            <span style={{ color: "var(--Brand, #FF5E95)" }}>
                              ₹ {saveAmount}
                            </span>
                          </div>
                        </div>
                        <style></style>
                      </div>
                    </div>
                  </div>
                )}

                {selectedOption === "option2" && (
                  <div>
                    {/* Content for Option 2 */}
                    <div
                      className="subscription_packages_grooming"
                      style={{
                        padding: "1rem 0",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        className="subscription_box"
                        onClick={handleDivClick6}
                        style={{
                          width: "6rem",
                          height: "8.9rem",
                          border: isClicked6
                            ? "1px solid #FF5E94"
                            : "1px solid #CCC",
                          borderRadius: "1rem",
                          marginRight: "1rem",
                          alignItems: "center",
                          // justifyContent:"center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {isClicked6 ? (
                          <div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#FF5E94",
                              display: "flex",
                              flexDirection: "row",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                color: "#fff",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 2px",
                              }}
                            >
                              You Save ₹1,249
                            </div>
                          </div>
                        ) : (
                          <div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#ccc",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                color: "#5C5C5C",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                              }}
                            >
                              You Save ₹1,249
                            </div>
                          </div>
                        )}

                        <img
                          src={dog4}
                          style={{
                            height: "2.5rem",
                            width: "2.5rem",
                            margin: "0.5rem 0 0.5rem 0",
                            // margin: "0.4rem 2.2rem 0.5rem 2rem",
                          }}
                        />
                        <div
                          className="subscription_box_number"
                          style={{
                            color: "#3f414e",
                            fontFamily: "Lato",
                            fontSize: "0.8rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            // margin: "0 1.2rem",
                          }}
                        >
                          1 Session
                        </div>
                        <div
                          className="subscription_box_amount"
                          style={{
                            color: "#3f414e",
                            fontFamily: "Lato",
                            fontSize: "1.2rem",
                            fontStyle: "normal",
                            fontWeight: "800",
                            lineHeight: "normal",
                            // margin: "0 1.2rem",
                          }}
                        >
                          ₹1,249
                        </div>
                        <div
                          className="subscription_box_session"
                          style={{
                            color: "#fd7a98",
                            fontFamily: "Lato",
                            fontSize: "0.8rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            // margin: "0 1rem",
                          }}
                        >
                          Per Session
                        </div>
                      </div>

                      <div
                        className="subscription_box"
                        onClick={handleDivClick7}
                        style={{
                          width: "6rem",
                          height: "8.9rem",
                          border: isClicked1
                            ? "1px solid #FF5E94"
                            : "1px solid #CCC",
                          borderRadius: "1rem",
                          marginRight: "1rem",
                          alignItems: "center",
                          // justifyContent:"center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {isClicked7 ? (
                          <div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#FF5E94",
                              display: "flex",
                              flexDirection: "row",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                color: "#fff",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 2px",
                              }}
                            >
                              You Save ₹1,249
                            </div>
                          </div>
                        ) : (
                          <div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#ccc",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                color: "#5C5C5C",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 2px",
                              }}
                            >
                              You Save ₹1,249
                            </div>
                          </div>
                        )}
                        <img
                          src={dog6}
                          style={{
                            height: "2.5rem",
                            width: "2.5rem",
                            margin: "0.5rem 0 0.5rem 0",
                            // margin: "0.4rem 2rem 0.5rem 1.7rem",
                          }}
                        />
                        <div
                          className="subscription_box_number"
                          style={{
                            color: "#3f414e",
                            fontFamily: "Lato",
                            fontSize: "0.8rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            // margin: "0 1.2rem",
                          }}
                        >
                          3 Session
                        </div>
                        <div
                          className="subscription_box_amount"
                          style={{
                            color: "#3f414e",
                            fontFamily: "Lato",
                            fontSize: "1.2rem",
                            fontStyle: "normal",
                            fontWeight: "800",
                            lineHeight: "normal",
                            // margin: "0 0.7rem",
                          }}
                        >
                          ₹ 1,149/-
                        </div>
                        <div
                          className="subscription_box_session"
                          style={{
                            color: "#fd7a98",
                            fontFamily: "Lato",
                            fontSize: "0.8rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            // margin: "0 1rem",
                          }}
                        >
                          Per Session
                        </div>
                      </div>

                      <div
                        className="subscription_box"
                        onClick={handleDivClick8}
                        style={{
                          width: "6rem",
                          height: "8.9rem",
                          border: isClicked8
                            ? "1px solid #FF5E94"
                            : "1px solid #CCC",
                          borderRadius: "1rem",
                          marginRight: "1rem",
                          alignItems: "center",
                          // justifyContent:"center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {isClicked8 ? (
                          <div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#FF5E94",
                              display: "flex",
                              flexDirection: "row",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                color: "#fff",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 2px",
                              }}
                            >
                              You Save ₹1,349
                            </div>
                          </div>
                        ) : (
                          <div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#ccc",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                color: "#5C5C5C",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                              }}
                            >
                              You Save ₹1,349
                            </div>
                          </div>
                        )}
                        <img
                          src={dog7}
                          style={{
                            height: "2.5rem",
                            width: "2.5rem",
                            margin: "0.5rem 0 0.5rem 0",
                          }}
                        />
                        <div
                          className="subscription_box_number"
                          style={{
                            color: "#3f414e",
                            fontFamily: "Lato",
                            fontSize: "0.8rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            // margin: "0 1.2rem",
                          }}
                        >
                          6 Session
                        </div>
                        <div
                          className="subscription_box_amount"
                          style={{
                            color: "#3f414e",
                            fontFamily: "Lato",
                            fontSize: "1.2rem",
                            fontStyle: "normal",
                            fontWeight: "800",
                            lineHeight: "normal",
                            // margin: "0 0.7rem",
                          }}
                        >
                          ₹ 1,049/-
                        </div>
                        <div
                          className="subscription_box_session"
                          style={{
                            color: "#fd7a98",
                            fontFamily: "Lato",
                            fontSize: "0.8rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            // margin: "0 1rem",
                          }}
                        >
                          Per Session
                        </div>
                      </div>

                      <div
                        className="subscription_box"
                        onClick={handleDivClick9}
                        style={{
                          width: "6rem",
                          height: "8.9rem",
                          border: isClicked9
                            ? "1px solid #FF5E94"
                            : "1px solid #CCC",
                          borderRadius: "1rem",
                          marginRight: "1rem",
                          alignItems: "center",
                          // justifyContent:"center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {isClicked9 ? (
                          <div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#FF5E94",
                              display: "flex",
                              flexDirection: "row",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                color: "#fff",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 2px",
                              }}
                            >
                              You Save ₹1,449
                            </div>
                          </div>
                        ) : (
                          <div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#ccc",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                color: "#5C5C5C",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                              }}
                            >
                              You Save ₹1,449
                            </div>
                          </div>
                        )}
                        <img
                          src={dog8}
                          style={{
                            height: "2.5rem",
                            width: "2.5rem",
                            margin: "0.5rem 0 0.5rem 0",
                            // margin: "0.4rem 2.2rem 0.5rem 1.7rem",
                          }}
                        />
                        <div
                          className="subscription_box_number"
                          style={{
                            color: "#3f414e",
                            fontFamily: "Lato",
                            fontSize: "0.8rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            // margin: "0 1.2rem",
                          }}
                        >
                          12 Session
                        </div>
                        <div
                          className="subscription_box_amount"
                          style={{
                            color: "#3f414e",
                            fontFamily: "Lato",
                            fontSize: "1.2rem",
                            fontStyle: "normal",
                            fontWeight: "800",
                            lineHeight: "normal",
                            // margin: "0 1.2rem",
                          }}
                        >
                          ₹ 949/-
                        </div>
                        <div
                          className="subscription_box_session"
                          style={{
                            color: "#fd7a98",
                            fontFamily: "Lato",
                            fontSize: "0.8rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            // margin: "0 1rem",
                          }}
                        >
                          Per Session
                        </div>
                      </div>
                    </div>

                    <div
                      className="subscription_box"
                      style={{
                        width: "19rem",
                        height: "8.9rem",
                        border: "1px solid #CCC",
                        borderRadius: "1rem",
                        marginRight: "1rem",
                        marginLeft: "7.8rem",
                        background: "#FAFAFA",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div className="total_img" style={{ flex: "0.4" }}>
                        <div
                          className="subscription_box_number"
                          style={{
                            color: "#3f414e",
                            fontFamily: "Lato",
                            fontSize: "1rem",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "normal",
                            margin: "1rem 0.5rem 0.5rem 1rem",
                          }}
                        >
                          {sessionNumber} Session
                        </div>
                        <img
                          src={dogBath}
                          style={{
                            height: "4.5rem",
                            width: "4.5rem",
                            margin: "0.5rem 2rem 0.5rem 1rem",
                          }}
                        />
                      </div>

                      <div className="total_calc" style={{ flex: "0.6", marginLeft: "2rem" }}>
                        <div
                          className="subscription_box_number"
                          style={{
                            color: "#727272",
                            fontFamily: "Lato",
                            fontSize: "1rem",
                            fontStyle: "normal",
                            fontWeight: "800",
                            lineHeight: "normal",
                            margin: "1.5rem 0rem 0.5rem 0rem",
                            textDecoration: "strikethrough",
                          }}
                        >
                          1 Session ₹1249 x {sessionNumber}
                        </div>
                        <div
                          className="subscription_box_number"
                          style={{
                            color: "#727272",
                            fontFamily: "Lato",
                            fontSize: "1rem",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "normal",
                            margin: "0.5rem 0rem ",
                          }}
                        >
                          <span style={{ color: "#11BC18" }}>
                            1 Session ₹{packageAmount}
                          </span>{" "}
                          x {sessionNumber}
                        </div>
                        <hr style={{ width: "8rem", margin: "0 0rem" }} />
                        <div
                          className="subscription_box_session"
                          style={{
                            color: "#fd7a98",
                            fontFamily: "Lato",
                            fontSize: "1rem",
                            fontStyle: "normal",
                            fontWeight: "800",
                            lineHeight: "normal",
                            margin: "0.5rem -1rem",
                          }}
                        >
                          You Save extra :{" "}
                          <span style={{ color: "var(--Brand, #FF5E95)" }}>
                            {" "}
                            ₹{saveAmount}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {selectedOption === "option3" && (
                  <div>
                    {/* Content for Option 3 */}
                    <div
                      className="subscription_packages_grooming"
                      style={{
                        padding: "1rem 0",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        className="subscription_box"
                        onClick={handleDivClick10}
                        style={{
                          width: "6rem",
                          height: "8.9rem",
                          border: isClicked10
                            ? "1px solid #FF5E94"
                            : "1px solid #CCC",
                          borderRadius: "1rem",
                          marginRight: "1rem",
                          alignItems: "center",
                          // justifyContent:"center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {isClicked10 ? (
                          <div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#FF5E94",
                              display: "flex",
                              flexDirection: "row",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                color: "#fff",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 2px",
                              }}
                            >
                              You Save ₹1,649
                            </div>
                          </div>
                        ) : (
                          <div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#ccc",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                color: "#5C5C5C",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                              }}
                            >
                              You Save ₹1,649
                            </div>
                          </div>
                        )}
                        <img
                          src={dog4}
                          style={{
                            height: "2.5rem",
                            width: "2.5rem",
                            margin: "0.5rem 0 0.5rem 0",
                            // margin: "0.4rem 2.2rem 0.5rem 2rem",
                          }}
                        />
                        <div
                          className="subscription_box_number"
                          style={{
                            color: "#3f414e",
                            fontFamily: "Lato",
                            fontSize: "0.8rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            // margin: "0 1.2rem",
                          }}
                        >
                          1 Session
                        </div>
                        <div
                          className="subscription_box_amount"
                          style={{
                            color: "#3f414e",
                            fontFamily: "Lato",
                            fontSize: "1.2rem",
                            fontStyle: "normal",
                            fontWeight: "800",
                            lineHeight: "normal",
                            // margin: "0 1.2rem",
                          }}
                        >
                          ₹1,649
                        </div>
                        <div
                          className="subscription_box_session"
                          style={{
                            color: "#fd7a98",
                            fontFamily: "Lato",
                            fontSize: "0.8rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            // margin: "0 1rem",
                          }}
                        >
                          Per Session
                        </div>
                      </div>

                      <div
                        className="subscription_box"
                        onClick={handleDivClick11}
                        style={{
                          width: "6rem",
                          height: "8.9rem",
                          border: isClicked11
                            ? "1px solid #FF5E94"
                            : "1px solid #CCC",
                          borderRadius: "1rem",
                          marginRight: "1rem",
                          alignItems: "center",
                          // justifyContent:"center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {isClicked11 ? (
                          <div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#FF5E94",
                              display: "flex",
                              flexDirection: "row",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                color: "#fff",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 8px",
                              }}
                            >
                              You Save ₹949
                            </div>
                          </div>
                        ) : (
                          <div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#ccc",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                color: "#5C5C5C",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 8px",
                              }}
                            >
                              You Save ₹949
                            </div>
                          </div>
                        )}
                        <img
                          src={dog9}
                          style={{
                            height: "2.5rem",
                            width: "2.5rem",
                            margin: "0.5rem 0 0.5rem 0",
                            // margin: "0.4rem 2rem 0.5rem 1.7rem",
                          }}
                        />
                        <div
                          className="subscription_box_number"
                          style={{
                            color: "#3f414e",
                            fontFamily: "Lato",
                            fontSize: "0.8rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            // margin: "0 1.2rem",
                          }}
                        >
                          3 Session
                        </div>
                        <div
                          className="subscription_box_amount"
                          style={{
                            color: "#3f414e",
                            fontFamily: "Lato",
                            fontSize: "1.2rem",
                            fontStyle: "normal",
                            fontWeight: "800",
                            lineHeight: "normal",
                            // margin: "0 0.7rem",
                          }}
                        >
                          ₹ 1,549/-
                        </div>
                        <div
                          className="subscription_box_session"
                          style={{
                            color: "#fd7a98",
                            fontFamily: "Lato",
                            fontSize: "0.8rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            // margin: "0 1rem",
                          }}
                        >
                          Per Session
                        </div>
                      </div>

                      <div
                        className="subscription_box"
                        onClick={handleDivClick12}
                        style={{
                          width: "6rem",
                          height: "8.9rem",
                          border: isClicked12
                            ? "1px solid #FF5E94"
                            : "1px solid #CCC",
                          borderRadius: "1rem",
                          marginRight: "1rem",
                          alignItems: "center",
                          // justifyContent:"center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {isClicked12 ? (
                          <div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#FF5E94",
                              display: "flex",
                              flexDirection: "row",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                color: "#fff",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 2px",
                              }}
                            >
                              You Save ₹1,049
                            </div>
                          </div>
                        ) : (
                          <div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#ccc",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                color: "#5C5C5C",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 2px",
                              }}
                            >
                              You Save ₹1,049
                            </div>
                          </div>
                        )}
                        <img
                          src={dog10}
                          style={{
                            height: "2.5rem",
                            width: "2.5rem",
                            margin: "0.5rem 0 0.5rem 0",
                            // margin: "0.4rem 2.2rem 0.5rem 1.7rem",
                          }}
                        />
                        <div
                          className="subscription_box_number"
                          style={{
                            color: "#3f414e",
                            fontFamily: "Lato",
                            fontSize: "0.8rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            // margin: "0 1.2rem",
                          }}
                        >
                          6 Session
                        </div>
                        <div
                          className="subscription_box_amount"
                          style={{
                            color: "#3f414e",
                            fontFamily: "Lato",
                            fontSize: "1.2rem",
                            fontStyle: "normal",
                            fontWeight: "800",
                            lineHeight: "normal",
                            // margin: "0 0.7rem",
                          }}
                        >
                          ₹ 1,449/-
                        </div>
                        <div
                          className="subscription_box_session"
                          style={{
                            color: "#fd7a98",
                            fontFamily: "Lato",
                            fontSize: "0.8rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            // margin: "0 1rem",
                          }}
                        >
                          Per Session
                        </div>
                      </div>

                      <div
                        className="subscription_box"
                        onClick={handleDivClick13}
                        style={{
                          width: "6rem",
                          height: "8.9rem",
                          border: isClicked13
                            ? "1px solid #FF5E94"
                            : "1px solid #CCC",
                          borderRadius: "1rem",
                          marginRight: "1rem",
                          alignItems: "center",
                          // justifyContent:"center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {isClicked13 ? (
                          <div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#FF5E94",
                              display: "flex",
                              flexDirection: "row",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                color: "#fff",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 2px",
                              }}
                            >
                              You Save ₹1,149
                            </div>
                          </div>
                        ) : (
                          <div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#ccc",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                color: "#5C5C5C",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 2px",
                              }}
                            >
                              You Save ₹1,149
                            </div>
                          </div>
                        )}
                        <img
                          src={DogImage}
                          style={{
                            height: "2.5rem",
                            width: "2.5rem",
                            margin: "0.5rem 0 0.5rem 0",
                            // margin: "0.4rem 2.2rem 0.5rem 1.7rem",
                          }}
                        />
                        <div
                          className="subscription_box_number"
                          style={{
                            color: "#3f414e",
                            fontFamily: "Lato",
                            fontSize: "0.8rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            // margin: "0 1.2rem",
                          }}
                        >
                          12 Session
                        </div>
                        <div
                          className="subscription_box_amount"
                          style={{
                            color: "#3f414e",
                            fontFamily: "Lato",
                            fontSize: "1.2rem",
                            fontStyle: "normal",
                            fontWeight: "800",
                            lineHeight: "normal",
                            // margin: "0 0.7rem",
                          }}
                        >
                          ₹ 1,349/-
                        </div>
                        <div
                          className="subscription_box_session"
                          style={{
                            color: "#fd7a98",
                            fontFamily: "Lato",
                            fontSize: "0.8rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            // margin: "0 1rem",
                          }}
                        >
                          Per Session
                        </div>
                      </div>
                    </div>

                    <div
                      className="subscription_box"
                      style={{
                        width: "19rem",
                        height: "8.9rem",
                        border: "1px solid #CCC",
                        borderRadius: "1rem",
                        marginRight: "1rem",
                        marginLeft: "7.8rem",
                        background: "#FAFAFA",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div className="total_img" style={{ flex: "0.4" }}>
                        <div
                          className="subscription_box_number"
                          style={{
                            color: "#3f414e",
                            fontFamily: "Lato",
                            fontSize: "1rem",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "normal",
                            margin: "1rem 0.5rem 0.5rem 1rem",
                          }}
                        >
                          {sessionNumber} session
                        </div>
                        <img
                          src={dogBath}
                          style={{
                            height: "4.5rem",
                            width: "4.5rem",
                            margin: "0.5rem 2rem 0.5rem 1rem",
                          }}
                        />
                      </div>

                      <div className="total_calc" style={{ flex: "0.6", marginLeft: "2rem" }}>
                        <div
                          className="subscription_box_number"
                          style={{
                            color: "#727272",
                            fontFamily: "Lato",
                            fontSize: "1rem",
                            fontStyle: "normal",
                            fontWeight: "800",
                            lineHeight: "normal",
                            margin: "1.5rem 0rem 0.5rem 0rem",
                            textDecoration: "strikethrough",
                          }}
                        >
                          1 Session ₹1649 x {sessionNumber}
                        </div>
                        <div
                          className="subscription_box_number"
                          style={{
                            color: "#727272",
                            fontFamily: "Lato",
                            fontSize: "1rem",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "normal",
                            margin: "0.5rem 0rem ",
                          }}
                        >
                          <span style={{ color: "#11BC18" }}>
                            1 Session ₹{packageAmount}
                          </span>{" "}
                          x {sessionNumber}
                        </div>
                        <hr style={{ width: "8rem", margin: "0 0rem" }} />
                        <div
                          className="subscription_box_session"
                          style={{
                            color: "#fd7a98",
                            fontFamily: "Lato",
                            fontSize: "1rem",
                            fontStyle: "normal",
                            fontWeight: "800",
                            lineHeight: "normal",
                            margin: "0.5rem -1rem",
                          }}
                        >
                          You Save extra :{" "}
                          <span style={{ color: "var(--Brand, #FF5E95)" }}>
                            {" "}
                            ₹{saveAmount}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="grooming-new-form-section1-right">
              <div style={{ width: "100%" }}>
                <ImageSlider images={images} />
              </div>
              {/* <div
                className="grooming-new-form-section1-right-include-container"
                style={{ width: "80%" }}
              >
                <div
                  className="grooming-new-form-section1-right-include-text"
                  style={{ width: "100%", textAlign: "start" }}
                >
                  Includes
                </div>
                {serviceChoice === "BATH & BRUSH" ? (
                  <div className="grooming-new-form-section1-right-include-list-container">
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        gap: "10%",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>Bath</div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>Nail Trim</div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>Burshing</div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>Shampoo</div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>Scrubbing</div>
                        </div>
                      </div>
                      <div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>
                            Coat Conditioner
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>
                            Ear Cleaning or Flushing
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>Blow Dry</div>
                        </div>
                     
                      </div>
                    </div>
                  </div>
                ) : null}

                {serviceChoice === "BATH & BRUSH (With tick treatment)" ? (
                  <div className="grooming-new-form-section1-right-include-list-container">
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        gap: "10%",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>Bath</div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>Nail Trim</div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>Brushing</div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>
                            Shampoo(vet approved)
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>Scrubbing</div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>Blow Dry</div>
                        </div>
                      </div>
                      <div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>
                            Coat Conditioner
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>
                            Ear Cleaning or Flushing
                          </div>
                        </div>

                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>
                            Plus tick treatment
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {serviceChoice === "HAIRCUT & STYLING" ? (
                  <div className="grooming-new-form-section1-right-include-list-container">
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        gap: "10%",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>Full Haircut</div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>
                            Breed Specific Haircut
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>
                            Fanny Hair Trim
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>Paw Hair Trim</div>
                        </div>
                      </div>
                      <div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>
                            Tail Hair Shaping
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>Brushing</div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                ) : null}

                {serviceChoice === "BATH & FULL HAIRCUT" ? (
                  <div className="grooming-new-form-section1-right-include-list-container">
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        gap: "10%",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>Bath</div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>Nail Trim</div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>Burshing</div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>Shampoo</div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>Scrubbing</div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>
                            Coat Conditioner
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>
                            Ear Cleaning or Flushing
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>Blow Dry</div>
                        </div>
                      </div>
                      <div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>Full Haircut</div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>
                            Breed Specific Haircut
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>
                            Fanny Hair Trim
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>Paw Hair Trim</div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>
                            Tail Hair Shaping
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>Brushing</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {serviceChoice === "MASSAGE & BATH & FULL HAIRCUT & STYLING" ? (
                  <div className="grooming-new-form-section1-right-include-list-container">
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        gap: "10%",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>
                            Oil massage of Feet and back
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>Bath</div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>Nail Trim</div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>Burshing</div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>Shampoo</div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>Scrubbing</div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>
                            Coat Conditioner
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>
                            Ear Cleaning or Flushing
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>Blow Dry</div>
                        </div>
                      </div>
                      <div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>Full Haircut</div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>
                            Breed Specific Haircut
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>
                            Fanny Hair Trim
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>Paw Hair Trim</div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>
                            Tail Hair Shaping
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <li></li>
                          </div>
                          <div style={{ fontSize: "15px" }}>Brushing</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div> */}

              <div
                className="grooming-new-form-section1-right-next-button-container"
                style={{ marginTop: "40px" }}
              >
                <div
                  onClick={() => {
                    groomingFormDesktopHandler1();
                    // displayLocation();
                  }}
                  className="grooming-new-form-section1-right-next-button"
                  style={{ width: "100%", color: "black" }}
                >
                  Next
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            {/* <div
              className="grooming-new-form-section1-right-include-text"
              style={{ width: "100%", textAlign: "start" }}
            >
              Includes
            </div> */}
            {serviceChoice === "BATH & BRUSH" ? (
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "5px",
                }}
              >
                {/* Includes Bath, Shampoo, Blow Dry, Brushing, Nail Trim, Ear Cleaning or Flushing, Scissoring/Trimming Feet Hair, Teeth Brushing */}
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Includes Bath</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Shampoo</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Blow Dry</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Brushing</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Nail Trim</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>
                    Ear Cleaning or Flushing
                  </div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>
                    Scissoring/Trimming Feet Hair
                  </div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Teeth Brushing</div>
                </div>
              </div>
            ) : null}
            {serviceChoice === "BATH & BRUSH (With tick treatment)" ? (
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "5px",
                }}
              >
                {/* Includes Bath, Nail Trim, Brushing, Shampoo(vet approved), Scrubbing, Coat Conditioner, Ear Cleaning or Flushing, Blow Dry, Plus Tick Treatment */}
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Includes Bath</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Nail Trim</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Brushing</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Shampoo(vet approved)</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Scrubbing</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Coat Conditioner</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>
                    Ear Cleaning or Flushing
                  </div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Blow Dry</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Plus Tick Treatment</div>
                </div>
              </div>
            ) : null}
            {serviceChoice === "HAIRCUT & STYLING" ? (
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "5px",
                }}
              >
                {/* Includes Full Haircut, Breed Specific Haircut, Fanny Hair Trim, Paw Hair Trim, Tail Hair Shaping, Brushing */}
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Includes Full Haircut</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>
                    {" "}
                    Breed Specific Haircut
                  </div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Fanny Hair Trim</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Paw Hair Trim</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Tail Hair Shaping</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Brushing</div>
                </div>
              </div>
            ) : null}
            {serviceChoice === "BATH & FULL HAIRCUT" ? (
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "5px",
                }}
              >
                {/* Includes Bath, Nail Trim, Brushing, Shampoo, Scrubbing, Coat Conditioner, Ear Cleaning or Flushing, Blow Dry, Full Haircut, Breed Specific Haircut, Fanny Hair Trim, Paw Hair Trim, Tail Hair Shaping */}
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Includes Bath</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Nail Trim</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Brushing</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Shampoo</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Scrubbing</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Coat Conditioner</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>
                    Ear Cleaning or Flushing
                  </div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Blow Dry</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Full Haircut</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Breed Specific Haircut</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}> Fanny Hair Trim</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Paw Hair Trim</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Tail Hair Shaping</div>
                </div>
              </div>
            ) : null}
            {serviceChoice === "MASSAGE & BATH & FULL HAIRCUT & STYLING" ? (
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "5px",
                }}
              >
                {/* Includes  Oil massage of Feet and back, Bath, Nail Trim, Brushing, Shampoo, Scrubbing, Coat Conditioner, Ear Cleaning or Flushing, Blow Dry, Full Haircut, Breed Specific Haircut, Fanny Hair Trim, Paw Hair Trim, Tail Hair Shaping */}
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>
                    Includes Oil massage of Feet and back
                  </div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}> Bath</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Nail Trim</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Brushing</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Shampoo</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Scrubbing</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Coat Conditioner</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>
                    Ear Cleaning or Flushing
                  </div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Blow Dry</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Full Haircut</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Breed Specific Haircut</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}> Fanny Hair Trim</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Paw Hair Trim</div>
                </div>
                <div className="intro-desktop-home-page-points-p1">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>Tail Hair Shaping</div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grooming-new-form-section1-carousel-container">
            <div className="grooming-new-form-section1-carousel-active"></div>
            <div className="grooming-new-form-section1-carousel"></div>
            <div className="grooming-new-form-section1-carousel"></div>
          </div>
        </div>
      ) : null}

      {selectedCarousel == 2 ? (
        <div className="grooming-new-form-section1-main-container">
          <div className="grooming-new-form-section1-main">
            <div className="grooming-new-form-section2-left">
              <div className="grooming-new-form-section3-right-ph-section">
                <div className="grooming-new-form-validation-container-desktop">
                  <div className="grooming-new-form-section3-right-ph-text">
                    Enter Name
                  </div>
                  {newNameValidation ? null : (
                    <div className="home-page-desktop-phone-validation-text">
                      Required
                    </div>
                  )}
                </div>

                <div className="grooming-new-form-section3-right-ph-input-contact">
                  <input
                    onChange={() => {
                      setNewNameValidation(true);
                      setnameValue(true);
                      setNameFill(newNameRef.current.value);
                    }}
                    ref={newNameRef}
                    className="grooming-new-form-desktop-input-container-type1"
                    placeholder="Enter your name"
                    type="text"
                    style={{ padding: "10px" }}
                    value={nameValue ? nameFill : ""}
                  ></input>
                </div>
              </div>

              <div
                className="grooming-new-form-section2-left-date-container"
                style={{ marginTop: "20px" }}
              >
                <div className="grooming-new-form-validation-container-desktop">
                  <div className="grooming-new-form-section3-right-ph-text">
                    Session Date
                  </div>
                  {newDateValidation ? null : (
                    <div className="home-page-desktop-phone-validation-text">
                      Required
                    </div>
                  )}
                </div>
                <div
                  className="grooming-new-form-desktop-input-space1"
                  style={{ position: "relative" }}
                >
                  <input
                    ref={newDateRef}
                    className="grooming-new-form-desktop-input-container-type1"
                    onChange={() => {
                      setStartValue(true);
                      setStartDate(newDateRef.current.value);
                    }}
                    placeholder="DD/MM/YY"
                    type="date"
                    style={{ padding: "10px", fontSize: "16px" }}
                    value={startValue ? startDate : formattedDate}
                  ></input>
                </div>
              </div>

              <div className="grooming-new-form-section2-left-date-text-container">
                {representationDate}{" "}
                <span className="pink-text">{representationDay}</span>
              </div>
            </div>
            <div className="grooming-new-form-section1-right">
              <div className="grooming-new-form-section3-right-ph-section-2">
                <div className="grooming-new-form-section3-right-ph-section-Phone-container">
                  <div>
                    <div className="grooming-new-form-validation-container-desktop">
                      <div className="grooming-new-form-section3-right-ph-text">
                        Phone Number
                      </div>
                      {newPhoneValidation ? null : (
                        <div className="home-page-desktop-phone-validation-text">
                          Required
                        </div>
                      )}
                    </div>
                    <div
                      className="grooming-new-form-section3-right-ph-input-contact"
                      style={{}}
                    >
                      {/* <div> */}
                      <input
                        disabled={isVerified}
                        onChange={() => {
                          setNewPhoneValidation(true);
                          setPhoneValue(true);
                          setPhoneFill(newPhoneRef.current.value);
                        }}
                        ref={newPhoneRef}
                        className="grooming-new-form-desktop-input-container-type1"
                        // {isVerified ? 'grooming-new-form-desktop-input-container-type1-verified' :'grooming-new-form-desktop-input-container-type1'}

                        placeholder="Contact number"
                        type="number"
                        required
                        style={{ padding: "10px", width: "100%" }}
                        value={phoneValue ? phoneFill : null}
                      ></input>
                      {/* </div> */}
                      {/* <div className='resend-otp-desktop' style={{display:"flex",flexDirection:"column"}}>
                                            {
                                                isVerified === true ? (null) : (
                                                    <>
                                                        <button id='send-otp-desk' disabled={isVerified} onClick={() => { getmobileOtp(); setShowEnterOtp(true) ; changeName();setSeconds(33) }} className='send-otp-button-desktop-container' style={{display:"block",borderColor:correctOTP?"white":"red",backgroundColor:phoneFill?.length>=10?"rgb(69, 163, 165)":"",color:phoneFill?.length>=10?"white":"",borderRadius:"10px"}} >
                                                        
                                                        Send OTP 
                                                    </button>
                                                    <div>
                                                    {seconds!=0 && countdown?<div className='countdown-seconds' style={{fontSize:"12px",textAlign:"center"}}>{seconds} sec</div>:null}
                                                    </div>
                                                    
                                                    </>
                                                )
                                            }
                                        </div> */}
                    </div>
                    {/* {seconds!=0?<div className='countdown-seconds' style={{fontSize:"12px",textAlign:"center"}}>{seconds} sec</div>:null} */}
                  </div>

                  {/* {  showEnterOtp && (newPhoneRef.current.value.toString().length === 10 || showDialog) && (<div className="enterotp">
                                    <div className='enterotp-heading' style={{marginBottom:"5px"}}>
                                        <div>Enter OTP</div>
                                        {

                                            (isWrongOtp && !isVerified) ? (
                                                <div className='home-page-walking-form-mobile-feedback'>
                                                    Wrong OTP
                                                </div>
                                            ) : null

                                        }
                                    </div>
                                    <input  ref={groomingOtpRef} onClick={() => { setIsWrongOtp(false) }} disabled={isVerified} type="number" placeholder='Enter Otp'
                                    style={{padding:"10px"}} className='grooming-new-form-desktop-input-container-type1'/>
                                            <button
                                            style={{padding:"5px",margin:"0px",height:"40px",border:"2px solid #45A5A3",backgroundColor:phoneFill?.length>=10?"rgb(69, 163, 165)":"",color:phoneFill?.length>=10?"white":""}}
                                            disabled={isVerified}
                                            onClick={() => { verifyMobileOTP();indicateAlert() }}
                                            >
                                                {isVerified ? (
                          <>
                         
                            <img src={greenTick} width={15} />{" "}
                            <span style={{ marginLeft: "5px",color:"white" }}>Verified</span>
                           </>
                        ) : (
                          "Verify"
                        )}
                        </button>
                                     
                                </div>)} */}
                </div>
              </div>
              <div
                className="grooming-new-form-section2-right-time-slot-container"
                style={{ marginTop: "20px", width: "100%" }}
              >
                <div>Time Slot</div>
                <div
                  className="grooming-new-form-section2-right-time-slots"
                  style={{ justifyContent: "flex-start" }}
                >
                  <div
                    onClick={() => setTimeSlot(1)}
                    className={
                      timeSlot == 1
                        ? "grooming-new-form-section2-right-time-slot-active"
                        : "grooming-new-form-section2-right-time-slot"
                    }
                  >
                    10AM-02PM
                  </div>
                  <div
                    onClick={() => setTimeSlot(2)}
                    className={
                      timeSlot == 2
                        ? "grooming-new-form-section2-right-time-slot-active"
                        : "grooming-new-form-section2-right-time-slot"
                    }
                  >
                    02PM-06PM
                  </div>
                  <div
                    onClick={() => setTimeSlot(3)}
                    className={
                      timeSlot == 3
                        ? "grooming-new-form-section2-right-time-slot-active"
                        : "grooming-new-form-section2-right-time-slot"
                    }
                  >
                    06PM-10PM
                  </div>
                </div>
              </div>
              <div
                className="grooming-new-form-section2-right-next-button-container"
                style={{ justifyContent: "flex-end" }}
              >
                <div
                  onClick={() => {
                    groomingFormDesktopHandler2();
                  }}
                  className="grooming-new-form-section1-right-next-button"
                  style={{ width: "100%", color: "black" }}
                >
                  Next
                </div>
              </div>
            </div>
          </div>

          <div className="grooming-new-form-section1-carousel-container">
            <div className="grooming-new-form-section1-carousel-active"></div>
            <div className="grooming-new-form-section1-carousel-active"></div>
            <div className="grooming-new-form-section1-carousel"></div>
          </div>
        </div>
      ) : null}

      {selectedCarousel == 3 ? (
        <div className="grooming-new-form-section1-main-container">
          <div className="grooming-new-form-section1-main">
            <div className="grooming-new-form-section3-left">
              <div className="grooming-new-form-section3-right-address-section">
                <div className="grooming-new-form-validation-container-desktop">
                  <div
                    className="grooming-new-form-section3-right-ph-text"
                    style={{ paddingTop: "1rem" }}
                  >
                    Your Location
                  </div>
                  {newAddressValidation ? null : (
                    <div className="home-page-desktop-phone-validation-text">
                      Required
                    </div>
                  )}
                </div>
                <div className="grooming-new-form-section3-right-ph-input-contact">
                  <input
                    onChange={() => {
                      setNewAddressValidation(true);
                      setAddress(newAddressRef.current.value);
                    }}
                    onFocus={handleLocationAccess}
                    ref={newAddressRef}
                    className="grooming-new-form-desktop-input-container-type1"
                    placeholder="Enter Your Home Address"
                    type="text"
                    style={{ padding: "10px" }}
                    value={address}
                  ></input>
                </div>
              </div>
            </div>
            <div
              className="grooming-new-form-section3-right"
              style={{ width: "45%" }}
            >
              <div
                className="grooming-new-form-section3-right-address-section"
                style={{ padding: "0 0 0 0rem" }}
              >
                <div className="grooming-form-tagline-text">
                  <center>
                    Paid Sessions are given time & elite groomers preference!
                  </center>
                </div>
                <div
                  className="grooming-new-form-section1-right-pay-button-container2"
                  style={{ paddingLeft: "1.5rem", paddingBottom: "1rem" }}
                >
                  <button
                    // disabled={isVerified === false || isVerified === null}
                    onClick={() => paymentInitiationHandler()}
                    className="grooming-new-form-section1-right-pay-button"
                    style={{ color: "black" }}
                  // style={{backgroundColor:!isVerified?"rgba(131, 145, 161, 1)":"",border:!isVerified?"2px solid rgba(131, 145, 161, 1)":""}}
                  >
                    Pay Now
                  </button>
                  {/* <button
                    //  disabled={isVerified === false || isVerified === null}
                    onClick={() => bookNowPayLaterHandler()}
                    className="grooming-new-form-section1-right-pay-later-button"
                    style={{ color: "black", width: "150%" }}
                    // style={{backgroundColor:!isVerified?"white":"",border:!isVerified?"2px solid rgba(131, 145, 161, 1)":"",color:!isVerified?"rgba(131, 145, 161, 1)":""}}
                  >
                    Book Now & Pay Later
                  </button> */}
                </div>
              </div>

              {/* {isLoading ? (
                <div className="grooming-page-loading-spinner-container-desktop">
                  <LoadingSpinner />
                </div>
              ) : (
                <div className="grooming-new-form-section1-right-pay-button-container">
                  <button
                    // disabled={isVerified === false || isVerified === null}
                    onClick={() => paymentInitiationHandler()}
                      className="grooming-new-form-section1-right-pay-button"
                      style={{color:"black"}}
                    // style={{backgroundColor:!isVerified?"rgba(131, 145, 161, 1)":"",border:!isVerified?"2px solid rgba(131, 145, 161, 1)":""}}
                  >
                    Pay Now
                  </button>
                  <button
                    //  disabled={isVerified === false || isVerified === null}
                    onClick={() => bookNowPayLaterHandler()}
                    className="grooming-new-form-section1-right-pay-later-button"
                    // style={{backgroundColor:!isVerified?"white":"",border:!isVerified?"2px solid rgba(131, 145, 161, 1)":"",color:!isVerified?"rgba(131, 145, 161, 1)":""}}
                  >
                    Book Now & Pay Later
                  </button>
                </div>
              )} */}
            </div>
          </div>

          <div className="grooming-new-form-section1-carousel-container">
            <div className="grooming-new-form-section1-carousel-active"></div>
            <div className="grooming-new-form-section1-carousel-active"></div>
            <div className="grooming-new-form-section1-carousel-active"></div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default HomePageForm;

//displayRazorpay()

//onClick={() => groomingDataSubmitHandler("grooming")}

// /payment/success:

//     request body:
// {
//     orderCreationId: order_id,
//     razorpayPaymentId: response.razorpay_payment_id,
//     razorpayOrderId: response.razorpay_order_id,
//     razorpaySignature: response.razorpay_signature,
// }
//     response body:
//         {
//             msg: "success",
//             orderId: razorpayOrderId,
//             paymentId: razorpayPaymentId,
//         }

// /payment/orders:
//         request body:
//             {
//                 'amount': serviceAmount
//             }

//         response body:

//                 {
//                     id: 'order_Kjsc3E9EEaiS13',
//                     entity: 'order',
//                     amount: 100,
//                     amount_paid: 0,
//                     amount_due: 100,
//                     currency: 'INR',
//                     receipt: 'receipt_order_74394',
//                     offer_id: null,
//                     status: 'created',
//                     attempts: 0,
//                     notes: [],
//                     created_at: 1669293764
//                 }
