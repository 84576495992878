import {useEffect, useState} from 'react';
import LazyLoad from "react-lazyload";
import TrainingPageForm from './CatTrainingPageForm';
import NavigationDesktop from '../../../components-desktop/Navigation/NavigationDesktop';

function IntroContainer({ introImage }) {

  return (
        <div className="intro-desktop-home-page-master-container">
            <div className='wrapper-class-intro-desktop-video' style={{position:"absolute"}}>
              <LazyLoad>
                {/* <video style={{width: "100vw", backgroundColor: "black"}} role="presentation" crossorigin="anonymous" playsInline preload="auto" muted loop tabindex="-1" autoPlay  className='home-page-desktop-background-video'>
                  <source
                    src="https://video.wixstatic.com/video/4e82bb_80fb0c4770334638a530f96539121a6c/720p/mp4/file.mp4"
                    type="video/mp4"
                    style={{width: "100%"}}
                  />
                </video> */}
                <img src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Desktop_Home_Top.png" alt="" style={{width:"100vw",height:"120vh",objectFit:"cover"}}/>
              </LazyLoad>
            </div>

            <div className='intro-desktop-home-page-content-container' style={{position:"relative"}}>
              <NavigationDesktop submissionType={"training"} submissionText={"Book a FREE Session"}  />

              <div className='intro-desktop-head-combiner'>
                
              
                <div className='intro-desktop-home-page-header'>
                  <h1 className='home-page-desktop-heading'>
                    <div className='intro-desktop-home-page-header1'>Find <span className='pink-bold'>Trusted & Qualified</span> Trainer</div>
                    <div className='intro-desktop-home-page-header1'>near your Neighbourhood!</div>
                  </h1>
                </div>
                <div className='intro-desktop-walking-page-points-container'>
                  <div className='intro-desktop-home-page-points-p1'>
                    <div><img alt="tick" className="tick-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
                    <div>INDIA's most Scientific and Advanced Research backed Curriculum</div>
                  </div>
                  <div className='intro-desktop-home-page-points-p1'>
                    <div><img alt="tick" className="tick-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
                    <div>EVERY Session is Agenda Driven & Purpose-Led</div>
                  </div>
                  {/* <div className='intro-desktop-home-page-points-p1'>
                    <div><img alt="tick" className="tick-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
                    <div>No questions asked Refund of remaining sessions</div>
                  </div> */}
                </div>
                <TrainingPageForm />


              </div>
            </div>
        </div>
  );
}

export default IntroContainer;
