import { useState, useEffect, useRef } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import iconsDesktop from "../../../images/iconsDesktop.png";
import LoadingSpinner from "../../loadingSpinner/LoadingSpinner";
import otptick from "../../../images/otptick.png";
import trainingFooterDesktop from "../../../images/trainingFooterDesktop.png";
import phoneIcon from "../../../images/telephoneIcon.png";
import dogsIcon from "../../../images/dogsIcon.png";
import locationIcon from "../../../images/locationIcon.png";
import { set } from "mongoose";

const HomePageForm = (props) => {
  const navigate = useNavigate();

  const [walking, setWalking] = useState(true);
  const [training, setTraining] = useState(false);
  const [phoneValidation, setPhoneValidation] = useState(true);
  const [petValidation, setPetValidation] = useState(true);
  const [petNameValidation, setNamePetValidation] = useState(true);
  const [cityValidation, setCityValidation] = useState();
  const [loading, setLoading] = useState(false);
  const location = useLocation().pathname;
  const date = new Date();
  const [lat, setLat] = useState(0);
  const [lon, setLon] = useState(0);
  const [address1, setAddress] = useState("");
  const [ipAddress, setIpAddress] = useState("");
  const [city, setCity] = useState("");
  const [loading1, setLoading1] = useState(true);

  // declaration of refs
  const phoneRef = useRef(0);
  const dateRef = useRef("");
  const addressRef = useRef("");
  const petRef = useRef("");

  const [petName, setPetName] = useState("");

  // -------------------handler functions for form header switch-------------

  function walkingHandlerFunction() {
    setWalking(true);
  }

  function trainingHandlerFunction() {
    setWalking(false);
    setTraining(true);
  }

  const [ipInfo, setIpInfo] = useState(null);

  const fetchIpAddress = async () => {
    try {
      const response = await axios.get("https://api64.ipify.org?format=json");
      const ipAddress = response.data.ip;
      console.log(ipAddress);
      // Fetch geolocation information using the obtained IP address
      const geoResponse = await axios.get(
        `https://ipinfo.io/${ipAddress}?token=ebe17d64fb1b22`
      );
      console.log(geoResponse.data);
      // setIpInfo(geoResponse.data);
      setAddress(geoResponse.data.region);
    } catch (error) {
      console.error("Error fetching IP information:", error);
    }
  };
  console.log("city", address1);
  useEffect(() => {
    // Fetch the user's dynamic IP address

    fetchIpAddress();
  }, []);

  // --------------------form submission handler functions-----------------------
  // const handleLocationAccess = () => {
  //   let r ;
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //     async  (position) => {
  //         const latitude = position.coords.latitude;
  //         const longitude = position.coords.longitude;
  //         setLat(latitude);
  //         setLon(longitude);
  //         // Make an API call to get the address based on the user's location
  //      const response = await axios.get(
  //           `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCeoWpdd6YOVu3wEqqA0qpY6P3B5rUumm8`
  //         )
  //         r=response.data.results[9].formatted_address
  //        setAddress(response.data.results[9].formatted_address)
  //        return response.data.results[9].formatted_address
  //       },
  //       (error) => {
  //         console.error(error);
  //       }
  //       );
  //       return r
  //   } else {
  //     console.error("Geolocation is not supported by this browser.");
  //   }
  // };
  // useEffect(()=>{
  //   handleLocationAccess()
  // },[])
  // console.log("success",address1)

  async function submitWalkingTrainingDataHandler(path) {
    let link = "";
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;
    const dateOfReg = `${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}`;

    const stringPhone = phoneRef.current.value.toString();

    let UTM_Source,
      UTM_Medium,
      UTM_Campaign = "";

    const urlParams = new URLSearchParams(window.location.search);
    UTM_Source = urlParams.get("utm_source");
    UTM_Medium = urlParams.get("utm_medium");
    UTM_Campaign = urlParams.get("utm_campaign");
    console.log(dateRef.current.value);
    let data = {
      data: {
        ContactNo: phoneRef.current.value,
        DateOfRegistration: dateRef.current.value || dateOfReg,
        location: address1,
        TimeOfRegistration: timeOfReg,
        Address: addressRef.current.value,
        FormType: "Direct Form",
        UTM_Source: UTM_Source || "",
        UTM_Medium: UTM_Medium || "",
        UTM_Campaign: UTM_Campaign || "",
        TrialDate: "",
        PetName: petName,
        City: city,
      },
    };

    

    if (
      addressRef !== "" &&
      stringPhone.length >= 10 &&
      petName.trim() !== "" &&
      city !== ""
    ) {
      setLoading(true);
      if (path === "walking") {
        link = "https://api.apispreadsheets.com/data/QcQTxBvwdD8rfShs/";
      } else if (path === "training") {
        console.log("Submitting the training data!");
        link =
          "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/saveTrainingData";
      }

      await axios
        .post(link, data)
        .then((response) => {
          console.log("hey2", response);
        })
        .catch((err) => {
          console.log("hey3", err);
        });

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: `${path} Registration Fired From Desktop Service Page Form`,
      });

      phoneRef.current.value = "";
      setLoading(false);
      navigate("/thankPage");
    } else if (stringPhone.length < 10) {
      setPhoneValidation(false);
      phoneRef.current.value = "";
    } else if (petName.trim() === "") {
      setNamePetValidation(false);
    } else {
      setCityValidation(false);
    }
  }

  const handleCityChange = (event) => {
    setCity(event.target.value);

    if (city !== "") setCityValidation(true);
    else setCityValidation(false);
  };

  const handlePetNameChange = (event) => {
    setPetName(event.target.value);

    if (petName.trim() !== "") setNamePetValidation(true);
    else setNamePetValidation(false);
  };

  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  // const [isPhoneValidated, setPhoneValidation] = useState(false);
  const [isOtpVerified, setOtpVerification] = useState(false);
  const [otpsent, setOtpsent] = useState(false);

  const sendOtp = async () => {
    try {
      // Make a POST request to send OTP to the provided phone number
      const response = await axios.post(
        "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/auth/sendMobileOTP",
        {
          phoneNumber: phoneNumber,
        }
      );
      setOtpsent(true);
      // Handle the response, maybe show a success message
      console.log("OTP Sent:", response.data);
    } catch (error) {
      // Handle error, show error message
      console.error("Error sending OTP:", error);
    }
  };

  const verifyOtp = async () => {
    try {
      // Make a POST request to verify the entered OTP
      console.log(otp, phoneNumber);
      const response = await axios.post(
        "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/auth/verifyMobileOTP",
        {
          otp: otp,
          phoneNumber: phoneNumber,
          type: "User",
        }
      );
      console.log(response.data);

      // If OTP is verified successfully, update state
      if (response.data.user && response.data.user.confirmed) {
        setOtpVerification(true);
      } else {
        setOtpVerification(false);

        // Handle unsuccessful OTP verification, show error message
        console.error("OTP Verification failed:", response.data.message);
      }
    } catch (error) {
      if (error && error.response && error.response.data.success === false) {
        setOtpVerification(false);
      }
      // Handle error, show error message
      console.error("Error verifying OTP:", error.response);
    }
  };
  useEffect(() => {
    if (otp.length === 6) {
      verifyOtp();
    }
  }, [otp]);

  return (
    <div className="home-page-desktop-intro-form-container2">
      <div className="walking-page-desktop-intro-form-header">
        <div>Book a FREE Training Session</div>
      </div>
      <div className="walking-page-desktop-intro-form-contents-container">
        <div className="walking-page-desktop-intro-form-contents-upper">
          {location == "/dog-training" && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "space-between",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    className="walking-page-desktop-intro-form-contents-upper-left"
                    style={{ marginRight: "10rem" }}
                  >
                    <div className="home-page-desktop-input-container">
                      <div>
                        Phone Number
                        <img
                          src={phoneIcon}
                          style={{
                            width: "15px",
                            height: "15px",
                            marginLeft: "2px",
                          }}
                        />{" "}
                      </div>

                      {phoneValidation ? null : (
                        <div className="home-page-desktop-phone-validation-text">
                          Please enter a valid phone number
                        </div>
                      )}
                    </div>
                    <input
                      onChange={() => setPhoneValidation(true)}
                      ref={phoneRef}
                      className="walking-page-phone-input"
                      type="number"
                      placeholder="Enter phone number"
                      name="phone"
                      required
                    />
                  </div>

                  <div className="walking-page-desktop-intro-form-contents-upper-left">
                    <div className="home-page-desktop-input-container">
                      <div>
                        Enter Pet Name{" "}
                        <img
                          src={dogsIcon}
                          style={{
                            width: "15px",
                            height: "15px",
                            marginLeft: "2px",
                          }}
                        />{" "}
                      </div>
                      {petNameValidation ? null : (
                        <div className="home-page-desktop-phone-validation-text">
                          Please enter petname
                        </div>
                      )}
                    </div>
                    <input
                      //   onChange={() => setPhoneValidation(true)}
                      //   ref={phoneRef}
                      className="walking-page-phone-input"
                      type="text"
                      placeholder="Enter Pet Name"
                      name="name"
                      value={petName}
                      onChange={(event) => {
                        handlePetNameChange(event);
                        setNamePetValidation(true);
                      }}
                      required
                    />
                  </div>
                </div>

                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div className="walking-page-desktop-intro-form-contents-upper-left">
                    <div className="home-page-desktop-input-container">
                      <div>
                        Select City{" "}
                        <img
                          src={locationIcon}
                          style={{
                            width: "15px",
                            height: "15px",
                            marginLeft: "2px",
                          }}
                        />{" "}
                        {cityValidation === undefined ||
                        cityValidation !== false ? null : (
                          <div className="home-page-desktop-phone-validation-text">
                            Select City
                          </div>
                        )}
                      </div>
                    </div>
                    <select
                      // onChange={() => setPhoneValidation(true)}
                      // ref={phoneRef}
                      className="walking-page-phone-input"
                      name="city"
                      value={city}
                      // onChange={handleCityChange}
                      onChange={(event) => {
                        handleCityChange(event);
                        setCityValidation(true);
                        setCity(event.target.value);
                      }}
                      required
                    >
                      <option value="" disabled selected>
                        Select City
                      </option>
                      <option value="Delhi NCR">Delhi NCR</option>
                      <option value="Mumbai Metropolitan City">
                        Mumbai Metropolitan City
                      </option>
                    </select>
                  </div>

                  <div
                    className="walking-page-desktop-intro-form-contents-upper-right"
                    style={{ marginTop: "2rem", marginLeft: "5rem" }}
                  >
                    <div className="home-page-intro-bottom-btn">
                      {loading ? (
                        <LoadingSpinner />
                      ) : (
                        <button
                          onClick={() =>
                            submitWalkingTrainingDataHandler("training")
                          }
                          className="booksession-form"
                        >
                          Book a FREE Session
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {location == "/dog-training-meta" && (
            <div style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="walking-page-desktop-intro-form-contents-upper-left">
                  <div className="home-page-desktop-input-container">
                    <div>Phone Number</div>

                    {phoneValidation ? null : (
                      <div className="home-page-desktop-phone-validation-text">
                        Please enter a valid phone number
                      </div>
                    )}
                  </div>
                  <input
                    onChange={() => setPhoneValidation(true)}
                    ref={phoneRef}
                    className="walking-page-phone-input"
                    type="number"
                    placeholder="Enter phone number"
                    name="phone"
                    required
                  />
                </div>
                <div
                  className="walking-page-desktop-intro-form-contents-upper-left"
                  style={{ display: "flex", justifyContent: "end" }}
                >
                  <div>
                    <div className="home-page-desktop-input-container">
                      <div>Pet Name</div>

                      {petValidation ? null : (
                        <div className="home-page-desktop-phone-validation-text">
                          Please enter a pet name
                        </div>
                      )}
                    </div>
                    <input
                      onChange={() => setPetValidation(true)}
                      ref={petRef}
                      className="walking-page-phone-input"
                      type="text"
                      placeholder="Enter pet name"
                      name="petname"
                      required
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginTop: "20px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className="walking-page-desktop-intro-form-contents-upper-right">
                  <div className="home-page-intro-bottom-btn">
                    {loading ? (
                      <LoadingSpinner />
                    ) : (
                      <button
                        onClick={() =>
                          submitWalkingTrainingDataHandler("training")
                        }
                        className="booksession-form"
                      >
                        Book a FREE Session
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div style={{ paddingTop: "2rem" }}>
          <img
            src={trainingFooterDesktop}
            style={{ width: "40rem", height: "10rem" }}
          />
        </div>
        <div className="walking-page-desktop-intro-form-contents-lower">
          <i>We are mission driven & a Family of Pet Lovers</i>
        </div>
      </div>
    </div>
  );
};

export default HomePageForm;
