import React, { useEffect, useState } from 'react'
import NavigationBar from '../Navigation/navigationBar/NavigationBar'
import NavigationDesktopBlogs from "../../components-desktop/Navigation/NavigationDesktopBlogs";
import "./refundsPage.css";
import imgs from "./Good team-pana.svg"

const RefundPolicy = () => {
    const [windowInnerWidth, setWindowInnerWidth] = useState("");

    useEffect(() => {
        setWindowInnerWidth(window.innerWidth);
        window &&
            window.addEventListener("resize", (data) => {
                setWindowInnerWidth(data.target.screen.width);
            });
    }, []);

    return (
        <div>
            {windowInnerWidth > 1150 ? (
                <NavigationDesktopBlogs
                    submissionType={"none"}
                    submissionText={"Download Our App"}
                />
            ) : (
                <NavigationBar />
            )}
            {windowInnerWidth < 1150 &&
                <div className='flex-1 flex justify-center'>
                    <img src={imgs} alt="background" className='h-[300px] w-[300px]' />
                </div>
            }
            <div className="refunds_text_wrapper">
                <div className='w-full flex justify-between'>
                    <div className='flex-1'>
                        <div className="refunds_div ">
                            <h1 className="refunds_heading">REFUND POLICIES</h1>
                            <div className="refunds_points">
                                <div className="refunds_dot"></div>
                                <p className="refunds_text">
                                    At Petmojo, we are committed to providing high-quality doorstep services for your pets.
                                </p>
                            </div>
                            <div className="refunds_points">
                                <div className="refunds_dot"></div>
                                <p className="refunds_text">
                                    However, we maintain a strict no refund policy for all our services.
                                </p>
                            </div>
                            <div className="refunds_points">
                                <div className="refunds_dot"></div>
                                <p className="refunds_text">
                                    Please read the following exceptions carefully:
                                </p>
                            </div>
                            {/* <div className="refunds_points">
                        <div className="refunds_dot"></div>
                        <p className="refunds_text">
                            The first 24 sessions are non refundable in all services. E.g., if A Service opted for 48 sessions where 24 sessions have been delivered, the refund will be provided only for the remaining 24 sessions.
                        </p>
                    </div>
                    <div className="refunds_points">
                        <div className="refunds_dot"></div>
                        <p className="refunds_text">
                            The refund amount will be made via Bank Transfer to the payee only
                        </p>
                    </div>
                    <div className="refunds_points">
                        <div className="refunds_dot"></div>
                        <p className="refunds_text">
                            Petmojo employees may verify your refund claims/ perform an internal check before the refund is sanctioned
                        </p>
                    </div> */}
                            {/* <div className="refunds_points">
            <div className="refunds_dot"></div>
            <p className="refunds_text">
              Petmojo employees may verify your refund claims/ perform an
              internal check before the refund is sanctioned
            </p>
          </div> */}
                            {/* <div className="refunds_points">
                        <div className="refunds_dot"></div>
                        <p className="refunds_text">
                            Once a refund request is raised it will take 14-21 days to process
                            & sanction your refund claim
                        </p>
                    </div> */}
                        </div>
                    </div>
                    {windowInnerWidth > 1150 &&
                        <div className='flex-1 flex justify-center'>
                            <img src={imgs} alt="background" className='h-[300px] w-[300px]' />
                        </div>
                    }
                </div>
                <div className="refunds_div">
                    <h1 className="refunds_heading" style={{ marginBottom: "15px" }}>
                        Exceptions to the No Refund Policy:
                    </h1>
                    <div className="flex gap-2">
                        <div >1.</div>
                        <p className="refunds_text">
                            Death of a Pet: In the unfortunate event of a pet's death, we will consider the case. Upon verification, we will refund 50% of the remaining balance for services not yet rendered.
                        </p>
                    </div>
                    <div className="flex gap-2">
                        <div >2.</div>
                        <p className="refunds_text">
                            Pet Ran Away or Disappeared: In the distressing situation where your pet runs away or disappears, we will assist in the search for your pet using all available company resources. This includes spreading information amongst our 25,000+ customers and deploying our ground staff to help locate your pet. This assistance will be provided free of charge for up to 10 days. Our team has successfully found and reunited multiple escaped dogs with their parents. However, if your pet is not found and you choose to cancel the remaining services, upon submission of the necessary proof, we will refund 50% of the remaining balance for services not yet rendered.
                        </p>
                    </div>
                </div>
                <div className="refunds_div">
                    <h1 className="refunds_heading" style={{ marginBottom: "15px" }}>
                        IMPORTANT NOTES
                    </h1>
                    <div className="refunds_points">
                        <div className="refunds_dot"></div>
                        <p className="refunds_text">
                            All refund requests must be accompanied by valid proof as required.
                        </p>
                    </div>
                    <div className="refunds_points">
                        <div className="refunds_dot"></div>
                        <p className="refunds_text">
                            The 50% refund will apply only to the remaining balance of services that have not yet been provided.
                        </p>
                    </div>
                </div>

                <div className="refunds_div">
                    <p className="refunds_text">
                        We appreciate your understanding and cooperation. Our goal is to provide the best possible service to you and your pets, and we are here to support you in any way we can.
                    </p>

                </div>
            </div>
        </div>
    )
}

export default RefundPolicy