import React, { useState } from 'react'
import NoVan from "../../../images/NoVan.png"
import background from "../../../images/background.png"
import greenTick from "../../../images/green-tick.png"
import ImageSlider from "../../ImageSlider";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const images = [
    {
        id: 1,
        src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-1.webp",
        alt: "Image 1",
    },
    {
        id: 2,
        src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-2.webp",
        alt: "Image 2",
    },
    {
        id: 3,
        src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-3.webp",
        alt: "Image 3",
    },
    {
        id: 4,
        src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-4.webp",
        alt: "Image 4",
    },
    {
        id: 5,
        src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-5.webp",
        alt: "Image 5",
    },
    {
        id: 6,
        src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-6.webp",
        alt: "Image 6",
    },
    {
        id: 7,
        src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-7.webp",
        alt: "Image 7",
    },
    {
        id: 8,
        src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-8.webp",
        alt: "Image 8",
    },
];

const GroomingPageUpdatedForm = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const baseUrl = "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api";

    const urlParams = new URLSearchParams(window.location.search);
    let UTM_Source = urlParams.get("utm_source");
    let UTM_Medium = urlParams.get("amp;utm_medium");
    let UTM_Campaign = urlParams.get("amp;utm_campaign");

    const handleFormSubmit = async (event) => {
        try {

            event.preventDefault();
            setIsLoading(true);

            const formData = new FormData(event.target);
            const data = {
                Name: formData.get('fullName'),
                Phone: formData.get('phoneNumber'),
                Timestamp: new Date(),
                UTM_Source: UTM_Source || "NA",
                UTM_Medium: UTM_Medium || "NA",
                UTM_Campaign: UTM_Campaign || "NA",
            };

            const res = await axios.post(`${baseUrl}/service/saveGroomingClientDataToSheet`, {
                data: data,
            });

            setIsLoading(false);

            if (res.status === 201) {
                navigate("/thankpage");
            } else {
                alert("There was an error submitting the form. Please try again.");
            }

        } catch (err) {
            console.log(err);
            setIsLoading(false);
            alert("There was an error submitting the form. Please try again.");
        }
    };

    return (
        <div className="home-page-desktop-intro-form-container2"
        >
            <div className="walking-page-desktop-intro-form-header">
                <div style={{ display: "flex", alignItems: "start" }}>
                    <div style={{ width: "100%" }} >
                        Book Your Grooming Session Here!{" "}
                        <span style={{ color: "#FF5E95" }}>
                            FLAT 50% OFF on all Grooming Packages
                        </span>
                    </div>
                </div>
            </div>

            <div className="grooming-new-form-section1-main-container">
                <div className='grooming-new-form-section1-main'>
                    <div className="grooming-new-form-section1-left" style={{ width: "50%" }}>
                        <form className='flex flex-col justify-between px-16 gap-8' onSubmit={handleFormSubmit}>
                            <div>
                                <div className="grooming-new-form-section3-right-ph-text mb-1 font-semibold">
                                    Enter Your Name
                                </div>
                                <input
                                    className="grooming-new-form-desktop-input-container-type1 py-7 px-3"
                                    placeholder="Enter full name"
                                    type="text"
                                    name="fullName"
                                    required
                                ></input>
                            </div>
                            <div>
                                <div className="grooming-new-form-section3-right-ph-text mb-1 font-semibold">
                                    Phone Number
                                </div>
                                <input
                                    className="grooming-new-form-desktop-input-container-type1 py-7 px-3"
                                    placeholder="Enter phone number"
                                    type="text"
                                    name="phoneNumber"
                                    required
                                    maxLength={10}
                                ></input>
                            </div>
                            <button
                                type='submit'
                                className="grooming-new-form-section1-right-next-button"
                                style={{ width: "100%", color: "black" }}
                            >
                                {isLoading ? "Submitting..." : "Book Appointment"}
                            </button>
                        </form>
                    </div>
                    <div className={"grooming-new-form-section1-right"} >
                        <div style={{ width: "100%" }}>
                            <ImageSlider images={images} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full bg-secondary rounded-b-[20px] flex justify-center p-2'>
                <h2 className='flex items-center gap-3 text-brand font-semibold text-xl italic'>
                    <img src={NoVan} style={{ height: "2.25rem", width: "2.25rem" }} alt='logo' />We do not offer Van Services
                </h2>
            </div>
        </div>
    )
}

export default GroomingPageUpdatedForm