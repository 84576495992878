import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { useGeolocated } from "react-geolocated";
import { useLocation, useNavigate } from "react-router-dom";
import greenTick from "../../../images/green-tick.png";
import ImageSliderWalking from "../../ImageSliderWalking";
import catSelect from "./../../../images/catSelect.png";
import dogSelect from "./../../../images/dogSelect.png";
import handShake from "./../../../images/HandShake.png";
import handPercent from "./../../../images/percentHand.png";
import starImg from "./../../../images/star.png";
import "./MobileFormsCSS.css";
// import greenTick from "./../../../images/green-tick.png"

const PackageToAmountMapping = {
  "Bath and Brush": 799,
  "Bath & Brush(with tick treatment)": 1199,
  "Haircut and Styling": 1249,
  "Bath, Full haircut & Styling": 1649,
  "Massage, Bath, Full haircut & Styling": 1949,
};

const style = {
  position: "absolute",
  // top: '50%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  // width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  width: "23.3rem",
};

const images = [
  {
    id: 1,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-mobile-1.webp",
    alt: "Image 1",
  },
  {
    id: 2,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-mobile-2.webp",
    alt: "Image 2",
  },
  {
    id: 3,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-mobile-3.webp",
    alt: "Image 3",
  },
  {
    id: 4,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-mobile-4.webp",
    alt: "Image 4",
  },
  {
    id: 5,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-mobile-5.webp",
    alt: "Image 5",
  },
  {
    id: 6,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-mobile-6.webp",
    alt: "Image 6",
  },
  {
    id: 7,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-mobile-7.webp",
    alt: "Image 7",
  },
  {
    id: 8,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-mobile-8.webp",
    alt: "Image 8",
  },
];

function useGetLocation() {
  const [location, setLocation] = useState(null);

  const { latitude, longitude } = useGeolocated();
  setLocation({ latitude, longitude });
  // useEffect(() => {
  // }, []);

  return location;
}

const GroomingPageForm = (props) => {
  const urlParams = new URLSearchParams(window.location.search);
  const name = urlParams.get("name") || "";
  const number = urlParams.get("number") || "";
  const groomingAddress = urlParams.get("address") || "";
  const amount = urlParams.get("amount") || "";
  const groomingDate = urlParams.get("date") || "";
  const packageType = urlParams.get("package") || "";
  const time = urlParams.get("time") || "";

  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  const [open3, setOpen3] = useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);

  const [open4, setOpen4] = useState(false);
  const handleOpen4 = () => setOpen4(true);
  const handleClose4 = () => setOpen4(false);

  const [open5, setOpen5] = useState(false);
  const handleOpen5 = () => setOpen5(true);
  const handleClose5 = () => setOpen5(false);

  const [otp, setOtp] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [couponValue, setCouponValue] = useState(0);
  const [couponErrorMsg, setCouponErrorMsg] = useState("");
  const [couponNotValid, setCouponNotValid] = useState(false);
  const [couponValid, setCouponValid] = useState(false);
  const [bookNow, setBookNow] = useState(false);
  const otpRef = useRef(null);
  const navigate = useNavigate();
  const [sessionDate, setSessionDate] = useState();
  const location = useLocation().pathname;
  const [seconds, setSeconds] = useState(0);
  // console.log("location: ",location.pathname)
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCarousel, setSelectedCarousel] = useState(
    name && number && packageType && time && groomingDate ? 3 : 1
  );
  const [correctOtp, setCorrectOtp] = useState(true);
  const groomingPhoneRef = useRef(0);
  const groomingAddressRef = useRef("");
  const groomingAddressRef1 = useRef("");
  const groomingDateRef = useRef("");
  const groomingSlotRef = useRef("");
  const groomingOtpRef = useRef("");
  const [countdown, setCountdown] = useState(true);
  const [startValue, setStartValue] = useState(groomingDate ? true : false);
  const [startDate, setStartDate] = useState(groomingDate || "");
  const [nameValue, setnameValue] = useState(name ? true : false);
  const [nameFill, setNameFill] = useState(name || "");
  const [phoneValue, setPhoneValue] = useState(number ? true : false);
  const [phoneFill, setPhoneFill] = useState(number || null);
  const [addressValue, setAddressValue] = useState(false);
  const [adressFill, setAdressFill] = useState("");
  const [slotValue, setSlotValue] = useState(time ? true : false);
  const [slotFill, setSlotFill] = useState(time || "");
  const [price1, setPrice] = useState(
    PackageToAmountMapping[packageType] || 799
  );
  // const groomingEmailRef = useRef("");
  const groomingNameRef = useRef("");
  const [inputDate, setInputDate] = useState("");
  const [inputSlot, setInputSlot] = useState("");
  const [copySuccess, setCopySuccess] = useState("");
  const [lat, setLat] = useState(0);
  const [lon, setLon] = useState(0);
  const [copyMessage, setCopyMessage] = useState("");
  const [userData, setUserData] = useState({
    Pet: "Dog",
    Service: packageType || "",
    amount: PackageToAmountMapping[packageType] || 0,
    Date: groomingDate || "",
    Slot: time || "",
    Name: name || "",
    Phone: number || "",
    Address: groomingAddress || "",
    Latitude: 0,
    Longitude: 0,
  });

  const [paymentData, setPaymentData] = useState({});
  const [flag, setFlag] = useState(false);
  const [address, setAddress] = useState(groomingAddress || "");
  const [selectedService, setSelectedService] = useState("Bath and Brush");
  const [selectedPet, setSelectedPet] = useState("Dog");
  const [serviceDropdown, setServiceDropdown] = useState(false);
  const baseUrl =
    "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api";

  const [groomingPhoneValidation, setGroomingPhoneValidation] = useState(true);
  const [groomingAddressValidation, setGroomingAddressValidation] =
    useState(true);
  const [groomingAddressValidation1, setGroomingAddressValidation1] =
    useState(true);

  const [groomingDateValidation, setGroomingDateValidation] = useState(true);
  const [groomingSlotValidation, setGroomingSlotValidation] = useState(true);
  const [groomingNameValidation, setGroomingNameValidation] = useState(true);
  const [groomingLoading, setGroomingLoading] = useState(false);
  const [selectedChoice, setSelectedChoice] = useState(
    packageType === "Bath and Brush"
      ? 1
      : packageType === "Bath & Brush(with tick treatment)"
        ? 2
        : packageType === "Haircut and Styling"
          ? 3
          : packageType === "Bath, Full haircut & Styling"
            ? 4
            : packageType === "Massage, Bath, Full haircut & Styling"
              ? 5
              : 1
  );
  const [selectedPetChoice, setSelectedPetChoice] = useState(1);
  const [navTab, setNavTab] = useState(1);
  const [formSection, setFormSection] = useState(1);
  const [showEnterOtp, setShowEnterOtp] = useState(false);
  const [showDownloadInvoice, setShowDownloadInvoice] = useState(null);
  const [isVerified, setIsVerified] = useState(null);
  const [isWrongOtp, setIsWrongOtp] = useState(false);
  const [razorpayOrderId, setRazorpayOrderId] = useState("");
  const [bookingId, setBookingId] = useState("");
  const [token, setToken] = useState("");
  const [phone, setPhone] = useState("");
  const sectionRef = useRef(null);
  function scrollToSection() {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  }
  var currentDate = new Date();
  var year = currentDate.getFullYear();
  var month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
  var day = ("0" + currentDate.getDate()).slice(-2);
  var formattedDate = year + "-" + month + "-" + day;
  function setSelectedServiceHandler(service) {
    setSelectedService(service);
    setServiceDropdown(false);
  }
  const groomingFirstFormHandler = () => {
    // if(nameFill==""){
    //   setGroomingNameValidation(false);
    // }
    // if(phoneFill==null || phoneFill==""){
    //   setGroomingPhoneValidation(false);
    // }
    // else{
    //   setSelectedCarousel(2);
    // }

    if (selectedChoice !== 0) setSelectedCarousel(2);
  };
  const firstFormDataHandler = () => {
    let groomingDateRefCurrValue = groomingDateRef.current.value;
    if (
      groomingDateRefCurrValue !== "" &&
      groomingSlotRef.current.value !== ""
    ) {
      setInputDate(groomingDateRefCurrValue);
      setInputSlot(groomingSlotRef.current.value);

      setUserData({
        ...userData,
        Pet: selectedPetChoice === 1 ? "Dog" : "Cat",
        Service:
          selectedChoice === 1
            ? "Bath and Brush"
            : selectedChoice === 2
              ? "Bath & Brush(with tick treatment)"
              : selectedChoice === 3
                ? "Haircut and Styling"
                : selectedChoice === 4
                  ? "Bath, Full haircut & Styling"
                  : "Massage, Bath, Full haircut & Styling",
        amount:
          selectedChoice === 1
            ? location === "/grooming"
              ? 799
              : 799
            : selectedChoice === 2
              ? 1199
              : selectedChoice === 3
                ? 1249
                : selectedChoice === 4
                  ? 1649
                  : 1949,
        Date: groomingDateRefCurrValue,
        Slot: groomingSlotRef.current.value,
        Latitude: lat,
        Longitude: lon,
      });
      //   setSelectedCarousel(2);
    } else {
      console.log("data coll err");
    }
  };
  const secondFormDataHandler = () => {
    if (
      groomingNameRef.current.value !== "" &&
      checkPhone(groomingPhoneRef.current.value.toString()).length === 10 &&
      groomingAddressRef.current.value !== ""
    ) {
      setUserData({
        ...userData,
        Name: groomingNameRef.current.value,
        Phone: phone,
        Address: groomingAddressRef.current.value,
      });

      //   setSelectedCarousel(3);
    } else {
      console.log("data col err");
    }
  };
  const [showDialog, setShowDialog] = useState(false);
  const checkPhone = (phoneString) => {
    if (
      phoneString.length > 10 &&
      (phoneString.startsWith("+91") || phoneString.startsWith("91"))
    ) {
      phoneString = phoneString.slice(-10);
      setPhone(phoneString);
      setShowDialog(true);
    }
    return phoneString;
  };
  const nextNavhandler = () => {
    let groomingDateRefCurrValue = groomingDateRef.current.value;
    if (
      groomingDateRefCurrValue !== "" &&
      groomingSlotRef.current.value !== "" &&
      nameFill != "" &&
      (phoneFill != null || phoneFill != "")
    ) {
      console.log(userData);
      setSelectedCarousel(3);
      // setFormSection(3);
    } else if (nameFill == "") {
      setGroomingNameValidation(false);
    } else if (phoneFill == null || phoneFill == "") {
      setGroomingPhoneValidation(false);
    } else if (groomingDateRefCurrValue === "") {
      console.log("error");
      setGroomingDateValidation(false);
    } else if (groomingSlotRef.current.value === "") {
      console.log("error");
      setGroomingSlotValidation(false);
    }

    if (
      groomingDateRefCurrValue === "" &&
      groomingSlotRef.current.value === ""
    ) {
      console.log("error");
      setGroomingSlotValidation(false);
      setGroomingDateValidation(false);
    }
  };

  function copyToClipboard(text) {
    text.select();
    document.execCommand("copy");
    text.focus();
    setCopySuccess("Copied!");
  }

  function copiedMessage() {
    setCopyMessage("Copied!");

    setTimeout(() => {
      setCopyMessage("");
    }, 2000);
  }
  const getmobileOtp = useCallback(async () => {
    try {
      let phoneString = checkPhone(groomingPhoneRef.current.value.toString());
      const res = await axios.post(`${baseUrl}/auth/sendMobileOTP`, {
        phoneNumber: phoneString,
      });
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  }, []);
  const changeName = () => {
    document.getElementById("send-otp").innerHTML = "Resend OTP";
  };

  const verifyMobileOTP = useCallback(async () => {
    try {
      let phoneString = checkPhone(groomingPhoneRef.current.value.toString());
      const res = await axios.post(
        "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/auth/verifyMobileOTP",
        {
          otp: groomingOtpRef.current.value.toString(),
          phoneNumber: phoneString,
          type: "User",
        }
      );
      console.log(res.status);
      console.log(res.data);

      if (res.status === 200) {
        setIsVerified(true);
        setCorrectOtp(true);
        setToken(res.data.token);
        setCountdown(false);
      }
    } catch (error) {
      console.log(error.response.status);
      console.log(error);
      if (error.response.status === 400) {
        setIsVerified(false);
        setCorrectOtp(false);
        setIsWrongOtp(true);
      }
    }
  }, []);
  const indicateAlert = () => {
    if (!correctOtp) {
      document.getElementById("send-otp").style.borderColor = "red";
    }
    // else{

    // }
  };

  // const displayLocation=()=>{
  //   console.log("Latitude:",coords.latitude);
  //   console.log("Longitude:",coords.longitude);
  //   console.log(address)
  // }
  // const removeAlert=()=>{
  //   if(correctOtp){
  //     document.getElementById("send-otp").style.borderColor="none"
  //   }
  // }
  const decrementSeconds = () => {
    setTimeout(() => {
      if (seconds == 0) {
        // document.getElementById("countdown-seconds").style?.display = "none";
      } else {
        setSeconds(seconds - 1);
      }
    }, 1000);
  };
  const handleOtpChange = (event) => {
    const { value } = event.target;
    setOtp(value);
  };

  // function to handle the form submission
  const handleSubmit = (event) => {
    // event.preventDefault();
    console.log(`Submitting OTP: ${otp}`);
  };

  // function to handle the "Paste" event and autofill OTP
  const handlePaste = (event) => {
    // event.preventDefault();
    const pastedText = event.clipboardData.getData("Text");
    setOtp(pastedText);
  };
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay(
    serviceAmount,
    SelectedService,
    DateOfReg,
    inputPhone,
    InputAddress,
    bookingId
  ) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // creating a new order
    const result = await axios.post(
      "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/generateGroomingRazorPayOrderId",
      {
        amount: serviceAmount,
      }
    );

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }

    // Getting the order details back
    const { amount, id: order_id, currency } = result.data;

    const options = {
      key: process.env.REACT_APP_KEY, // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: "Petmojo",
      description: "Pet Grooming Payment",
      //image: { logo },
      order_id: order_id,
      handler: async function (response) {
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };

        const result = await axios.post(
          "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/generateGroomingRazorPaySuccess",
          data
        );
        //alert(result.data.msg);
        console.log(result.data);
        //setRazorpayOrderId(result.data.orderId)

        const postGroomingPayment = async (bId, oId, amt) => {
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjYzZWY5ZWZkNTc1MGY5Y2Q4MmVmYmZmNyJ9.QOz1wBekeflAqD2ktWjDQLubM4snuMMDFV0Lk97z5UY",
            },
          };
          try {
            console.log(bId, oId, amt);
            const res = await axios.patch(
              "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/service/postGroomingPayment",
              {
                bookingId: bId,
                transactionId: oId,
                amount: amt,
                type: "User",
                isFromWebsite: true,
              },
              config
            );
            console.log(res.data);
          } catch (error) {
            console.log(bId, oId, amt);
            console.log(error);
          }
        };

        if (result.status === 200) {
          postGroomingPayment(bookingId, result.data.orderId, serviceAmount);
          setNavTab(2);
        }
        const Data = {
          ...result.data,
          SelectedService,
          DateOfReg,
          InputAddress,
        };
        setSelectedService(selectedService);
        const actualAmount = serviceAmount / 100;

        const dataToStore = {
          Date: DateOfReg,
          Address: InputAddress,
          Contact_No: inputPhone.toString(),
          Amount_Paid: actualAmount.toString(),
          Selected_Service: SelectedService,
          Order_id: order_id,
        };

        await axios
          .post(
            "https://api.apispreadsheets.com/data/hq3CiEAYcPfNXIJE/",
            dataToStore
          )
          .then((response) => {
            console.log(response);
            console.log("The grooming payment has been completed!");
          })
          .catch((err) => {
            console.log(err);
            console.log(
              "There was some error in submitting the grooming payment to backend!"
            );
          });

        setPaymentData(Data);
        setFlag(true);
      },
      // prefill: {
      //     name: "Petmojo",
      //     email: "customercare@petmojo.in",
      //     contact: "+91 9009004473",
      // },
      notes: {
        address: "Petmojo",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }
  let UTM_Source,
    UTM_Medium,
    UTM_Campaign = "";

  UTM_Source = urlParams.get("utm_source");
  UTM_Medium = urlParams.get("amp;utm_medium");
  UTM_Campaign = urlParams.get("amp;utm_campaign");

  const finalFormSubmitHandler = async (flag) => {
    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${date.getMonth() + 1
      }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;

    if (
      nameFill !== "" &&
      checkPhone(phoneFill).length === 10 &&
      groomingAddressRef.current.value !== ""
    ) {
      // console.log(userData);
      // console.log(userData.Phone)
      const config = {
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
      };
      setIsLoading(true);
      try {
        // console.log("hey1")
        const phone =
          userData.Phone.length > 10 &&
            (userData.Phone.startsWith("+91") || userData.Phone.startsWith("91"))
            ? userData.Phone.slice(-10)
            : userData.Phone;
        // console.log(phone)
        setSelectedService(userData.Service);
        const res = await axios.post(
          `${baseUrl}/serviceBooking/websiteDogGroomingBookingService`,
          {
            fullName: userData.Name,
            address: address,
            phone: phone,
            startDate: userData.Date,
            sessionTime: userData.Slot,
            pet: userData.Pet,
            latitude: lat,
            longitude: lon,
            package: {
              description: userData.Service,
              amount: userData.amount - couponValue,
              frequency: 1,
            },
            isCouponCodeUsed: couponValue === 0 ? false : true,
            couponCode: couponValue !== 0 ? couponCode : "",
            type: "User",
            UTM_Source: UTM_Source || "",
            UTM_Medium: UTM_Medium || "",
            UTM_Campaign: UTM_Campaign || "",
          },
          config
        );
        // console.log("hey2")
        console.log(res.data);
        console.log(res.status);
        if (flag) {
          let UTM_Source,
            UTM_Medium,
            UTM_Campaign = "";

          const urlParams = new URLSearchParams(window.location.search);
          UTM_Source = urlParams.get("utm_source");
          UTM_Medium = urlParams.get("amp;utm_medium");
          UTM_Campaign = urlParams.get("amp;utm_campaign");

          setSelectedService(userData.Service);

          const data = {
            ContactNo: userData.Phone,
            Services: userData.Service,
            Date: userData.Date,
            Time: userData.Slot,
            Email: "",
            FormType: "Direct Form",
            DateOfRegistration: dateOfReg,
            TimeOfRegistration: timeOfReg,
            Address: userData.Address,
            Name: userData.Name,
            Pet: userData.Pet,
            latitude: lat,
            longitude: lon,
            Amount: userData.amount - couponValue,
            UTM_Source: UTM_Source || "",
            UTM_Medium: UTM_Medium || "",
            UTM_Campaign: UTM_Campaign || "",
          };
          data["paymentStatus"] = 1;

          setSelectedService(userData.Service);

          // await axios
          //   .post(
          //     "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/saveGroomingData",
          //     data
          //   )
          //   .then((response) => {
          //     console.log(response);
          //     console.log("The grooming form is being submitted...");
          //     setIsLoading(false);
          //   })
          //   .catch((err) => {
          //     console.log(err);
          //     console.log(
          //       "There was some error in submitting the grooming form..."
          //     );
          //     setIsLoading(false);
          //   });
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: `Grooming Registration Fired From Mobile Service Page Form`,
          });
          setShowDownloadInvoice(true);
          displayRazorpay(
            userData.amount - couponValue,
            userData.Service,
            dateOfReg,
            userData.Phone,
            userData.Address,
            res.data.bookingId
          );
        } else {
          // console.log("hello guys")
          let UTM_Source,
            UTM_Medium,
            UTM_Campaign = "";

          const urlParams = new URLSearchParams(window.location.search);
          UTM_Source = urlParams.get("utm_source");
          UTM_Medium = urlParams.get("amp;utm_medium");
          UTM_Campaign = urlParams.get("amp;utm_campaign");

          setSelectedService(userData.Service);
          const data = {
            ContactNo: userData.Phone,
            Services: userData.Service,
            Date: userData.Date,
            Time: userData.Slot,
            Email: "",
            FormType: "Direct Form",
            DateOfRegistration: dateOfReg,
            TimeOfRegistration: timeOfReg,
            Address: address,
            Name: userData.Name,
            Pet: userData.Pet,
            Amount: userData.amount - couponValue,
            Latitude: lat,
            Longitude: lon,
            UTM_Source: UTM_Source || "",
            UTM_Medium: UTM_Medium || "",
            UTM_Campaign: UTM_Campaign || "",
          };
          data["paymentStatus"] = 2;

          await axios
            .post(
              "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/saveGroomingData",
              data
            )
            .then((response) => {
              console.log(response);
              console.log("The grooming form is being submitted...");
            })
            .catch((err) => {
              console.log(err);
              console.log(
                "There was some error in submitting the grooming form..."
              );
            });
          setShowDownloadInvoice(false);
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: `Grooming Registration Fired From Mobile Service Page Form`,
          });
          setNavTab(2);
        }
      } catch (error) {
        console.log("error------->", error);
      }
    } else if (nameFill === "") {
      console.log("err");
      setGroomingNameValidation(false);
    } else if (checkPhone(phoneFill).length !== 10) {
      console.log("err123");
      setGroomingPhoneValidation(false);
    } else if (groomingAddressRef.current.value === "") {
      console.log("err");
      setGroomingAddressValidation(false);
    }

    if (
      nameFill === "" &&
      checkPhone(phoneFill).length !== 10 &&
      groomingAddressRef.current.value === ""
    ) {
      console.log("err");
      setGroomingNameValidation(false);
      setGroomingPhoneValidation(false);
      setGroomingAddressValidation(false);
    }

    setIsLoading(false);
  };
  const [changeColor, setChangeColor] = useState(false);
  const formShiftHandler = () => {
    window.scrollTo(0, 300);
  };

  const backArrow = () => {
    setSelectedCarousel(selectedCarousel - 1);
  };

  const changeButtonColor = (e) => {
    console.log(e);
    // const ele=document.getElementById("send-otp");
    // if(e.target.value.length()>=10){
    //   ele.style.backgroundColor="red";
    // }
  };

  useEffect(() => {
    indicateAlert();
  }, [correctOtp]);

  const applyMOJO = async () => {
    applyCoupon("MOJO5");
    setCouponCode("MOJO5");
  };

  const applyCoupon = async (cd) => {
    await axios
      .post(
        "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/websiteVerifyCoupon",
        {
          name: cd ?? couponCode,
          serviceType: 2,
          package: selectedService,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: token,
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response.data.value) {
          setCouponValue(response.data.value);
          setCouponErrorMsg("");
          setCouponNotValid(false);
          setCouponValid(true);
        } else {
          setCouponValid(false);
          setCouponNotValid(true);
          setCouponErrorMsg(response.data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const params = {};
    for (let param of searchParams.entries()) {
      params[param[0]] = param[1];
    }
    if (
      params["amp;utm_campaign"] === "Grooming 499" &&
      params["amp;utm_medium"] === "Whatsapp Campaign" &&
      params["utm_source"] === "Whatsapp"
    ) {
      setCouponCode("WLCMBK");
      applyCoupon("WLCMBK");
    }
  }, []);

  useEffect(() => {
    decrementSeconds();
  }, [seconds]);
  // useEffect(() => {
  //   if (coords) {
  //     const { latitude, longitude } = coords;
  //     const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCeoWpdd6YOVu3wEqqA0qpY6P3B5rUumm8`;

  //     fetch(url)
  //           .then(response => response.json())
  //           .then(data => {
  //             setAddress(data.results[0].formatted_address);
  //           })
  //           .catch(error => console.error(error));
  //       },
  //       (error) => {
  //         console.error(error);
  //       }
  //     );
  //   } else {
  //     console.error('Geolocation is not supported by this browser.');
  //   }
  // };

  // useEffect(() => {
  //   if (coords) {
  //     const { latitude, longitude } = coords;
  //     const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCeoWpdd6YOVu3wEqqA0qpY6P3B5rUumm8`;

  //     fetch(url)
  //       .then(response => response.json())
  //       .then(data => {
  //         const { results } = data;
  //         if (results && results.length > 0) {
  //           setAddress(results[0].formatted_address);
  //           // console.log(address)
  //         }
  //       })
  //       .catch(error => {
  //         console.log('Error:', error);
  //       });
  //   }
  // }, [coords]);
  const useLocation123 = () => {
    // const { coords } = useGeolocated();
    // console.log(coords);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        // setLocation({ latitude, longitude });
        console.log("asdf", latitude, longitude);
        setLat(latitude);
        setLon(longitude);
        // const result=getAddress(latitude,longitude);
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCeoWpdd6YOVu3wEqqA0qpY6P3B5rUumm8`;
        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            const { results } = data;
            if (results && results.length > 0) {
              setAddress(results[0].formatted_address);
              setGroomingAddressValidation(true);
              // console.log(address)
            }
          })
          .catch((error) => {
            console.log("Error:", error);
          });
      },
      (error) => {
        console.log(error);
      }
    );
    // useEffect(()=>{
    //   if(address!=''){
    //     setGroomingAddressValidation(true);
    //   }
    // },[address])
    // const getAddress=async(latitude,longitude)=>{
    //   console.log("Latitude:",latitude);
    //   console.log("Longitude:",longitude);
    //   const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCeoWpdd6YOVu3wEqqA0qpY6P3B5rUumm8`;
    //   fetch(url)
    //     .then(response => response.json())
    //     .then(data => {
    //       const { results } = data;
    //       if (results && results.length > 0) {
    //         setAddress(results[0].formatted_address);
    //         // console.log(address)
    //       }
    //     })
    //     .catch(error => {
    //       console.log('Error:', error);
    //     });

    // }
    // const location = useGeoLocated();
    // console.log("asdfasdfasdfasdf",location)
    // if (coords) {
    //   const { latitude, longitude } = coords;
    //   const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCeoWpdd6YOVu3wEqqA0qpY6P3B5rUumm8`;

    //   fetch(url)
    //     .then(response => response.json())
    //     .then(data => {
    //       const { results } = data;
    //       if (results && results.length > 0) {
    //         setAddress(results[0].formatted_address);
    //         // console.log(address)
    //       }
    //     })
    //     .catch(error => {
    //       console.log('Error:', error);
    //     });
    // }
  };
  return (
    <>
      <div
        style={{
          padding: "10px",
          marginTop: "10px",
          marginBottom: "30px",
          width: "100%",
        }}
      >
        <ImageSliderWalking images={images} />
      </div>

      <div style={{ marginLeft: "2rem" }}>
        <div
          style={{
            fontFamily: "DM Sans",
            fontWeight: "bolder",
            fontSize: "2rem",
            lineHeight: "3rem",
            color: "black",
          }}
        >
          <h1>Fastest Pet</h1>
          <h1>Grooming Services</h1>
          <h1>Near You</h1>
        </div>

        <div>
          <p
            style={{
              fontFamily: "DM Sans",
              fontWeight: "0.2rem",
              fontSize: "0.8rem",
              display: "flex",
              flexDirection: "row",
              textDecoration: "underline",
              color: "black",
            }}
          >
            <img
              src={starImg}
              alt="star"
              style={{
                width: "0.8rem",
                height: "0.8rem",
                marginTop: "0.2rem",
                marginRight: "0.2rem",
              }}
            />
            4.90 (525K bookings)
          </p>
        </div>

        <div
          className="petType"
          style={{
            border: "1px solid black",
            borderRadius: "0.5rem",
            width: "90%",
            height: "11rem",
            marginTop: "1rem",
            color: "black",
          }}
        >
          <p style={{ margin: "1rem 0 0 0.5rem" }}>Which one is yours?</p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginLeft: "1rem",
              marginTop: "1rem",
            }}
          >
            <div style={{ marginRight: "0.8rem" }}>
              <img src={dogSelect} style={{ width: "4rem", height: "4rem" }} />
              <p style={{ fontSize: "0.8rem" }}>Ist it a Dog?</p>
            </div>
            <div>
              <img src={catSelect} style={{ width: "4rem", height: "4rem" }} />
              <p style={{ fontSize: "0.8rem" }}>Ist it a Cat?</p>
            </div>
          </div>
        </div>

        <div
          style={{
            border: "1px solid black",
            borderRadius: "0.5rem",
            width: "90%",
            height: "5rem",
            marginTop: "1rem",
            display: "flex",
            flexDirection: "row",
            color: "black",
          }}
        >
          <img
            src={handShake}
            alt="Hand Shake"
            style={{
              width: "4rem",
              height: "4rem",
              margin: "0.5rem 0.5rem 0 0.5rem",
            }}
          />
          <p style={{ marginTop: "1.8rem" }}>Trusted by 1000+ Customers</p>
        </div>

        <div
          style={{
            border: "1px solid black",
            borderRadius: "0.5rem",
            width: "90%",
            height: "5rem",
            marginTop: "1rem",
            display: "flex",
            flexDirection: "row",
            color: "black",
          }}
        >
          <img
            src={handPercent}
            alt="Hand Shake"
            style={{
              width: "4rem",
              height: "4rem",
              margin: "0.5rem 0.5rem 0 0.5rem",
            }}
          />
          <p style={{ marginTop: "1.8rem" }}>Flat 50% Off on Pet Grooming</p>
        </div>

        <div
          style={{
            fontFamily: "DM Sans",
            fontWeight: "bolder",
            fontSize: "1.2rem",
            lineHeight: "3rem",
            color: "black",
          }}
        >
          <h1>Our Packages</h1>

          <div
            className="packages"
            style={{
              // padding: "4rem 3rem ",
              // border: "1px solid black",
              // borderRadius: "0.5rem",
              color: "black",
            }}
          >
            <div
              style={{
                color: "black",
                flexDirection: "column",
                alignContent: "start",
                alignItems: "start",
                width: "100vh",
                paddingRight: "5rem",
                textAlign: "left",
              }}
            >
              <p
                style={{
                  marginRight: "5rem",
                  paddingRight: "5rem",
                  fontSize: "0.8rem",
                  color: "#0B5459",
                }}
              >
                PACKAGE
              </p>
              <p>
                Bath + Brush{" "}
                <Button
                  variant="outlined"
                  style={{
                    backgroundColor: "white",
                    color: "pink",
                    borderColor: "#0F0F0F14",
                    marginLeft: "5rem",
                  }}
                >
                  {" "}
                  Add{" "}
                </Button>
              </p>
              <p
                style={{
                  fontFamily: "DM Sans",
                  fontWeight: "0.2rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  flexDirection: "row",
                  textDecoration: "underline",
                }}
              >
                <img
                  src={starImg}
                  alt="star"
                  style={{
                    width: "0.8rem",
                    height: "0.8rem",
                    marginTop: "0.2rem",
                    marginRight: "0.2rem",
                  }}
                />
                4.90 (525K bookings)
              </p>
              <p>₹1598 </p>
              <p>★     Bath & Shampoo</p>
              <p>★     Bath & Shampoo</p>
              <p>★     Bath & Shampoo</p>
              <Button
                variant="outlined"
                style={{ backgroundColor: "white", marginTop: "1rem" }}
                onClick={handleOpen1}
              >
                View More
              </Button>

              <Modal
                open={open1}
                onClose={handleClose1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <ImageSliderWalking images={images} />
                  <div style={{ marginLeft: "0.5rem" }}>
                    <p
                      style={{
                        marginTop: "2rem",
                        fontSize: "1.7rem",
                        fontWeight: "bolder",
                      }}
                    >
                      Bath + Brush{" "}
                    </p>
                    <p
                      style={{
                        fontFamily: "DM Sans",
                        fontWeight: "0.2rem",
                        fontSize: "0.8rem",
                        display: "flex",
                        flexDirection: "row",
                        textDecoration: "underline",
                      }}
                    >
                      <img
                        src={starImg}
                        alt="star"
                        style={{
                          width: "0.8rem",
                          height: "0.8rem",
                          marginTop: "0.2rem",
                          marginRight: "0.2rem",
                        }}
                      />
                      4.90 (525K bookings)
                    </p>
                    <hr style={{ width: "20rem", margin: "1rem 0" }} />

                    <p style={{ fontSize: "1.2rem", fontWeight: "bolder" }}>
                      Includes
                    </p>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        className="left"
                        style={{
                          display: "flex",
                          flex: "0.35",
                          flexDirection: "column",
                        }}
                      >
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Includes Bath
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Shampoo
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Blow Dry
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Brushing
                        </p>
                      </div>
                      <div
                        className="right"
                        style={{
                          display: "flex",
                          flex: "0.65",
                          flexDirection: "column",
                        }}
                      >
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Nail Trim
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Ear Cleaning or Flushing
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Scissoring/Trimming feet hair
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Teeth Brushing
                        </p>
                      </div>
                    </div>
                    <p
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: "bolder",
                        margin: "0.8rem 0",
                      }}
                    >
                      Our Safe & Secure Pet Grooming Process
                    </p>
                    <div>
                      <p style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>
                        Pre-Grooming Consultation
                      </p>
                      <p style={{ fontSize: "0.8rem" }}>
                        First, our pet professionals will discuss your
                        requirements according to your pet’s bread, temperament
                        and physical appearance.
                      </p>
                    </div>

                    <div>
                      <p style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>
                        Pre-Grooming Consultation
                      </p>
                      <p style={{ fontSize: "0.8rem" }}>
                        First, our pet professionals will discuss your
                        requirements according to your pet’s bread, temperament
                        and physical appearance.
                      </p>
                    </div>
                    <div>
                      <p style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>
                        Pre-Grooming Consultation
                      </p>
                      <p style={{ fontSize: "0.8rem" }}>
                        First, our pet professionals will discuss your
                        requirements according to your pet’s bread, temperament
                        and physical appearance.
                      </p>
                    </div>
                    <p style={{ fontSize: "1.2rem", fontWeight: "bolder", margin: "0.8rem 0" }}>
                      FAQs
                    </p>

                    <div>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          Why do I need pet grooming services from Pet Mojo?
                        </AccordionSummary>
                        <AccordionDetails>
                          Pet grooming services are necessary to maintain your pet’s healthy shape. Pet Mojo offers a wide range of pet grooming services that come with several benefits, such as utilization of genuine products, pet-friendly environment, breed-focused training and a lot more
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          How often do I need pet grooming services?
                        </AccordionSummary>
                        <AccordionDetails>
                          It totally depends on the type of pet and their breed. Our pet grooming professionals will assist you to schedule pet grooming by analyzing your canine or feline’s health.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          How long does it take to groom my pet?
                        </AccordionSummary>
                        <AccordionDetails>
                          Minimum time that takes for your pet grooming will be 30 minutes. For customized solutions or any other package that requires more attention, then it might take up to 1-2 hours.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          What if I am not satisfied with the service my dog ​​is receiving?
                        </AccordionSummary>
                        <AccordionDetails>
                          Well, it is the rarest case we come to know about. However, when it appears, our pet grooming experts will take complete care of your concerns. You just need to tell us your issues within 2 working days from the pet grooming services. We focus on exceeding expectations by giving impressive pet care solutions.
                        </AccordionDetails>
                      </Accordion>

                    </div>
                  </div>
                </Box>
              </Modal>
            </div>

            <hr style={{ width: "20rem", marginTop: "3rem" }} />

            <div
              style={{
                color: "black",
                flexDirection: "column",
                alignContent: "start",
                alignItems: "start",
                width: "100vh",
                paddingRight: "5rem",
                textAlign: "left",
              }}
            >
              <p
                style={{
                  marginRight: "5rem",
                  paddingRight: "5rem",
                  fontSize: "0.8rem",
                  color: "#0B5459",
                  marginTop: "2rem",
                }}
              >
                PACKAGE
              </p>
              <p>
                Bath + Brush{" "}
                <Button
                  variant="outlined"
                  style={{
                    backgroundColor: "white",
                    color: "pink",
                    borderColor: "#0F0F0F14",
                    marginLeft: "5rem",
                  }}
                >
                  {" "}
                  Add{" "}
                </Button>
              </p>
              <p
                style={{
                  fontFamily: "DM Sans",
                  fontWeight: "0.2rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  flexDirection: "row",
                  textDecoration: "underline",
                }}
              >
                <img
                  src={starImg}
                  alt="star"
                  style={{
                    width: "0.8rem",
                    height: "0.8rem",
                    marginTop: "0.2rem",
                    marginRight: "0.2rem",
                  }}
                />
                4.90 (525K bookings)
              </p>
              <p>₹1598 </p>
              <p>★     Bath & Shampoo</p>
              <p>★     Bath & Shampoo</p>
              <p>★     Bath & Shampoo</p>
              <Button
                variant="outlined"
                style={{ backgroundColor: "white", marginTop: "1rem" }}
                onClick={handleOpen2}
              >
                View More
              </Button>

              <Modal
                open={open2}
                onClose={handleClose2}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <ImageSliderWalking images={images} />
                  <div style={{ marginLeft: "0.5rem" }}>
                    <p
                      style={{
                        marginTop: "2rem",
                        fontSize: "1.7rem",
                        fontWeight: "bolder",
                      }}
                    >
                      Bath + Brush{" "}
                    </p>
                    <p
                      style={{
                        fontFamily: "DM Sans",
                        fontWeight: "0.2rem",
                        fontSize: "0.8rem",
                        display: "flex",
                        flexDirection: "row",
                        textDecoration: "underline",
                      }}
                    >
                      <img
                        src={starImg}
                        alt="star"
                        style={{
                          width: "0.8rem",
                          height: "0.8rem",
                          marginTop: "0.2rem",
                          marginRight: "0.2rem",
                        }}
                      />
                      4.90 (525K bookings)
                    </p>
                    <hr style={{ width: "20rem", margin: "1rem 0" }} />

                    <p style={{ fontSize: "1.2rem", fontWeight: "bolder" }}>
                      Includes
                    </p>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        className="left"
                        style={{
                          display: "flex",
                          flex: "0.35",
                          flexDirection: "column",
                        }}
                      >
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Includes Bath
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Shampoo
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Blow Dry
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Brushing
                        </p>
                      </div>
                      <div
                        className="right"
                        style={{
                          display: "flex",
                          flex: "0.65",
                          flexDirection: "column",
                        }}
                      >
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Nail Trim
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Ear Cleaning or Flushing
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Scissoring/Trimming feet hair
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Teeth Brushing
                        </p>
                      </div>
                    </div>
                    <p
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: "bolder",
                        margin: "0.8rem 0",
                      }}
                    >
                      Our Safe & Secure Pet Grooming Process
                    </p>
                    <div>
                      <p style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>
                        Pre-Grooming Consultation
                      </p>
                      <p style={{ fontSize: "0.8rem" }}>
                        First, our pet professionals will discuss your
                        requirements according to your pet’s bread, temperament
                        and physical appearance.
                      </p>
                    </div>

                    <div>
                      <p style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>
                        Pre-Grooming Consultation
                      </p>
                      <p style={{ fontSize: "0.8rem" }}>
                        First, our pet professionals will discuss your
                        requirements according to your pet’s bread, temperament
                        and physical appearance.
                      </p>
                    </div>
                    <div>
                      <p style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>
                        Pre-Grooming Consultation
                      </p>
                      <p style={{ fontSize: "0.8rem" }}>
                        First, our pet professionals will discuss your
                        requirements according to your pet’s bread, temperament
                        and physical appearance.
                      </p>
                    </div>
                    <p style={{ fontSize: "1.2rem", fontWeight: "bolder", margin: "0.8rem 0" }}>
                      FAQs
                    </p>

                    <div>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          Why do I need pet grooming services from Pet Mojo?
                        </AccordionSummary>
                        <AccordionDetails>
                          Pet grooming services are necessary to maintain your pet’s healthy shape. Pet Mojo offers a wide range of pet grooming services that come with several benefits, such as utilization of genuine products, pet-friendly environment, breed-focused training and a lot more
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          How often do I need pet grooming services?
                        </AccordionSummary>
                        <AccordionDetails>
                          It totally depends on the type of pet and their breed. Our pet grooming professionals will assist you to schedule pet grooming by analyzing your canine or feline’s health.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          How long does it take to groom my pet?
                        </AccordionSummary>
                        <AccordionDetails>
                          Minimum time that takes for your pet grooming will be 30 minutes. For customized solutions or any other package that requires more attention, then it might take up to 1-2 hours.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          What if I am not satisfied with the service my dog ​​is receiving?
                        </AccordionSummary>
                        <AccordionDetails>
                          Well, it is the rarest case we come to know about. However, when it appears, our pet grooming experts will take complete care of your concerns. You just need to tell us your issues within 2 working days from the pet grooming services. We focus on exceeding expectations by giving impressive pet care solutions.
                        </AccordionDetails>
                      </Accordion>

                    </div>
                  </div>
                </Box>
              </Modal>
            </div>

            <hr style={{ width: "20rem", marginTop: "3rem" }} />

            <div
              style={{
                color: "black",
                flexDirection: "column",
                alignContent: "start",
                alignItems: "start",
                width: "100vh",
                paddingRight: "5rem",
                textAlign: "left",
              }}
            >
              <p
                style={{
                  marginRight: "5rem",
                  paddingRight: "5rem",
                  fontSize: "0.8rem",
                  color: "#0B5459",
                  marginTop: "2rem",
                }}
              >
                PACKAGE
              </p>
              <p>
                Bath + Brush{" "}
                <Button
                  variant="outlined"
                  style={{
                    backgroundColor: "white",
                    color: "pink",
                    borderColor: "#0F0F0F14",
                    marginLeft: "5rem",
                  }}
                >
                  {" "}
                  Add{" "}
                </Button>
              </p>
              <p
                style={{
                  fontFamily: "DM Sans",
                  fontWeight: "0.2rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  flexDirection: "row",
                  textDecoration: "underline",
                }}
              >
                <img
                  src={starImg}
                  alt="star"
                  style={{
                    width: "0.8rem",
                    height: "0.8rem",
                    marginTop: "0.2rem",
                    marginRight: "0.2rem",
                  }}
                />
                4.90 (525K bookings)
              </p>
              <p>₹1598 </p>
              <p>★     Bath & Shampoo</p>
              <p>★     Bath & Shampoo</p>
              <p>★     Bath & Shampoo</p>
              <Button
                variant="outlined"
                style={{ backgroundColor: "white", marginTop: "1rem" }}
                onClick={handleOpen3}
              >
                View More
              </Button>
              <Modal
                open={open3}
                onClose={handleClose3}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <ImageSliderWalking images={images} />
                  <div style={{ marginLeft: "0.5rem" }}>
                    <p
                      style={{
                        marginTop: "2rem",
                        fontSize: "1.7rem",
                        fontWeight: "bolder",
                      }}
                    >
                      Bath + Brush{" "}
                    </p>
                    <p
                      style={{
                        fontFamily: "DM Sans",
                        fontWeight: "0.2rem",
                        fontSize: "0.8rem",
                        display: "flex",
                        flexDirection: "row",
                        textDecoration: "underline",
                      }}
                    >
                      <img
                        src={starImg}
                        alt="star"
                        style={{
                          width: "0.8rem",
                          height: "0.8rem",
                          marginTop: "0.2rem",
                          marginRight: "0.2rem",
                        }}
                      />
                      4.90 (525K bookings)
                    </p>
                    <hr style={{ width: "20rem", margin: "1rem 0" }} />

                    <p style={{ fontSize: "1.2rem", fontWeight: "bolder" }}>
                      Includes
                    </p>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        className="left"
                        style={{
                          display: "flex",
                          flex: "0.35",
                          flexDirection: "column",
                        }}
                      >
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Includes Bath
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Shampoo
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Blow Dry
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Brushing
                        </p>
                      </div>
                      <div
                        className="right"
                        style={{
                          display: "flex",
                          flex: "0.65",
                          flexDirection: "column",
                        }}
                      >
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Nail Trim
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Ear Cleaning or Flushing
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Scissoring/Trimming feet hair
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Teeth Brushing
                        </p>
                      </div>
                    </div>
                    <p
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: "bolder",
                        margin: "0.8rem 0",
                      }}
                    >
                      Our Safe & Secure Pet Grooming Process
                    </p>
                    <div>
                      <p style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>
                        Pre-Grooming Consultation
                      </p>
                      <p style={{ fontSize: "0.8rem" }}>
                        First, our pet professionals will discuss your
                        requirements according to your pet’s bread, temperament
                        and physical appearance.
                      </p>
                    </div>

                    <div>
                      <p style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>
                        Pre-Grooming Consultation
                      </p>
                      <p style={{ fontSize: "0.8rem" }}>
                        First, our pet professionals will discuss your
                        requirements according to your pet’s bread, temperament
                        and physical appearance.
                      </p>
                    </div>
                    <div>
                      <p style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>
                        Pre-Grooming Consultation
                      </p>
                      <p style={{ fontSize: "0.8rem" }}>
                        First, our pet professionals will discuss your
                        requirements according to your pet’s bread, temperament
                        and physical appearance.
                      </p>
                    </div>
                    <p style={{ fontSize: "1.2rem", fontWeight: "bolder", margin: "0.8rem 0" }}>
                      FAQs
                    </p>

                    <div>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          Why do I need pet grooming services from Pet Mojo?
                        </AccordionSummary>
                        <AccordionDetails>
                          Pet grooming services are necessary to maintain your pet’s healthy shape. Pet Mojo offers a wide range of pet grooming services that come with several benefits, such as utilization of genuine products, pet-friendly environment, breed-focused training and a lot more
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          How often do I need pet grooming services?
                        </AccordionSummary>
                        <AccordionDetails>
                          It totally depends on the type of pet and their breed. Our pet grooming professionals will assist you to schedule pet grooming by analyzing your canine or feline’s health.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          How long does it take to groom my pet?
                        </AccordionSummary>
                        <AccordionDetails>
                          Minimum time that takes for your pet grooming will be 30 minutes. For customized solutions or any other package that requires more attention, then it might take up to 1-2 hours.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          What if I am not satisfied with the service my dog ​​is receiving?
                        </AccordionSummary>
                        <AccordionDetails>
                          Well, it is the rarest case we come to know about. However, when it appears, our pet grooming experts will take complete care of your concerns. You just need to tell us your issues within 2 working days from the pet grooming services. We focus on exceeding expectations by giving impressive pet care solutions.
                        </AccordionDetails>
                      </Accordion>

                    </div>
                  </div>
                </Box>
              </Modal>
            </div>

            <hr style={{ width: "20rem", marginTop: "3rem" }} />

            <div
              style={{
                color: "black",
                flexDirection: "column",
                alignContent: "start",
                alignItems: "start",
                width: "100vh",
                paddingRight: "5rem",
                textAlign: "left",
              }}
            >
              <p
                style={{
                  marginRight: "5rem",
                  paddingRight: "5rem",
                  fontSize: "0.8rem",
                  color: "#0B5459",
                  marginTop: "2rem",
                }}
              >
                PACKAGE
              </p>
              <p>
                Bath + Brush{" "}
                <Button
                  variant="outlined"
                  style={{
                    backgroundColor: "white",
                    color: "pink",
                    borderColor: "#0F0F0F14",
                    marginLeft: "5rem",
                  }}
                >
                  {" "}
                  Add{" "}
                </Button>
              </p>
              <p
                style={{
                  fontFamily: "DM Sans",
                  fontWeight: "0.2rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  flexDirection: "row",
                  textDecoration: "underline",
                }}
              >
                <img
                  src={starImg}
                  alt="star"
                  style={{
                    width: "0.8rem",
                    height: "0.8rem",
                    marginTop: "0.2rem",
                    marginRight: "0.2rem",
                  }}
                />
                4.90 (525K bookings)
              </p>
              <p>₹1598 </p>
              <p>★     Bath & Shampoo</p>
              <p>★     Bath & Shampoo</p>
              <p>★     Bath & Shampoo</p>
              <Button
                variant="outlined"
                style={{ backgroundColor: "white", marginTop: "1rem" }}
                onClick={handleOpen4}
              >
                View More
              </Button>
              <Modal
                open={open4}
                onClose={handleClose4}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <ImageSliderWalking images={images} />
                  <div style={{ marginLeft: "0.5rem" }}>
                    <p
                      style={{
                        marginTop: "2rem",
                        fontSize: "1.7rem",
                        fontWeight: "bolder",
                      }}
                    >
                      Bath + Brush{" "}
                    </p>
                    <p
                      style={{
                        fontFamily: "DM Sans",
                        fontWeight: "0.2rem",
                        fontSize: "0.8rem",
                        display: "flex",
                        flexDirection: "row",
                        textDecoration: "underline",
                      }}
                    >
                      <img
                        src={starImg}
                        alt="star"
                        style={{
                          width: "0.8rem",
                          height: "0.8rem",
                          marginTop: "0.2rem",
                          marginRight: "0.2rem",
                        }}
                      />
                      4.90 (525K bookings)
                    </p>
                    <hr style={{ width: "20rem", margin: "1rem 0" }} />

                    <p style={{ fontSize: "1.2rem", fontWeight: "bolder" }}>
                      Includes
                    </p>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        className="left"
                        style={{
                          display: "flex",
                          flex: "0.35",
                          flexDirection: "column",
                        }}
                      >
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Includes Bath
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Shampoo
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Blow Dry
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Brushing
                        </p>
                      </div>
                      <div
                        className="right"
                        style={{
                          display: "flex",
                          flex: "0.65",
                          flexDirection: "column",
                        }}
                      >
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Nail Trim
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Ear Cleaning or Flushing
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Scissoring/Trimming feet hair
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Teeth Brushing
                        </p>
                      </div>
                    </div>
                    <p
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: "bolder",
                        margin: "0.8rem 0",
                      }}
                    >
                      Our Safe & Secure Pet Grooming Process
                    </p>
                    <div>
                      <p style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>
                        Pre-Grooming Consultation
                      </p>
                      <p style={{ fontSize: "0.8rem" }}>
                        First, our pet professionals will discuss your
                        requirements according to your pet’s bread, temperament
                        and physical appearance.
                      </p>
                    </div>

                    <div>
                      <p style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>
                        Pre-Grooming Consultation
                      </p>
                      <p style={{ fontSize: "0.8rem" }}>
                        First, our pet professionals will discuss your
                        requirements according to your pet’s bread, temperament
                        and physical appearance.
                      </p>
                    </div>
                    <div>
                      <p style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>
                        Pre-Grooming Consultation
                      </p>
                      <p style={{ fontSize: "0.8rem" }}>
                        First, our pet professionals will discuss your
                        requirements according to your pet’s bread, temperament
                        and physical appearance.
                      </p>
                    </div>
                    <p style={{ fontSize: "1.2rem", fontWeight: "bolder", margin: "0.8rem 0" }}>
                      FAQs
                    </p>

                    <div>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          Why do I need pet grooming services from Pet Mojo?
                        </AccordionSummary>
                        <AccordionDetails>
                          Pet grooming services are necessary to maintain your pet’s healthy shape. Pet Mojo offers a wide range of pet grooming services that come with several benefits, such as utilization of genuine products, pet-friendly environment, breed-focused training and a lot more
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          How often do I need pet grooming services?
                        </AccordionSummary>
                        <AccordionDetails>
                          It totally depends on the type of pet and their breed. Our pet grooming professionals will assist you to schedule pet grooming by analyzing your canine or feline’s health.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          How long does it take to groom my pet?
                        </AccordionSummary>
                        <AccordionDetails>
                          Minimum time that takes for your pet grooming will be 30 minutes. For customized solutions or any other package that requires more attention, then it might take up to 1-2 hours.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          What if I am not satisfied with the service my dog ​​is receiving?
                        </AccordionSummary>
                        <AccordionDetails>
                          Well, it is the rarest case we come to know about. However, when it appears, our pet grooming experts will take complete care of your concerns. You just need to tell us your issues within 2 working days from the pet grooming services. We focus on exceeding expectations by giving impressive pet care solutions.
                        </AccordionDetails>
                      </Accordion>

                    </div>
                  </div>
                </Box>
              </Modal>
            </div>

            <hr style={{ width: "20rem", marginTop: "3rem" }} />
            <div
              style={{
                color: "black",
                flexDirection: "column",
                alignContent: "start",
                alignItems: "start",
                width: "100vh",
                paddingRight: "5rem",
                textAlign: "left",
              }}
            >
              <p
                style={{
                  marginRight: "5rem",
                  paddingRight: "5rem",
                  fontSize: "0.8rem",
                  color: "#0B5459",
                  marginTop: "2rem",
                }}
              >
                PACKAGE
              </p>
              <p>
                Bath + Brush{" "}
                <Button
                  variant="outlined"
                  style={{
                    backgroundColor: "white",
                    color: "pink",
                    borderColor: "#0F0F0F14",
                    marginLeft: "5rem",
                  }}
                >
                  {" "}
                  Add{" "}
                </Button>
              </p>
              <p
                style={{
                  fontFamily: "DM Sans",
                  fontWeight: "0.2rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  flexDirection: "row",
                  textDecoration: "underline",
                }}
              >
                <img
                  src={starImg}
                  alt="star"
                  style={{
                    width: "0.8rem",
                    height: "0.8rem",
                    marginTop: "0.2rem",
                    marginRight: "0.2rem",
                  }}
                />
                4.90 (525K bookings)
              </p>
              <p>₹1598 </p>
              <p>★     Bath & Shampoo</p>
              <p>★     Bath & Shampoo</p>
              <p>★     Bath & Shampoo</p>
              <Button
                variant="outlined"
                style={{ backgroundColor: "white", marginTop: "1rem" }}
                onClick={handleOpen5}
              >
                View More
              </Button>
              <Modal
                open={open5}
                onClose={handleClose5}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <ImageSliderWalking images={images} />
                  <div style={{ marginLeft: "0.5rem" }}>
                    <p
                      style={{
                        marginTop: "2rem",
                        fontSize: "1.7rem",
                        fontWeight: "bolder",
                      }}
                    >
                      Bath + Brush{" "}
                    </p>
                    <p
                      style={{
                        fontFamily: "DM Sans",
                        fontWeight: "0.2rem",
                        fontSize: "0.8rem",
                        display: "flex",
                        flexDirection: "row",
                        textDecoration: "underline",
                      }}
                    >
                      <img
                        src={starImg}
                        alt="star"
                        style={{
                          width: "0.8rem",
                          height: "0.8rem",
                          marginTop: "0.2rem",
                          marginRight: "0.2rem",
                        }}
                      />
                      4.90 (525K bookings)
                    </p>
                    <hr style={{ width: "20rem", margin: "1rem 0" }} />

                    <p style={{ fontSize: "1.2rem", fontWeight: "bolder" }}>
                      Includes
                    </p>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        className="left"
                        style={{
                          display: "flex",
                          flex: "0.35",
                          flexDirection: "column",
                        }}
                      >
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Includes Bath
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Shampoo
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Blow Dry
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Brushing
                        </p>
                      </div>
                      <div
                        className="right"
                        style={{
                          display: "flex",
                          flex: "0.65",
                          flexDirection: "column",
                        }}
                      >
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Nail Trim
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Ear Cleaning or Flushing
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Scissoring/Trimming feet hair
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Teeth Brushing
                        </p>
                      </div>
                    </div>
                    <p
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: "bolder",
                        margin: "0.8rem 0",
                      }}
                    >
                      Our Safe & Secure Pet Grooming Process
                    </p>
                    <div>
                      <p style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>
                        Pre-Grooming Consultation
                      </p>
                      <p style={{ fontSize: "0.8rem" }}>
                        First, our pet professionals will discuss your
                        requirements according to your pet’s bread, temperament
                        and physical appearance.
                      </p>
                    </div>

                    <div>
                      <p style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>
                        Pre-Grooming Consultation
                      </p>
                      <p style={{ fontSize: "0.8rem" }}>
                        First, our pet professionals will discuss your
                        requirements according to your pet’s bread, temperament
                        and physical appearance.
                      </p>
                    </div>
                    <div>
                      <p style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>
                        Pre-Grooming Consultation
                      </p>
                      <p style={{ fontSize: "0.8rem" }}>
                        First, our pet professionals will discuss your
                        requirements according to your pet’s bread, temperament
                        and physical appearance.
                      </p>
                    </div>
                    <p style={{ fontSize: "1.2rem", fontWeight: "bolder", margin: "0.8rem 0" }}>
                      FAQs
                    </p>

                    <div>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          Why do I need pet grooming services from Pet Mojo?
                        </AccordionSummary>
                        <AccordionDetails>
                          Pet grooming services are necessary to maintain your pet’s healthy shape. Pet Mojo offers a wide range of pet grooming services that come with several benefits, such as utilization of genuine products, pet-friendly environment, breed-focused training and a lot more
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          How often do I need pet grooming services?
                        </AccordionSummary>
                        <AccordionDetails>
                          It totally depends on the type of pet and their breed. Our pet grooming professionals will assist you to schedule pet grooming by analyzing your canine or feline’s health.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          How long does it take to groom my pet?
                        </AccordionSummary>
                        <AccordionDetails>
                          Minimum time that takes for your pet grooming will be 30 minutes. For customized solutions or any other package that requires more attention, then it might take up to 1-2 hours.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          What if I am not satisfied with the service my dog ​​is receiving?
                        </AccordionSummary>
                        <AccordionDetails>
                          Well, it is the rarest case we come to know about. However, when it appears, our pet grooming experts will take complete care of your concerns. You just need to tell us your issues within 2 working days from the pet grooming services. We focus on exceeding expectations by giving impressive pet care solutions.
                        </AccordionDetails>
                      </Accordion>

                    </div>
                  </div>
                </Box>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GroomingPageForm;
