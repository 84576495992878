import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from "react";
import LazyLoad from "react-lazyload";
import { useLocation, useNavigate } from "react-router-dom";
import GoToTop from "./GoToTop";
import LoadingSpinner from "./loadingSpinner/LoadingSpinner";
import "./Popup.css";

const timeObj = {
  '10AM-02PM': 1,
  '02PM-06PM': 2,
  '06PM-10PM': 3
}

function Popup({ data }) {

  const [selectedFirst, setSelectedFirst] = useState(true);
  const [selectedSecond, setSelectedSecond] = useState(false);
  const [selectedThird, setSelectedThird] = useState(false);
  const [selectedNew, setSelectedNew] = useState(false);
  const [timeSlot, setTimeSlot] = useState(timeObj[data?.time] || 1);
  const [showTimeSlots, setShowTimeSlots] = useState(false);
  const navigate = useNavigate(); // navigation hook initialization --------------
  const [loading, setLoading] = useState(false);
  const [groomingLoading, setGroomingLoading] = useState(false);
  const [changeView, setChangeView] = useState(false);

  const phoneRef = useRef('');
  const emailRef = useRef('');
  const nameRef = useRef('');
  const dateRef = useRef();
  const addressRef = useRef('');
  const timeRef = useRef('');
  const newDateRef = useRef('');

  const [phNo, setPhNo] = useState('');

  const [inputText, setInputText] = useState("");
  const [submit, setSubmit] = useState(false);

  const [inputPhone, setInputPhone] = useState('');
  const [inputName, setInputName] = useState(data?.name || '');
  const [inputEmail, setInputEmail] = useState("");
  const [inputDate, setInputDate] = useState('');
  const [inputAddress, setInputAddress] = useState('');
  const [inputTime, setInputTime] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [validPhone, setValidPhone] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [validName, setValidName] = useState(true);
  const [validDate, setValidDate] = useState(true);
  const [validTime, setValidTime] = useState(true);
  const [validAddress, setValidAddress] = useState(true);

  const [newPhoneValidation, setNewPhoneValidation] = useState(true);

  const baseUrl = "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api"
  const [isVerified, setIsVerified] = useState(null)
  const [showEnterOtp, setShowEnterOtp] = useState(false)
  const [isWrongOtp, setIsWrongOtp] = useState(false)
  const [token, setToken] = useState("")
  const [bookingId, setBookingId] = useState("");

  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState(data?.time || "10AM-02PM");
  const [serviceChoice, setServiceChoice] = useState(data?.packageType || "BATH & BRUSH");
  const [overallChoice, setOverallChoice] = useState({
    choice: "",
    date: "",
    time: "10AM-02PM",
    name: "",
    address: "",
    phone: ""
  });
  const [newNameValidation, setNewNameValidation] = useState(true);
  const [newAddressValidation, setNewAddressValidation] = useState(true);
  const [newDateValidation, setNewDateValidation] = useState(true);

  const newPhoneRef = useRef("");
  const groomingOtpRef = useRef("");
  const newNameRef = useRef("");
  const newAddressRef = useRef("");

  useEffect(() => {
    if (window.innerWidth <= 1000) {
      setChangeView(false);
    } else {
      setChangeView(true);
    }
  }, []);

  const [valid, setValid] = useState(true); // setting up the phone number validation


  const [selectedService, setSelectedService] = useState(data?.packageType || "BATH & BRUSH");
  const [serviceDropdown, setServiceDropdown] = useState(false);
  const [paymentData, setPaymentData] = useState({});
  const [flag, setFlag] = useState(false);

  function setSelectedServiceHandler(service) {
    setSelectedService(service);
    setServiceDropdown(false);
  }

  const paymentGatewayHit = async (data) => {

    const path = "grooming";
    const inputPhone = data.ContactNo;
    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString('en-US')}`;
    const inputAddress = newAddressRef.current.value;


    var amount = 0;

    if (data.Services === "BATH & BRUSH") {//kartik
      amount = 799;
    }
    else if (data.Services === "BATH & BRUSH (With Tick Treatment)") {
      amount = 1199;
    }
    else if (data.Services === "HAIRCUT & STYLING") {
      amount = 1249;
    }
    else if (data.Services === "BATH & FULL HAIRCUT") {
      amount = 1799;
    }

    axios.post('https://api.apispreadsheets.com/data/GWyR613yTiDqBzxn/', data)
      .then((response) => {
        console.log(response);
        console.log("The grooming form is being submitted...")
      })
      .catch((err) => {
        console.log(err);
        console.log("There was some error in submitting the grooming form...")
      })

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      "event": `${path} Registration Fired From Desktop Service Page Form`
    });

    displayRazorpay(amount, inputPhone, selectedService, dateOfReg, inputAddress);

    console.log(data);

  }

  const submitData = async (path) => {

    const phNoString = phNo.toString();

    if (phNoString.length !== 10) {
      setValid(false);
      return 0;
    } else {

      setLoading(true);

      const elapsed = Date.now();
      const date = new Date(elapsed);
      const dateOfReg = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
      const timeOfReg = `${date.toLocaleTimeString('en-US')}`;

      const data = {
        ContactNo: phNo,
        DateOfRegistration: dateOfReg,
        TimeOfRegistration: timeOfReg,
        FormType: "CTA Page Form"
      }
      let link = "";

      if (path === "dog-training") {
        link = "https://api.apispreadsheets.com/data/kR8JfKXyaxqMQyqt/";
        console.log("you are on dog-training page")
      }
      else if (path === "dog-running") {
        link = "https://api.apispreadsheets.com/data/QcQTxBvwdD8rfShs/"
        console.log("you are on dog-running page")
      }


      await axios.post(link, data)
        .then((response) => {
          console.log(response);
          setPhNo('');
        })

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        "event": `${path} Registration Fired`
      });

      setLoading(false);
      navigate("/thankPage");

    }
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay(serviceAmount, inputPhone, SelectedService, DateOfReg, InputAddress) {
    setIsLoading(false);
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // creating a new order
    const result = await axios.post("https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/generateGroomingRazorPayOrderId", {
      'amount': serviceAmount
    });

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }

    // Getting the order details back
    const { amount, id: order_id, currency } = result.data;

    const options = {
      key: process.env.REACT_APP_KEY, // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: "Petmojo",
      description: "Pet Grooming Payment",
      //image: { logo },
      order_id: order_id,
      handler: async function (response) {
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };

        //console.log("Grooming Page Function: ", data);

        const result = await axios.post("https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/generateGroomingRazorPaySuccess", data);
        //alert(result.data.msg);
        const Data = {
          ...result.data,
          SelectedService,
          DateOfReg,
          InputAddress
        }

        const actualAmount = serviceAmount / 100;

        const dataToStore = {
          Date: DateOfReg,
          Address: InputAddress,
          Contact_No: inputPhone.toString(),
          Amount_Paid: actualAmount.toString(),
          Selected_Service: SelectedService,
          Order_id: order_id
        }

        await axios.post("https://api.apispreadsheets.com/data/hq3CiEAYcPfNXIJE/", dataToStore)
          .then((response) => {
            console.log(response);
            console.log("The grooming payment has been completed!")
          })
          .catch((err) => {
            console.log(err);
            console.log("There was some error in submitting the grooming payment to backend!")
          })

        setPaymentData(Data);
        setFlag(true);
      },
      prefill: {
        name: "Petmojo",
        email: "customercare@petmojo.in",
        contact: inputPhone,
      },
      notes: {
        address: "Petmojo",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    //console.log(paymentObject);
    paymentObject.open();
  }

  const checkDateValidationHandler = () => {
    if (newDateRef.current.value === "") {
      setNewDateValidation(false);
    } else {
      getmobileOtp();
      setShowEnterOtp(true)
    }
  }

  useEffect(() => {
    if (flag) navigate('/ordersummary', { state: paymentData });
  }, [paymentData, flag])

  const submitGroomingData = async () => {

    console.log("Function is called!");

    if ((inputName !== "") && (inputPhone.toString().length === 10) && (inputEmail !== "") && (inputAddress !== "") && (inputDate !== "")) {
      setGroomingLoading(true);

      const elapsed = Date.now();
      const date = new Date(elapsed);
      const dateOfReg = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
      const timeOfReg = `${date.toLocaleTimeString('en-US')}`;

      const data = {
        ContactNo: inputPhone,
        Services: selectedService,
        Name: inputName,
        Date: inputDate,
        Time: timeSlot,
        Address: inputAddress,
        DateOfRegistration: dateOfReg,
        TimeOfRegistration: timeOfReg,
        Email: inputEmail,
        FormType: "CTA Page Form"
      };

      var amount = 0;

      if (selectedService === "BATH & BRUSH") {//kartik
        amount = 799;
      }
      else if (selectedService === "BATH & BRUSH (With Tick Treatment)") {
        amount = 1199;
      }
      else if (selectedService === "HAIRCUT & STYLING") {
        amount = 1249;
      }
      else if (selectedService === "BATH & FULL HAIRCUT") {
        amount = 1799;
      }

      await axios.post("https://api.apispreadsheets.com/data/GWyR613yTiDqBzxn/", data)
        .then((response) => {
          console.log(response);
        })

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        "event": "Grooming Registration Fired"
      });

      setGroomingLoading(false);

      displayRazorpay(amount, inputPhone, selectedService, dateOfReg, inputAddress);

      //navigate("/thankPage");
    } else {
      if (inputName === "") {
        setValidName(false);
      }
      if (inputPhone.toString().length !== 10) {
        setValidPhone(false);
      }
      if (inputEmail === "") {
        setValidEmail(false);
      }
      if (inputAddress === "") {
        setValidAddress(false);
      }
      if (inputDate === "") {
        setValidDate(false);
      }
      if (inputTime === "") {
        setValidTime(false);
      }

      return;
    }
  }

  const getmobileOtp = useCallback(async () => {
    try {
      const res = await axios.post(`${baseUrl}/auth/sendMobileOTP`, {
        phoneNumber: newPhoneRef.current.value.toString()
      })
      console.log(res.data)

    } catch (error) {
      console.log(error)

    }
  },
    [],
  )

  const verifyMobileOTP = useCallback(async () => {
    try {
      const res = await axios.post("https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/auth/verifyMobileOTP", {
        otp: groomingOtpRef.current.value.toString(),
        phoneNumber: newPhoneRef.current.value.toString(),
        type: "User"
      })
      console.log(res.status)
      console.log(res.data)

      if (res.status === 200) {
        setIsVerified(true)
        setToken(res.data.token)
      }
    }
    catch (error) {
      console.log(error.response.status)
      console.log(error)
      if (error.response.status === 400) {
        setIsVerified(false)
        setIsWrongOtp(true)
      }
    }
  }, []);


  const paymentInitiationHandler = async () => {
    const phone = newPhoneRef.current.value;
    const name = newNameRef.current.value;
    const address = newAddressRef.current.value;

    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString('en-US')}`;

    const phoneString = phone.toString();

    const enteredYear = parseInt(selectedDate.substring(0, 4));
    const enteredMonth = parseInt(selectedDate.substring(5, 7));
    const enteredDay = parseInt(selectedDate.substring(8, 10));

    if (phoneString.length !== 10 || name === "" || address === "") {
      console.log("cannot proceed further!");
    } else {

      // if(enteredMonth === date.getMonth()+1){
      //     if(enteredDay <= date.getDate()){
      //       setNewDateValidation(false);
      //       return;
      //     }
      // }

      setIsLoading(true);

      setOverallChoice({
        choice: serviceChoice,
        name: name,
        address: address,
        phone: phone,
        date: selectedDate,
        time: selectedTime
      });

      const data = {
        ContactNo: phone,
        Services: serviceChoice,
        Date: selectedDate,
        Time: selectedTime,
        Email: "",
        FormType: "Direct Form",
        DateOfRegistration: dateOfReg,
        TimeOfRegistration: timeOfReg,
        Address: address,
        Name: name,
      }

      // ContactNo: inputPhone,
      // Services: selectedService,
      // Date: inputDate,
      // Time: inputTime,
      // Address: inputAddress,
      // DateOfRegistration: dateOfReg,
      // TimeOfRegistration: timeOfReg,
      // Email: inputEmail,
      // FormType: "Direct Form"

      var amount = 0;

      if (data.Services === "BATH & BRUSH") {//kartik
        amount = 799;
      }
      else if (data.Services === "BATH & BRUSH (With Tick Treatment)") {
        amount = 1199;
      }
      else if (data.Services === "HAIRCUT & STYLING") {
        amount = 1249;
      }
      else if (data.Services === "BATH & FULL HAIRCUT") {
        amount = 1799;
      }

      let UTM_Source, UTM_Medium, UTM_Campaign = "";

      const urlParams = new URLSearchParams(window.location.search);
      UTM_Source = urlParams.get('utm_source');
      UTM_Medium = urlParams.get('utm_medium');
      UTM_Campaign = urlParams.get('utm_campaign')

      const data2 = {
        fullName: name,
        address: address,
        phone: phone,
        startDate: selectedDate,
        sessionTime: selectedTime,
        package: {
          description: serviceChoice,
          price: amount,
          frequency: 1,
        },
        type: "User",
        UTM_Source: UTM_Source || "",
        UTM_Medium: UTM_Medium || "",
        UTM_Campaign: UTM_Campaign || "",
      }
      data2["Amount"] = amount;

      await axios.post('https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/websiteDogGroomingBookingService', data2, {
        headers: {
          'Content-Type': 'application/json',
          'authorization': token,
        }
      })
        .then((response) => {
          console.log(response);
          setBookingId(response.bookingId);
          console.log("The grooming data is submitted to the database.")
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          console.log("There was some error in saving the data in database.")
        })

      paymentGatewayHit(data);
      return;
    }

    if (phoneString.length !== 10) {
      setNewPhoneValidation(false);
    }
    if (name === "") {
      setNewNameValidation(false);
    }
    if (address === "") {
      setNewAddressValidation(false);
    }
  }


  const bookNowPayLaterHandler = async () => {
    const phone = newPhoneRef.current.value;
    const name = newNameRef.current.value;
    const address = newAddressRef.current.value;

    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString('en-US')}`;

    const phoneString = phone.toString();

    const enteredYear = parseInt(selectedDate.substring(0, 4));
    const enteredMonth = parseInt(selectedDate.substring(5, 7));
    const enteredDay = parseInt(selectedDate.substring(8, 10));

    if (phoneString.length !== 10 || name === "" || address === "") {
      console.log("cannot proceed further!");
    } else {
      // if(enteredMonth === date.getMonth()+1){
      //     if(enteredDay <= date.getDate()){
      //       setNewDateValidation(false);
      //       return;
      //     }
      // }

      setIsLoading(true);

      setOverallChoice({
        choice: serviceChoice,
        name: name,
        address: address,
        phone: phone,
        date: selectedDate,
        time: selectedTime
      });

      const data = {
        ContactNo: phone,
        Services: serviceChoice,
        Date: selectedDate,
        Time: selectedTime,
        Email: "",
        FormType: "Direct Form",
        DateOfRegistration: dateOfReg,
        TimeOfRegistration: timeOfReg,
        Address: address,
        Name: name
      };

      var amount = 0;

      if (data.Services === "BATH & BRUSH") {//kartik
        amount = 799;
      }
      else if (data.Services === "BATH & BRUSH (With Tick Treatment)") {
        amount = 1199;
      }
      else if (data.Services === "HAIRCUT & STYLING") {
        amount = 1249;
      }
      else if (data.Services === "BATH & FULL HAIRCUT") {
        amount = 1799;
      }

      axios.post('https://api.apispreadsheets.com/data/GWyR613yTiDqBzxn/', data)
        .then((response) => {
          console.log(response);
          console.log("The grooming form is being submitted...")
        })
        .catch((err) => {
          console.log(err);
          console.log("There was some error in submitting the grooming form...")
        })

      let UTM_Source, UTM_Medium, UTM_Campaign = "";

      const urlParams = new URLSearchParams(window.location.search);
      UTM_Source = urlParams.get('utm_source');
      UTM_Medium = urlParams.get('utm_medium');
      UTM_Campaign = urlParams.get('utm_campaign')
      const data2 = {
        fullName: name,
        address: address,
        phone: phone,
        startDate: selectedDate,
        sessionTime: selectedTime,
        package: {
          description: serviceChoice,
          amount: amount,
          frequency: 1,
        },
        type: "User",
        UTM_Source: UTM_Source || "",
        UTM_Medium: UTM_Medium || "",
        UTM_Campaign: UTM_Campaign || "",
      }

      data2["Amount"] = amount;
      console.log(data2);

      await axios.post('https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/websiteDogGroomingBookingService', data2, {
        headers: {
          'Content-Type': 'application/json',
          'authorization': token,
        }
      })
        .then((response) => {
          console.log(response);
          console.log("The grooming data is submitted to the database.")
        })
        .catch((err) => {
          console.log(err);
          console.log("There was some error in saving the data in database.")
        })

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        "event": `Grooming Registration Fired From Desktop Service Page Form`
      });

      navigate("/thankPage");
    }

    if (phoneString.length !== 10) {
      setNewPhoneValidation(false);
    }
    if (name === "") {
      setNewNameValidation(false);
    }
    if (address === "") {
      setNewAddressValidation(false);
    }

  }

  const location = useLocation();
  let type;

  if (location.state) {
    type = location.state?.type;
  }
  if (data?.type) {
    type = data?.type;
  }

  return (
    <div>

      {
        !changeView ? (
          <div>
            {(type === "dog-training" || type === "dog-running") ? (
              <div>
                {submit ? null : (
                  <div className="popup">
                    <div className="popup-header-container">
                      <div className="popup-header">
                        <span className="header-pink">FREE</span> {type === "dog-training" ? "Training" : "Walking"} Session
                      </div>
                      <div className="disclaimer">
                        <div className="disclaimer-heading">
                          Your Data Privacy Matters
                        </div>
                        <div className="disclaimer-text">
                          We only use your contact number to reach you regarding your service request.
                        </div>
                      </div>
                    </div>
                    <div className="popup-input-container">
                      <form id="contactForm">
                        <div className="phone-number-text">
                          Phone Number
                        </div>
                        {
                          valid ? null : (
                            <div className="validationText">
                              Please enter a valid phone number!
                            </div>
                          )
                        }
                        <input
                          type="number"
                          name="phoneNoTraining/Walking"
                          className={valid ? "popup-phone-input" : "popup-phone-input-invalid"}
                          onChange={(e) => {
                            setValid(true)
                            setPhNo(e.target.value)
                          }}
                          placeholder="Enter Phone Number (10 digits)"
                          maxLength="10"
                          minLength="10"
                        ></input>
                      </form>
                      {
                        loading ? (
                          <div className="spinnerContainer">
                            <LoadingSpinner />
                          </div>
                        ) : (
                          <div style={{ marginTop: "10px" }}>
                            <div className="bookingbutton popup-cta">
                              <button className="booksession" onClick={() => submitData(type)}>
                                Book a FREE Session
                              </button>
                            </div>
                            <div className="limited-container">
                              <div className="limited-message">
                                Limited Slots Left. Book Now!
                              </div>
                            </div>
                          </div>

                        )
                      }


                      <div className='more-footer-container partner-footer'>
                        {/* <LazyLoad> */}
                        <img className='petmojo-logo' src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/petmojo-dark-logo-.png" ></img>
                        {/* </LazyLoad> */}

                        <div className='footer'>
                          <div>Copyright 2024 Petmojo</div>
                          <div>All copyrights are reserved - any infringement will be legally pursued</div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="desktop-view">


                {submit ? null : (

                  <div className="popup">

                    <div className="popup-header-container">
                      {/* {
                  type==="boarding"&&
                  <div className="popup-header-grooming">
                    <span className="header-pink">BOOK</span> Boarding Session
                  </div>
                } */}
                      {(type === "grooming") && <div className="popup-header-grooming">
                        <span className="header-pink">BOOK</span> Grooming Session
                      </div>
                      }
                      <div className="disclaimer">
                        <div className="disclaimer-heading">
                          Your Data Privacy Matters
                        </div>
                        <div className="disclaimer-text">
                          We only use your contact number to reach you regarding your service request.
                        </div>
                      </div>
                    </div>
                    {/* //////////////////////////////////////////////// */}


                    <form id="groomingFormData">
                      <div className="popup-input-container">

                        <div className="options-title">
                          Select service of your choic
                        </div>
                        <div className="options-container-grooming">
                          <div className="option-box-grooming">
                            <div className="grooming-option">
                              {selectedFirst ? (
                                <LazyLoad>
                                  <img src={"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/radio-selected_thqfzr.png"} className="radio-icon"></img>
                                </LazyLoad>
                              ) : (
                                <LazyLoad>
                                  <img
                                    src={"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/radio-unselected_zoagnz.png"}
                                    className="radio-icon"
                                    onClick={() => {
                                      setSelectedFirst(true);
                                      setSelectedSecond(false);
                                      setSelectedThird(false);
                                      setSelectedNew(false);
                                    }}
                                  ></img>
                                </LazyLoad>
                              )}
                              <div className="option-name">BATH & BRUSH</div>
                            </div>
                            <div className="option-price">₹799</div>
                          </div>

                          <div className="option-box-grooming">
                            <div className="grooming-option">
                              {selectedNew ? (
                                <LazyLoad>
                                  <img src={"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/radio-selected_thqfzr.png"} className="radio-icon"></img>
                                </LazyLoad>
                              ) : (
                                <LazyLoad>
                                  <img
                                    src={"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/radio-unselected_zoagnz.png"}
                                    className="radio-icon"
                                    onClick={() => {
                                      setSelectedFirst(false);
                                      setSelectedSecond(false);
                                      setSelectedThird(false);
                                      setSelectedNew(true);
                                    }}
                                  ></img>
                                </LazyLoad>
                              )}
                              <div className="option-name">BATH & BRUSH <span><i>(With Tick Treatment)</i></span></div>
                            </div>
                            <div className="option-price">₹1199</div>
                          </div>

                          <div className="option-box-grooming">
                            <div className="grooming-option">
                              {selectedSecond ? (
                                <LazyLoad>
                                  <img src={"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/radio-selected_thqfzr.png"} className="radio-icon"></img>
                                </LazyLoad>
                              ) : (
                                <LazyLoad>
                                  <img
                                    src={"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/radio-unselected_zoagnz.png"}
                                    className="radio-icon"
                                    onClick={() => {
                                      setSelectedFirst(false);
                                      setSelectedSecond(true);
                                      setSelectedThird(false);
                                      setSelectedNew(false);
                                    }}
                                  ></img>
                                </LazyLoad>
                              )}
                              <div className="option-name">HAIRCUT & STYLING</div>
                            </div>
                            <div className="option-price">₹1249</div>
                          </div>

                          <div className="option-box-grooming">
                            <div className="grooming-option">
                              {selectedThird ? (
                                <LazyLoad>
                                  <img src={"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/radio-selected_thqfzr.png"} className="radio-icon"></img>
                                </LazyLoad>
                              ) : (
                                <LazyLoad>
                                  <img
                                    src={"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/radio-unselected_zoagnz.png"}
                                    className="radio-icon"
                                    onClick={() => {
                                      setSelectedFirst(false);
                                      setSelectedSecond(false);
                                      setSelectedThird(true);
                                      setSelectedNew(false);
                                    }}
                                  ></img>
                                </LazyLoad>
                              )}
                              <div className="option-name">BATH & FULL HAIRCUT</div>
                            </div>
                            <div className="option-price">₹1799</div>
                          </div>
                        </div>


                        <div className="name-input-container">
                          <div className="phone-number-text-grooming">
                            Phone Number
                            {
                              validPhone ? null : (
                                <div className="validationText-grooming-phone">
                                  Please enter a valid phone number!
                                </div>
                              )
                            }
                          </div>
                          <input
                            type="number"
                            className={validPhone ? "popup-phone-input" : "popup-phone-input-invalid"}
                            ref={phoneRef}
                            onChange={(e) => {
                              setInputText(e.target.value);
                              setInputPhone(e.target.value);
                              setValidPhone(true);
                            }}
                            placeholder="Enter Your Number"
                          ></input>
                        </div>




                        <div className="name-input-container">
                          <div className="phone-number-text-grooming">Name
                            {
                              validName ? null : (
                                <div className="validationText-grooming-name">
                                  Required!
                                </div>
                              )
                            }
                          </div>
                          <input
                            type="text"
                            className={validName ? "popup-phone-input" : "popup-phone-input-invalid"}
                            ref={nameRef}
                            onChange={(e) => {
                              setInputText(e.target.value);
                              setInputName(e.target.value);
                              setValidName(true);
                            }}
                            placeholder="Enter Your Name"
                          ></input>
                        </div>


                        <div className="name-input-container">
                          <div className="phone-number-text-grooming">Email
                            {
                              validEmail ? null : (
                                <div className="validationText-grooming-address">
                                  Required!
                                </div>
                              )
                            }
                          </div>
                          <input
                            type="text"
                            ref={emailRef}
                            className={validEmail ? "popup-phone-input" : "popup-phone-input-invalid"}
                            onChange={(e) => {
                              setInputText(e.target.value);
                              setInputEmail(e.target.value)
                            }}
                            placeholder="Enter your email"
                          ></input>
                        </div>


                        <div className="date-time-container">
                          <div className="name-input-container-1">
                            <div className="phone-number-text-grooming-1">Date
                              {
                                validDate ? null : (
                                  <div className="validationText-grooming-date">
                                    Required!
                                  </div>
                                )
                              }
                            </div>
                            <input
                              type="date"
                              className={validDate ? "popup-phone-input date-time-input" : "popup-phone-input-invalid date-time-input"}
                              ref={dateRef}
                              style={{ color: "#5E5E5E" }}
                              onChange={(e) => {
                                setInputText(e.target.value);
                                setInputDate(e.target.value)
                                setValidDate(true)
                              }}
                              placeholder="DD/MM/YYYY"
                            ></input>
                          </div>

                          {/* <div className="name-input-container">
                    <div className="phone-number-text-grooming">Time</div>
                    <div
                      style={{color: "#5E5E5E"}}
                      onClick={() => {
                        setShowTimeSlots(!showTimeSlots);
                        setValidTime(true);
                      }}
                      className={validTime ? "popup-phone-input date-time-input time-input" : "popup-phone-input-invalid date-time-input time-input"}
                    >
                      <div className={(timeSlot === "Time Slot") ? "timeSlotText" : ""}>{timeSlot}</div>
                      <div className="timeSlotIcon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock-fill" viewBox="0 0 16 16">
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
                        </svg>
                      </div>
                    </div>
                  </div> */}
                          {/* {
                    validTime ? null : (
                      <div className="validationText-grooming-time">
                        Required!
                      </div>
                    )
                  } */}
                        </div>

                        <div className="name-input-container">
                          <div className="phone-number-text-grooming">Address
                            {
                              validAddress ? null : (
                                <div className="validationText-grooming-address">
                                  Required!
                                </div>
                              )
                            }
                          </div>
                          <input
                            type="text"
                            ref={addressRef}
                            className={validAddress ? "popup-phone-input" : "popup-phone-input-invalid"}
                            onChange={(e) => {
                              setInputText(e.target.value);
                              setInputAddress(e.target.value)
                            }}
                            placeholder="Enter Your Home Address"
                          ></input>
                        </div>

                      </div>
                    </form>

                    {
                      groomingLoading ? (
                        <div className="spinnerContainer">
                          <LoadingSpinner />
                        </div>
                      ) : (
                        <div>
                          <div className="bookingbutton popup-cta">
                            <button className="booksession-grooming-form" onClick={() => submitGroomingData()}>
                              Book Now
                            </button>
                          </div>
                        </div>
                      )
                    }


                    <br />
                    <br />

                    <div className='more-footer-container partner-footer'>
                      <LazyLoad>
                        <img className='petmojo-logo' src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_6_7_v4zlsi.png" ></img>
                      </LazyLoad>

                      <div className='footer'>
                        <div>Copyright 2024 Petmojo</div>
                        <div>All copyrights are reserved - any infringement will be legally pursued</div>
                      </div>
                    </div>
                    <div>{""} </div>
                    <div>{""} </div>

                  </div>
                )}
              </div>
            )}
          </div>

        ) : (
          <div>



            {(type === "dog-training" || type === "dog-running") ? (
              <div>
                {submit ? null : (
                  <div className="popup">
                    <div className="popup-desktop-training-container">

                      <div className="popup-training-walking-left">

                        <div className="popup-header-container">
                          <div className="popup-header">
                            <span className="header-pink">FREE</span> {type === "dog-training" ? "Training" : "Walking"} Session
                          </div>
                          <div className="disclaimer">
                            <div className="disclaimer-heading">
                              Your Data Privacy Matters
                            </div>
                            <div className="disclaimer-text">
                              We only use your contact number to reach you regarding your service request.
                            </div>
                          </div>
                        </div>

                        <div className="popup-input-container-desktop">
                          <form id="contactForm">
                            <div className="phone-number-text">
                              Phone Number
                            </div>
                            {
                              valid ? null : (
                                <div className="validationText">
                                  Please enter a valid phone number!
                                </div>
                              )
                            }
                            <input
                              type="number"
                              name="phoneNoTraining/Walking"
                              className={valid ? "popup-phone-input" : "popup-phone-input-invalid"}
                              onChange={(e) => {
                                setValid(true)
                                setPhNo(e.target.value)
                              }}
                              placeholder="Enter Phone Number (10 digits)"
                              maxLength="10"
                              minLength="10"
                            ></input>
                          </form>
                          {
                            loading ? (
                              <div className="spinnerContainer">
                                <LoadingSpinner />
                              </div>
                            ) : (
                              <div style={{ marginTop: "10px" }}>
                                <div className="bookingbutton popup-cta">
                                  <button className="booksession" onClick={() => submitData(type)}>
                                    Book a FREE Session
                                  </button>
                                </div>
                                <div className="limited-container">
                                  <div className="limited-message">
                                    Limited Slots Left. Book Now!
                                  </div>
                                </div>
                              </div>

                            )
                          }
                        </div>
                      </div>
                      <div className="popup-desktop-image-train">
                        <img alt="popupImage" className="popup-image-desktop-train" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/2V3A7175-removebg-preview_1_k0jy5a.png" />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                {submit ? null : (
                  <div className="popup">
                    <div className="popup-grooming-desktop-master-container">

                      <div className="popup-grooming-desktop-left">
                        <div className="popup-header-container">
                          {/* {type === "boarding"&&
                    <div className="popup-header-grooming-desktop">
                      <span className="header-pink">BOOK</span> Grooming Session
                    </div>
                    } */}

                          <div className="popup-header-grooming-desktop">
                            <span className="header-pink">BOOK</span> Grooming Session
                          </div>

                          <div className="disclaimer">
                            <div className="disclaimer-heading">
                              Your Data Privacy Matters
                            </div>
                            <div className="disclaimer-text">
                              We only use your contact number to reach you regarding your service request.
                            </div>
                          </div>
                        </div>


                        <div onClick={() => setServiceDropdown(!serviceDropdown)} className='grooming-popup-page-desktop-view-service-form'>
                          <div>Select service of your choice</div>
                          <div className='groming-page-desktop-view-services-container'>
                            <div className='grooming-page-mobile-view-selected-service'>{selectedService}</div>
                            <div className='home-page-form-mobile-view-dropdown-arrow'>
                              <svg className='grooming-page-form-mobile-view-dropdown-arrow-img' xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                              </svg>
                            </div>
                          </div>
                          {
                            serviceDropdown ? (
                              <div className="grooming-popup-page-mobile-view-service-drop-container">
                                <div onClick={() => setSelectedServiceHandler("BATH & BRUSH")} className="grooming-page-desktop-view-drop-item">
                                  <div className="grooming-page-mobile-view-drop-item-text">BATH & BRUSH</div>
                                  <div className="grooming-page-mobile-view-drop-item-price">₹799</div>
                                </div>
                                <div onClick={() => setSelectedServiceHandler("BATH & BRUSH (With Tick Treatment)")} className="grooming-page-desktop-view-drop-item">
                                  <div className="grooming-page-mobile-view-drop-item-text">BATH & BRUSH<span><i>(With Tick Treatment)</i>&nbsp;&nbsp;</span></div>
                                  <div className="grooming-page-mobile-view-drop-item-price">₹1199</div>
                                </div>
                                <div onClick={() => setSelectedServiceHandler("HAIRCUT & STYLING")} className="grooming-page-desktop-view-drop-item">
                                  <div className="grooming-page-mobile-view-drop-item-text">HAIRCUT & STYLING</div>
                                  <div className="grooming-page-mobile-view-drop-item-price">₹1249</div>
                                </div>
                                <div onClick={() => setSelectedServiceHandler("BATH & FULL HAIRCUT")} className="grooming-page-desktop-view-drop-item">
                                  <div className="grooming-page-mobile-view-drop-item-text">BATH & FULL HAIRCUT</div>
                                  <div className="grooming-page-mobile-view-drop-item-price">₹1799</div>
                                </div>
                              </div>
                            ) : null
                          }
                        </div>
                        <div className="date-time-container-desktop">
                          <div className="name-input-container-1" style={{ width: "100%" }}>
                            <div className="date-time-desktop-bundle">
                              <div className="phone-number-text-grooming">Date</div>
                              {
                                newDateValidation ? null : (
                                  <div className="red-feedback-text-desktop">
                                    Required!
                                  </div>
                                )
                              }
                            </div>
                            <div className="new-date-ref-container">
                              <input ref={newDateRef} className='grooming-new-form-desktop-input-container-type1' placeholder="DD/MM/YY" type="date" value={data?.date || ""}></input>
                            </div>
                          </div>
                          <div className="name-input-container-1" style={{ width: "100%", marginLeft: "20px" }}>
                            <div className="date-time-desktop-bundle">
                              <div className="phone-number-text-grooming">Time Slot</div>
                            </div>
                            <div className='grooming-new-form-section2-right-time-slots'>
                              <div onClick={() => setTimeSlot(1)} className={timeSlot == 1 ? 'grooming-new-form-section2-right-time-slot-active' : 'grooming-new-form-section2-right-time-slot'}>10AM-02PM</div>
                              <div onClick={() => setTimeSlot(2)} className={timeSlot == 2 ? 'grooming-new-form-section2-right-time-slot-active' : 'grooming-new-form-section2-right-time-slot'}>02PM-06PM</div>
                              <div onClick={() => setTimeSlot(3)} className={timeSlot == 3 ? 'grooming-new-form-section2-right-time-slot-active' : 'grooming-new-form-section2-right-time-slot'}>06PM-10PM</div>
                            </div>
                          </div>
                        </div>

                        <div className="name-input-container">
                          <div className="phone-number-desktop-bundle">
                            <div className="phone-number-text-grooming">Name</div>
                            {
                              newNameValidation ? null : (
                                <div className="red-feedback-text-desktop">
                                  Required!
                                </div>
                              )
                            }
                          </div>

                          <input onChange={() => setNewNameValidation(true)} ref={newNameRef} className='grooming-new-form-desktop-input-container-type1' placeholder='Enter your name' type="text" value={data?.name || ""} ></input>
                        </div>
                      </div>

                      <div className="popup-grooming-desktop-right">
                        <div className="popup-grooming-desktop-phone">
                          <div className="popup-grooming-desktop-phone-phone-container">
                            <div className="phone-number-desktop-bundle">
                              <div className="phone-number-text-grooming">
                                Phone Number
                              </div>
                              {
                                newPhoneValidation ? null : (
                                  <div className="red-feedback-text-desktop">
                                    Please enter a valid phone number!
                                  </div>
                                )
                              }
                            </div>
                          </div>
                          <div className="popup-phone-grooming-desktop-otp-handle-bundler">
                            <div className='grooming-new-form-section3-right-ph-input-contact'>
                              <input disabled={isVerified} onChange={() => { setNewPhoneValidation(true) }} value={data?.number || ""} ref={newPhoneRef} className={isVerified ? 'grooming-new-form-desktop-input-container-type1-verified' : 'grooming-new-form-desktop-input-container-type1'} placeholder='Contact number' type="number" required></input>
                            </div>

                            <div className="grooming-page-popup-enter-otp-container">
                              {isVerified !== true && showEnterOtp && (newPhoneRef.current.value.toString().length === 10) && <div className="enterotp-grooming-popup-page">
                                <div className='enterotp-heading'>
                                  <div>Enter OTP</div>
                                  {
                                    (isWrongOtp && !isVerified) ? (
                                      <div className='home-page-walking-form-mobile-feedback'>
                                        Wrong OTP
                                      </div>
                                    ) : (null)

                                  }
                                </div>
                                <input className={isVerified ? 'otp-input-container-desktop-verified' : 'otp-input-container-desktop'} ref={groomingOtpRef} onClick={() => { setIsWrongOtp(false) }} disabled={isVerified} type="number" placeholder='Enter Otp' />
                                {
                                  isVerified ? (null) : (
                                    <button className={isVerified ? 'verified-button' : 'non-verified-button'} disabled={isVerified} onClick={() => { verifyMobileOTP() }}>{isVerified ? "Verified" : "Verify"}</button>
                                  )
                                }
                              </div>}
                            </div>
                          </div>
                          <div className="grooming-popup-page-send-otp-container">
                            {
                              isVerified === true ? (null) : (
                                <div disabled={isVerified} onClick={() => { checkDateValidationHandler(); }} className='send-otp-button-desktop-container'>
                                  Send OTP
                                </div>
                              )
                            }
                          </div>
                        </div>


                        <div className="name-input-container-desktop">
                          <div className="phone-number-desktop-bundle">
                            <div className="phone-number-text-grooming">Address</div>
                            {
                              newAddressValidation ? null : (
                                <div className="red-feedback-text-desktop">
                                  Required!
                                </div>
                              )
                            }
                          </div>

                          <input onChange={() => setNewAddressValidation(true)} ref={newAddressRef} className='grooming-new-form-desktop-input-container-type1' placeholder='Enter your Home address' type="text" value={data?.address || ""}></input>
                        </div>
                        {
                          isLoading ? (<div className='grooming-page-loading-spinner-container-desktop'><LoadingSpinner /></div>) : (
                            <div className='grooming-new-form-section1-right-pay-button-container'>
                              <button disabled={isVerified === false || isVerified === null} onClick={() => paymentInitiationHandler()} className='grooming-new-form-section1-right-pay-button'>
                                Pay Now
                              </button>
                              <button disabled={isVerified === false || isVerified === null} onClick={() => bookNowPayLaterHandler()} className='grooming-new-form-section1-right-pay-later-button'>
                                Book Now & Pay Later
                              </button>
                            </div>
                          )
                        }

                      </div>



                    </div>

                    <br />
                    <br />
                    <div>{""} </div>
                    <div>{""} </div>

                  </div>
                )}
              </div>
            )}



          </div>
        )
      }


      <GoToTop />
    </div>
  );
}

export default Popup;
