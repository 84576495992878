import React from 'react'
import Popup from './Popup';

const DogGroomingBooking = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const type="grooming";
    const name = urlParams.get("name");
    const number = urlParams.get("number");
    const address = urlParams.get("address");
    const amount = urlParams.get("amount");
    const date = urlParams.get("date");
    const packageType = urlParams.get("package");
    const time = urlParams.get("time");

    return (
        <Popup data={{name, number, address, amount, date, packageType, time,type}}/>
    )
}

export default DogGroomingBooking