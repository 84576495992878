import { BiArrowBack } from "@react-icons/all-files/bi/BiArrowBack";
import { FaArrowAltCircleRight } from "@react-icons/all-files/fa/FaArrowAltCircleRight";
import { FaCheck } from "@react-icons/all-files/fa/FaCheck";
import { MdClose } from "@react-icons/all-files/md/MdClose";
import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "../../loadingSpinner/LoadingSpinner";
//import dotenv from 'dotenv'
import ImageSlider from "../../ImageSlider";

const images = [
  {
    id: 1,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/boarding-desktop-1.webp",
    alt: "Image 1",
  },
  {
    id: 2,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/boarding-desktop-2.webp",
    alt: "Image 2",
  },
  {
    id: 3,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/boarding-desktop-3.webp",
    alt: "Image 3",
  },
  {
    id: 4,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/boarding-desktop-4.webp",
    alt: "Image 4",
  },
  {
    id: 5,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/boarding-desktop-5.webp",
    alt: "Image 5",
  },
  {
    id: 6,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/boarding-desktop-6.webp",
    alt: "Image 6",
  },
  {
    id: 7,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/boarding-desktop-7.webp",
    alt: "Image 7",
  },
  {
    id: 8,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/boarding-desktop-8.webp",
    alt: "Image 8",
  },
  {
    id: 9,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/boarding-desktop-9.webp",
    alt: "Image 9",
  },
  {
    id: 10,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/boarding-desktop-10.webp",
    alt: "Image 10",
  },
];

const HomePageForm = (props) => {
  const navigate = useNavigate();
  const [selectedService, setSelectedService] = useState("");
  const [seconds, setSeconds] = useState(0);
  const [countdown, setCountdown] = useState(true);
  const [serviceDropdown, setServiceDropdown] = useState(false);
  const [selectedCarousel, setSelectedCarousel] = useState(1);
  const [timeSlot, setTimeSlot] = useState(1);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [serviceChoice, setServiceChoice] = useState("");
  const [serviceType, setServiceType] = useState("Health Resort Stay");
  const [pet, setPet] = useState("Small Pet");
  const [petChoice, setpetChoice] = useState(1);
  const location = useLocation().pathname;
  const [petnameValue, setPetnameValue] = useState(false);
  const [petnameFill, setPetnameFill] = useState("");
  const [isStartDateSelected, setIsStartDateSelected] = useState(false);
  const [isEndDateSelected, setIsEndDateSelected] = useState(false);
  const [pickupSlot, setPickupSlot] = useState(0);
  const [dropOffSlot, setDropOffSlot] = useState(0);
  const pickupTime = ["", "10AM-02PM", "02PM-06PM", "06PM-10PM"];
  const dropOffTime = ["", "10AM-02PM", "02PM-06PM", "06PM-10PM"];
  const [isPayNow, setIsPayNow] = useState(false);
  const [overallChoice, setOverallChoice] = useState({
    Pet: "",
    Service: "",
    amount: 0,
    PickupSlot: "",
    DropoffSlot: "",
    Name: "",
    Phone: "",
    Address: "",
    Latitude: 0,
    Longitude: 0,
    StartDate: "",
    EndDate: "",
    PetName: "",
    Package: "",
    Location: "",
  });
  const [newNameValidation, setNewNameValidation] = useState(true);
  const [newPetNameValidation, setPetNewNameValidation] = useState(true);
  const [newPhoneValidation, setNewPhoneValidation] = useState(true);
  const [newAddressValidation, setNewAddressValidation] = useState(true);
  const [newAddressValidation1, setNewAddressValidation1] = useState(true);
  const [newDateValidation, setNewDateValidation] = useState(true);
  const [newEndDateValidation, setNewEndDateValidation] = useState(true);
  const [representationDate, setRepresentationDate] = useState("");
  const [representationDay, setRepresentationDay] = useState("");
  const [isVerified, setIsVerified] = useState(null);
  const [showEnterOtp, setShowEnterOtp] = useState(false);
  const [isWrongOtp, setIsWrongOtp] = useState(false);
  const [correctOTP, setCorrectOTP] = useState(true);

  const [slotValue, setSlotValue] = useState(false);
  const [slotFill, setSlotFill] = useState("GURGAON (SEC 58)");
  const baseUrl =
    "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api";
  const [token, setToken] = useState("");
  const [bookingId, setBookingId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api
  const newDateRef = useRef("");
  const newEndDateRef = useRef("");
  const newServiceRef = useRef("");
  const newTimeServiceRef = useRef("");
  const newNameRef = useRef("");
  const newPetNameRef = useRef("");
  const newAddressRef = useRef("");
  const newAddressRef1 = useRef("");
  const newPhoneRef = useRef("");
  const groomingOtpRef = useRef("");
  const groomingSlotRef = useRef("");
  const [groomingSlotValidation, setGroomingSlotValidation] = useState(true);
  const [paymentData, setPaymentData] = useState({});
  const [flag, setFlag] = useState(false);
  // const { coords } = useGeolocated();
  const [address, setAddress] = useState("");
  const [startValue, setStartValue] = useState(false);
  const [endValue, setEndValue] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startAddress, setStartAddress] = useState(false);
  const [lat, setLat] = useState(0);
  const [lon, setLon] = useState(0);
  const [nameValue, setnameValue] = useState(false);
  const [nameFill, setNameFill] = useState("");
  const [phoneValue, setPhoneValue] = useState(false);
  const [phoneFill, setPhoneFill] = useState(null);
  const [addressValue, setAddressValue] = useState(false);
  const [adressFill, setAdressFill] = useState("");
  const [phone, setPhone] = useState("");
  const [nonvegMeal, setNonvegMeal] = useState(false);
  const [thirdMeal, setThirdMeal] = useState(false);
  const [dropOff, setDropOff] = useState(false);
  const [pickup, setPickup] = useState(false);
  const [daysDiff, setDaysDiff] = useState(0);
  const [displaySlots, setDisplaySlots] = useState(true);
  const [couponCode, setCouponCode] = useState("");
  const [couponValue, setCouponValue] = useState(0);
  const [couponErrorMsg, setCouponErrorMsg] = useState("");
  const [couponNotValid, setCouponNotValid] = useState(false);
  const [couponValid, setCouponValid] = useState(false);
  var currentDate = new Date();
  var year = currentDate.getFullYear();
  var month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
  var day = ("0" + currentDate.getDate()).slice(-2);
  var formattedDate = year + "-" + month + "-" + day;

  useEffect(() => {
    if (isStartDateSelected || isEndDateSelected) {
      var date1;
      var date2;
      if (!isStartDateSelected) {
        date1 = new Date(formattedDate);
      } else {
        date1 = new Date(startDate);
      }

      if (!isEndDateSelected) {
        date2 = new Date(formattedDate);
      } else {
        date2 = new Date(endDate);
      }

      // Calculate the time difference in milliseconds
      var timeDiff = date2.getTime() - date1.getTime();

      // Convert the time difference to days
      var daysDifference = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      console.log(daysDifference + 1);
      setDaysDiff(daysDifference + 1);
    }
  }, [startDate, endDate]);
  useEffect(() => {
    if (!pickup) setPickupSlot(0);
    if (!dropOff) setDropOffSlot(0);
  }, [pickup, dropOff]);
  useEffect(() => {
    if (daysDiff > 10) {
      setDisplaySlots(false);
    } else {
      setDisplaySlots(true);
    }
  }, [daysDiff]);
  function setSelectedServiceHandler(service) {
    setSelectedService(service);
    setServiceDropdown(false);
  }
  const firstFromBoardingHandler = () => {
    if (phoneFill == null || phoneFill == "") {
      setNewPhoneValidation(false);
    } else {
      setSelectedCarousel(2);
    }
  };
  const secondFormBoardingHandler = () => {
    if (nameFill == "") {
      setNewNameValidation(false);
    } else {
      setSelectedCarousel(3);
    }
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay(
    serviceAmount,
    inputPhone,
    SelectedService,
    DateOfReg,
    InputAddress
  ) {
    setIsLoading(false);

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // creating a new order
    const result = await axios.post(
      "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/generateGroomingRazorPayOrderId",
      {
        amount: serviceAmount,
      }
    );

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }

    // Getting the order details back
    const { amount, id: order_id, currency } = result.data;

    const options = {
      key: process.env.REACT_APP_KEY, // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: "Petmojo",
      description: "Pet Hotel Payment",
      //image: { logo },
      order_id: order_id,
      handler: async function (response) {
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };

        //console.log("Grooming Page Function: ", data);

        // const result = await axios.post(
        //   "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/generateGroomingRazorPaySuccess",
        //   data
        // );
        // //alert(result.data.msg);
        // const Data = {
        //   ...result.data,
        //   SelectedService,
        //   DateOfReg,
        //   InputAddress,
        // };

        const actualAmount = serviceAmount / 100;

        const dataToStore = {
          Date: DateOfReg,
          Address: InputAddress,
          Contact_No: inputPhone.toString(),
          Amount_Paid: actualAmount.toString(),
          Selected_Service: SelectedService,
          Order_id: order_id,
        };

        await axios
          .post(
            "https://api.apispreadsheets.com/data/dHCUmWHZrnyATifS/",
            dataToStore
          )
          .then((response) => {
            console.log(response);
            console.log("The baording payment has been completed!");
          })
          .catch((err) => {
            console.log(err);
            console.log(
              "There was some error in submitting the boarding payment to backend!"
            );
          });

        const data2 = {
          bookingId: bookingId,
          status: isPayNow ? 1 : 2,
          amount: actualAmount,
        };

        // {
        //     "bookingId" : "63d554eb156e83edfd5d1144",
        //     "transactionId" : "order_JCB9CaLSarOaTj",
        //     "amount":799,
        //     "type": "User"
        // }

        await axios
          .post(
            "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/service/postPetBoardingPayment",
            data2,
            {
              "Content-Type": "application/json",
              authorization: token,
            }
          )
          .then((response) => {
            console.log(response);
            console.log(
              "The grooming payment has been completed and info has been saved in the database!"
            );
            setIsPayNow(true);
            navigate("/ordersummaryboarding", {
              data: {
                ContactNo: newPhoneRef.current.value,

                Address: newAddressRef.current.value,
                StartDate:
                  overallChoice.StartDate == ""
                    ? formattedDate
                    : overallChoice.StartDate,
                EndDate:
                  overallChoice.EndDate == ""
                    ? formattedDate
                    : overallChoice.EndDate,

                Service: overallChoice.Service,
                ParentName: overallChoice.Name,
                PickupSlot: pickupTime[pickupSlot],
                DropoffSlot: dropOffTime[dropOffSlot],
                NonVegMealAdded: nonvegMeal ? "Yes" : "No",
                ThirdMealAdded: thirdMeal ? "Yes" : "No",
                PetSize: petChoice === 1 ? "Small Pet" : "Big Pet",
                Days: daysDiff,
                PackageAmount: petChoice === 1 ? 499 : 599,
                PayNow: isPayNow,
              },
            });
          })
          .catch((err) => {
            console.log(err);
            console.log(
              "There was some error in saving the grooming payment to database!"
            );
          });

        // setPaymentData(Data);
        // setFlag(true);
      },
      // prefill: {
      //     name: "Petmojo",
      //     email: "customercare@petmojo.in",
      //     contact: "+91 9009004473",
      // },
      notes: {
        address: "Petmojo",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    //console.log(paymentObject);
    paymentObject.open();
  }
  const [showDialog, setShowDialog] = useState(false);
  const checkPhone = (phoneString) => {
    if (
      phoneString.length > 10 &&
      (phoneString.startsWith("+91") || phoneString.startsWith("91"))
    ) {
      phoneString = phoneString.slice(-10);
      setPhone(phoneString);
      setShowDialog(true);
    }
    return phoneString;
  };
  const groomingFormDesktopHandler1 = () => {
    if (serviceType === "") {
      console.log("Cant move to next card!");
    } else {
      setOverallChoice({
        Pet: pet,
        Service: serviceType,
        // Package:serviceChoice,

        // latitude:lat,
        // longitude:lon
      });
      console.log(overallChoice);
      setSelectedCarousel(2);
    }
  };

  const groomingFormDesktopHandler2 = () => {
    overallChoice.StartDate = newDateRef.current.value;
    overallChoice.EndDate = newEndDateRef.current.value;
    if (overallChoice.StartDate == "" && overallChoice.EndDate == "") {
      console.log("Cant move to next card!");
      console.log(overallChoice);
    } else {
      setSelectedDate(newDateRef.current.value);
      // setSelectedTime(time);
      //overallChoice.time

      setOverallChoice({
        ...overallChoice,
        Package: serviceChoice,
        Pet: petChoice === 1 ? "Small Pet" : "Big Pet",
        StartDate: newDateRef.current.value,
        EndDate: newEndDateRef.current.value,
        PickupSlot: pickupTime[pickupSlot],
        DropoffSlot: dropOffTime[dropOffSlot],
        amount: petChoice === 1 ? 499 : 599,
        // latitude:lat,
        // longitude:lon
      });
      console.log(overallChoice);
      setSelectedCarousel(3);
    }
  };

  const getmobileOtp = useCallback(async () => {
    try {
      let phoneString = checkPhone(newPhoneRef.current.value.toString());
      const res = await axios.post(`${baseUrl}/auth/sendMobileOTP`, {
        phoneNumber: phoneString,
      });
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  }, []);
  const changeName = () => {
    document.getElementById("send-otp-desk").innerHTML = "Resend OTP";
  };
  const verifyMobileOTP = useCallback(async () => {
    try {
      let phoneString = checkPhone(newPhoneRef.current.value.toString());
      const res = await axios.post(
        "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/auth/verifyMobileOTP",
        {
          otp: groomingOtpRef.current.value.toString(),
          phoneNumber: phoneString,
          type: "User",
        }
      );
      console.log(res.status);
      console.log(res.data);

      if (res.status === 200) {
        setIsVerified(true);
        setToken(res.data.token);
        setCountdown(false);
        setCorrectOTP(true);
      }
    } catch (error) {
      console.log(error.response.status);
      console.log(error);
      if (error.response.status === 400) {
        setIsVerified(false);
        setIsWrongOtp(true);
        setCorrectOTP(false);
      }
    }
  }, []);
  const indicateAlert = () => {
    if (!correctOTP) {
      document.getElementById("send-otp-desk").style.borderColor = "red";
    }
  };
  const backArrow = () => {
    setSelectedCarousel(selectedCarousel - 1);
  };
  const decrementSeconds = () => {
    // if(seconds===0){
    //     document.getElementById("resend-countdown").style.display="none"
    // }
    // else{
    setTimeout(() => {
      if (seconds == 0) {
        document.getElementById("countdown-seconds").style.display = "none";
      } else {
        setSeconds(seconds - 1);
      }
    }, 1000);
    // }
  };
  const paymentGatewayHit = async (data) => {
    const path = "boarding";
    const inputPhone = data.ContactNo;
    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${date.getMonth() + 1
      }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;
    const inputAddress = newAddressRef.current.value;
    const service = "Health Resort Stay";

    var amount = 0;

    if (petChoice == 1) {
      amount += 499 * daysDiff;
    } else {
      amount += 599 * daysDiff;
    }

    if (thirdMeal) {
      amount += 59 * daysDiff;
    }
    if (nonvegMeal) {
      amount += 99 * daysDiff;
    }
    if (daysDiff <= 10) {
      amount += 990;
    }
    amount += 990;

    axios
      .post(
        "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/saveBoardingData",
        data
      )
      .then((response) => {
        console.log(response);
        console.log("The boarding form is being submitted...");
      })
      .catch((err) => {
        console.log(err);
        console.log("There was some error in submitting the boarding form...");
      });

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: `${path} Registration Fired From Desktop Service Page Form`,
    });

    displayRazorpay(
      amount - couponValue,
      inputPhone,
      service,
      dateOfReg,
      inputAddress
    );

    console.log(data);
  };

  const paymentInitiationHandler = async () => {
    console.log(phoneFill, nameFill, startDate, endDate);
    const phone = overallChoice.Phone;
    const phoneString = checkPhone(phoneFill);
    const name = nameFill;
    // const address = newAddressRef.current.value;

    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${date.getMonth() + 1
      }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;

    const enteredYear = parseInt(selectedDate.substring(0, 4));
    const enteredMonth = parseInt(selectedDate.substring(5, 7));
    const enteredDay = parseInt(selectedDate.substring(8, 10));

    if (phoneString.length !== 10 || name === "") {
      console.log("cannot proceed further!");
    } else {
      // if(enteredMonth === date.getMonth()+1){
      //     if(enteredDay <= date.getDate()){
      //       setNewDateValidation(false);
      //       return;
      //     }
      // }

      setIsLoading(true);

      setOverallChoice({
        Pet: petChoice == 1 ? "Small Pet" : "Big Pet",
        StartDate: startDate,
        EndDate: endDate,
        Name: name,
        Phone: phone,
        Address: address,
        Service: serviceType,
        PickupSlot: pickupTime[pickupSlot],
        DropoffSlot: dropOffTime[dropOffSlot],
      });

      const data = {
        ContactNo: phoneFill,
        TimeOfRegistration: timeOfReg,
        DateOfRegistration: dateOfReg,
        Address: address,
        StartDate:
          overallChoice.StartDate == ""
            ? formattedDate
            : overallChoice.StartDate,
        EndDate:
          overallChoice.EndDate == "" ? formattedDate : overallChoice.EndDate,
        FormType: "Direct Form",
        Service: overallChoice.Service,
        // Package: overallChoice.Package,
        ParentName: nameFill,
        // PetName: overallChoice.PetName,
        PickupSlot: pickupTime[pickupSlot],
        DropoffSlot: dropOffTime[dropOffSlot],
        NonVegMealAdded: nonvegMeal ? "Yes" : "No",
        ThirdMealAdded: thirdMeal ? "Yes" : "No",
        PetSize: petChoice === 1 ? "Small Pet" : "Big Pet",
      };

      var amount = 0;

      const data2 = {
        petDetails: [
          {
            size: petChoice == 1 ? "small" : "big",
          },
        ],
        phoneNumber: phoneString,
        startDate: startDate == "" ? formattedDate : startDate,
        endDate: endDate == "" ? formattedDate : endDate,
        isThirdMeal: thirdMeal,
        isNonVegMeal: nonvegMeal,
        address: address,
        petParentName: nameFill,
        isCouponCodeUsed: couponValue === 0 ? false : true,
        couponCode: couponValue !== 0 ? couponCode : "",
        couponValue: couponValue,
      };
      // serviceBooking/postPetBoardingDetails
      await axios
        .post(
          "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/postPetBoardingDetails",
          data2,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: token,
            },
          }
        )
        .then((response) => {
          console.log("response--->", response);
          setBookingId(response.bookingId);
          console.log("The boarding data is submitted to the database.");
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          console.log("There was some error in saving the data in database.");
        });
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: `Boarding Registration Fired From Desktop Service Page Form`,
      });
      paymentGatewayHit(data);
      return;
    }

    if (phoneString.length !== 10) {
      setNewPhoneValidation(false);
    }
    if (name === "") {
      setNewNameValidation(false);
    }
    if (address === "") {
      setNewAddressValidation(false);
    }
  };

  useEffect(() => {
    if (flag) navigate("/ordersummary", { state: paymentData });
  }, [paymentData, flag]);

  const date = new Date();

  // declaration of refs
  const phoneRef = useRef("");
  const emailRef = useRef("");
  const dateRef = useRef("");
  const addressRef = useRef("");
  const timeRef = useRef("");
  const [addressFill, setAddressFill] = useState(false);

  useEffect(() => {
    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${date.getMonth() + 1
      }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;

    var MonthName;
    if (date.getMonth() + 1 == 1) {
      MonthName = "January";
    } else if (date.getMonth() + 1 == 2) {
      MonthName = "February";
    } else if (date.getMonth() + 1 == 3) {
      MonthName = "March";
    } else if (date.getMonth() + 1 == 4) {
      MonthName = "April";
    } else if (date.getMonth() + 1 == 5) {
      MonthName = "May";
    } else if (date.getMonth() + 1 == 6) {
      MonthName = "June";
    } else if (date.getMonth() + 1 == 7) {
      MonthName = "July";
    } else if (date.getMonth() + 1 == 8) {
      MonthName = "August";
    } else if (date.getMonth() + 1 == 9) {
      MonthName = "September";
    } else if (date.getMonth() + 1 == 10) {
      MonthName = "October";
    } else if (date.getMonth() + 1 == 11) {
      MonthName = "November";
    } else if (date.getMonth() + 1 == 12) {
      MonthName = "December";
    }

    var DayName;
    if (date.getDay() + 1 == 1) DayName = "Sunday";
    else if (date.getDay() + 1 == 2) DayName = "Monday";
    else if (date.getDay() + 1 == 3) DayName = "Tuesday";
    else if (date.getDay() + 1 == 4) DayName = "Wednesday";
    else if (date.getDay() + 1 == 5) DayName = "Thursday";
    else if (date.getDay() + 1 == 6) DayName = "Friday";
    else if (date.getDay() + 1 == 7) DayName = "Saturday";

    const repDate =
      MonthName + " " + date.getDate() + ", " + date.getFullYear() + " | ";
    const repDay = DayName;
    setRepresentationDate(repDate);
    setRepresentationDay(repDay);
  }, []);
  useEffect(() => {
    indicateAlert();
  }, [correctOTP]);

  useEffect(() => {
    decrementSeconds();
  }, [seconds]);
  const handleLocationAccess = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setLat(latitude);
          setLon(longitude);
          // Make an API call to get the address based on the user's location
          fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCeoWpdd6YOVu3wEqqA0qpY6P3B5rUumm8`
          )
            .then((response) => response.json())
            .then((data) => {
              setAddress(data.results[9].formatted_address);
            })
            .catch((error) => console.error(error));
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const applyCoupon = async () => {
    await axios
      .post(
        "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/websiteVerifyCoupon",
        {
          name: couponCode,
          serviceType: 2,
          package: "Pet Hotel",
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: token,
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response.data.value) {
          setCouponValue(response.data.value);
          setCouponErrorMsg("");
          setCouponNotValid(false);
          setCouponValid(true);
        } else {
          setCouponValid(false);
          setCouponNotValid(true);
          setCouponErrorMsg(response.data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  async function submitWalkingTrainingDataHandler(path) {
    const phoneString = checkPhone(phone.toString());
    console.log(newDateRef.current.value);
    console.log(newEndDateRef.current.value);
    console.log(overallChoice.Address);

    let link = "";
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;
    const dateOfReg = `${date.getDate()}-${date.getMonth() + 1
      }-${date.getFullYear()}`;

    const stringPhone = newPhoneRef.toString();
    const name = nameFill;

    setOverallChoice({
      Pet: petChoice == 1 ? "Small Pet" : "Big Pet",
      StartDate: newDateRef.current.value
        ? newDateRef.current.value
        : formattedDate,
      EndDate: newEndDateRef.current.value,
      Name: name,
      Phone: phone,
      Address: overallChoice.Address,
      Service: serviceType,
      PickupSlot: pickupTime[pickupSlot],
      DropoffSlot: dropOffTime[dropOffSlot],
    });
    // console.log(phoneFill);
    // console.log(nameFill);
    // console.log(startDate);
    // console.log(endDate);
    // console.log(address);
    let data = {
      ContactNo: phoneFill,
      TimeOfRegistration: timeOfReg,
      DateOfRegistration: dateOfReg,
      Address: overallChoice.Address,
      StartDate:
        overallChoice.StartDate == "" ? formattedDate : overallChoice.StartDate,
      EndDate:
        overallChoice.EndDate == "" ? formattedDate : overallChoice.EndDate,
      FormType: "Direct Form",
      Service: serviceType,
      // Package: overallChoice.Package,
      ParentName: nameFill,
      // PetName: overallChoice.PetName,
      PickupSlot: pickupTime[pickupSlot],
      DropoffSlot: dropOffTime[dropOffSlot],
      NonVegMealAdded: nonvegMeal ? "Yes" : "No",
      ThirdMealAdded: thirdMeal ? "Yes" : "No",
      PetSize: petChoice === 1 ? "Small Pet" : "Big Pet",

    };
    console.log(data);
    const data2 = {
      petDetails: [
        {
          size: petChoice == 1 ? "small" : "big",
        },
      ],
      phoneNumber: phoneFill,
      startDate:
        overallChoice.StartDate == "" || overallChoice.StartDate == undefined
          ? formattedDate
          : overallChoice.StartDate,
      endDate:
        overallChoice.EndDate == "" ? formattedDate : overallChoice.EndDate,
      isThirdMeal: thirdMeal,
      isNonVegMeal: nonvegMeal,
      address: "",
      petParentName: nameFill,
      isCouponCodeUsed: couponValue === 0 ? false : true,
      couponCode: couponValue !== 0 ? couponCode : "",
      couponValue: couponValue,
    };
    // console.log(data2);
    // // serviceBooking/postPetBoardingDetails
    setIsLoading(true);
    await axios
      .post(
        "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/postPetBoardingDetails",
        data2,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: token,
          },
        }
      )
      .then((response) => {
        console.log("response--->", response);
        setBookingId(response.bookingId);
        console.log("The boarding data is submitted to the database.");
        // setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        console.log("There was some error in saving the data in database.");
      });
    const startDate = overallChoice.StartDate;
    const endDate = overallChoice.EndDate;
    const address = overallChoice.Address;

    if (phoneFill !== "") {
      // setIsLoading(true);

      const link =
        "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/saveBoardingData";
      // https://api.apispreadsheets.com/data/QoUeTA8rf8BrEjAW/

      await axios
        .post(link, data)
        .then((response) => {
          console.log(response);
          setIsLoading(false);
          navigate("/ordersummaryboarding", {
            state: {
              data: {
                ContactNo: phoneFill,

                Address: newAddressRef.current.value,
                StartDate:
                  overallChoice.StartDate == ""
                    ? formattedDate
                    : overallChoice.StartDate,
                EndDate:
                  overallChoice.EndDate == ""
                    ? formattedDate
                    : overallChoice.EndDate,

                Service: serviceType,
                ParentName: nameFill,
                PickupSlot: pickupTime[pickupSlot],
                DropoffSlot: dropOffTime[dropOffSlot],
                NonVegMealAdded: nonvegMeal ? "Yes" : "No",
                ThirdMealAdded: thirdMeal ? "Yes" : "No",
                PetSize: petChoice === 1 ? "Small Pet" : "Big Pet",
                Days: daysDiff,
                PackageAmount: petChoice === 1 ? 499 : 599,
                PayNow: isPayNow,
              },
            },
          });
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });

      // phoneRef.current.value = "";

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: `Boarding Registration Fired From Desktop Service Page Form`,
      });

      navigate("/thankPageGroom");
    } else {
      if (stringPhone.length !== 10) {
        setNewPhoneValidation(false);
      }
      // if (address === "") {
      //   setNewAddressValidation(false);
      // }
      // if (startDate === "") {
      //   setNewDateValidation(false);
      // }
      // if (endDate === "") {
      //   setNewEndDateValidation(false);
      // }

      return;
    }
  }

  return (
    <div
      className="home-page-desktop-intro-form-container2"
      style={{
        width: location == "/" ? "100%" : "",
        marginTop: location == "/" ? "0px" : "",
        border: location == "/" ? "0px" : "",
      }}
    >
      <div className="walking-page-desktop-intro-form-header">
        <div style={{ display: "flex", alignItems: "start" }}>
          <div
            style={{
              width: selectedCarousel === 1 ? "0%" : "8%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <BiArrowBack
              style={{ display: selectedCarousel === 1 ? "none" : "block" }}
              onClick={backArrow}
            />
          </div>
          <div style={{ width: selectedCarousel === 1 ? "100%" : "90%" }}>
            {selectedCarousel == 1 || selectedCarousel == 2 ? (
              <>
                Book Here!{" "}
                <span className="pink"> Limited Slots Available</span>
              </>
            ) : (
              <>
                {/* {serviceType == "" ? <>Select service start date</> : <></>}
                <div>Choose Start Date / Select Pick-Up & Drop-Off</div>{" "}
                <div className="pink" style={{ fontSize: "20px" }}>
                  {" "}
                  Free Pick up (for more than 10 days of stay)
                </div> */}
                <div>Choose Start Date / Select Pick-Up & Drop-Off</div>{" "}
                <div className="pink" style={{ fontSize: "20px" }}>
                  {" "}
                  Free Pick up (for more than 40 days of stay)
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {selectedCarousel == 1 ? (
        <div className="grooming-new-form-section1-main-container">
          <div className="grooming-new-form-section1-main">
            <div
              className="grooming-new-form-section1-left"
              style={{ width: "50%" }}
            >
              {/* <div className="">
                <div
                  className="grooming-new-form-section1-left-head"
                  style={{ padding: "0px" }}
                >
                  Select service of your choice
                </div>
                <div
                  className="grooming-new-form-section1-left-choice-container"
                  style={{ padding: "10px" }}
                >
                  <div
                    className="grooming-new-form-section1-left-choice-name-container"
                    onClick={() =>
                      setServiceType("Health Resorts & Luxury Stay")
                    }
                  >
                    <div className="selection-circle">
                      {serviceType == "Health Resorts & Luxury Stay" && (
                        <div className="dot"></div>
                      )}
                    </div>
                    <div
                      onClick={() =>
                        setServiceType("Health Resorts & Luxury Stay")
                      }
                      htmlFor="bath&brush"
                    >
                      <span className="grooming-new-form-section1-left-choice-name">
                        Health Resorts & Luxury Stay
                      </span>
                    </div>
                  </div>
                  <div className="grooming-new-form-section1-left-choice-price">
                    ₹{petChoice === 1 ? "499" : "599"}/-{" "}
                    <span className="pink">per night</span>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    padding: "10px",
                    width: "100%",
                    gap: "10%",
                  }}
                  className=""
                >
                  <div
                    className="option"
                    id="one"
                    style={{ display: "flex" }}
                    onClick={() => {
                      setpetChoice(1);
                    }}
                  >
                    <div className="heading" style={{ marginBottom: "5px" }}>
                      <div
                        className="left"
                        onClick={() => {
                          // console.log("h")
                          setPet("Small Pet");
                          setOverallChoice({ pet: "Small Pet" });
                        }}
                      >
                        <div className="selection-circle">
                          {petChoice == 1 && <div className="dot"></div>}
                        </div>
                        <div style={{ display: "flex", gap: "2px" }}>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Dog.png"
                            alt=""
                            style={{ width: "25px", height: "25px" }}
                          />
                          <span style={{ fontWeight: "500" }}>
                            Small Pet{" "}
                            <small style={{ fontSize: "13px" }}>
                              {" "}
                              <br />
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="option"
                    id="two"
                    style={{ display: "flex" }}
                    onClick={() => {
                      setpetChoice(2);
                    }}
                  >
                    <div className="heading" style={{ marginBottom: "5px" }}>
                      <div
                        className="left"
                        onClick={() => {
                          // console.log("g");

                          setPet("Big Pet");
                          setOverallChoice({ pet: "Big Pet" });
                        }}
                      >
                        <div className="selection-circle">
                          {petChoice == 2 && <div className="dot"></div>}
                        </div>
                        <div style={{ display: "flex", gap: "2px" }}>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Cat.png"
                            alt=""
                            style={{ width: "25px", height: "25px" }}
                          />
                          <span style={{ fontWeight: "500" }}>
                            Big Pet{" "}
                            <small style={{ fontSize: "13px" }}>
                              {" "}
                              <br />
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="grooming-new-form-section1-left-head">
                {/* Resort Stay & Training */}
                Select Resort Location
              </div>
              <div
                className="grooming-new-form-section1-left-container"
                style={{ padding: "0px" }}
              >
                <div className="dateandtime">
                  <div className="select-slot">
                    {!groomingSlotValidation ? (
                      <div className="home-page-walking-form-mobile-feedback">
                        Required
                      </div>
                    ) : null}
                    <select
                      name="slot"
                      id="slot"
                      ref={groomingSlotRef}
                      onChange={() => {
                        // firstFormDataHandler();
                        setGroomingSlotValidation(true);

                        setSlotValue(true);
                        setSlotFill(groomingSlotRef.current.value);
                        setOverallChoice({
                          ...overallChoice,
                          Location: groomingSlotRef.current.value,
                        });
                        console.log(groomingSlotRef.current.value);
                      }}
                      required
                      style={{ fontSize: "14px" }}
                      // value={userData.Slot}
                      value={slotValue ? slotFill : ""}
                    >
                      <option
                        value="GURGAON (SEC 58)"
                        style={{ height: "56px" }}
                      >
                        GURGAON (SEC 58)
                      </option>
                      <option
                        value="FARIDABAD (SEC 72)"
                        style={{ height: "56px" }}
                      >
                        FARIDABAD (SEC 72)
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div
                className="grooming-new-form-validation-container-desktop"
                style={{ marginTop: "20px", marginBottom: "10px" }}
              >
                <div className="grooming-new-form-section1-left-head">
                  Phone Number
                </div>
                {newPhoneValidation ? null : (
                  <div className="home-page-desktop-phone-validation-text">
                    Required
                  </div>
                )}
              </div>
              <div
                className="grooming-new-form-section3-right-ph-input-contact"
                style={{ height: "56px" }}
              >
                {/* <div> */}
                <input
                  onChange={() => {
                    setNewPhoneValidation(true);
                    setPhoneValue(true);
                    setPhoneFill(newPhoneRef.current.value);
                    setOverallChoice({
                      ...overallChoice,
                      Phone: newPhoneRef.current.value,
                    });
                    console.log(newPhoneRef.current.value);
                  }}
                  ref={newPhoneRef}
                  className="grooming-new-form-desktop-input-container-type1"
                  placeholder="Contact number"
                  type="number"
                  required
                  style={{
                    padding: "10px",
                    width: "100%",
                    height: "100%",
                    fontSize: "14px",
                  }}
                  value={phoneValue ? phoneFill : null}
                ></input>
              </div>

              {/* <div
                  className="option"
                  id="one"
                  style={{ display: "flex" }}
                  onClick={() => {
                    setpetChoice(1);
                  }}
                >
                  <div
                    className="heading"
                    style={{
                      marginBottom: "5px",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div
                      className="left"
                      onClick={() => {
                        // console.log("h")
                        setPet("Small Pet");
                        setOverallChoice({ pet: "Small Pet" });
                      }}
                    >
                      <div className="selection-circle">
                        {petChoice == 1 && <div className="dot"></div>}
                      </div>
                      <div style={{ display: "flex", gap: "2px" }}>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Dog.png"
                          alt=""
                          style={{ width: "25px", height: "25px" }}
                        />
                        <span style={{ fontWeight: "500" }}>
                          Small Pet{" "}
                          <small style={{ fontSize: "13px" }}>
                            {" "}
                            <br />
                          </small>
                        </span>
                      </div>
                    </div>
                    <div
                      className="right"
                      style={{ fontSize: "15px", width: "40%" }}
                    >
                      ₹ 499/-
                      <span style={{ color: "#FF5E95", fontSize: "15px" }}>
                        Per Night
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="option"
                  id="two"
                  style={{ display: "flex" }}
                  onClick={() => {
                    setpetChoice(2);
                  }}
                >
                  <div
                    className="heading"
                    style={{
                      marginBottom: "5px",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      className="left"
                      onClick={() => {
                        // console.log("g");

                        setPet("Big Pet");
                        setOverallChoice({ pet: "Big Pet" });
                      }}
                    >
                      <div className="selection-circle">
                        {petChoice == 2 && <div className="dot"></div>}
                      </div>
                      <div style={{ display: "flex", gap: "2px" }}>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/bigpet.png"
                          alt=""
                          style={{ width: "25px", height: "25px" }}
                        />
                        <span style={{ fontWeight: "500" }}>
                          Big Pet{" "}
                          <small style={{ fontSize: "13px" }}>
                            {" "}
                            <br />
                          </small>
                        </span>
                      </div>
                    </div>
                    <div
                      className="right"
                      style={{ fontSize: "15px", width: "40%" }}
                    >
                      ₹ 599/-
                      <span style={{ color: "#FF5E95", fontSize: "15px" }}>
                        Per Night
                      </span>
                    </div>
                  </div>
                </div> */}
              {/* <div
                  className={
                    serviceChoice === "Stay & Basic Training"
                      ? "grooming-new-form-section1-left-choice-container-active"
                      : "grooming-new-form-section1-left-choice-container"
                  }
                >
                  <div
                    className="grooming-new-form-section1-left-choice-name-container"
                    onClick={() => setServiceChoice("Stay & Basic Training")}
                  >
                    <div className="selection-circle">
                      {serviceChoice == "Stay & Basic Training" && (
                        <div className="dot"></div>
                      )}
                    </div>
                    <div
                      onClick={() => (
                        setServiceChoice("Stay & Basic Training"),
                        setOverallChoice({ Package: "Stay & Basic Training" })
                      )}
                      htmlFor="stay&basictraining"
                    >
                      <span className="grooming-new-form-section1-Package-name">
                        Stay & Basic Training
                      </span>
                    </div>
                  </div>
                  <div className="grooming-new-form-section1-left-choice-price">
                    ₹18,100/- <span className="pink">30 Days</span>
                  </div>
                </div>
                <div
                  className={
                    serviceChoice === "Stay & Smart Training"
                      ? "grooming-new-form-section1-left-choice-container-active"
                      : "grooming-new-form-section1-left-choice-container"
                  }
                >
                  <div
                    className="grooming-new-form-section1-left-choice-name-container"
                    onClick={() => setServiceChoice("Stay & Smart Training")}
                  >
                    <div className="selection-circle">
                      {serviceChoice == "Stay & Smart Training" && (
                        <div className="dot"></div>
                      )}
                    </div>
                    <div
                      onClick={() => (
                        setServiceChoice("Stay & Smart Training"),
                        setOverallChoice({
                          Package: "Stay & Smart Training",
                        })
                      )}
                      htmlFor="bath&brushTicks"
                    >
                      <span className="grooming-new-form-section1-left-choice-name">
                        Stay & Smart Training
                      </span>
                    </div>
                  </div>
                  <div className="grooming-new-form-section1-left-choice-price">
                    ₹28,200/- <span className="pink">60 Days</span>
                  </div>
                </div>
                <div
                  className={
                    serviceChoice === "Stay & Advanced Training"
                      ? "grooming-new-form-section1-left-choice-container-active"
                      : "grooming-new-form-section1-left-choice-container"
                  }
                >
                  <div
                    className="grooming-new-form-section1-left-choice-name-container"
                    onClick={() => setServiceChoice("Stay & Advanced Training")}
                  >
                    <div className="selection-circle">
                      {serviceChoice == "Stay & Advanced Training" && (
                        <div className="dot"></div>
                      )}
                    </div>
                    <div
                      onClick={() => (
                        setServiceChoice("Stay & Advanced Training"),
                        setOverallChoice({ Package: "Stay & Advanced Training" })
                      )}
                      htmlFor="haircutstyling"
                    >
                      <span className="grooming-new-form-section1-left-choice-name">
                        Stay & Advanced Training
                      </span>
                    </div>
                  </div>
                  <div className="grooming-new-form-section1-left-choice-price">
                    ₹36,300/- <span className="pink">90 Days</span>
                  </div>
                </div>
                <div
                  className={
                    serviceChoice === "Stay & Canine Behaviour Training"
                      ? "grooming-new-form-section1-left-choice-container-active"
                      : "grooming-new-form-section1-left-choice-container"
                  }
                >
                  <div
                    className="grooming-new-form-section1-left-choice-name-container"
                    onClick={() =>
                      setServiceChoice("Stay & Canine Behaviour Training")
                    }
                  >
                    <div className="selection-circle">
                      {serviceChoice == "Stay & Canine Behaviour Training" && (
                        <div className="dot"></div>
                      )}
                    </div>
                    <div
                      onClick={() => (
                        setServiceChoice("Stay & Canine Behaviour Training"),
                        setOverallChoice({
                          Package: "Stay & Canine Behaviour Training",
                        })
                      )}
                      htmlFor="bathhaircut"
                    >
                      <span className="grooming-new-form-section1-left-choice-name">
                        Stay & Canine Behaviour Training
                      </span>
                    </div>
                  </div>
                  <div className="grooming-new-form-section1-left-choice-price">
                    ₹28,200/- <span className="pink">60 Days</span>
                  </div>
                </div>
                <div
                  className={
                    serviceChoice === "Stay & Canine Behaviour Training 90"
                      ? "grooming-new-form-section1-left-choice-container-active"
                      : "grooming-new-form-section1-left-choice-container"
                  }
                >
                  <div
                    className="grooming-new-form-section1-left-choice-name-container"
                    onClick={() =>
                      setServiceChoice("Stay & Canine Behaviour Training 90")
                    }
                  >
                    <div className="selection-circle">
                      {serviceChoice ==
                        "Stay & Canine Behaviour Training 90" && (
                        <div className="dot"></div>
                      )}
                    </div>
                    <div
                      onClick={() => (
                        setServiceChoice("Stay & Canine Behaviour Training 90"),
                        setOverallChoice({
                          Package: "Stay & Canine Behaviour Training 90",
                        })
                      )}
                      htmlFor="bathhaircut"
                    >
                      <span
                        className="grooming-new-form-section1-left-choice-name"
                        style={{ paddingRight: "5px" }}
                      >
                        Stay & Canine Behaviour Training
                      </span>
                    </div>
                  </div>
                  <div className="grooming-new-form-section1-left-choice-price">
                    ₹36,300/- <span className="pink">90 Days</span>
                  </div>
                </div> */}

              {/* <div
                className="grooming-new-form-section1-left-head"
                style={{ marginTop: "20px" }}
              >
                Add On
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10%",
                  marginTop: "10px",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    fontSize: "10px",
                    backgroundColor: nonvegMeal
                      ? "rgba(83, 177, 59, 1)"
                      : "rgba(255, 94, 149, 1)",
                    height: "80px",
                    borderRadius: "10px",
                    border: !nonvegMeal
                      ? "2px solid rgba(255, 94, 149, 1)"
                      : "2px solid rgba(83, 177, 59, 1)",
                      cursor:"pointer"
                  }}
                  onClick={() => setNonvegMeal((prev) => !prev)}
                >
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "white",
                      height: "70%",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      padding: "5px",
                      gap: "5px",
                    }}
                  >
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/nonVegMeal.png"
                      alt=""
                      style={{ width: "25px", height: "25px" }}
                    />
                    <div>
                      <span
                        style={{
                          color: nonvegMeal
                            ? "rgba(83, 177, 59, 1)"
                            : "rgba(255, 94, 149, 1)",
                          fontWeight: "700",
                          fontSize: "15px",
                        }}
                      >
                        Non-Veg Meal @
                      </span>
                      <span
                        style={{
                          color: "rgba(69, 163, 165, 1)",
                          fontWeight: "700",
                          fontSize: "15px",
                        }}
                      >
                        99/DAY
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      color: "white",
                      height: "50%",
                      fontWeight: "700",
                      fontSize: "15px",
                    }}
                  >
                    {nonvegMeal ? "Added" : "Add"}
                  </div>
                </div>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    fontSize: "10px",
                    backgroundColor: thirdMeal
                      ? "rgba(83, 177, 59, 1)"
                      : "rgba(255, 94, 149, 1)",
                    borderRadius: "10px",
                    border: !thirdMeal
                      ? "2px solid rgba(255, 94, 149, 1)"
                      : "2px solid rgba(83, 177, 59, 1)",
                    height: "80px",
                    cursor:"pointer"
                  }}
                  onClick={() => setThirdMeal((prev) => !prev)}
                >
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "white",
                      height: "70%",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      gap: "5px",
                      padding: "5px",
                    }}
                  >
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/thirdMeal.png"
                      alt=""
                      style={{ width: "25px", height: "25px" }}
                    />
                    <div>
                      <span
                        className="pink"
                        style={{ fontWeight: "700", fontSize: "15px" }}
                      >
                        Third Meal @
                      </span>
                      <span
                        style={{
                          color: "rgba(69, 163, 165, 1)",
                          fontWeight: "700",
                          fontSize: "15px",
                        }}
                      >
                        59/DAY
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      color: "white",
                      height: "50%",
                      fontWeight: "700",
                      fontSize: "15px",
                    }}
                  >
                    {thirdMeal ? "Added" : "Add"}
                  </div>
                </div>
              </div> */}
            </div>
            <div
              className="grooming-new-form-section1-right"
              style={{ width: "60%" }}
            >
              <div
                className="grooming-new-form-section1-right-include-container"
                style={{ width: "80%" }}
              >
                <div
                  className="grooming-new-form-section1-right-include-text"
                  style={{ width: "100%", textAlign: "start" }}
                >
                  Includes
                </div>

                <div className="grooming-new-form-section1-right-include-list-container">
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    {/* <div> */}
                    <div style={{ display: "flex" }}>
                      <div>
                        <li></li>
                      </div>
                      <div
                        style={{
                          fontSize: "15px",
                          fontWeight: "500",
                          color: "rgba(131, 145, 161, 1)",
                        }}
                      >
                        Boarding for{" "}
                        <span
                          style={{
                            fontWeight: "700",
                            color: "rgba(88, 88, 88, 1)",
                          }}
                        >
                          Rabbit, Guinea Pigs, Cats & Dogs
                        </span>{" "}
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div>
                        <li></li>
                      </div>
                      <div
                        style={{
                          fontSize: "15px",
                          fontWeight: "500",
                          color: "rgba(131, 145, 161, 1)",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "700",
                            color: "rgba(88, 88, 88, 1)",
                          }}
                        >
                          7 Times Water
                        </span>{" "}
                        change and{" "}
                        <span
                          style={{
                            fontWeight: "700",
                            color: "rgba(88, 88, 88, 1)",
                          }}
                        >
                          2 Meals
                        </span>{" "}
                        included
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div>
                        <li></li>
                      </div>
                      <div
                        style={{
                          fontSize: "15px",
                          fontWeight: "500",
                          color: "rgba(131, 145, 161, 1)",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "700",
                            color: "rgba(88, 88, 88, 1)",
                          }}
                        >
                          CCTV Cameras
                        </span>{" "}
                        all around{" "}
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div>
                        <li></li>
                      </div>
                      <div
                        style={{
                          fontSize: "15px",
                          fontWeight: "500",
                          color: "rgba(131, 145, 161, 1)",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "700",
                            color: "rgba(88, 88, 88, 1)",
                          }}
                        >
                          24x7 Vets
                        </span>{" "}
                        on Resort
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div>
                        <li></li>
                      </div>
                      <div
                        style={{
                          fontSize: "15px",
                          fontWeight: "500",
                          color: "rgba(131, 145, 161, 1)",
                        }}
                      >
                        Daily{" "}
                        <span
                          style={{
                            fontWeight: "700",
                            color: "rgba(88, 88, 88, 1)",
                          }}
                        >
                          LIVE Updates
                        </span>{" "}
                        on Petmojo Application
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div>
                        <li></li>
                      </div>
                      <div
                        style={{
                          fontSize: "15px",
                          fontWeight: "500",
                          color: "rgba(131, 145, 161, 1)",
                        }}
                      >
                        {/* 24/7 day and night{" "} */}
                        <span
                          style={{
                            fontWeight: "700",
                            color: "rgba(88, 88, 88, 1)",
                          }}
                        >
                          Dedicated Caretakers
                        </span>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div>
                        <li></li>
                      </div>
                      <div
                        style={{
                          fontSize: "15px",
                          fontWeight: "500",
                          color: "rgba(131, 145, 161, 1)",
                        }}
                      >
                        Empathetic & Friendly{" "}
                        <span
                          style={{
                            fontWeight: "700",
                            color: "rgba(88, 88, 88, 1)",
                          }}
                        >
                          Canine Handlers
                        </span>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div>
                        <li></li>
                      </div>
                      <div
                        style={{
                          fontSize: "15px",
                          fontWeight: "500",
                          color: "rgba(131, 145, 161, 1)",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "700",
                            color: "rgba(88, 88, 88, 1)",
                          }}
                        >
                          11 Feet Fencing
                        </span>{" "}
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div>
                        <li></li>
                      </div>
                      <div
                        style={{
                          fontSize: "15px",
                          fontWeight: "500",
                          color: "rgba(131, 145, 161, 1)",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "700",
                            color: "rgba(88, 88, 88, 1)",
                          }}
                        >
                          AC Van
                        </span>{" "}
                        Pick up and Drop
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div>
                        <li></li>
                      </div>
                      <div
                        style={{
                          fontSize: "15px",
                          fontWeight: "500",
                          color: "rgba(131, 145, 161, 1)",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "700",
                            color: "rgba(88, 88, 88, 1)",
                          }}
                        >
                          Free Pick-Up
                        </span>{" "}
                        (for more than 40 days of stay)
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div>
                        <li></li>
                      </div>
                      <div
                        style={{
                          fontSize: "15px",
                          fontWeight: "500",
                          color: "rgba(131, 145, 161, 1)",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "700",
                            color: "rgba(88, 88, 88, 1)",
                          }}
                        >
                          Free Pick-Up not applicable for CBT Boarding &
                          Training
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="grooming-new-form-section1-right-next-button-container"
                style={{ marginTop: "40px" }}
              >
                <div
                  onClick={() => {
                    // groomingFormDesktopHandler1();
                    firstFromBoardingHandler();
                    // displayLocation();
                  }}
                  className="grooming-new-form-section1-right-next-button"
                  style={{ width: "85%", color: "black" }}
                >
                  Next
                </div>
              </div>
            </div>
          </div>

          <div className="grooming-new-form-section1-carousel-container">
            <div className="grooming-new-form-section1-carousel-active"></div>
            <div className="grooming-new-form-section1-carousel"></div>
            <div className="grooming-new-form-section1-carousel"></div>
          </div>
          <div style={{ paddingTop: "2rem" }}>
            {/* <img
              src={iconsDesktop}
              style={{ width: "40rem", height: "6rem" }}
            /> */}
          </div>
          <div className="walking-page-desktop-intro-form-contents-lower">
            <i>We are mission driven & a Family of Pet Lovers</i>
          </div>
        </div>
      ) : null}
      {selectedCarousel == 2 ? (
        <div className="grooming-new-form-section1-main-container">
          <div className="grooming-new-form-section1-main">
            <div
              className="grooming-new-form-section1-left"
              style={{ width: "40%" }}
            >
              {/* <div className="grooming-new-form-section3-right-ph-section">
                <div className="grooming-new-form-validation-container-desktop">
                  <div className="grooming-new-form-section3-right-ph-text">
                    Pet Parent Name
                  </div>
                  {newNameValidation ? null : (
                    <div className="home-page-desktop-phone-validation-text">
                      Required
                    </div>
                  )}
                </div>

                <div className="grooming-new-form-section3-right-ph-input-contact">
                  <input
                    onChange={() => {
                      setNewNameValidation(true);
                      setnameValue(true);
                      setNameFill(newNameRef.current.value);
                      setOverallChoice({
                        ...overallChoice,
                        Name: newNameRef.current.value,
                      });
                    }}
                    ref={newNameRef}
                    className="grooming-new-form-desktop-input-container-type1"
                    placeholder="Enter your name"
                    type="text"
                    style={{ padding: "10px" }}
                    value={nameValue ? nameFill : ""}
                  ></input>
                </div>
              </div> */}
              {/* <div
                className="grooming-new-form-section3-right-ph-section-2"
                style={{ marginTop: "10px" }}
              >
                <div className="grooming-new-form-section3-right-ph-section-Phone-container">
                  <div>
                    <div className="grooming-new-form-validation-container-desktop">
                      <div className="grooming-new-form-section3-right-ph-text">
                        Phone Number
                      </div>
                      {newPhoneValidation ? null : (
                        <div className="home-page-desktop-phone-validation-text">
                          Required
                        </div>
                      )}
                    </div>
                    <div
                      className="grooming-new-form-section3-right-ph-input-contact"
                      style={{}}
                    >
                      <input
                        disabled={isVerified}
                        onChange={() => {
                          setNewPhoneValidation(true);
                          setPhoneValue(true);
                          setPhoneFill(newPhoneRef.current.value);
                          setOverallChoice({
                            ...overallChoice,
                            Phone: newPhoneRef.current.value,
                          });
                        }}
                        ref={newPhoneRef}
                        className="grooming-new-form-desktop-input-container-type1"
                        placeholder="Contact number"
                        type="number"
                        required
                        style={{ padding: "10px", width: "100%" }}
                        value={phoneValue ? phoneFill : null}
                      ></input>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="select-service">
                <div className="grooming-new-form-section1-left-head">
                  {/* Resort Stay & Training */}
                  Select size of your pet
                </div>
                <div
                  className="options-box"
                  style={{
                    backgroundColor: "#F7F8F9",
                    borderRadius: "10px",
                    padding: "10px",
                    marginTop: "10px",
                  }}
                >
                  <div
                    className="option"
                    id="one"
                    style={{
                      display: "flex",
                      width: "100%",
                      marginTop: "10px",
                    }}
                    onClick={() => {
                      setpetChoice(1);
                    }}
                  >
                    <div
                      className="heading"
                      style={{
                        marginBottom: "5px",
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <div
                        className="left"
                        onClick={() => {
                          // console.log("h")
                          setPet("Small Pet");
                          setOverallChoice({ pet: "Small Pet" });
                        }}
                      >
                        <div className="selection-circle">
                          {petChoice == 1 && <div className="dot"></div>}
                        </div>
                        <div style={{ display: "flex", gap: "2px" }}>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Dog.png"
                            alt=""
                            style={{ width: "25px", height: "25px" }}
                          />
                          <span style={{ fontWeight: "500" }}>
                            Small Pet{" "}
                            <small style={{ fontSize: "13px" }}>
                              {" "}
                              <br />
                            </small>
                          </span>
                        </div>
                      </div>
                      <div
                        className="right"
                        style={{ fontSize: "15px", width: "40%" }}
                      >
                        ₹ 499/-
                        <span style={{ color: "#FF5E95", fontSize: "15px" }}>
                          Per Night
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="option"
                    id="two"
                    style={{ display: "flex" }}
                    onClick={() => {
                      setpetChoice(2);
                    }}
                  >
                    <div
                      className="heading"
                      style={{
                        marginBottom: "5px",
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className="left"
                        onClick={() => {
                          // console.log("g");

                          setPet("Big Pet");
                          setOverallChoice({ pet: "Big Pet" });
                        }}
                      >
                        <div className="selection-circle">
                          {petChoice == 2 && <div className="dot"></div>}
                        </div>
                        <div style={{ display: "flex", gap: "2px" }}>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/bigpet.png"
                            alt=""
                            style={{ width: "25px", height: "25px" }}
                          />
                          <span style={{ fontWeight: "500" }}>
                            Big Pet{" "}
                            <small style={{ fontSize: "13px" }}>
                              {" "}
                              <br />
                            </small>
                          </span>
                        </div>
                      </div>
                      <div
                        className="right"
                        style={{ fontSize: "15px", width: "40%" }}
                      >
                        ₹ 599/-
                        <span style={{ color: "#FF5E95", fontSize: "15px" }}>
                          Per Night
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="grooming-new-form-section3-right-ph-section"
                style={{ marginTop: "25px" }}
              >
                <div className="grooming-new-form-validation-container-desktop">
                  <div className="grooming-new-form-section3-right-ph-text">
                    Pet Parent Name
                  </div>
                  {newNameValidation ? null : (
                    <div className="home-page-desktop-phone-validation-text">
                      Required
                    </div>
                  )}
                </div>

                <div className="grooming-new-form-section3-right-ph-input-contact">
                  <input
                    onChange={() => {
                      setNewNameValidation(true);
                      setnameValue(true);
                      setNameFill(newNameRef.current.value);
                      setOverallChoice({
                        ...overallChoice,
                        Name: newNameRef.current.value,
                      });
                    }}
                    ref={newNameRef}
                    className="grooming-new-form-desktop-input-container-type1"
                    placeholder="Enter your name"
                    type="text"
                    style={{ padding: "10px" }}
                    value={nameValue ? nameFill : ""}
                  ></input>
                </div>
              </div>
              <div
                className="grooming-new-form-section2-left-date-text-container"
                style={{ width: "100%", marginTop: "25px" }}
              >
                {representationDate}{" "}
                <span className="pink-text">{representationDay}</span>
              </div>
            </div>
            <div
              className="grooming-new-form-section3-right"
              style={{ width: "40%" }}
            >
              <div style={{ width: "100%" }}>
                <ImageSlider images={images} />
              </div>
              {/* <div className="grooming-new-form-section3-right-ph-section">
                <div className="grooming-new-form-validation-container-desktop">
                  <div className="grooming-new-form-section3-right-ph-text">
                    Pet Name
                  </div>
                  {newPetNameValidation ? null : (
                    <div className="home-page-desktop-phone-validation-text">
                      Required
                    </div>
                  )}
                </div>

                <div className="grooming-new-form-section3-right-ph-input-contact">
                  <input
                    onChange={() => {
                      setPetNewNameValidation(true);
                      setPetnameValue(true);
                      setPetnameFill(newPetNameRef.current.value);
                      setOverallChoice({
                        ...overallChoice,
                        PetName:newPetNameRef.current.value
                      })
                    }}
                    ref={newPetNameRef}
                    className="grooming-new-form-desktop-input-container-type1"
                    placeholder="Pet's Name"
                    type="text"
                    style={{ padding: "10px" }}
                    value={petnameValue ? petnameFill : ""}
                  ></input>
                </div>
              </div> */}
              {/* <div className="grooming-new-form-section3-right-ph-section-2" style={{marginBottom:"10px"}}>
                <div
                  className="grooming-new-form-section3-right-address-section"
                  style={{ marginTop: "0px" }}
                >
                  <div className="grooming-new-form-validation-container-desktop">
                    <div className="grooming-new-form-section3-right-ph-text">
                      Address
                    </div>
                    {newAddressValidation ? null : (
                      <div className="home-page-desktop-phone-validation-text">
                        Required
                      </div>
                    )}
                  </div>
                  <div className="grooming-new-form-section3-right-ph-input-contact">
                    <input
                      onChange={() => {
                        setNewAddressValidation(true);
                        setAddress(newAddressRef.current.value);
                        setOverallChoice({
                          ...overallChoice,
                          Address: newAddressRef.current.value,
                        });
                      }}
                      onFocus={handleLocationAccess}
                      ref={newAddressRef}
                      className="grooming-new-form-desktop-input-container-type1"
                      placeholder="Enter Your Home Address"
                      type="text"
                      style={{ padding: "10px" }}
                      value={address}
                    ></input>
                  </div>
                </div>
              </div> */}

              <div
                className="grooming-new-form-section1-right"
                style={{
                  flexDirection: "row",
                  width: "100%",
                  marginTop: "20px",
                }}
              >
                <div
                  className="grooming-new-form-section2-right-next-button-container"
                  style={{ justifyContent: "flex-end", width: "100%" }}
                >
                  <div
                    onClick={() => {
                      // groomingFormDesktopHandler2();
                      // setSelectedCarousel(3);
                      secondFormBoardingHandler();
                    }}
                    className="grooming-new-form-section1-right-next-button"
                    style={{ width: "100%", color: "black" }}
                  >
                    Next
                  </div>
                </div>
              </div>
              {/* <div className="grooming-form-tagline-text">
                <center>
                  Paid Sessions are given time & elite groomers preference!
                </center>
              </div> */}
            </div>
          </div>

          <div className="grooming-new-form-section1-carousel-container">
            <div className="grooming-new-form-section1-carousel-active"></div>
            <div className="grooming-new-form-section1-carousel-active"></div>
            <div className="grooming-new-form-section1-carousel-active"></div>
          </div>
        </div>
      ) : null}
      {selectedCarousel == 3 ? (
        <>
          <div className="grooming-new-form-section1-main-container">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "10px",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: "10%",
                }}
              >
                <div
                  className="grooming-new-form-section2-left-date-container"
                  style={{ width: "40%" }}
                >
                  <div className="grooming-new-form-validation-container-desktop">
                    <div className="grooming-new-form-section3-right-ph-text">
                      Start Date
                    </div>
                    {newDateValidation ? null : (
                      <div className="home-page-desktop-phone-validation-text">
                        Required
                      </div>
                    )}
                  </div>
                  <div
                    className="grooming-new-form-desktop-input-space1"
                    style={{ position: "relative" }}
                  >
                    <input
                      ref={newDateRef}
                      className="grooming-new-form-desktop-input-container-type1"
                      onChange={(e) => {
                        setStartValue(true);
                        setStartDate(newDateRef.current.value);
                        console.log("Start Date->", newDateRef.current.value);
                        setIsStartDateSelected(true);
                        setOverallChoice({
                          ...overallChoice,
                          StartDate: e.target.value,
                        });
                      }}
                      placeholder="DD/MM/YY"
                      type="date"
                      style={{ padding: "10px", fontSize: "16px" }}
                      value={startValue ? startDate : formattedDate}
                    ></input>
                  </div>
                </div>
                <div
                  className="grooming-new-form-section2-left-date-container"
                  style={{ width: "40%" }}
                >
                  <div className="grooming-new-form-validation-container-desktop">
                    <div className="grooming-new-form-section3-right-ph-text">
                      End Date
                    </div>
                    {newEndDateValidation ? null : (
                      <div className="home-page-desktop-phone-validation-text">
                        Required
                      </div>
                    )}
                  </div>
                  <div
                    className="grooming-new-form-desktop-input-space1"
                    style={{ position: "relative" }}
                  >
                    <input
                      ref={newEndDateRef}
                      className="grooming-new-form-desktop-input-container-type1"
                      onChange={(e) => {
                        setEndValue(true);
                        setEndDate(newEndDateRef.current.value);
                        setIsEndDateSelected(true);
                        setOverallChoice({
                          ...overallChoice,
                          EndDate: e.target.value,
                        });
                      }}
                      placeholder="DD/MM/YY"
                      type="date"
                      style={{ padding: "10px", fontSize: "16px" }}
                      value={endValue ? endDate : formattedDate}
                    ></input>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: "10%",
                }}
              >
                <div style={{ width: "40%", padding: "2px" }}>
                  {/* <input type="checkbox"  onChange={()=>setPickup(prev=>!prev)}/>
                    <span style={{ fontSize: "14px" }}>
                      Pickup Required? (AV Van can be availed @ ₹899/-)
                    </span> */}
                  <input
                    type="checkbox"
                    id="pickup"
                    name="pickup"
                    value="pickup"
                    checked={pickup}
                    onChange={() => setPickup((prev) => !prev)}
                  // disabled={displaySlots}
                  />
                  <label style={{ fontSize: "14px" }}>
                    Pickup Required? (AC Van can be availed @ ₹990/-)
                  </label>
                </div>
                <div style={{ width: "40%", padding: "2px" }}>
                  {/* <input type="checkbox"  onChange={()=>setDropOff(prev=>!prev)}/>
                    <span style={{ fontSize: "14px" }}>
                      Dropoff Required? (AC Van can be availed @ ₹899/-)
                    </span> */}
                  <input
                    type="checkbox"
                    id="dropoff"
                    name="dropoff"
                    value="dropoff"
                    checked={dropOff}
                    onChange={() => setDropOff((prev) => !prev)}
                  // disabled={displaySlots}
                  />
                  <label style={{ fontSize: "14px" }}>
                    Dropoff Required? (AC Van can be availed @ ₹990/-)
                  </label>
                </div>
              </div>
              <div
                style={{
                  display: pickup || dropOff ? "flex" : "none",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: "10%",
                }}
              >
                <div
                  className="grooming-new-form-section2-right-time-slot-container"
                  style={{
                    width: "40%",
                    visibility: !pickup ? "hidden" : "visible",
                  }}
                >
                  <div>Pickup Time</div>
                  <div className="grooming-new-form-section2-right-time-slots">
                    <div
                      onClick={() => setPickupSlot(1)}
                      className={
                        pickupSlot == 1
                          ? "grooming-new-form-section2-right-time-slot-active"
                          : "grooming-new-form-section2-right-time-slot"
                      }
                    >
                      10AM-02PM
                    </div>
                    <div
                      onClick={() => setPickupSlot(2)}
                      className={
                        pickupSlot == 2
                          ? "grooming-new-form-section2-right-time-slot-active"
                          : "grooming-new-form-section2-right-time-slot"
                      }
                    >
                      02PM-06PM
                    </div>
                    <div
                      onClick={() => setPickupSlot(3)}
                      className={
                        pickupSlot == 3
                          ? "grooming-new-form-section2-right-time-slot-active"
                          : "grooming-new-form-section2-right-time-slot"
                      }
                    >
                      06PM-10PM
                    </div>
                  </div>
                </div>

                <div
                  className="grooming-new-form-section2-right-time-slot-container"
                  style={{
                    width: "40%",
                    visibility: !dropOff ? "hidden" : "visible",
                  }}
                >
                  <div>Drop Time</div>
                  <div className="grooming-new-form-section2-right-time-slots">
                    <div
                      onClick={() => setDropOffSlot(1)}
                      className={
                        dropOffSlot == 1
                          ? "grooming-new-form-section2-right-time-slot-active"
                          : "grooming-new-form-section2-right-time-slot"
                      }
                    >
                      10AM-02PM
                    </div>
                    <div
                      onClick={() => setDropOffSlot(2)}
                      className={
                        dropOffSlot == 2
                          ? "grooming-new-form-section2-right-time-slot-active"
                          : "grooming-new-form-section2-right-time-slot"
                      }
                    >
                      02PM-06PM
                    </div>
                    <div
                      onClick={() => setDropOffSlot(3)}
                      className={
                        dropOffSlot == 3
                          ? "grooming-new-form-section2-right-time-slot-active"
                          : "grooming-new-form-section2-right-time-slot"
                      }
                    >
                      06PM-10PM
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: "10%",
                }}
              >
                <div className="" style={{ width: "40%" }}>
                  <div className="grooming-new-form-validation-container-desktop">
                    <div className="grooming-new-form-section3-right-ph-text">
                      Coupon Code
                      <span style={{ fontStyle: "italic" }}>
                        (If Available)
                      </span>
                    </div>
                    {couponValid && (
                      <div style={{ color: "#618E41", fontSize: "15px" }}>
                        Code applied successfully
                      </div>
                    )}
                    {couponNotValid && (
                      <div style={{ color: "red", fontSize: "15px" }}>
                        Invalid Code
                      </div>
                    )}
                  </div>
                  <div className="grooming-new-form-section3-right-ph-input-contact">
                    <input
                      id="coupon"
                      type="text"
                      className="grooming-new-form-desktop-input-container-type1"
                      onChange={(e) => {
                        setCouponCode(e.target.value);
                      }}
                    />
                    {!couponValid && !couponNotValid && (
                      <div
                        className="coupon-apply-button"
                        onClick={applyCoupon}
                      >
                        <span style={{ paddingRight: "0.5rem" }}>Apply</span>
                        <FaArrowAltCircleRight className="coupon-apply-button-icon" />
                      </div>
                    )}

                    {couponValid && (
                      <div
                        className="coupon-applied-text"
                        style={{ color: "#618E41;" }}
                      >
                        <span style={{ marginRight: "8px" }}>
                          ₹ {couponValue} /- OFF
                        </span>{" "}
                        <FaCheck className="check-mark" />
                      </div>
                    )}
                    {couponNotValid && (
                      <div className="coupon-not-valid">
                        <MdClose className="x-mark" />
                      </div>
                    )}
                  </div>
                </div>
                {isLoading ? (
                  <div className="grooming-page-loading-spinner-container-desktop">
                    <LoadingSpinner />
                  </div>
                ) : (
                  <div
                    className="grooming-new-form-section1-right-pay-button-container"
                    // style={{ marginTop: "10px" }}
                    style={{ width: "40%" }}
                  >
                    <button
                      // disabled={isVerified === false || isVerified === null}
                      onClick={() => {
                        paymentInitiationHandler();
                      }}
                      className="grooming-new-form-section1-right-pay-button"
                      // style={{backgroundColor:!isVerified?"rgba(131, 145, 161, 1)":"",border:!isVerified?"2px solid rgba(131, 145, 161, 1)":""}}
                      style={{ width: "40%" }}
                    >
                      Book Now
                    </button>
                    <button
                      //  disabled={isVerified === false || isVerified === null}
                      onClick={() =>
                        submitWalkingTrainingDataHandler("boarding")
                      }
                      className="grooming-new-form-section1-right-pay-later-button"
                      // style={{backgroundColor:!isVerified?"white":"",border:!isVerified?"2px solid rgba(131, 145, 161, 1)":"",color:!isVerified?"rgba(131, 145, 161, 1)":""}}
                      style={{ width: "60%" }}
                    >
                      Book Now & Pay Later
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div
              className="grooming-new-form-section1-carousel-container"
              style={{ marginTop: "50px" }}
            >
              <div className="grooming-new-form-section1-carousel-active"></div>
              <div className="grooming-new-form-section1-carousel-active"></div>
              <div className="grooming-new-form-section1-carousel"></div>
            </div>
          </div>
          {/* {serviceChoice == "" ? (
            <div className="grooming-new-form-section1-main-container">
              <div
                className="grooming-new-form-section1-main"
                style={{ justifyContent: "space-between" }}
              >
                <div className="grooming-new-form-section2-left">
                  <div className="grooming-new-form-section2-left-date-container">
                    <div className="grooming-new-form-validation-container-desktop">
                      <div className="grooming-new-form-section3-right-ph-text">
                        Start Date
                      </div>
                      {newDateValidation ? null : (
                        <div className="home-page-desktop-phone-validation-text">
                          Required
                        </div>
                      )}
                    </div>
                    <div
                      className="grooming-new-form-desktop-input-space1"
                      style={{ position: "relative" }}
                    >
                      <input
                        ref={newDateRef}
                        className="grooming-new-form-desktop-input-container-type1"
                        onChange={() => {
                          setStartValue(true);
                          setStartDate(newDateRef.current.value);
                        }}
                        placeholder="DD/MM/YY"
                        type="date"
                        style={{ padding: "10px", fontSize: "16px" }}
                        value={startValue ? startDate : formattedDate}
                      ></input>
                    </div>
                  </div>

                  <div className="grooming-new-form-section2-left-date-text-container">
                    {representationDate}{" "}
                    <span className="pink-text">{representationDay}</span>
                  </div>
                </div>
                <div
                  className="grooming-new-form-section1-right"
                  style={{ flexDirection: "row", width: "50%" }}
                >
                  
                  <div
                    className="grooming-new-form-section2-right-next-button-container"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <div
                      onClick={() => {
                        groomingFormDesktopHandler2();
                      }}
                      className="grooming-new-form-section1-right-next-button"
                      style={{ width: "70%" }}
                    >
                      Next
                    </div>
                  </div>
                </div>
              </div>

              <div className="grooming-new-form-section1-carousel-container">
                <div className="grooming-new-form-section1-carousel-active"></div>
                <div className="grooming-new-form-section1-carousel-active"></div>
                <div className="grooming-new-form-section1-carousel"></div>
              </div>
            </div>
          ) : (
            <div className="grooming-new-form-section1-main-container">
              
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "10px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    gap: "10%",
                  }}
                >
                  <div
                    className="grooming-new-form-section2-left-date-container"
                    style={{ width: "40%" }}
                  >
                    <div className="grooming-new-form-validation-container-desktop">
                      <div className="grooming-new-form-section3-right-ph-text">
                        Start Date
                      </div>
                      {newDateValidation ? null : (
                        <div className="home-page-desktop-phone-validation-text">
                          Required
                        </div>
                      )}
                    </div>
                    <div
                      className="grooming-new-form-desktop-input-space1"
                      style={{ position: "relative" }}
                    >
                      <input
                        ref={newDateRef}
                        className="grooming-new-form-desktop-input-container-type1"
                        onChange={() => {
                          setStartValue(true);
                          setStartDate(newDateRef.current.value);
                          console.log("Start Date->", newDateRef.current.value);
                          setIsStartDateSelected(true);
                        }}
                        placeholder="DD/MM/YY"
                        type="date"
                        style={{ padding: "10px", fontSize: "16px" }}
                        value={startValue ? startDate : formattedDate}
                      ></input>
                    </div>
                  </div>
                  <div
                    className="grooming-new-form-section2-left-date-container"
                    style={{ width: "40%" }}
                  >
                    <div className="grooming-new-form-validation-container-desktop">
                      <div className="grooming-new-form-section3-right-ph-text">
                        End Date
                      </div>
                      {newEndDateValidation ? null : (
                        <div className="home-page-desktop-phone-validation-text">
                          Required
                        </div>
                      )}
                    </div>
                    <div
                      className="grooming-new-form-desktop-input-space1"
                      style={{ position: "relative" }}
                    >
                      <input
                        ref={newEndDateRef}
                        className="grooming-new-form-desktop-input-container-type1"
                        onChange={() => {
                          setEndValue(true);
                          setEndDate(newEndDateRef.current.value);
                          setIsEndDateSelected(true);
                        }}
                        placeholder="DD/MM/YY"
                        type="date"
                        style={{ padding: "10px", fontSize: "16px" }}
                        value={endValue ? endDate : formattedDate}
                      ></input>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    gap: "10%",
                  }}
                >
                  <div style={{ width: "40%", padding: "2px" }}>
                   
                    <input
                      type="checkbox"
                      id="pickup"
                      name="pickup"
                      value="pickup"
                      onChange={() => setPickup((prev) => !prev)}
                      disabled={displaySlots}
                    />
                    <label style={{ fontSize: "14px" }}>
                      Pickup Required? (AV Van can be availed @ ₹899/-)
                    </label>
                  </div>
                  <div style={{ width: "40%", padding: "2px" }}>
                   
                    <input
                      type="checkbox"
                      id="dropoff"
                      name="dropoff"
                      value="dropoff"
                      onChange={() => setDropOff((prev) => !prev)}
                      disabled={displaySlots}
                    />
                    <label style={{ fontSize: "14px" }}>
                      Dropoff Required? (AV Van can be availed @ ₹899/-)
                    </label>
                  </div>
                </div>
                <div
                  style={{
                    display: !pickup || !dropOff ? "flex" : "none",
                    justifyContent: "space-between",
                    width: "100%",
                    gap: "10%",
                  }}
                >
                  <div
                    className="grooming-new-form-section2-right-time-slot-container"
                    style={{
                      width: "40%",
                      visibility: pickup ? "hidden" : "visible",
                    }}
                  >
                    <div>Pickup Time</div>
                    <div className="grooming-new-form-section2-right-time-slots">
                      <div
                        onClick={() => setPickupSlot(0)}
                        className={
                          pickupSlot == 0
                            ? "grooming-new-form-section2-right-time-slot-active"
                            : "grooming-new-form-section2-right-time-slot"
                        }
                      >
                        10AM-02PM
                      </div>
                      <div
                        onClick={() => setPickupSlot(1)}
                        className={
                          pickupSlot == 1
                            ? "grooming-new-form-section2-right-time-slot-active"
                            : "grooming-new-form-section2-right-time-slot"
                        }
                      >
                        02PM-06PM
                      </div>
                      <div
                        onClick={() => setPickupSlot(2)}
                        className={
                          pickupSlot == 2
                            ? "grooming-new-form-section2-right-time-slot-active"
                            : "grooming-new-form-section2-right-time-slot"
                        }
                      >
                        06PM-10PM
                      </div>
                    </div>
                  </div>

                  <div
                    className="grooming-new-form-section2-right-time-slot-container"
                    style={{
                      width: "40%",
                      visibility: dropOff ? "hidden" : "visible",
                    }}
                  >
                    <div>Drop Time</div>
                    <div className="grooming-new-form-section2-right-time-slots">
                      <div
                        onClick={() => setDropOffSlot(0)}
                        className={
                          dropOffSlot == 0
                            ? "grooming-new-form-section2-right-time-slot-active"
                            : "grooming-new-form-section2-right-time-slot"
                        }
                      >
                        10AM-02PM
                      </div>
                      <div
                        onClick={() => setDropOffSlot(1)}
                        className={
                          dropOffSlot == 1
                            ? "grooming-new-form-section2-right-time-slot-active"
                            : "grooming-new-form-section2-right-time-slot"
                        }
                      >
                        02PM-06PM
                      </div>
                      <div
                        onClick={() => setDropOffSlot(2)}
                        className={
                          dropOffSlot == 2
                            ? "grooming-new-form-section2-right-time-slot-active"
                            : "grooming-new-form-section2-right-time-slot"
                        }
                      >
                        06PM-10PM
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    gap: "10%",
                  }}
                >
                  <div
                    className="grooming-new-form-section2-left-date-text-container"
                    style={{ width: "40%" }}
                  >
                    {representationDate}{" "}
                    <span className="pink-text">{representationDay}</span>
                  </div>
                  <div
                    className="grooming-new-form-section1-right"
                    style={{ flexDirection: "row", width: "40%" }}
                  >
                    <div
                      className="grooming-new-form-section2-right-next-button-container"
                      style={{ justifyContent: "flex-end", width: "100%" }}
                    >
                      <div
                        onClick={() => {
                          groomingFormDesktopHandler2();
                        }}
                        className="grooming-new-form-section1-right-next-button"
                        style={{ width: "100%" }}
                      >
                        Next
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="grooming-new-form-section1-carousel-container"
                style={{ marginTop: "50px" }}
              >
                <div className="grooming-new-form-section1-carousel-active"></div>
                <div className="grooming-new-form-section1-carousel-active"></div>
                <div className="grooming-new-form-section1-carousel"></div>
              </div>
            </div>
          )} */}
        </>
      ) : null}
    </div>
  );
};

export default HomePageForm;
