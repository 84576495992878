import { BsArrowUp} from "@react-icons/all-files/bs/BsArrowUp";
import { BsChevronRight} from "@react-icons/all-files/bs/BsChevronRight";
import { BsChevronLeft} from "@react-icons/all-files/bs/BsChevronLeft";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import LazyLoad from "react-lazyload";
import { Link, useLocation } from "react-router-dom";
import FAQContainerWalking from "../components/container/faqContainer/FAQContainerWalking";
import HeartContainer from "../components/container/heartContainer/HeartContainer";
import classes from "../pages/Landing/LandingPage.module.css";
import GoToTop from "./GoToTop";
import TimelineComponent from "./TimelineComponent";
import "./Walking.css";

import IntroContainerDesktopWalking from "./container/introContainer/IntroContainerDesktopWalking";
import IntroContainerMobileWalking from "./container/introContainer/IntroContainerMobileWalking";

const timelineData = [
  {
    title: "Book an appointment",
    text: "Free first session for Training and Walking Services. ",
    routeText: "Book Now",
    route: "/popup",
    type: "dog-running",
  },
  {
    title: "Select Your Service",
    text: "Choose the service perfect for your pet. ",
    routeText: "Check all services",
    route: "/services",
  },
  {
    title: "Start your Pet Service",
    text: "Begin your Journey with Us on the Petmojo App. ",
    link: "https://play.google.com/store/apps/details?id=in.tamely.user",
  },
];

const testimonialArray = [
  {
    message:
      "My dogs get Walkers from PetMojo since I am busy at office. With minimal cost, both my baby is very happy and playful from everyday session. 🙏",
    name: "Revati B",
    img_src:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/testimonial-phone-revati.webp",
    profession: "Artist",
  },
  {
    message:
      "Would surely recommend to my mates & family. Really liked the app as it has all the information about the training I booked & progress.",
    name: "Sahil Khan",
    img_src:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/testimonial-phone-sahil.webp",
    profession: "BHMS",
  },
  {
    message:
      "Petmojo has been a great partner in my new pet parenting journey. I worry less knowing my dog is getting trained and walks everyday.",
    name: "Ronak K",
    img_src:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/testimonial-phone-ronak.webp",
    profession: "Shop Owner",
  },
  {
    message:
      "Vikas is excellent 👍 I know my dog's behaviour and understand his language and emotions on a level I never imagined. It was all like a magic.",
    name: "Neel Rai",
    img_src:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/testimonial-phone-neel.webp",
    profession: "Pharmacist",
  },
  {
    message:
      "Lovely App. Very easy to use. Not to mention, service is very detailed and comprehensive. Can't complain.",
    name: "Rohan D",
    img_src:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/testimonial-phone-rohanD.webp",
    profession: "Pharmacist",
  },
];

function Walking() {
  const [popup, setPopup] = useState(false);
  const [walkingState1, setWalkingState1] = useState("once-a-day");
  const [navDropdown, setNavDropdown] = useState(false);
  const [changeView, setChangeView] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [currentValue, setCurrentValue] = useState("Walking for 1 Pet");
  const [currentOption, setCurrentOption] = useState("Walking for 2 Pets");
  const [open, setOpen] = useState(false);
  const path = useLocation().pathname;
  const [testmonialData, setTestimonialData] = useState({
    message:
      "My dogs get Walkers from PetMojo since I am busy at office. With minimal cost, both my baby is very happy and playful from everyday session. 🙏",
    name: "Revati B",
    img_src:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/testimonial-phone-revati.webp",
    profession: "Artist",
  });

  let count = 0;
  function rightScroll() {
    console.log('right scroll')
    const right = document.querySelector('.slider-training-packages');
    right.scrollBy(200, 0);
  }
  useEffect(() => {
    const targetDiv = document.querySelector("#testimonialContainer");
    console.log(targetDiv);

    const interval = setInterval(() => {
      count++;
      if (count === 5) {
        count = 0;
      }
      setTestimonialData(testimonialArray[count]);
      //console.log(" The current item is: " +count+ " " + testmonialData.name);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const [isActive, setIsActive] = useState(false);
  const [trainer, setTrainer] = useState("Walking for 1 Pet");
  const [selected, setSelected] = useState("Daily Pet Exercise/Walking");
  const options = [
    "Daily Dog Exercise/Walking",
    "Daily Dog Training",
    "Daily Pet Walking",
    "Daily Pet Grooming",
  ];

  const dropdownRef = useRef(null);

  const [scroll, setScroll] = useState(true);
  const [scroll1, setScroll1] = useState(true);
  const [scroll2, setScroll2] = useState(true);
  const [scroll3, setScroll3] = useState(true);
  const [valueMobile, setValueMobile] = useState(10);
  const handleChangeMobile = (value) => {
    setValueMobile(value);
    setScroll1(false);
    const scrollableDiv = document.getElementById("scrollable-div-mobile");
    scrollableDiv.scrollLeft =
      (valueMobile / 100) *
      (scrollableDiv.scrollWidth - scrollableDiv.clientWidth);
  };
  const [valueMobile2, setValueMobile2] = useState(10);
  const handleChangeMobile2 = (value) => {
    setValueMobile2(value);
    setScroll3(false);
    const scrollableDiv = document.getElementById("scrollable-div-mobile2");
    scrollableDiv.scrollLeft =
      (valueMobile2 / 100) *
      (scrollableDiv.scrollWidth - scrollableDiv.clientWidth);
  };
  const [value1, setValue1] = useState(10);
  const handleChange = (value) => {
    setValue1(value);
    // setScroll(false);
    const scrollableDiv = document.getElementById("scrollable-div");
    scrollableDiv.scrollLeft =
      (value / 100) * (scrollableDiv.scrollWidth - scrollableDiv.clientWidth);
  };
  const [value2, setValue2] = useState(10);
  const handleChange2 = (value) => {
    setValue2(value);
    // setScroll2(false);
    const scrollableDiv = document.getElementById("scrollable-div2");
    scrollableDiv.scrollLeft =
      (value2 / 100) * (scrollableDiv.scrollWidth - scrollableDiv.clientWidth);
  };
  const scrollToTop = () => {
    return window.scrollTo({ top: 200, left: 0, behavior: "smooth" });
  };

  function goToTopHandler() {
    return window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }
  function rightScroll() {
    console.log('right scroll')
    const right = document.querySelector('.slider-training-packages');
    right.scrollBy(200, 0);
  }
  function leftScroll() {
    console.log('right scroll')
    const right = document.querySelector('.slider-training-packages');
    right.scrollBy(-200, 0);
  }
  const location = useLocation();
  const sectionOneRef = useRef(null);
  const sectionTwoRef = useRef(null);
  const sectionThreeRef = useRef(null);
  // console.log(section1);
  // const mySectionRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // console.log('Section is now visible');
          const ele1 = document.getElementById("section1");
          const ele2 = document.getElementById("section2");
          // console.log(ele);
          ele1.classList.add("appstore");
          ele2.classList.add("playstore");
          // Trigger the section action
        }
      },
      { rootMargin: "0px 0px -50% 0px" }
    );
    if (sectionOneRef.current) {
      observer.observe(sectionOneRef.current);
    }
    return () => {
      if (sectionOneRef.current) {
        observer.unobserve(sectionOneRef.current);
      }
    };
  }, []);
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        // console.log('Section is now visible');
        const ele1 = document.getElementById("section3");
        const ele2 = document.getElementById("section4");
        // console.log(ele);
        ele1.classList.add("appstore");
        ele2.classList.add("playstore");
        // Trigger the section action
      }
    });
    if (sectionTwoRef.current) {
      observer.observe(sectionTwoRef.current);
    }
    return () => {
      if (sectionTwoRef.current) {
        observer.unobserve(sectionTwoRef.current);
      }
    };
  }, []);
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        // console.log('Section is now visible');
        const ele1 = document.getElementById("section5");
        const ele2 = document.getElementById("section6");

        ele1.classList.add("appstore");
        ele2.classList.add("playstore");
        // Trigger the section action
      }
    });
    if (sectionThreeRef.current) {
      observer.observe(sectionThreeRef.current);
    }
    return () => {
      if (sectionThreeRef.current) {
        observer.unobserve(sectionThreeRef.current);
      }
    };
  }, []);
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setNavDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (window.innerWidth <= 1000) {
      setChangeView(false);
    } else {
      setChangeView(true);
    }
  }, []);

  useEffect(() => {
    const script1 = document.createElement("script");
    const script2 = document.createElement("script");

    script1.type = "application/ld+json";
    script1.innerText = JSON.stringify({
      "@context": "https://schema.org/",
      "@type": "Review",
      author: {
        "@type": "Person",
        name: "Pankaj Dhanotia",
      },
      itemReviewed: {
        "@type": "LocalBusiness",
        name: "Petmojo Walking Service",
        address: "New Delhi, India",
        image:
          "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/petmojo-logo-white.png",
        aggregateRating: {
          "@type": "AggregateRating",
          ratingValue: "4.6",
          ratingCount: "2563",
        },
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: "4.7",
        reviewAspect: "",
      },
      publisher: {
        "@type": "Person",
        name: "Ahana Sharma",
      },
      reviewBody:
        "We have been using Petmojo’s dog walking service for 3 months now. Our life has become so easy thanks to them. Best Dog Walkers in town!",
    });

    script2.type = "application/ld+json";
    script2.innerText = JSON.stringify({
      "@context": "https://schema.org/",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "Where will you walk my dog?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Our team will be happy to walk your dog where you would normally walk them. We can stick to your immediate neighborhood or take them to another area, depending on your preferences. Our aim is to keep the walk as close to yours as possible so that we are consistent with your dog's routine. Please note that we are unable to transport your dog.",
          },
        },
        {
          "@type": "Question",
          name: "Do you conduct background checks on your Trainers/Walkers?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Our certified caregivers at Petmojo undergo rigorous background checks as well as an extensive certification program before they can care for our clients. We only hire individuals we would trust to care for our own pets, so you can be sure your furry friends are always in the best hands.",
          },
        },
        {
          "@type": "Question",
          name: "My dog ​​has health problems, will you still come?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Of course! Our certified caregivers are trained to care for pets with special needs as well as older pets. During your free initial consultation, we'll take the time to understand the range of your pet's needs and copy down the detailed information you provide to ensure they get exactly what they need while you're not around.",
          },
        },
        {
          "@type": "Question",
          name: "What are you doing on your walk?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "We can tailor walks to your liking. Our goal is to keep your dog peeing and pooping and your pet super happy!",
          },
        },
        {
          "@type": "Question",
          name: "Can I meet the person who will walk my dog?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Yes. We strongly recommend that you have an initial meeting with your walker at your home to discuss your requirements prior to the walk. They can be arranged at short notice for a time that suits you.",
          },
        },

        {
          "@type": "Question",
          name: "Will the same person walk my dog ​​every day?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Most of the time, yes. You will have a dedicated walker. However, there may be a special occasion due to illness or annual leave when we need to replace the walker. The replacement walker will be fully informed of your dog's specific requirements. Our entire team is very experienced and we communicate constantly, so your dog will always be in great hands.",
          },
        },
      ],
    });
    script1.id = "walking-page1";
    script2.id = "walking-page2";
    document.body.appendChild(script1);
    document.body.appendChild(script2);

    return () => {
      document.getElementById("walking-page1").remove();
      document.getElementById("walking-page2").remove();
    };
  }, []);

  useEffect(() => {
    document.head.innerHTML += `
    
<meta id="walking-1" property="og:title" content="Book Daily Dog walkers near you - Petmojo"/>
<meta id="walking-2" property="og:site_name" content="Petmojo"/>
<meta id="walking-3" property="og:url" content="https://petmojo.care"/>
<meta id="walking-4" property="og:description" content="Your pet needs physical exercise to stay healthy. We know you have a busy schedule, so we'll take care of dog walking and you can track their progress from your home."/>
<meta id="walking-5" property="og:type" content="website"/>
<meta id="walking-6" property="og:image" content="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/petmojo-logo-white.png"/>


<title id="walking-id">Get Dog walkers near you at your home - Petmojo</title>
<meta id="walking-desc" name="description" content="Professional dog walkers at your home. Dog walking service to get the exercise your dog needs despite your busy schedules" />
    

    `;

    return () => {
      document.getElementById("walking-1").remove();
      document.getElementById("walking-2").remove();
      document.getElementById("walking-3").remove();
      document.getElementById("walking-4").remove();
      document.getElementById("walking-5").remove();
      document.getElementById("walking-6").remove();
      document.getElementById("walking-id").remove();
      document.getElementById("walking-desc").remove();
    };
  }, []);

  const dropdownHandler = () => {
    setShowDropdown(!showDropdown);
  };

  const reciprocateHandler = () => {
    const trashValue = currentValue;
    setCurrentValue(currentOption);
    setCurrentOption(trashValue);

    setShowDropdown(!showDropdown);
  };

  const breakPoints = [
    { width: 1, itemsToShow: 3 },
    { width: 550, itemsToShow: 3 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 },
  ];

  const data = [
    {
      title: "Where will you walk my dog?",
      content:
        "Our team will be happy to walk your dog where you would normally walk them. We can stick to your immediate neighborhood or take them to another area, depending on your preferences. Our aim is to keep the walk as close to yours as possible so that we are consistent with your dog's routine. Please note that we are unable to transport your dog.",
    },
    {
      title: "Do you conduct background checks on your Trainers/Walkers?",
      content:
        "Our certified caregivers at Petmojo undergo rigorous background checks as well as an extensive certification program before they can care for our clients. We only hire individuals we would trust to care for our own pets, so you can be sure your furry friends are always in the best hands, except yours.",
    },
    {
      title: "My dog ​​has health problems, will you still come?",
      content:
        "Of course! Our certified caregivers are trained to care for pets with special needs as well as older pets. During your free initial consultation, we'll take the time to understand the range of your pet's needs and copy down the detailed information you provide to ensure they get exactly what they need while you're away.",
    },
    {
      title: "What are you doing on your walk?",
      content:
        "We can tailor visits to your liking. Our goal is to keep your dog peeing and pooping and your pet super happy!",
    },
    {
      title: "Can I meet the person who will walk my dog?",
      content:
        "Yes. We strongly recommend that you have an initial meeting with your walker at your home to discuss your requirements prior to the walk. They can be arranged at short notice for a time that suits you.",
    },
    {
      title: "Will the same person walk my dog ​​every day?",
      content:
        "Most of the time, yes. You will have a dedicated walker. However, there may be a special occasion due to illness or annual leave when we need to replace the walker. The replacement walker will be fully informed of your dog's specific requirements. Our entire team is very experienced and we communicate constantly, so your dog will always be in great hands.",
    },
  ];

  const value = 1.1;
  const [width, setWidth] = useState();
  useEffect(() => {
    setWidth(window.innerWidth);
  }, [width])
  return (
    <div className="walking">
      <Helmet>
        <title>Get Dog walkers near you at your home - Petmojo</title>
        <meta
          name="description"
          content="Professional dog walkers at your home. Dog walking service to get the exercise your dog needs despite your busy schedules"
        />
      </Helmet>

      {!changeView ? (
        <IntroContainerMobileWalking />
      ) : (
        <IntroContainerDesktopWalking />
      )}
      <div className="sliderServiceContainer" style={{ marginTop: "50px", backgroundColor: "#F3E18A" }}>
        {!changeView ? (
          <div style={{ marginTop: '20px', padding: '20px' }}>
            <div className="textbox2" style={{ color: "black" }}>
              Meet our Happy Doggos
            </div>
            <hr className="vector2" style={{ borderColor: 'black' }}></hr>

            <div className="dog-container" style={{ border: "10px solid black", padding: '0px', display: 'flex', width: '100%', justifyContent: "center" }}>
              <div className="dog-box-2" style={{ display: "flex", justifyContent: "center", backgroundColor: "rgba(0, 0, 0, 0.10)", marginTop: '0px' }}>
                <div className="social-page-mobile-view-card-container" style={{ display: "flex", justifyContent: "center", border: "none", marginRight: '0px', marginBottom: "0px", paddingBottom: "0px", padding: "5px 10px" }}>
                  <div style={{ width: "100%", height: "100%" }}>
                    <video width="100%" height="100%" controls poster="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/walking-mobile-thumbnail.webp" muted loop tabIndex="-1" autoPlay>
                      <source src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/review-1.mp4" type="video/mp4" />
                    </video>
                  </div>
                </div>


              </div>
            </div>
          </div>
        ) : null}
      </div>
      {!changeView ? null : (
        <div className="dog-container-desktop2" style={{ marginTop: "50px", backgroundColor: "#F3E18A" }}>
          <div style={{ border: "10px solid black", display: 'flex', width: "100%", padding: "20px", justifyContent: "space-between" }}>
            <div className="dog-slide-desktop-training">
              <div className="training-page-behave-box1" style={{ marginBottom: "0px" }}>
                <h2 className="fifty-font-size bold-none" style={{ color: "black" }}>
                  Meet our
                  Happy Doggos
                </h2>
                <div className="line-div relative-line">
                  <hr className="live-training-pink-line" style={{ borderColor: 'black' }}></hr>
                </div>
              </div>
              <div className="training-page-behave-box2" style={{ position: "relative", bottom: "20px" }}>
                <h4 className="bold-none" style={{ color: 'black' }}>
                  <div>16000+ monthly happy pet </div>
                  <div>parents on our platform!</div>
                </h4>
              </div>
            </div>

            <div className="dog-box-desktop-training" style={{ display: "flex", justifyContent: "center", backgroundColor: "rgba(0, 0, 0, 0.10)", width: '50%' }}>
              {/* <div className="adoption-price-tag-out">
                    
                    </div>
                <div className="adoption-price-tag" style={{background:'black',width:"200px"}}>
                <span className="adoption-price" style={{color:"white"}}>
                Happy Customers
                </span>
                </div> */}
              <div className="educational-content-card-training" style={{ width: "100%", height: "100%", boxShadow: "0px 4px 76px 0px #000", backgroundColor: "none", border: "none", background: "transparent", padding: '0px', margin: "0px", borderRadius: "0px" }}>
                <video width="100%" height="100%" controls muted loop poster="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/walking-desktop-thumbnail.webp">
                  <source src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/review-1.mp4" type="video/mp4" />
                </video>
              </div>


            </div>
          </div>

        </div>
      )}
      {!changeView ? (
        <div className="walking-page-margin-top">
          <div className="textbox2 ">Our Monthly Packages</div>
          <hr className="vector1"></hr>
        </div>
      ) : null}

      {!changeView ? (
        <div className="walking-page-mobile-view-packages-master-container grey-background">
          <div className="textbox2-walking-mobile">
            <span onClick={() => setOpen(!open)} className="trainers-option">
              {trainer}{" "}
              <span>
                <div className="home-page-form-mobile-view-dropdown-arrow">
                  <svg
                    className="walking-page-form-mobile-view-dropdown-arrow-img"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    fill="currentColor"
                    class="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
              </span>
            </span>
          </div>

          {!open ? null : (
            <div className="optionsContainer-walking">
              <div
                onClick={() => {
                  setTrainer("Walking for 1 Pet");
                  setOpen(!open);
                }}
                className="option"
              >
                Walking for 1 Pet
              </div>
              <hr></hr>
              <div
                onClick={() => {
                  setTrainer("Walking for 2 Pets");
                  setOpen(!open);
                }}
                className="option"
              >
                Walking for 2 Pets
              </div>
            </div>
          )}
        </div>
      ) : null}

      {!changeView ? (
        <div className="mobile-view-walking-packages-master-container grey-background">
          {trainer === "Walking for 1 Pet" ? (
            <div class="slider-container-grey">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  class="slider-training-package-mobile"
                  id="scrollable-div-mobile"
                  style={{ overflowX: "scroll" }}
                >
                  <div
                    className="training-page-mobile-view-package-container"
                    style={{ animation: "scroll-div10 2s linear" }} onClick={scrollToTop}
                  >
                    <div className="training-page-mobile-view-package-sec-1">
                      <img
                        alt="trainingIcon"
                        className="training-page-mobile-view-packageIcon"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Mask_group_ltsl6l.png"
                      />
                    </div>
                    <div className="walking-orange-txt">One Pet</div>
                    <div className="walking-page-mobile-view-package-sec-2">
                      PET EXERCISE & TRAINING
                    </div>
                    <div className="training-page-mobile-view-package-sec-3">
                      <div className="training-page-mobile-view-package-sec-3-1">
                        Monthly | 26 Sessions | Once a day
                      </div>
                      <div className="training-page-mobile-view-package-section3">
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>30 mins walk each</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>GPS Live Tracking</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>Photo Updates</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>Daily Report Card</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>100% Money Back Guarantee</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>Distance Covered</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>Weekly one off</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>Professional Dog Trainers Only</div>
                        </div>
                      </div>
                    </div>

                    <div className="training-page-mobile-view-package-sec-4">
                      <div className="training-card-mobile-view-discounted-price">
                        Discounted Price
                      </div>
                      <div className="walking-card-mobile-view-price-btn">
                        <div className="training-card-mobile-view-og-price">
                          <div className="diagonal-line-mobile-view-training">
                            <div className="diagonal-line-mobile-view-text">
                              ₹9999
                            </div>
                          </div>{" "}
                        </div>
                        <div className="walking-1-card-mobile-view-disc-price">
                          ₹2940
                        </div>
                      </div>
                      <div className="mobile-view-per-session" style={{ fontWeight: "bold" }}>(+18% GST)</div>
                    </div>
                  </div>
                  <div
                    className="training-page-mobile-view-package-container"
                    style={{ animation: "scroll-div10 2s linear" }} onClick={scrollToTop}
                  >
                    <div className="training-page-mobile-view-package-sec-1">
                      <img
                        alt="trainingIcon"
                        className="training-page-mobile-view-packageIcon"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Mask_group_mwibst.png"
                      />
                    </div>
                    <div className="walking-pink-txt">One Pet</div>
                    <div className="walking-page-mobile-view-package-sec-2-two">
                      PET EXERCISE & TRAINING
                    </div>
                    <div className="training-page-mobile-view-package-sec-3">
                      <div className="training-page-mobile-view-package-sec-3-1">
                        Monthly | 52 Sessions | Twice a day
                      </div>
                      <div className="training-page-mobile-view-package-section3">
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>30 mins walk each</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>GPS Live Tracking</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>Photo Updates</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>Daily Report Card</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>100% Money Back Guarantee</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>Recommended 60 mins each day</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>Overexcitement Correction</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>Professional Dog Trainers Only</div>
                        </div>
                      </div>
                    </div>

                    <div className="training-page-mobile-view-package-sec-4">
                      <div className="training-card-mobile-view-discounted-price">
                        Discounted Price
                      </div>
                      <div className="walking-card-mobile-view-price-btn">
                        <div className="training-card-mobile-view-og-price">
                          <div className="diagonal-line-mobile-view-training">
                            <div className="diagonal-line-mobile-view-text">
                              ₹15999
                            </div>
                          </div>{" "}
                        </div>
                        <div className="walking-1-card-mobile-view-disc-price">
                          ₹5940
                        </div>
                      </div>
                      <div className="mobile-view-per-session" style={{ fontWeight: "bold" }}>(+18% GST)</div>
                    </div>
                  </div>
                  <div
                    className="training-page-mobile-view-package-container"
                    style={{ animation: "scroll-div10 2s linear" }} onClick={scrollToTop}
                  >
                    <div className="training-page-mobile-view-package-sec-1">
                      <img
                        alt="trainingIcon"
                        className="training-page-mobile-view-packageIcon"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Mask_group_mwibst.png"
                      />
                    </div>
                    <div className="walking-pink-txt">One Pet</div>
                    <div className="walking-page-mobile-view-package-sec-2-two">
                      PET EXERCISE & TRAINING
                    </div>
                    <div className="training-page-mobile-view-package-sec-3">
                      <div className="training-page-mobile-view-package-sec-3-1">
                        Monthly | 78 Sessions | Thrice a day
                      </div>
                      <div className="training-page-mobile-view-package-section3">
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>30 mins walk each</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>GPS Live Tracking</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>Photo Updates</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>Daily Report Card</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>100% Money Back Guarantee</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>Recommended 60 mins each day</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>Overexcitement Correction</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>Professional Dog Trainers Only</div>
                        </div>
                      </div>
                    </div>

                    <div className="training-page-mobile-view-package-sec-4">
                      <div className="training-card-mobile-view-discounted-price">
                        Discounted Price
                      </div>
                      <div className="walking-card-mobile-view-price-btn">
                        <div className="training-card-mobile-view-og-price">
                          <div className="diagonal-line-mobile-view-training">
                            <div className="diagonal-line-mobile-view-text">
                              ₹19999
                            </div>
                          </div>{" "}
                        </div>
                        <div className="walking-1-card-mobile-view-disc-price">
                          ₹8940
                        </div>
                      </div>
                      <div className="mobile-view-per-session" style={{ fontWeight: "bold" }}>(+18% GST)</div>
                    </div>
                  </div>
                </div>
                {/* <div
                  style={{
                    width: "100%",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  
                </div> */}
              </div>
            </div>
          ) : (
            <div class="slider-container-grey">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  class="slider-training-package-mobile"
                  id="scrollable-div-mobile2"
                  style={{ overflowX: "scroll" }}
                >
                  <div
                    className="training-page-mobile-view-package-container"
                    style={{ animation: "scroll-div10 2s linear" }} onClick={scrollToTop}
                  >
                    <div className="training-page-mobile-view-package-sec-1">
                      <img
                        alt="trainingIcon"
                        className="training-page-mobile-view-packageIcon"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Mask_group_hd1jdh.png"
                      />
                    </div>
                    <div className="walking-orange-txt">Two Pets</div>
                    <div className="walking-page-mobile-view-package-sec-2">
                      PET EXERCISE & TRAINING
                    </div>
                    <div className="training-page-mobile-view-package-sec-3">
                      <div className="training-page-mobile-view-package-sec-3-1">
                        Monthly | 26 Sessions | Once a day
                      </div>
                      <div className="training-page-mobile-view-package-section3">
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>30 mins walk each</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>GPS Live Tracking</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>Photo Updates</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>Daily Report Card</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>100% Money Back Guarantee</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>Distance Covered</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>Weekly one off</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>Professional Dog Trainers Only</div>
                        </div>
                      </div>
                    </div>

                    <div className="training-page-mobile-view-package-sec-4">
                      <div className="training-card-mobile-view-discounted-price">
                        Discounted Price
                      </div>
                      <div className="walking-card-mobile-view-price-btn">
                        <div className="training-card-mobile-view-og-price">
                          <div className="diagonal-line-mobile-view-training">
                            <div className="diagonal-line-mobile-view-text">
                              ₹19998
                            </div>
                          </div>{" "}
                        </div>
                        <div className="walking-1-card-mobile-view-disc-price">
                          ₹3940
                        </div>
                      </div>
                      <div className="mobile-view-per-session" style={{ fontWeight: "bold" }}>(+18% GST)</div>
                    </div>
                  </div>
                  <div
                    className="training-page-mobile-view-package-container"
                    style={{ animation: "scroll-div10 2s linear" }} onClick={scrollToTop}
                  >
                    <div className="training-page-mobile-view-package-sec-1">
                      <img
                        alt="trainingIcon"
                        className="training-page-mobile-view-packageIcon"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Mask_group_dqz11s.png"
                      />
                    </div>
                    <div className="walking-pink-txt">Two Pets</div>
                    <div className="walking-page-mobile-view-package-sec-2-two">
                      PET EXERCISE & TRAINING
                    </div>
                    <div className="training-page-mobile-view-package-sec-3">
                      <div className="training-page-mobile-view-package-sec-3-1">
                        Monthly | 52 Sessions | Twice a day
                      </div>
                      <div className="training-page-mobile-view-package-section3">
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>30 mins walk each</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>GPS Live Tracking</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>Photo Updates</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>Daily Report Card</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>100% Money Back Guarantee</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>Recommended 60 mins each day</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>Overexcitement Correction</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>Professional Dog Trainers Only</div>
                        </div>
                      </div>
                    </div>

                    <div className="training-page-mobile-view-package-sec-4">
                      <div className="training-card-mobile-view-discounted-price">
                        Discounted Price
                      </div>
                      <div className="walking-card-mobile-view-price-btn">
                        <div className="training-card-mobile-view-og-price">
                          <div className="diagonal-line-mobile-view-training">
                            <div className="diagonal-line-mobile-view-text">
                              ₹29998
                            </div>
                          </div>{" "}
                        </div>
                        <div className="training-card-mobile-view-disc-price">
                          ₹6940
                        </div>
                      </div>
                      <div className="mobile-view-per-session" style={{ fontWeight: "bold" }}>(+18% GST)</div>
                    </div>
                  </div>
                  <div
                    className="training-page-mobile-view-package-container"
                    style={{ animation: "scroll-div10 2s linear" }} onClick={scrollToTop}
                  >
                    <div className="training-page-mobile-view-package-sec-1">
                      <img
                        alt="trainingIcon"
                        className="training-page-mobile-view-packageIcon"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Mask_group_dqz11s.png"
                      />
                    </div>
                    <div className="walking-pink-txt">Two Pets</div>
                    <div className="walking-page-mobile-view-package-sec-2-two">
                      PET EXERCISE & TRAINING
                    </div>
                    <div className="training-page-mobile-view-package-sec-3">
                      <div className="training-page-mobile-view-package-sec-3-1">
                        Monthly | 78 Sessions | Thrice a day
                      </div>
                      <div className="training-page-mobile-view-package-section3">
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>30 mins walk each</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>GPS Live Tracking</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>Photo Updates</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>Daily Report Card</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>100% Money Back Guarantee</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>Recommended 60 mins each day</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>Overexcitement Correction</div>
                        </div>
                        <div className="walking-page-mobile-view-package-point">
                          <div className="">
                            <img
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                              alt="tickIcon"
                              className="walking-page-tick-mobile"
                            />
                          </div>
                          <div>Professional Dog Trainers Only</div>
                        </div>
                      </div>
                    </div>

                    <div className="training-page-mobile-view-package-sec-4">
                      <div className="training-card-mobile-view-discounted-price">
                        Discounted Price
                      </div>
                      <div className="walking-card-mobile-view-price-btn">
                        <div className="training-card-mobile-view-og-price">
                          <div className="diagonal-line-mobile-view-training">
                            <div className="diagonal-line-mobile-view-text">
                              ₹29998
                            </div>
                          </div>{" "}
                        </div>
                        <div className="training-card-mobile-view-disc-price">
                          ₹9940
                        </div>
                      </div>
                      <div className="mobile-view-per-session" style={{ fontWeight: "bold" }}>(+18% GST)</div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  {/* <Slider
                    min={0}
                    max={100}
                    value={valueMobile2}
                    onChange={handleChangeMobile2}
                    onChangeComplete={() => setScroll3(true)}
                    tooltip={false}
                  /> */}
                  {/* <center style={{}}>
                <Loader color="#ff5e95" />
                </center> */}
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div
          className="slider-container-desktop-grooming margin-for-desktop"
          style={{ justifyContent: "space-between", marginTop: "0px", position: "relative" }}
        >
          <div className="slide-grooming-package grooming-package-1-desktop">
            <div className="walking-page-package-container">
              <div className="walking-page-package-heading">
                <div className="walking-page-package-heading-line">
                  <h2 className="fifty-font-size bold-none">
                    Our Monthly Packages{" "}
                  </h2>
                </div>
                <div className="line-div relative-line">
                  <hr className="live-training-pink-line"></hr>
                </div>
              </div>

              <div className="walking-page-packages-dropdown-master-container">
                <div
                  onClick={dropdownHandler}
                  className="walking-page-packages-dropdown-container"
                >
                  <div className="walking-page-packages-dropdown">
                    {currentValue}
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-chevron-down"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </div>
                </div>

                {showDropdown ? (
                  <div
                    onClick={reciprocateHandler}
                    className="walking-dropdown-container"

                  >
                    {currentOption}
                  </div>
                ) : null}
              </div>

              <div style={{ marginTop: showDropdown ? "20px" : "" }}>
                <div className="bookingbutton-desktop">
                  {/* <Link to="/popup" state={{ type: "dog-running" }}> */}
                  <button className="booksession" onClick={() => scrollToTop()}>
                    Book FREE Walk
                  </button>
                  {/* </Link> */}
                  <div className="red-text">Hurry Up, Limited slots left!</div>
                </div>
              </div>
            </div>
          </div>

          <div className="walking-page-packages-right">
            {currentValue === "Walking for 1 Pet" ? (
              <div class="slider-container-desktop-training2">
                <button style={{ height: "150px", borderRadius: "5px", padding: "10px", display: "flex", flexDirection: "column", justifyContent: "center", borderColor: "rgba(254, 119, 152, 1)", border: "2px solid rgba(254,119,152,1)", cursor: "pointer", background: "none" }} onClick={leftScroll}>
                  <BsChevronLeft color="rgba(254,119,152,1)" size={20} />
                </button>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "10px"
                  }}
                >
                  <div
                    class="slider-training-packages"
                    id="scrollable-div"
                    style={{ overflowX: "auto", width: "50vw", margin: "0px", scrollBehavior: "smooth" }}
                  >
                    <div
                      className="training-page-mobile-view-package-container"
                      style={{ animation: "scroll-div10 2s linear" }} onClick={scrollToTop}
                    >
                      <div className="training-page-mobile-view-package-sec-1">
                        <img
                          alt="trainingIcon"
                          className="training-page-mobile-view-packageIcon"
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Mask_group_ltsl6l.png"
                        />
                      </div>
                      <div className="walking-orange-txt">One Pet</div>
                      <div className="walking-page-mobile-view-package-sec-2">
                        PET EXERCISE & TRAINING
                      </div>
                      <div className="training-page-mobile-view-package-sec-3">
                        <div className="training-page-mobile-view-package-sec-3-1">
                          Monthly | 26 Sessions | Once a day
                        </div>
                        <div className="training-page-mobile-view-package-section3">
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>30 mins walk each</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>GPS Live Tracking</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>Photo Updates</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>Daily Report Card</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>100% Money Back Guarantee</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>Distance Covered</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>Weekly one off</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>Professional Dog Trainers Only</div>
                          </div>
                        </div>
                      </div>

                      <div className="training-page-mobile-view-package-sec-4">
                        <div className="training-card-mobile-view-discounted-price">
                          Discounted Price
                        </div>
                        <div className="walking-card-mobile-view-price-btn">
                          <div className="training-card-mobile-view-og-price">
                            <div className="diagonal-line-mobile-view-training">
                              <div className="diagonal-line-mobile-view-text">
                                ₹9999
                              </div>
                            </div>{" "}
                          </div>
                          <div className="walking-1-card-mobile-view-disc-price">
                            ₹2940
                          </div>
                        </div>
                        <div className="mobile-view-per-session" style={{ fontWeight: "bold" }}>(+18% GST)</div>
                      </div>
                    </div>
                    <div
                      className="training-page-mobile-view-package-container"
                      style={{ animation: "scroll-div10 2s linear" }} onClick={scrollToTop}
                    >
                      <div className="training-page-mobile-view-package-sec-1">
                        <img
                          alt="trainingIcon"
                          className="training-page-mobile-view-packageIcon"
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Mask_group_mwibst.png"
                        />
                      </div>
                      <div className="walking-pink-txt">One Pet</div>
                      <div className="walking-page-mobile-view-package-sec-2-two">
                        PET EXERCISE & TRAINING
                      </div>
                      <div className="training-page-mobile-view-package-sec-3">
                        <div className="training-page-mobile-view-package-sec-3-1">
                          Monthly | 52 Sessions | Twice a day
                        </div>
                        <div className="training-page-mobile-view-package-section3">
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>30 mins walk each</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>GPS Live Tracking</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>Photo Updates</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>Daily Report Card</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>100% Money Back Guarantee</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>Recommended 60 mins each day</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>Overexcitement Correction</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>Professional Dog Trainers Only</div>
                          </div>
                        </div>
                      </div>

                      <div className="training-page-mobile-view-package-sec-4">
                        <div className="training-card-mobile-view-discounted-price">
                          Discounted Price
                        </div>
                        <div className="walking-card-mobile-view-price-btn">
                          <div className="training-card-mobile-view-og-price">
                            <div className="diagonal-line-mobile-view-training">
                              <div className="diagonal-line-mobile-view-text">
                                ₹15999
                              </div>
                            </div>{" "}
                          </div>
                          <div className="walking-1-card-mobile-view-disc-price">
                            ₹5940
                          </div>
                        </div>
                        <div className="mobile-view-per-session" style={{ fontWeight: "bold" }}>(+18% GST)</div>
                      </div>
                    </div>
                    <div
                      className="training-page-mobile-view-package-container"
                      style={{ animation: "scroll-div10 2s linear" }} onClick={scrollToTop}
                    >
                      <div className="training-page-mobile-view-package-sec-1">
                        <img
                          alt="trainingIcon"
                          className="training-page-mobile-view-packageIcon"
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Mask_group_mwibst.png"
                        />
                      </div>
                      <div className="walking-pink-txt">One Pet</div>
                      <div className="walking-page-mobile-view-package-sec-2-two">
                        PET EXERCISE & TRAINING
                      </div>
                      <div className="training-page-mobile-view-package-sec-3">
                        <div className="training-page-mobile-view-package-sec-3-1">
                          Monthly | 78 Sessions | Thrice a day
                        </div>
                        <div className="training-page-mobile-view-package-section3">
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>30 mins walk each</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>GPS Live Tracking</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>Photo Updates</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>Daily Report Card</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>100% Money Back Guarantee</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>Recommended 60 mins each day</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>Overexcitement Correction</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>Professional Dog Trainers Only</div>
                          </div>
                        </div>
                      </div>

                      <div className="training-page-mobile-view-package-sec-4">
                        <div className="training-card-mobile-view-discounted-price">
                          Discounted Price
                        </div>
                        <div className="walking-card-mobile-view-price-btn">
                          <div className="training-card-mobile-view-og-price">
                            <div className="diagonal-line-mobile-view-training">
                              <div className="diagonal-line-mobile-view-text">
                                ₹19999
                              </div>
                            </div>{" "}
                          </div>
                          <div className="walking-1-card-mobile-view-disc-price">
                            ₹8940
                          </div>
                        </div>
                        <div className="mobile-view-per-session" style={{ fontWeight: "bold" }}>(+18% GST)</div>
                      </div>
                    </div>
                  </div>

                </div>
                <button style={{ height: "150px", borderRadius: "5px", padding: "10px", display: "flex", flexDirection: "column", justifyContent: "center", borderColor: "rgba(254, 119, 152, 1)", border: "2px solid rgba(254,119,152,1)", cursor: "pointer", background: "none" }} onClick={rightScroll}>
                  <BsChevronRight color="rgba(254,119,152,1)" size={20} />
                </button>
                {/* <div style={{height:"150px",borderRadius:"5px",padding:"10px",display:"flex",flexDirection:"column",justifyContent:"center",borderColor:"rgba(254, 119, 152, 1)",border:"2px solid rgba(254,119,152,1)"}}>
            <BsChevronRight color="rgba(254,119,152,1)"/>
          </div> */}
              </div>
            ) : (
              <div class="slider-container-desktop-training2">
                <button style={{ height: "150px", borderRadius: "5px", padding: "10px", display: "flex", flexDirection: "column", justifyContent: "center", borderColor: "rgba(254, 119, 152, 1)", border: "2px solid rgba(254,119,152,1)", cursor: "pointer", background: "none" }} onClick={leftScroll}>
                  <BsChevronLeft color="rgba(254,119,152,1)" size={20} />
                </button>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "10px"
                  }}
                >
                  <div
                    class="slider-training-packages"
                    id="scrollable-div2"
                    style={{ overflowX: "scroll", width: "50vw" }}
                  >
                    <div
                      className="training-page-mobile-view-package-container"
                      style={{ animation: "scroll-div10 2s linear" }} onClick={scrollToTop}
                    >
                      <div className="training-page-mobile-view-package-sec-1">
                        <img
                          alt="trainingIcon"
                          className="training-page-mobile-view-packageIcon"
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Mask_group_hd1jdh.png"
                        />
                      </div>
                      <div className="walking-orange-txt">Two Pets</div>
                      <div className="walking-page-mobile-view-package-sec-2">
                        PET EXERCISE & TRAINING
                      </div>
                      <div className="training-page-mobile-view-package-sec-3">
                        <div className="training-page-mobile-view-package-sec-3-1">
                          Monthly | 26 Sessions | Once a day
                        </div>
                        <div className="training-page-mobile-view-package-section3">
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>30 mins walk each</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>GPS Live Tracking</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>Photo Updates</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>Daily Report Card</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>100% Money Back Guarantee</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>Distance Covered</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>Weekly one off</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>Professional Dog Trainers Only</div>
                          </div>
                        </div>
                      </div>

                      <div className="training-page-mobile-view-package-sec-4">
                        <div className="training-card-mobile-view-discounted-price">
                          Discounted Price
                        </div>
                        <div className="walking-card-mobile-view-price-btn">
                          <div className="training-card-mobile-view-og-price">
                            <div className="diagonal-line-mobile-view-training">
                              <div className="diagonal-line-mobile-view-text">
                                ₹19998
                              </div>
                            </div>{" "}
                          </div>
                          <div className="walking-1-card-mobile-view-disc-price">
                            ₹3940
                          </div>
                        </div>
                        <div className="mobile-view-per-session" style={{ fontWeight: "bold" }}>(+18% GST)</div>
                      </div>
                    </div>
                    <div
                      className="training-page-mobile-view-package-container"
                      style={{ animation: "scroll-div10 2s linear" }} onClick={scrollToTop}
                    >
                      <div className="training-page-mobile-view-package-sec-1">
                        <img
                          alt="trainingIcon"
                          className="training-page-mobile-view-packageIcon"
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Mask_group_dqz11s.png"
                        />
                      </div>
                      <div className="walking-pink-txt">Two Pets</div>
                      <div className="walking-page-mobile-view-package-sec-2-two">
                        PET EXERCISE & TRAINING
                      </div>
                      <div className="training-page-mobile-view-package-sec-3">
                        <div className="training-page-mobile-view-package-sec-3-1">
                          Monthly | 52 Sessions | Twice a day
                        </div>
                        <div className="training-page-mobile-view-package-section3">
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>30 mins walk each</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>GPS Live Tracking</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>Photo Updates</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>Daily Report Card</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>100% Money Back Guarantee</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>Recommended 60 mins each day</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>Overexcitement Correction</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>Professional Dog Trainers Only</div>
                          </div>
                        </div>
                      </div>

                      <div className="training-page-mobile-view-package-sec-4">
                        <div className="training-card-mobile-view-discounted-price">
                          Discounted Price
                        </div>
                        <div className="walking-card-mobile-view-price-btn">
                          <div className="training-card-mobile-view-og-price">
                            <div className="diagonal-line-mobile-view-training">
                              <div className="diagonal-line-mobile-view-text">
                                ₹29998
                              </div>
                            </div>{" "}
                          </div>
                          <div className="training-card-mobile-view-disc-price">
                            ₹6940
                          </div>
                        </div>
                        <div className="mobile-view-per-session" style={{ fontWeight: "bold" }}>(+18% GST)</div>
                      </div>
                    </div>
                    <div
                      className="training-page-mobile-view-package-container"
                      style={{ animation: "scroll-div10 2s linear" }} onClick={scrollToTop}
                    >
                      <div className="training-page-mobile-view-package-sec-1">
                        <img
                          alt="trainingIcon"
                          className="training-page-mobile-view-packageIcon"
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Mask_group_dqz11s.png"
                        />
                      </div>
                      <div className="walking-pink-txt">Two Pets</div>
                      <div className="walking-page-mobile-view-package-sec-2-two">
                        PET EXERCISE & TRAINING
                      </div>
                      <div className="training-page-mobile-view-package-sec-3">
                        <div className="training-page-mobile-view-package-sec-3-1">
                          Monthly | 78 Sessions | Thrice a day
                        </div>
                        <div className="training-page-mobile-view-package-section3">
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>30 mins walk each</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>GPS Live Tracking</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>Photo Updates</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>Daily Report Card</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>100% Money Back Guarantee</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>Recommended 60 mins each day</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>Overexcitement Correction</div>
                          </div>
                          <div className="walking-page-mobile-view-package-point">
                            <div className="">
                              <img
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                                alt="tickIcon"
                                className="walking-page-tick-mobile"
                              />
                            </div>
                            <div>Professional Dog Trainers Only</div>
                          </div>
                        </div>
                      </div>

                      <div className="training-page-mobile-view-package-sec-4">
                        <div className="training-card-mobile-view-discounted-price">
                          Discounted Price
                        </div>
                        <div className="walking-card-mobile-view-price-btn">
                          <div className="training-card-mobile-view-og-price">
                            <div className="diagonal-line-mobile-view-training">
                              <div className="diagonal-line-mobile-view-text">
                                ₹29998
                              </div>
                            </div>{" "}
                          </div>
                          <div className="training-card-mobile-view-disc-price">
                            ₹9940
                          </div>
                        </div>
                        <div className="mobile-view-per-session" style={{ fontWeight: "bold" }}>(+18% GST)</div>
                      </div>
                    </div>
                  </div>

                </div>
                <button style={{ height: "150px", borderRadius: "5px", padding: "10px", display: "flex", flexDirection: "column", justifyContent: "center", borderColor: "rgba(254, 119, 152, 1)", border: "2px solid rgba(254,119,152,1)", cursor: "pointer", background: "none" }} onClick={rightScroll}>
                  <BsChevronRight color="rgba(254,119,152,1)" size={20} />
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      {!changeView ? (
        <div
          className="grey-background"
          style={{ paddingLeft: "30px", paddingRight: "30px" }}
        >
          <div className="bookingbutton" style={{ width: "100%" }}>
            {/* <Link to="/popup" state={{ type: "dog-running" }}> */}
            <button
              className="booksession-mobile-view"
              onClick={() => scrollToTop()}
              style={{ width: "100%", marginTop: "0px" }}
            >
              Book FREE Walk
            </button>
            {/* </Link> */}
            <div className="limitedslots">Hurry Up, Limited slots left!</div>
          </div>
        </div>
      ) : null}

      {!changeView ? (
        <div className="pink-container">
          <div className="textbox tracking-text">
            <span>
              <span className="pinktext">Track every session on our </span>{" "}
              <span className="pet-bold-text">pet</span>
              <span className="pinktext">mojo Application</span>
            </span>
          </div>

          <div className="tracking-container" ref={sectionOneRef}>
            <div className="tracking-info">
              <div className="tracking-info-mobile-view-master-container">
                <div className="tracking-info-mobile-view-container">
                  <div>
                    <img
                      alt="tick"
                      className="tick-mobile-view-image"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div>Report card of sessions</div>
                </div>
                <div className="tracking-info-mobile-view-container">
                  <div>
                    <img
                      alt="tick"
                      className="tick-mobile-view-image"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div>Tracking progress</div>
                </div>
                <div className="tracking-info-mobile-view-container">
                  <div>
                    <img
                      alt="tick"
                      className="tick-mobile-view-image"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div>Photos from session</div>
                </div>
                <div className="tracking-info-mobile-view-container">
                  <div>
                    <img
                      alt="tick"
                      className="tick-mobile-view-image"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div>Agenda for sessions</div>
                </div>
                <div className="tracking-info-mobile-view-container">
                  <div>
                    <img
                      alt="tick"
                      className="tick-mobile-view-image"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div>Trainers feedback</div>
                </div>
                <div className="tracking-info-mobile-view-container">
                  <div>
                    <img
                      alt="tick"
                      className="tick-mobile-view-image"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div>Highlights from today</div>
                </div>
                <div className="tracking-info-mobile-view-container">
                  <div>
                    <img
                      alt="tick"
                      className="tick-mobile-view-image"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div>Photo/Video Update</div>
                </div>
              </div>
              <LazyLoad>
                <a
                  href="https://apps.apple.com/in/app/petmojo/id1609861328"
                  target="_blank" rel="nofollow"
                >
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/appstore_lmgm04.png"
                    alt="appstore-training"
                    id="section1"
                    style={{ objectFit: "contain" }}
                  ></img>
                </a>
              </LazyLoad>
              <LazyLoad>
                <a
                  href="https://play.google.com/store/apps/details?id=in.tamely.user"
                  target="_blank" rel="nofollow"
                >
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/playstore_ordt0r.png"
                    alt="playstore"
                    id="section2"
                    style={{ objectFit: "contain" }}
                  ></img>
                </a>
              </LazyLoad>
            </div>
            <LazyLoad>
              <img
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/girlwithmobile.webp"
                alt="trackingicon"
                className="tracking-icon"
              ></img>
            </LazyLoad>
          </div>

          <div
            className="bookingbutton"
            style={{ paddingLeft: "30px", paddingRight: "30px" }}
          >
            {/* <Link to="/popup" state={{ type: "dog-running" }}> */}
            <button
              className="booksession-mobile-view"
              onClick={() => scrollToTop()}
              style={{ width: "100%" }}
            >
              Book FREE Walk
            </button>
            {/* </Link> */}
            <div className="limitedslots">Hurry Up, Limited slots left!</div>
          </div>
        </div>
      ) : null}

      {/* <div className="pink-container">
        <div className="textbox2">Your Pet - Our Family!</div>
        <hr className="vector1"></hr>
        <div className="heartimg">
          <LazyLoad>
            <img
              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/desktop-heart-container.webp"
              alt="heart image"
              className="hearticon"
            ></img>
          </LazyLoad>
        </div>
      </div> */}

      {!changeView ? null : (

        <div className="walking-page-track-container">
          <div className="walking-page-track-right">
            <div className="walking-page-track-left-heading">
              <div className="walking-page-track-line1 pink">
                <h2
                  className="fifty-font-size bold-none"
                  style={{ fontFamily: "DM Sans" }}
                >
                  Track every session on our{" "}
                  <span className="dark-grey-text fifty-font-size">pet</span>
                  <span className="pink">mojo</span>{" "}
                  <span className="pink">Application</span>
                </h2>
              </div>
              {/* <hr
                className=""
                style={{ position: "relative",bottom:"0px",width:"150px" ,borderRadius:"10px",border:"3px solid rgba(254, 119, 152, 1)"}}
              ></hr> */}
              <div className="line-div relative-line">
                <hr className="live-training-pink-line"></hr>
              </div>
            </div>
            <div
              className="walking-page-track-left-content"
              style={{ fontFamily: "DM Sans" }}
            >
              <div className="track-session-point">
                <div>
                  <img
                    alt="tick"
                    className="tick-img"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                  />
                </div>
                <div>Report card of sessions</div>
              </div>
              <div className="track-session-point">
                <div>
                  <img
                    alt="tick"
                    className="tick-img"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                  />
                </div>
                <div>Tracking progress</div>
              </div>
              <div className="track-session-point">
                <div>
                  <img
                    alt="tick"
                    className="tick-img"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                  />
                </div>
                <div>Photos from session</div>
              </div>
              <div className="track-session-point">
                <div>
                  <img
                    alt="tick"
                    className="tick-img"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                  />
                </div>
                <div>Agenda for sessions</div>
              </div>
              <div className="track-session-point">
                <div>
                  <img
                    alt="tick"
                    className="tick-img"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                  />
                </div>
                <div>Trainers feedback</div>
              </div>
              <div className="track-session-point">
                <div>
                  <img
                    alt="tick"
                    className="tick-img"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                  />
                </div>
                <div>Highlights from today</div>
              </div>
              <div className="track-session-point">
                <div>
                  <img
                    alt="tick"
                    className="tick-img"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                  />
                </div>
                <div>Photo/Video Update</div>
              </div>
            </div>
            <div className="" style={{ marginTop: "40px" }}>
              {/* <Link to="/popup" state={{ type: "dog-running" }}> */}
              <button className="booksession-track" onClick={() => scrollToTop()} style={{ width: "" }}>Book FREE Walk</button>
              {/* </Link> */}
            </div>
          </div>
          <div className="" style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <div className="walking-page-track-left-buttons" style={{ height: "80%", display: "flex", alignItems: "flex-end" }}>
              <div className="icons-store-section-float-2">
                <div>
                  <a
                    target="_blank" rel="nofollow"
                    href="https://apps.apple.com/in/app/petmojo/id1609861328"
                  >
                    <img
                      alt="appStore"
                      className="walking-page-track-left-buttons-store"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/image_17_txwsw4.png"
                    />
                  </a>
                </div>
                <div>
                  <a
                    target="_blank" rel="nofollow"
                    href="https://play.google.com/store/apps/details?id=in.tamely.user"
                  >
                    <img
                      alt="playStore"
                      className="walking-page-track-left-buttons-store"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/play-store-download.png"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="walking-page-track-left" style={{ display: "flex", alignItems: "flex-end" }}>
            <div style={{ height: "100%", width: "full", display: "flex", alignItems: "flex-end" }}>
              <img
                alt="petmojoImg"
                className="walking-page-track-image"
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/girlwithmobile.webp"
                style={{ height: "524px", width: "100%", objectFit: "contain" }}
              />
            </div>
          </div>
        </div>
      )}

      {!changeView ? (
        <HeartContainer pathname={path} />
      ) : (
        <div className={classes.heartSection}>
          <div className={classes.heartSectionTextContainer}>
            <div
              className={classes.heartSectionTextHeading}
              style={{ marginBottom: "0px" }}
            >
              <h2 className="fifty-font-size bold-none">
                Your Pet, Our Family 💖
              </h2>
              <div className="line-div relative-line">
                <hr className="live-training-pink-line"></hr>
              </div>
            </div>

            <div
              className={classes.heartSectionText}
              style={{
                position: "relative",
                bottom: "25px",
                marginBottom: "0px",
                marginTop: "0px",
              }}
            >
              <div>
                <h4 className="bold-none">
                  <span className="pink">16000+</span> monthly happy pet parents
                  on our platform!
                </h4>
              </div>
            </div>

            <div
              className="bookingbutton"
              style={{ marginTop: "0px", position: "relative", bottom: "25px" }}
            >
              {/* <Link to="/popup" state={{ type: "dog-running" }}> */}
              <button className="booksession" onClick={() => scrollToTop()}>
                Book FREE Walk
              </button>
              {/* </Link> */}
              <div className="limitedslots">Hurry Up, Limited slots left!</div>
            </div>
          </div>
          <div className={classes.heartSectionImage}>
            <img
              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/desktop-heart-container.webp"
              alt="heart"
              className="hearticon"
            ></img>
          </div>
        </div>
      )}

      <div
        className={
          !changeView
            ? "container white-background"
            : "container-desktop-walking"
        }
      >
        {!changeView ? (
          <div>
            <div className="textbox2">Words From Pet Parents</div>
            <hr className="vector2"></hr>
          </div>
        ) : null}

        <div class="review-container">
          <div class={!changeView ? "review-box" : "review-box-desktop"}>
            {!changeView ? null : (
              <div className="home-page-customers-head-walking">
                <div
                  className="home-page-customer-heading"
                  style={{ marginBottom: "0px" }}
                >
                  <div>
                    <h2 className="fifty-font-size bold-none">Our Customers</h2>
                  </div>
                  <div className="line-div relative-line">
                    <hr className="live-training-pink-line"></hr>
                  </div>
                </div>

                <div
                  className="home-page-customers-contents"
                  style={{
                    marginTop: "0px",
                    position: "relative",
                    bottom: "25px",
                  }}
                >
                  <h4 className="bold-none">
                    <div>Our customers trust us for having</div>
                    <div>
                      <span className="pink">Joyful</span> and{" "}
                      <span className="pink">Positive</span> Reinforcements
                    </div>
                    <div>only, "Your Pet is Our Family!"</div>
                  </h4>
                </div>

                <div
                  className="bookingbutton"
                  style={{ position: "relative", bottom: "25px" }}
                >
                  {/* <Link to="/popup" state={{ type: "dog-running" }}> */}
                  <button className="booksession" onClick={() => scrollToTop()}>
                    Book FREE Walk
                  </button>
                  {/* </Link> */}
                  <div className="limitedslots">
                    Hurry Up, Limited slots left!
                  </div>
                </div>
              </div>
            )}

            <div
              className={!changeView ? "review-box" : "review-box-desktop-3"}
            >
              <div
                className={
                  !changeView
                    ? "testimonial-card-mastercontainer-mobile-view"
                    : "testimonial-card-mastercontainer-2"
                }
              >
                <div className="testimonial-card-upper-container">
                  <div className="testimonial-card-quote-image">
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/quote-icon.png"
                      alt="quoteIcon"
                      className="quote-icon"
                    ></img>
                  </div>
                  <div className="testimonial-card-review-content">
                    {testmonialData.message}
                  </div>
                  <hr className="grey-line"></hr>
                </div>
                <div className="testimonial-card-lower-container">
                  <div className="testimonial-card-reviewer-image">
                    <img
                      alt="rudrangImage"
                      className="testimonial-user-image"
                      src={testmonialData.img_src}
                    />
                  </div>
                  <div className="testimonial-card-reviewer-name">
                    {testmonialData.name}
                  </div>
                  <div className="testimonial-card-reviewer-profession">
                    {testmonialData.profession}
                  </div>
                  <div className="testimonial-card-review-star-box">
                    <div>
                      <img
                        alt="starIcon"
                        className="star-icon-desktop-image"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                      />
                    </div>
                    <div>
                      <img
                        alt="starIcon"
                        className="star-icon-desktop-image"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                      />
                    </div>
                    <div>
                      <img
                        alt="starIcon"
                        className="star-icon-desktop-image"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                      />
                    </div>
                    <div>
                      <img
                        alt="starIcon"
                        className="star-icon-desktop-image"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                      />
                    </div>
                    <div>
                      <img
                        alt="starIcon"
                        className="star-icon-desktop-image"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {!changeView ? (
        <div
          className="bookingbutton"
          style={{ paddingLeft: "30px", paddingRight: "30px" }}
        >
          {/* <Link to="/popup" state={{ type: "dog-running" }}> */}
          <button
            className="booksession-mobile-view2"
            onClick={() => scrollToTop()}
            style={{ width: "100%" }}
          >
            Book FREE Walk
          </button>
          {/* </Link> */}
          <div className="limitedslots">Hurry Up, Limited slots left!</div>
        </div>
      ) : null}

      {!changeView ? null : (
        <div>
          <div className="container-home-desktop">
            <div className="get-started-walking-head">
              <div
                className={classes.howToStart}
                style={{ marginBottom: "0px" }}
              >
                <div className="">
                  <h2 className="fifty-font-size bold-none">
                    Let’s Get You Started with Our Fitness Training
                  </h2>
                </div>
                <div className="line-div relative-line">
                  <hr className="live-training-pink-line"></hr>
                </div>
              </div>

              <div
                className="get-started-walking-content"
                style={{
                  marginTop: "0px",
                  position: "relative",
                  bottom: "25px",
                }}
              >
                <h4 className="bold-none">
                  <div>It's as easy as 1-2-3, Follow the steps</div>
                  <div>given and you are good to proceed!</div>
                </h4>
              </div>

              <div
                className="bookingbutton"
                style={{ position: "relative", bottom: "25px" }}
              >
                {/* <Link to="/popup" state={{ type: "dog-running" }}> */}
                <button className="booksession" onClick={() => scrollToTop()}>
                  Book FREE Walk
                </button>
                {/* </Link> */}
                <div className="limitedslots">
                  Hurry Up, Limited slots left!
                </div>
              </div>
            </div>
            <div className="timeline-component-container">
              <TimelineComponent
                version={"desktop"}
                timelineData={timelineData}
              />
            </div>
          </div>
        </div>
      )}

      {/* <div className="textbox2">Your Dog Deserves A Great Run Daily!</div>
      <hr className="vector2"></hr> */}
      {!changeView ? (
        <div className="container-home2 grey-background">
          <div className="textbox2">
            Let’s Get You Started with Our Fitness Training
          </div>
          <hr className="vector1"></hr>
          <div>
            <TimelineComponent version={"mobile"} timelineData={timelineData} />
          </div>
          <div
            className="bookingbutton"
            style={{ paddingLeft: "30px", paddingRight: "30px" }}
          >
            {/* <Link to="/popup" state={{ type: "dog-running" }}> */}
            <button
              className="booksession-mobile-view2"
              onClick={() => scrollToTop()}
              style={{ width: "100%" }}
            >
              Book FREE Walk
            </button>
            {/* </Link> */}
            <div className="limitedslots">Hurry Up, Limited slots left!</div>
          </div>
        </div>
      ) : null}

      {!changeView ? (
        <div className="walking-page-mobile-view-daily">
          <div className="walking-page-mobile-view-daily-section-1">
            Your Dog Deserves a Great Run Daily!
          </div>
          <hr className="vector2"></hr>
          <div className="walking-page-mobile-view-daily-section-2">
            Improve your dog’s quality of life x2 by booking PetMojo trusted and
            passionate dog runners.
          </div>
          <div
            className="walking-page-mobile-view-daily-section-3"
            style={{ width: "100%" }}
          >
            <div
              className="bookingbutton"
              style={{
                width: "100%",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              {/* <Link to="/popup" state={{ type: "dog-running" }}> */}
              <button
                className="booksession-mobile-view2"
                onClick={() => scrollToTop()}
                style={{ width: "100%" }}
              >
                Book FREE Walk
              </button>
              {/* </Link> */}
              <div className="limitedslots">Hurry Up, Limited slots left!</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="walking-page-trainer-meet">
          <div className="walking-page-trainer-meet-container">
            {/* <div className="walking-page-trainer-meet-left">
              <div>
                <h2 className="fifty-font-size bold-none">
                  Your Dog Deserves A Great Run Daily!
                </h2>
              </div>
              <div className="line-div relative-line">
                <hr className="live-training-pink-line"></hr>
              </div>
            </div> */}
            <div className="walking-page-trainer-meet-right-up">
              <div className="walking-page-desktop-view-trainer-meet-subtext">
                <h3
                  className="bold-none"
                  style={{
                    fontFamily: "DM Sans",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "25px",
                    lineHeight: "50px",
                    /* or 200% */

                    textAlign: "center",

                    color: "#FFFFFF",
                  }}
                >
                  Improve your dog's quality of life x2 by booking Petmojo
                  trusted and passionate dog runners.
                </h3>
              </div>
            </div>

            <div className="walking-page-trainer-meet-right">
              {/* <div className="walking-page-trainer-meet-right-up">
                <div className="walking-page-desktop-view-trainer-meet-subtext">
                  <h3 className="bold-none">
                    Improve your dog's quality of life x2 by booking Petmojo
                    trusted and passionate dog runners.
                  </h3>
                </div>
              </div> */}
              <div className="walking-page-trainer-meet-left">
                <div>
                  <h2 className="fifty-font-size bold-none">
                    Your Dog Deserves A Great Run Daily!
                  </h2>
                </div>
                <div
                  className="line-div relative-line "
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <div>
                    <hr className="live-training-pink-line"></hr>
                  </div>
                </div>
              </div>
              <div
                className="bookingbutton"
                style={{
                  marginTop: "40px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  position: "relative",
                  bottom: "20px",
                }}
              >
                {/* <Link to="/popup" state={{ type: "dog-running" }}> */}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    className="booksession"
                    onClick={() => scrollToTop()}
                    style={{ width: "40%" }}
                  >
                    Book FREE Walk
                  </button>
                </div>
                {/* </Link> */}
                <div
                  className="limitedslots"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  Hurry Up, Limited slots left!
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {!changeView ? (
        <div className="training-page-mobile-view-why-container">
          <div className="services-container2">
            <div className="service-box">
              <div
                className="textbox2"
                style={{ color: "#5D5D5D", fontWeight: 700 }}
              >
                Why Choose Petmojo?
              </div>
              <hr className="vector1"></hr>
              <div className="service-1">
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/trust-icon.png"
                    alt="trust icon"
                    className="trust-icon"
                  ></img>
                </LazyLoad>
                <div
                  className="service-info"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  Trusted & Caring Walkers
                </div>
              </div>
              <div className="service-2">
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/door-icon.png"
                    alt="door icon"
                    className="door-icon"
                  ></img>
                </LazyLoad>
                <div
                  className="service-info"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  Service at Your Door-Step{" "}
                </div>
              </div>
              <div className="service-3">
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/refund-icon.png"
                    alt="refund icon"
                    className="refund-icon"
                  ></img>
                </LazyLoad>
                <div
                  className="service-info"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  Guaranteed Satisfaction{" "}
                </div>
              </div>
              <div className="service-4">
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/team-icon.png"
                    alt="team icon"
                    className="team-icon"
                  ></img>
                </LazyLoad>
                <div
                  className="service-info"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  Local Onground Support
                </div>
              </div>
              {/*     
                      <div className="grey-background">
                  <div className="bookingbutton">
                    <Link to="/popup" state={{ type: "dog-running" }}>
                      <button className="booksession-mobile-view2">Book 1st FREE Walk</button>
                    </Link>
                  </div>
                </div> */}
            </div>
          </div>
        </div>
      ) : null}

      {!changeView ? null : (
        <div className="home-page-why-choose-container">
          <div className="home-page-why-choose-container4">
            <div
              className="home-page-why-choose-container1-heading-seo"
              style={{ marginBottom: "0px" }}
            >
              <div>
                <h3 className="non-bold">Why Choose Petmojo?</h3>
              </div>
              <div className="line-div relative-line" style={{}}>
                <hr className="live-training-pink-line"></hr>
              </div>
            </div>

            <div
              className="home-page-why-choose-container1-content"
              style={{ marginTop: "0px", position: "relative", bottom: "10px" }}
            >
              <div>
                <h4>We are worlds most empathetic Pet Care Services.</h4>
              </div>
            </div>
          </div>

          <div className="home-page-why-choose-container2">
            <div className="service-1">
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/trust-icon.png"
                  alt="trust icon"
                  className="trust-icon"
                ></img>
              </LazyLoad>
              <div
                className="service-info"
                style={{ color: "white", fontWeight: 700 }}
              >
                Trusted & Caring Walkers
              </div>
            </div>
            <div className="service-2">
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/door-icon.png"
                  alt="door icon"
                  className="door-icon"
                ></img>
              </LazyLoad>
              <div
                className="service-info"
                style={{ color: "white", fontWeight: 700 }}
              >
                Service at Your Door-Step{" "}
              </div>
            </div>
            <div className="service-3">
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/refund-icon.png"
                  alt="refund icon"
                  className="refund-icon"
                ></img>
              </LazyLoad>
              <div
                className="service-info"
                style={{ color: "white", fontWeight: 700 }}
              >
                Guaranteed Satisfaction{" "}
              </div>
            </div>
            <div className="service-4">
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/team-icon.png"
                  alt="team icon"
                  className="team-icon"
                ></img>
              </LazyLoad>
              <div
                className="service-info"
                style={{ color: "white", fontWeight: 700 }}
              >
                Local Onground Support
              </div>
            </div>
          </div>

          <div className="home-page-why-choose-container3">
            <img
              className="home-page-why-choose-container3-img"
              alt="heroImg"
              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/desktop-bg-dog.webp"
            />
          </div>
        </div>
      )}

      {!changeView ? (
        <div className="pink-container-family" style={{ padding: "10px" }}>
          <div className="textbox2">We are Family of Pet Lovers</div>
          <hr className="vector2"></hr>

          <div className="textbox3">
            Fully Trained and Certified Trainers/Groomers.
            <br /> <span className="pinktext">Top 1% </span>
            <span className="grey-text">
              of all Trainers/Groomers in India. Rigorous quality and audit
              checks
            </span>
          </div>

          <div
            className="trainer-container"
            style={{ border: "1px solid red" }}
          >
            <div className="trainer-cards-mobile ">
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/rectangle-box.png"
                  alt="trainercardborder"
                  className="trainer-card-border-mobile"
                ></img>
              </LazyLoad>
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/trainee-testimonials-mobile-1.webp"
                  alt="trainercard1"
                  className="trainer-card-mobile"
                ></img>
              </LazyLoad>
            </div>
            <div className="trainer-cards-mobile">
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/rectangle-box.png"
                  alt="trainercardborder"
                  className="trainer-card-border-mobile"
                ></img>
              </LazyLoad>
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/trainee-testimonials-mobile-2.webp"
                  alt="trainercard2"
                  className="trainer-card-mobile"
                ></img>
              </LazyLoad>
            </div>
            <div className="trainer-cards-mobile">
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/rectangle-box.png"
                  alt="trainercardborder"
                  className="trainer-card-border-mobile"
                ></img>
              </LazyLoad>
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/trainee-testimonials-mobile-3.webp"
                  alt="trainercard2"
                  className="trainer-card-mobile"
                ></img>
              </LazyLoad>
            </div>
          </div>
          <div
            className="bookingbutton"
            style={{ paddingLeft: "30px", paddingRight: "30px" }}
          >
            {/* <Link to="/popup" state={{ type: "dog-running" }}> */}
            <button
              className="booksession-mobile-view"
              onClick={() => scrollToTop()}
              style={{ width: "100%" }}
            >
              Book FREE Walk
            </button>
            {/* </Link> */}
            <div className="limitedslots">Hurry Up, Limited slots left!</div>
          </div>
        </div>
      ) : (
        <div className="pink-container-desktop">
          <div className="pink-container-left">
            <div className="pink-container-left-box1">
              <div className="textbox2-desktop">
                <h2 className="bold-none fifty-font-size">
                  We are Family of Pet Lovers
                </h2>
              </div>
              <div className="line-div2 relative-line">
                <hr className="live-training-pink-line"></hr>
              </div>
            </div>

            <div className="pink-container-left-box2">
              <h4 className="bold-none">
                <div>Fully Trained and Certified</div>
                <div>
                  Trainers. <span className="pink-bold">Top 1%</span> of all
                  trainers in
                </div>
                <div>India. Rigorous quality and audit</div>
                <div>checks.</div>
              </h4>
            </div>

            <div className="pink-container-left-box3">
              <div className="bookingbutton">
                {/* <Link to="/popup" state={{ type: "dog-running" }}> */}
                <button className="booksession" onClick={() => scrollToTop()}>
                  Book FREE Walk
                </button>
                {/* </Link> */}
                <div className="limitedslots">
                  Hurry Up, Limited slots left!
                </div>
              </div>
            </div>
          </div>

          <div className="pink-container-right">
            <div className="pink-container-right-head">
              <h3 className="bold-none">Some of Our Top Trainers</h3>
            </div>
            <div className="trainer-container">
              <div className="trainer-cards">
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/rectangle-box.png"
                    alt="trainercardborder"
                    className="trainer-card-border-desktop"
                  ></img>
                </LazyLoad>
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8926_criqhc.png"
                    alt="trainercard1"
                    className="trainer-card-desktop"
                  ></img>
                </LazyLoad>
              </div>
              <div className="trainer-cards">
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/rectangle-box.png"
                    alt="trainercardborder"
                    className="trainer-card-border-desktop"
                  ></img>
                </LazyLoad>
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8928_rtscxb.png"
                    alt="trainercard2"
                    className="trainer-card-desktop"
                  ></img>
                </LazyLoad>
              </div>

              <div className="trainer-cards">
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/rectangle-box.png"
                    alt="trainercardborder"
                    className="trainer-card-border-desktop"
                  ></img>
                </LazyLoad>
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8930_nsgnok.png"
                    alt="trainercard3"
                    className="trainer-card-desktop"
                  ></img>
                </LazyLoad>
              </div>
            </div>
          </div>
        </div>
      )}

      {!changeView ? (
        <FAQContainerWalking view={"mobile"} />
      ) : (
        <FAQContainerWalking view={"desktop"} />
      )}

      {!changeView ? (
        <div className="pink-container-family">
          <div className="textbox2">We’ll Make Your Pet Smile</div>
          <hr className="vector2"></hr>

          <div className="heroImage1Container">
            <div className="heroImage1">
              <img
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/homepage-petmojo-partner-mobile-outline.webp"
                alt="makepetsmile"
                className="make-pet-smile"
              ></img>
              <div className="petmojo-partner-mobile-view-name">
                Petmojo Partner -{" "}
                <span className="petmojo-partner-mobile-view-name-cursive">
                  Kartik Rai
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {!changeView ? null : (
        <div className="hero-image-container-desktop">
          <div className="hero-image-container-desktop-left">
            <div className="pet-smile-home-set1">
              <div>
                <h5 className="fifty-font-size bold-none" style={{ marginBottom: "2rem" }}>
                  We'll Make Your Pet Smile
                </h5>
              </div>
              <div className="line-div relative-line">
                <hr className="live-training-pink-line"></hr>
              </div>
            </div>

            <div
              className="pet-smile-home-set2"
              style={{ position: "relative", bottom: "20px" }}
            >
              <h4 className="bold-none">Your Pet Our Family!</h4>
            </div>
          </div>

          <div className="hero-image-container-desktop-right">
            <img
              className="pet-smile-img"
              alt="heroImg"
              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/desktop-petmojo-partner.webp"
            />
            <div className="petmojo-partner-name-desktop">
              Petmojo Partner -{" "}
              <span className="petmojo-partner-name-desktop-cursive">
                Kartik Rai
              </span>
            </div>
          </div>
        </div>
      )}

      {!changeView ? null : (
        <div className="storeContainer">
          <div className="storeContainer_left">
            <h2 className="storeContainer_heading" >
              Get Petmojo Application
            </h2>
            <hr className="live-training-pink-line"></hr>

            <p className="storeContainer_text">
              Download our mobile application to get exclusive offers and discounts on Pet Grooming
            </p>
            <div className="pink-container-left-box3">
              <div className="bookingbutton">
                {/* <Link to="/popup" state={{ type: "dog-running" }}> */}
                <button className="booksession" onClick={() => scrollToTop()}>
                  Book Now!
                </button>
                {/* </Link> */}
                <div className="limitedslots">
                  Hurry Up, Limited slots left!
                </div>
              </div>
            </div>
          </div>

          <div className="storeContainer_right">
            <div className="storeContainer_logo_container">
              <img
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/petmojo-dark-logo-.png"
                alt=""
                style={{ objectFit: "contain" }}
              />
            </div>
            <div className="storeContainer_main_image">
              <img
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/get-petmojo-application.webp"
                alt=""
              />
            </div>

            <div className="storeContainer_button_container">
              <a
                target="_blank" rel="nofollow"
                href="https://play.google.com/store/apps/details?id=in.tamely.user"
              >
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/play-store-download.png"
                  alt=""
                />
              </a>
              <a
                target="_blank" rel="nofollow"
                href="https://apps.apple.com/in/app/petmojo/id1609861328"
              >
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/app-store-download.png"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      )}

      <div className="giveSpace">
        {!changeView ? (
          <div className="pink-container-family">
            <div className="textbox2">Download Petmojo App</div>
            <hr className="vector2"></hr>
          </div>
        ) : null}

        {
          !changeView ? (
            <div className="iphone-container">
              <div className="storeContainer_logo_container_mobile">
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/petmojo-dark-logo-.png"
                  alt=""
                  style={{ objectFit: "contain" }}
                />
              </div>
              {/* <LazyLoad> */}
              <div>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/get-petmojo-application-mobile.webp"
                  alt="iphoneicon"
                  className="iphone-icon"
                ></img>
              </div>

              {/* </LazyLoad> */}
              <div className="download-btn-container" ref={sectionTwoRef}>
                <div className="playstore-icon">
                  <a
                    target="_blank" rel="nofollow"
                    href="https://play.google.com/store/apps/details?id=in.tamely.user"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/playstore_ordt0r.png"
                      alt="playstore"
                      id="section4"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </div>
                <div className="appstore-icon">
                  <a
                    target="_blank" rel="nofollow"
                    href="https://apps.apple.com/in/app/petmojo/id1609861328"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/appstore_lmgm04.png"
                      alt="appstore"
                      id="section3"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </div>
              </div>
            </div>
          ) : null

        }
      </div>

      <div style={{ margin: "2rem 0rem" }}>
        {!changeView ? (
          <div>
            <div className="textbox2">Social Media Links</div>
            <hr className="vector2"></hr>

            <div className="ellipse-container">
              <div className="ellipse">
                {/* <LazyLoad> */}
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/outer-ring.png"
                  alt="outerring"
                  className="outer-ring"
                ></img>
                {/* </LazyLoad> */}
                {/* <LazyLoad> */}
                <img
                  src={
                    "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/inner-circle.png"
                  }
                  alt="innerring"
                  className="inner-ring"
                ></img>
                {/* </LazyLoad> */}
                <button className="youtube-icon">
                  <a
                    href="https://youtube.com/channel/UCPQrCllizVvTOkSk7_1SIHw"
                    target="_blank"
                    rel="nofollow"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/yt-icon.png"
                      alt="youtubeicon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="facebook-icon">
                  <a
                    href="https://www.facebook.com/petmojo.care/"
                    target="_blank"
                    rel="nofollow"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/fb-icoc.png"
                      alt="facebookicon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="twitter-icon">
                  <a href="https://twitter.com/Petmojo_App" target="_blank" rel="nofollow">
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/twitter-icon.png"
                      alt="twittericon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="instagram-icon">
                  <a
                    href="https://www.instagram.com/petmojo.care/"
                    target="_blank"
                    rel="nofollow"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/insta-icon.png"
                      alt="instagramicon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="phone-icon">
                  <a href="tel:+91 8889991800" target="_blank" rel="nofollow">
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/phone-icon.png"
                      alt="phoneicon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="gmail-icon">
                  <a
                    href="mailto: customercare@petmojo.in"
                    target="_blank"
                    rel="nofollow"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/gm-icon.png"
                      alt="gmailicon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="whatsapp-icon">
                  <a href="tel:+91 8889991800" target="_blank" rel="nofollow">
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/whatsapp-icon.png"
                      alt="whatsappicon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>

      {!changeView ? null : (
        <div className="social-media-links-container">
          <div className="social-media-links-header">
            <div className="social-media-links-text">
              Connect With Us
              <div className="line-div">
                <hr className="live-training-pink-line"></hr>
              </div>
            </div>

            <div className="home-page-walking-packages-desktop-container2">
              <div>Connect on applications you use</div>
              <div>daily to get Informative/Educational </div>
              <div>contents on Petmojo.</div>
            </div>
          </div>

          <div className="social-media-links-image">
            <div className="ellipse-container">
              <div className="ellipse-desktop">
                {/* <LazyLoad> */}
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Ellipse_374_ijrumy.png"
                  alt="outerring"
                  className="outer-ring-desktop"
                ></img>
                {/* </LazyLoad> */}
                {/* <LazyLoad> */}
                <img
                  src={
                    "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Ellipse_373_iadrer.png"
                  }
                  alt="innerring"
                  className="inner-ring-desktop"
                ></img>
                {/* </LazyLoad> */}
                <button className="youtube-icon-desktop">
                  <a
                    href="https://youtube.com/channel/UCPQrCllizVvTOkSk7_1SIHw"
                    target="_blank"
                    rel="nofollow"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/yt-icon.png"
                      alt="youtubeicon"
                      width="60px"
                      height="60px"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="facebook-icon-desktop">
                  <a
                    href="https://www.facebook.com/petmojo.care/"
                    target="_blank"
                    rel="nofollow"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/fb-desktop.png"
                      alt="facebookicon"
                      width="60px"
                      height="60px"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>

                <button className="instagram-icon-desktop">
                  <a
                    href="https://www.instagram.com/petmojo.care/"
                    target="_blank"
                    rel="nofollow"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/insta-desktop.png"
                      alt="instagramicon"
                      width="60px"
                      height="60px"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="phone-icon-desktop">
                  <a href="tel:+91 8889991800" target="_blank" rel="nofollow">
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/phone-blue-desktop.png"
                      alt="phoneicon"
                      width="60px"
                      height="60px"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="gmail-icon-desktop">
                  <a
                    href="mailto: customercare@petmojo.in"
                    target="_blank"
                    rel="nofollow"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/gmail-desktop.png"
                      alt="gmailicon"
                      width="60px"
                      height="60px"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="whatsapp-icon-desktop">
                  <a href="tel:+91 8889991800" target="_blank" rel="nofollow">
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/whatsapp-desktop.png"
                      alt="whatsappicon"
                      width="60px"
                      height="60px"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {!changeView ? null : (
        <div className="footerDetailsContainer">
          <div className="footerDetailsCont1">
            <div className="footerDetailsHeading">Need Help</div>
            <hr className="footerDetailsLine"></hr>
            <div className="footerDetailsContentContainer">
              <div className="footer-details-content-call-mail">
                <div>
                  <img
                    alt="icon"
                    className="icon-desktop-call"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/more-contact-icon.png"
                  />
                </div>
                <div>
                  <a
                    className="footerDetailsLink"
                    target="_blank" rel="nofollow"
                    href="tel: 8889991800"
                  >
                    8889991800
                  </a>
                </div>
              </div>

              <div className="footer-details-content-call-mail">
                <div>
                  <img
                    alt="icon"
                    className="icon-desktop-mail"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-mail-icon.png"
                  />
                </div>
                <div>
                  <a
                    className="footerDetailsLink"
                    target="_blank" rel="nofollow"
                    href="mailto: customercare@petmojo.in"
                  >
                    customercare@petmojo.in
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="footerDetailsCont1">
            <div className="footerDetailsHeading">Services</div>
            <hr className="footerDetailsLine"></hr>
            <div className="footerDetailsContentContainer">
              <Link
                className="footerDetailsLink"
                to="/dog-running"
                state={{ from: "walking" }}
              >
                <div>Daily Dog Exercise/Walking</div>
              </Link>
              <Link
                className="footerDetailsLink"
                to="/dog-training"
                state={{ from: "walking" }}
              >
                <div>Dog Training</div>
              </Link>
              <Link
                className="footerDetailsLink"
                to="/cat-training"
                state={{ from: "training" }}
              >
                <div>Cat Training</div>
              </Link>
              <Link
                className="footerDetailsLink"
                to="/grooming"
                state={{ from: "walking" }}
              >
                <div>Pet Grooming</div>
              </Link>
              <Link
                className="footerDetailsLink"
                to="/grooming-subscription"
                state={{ from: "footer" }}
              >
                <div>Grooming Subscription</div>
              </Link>
              <Link
                className="footerDetailsLink"
                to="/pet-boarding"
                state={{ from: "walking" }}
              >
                <div>Pet Hotel</div>
              </Link>
              {/* <Link
                className="footerDetailsLink"
                to="/styling"
                state={{ from: "walking" }}
              >
                <div>Pet Styling</div>
              </Link> */}
            </div>
          </div>
          <div className="footerDetailsContr1">
            <div className="footerDetailsHeading">Serving In</div>
            <hr className="footerDetailsLine"></hr>
            <div className="footerDetailsContentContainer">
              <div className="footerDetailsLink">Delhi</div>
              <div className="footerDetailsLink">Gurgaon</div>
              <div className="footerDetailsLink">Noida</div>
              <div className="footerDetailsLink">Ghaziabad</div>
              <div className="footerDetailsLink">Faridabad</div>
              <div className="footerDetailsLink">Manesar</div>
              <div className="footerDetailsLink">Mumbai</div>
            </div>
          </div>
          <div className="footerDetailsCont1">
            <div className="footerDetailsHeading">More</div>
            <hr className="footerDetailsLine"></hr>
            <div className="footerDetailsContentContainer">
              <Link className="footerDetailsLink" to="/partner">
                <div>Become a Partner</div>
              </Link>
              <Link className="footerDetailsLink" to="/terms">
                <div>Terms and Conditions</div>
              </Link>
              <Link className="footerDetailsLink" to="/privacy">
                <div>Privacy Policy</div>
              </Link>
              <Link className="footerDetailsLink" to="/">
                <div>Privacy Policy - Partner</div>
              </Link>
              <Link className="footerDetailsLink" to="/customer-support-policy">
                <div>Customer Support Policy</div>
              </Link>
              <Link className="footerDetailsLink" to="/about">
                <div>About Us</div>
              </Link>
            </div>
          </div>
        </div>
      )}

      {!changeView ? (
        <div>
          <div className="mobile-view-footer-1">
            <div className="mobile-view-footer-1-left">
              <div className="mobile-view-footer-1-left-header-container">
                <div className="mobile-view-footer-1-header">Need Help</div>
                <hr className="vector2-footer"></hr>
              </div>
              <div className="mobile-view-footer-1-content-left">
                <div className="mobile-view-footer-phone-container">
                  <div className="mobile-view-footer-phone-icon">
                    <img
                      alt="telIcon"
                      className="mobile-view-tel-icon"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-phone-icon.png"
                    />
                  </div>
                  <div className="">
                    <a
                      className="mobile-view-footer-phone-text"
                      style={{ textDecoration: "none" }}
                      href="tel: +91 8889991800"
                      target="_blank" rel="nofollow"
                    >
                      8889991800
                    </a>
                  </div>
                </div>
                <div className="mobile-view-footer-mail-container">
                  <div className="mobile-view-footer-mail-icon">
                    <img
                      alt="mailIcon"
                      className="mobile-view-mail-icon"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-mail-icon.png"
                    />
                  </div>
                  <div className="">
                    <a
                      className="mobile-view-footer-mail-text"
                      style={{ textDecoration: "none" }}
                      href="mailto: customercare@petmojo.in"
                      target="_blank" rel="nofollow"
                    >
                      customercare@petmojo.in
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="mobile-view-footer-1-right">
              <div className="mobile-view-footer-1-header">Services</div>
              <hr className="vector2-footer"></hr>
              <div className="mobile-view-footer-1-right-content">
                <Link
                  className="mobile-view-footer-page-link"
                  style={{ textDecoration: "none" }}
                  to="/dog-running"
                  state={{ from: "walking" }}
                >
                  Dog Exercise/Walking
                </Link>
              </div>
              <div className="mobile-view-footer-1-right-content">
                <Link
                  className="mobile-view-footer-page-link"
                  style={{ textDecoration: "none" }}
                  to="/dog-training"
                  state={{ from: "walking" }}
                >
                  Dog Training
                </Link>
              </div>
              <div className="mobile-view-footer-1-right-content">
                <Link
                  className="mobile-view-footer-page-link"
                  style={{ textDecoration: "none" }}
                  to="/grooming"
                  state={{ from: "walking" }}
                >
                  Pet Grooming
                </Link>
              </div>
              <div className="mobile-view-footer-1-right-content">
                <Link
                  className="mobile-view-footer-page-link"
                  style={{ textDecoration: "none" }}
                  to="/grooming-subscription"
                  state={{ from: "training" }}
                >
                  Grooming Subscription
                </Link>
              </div>
              <div className="mobile-view-footer-1-right-content">
                <Link
                  className="mobile-view-footer-page-link"
                  style={{ textDecoration: "none" }}
                  to="/pet-boarding"
                  state={{ from: "walking" }}
                >
                  Pet Hotel
                </Link>
              </div>
              {/* <div className="mobile-view-footer-1-right-content">
                <Link
                  className="mobile-view-footer-page-link"
                  style={{ textDecoration: "none" }}
                  to="/styling"
                  state={{ from: "walking" }}
                >
                  Pet Styling
                </Link>
              </div> */}
            </div>
          </div>

          <div className="mobile-view-footer-2">
            <div className="mobile-view-footer-2-left">
              <div className="footerDetailsHeading-mobile">Serving In</div>
              <hr className="footerDetailsLine"></hr>
              <div className="footerDetailsContentContainer-mobile">
                <div>
                  <div className="footerDetailsLink-mobile">Delhi</div>
                  <div className="footerDetailsLink-mobile">Gurgaon</div>
                  <div className="footerDetailsLink-mobile">Noida</div>
                  <div className="footerDetailsLink-mobile">Ghaziabad</div>
                </div>
                <div>
                  <div className="footerDetailsLink-mobile">Faridabad</div>
                  <div className="footerDetailsLink-mobile">Manesar</div>
                  <div className="footerDetailsLink-mobile">Mumbai</div>
                </div>
              </div>
            </div>
            <div className="mobile-view-footer-2-right">
              <div className="mobile-view-footer-2-right-header">More</div>
              <hr className="vector2-footer"></hr>
              <div className="mobile-view-links-div">
                <Link
                  style={{
                    textDecoration: "none",
                    color: "rgba(132, 132, 132, 1)",
                  }}
                  to="/terms"
                >
                  <div className="mobile-view-footer-2-tnc">
                    Terms and Conditions
                  </div>
                </Link>
                <Link
                  style={{
                    textDecoration: "none",
                    color: "rgba(132, 132, 132, 1)",
                  }}
                  to="/privacy"
                >
                  <div className="mobile-view-footer-2-tnc">Privacy Policy</div>
                </Link>
                <Link
                  style={{
                    textDecoration: "none",
                    color: "rgba(132, 132, 132, 1)",
                  }}
                  to="/partner"
                >
                  <div className="mobile-view-footer-2-left-content">
                    Become a Partner
                  </div>
                </Link>{" "}
                <div className="mobile-view-footer-2-left-content">
                  Privacy Policy - Partner
                </div>
                <Link
                  style={{
                    textDecoration: "none",
                    color: "rgba(132, 132, 132, 1)",
                  }}
                  to="/customer-support-policy"
                >
                  <div className="mobile-view-footer-2-left-content">
                    Customer Support Policy
                  </div>
                </Link>
                <Link
                  style={{
                    textDecoration: "none",
                    color: "rgba(132, 132, 132, 1)",
                  }}
                  to="/about"
                >
                  <div className="mobile-view-footer-2-left-content">
                    About Us
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div className="mobile-footer-wrap" ref={sectionThreeRef}>
            <div className="mobile-view-footer-3" style={{ gap: "5px" }}>
              <div
                className="mobile-view-footer-3-btn1"
                style={{ height: "33px" }}
              >
                <a
                  href="https://apps.apple.com/in/app/petmojo/id1609861328"
                  target="_blank" rel="nofollow"
                >
                  <img
                    className="mobile-view-footer-appStore-icon "
                    id="section5"
                    alt="appStore"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/app-store-2.png"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                </a>
              </div>
              <div
                className="mobile-view-footer-3-btn2"
                style={{ height: "33px" }}
              >
                <a
                  href="https://play.google.com/store/apps/details?id=in.tamely.user"
                  target="_blank" rel="nofollow"
                >
                  <img
                    className="mobile-view-footer-appStore-icon"
                    id="section6"
                    alt="playStore"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/play-store-2.png"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                </a>
              </div>
              <div
                onClick={() => goToTopHandler()}
                className="mobile-view-footer-3-btn3"
                style={{
                  height: "33px",
                  padding: "5px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <span>Go to the top</span>
                <BsArrowUp />
                {/* <div>Go to the top</div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-up"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                    />
                  </svg>
                </div> */}
              </div>
            </div>
          </div>

          <div className="mobile-view-footer-4">
            <div>Copyright 2024 Petmojo</div>
            <div>
              All copyrights are reserved - any infringement will be legally
              pursued.
            </div>
            <div>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </div>
          </div>
        </div>
      ) : null}

      {!changeView ? null : (
        <div>
          <div className="desktop-footer-buttons-container-add" style={{ display: "flex", flexDirection: "column" }}>
            <p style={{ fontWeight: "800" }}>MANGOPAW TECHNOLOGIES AND SOLUTIONS PRIVATE LIMITED</p>
            <p style={{ marginTop: "0rem" }}> Ph no- +918889991800</p>
          </div>
          <div className="desktop-footer-buttons-container">
            <div className="desktop-footer-buttons-left">
              <div className="desktop-footer-buttons-left-btn1">
                <a
                  href="https://play.google.com/store/apps/details?id=in.tamely.user"
                  target="_blank" rel="nofollow"
                >
                  <img
                    alt="icon"
                    className="desktop-footer-buttons-left-btn1-img"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/play-store-download.png"
                  />
                </a>
              </div>
              <div className="desktop-footer-buttons-left-btn1">
                <a
                  href="https://apps.apple.com/in/app/petmojo/id1609861328"
                  target="_blank" rel="nofollow"
                >
                  <img
                    alt="icon"
                    className="desktop-footer-buttons-left-btn1-img"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/app-store-download.png"
                  />
                </a>
              </div>
              <div
                onClick={scrollToTop}
                className="desktop-footer-buttons-left-btn3"
              >
                <div>Go at the top</div>

                <svg
                  className="top-arrow"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-arrow-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                  />
                </svg>
              </div>
            </div>

            <div className="desktop-footer-buttons-right">
              Copyright 2024 Petmojo
            </div>
          </div>
        </div>
      )}

      <GoToTop />
    </div>
  );
}

export default Walking;
