import { BsArrowUp } from "@react-icons/all-files/bs/BsArrowUp";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from 'react-helmet-async';
import LazyLoad from "react-lazyload";
import { Link } from "react-router-dom";
import FAQContainerBoarding from "../components/container/faqContainer/FAQContainerBoarding";
import HeartContainer from "../components/container/heartContainer/HeartContainer";
import { default as classes, default as styles } from '../pages/Landing/LandingPage.module.css';
import IntroContainerDesktopBoarding from "./container/introContainer/IntroContainerDesktopBoarding";
import IntroContainerMobileBoarding from './container/introContainer/IntroContainerMobileBoarding';
import TimelineComponentBoarding from "./TimelineComponentBoarding";
import "./Walking.css";

const testimonialArray = [
  {
    message: "I was nervous to leave Leo with strangers, but my worries quickly faded. The best thing is they provide regular updates so I was worry free throughout my trip.",
    name: "Kriti Raj",
    img_src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/testimonial-phone-kriti.webp",
    profession: "Assistant Manager"
  },
  {
    message: "My sitter took great care of my dog. It was above and beyond my expectations. I would book with them again in a heartbeat! ",
    name: "Mukesh Kumar",
    img_src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/testimonial-phone-mukesh.webp",
    profession: "Social Worker"
  },
  {
    message: "Petmojo has been a great partner in my new pet parenting journey. I worry less knowing my dog is getting trained and walks everyday.",
    name: "Nidhi K",
    img_src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/testimonial-phone-nidhi.webp",
    profession: "Shop Owner"
  },
  {
    message: "We couldn't be happier with the care we receive. We would recommend their service to anyone looking to leave their pet for a while.",
    name: "Rachit R",
    img_src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/testimonial-phone-rachit.webp",
    profession: "Business Owner"
  },
];


const Boarding = (props) => {

  const [changeView, setChangeView] = useState(false);
  const [testmonialData, setTestimonialData] = useState(
    {
      message: "Got myself Advanced Training Package for both my dog, German & Golden. I never realised what all they could learn if trained properly 🙏 Thank you so much",
      name: "Rudrang",
      img_src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/rudrang.png",
      profession: "Tattoo Artist"
    }
  );


  useEffect(() => {
    if (window.innerWidth <= 1000) {
      setChangeView(false);
    } else {
      setChangeView(true);
    }
  }, []);
  const sectionTwoRef = useRef(null);
  const sectionOneRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // console.log('Section is now visible');
          const ele1 = document.getElementById('section1');
          const ele2 = document.getElementById('section2');
          // console.log(ele);
          ele1.classList.add('appstore')
          ele2.classList.add('playstore')
          // Trigger the section action
        }
      },
      { rootMargin: '0px 0px -50% 0px' }
    );
    if (sectionOneRef.current) {
      observer.observe(sectionOneRef.current);
    }
    return () => {
      if (sectionOneRef.current) {
        observer.unobserve(sectionOneRef.current);
      }
    };
  }, []);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // console.log('Section is now visible');
          const ele1 = document.getElementById('section3');
          const ele2 = document.getElementById('section4');
          // console.log(ele);
          ele1.classList.add('appstore')
          ele2.classList.add('playstore')
          // Trigger the section action
        }
      },
    );
    if (sectionTwoRef.current) {
      observer.observe(sectionTwoRef.current);
    }
    return () => {
      if (sectionTwoRef.current) {
        observer.unobserve(sectionTwoRef.current);
      }
    };
  }, []);
  let count = 0;

  useEffect(() => {
    const targetDiv = document.querySelector("#testimonialContainer")
    console.log(targetDiv);

    const interval = setInterval(() => {
      count++;
      if (count === 4) {
        count = 0;
      }
      setTestimonialData(testimonialArray[count]);
    }, 5000);

    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    const script1 = document.createElement("script");
    const script2 = document.createElement("script");

    script1.type = "application/ld+json";
    script1.innerText = JSON.stringify({
      "@context": "https://schema.org/",
      "@type": "Review",
      "author": {
        "@type": "Person",
        "name": "Deepak Gaur"
      },
      "itemReviewed": {
        "@type": "LocalBusiness",
        "name": "Petmojo Boarding Service",
        "address": "New Delhi, India",
        "image": "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/petmojo-logo-white.png",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.8",
          "ratingCount": "2184"
        }
      },
      "reviewRating": {
        "@type": "Rating",
        "ratingValue": "4.8",
        "reviewAspect": ""
      },
      "publisher": {
        "@type": "Person",
        "name": "Parul Bhatnagar"
      },
      "reviewBody": "I left my doggo with them for 3 days, 2 nights. When I returned, I was happy to see he was not miserable and actually had a good time!"
    }
    )



    script1.id = "boarding-page1";

    document.body.appendChild(script1);


    return (() => {
      document.getElementById("boarding-page1").remove();
    })


  }, []);


  useEffect(() => {
    document.head.innerHTML += `
      <meta id="boarding-1" property="og:title" content="Book the most trusted Pet Hotel Service - Petmojo"/>
<meta id="boarding-2" property="og:site_name" content="Petmojo"/>
<meta id="boarding-3" property="og:url" content="https://petmojo.care"/>
<meta id="boarding-4" property="og:description" content="Going away for a vacation? But worried about your dog while you're away? Look no further, we are here to make sure all the needs of your pet are met."/>
<meta id="boarding-5" property="og:type" content="website"/>
<meta id="boarding-6" property="og:image" content="https://res.cloudinary.com/tamely-app/image/upload/v1662576550/petmojo-website/Updated_Logo_White_4_drxvnq.png"/>


<title id="boarding-id">Premium Pet Boarding Services in Delhi & Mumbai | Petmojo</title>
<meta id="boarding-desc" name="description" content="Pet Boarding - Find the best pet's comfort and safety with our top-notch pet boarding services in Delhi and Mumbai. Trust Petmojo for a worry-free experience." />
      `


    return (() => {
      document.getElementById("boarding-1").remove();
      document.getElementById("boarding-2").remove();
      document.getElementById("boarding-3").remove();
      document.getElementById("boarding-4").remove();
      document.getElementById("boarding-5").remove();
      document.getElementById("boarding-6").remove();
      document.getElementById("boarding-id").remove();
      document.getElementById("boarding-desc").remove();
    })
  }, []);

  function goToTopHandler() {
    return window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }

  const scrollToTop = () => {
    return window.scrollTo({ top: 200, left: 0, behavior: "smooth" });
  };


  const timelineData = [
    {
      title: "Download our App",
      text: "Book boarding for your preferred dates on our Petmojo application.",
      type: "boarding"
    },
    {
      title: "Pickup by Boarder",
      text: "Our Boarding partner will pick up your Dog in your given time slot. Enjoy your trip as your pet is in great hands.",
      type: "boarding"
    },
    {
      title: "Get Daily Updates",
      text: "You will receive updates everyday on your Dog’s well-being.",
      type: "boarding"
    },
    {
      title: "Drop off on Service End Date by Boarder",
      text: "On your return, we will drop them off at your house.",
      type: "boarding"
    },
  ];




  return (
    <div className={!changeView ? "boarding-mobile" : "boarding-desktop"} >

      <Helmet>
        <title>Premium Pet Boarding Services in Delhi & Mumbai | Petmojo</title>
        <meta name="description" content="Pet Boarding - Find the best pet's comfort and safety with our top-notch pet boarding services in Delhi and Mumbai. Trust Petmojo for a worry-free experience." />
      </Helmet>

      {
        !changeView ? (< IntroContainerMobileBoarding />) : (<IntroContainerDesktopBoarding />)
      }
      <div className="sliderServiceContainer" style={{ marginTop: "50px", backgroundColor: "#F3E18A" }}>
        {!changeView ? (
          <div style={{ marginTop: '20px', padding: '20px' }}>
            <div className="textbox2" style={{ color: "black" }}>

              Luxurious Pet Hotel
            </div>
            <hr className="vector2" style={{ borderColor: 'black' }}></hr>

            <div className="dog-container" style={{ border: "10px solid black", padding: '0px', display: 'flex', width: '100%', justifyContent: "center" }}>
              <div className="dog-box-2" style={{ display: "flex", justifyContent: "center", backgroundColor: "rgba(0, 0, 0, 0.10)", marginTop: '0px' }}>
                <div className="social-page-mobile-view-card-container" style={{ display: "flex", justifyContent: "center", border: "none", marginRight: '0px', marginBottom: "0px", paddingBottom: "0px", padding: "5px 10px" }}>
                  <div style={{ width: "100%", height: "100%" }}>
                    <video width="100%" height="100%" controls muted loop tabIndex="-1" autoPlay>
                      <source src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/review-3.mp4" type="video/mp4" />
                    </video>
                  </div>
                </div>

                {/* <div className="social-page-mobile-view-card-container">
                  <div className="social-page-mobile-view-card-image">
                    <img
                      alt="cardImage"
                      className="social-page-mobile-view-card-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Rectangle_1200_ullzjv.png"
                    />
                  </div>
                  <div className="social-page-mobile-blog-text"></div>
                  <div className="social-page-mobile-view-card-title">
                    Mental stimulation games for dogs.
                  </div>
                  <div>
                    <Link
                      className="social-page-mobile-view-card-read"
                      to="/blog"
                    >
                      Read More
                    </Link>
                  </div>
                </div>

                <div className="social-page-mobile-view-card-container">
                  <div className="social-page-mobile-view-card-image">
                    <img
                      alt="cardImage"
                      className="social-page-mobile-view-card-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Rectangle_1201_fquw51.png"
                    />
                  </div>
                  <div className="social-page-mobile-blog-text"></div>
                  <div className="social-page-mobile-view-card-title">
                    Mental stimulation games for dogs.
                  </div>
                  <div>
                    <Link
                      className="social-page-mobile-view-card-read"
                      to="/blog"
                    >
                      Read More
                    </Link>
                  </div>
                </div>

                <div className="social-page-mobile-view-card-container">
                  <div className="social-page-mobile-view-card-image">
                    <img
                      alt="cardImage"
                      className="social-page-mobile-view-card-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Rectangle_1202_t80vxh.png"
                    />
                  </div>
                  <div className="social-page-mobile-blog-text"></div>
                  <div className="social-page-mobile-view-card-title">
                    Mental stimulation games for dogs.
                  </div>
                  <div>
                    <Link
                      className="social-page-mobile-view-card-read"
                      to="/blog"
                    >
                      Read More
                    </Link>
                  </div>
                </div>

                <div className="social-page-mobile-view-card-container">
                  <div className="social-page-mobile-view-card-image">
                    <img
                      alt="cardImage"
                      className="social-page-mobile-view-card-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Rectangle_1203_x3n4ik.png"
                    />
                  </div>
                  <div className="social-page-mobile-blog-text"></div>
                  <div className="social-page-mobile-view-card-title">
                    Mental stimulation games for dogs.
                  </div>
                  <div className="">
                    <Link
                      className="social-page-mobile-view-card-read"
                      to="/blog"
                    >
                      Read More
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {!changeView ? null : (
        <div className="dog-container-desktop2" style={{ marginTop: "50px", backgroundColor: "#F3E18A" }}>
          <div style={{ border: "10px solid black", display: 'flex', width: "100%", padding: "20px", justifyContent: "space-between" }}>
            <div className="dog-slide-desktop-training">
              <div className="training-page-behave-box1" style={{ marginBottom: "0px" }}>
                <h2 className="fifty-font-size bold-none" style={{ color: "black" }}>
                  Checkout our
                  Luxurious Pet Hotel
                </h2>
                <div className="line-div relative-line">
                  <hr className="live-training-pink-line" style={{ borderColor: 'black' }}></hr>
                </div>
              </div>
              <div className="training-page-behave-box2" style={{ position: "relative", bottom: "20px" }}>
                <h4 className="bold-none" style={{ color: 'black' }}>
                  <div>16000+ monthly happy pet </div>
                  <div>parents on our platform!</div>
                </h4>
              </div>
            </div>

            <div className="dog-box-desktop-training" style={{ display: "flex", justifyContent: "center", backgroundColor: "rgba(0, 0, 0, 0.10)", width: '50%' }}>
              {/* <div className="adoption-price-tag-out">
                    
                    </div>
                <div className="adoption-price-tag" style={{background:'black',width:"200px"}}>
                <span className="adoption-price" style={{color:"white"}}>
                Happy Customers
                </span>
                </div> */}
              <div className="educational-content-card-training" style={{ width: "100%", height: "100%", boxShadow: "0px 4px 76px 0px #000", backgroundColor: "none", border: "none", background: "transparent", padding: '0px', margin: "0px", borderRadius: "0px" }}>
                <video width="100%" height="100%" controls muted loop autoPlay>
                  <source src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/review-4.mp4" type="video/mp4" />
                </video>
              </div>

              {/* <div className="educational-content-card-training">
              <div className="educational-content-card-training-upper">
                <img
                  className="educational-content-card-training-image"
                  alt="eduCard"
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Rectangle_1200_ullzjv.png"
                />
                <div className="educational-content-card-training-text">
                  Training
                </div>
                <div className="educational-content-card-training-head">
                Signs of an Aggressive Dog
                </div>
              </div>
              <div className="educational-content-card-training-lower">
                <div className="">
                  <Link
                    className="educational-content-card-training-link"
                    to="/blog/signs-of-an-aggressive-dog-37"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>

            <div className="educational-content-card-training">
              <div className="educational-content-card-training-upper">
                <img
                  className="educational-content-card-training-image"
                  alt="eduCard"
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Rectangle_1201_fquw51.png"
                />
                <div className="educational-content-card-training-text">
                  Training
                </div>
                <div className="educational-content-card-training-head">
                  Methods to Train a Dog Part 1
                </div>
              </div>
              <div className="educational-content-card-training-lower">
                <div className="">
                  <Link
                    className="educational-content-card-training-link"
                    to="/blog/methods-to-train-a-dog-1-34"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>

            <div className="educational-content-card-training">
              <div className="educational-content-card-training-upper">
                <img
                  className="educational-content-card-training-image"
                  alt="eduCard"
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Rectangle_1202_t80vxh.png"
                />
                <div className="educational-content-card-training-text">
                  Training
                </div>
                <div className="educational-content-card-training-head">
                  Pet Seperation Anxiety
                </div>
              </div>
              <div className="educational-content-card-training-lower">
                <div className="">
                  <Link
                    className="educational-content-card-training-link"
                    to="/blog/pet-separation-anxiety"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>

            <div className="educational-content-card-training">
              <div className="educational-content-card-training-upper">
                <img
                  className="educational-content-card-training-image"
                  alt="eduCard"
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Rectangle_1203_x3n4ik.png"
                />
                <div className="educational-content-card-training-text">
                  Training
                </div>
                <div className="educational-content-card-training-head">
                  Controlling a Hyperactive Dog
                </div>
              </div>
              <div className="educational-content-card-training-lower">
                <div className="">
                  <Link
                    className="educational-content-card-training-link"
                    to="/blog/controlling-a-hyperactive-dog"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div> */}
            </div>
          </div>

        </div>
      )}

      {
        !changeView ? (
          <div className="boarding-page-mobile-view-heart-container grey-background" style={{ marginTop: "30px", width: "100%" }}>
            <HeartContainer />
            <div className="bookingbutton" style={{ width: "100%", paddingLeft: "30px", paddingRight: "30px" }}>
              {/* <Link to="/popup-boarding" state={{ type: "boarding" }} style={{width:"100%"}}> */}
              <button className="booksession-mobile-form" style={{ width: "100%" }} onClick={scrollToTop}>Book Now!</button>
              {/* </Link> */}
            </div>
          </div>
        ) : (
          <div className="screen-height-manager-container" style={{ marginTop: "0px" }}>
            <div className={classes.heartSection}>
              <div className={classes.heartSectionTextContainer}>
                <div className={classes.heartSectionTextHeading}>
                  Your Pet, Our Family 💖
                </div>
                <div className="line-div">
                  <hr className="live-training-pink-line"></hr>
                </div>
                <div className={classes.heartSectionText} style={{ position: "relative", bottom: "20px" }}>
                  <div><span className="pink">16000+</span> monthly happy pet</div>
                  <div>parents on our platform!</div>
                </div>
              </div>
              <div className={classes.heartSectionImage}>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/desktop-heart-container.webp"
                  alt="heart"
                  className="hearticon"
                ></img>
              </div>
            </div>
          </div>
        )
      }

      {
        !changeView ? (
          <div className="pink-container">
            <div className="textbox tracking-text">
              <span>
                <span className="pinktext">Get LIVE updates on our</span>{" "}
                <span className="pet-bold-text">pet</span>
                <span className="pinktext">mojo Application</span>
              </span>
            </div>

            <div className="tracking-container" ref={sectionOneRef}>
              <div className="tracking-info">
                <div className="tracking-info-mobile-view-master-container">
                  <div className="tracking-info-mobile-view-container">
                    <div><img alt="tick" className="tick-mobile-view-image" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
                    <div>Tracking Daily Progress</div>
                  </div>
                  <div className="tracking-info-mobile-view-container">
                    <div><img alt="tick" className="tick-mobile-view-image" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
                    <div>Photo/Video updates</div>
                  </div>
                  <div className="tracking-info-mobile-view-container">
                    <div><img alt="tick" className="tick-mobile-view-image" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
                    <div>Twice a day Updates</div>
                  </div>
                  <div className="tracking-info-mobile-view-container">
                    <div><img alt="tick" className="tick-mobile-view-image" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
                    <div>Food, Water & Exercise</div>
                  </div>
                  <div className="tracking-info-mobile-view-container">
                    <div><img alt="tick" className="tick-mobile-view-image" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
                    <div>Boarding feedback</div>
                  </div>
                </div>
                <LazyLoad>
                  <a href="https://apps.apple.com/in/app/petmojo/id1609861328" target="_blank">

                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/appstore_lmgm04.png"
                      alt="appstore-training"
                      id="section1"
                    ></img>
                  </a>
                </LazyLoad>
                <LazyLoad>
                  <a href="https://play.google.com/store/apps/details?id=in.tamely.user" target="_blank">

                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/playstore_ordt0r.png"
                      alt="playstore"
                      id="section2"

                    ></img>
                  </a>
                </LazyLoad>
              </div>
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/boarding_twice_updates.png"
                  alt="trackingicon"
                  className="tracking-icon"
                ></img>
              </LazyLoad>
            </div>

            <div className="bookingbutton boarding-page-live-tracking-margin-bottom" style={{ width: "100%", paddingLeft: "30px", paddingRight: "30px" }}>
              {/* <Link to="/popup-boarding" state={{ type: "boarding" }} style={{width:"100%",paddingLeft:"30px",paddingRight:"30px"}}> */}
              <button className="booksession-mobile-form" style={{ width: "100%" }} onClick={scrollToTop}>Book Now!</button>
              {/* </Link> */}
            </div>
          </div>
        ) : (
          <div className="walking-page-track-container">
            <div className="walking-page-track-right">

              <div className="walking-page-track-left-heading" style={{ fontFamily: "DM Sans" }}>
                <div className="walking-page-track-line1 pink">Get LIVE updates on our</div>
                <div className="walking-page-track-line2">pet<span className="pink">mojo</span> <span className="pink">Application</span></div>
                <div className="">
                  <hr className="live-training-pink-line"></hr>
                </div>
              </div>

              <div className="walking-page-track-left-content" style={{ fontFamily: "DM Sans" }}>
                <div className="track-session-point">
                  <div><img alt="tick" className="tick-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
                  <div>Tracking Daily Progress</div>
                </div>
                <div className="track-session-point">
                  <div><img alt="tick" className="tick-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
                  <div>Photo/Video updates</div>
                </div>
                <div className="track-session-point">
                  <div><img alt="tick" className="tick-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
                  <div>Twice a day Updates</div>
                </div>
                <div className="track-session-point">
                  <div><img alt="tick" className="tick-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
                  <div>Food, Water & Exercise</div>
                </div>
                <div className="track-session-point">
                  <div><img alt="tick" className="tick-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
                  <div>Boarding feedback</div>
                </div>

              </div>

              <div className="" style={{ marginTop: "40px" }}>
                {/* <Link to="/popup" state={{ type: "dog-running" }}> */}
                <button className="booksession-form" onClick={() => scrollToTop()} style={{ paddingLeft: "20px", paddingRight: "20px" }}>Book Now!</button>
                {/* </Link> */}
              </div>

            </div>
            <div className="" style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <div className="walking-page-track-left-buttons" style={{ height: "80%", display: "flex", alignItems: "flex-end" }}>
                <div className="icons-store-section-float-2">
                  <div>
                    <a
                      target="_blank" rel="nofollow"
                      href="https://apps.apple.com/in/app/petmojo/id1609861328"
                    >
                      <img
                        alt="appStore"
                        className="walking-page-track-left-buttons-store"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/image_17_txwsw4.png"
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      target="_blank" rel="nofollow"
                      href="https://play.google.com/store/apps/details?id=in.tamely.user"
                    >
                      <img
                        alt="playStore"
                        className="walking-page-track-left-buttons-store"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/play-store-download.png"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="walking-page-track-left">


              <div style={{ height: "100%", width: "full", display: "flex", alignItems: "flex-end" }}>
                <img alt="petmojoImg" className="walking-page-track-image" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/boarding_twice_updates.png" style={{ height: "524px", width: "100%", objectFit: "contain" }} />
              </div>

              {/* buttons start */}
              {/* <div className="walking-page-track-left-buttons">
              <div className="icons-store-section-float-2">
                <div>
                  <a target="_blank" href="https://apps.apple.com/in/app/petmojo/id1609861328">
                    <img alt="appStore" className="walking-page-track-left-buttons-store" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/image_17_txwsw4.png" />
                  </a>

                </div> 
                <div>
                  <a target="_blank" href="https://play.google.com/store/apps/details?id=in.tamely.user">
                    <img alt="playStore" className="walking-page-track-left-buttons-store" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/play-store-download.png" />
                  </a>

                </div>
              </div>

            </div> */}
              {/* buttons end */}

            </div>

          </div>
        )
      }

      {
        !changeView ? (null) : (
          <div className={!changeView ? "container white-background" : "container-desktop-walking grey-background"}>
            {
              !changeView ? (
                <div>
                  <div className="textbox2">Words From Pet Parents</div>
                  <hr className="vector2"></hr>
                </div>
              ) : (
                null
              )
            }

            <div class="review-container">
              <div class={!changeView ? "review-box" : "review-box-desktop"}>

                {
                  !changeView ? (null) : (
                    <div className="home-page-customers-head-walking">
                      <div className="home-page-customer-heading">
                        <div>Our Customers</div>
                        <div className="line-div">
                          <hr className="live-training-pink-line"></hr>
                        </div>
                      </div>

                      <div className="home-page-customers-contents">
                        <div>Our customers trust us for having</div>
                        <div><span className="pink">Joyful</span> and <span className="pink">Positive</span> Reinforcements</div>
                        <div>only, "Your Pet is Our Family!"</div>
                      </div>
                    </div>
                  )
                }

                <div id="testimonialContainer" className={!changeView ? "review-box" : "review-box-desktop-3"}>
                  <div className={!changeView ? "testimonial-card-mastercontainer-mobile-view" : "testimonial-card-mastercontainer-2"}>
                    <div className="testimonial-card-upper-container">
                      <div className="testimonial-card-quote-image">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/quote-icon.png"
                          alt="quoteIcon"
                          className="quote-icon"
                        ></img>
                      </div>
                      <div className="testimonial-card-review-content">
                        {testmonialData.message}
                      </div>
                      <hr className="grey-line"></hr>
                    </div>
                    <div className="testimonial-card-lower-container">
                      <div className="testimonial-card-reviewer-image">
                        <img alt="rudrangImage" className="testimonial-user-image" src={testmonialData.img_src} />
                      </div>
                      <div className="testimonial-card-reviewer-name">{testmonialData.name}</div>
                      <div className="testimonial-card-reviewer-profession">{testmonialData.profession}</div>
                      <div className="testimonial-card-review-star-box">
                        <div><img alt="starIcon" className="star-icon-desktop-image" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png" /></div>
                        <div><img alt="starIcon" className="star-icon-desktop-image" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png" /></div>
                        <div><img alt="starIcon" className="star-icon-desktop-image" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png" /></div>
                        <div><img alt="starIcon" className="star-icon-desktop-image" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png" /></div>
                        <div><img alt="starIcon" className="star-icon-desktop-image" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {
              !changeView ? (
                <div className="bookingbutton">
                  {/* <Link href='/popup-boarding'  state={{ type: "boarding" }}>  */}
                  <button className="booksession-mobile-form" onClick={scrollToTop}>Book Now!</button>
                  {/* </Link> */}
                </div>
              ) : (
                null
              )
            }

          </div>
        )
      }


      {
        !changeView ? (
          <div className="bording-page-mobile-training-master-container">
            <div className="bording-page-mobile-training-section1">
              <div className="bording-page-mobile-training-section1-head">What is Pet Hotel & Boarding with <span className="pink">Training</span></div>
              <hr className="vector2"></hr>
            </div>
            <div className="bording-page-mobile-training-section2">
              <div className="bording-page-mobile-training-section2-head">What We Offer?</div>
              <div className="bording-page-mobile-training-section2-head2">
                We help you relax knowing your dog will be treatedlike family with us.
              </div>
              <div className="bording-page-mobile-training-section2-points">
                <li>Your Dog's Comfortable abode away from home.</li>
                <li>Safe and reliable boarding services.</li>
                <li>Special package with add on training services .</li>
              </div>
            </div>
            <div className="bording-page-mobile-training-section3" style={{ width: "100%" }}>
              <div className="bookingbutton" style={{ width: "100%", paddingLeft: "10px", paddingRight: "10px" }}>
                {/* <Link to="/popup-boarding" state={{ type: "boarding" }} style={{width:"100%"}}> */}
                <button className="booksession-mobile-form" style={{ width: "100%" }} onClick={scrollToTop}>Book Now!</button>
                {/* </Link> */}
              </div>
            </div>
          </div>
        ) : (
          <div className="boarding-page-desktop-view-meet-master-container">
            <div className="boarding-page-desktop-view-meet-left-container">
              <div className="boarding-page-desktop-view-left-head">
                <div className="boarding-page-desktop-view-left-head-text"><h2 className="fifty-font-size bold-none">What is Pet Hotel & Boarding with Training?</h2></div>
                <div className="line-div relative-line2">
                  <hr className="live-training-pink-line"></hr>
                </div>
              </div>
              <div>
                {/* <Link to="/popup-boarding" state={{ type: "boarding" }}> */}
                <button className="booksession-form" onClick={scrollToTop}>Book Now!</button>
                {/* </Link> */}
              </div>
            </div>
            <div className="boarding-page-desktop-view-meet-right-container">
              <div className="boarding-page-desktop-view-meet-right-head">
                <h3 className="bold-none">What We Offer?</h3>
              </div>
              <div>
                <div className="boarding-page-desktop-view-right-container-subtext"><h3 className="bold-none">We help you relax knowing your dog will be treated like family when with us.</h3> </div>
                <div>
                  <h4 className="bold-none boarding-page-desktop-view-right-container-subtext">
                    <li>Your Dog's Comfortable abode away from home.</li>
                    <li>Safe and reliable boarding services.</li>
                    <li>Special package with add on training services.</li>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        )
      }


      {
        !changeView ? (
          <div className="container-home pink-container-family">
            <div className="textbox2-get-pet-grooming">How it works?</div>
            <hr className="vector1"></hr>
            <div>
              <TimelineComponentBoarding version={"mobile"} timelineData={timelineData} />
            </div>
          </div>
        ) : (
          <div>
            <div className="container-home-desktop">
              <div className={styles.getStartedHeading}>
                <div className={styles.howToStart}>
                  <div className="textbox2-desktop">How to Start?</div>
                </div>
                <div className="line-div">
                  <hr className="live-training-pink-line"></hr>
                </div>

                <div className="get-started-grooming-page-sub-text-container">
                  <div className="get-started-grooming-page-sub-text-container1">It's as easy as 1-2-3, Follow the steps</div>
                  <div className="get-started-grooming-page-sub-text-container2">given and you are good to proceed!</div>
                </div>

                <div className="bookingbutton" style={{ marginTop: "20px", width: "60%", padding: "0px" }}>
                  {/* <Link to="/popup-boarding" state={{ type: "boarding" }} style={{width:"100%"}}> */}
                  <button className="booksession" style={{ width: "80%" }} onClick={scrollToTop}>Book Now!</button>
                  {/* </Link> */}
                  <div className="limitedslots">Hurry Up, Limited slots left!</div>
                </div>
              </div>
              <div>
                <TimelineComponentBoarding version={"desktop"} timelineData={timelineData} />
              </div>
            </div>
          </div>
        )
      }

      {
        !changeView ? (
          <div className="boarding-page-mobile-view-standard-master-container">
            <div>
              <div className="boarding-page-mobile-standard-heading">Standards & Safety</div>
              <hr className="vector2"></hr>
            </div>
            <div className="boarding-page-mobile-standard-sub-heading">
              Get peace of mind knowing your pet is safe and with TRUSTED dog lovers.
            </div>

            <div className="boarding-page-mobile-standard-container">
              <img className="boarding-page-mobile-standard-container-img" alt="containerImage" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8994_djxo0w.png" />
              <div className="boarding-page-mobile-standard-container-mobile">Hygienic food is provided on time (twice a day). </div>
            </div>

            <div className="boarding-page-mobile-standard-container">
              <img className="boarding-page-mobile-standard-container-img" alt="containerImage" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8995_bsexnv.png" />
              <div className="boarding-page-mobile-standard-container-mobile">Clean and adequate amount of water is provided. </div>
            </div>

            <div className="boarding-page-mobile-standard-container">
              <img className="boarding-page-mobile-standard-container-img" alt="containerImage" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8996_eqyuzq.png" />
              <div className="boarding-page-mobile-standard-container-mobile">While boarding with us, your pet will be given regular walks and exercises. </div>
            </div>

            <div className="boarding-page-mobile-standard-container">
              <img className="boarding-page-mobile-standard-container-img" alt="containerImage" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8997_otttwj.png" />
              <div className="boarding-page-mobile-standard-container-mobile">Updates given to you twice a day with pictures and videos. </div>
            </div>

            <div className="boarding-page-mobile-standard-container">
              <img className="boarding-page-mobile-standard-container-img" alt="containerImage" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8998_ulgvsd.png" />
              <div className="boarding-page-mobile-standard-container-mobile">Special add on training service can also be included.  </div>
            </div>
          </div>
        ) : (
          <div className="boarding-page-desktop-view-standard-master-container">
            <div className="boarding-page-desktop-view-standard-upper">
              <div className="boarding-page-desktop-view-standard-image-conatiner">
                <img className="boarding-standard-desktop-img" alt="imgContainer" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8992_bhtkeq.png" />
                <div className="boarding-page-desktop-view-standard-inner-text1">Hygienic food is provided on time (twice a day). </div>
              </div>
              <div className="boarding-page-desktop-view-standard-image-conatiner">
                <img className="boarding-standard-desktop-img" alt="imgContainer" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8993_hia6jl.png" />
                <div className="boarding-page-desktop-view-standard-inner-text1">Clean and adequate amount of water is provided. </div>
              </div>
              <div className="boarding-page-desktop-view-standard-text-conatiner">
                <div className="boarding-page-desktop-view-standard-text-conatiner-text">
                  <h2 className="fifty-font-size bold-none standard-and-safety">Standards & Safety</h2>
                  <div className="line-div relative-line-right">
                    <hr className="live-training-pink-line"></hr>
                  </div>
                </div>
                <div className="boarding-page-desktop-view-standard-subtext">
                  <h4 className="bold-none">
                    Get peace of mind knowing your pet is safe and with <span className="pink">TRUSTED</span> dog lovers.
                  </h4>
                </div>
              </div>
            </div>
            <div className="boarding-page-desktop-view-standard-lower">
              <div className="boarding-page-desktop-view-standard-image-conatiner">
                <img className="boarding-standard-desktop-img" alt="imgContainer" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8989_pq4vqi.png" />
                <div className="boarding-page-desktop-view-standard-inner-text1">While boarding with us, your pet will be given regular walks and exercises. </div>

              </div>
              <div className="boarding-page-desktop-view-standard-image-conatiner">
                <img className="boarding-standard-desktop-img" alt="imgContainer" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8988_n4w9ue.png" />
                <div className="boarding-page-desktop-view-standard-inner-text1">Updates given to you twice a day with pictures and videos. </div>

              </div>
              <div className="boarding-page-desktop-view-standard-image-conatiner">
                <img className="boarding-standard-desktop-img" alt="imgContainer" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8990_ofahvl.png" />
                <div className="boarding-page-desktop-view-standard-inner-text1">Special add on training service can also be included.  </div>

              </div>
            </div>
          </div>
        )
      }





      {
        !changeView ? (
          <div className="training-page-mobile-view-why-container">
            <div className="services-container2">

              <div className="service-box">
                <div className="textbox2" style={{ color: "#5D5D5D", fontWeight: 700 }}>
                  Why Choose Petmojo?
                </div>
                <hr className="vector1"></hr>
                <div className="service-1">
                  <LazyLoad>
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/trust-icon.png"
                      alt="trust icon"
                      className="trust-icon"
                    ></img>
                  </LazyLoad>
                  <div
                    className="service-info"
                    style={{ color: "white", fontWeight: 700 }}
                  >
                    Trusted & Caring Trainers
                  </div>
                </div>
                <div className="service-2">
                  <LazyLoad>
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/door-icon.png"
                      alt="door icon"
                      className="door-icon"
                    ></img>
                  </LazyLoad>
                  <div
                    className="service-info"
                    style={{ color: "white", fontWeight: 700 }}
                  >
                    Service at Your Door-Step{" "}
                  </div>
                </div>
                <div className="service-3">
                  <LazyLoad>
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/refund-icon.png"
                      alt="refund icon"
                      className="refund-icon"
                    ></img>
                  </LazyLoad>
                  <div
                    className="service-info"
                    style={{ color: "white", fontWeight: 700 }}
                  >
                    Guaranteed Satisfaction{" "}
                  </div>
                </div>
                <div className="service-4">
                  <LazyLoad>
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/team-icon.png"
                      alt="team icon"
                      className="team-icon"
                    ></img>
                  </LazyLoad>
                  <div
                    className="service-info"
                    style={{ color: "white", fontWeight: 700 }}
                  >
                    Local Onground Support
                  </div>
                </div>
                {/* 
                <div className="grey-background">
            <div className="bookingbutton">
                <button className="booksession-mobile-view2">GET QUOTE</button>

            </div>
          </div> */}

              </div>
            </div>
          </div>
        ) : (
          <div className="home-page-why-choose-container  white-background">

            <div className="home-page-why-choose-container1">
              <div className="home-page-why-choose-container1-heading">
                <div>Why Choose</div>
                <div>Petmojo?</div>
              </div>

              <div className="line-div">
                <hr className="live-training-pink-line"></hr>
              </div>

              <div className="home-page-why-choose-container1-content">
                <div>We are worlds most empathetic</div>
                <div>Pet Care Services.</div>
              </div>
            </div>

            <div className="home-page-why-choose-container2">

              <div className="service-1">
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/trust-icon.png"
                    alt="trust icon"
                    className="trust-icon"
                  ></img>
                </LazyLoad>
                <div
                  className="service-info"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  Trusted & Caring Trainers
                </div>
              </div>
              <div className="service-2">
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/door-icon.png"
                    alt="door icon"
                    className="door-icon"
                  ></img>
                </LazyLoad>
                <div
                  className="service-info"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  Service at Your Door-Step{" "}
                </div>
              </div>
              <div className="service-3">
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/refund-icon.png"
                    alt="refund icon"
                    className="refund-icon"
                  ></img>
                </LazyLoad>
                <div
                  className="service-info"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  Guaranteed Satisfaction{" "}
                </div>
              </div>
              <div className="service-4">
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/team-icon.png"
                    alt="team icon"
                    className="team-icon"
                  ></img>
                </LazyLoad>
                <div
                  className="service-info"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  Local Onground Support
                </div>
              </div>


            </div>

            <div className="home-page-why-choose-container3">
              <img className="home-page-why-choose-container3-img" alt="heroImg" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/desktop-bg-dog.webp" />
            </div>

          </div>
        )
      }

      {
        !changeView ? (
          <div className={!changeView ? "container white-background" : "container-desktop-walking grey-background"}>
            {
              !changeView ? (
                <div>
                  <div className="textbox2">Words From Pet Parents</div>
                  <hr className="vector2"></hr>
                </div>
              ) : (
                null
              )
            }

            <div class="review-container">
              <div class={!changeView ? "review-box" : "review-box-desktop"}>

                {
                  !changeView ? (null) : (
                    <div className="home-page-customers-head-walking">
                      <div className="home-page-customer-heading">
                        <div>Our Customers</div>
                        <div className="line-div">
                          <hr className="live-training-pink-line"></hr>
                        </div>
                      </div>

                      <div className="home-page-customers-contents">
                        <div>Our customers trust us for having</div>
                        <div><span className="pink">Joyful</span> and <span className="pink">Positive</span> Reinforcements</div>
                        <div>only, "Your Pet is Our Family!"</div>
                      </div>

                    </div>
                  )
                }
                <div id="testimonialContainer" className={!changeView ? "review-box" : "review-box-desktop-3"}>
                  <div className={!changeView ? "testimonial-card-mastercontainer-mobile-view" : "testimonial-card-mastercontainer-2"}>
                    <div className="testimonial-card-upper-container">
                      <div className="testimonial-card-quote-image">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/quote-icon.png"
                          alt="quoteIcon"
                          className="quote-icon"
                        ></img>
                      </div>
                      <div className="testimonial-card-review-content">
                        {testmonialData.message}
                      </div>
                      <hr className="grey-line"></hr>
                    </div>
                    <div className="testimonial-card-lower-container">
                      <div className="testimonial-card-reviewer-image">
                        <img alt="rudrangImage" className="testimonial-user-image" src={testmonialData.img_src} />
                      </div>
                      <div className="testimonial-card-reviewer-name">{testmonialData.name}</div>
                      <div className="testimonial-card-reviewer-profession">{testmonialData.profession}</div>
                      <div className="testimonial-card-review-star-box">
                        <div><img alt="starIcon" className="star-icon-desktop-image" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png" /></div>
                        <div><img alt="starIcon" className="star-icon-desktop-image" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png" /></div>
                        <div><img alt="starIcon" className="star-icon-desktop-image" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png" /></div>
                        <div><img alt="starIcon" className="star-icon-desktop-image" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png" /></div>
                        <div><img alt="starIcon" className="star-icon-desktop-image" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {
              !changeView ? (
                <div className="bookingbutton" style={{ width: "100%", paddingLeft: "30px", paddingRight: "30px" }}>
                  {/* <Link to="/popup-boarding" state={{ type: "boarding" }} style={{width:"100%"}}> */}
                  <button className="booksession-mobile-form" style={{ width: "100%" }} onClick={scrollToTop}>Book Now!</button>
                  {/* </Link> */}
                </div>
              ) : (
                null
              )
            }

          </div>
        ) : null
      }



      {!changeView ? <FAQContainerBoarding view={"mobile"} /> : <FAQContainerBoarding view={"desktop"} exception={"true"} />}

      {
        !changeView ? (
          <div className="grey-background">

            <div className="pink-container-family white-background">
              <div className="textbox2">We’ll Make Your Pet Smile</div>
              <hr className="vector2"></hr>

              <div className="heroImage1Container">
                <div className="heroImage1">
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/homepage-petmojo-partner-mobile-outline.webp"
                    alt="makepetsmile"
                    className="make-pet-smile"
                  ></img>
                  <div className='petmojo-partner-mobile-view-name'>Petmojo Partner - <span className='petmojo-partner-mobile-view-name-cursive'>Kartik Rai</span></div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="hero-image-container-desktop">

            <div className="hero-image-container-desktop-left">

              <div className="pet-smile-home-set1">
                <div>We'll Make Your</div>
                <div>Pet Smile</div>
                <div className="line-div">
                  <hr className="live-training-pink-line"></hr>
                </div>
              </div>

              <div className="pet-smile-home-set2">
                Your Pet Our Family!
              </div>



            </div>

            <div className="hero-image-container-desktop-right">
              <img className="pet-smile-img" alt="heroImg" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/desktop-petmojo-partner.webp" />
              <div className='petmojo-partner-name-desktop'>Petmojo Partner - <span className='petmojo-partner-name-desktop-cursive'>Kartik Rai</span></div>
            </div>
          </div>
        )
      }

      {
        !changeView ? (
          <div className="grey-background">
            <div className="textbox2">Connect With Us</div>
            <hr className="vector2"></hr>

            <div className="ellipse-container">
              <div className="ellipse">
                {/* <LazyLoad> */}
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/outer-ring.png"
                  alt="outerring"
                  className="outer-ring"
                ></img>
                {/* </LazyLoad> */}
                {/* <LazyLoad> */}
                <img src={"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/inner-circle.png"} alt="innerring" className="inner-ring"></img>
                {/* </LazyLoad> */}
                <button className="youtube-icon">
                  <a
                    href="https://youtube.com/channel/UCPQrCllizVvTOkSk7_1SIHw"
                    target="_blank"
                    rel="nofollow"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/yt-icon.png"
                      alt="youtubeicon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="facebook-icon">
                  <a
                    href="https://www.facebook.com/petmojo.care/"
                    target="_blank"
                    rel="nofollow"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/fb-icoc.png"
                      alt="facebookicon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="twitter-icon">
                  <a
                    href="https://twitter.com/Petmojo_App"
                    target="_blank" rel="nofollow"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/twitter-icon.png"
                      alt="twittericon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="instagram-icon">
                  <a
                    href="https://www.instagram.com/petmojo.care/"
                    target="_blank"
                    rel="nofollow"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/insta-icon.png"
                      alt="instagramicon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="phone-icon">
                  <a href="tel:+91 8889991800" target="_blank" rel="nofollow">
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/phone-icon.png"
                      alt="phoneicon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="gmail-icon">
                  <a
                    href="mailto: customercare@petmojo.in"
                    target="_blank"
                    rel="nofollow"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/gm-icon.png"
                      alt="gmailicon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="whatsapp-icon">
                  <a href="tel:+91 8889991800" target="_blank" rel="nofollow">
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/whatsapp-icon.png"
                      alt="whatsappicon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
              </div>
            </div>

          </div>
        ) : (
          <div className="social-media-links-container grey-background">
            <div className="social-media-links-header">
              <div className="social-media-links-text">
                Connect With Us
                <div className="line-div">
                  <hr className="live-training-pink-line"></hr>
                </div>
              </div>
              <div className="home-page-walking-packages-desktop-container2">
                <div>Connect on applications you use</div>
                <div>daily to get Informative/Educational </div>
                <div>contents on Petmojo.</div>
              </div>
            </div>

            <div className="social-media-links-image">
              <div className="ellipse-container">
                <div className="ellipse-desktop">
                  {/* <LazyLoad> */}
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Ellipse_374_ijrumy.png"
                    alt="outerring"
                    className="outer-ring-desktop"
                  ></img>
                  {/* </LazyLoad> */}
                  {/* <LazyLoad> */}
                  <img src={"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Ellipse_373_iadrer.png"} alt="innerring" className="inner-ring-desktop"></img>
                  {/* </LazyLoad> */}
                  <button className="youtube-icon-desktop">
                    <a
                      href="https://youtube.com/channel/UCPQrCllizVvTOkSk7_1SIHw"
                      target="_blank"
                      rel="nofollow"
                    >
                      {/* <LazyLoad> */}
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/yt-icon.png"
                        alt="youtubeicon"
                        width="60px"
                        height="60px"
                      ></img>
                      {/* </LazyLoad> */}
                    </a>
                  </button>
                  <button className="facebook-icon-desktop">
                    <a
                      href="https://www.facebook.com/petmojo.care/"
                      target="_blank"
                      rel="nofollow"
                    >
                      {/* <LazyLoad> */}
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/fb-desktop.png"
                        alt="facebookicon"
                        width="60px"
                        height="60px"
                      ></img>
                      {/* </LazyLoad> */}
                    </a>
                  </button>

                  <button className="instagram-icon-desktop">
                    <a
                      href="https://www.instagram.com/petmojo.care/"
                      target="_blank"
                      rel="nofollow"
                    >
                      {/* <LazyLoad> */}
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/insta-desktop.png"
                        alt="instagramicon"
                        width="60px"
                        height="60px"
                      ></img>
                      {/* </LazyLoad> */}
                    </a>
                  </button>
                  <button className="phone-icon-desktop">
                    <a href="tel:+91 8889991800" target="_blank" rel="nofollow">
                      {/* <LazyLoad> */}
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/phone-blue-desktop.png"
                        alt="phoneicon"
                        width="60px"
                        height="60px"
                      ></img>
                      {/* </LazyLoad> */}
                    </a>
                  </button>
                  <button className="gmail-icon-desktop">
                    <a
                      href="mailto: customercare@petmojo.in"
                      target="_blank"
                      rel="nofollow"
                    >
                      {/* <LazyLoad> */}
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/gmail-desktop.png"
                        alt="gmailicon"
                        width="60px"
                        height="60px"
                      ></img>
                      {/* </LazyLoad> */}
                    </a>
                  </button>
                  <button className="whatsapp-icon-desktop">
                    <a href="tel:+91 8889991800" target="_blank" rel="nofollow">
                      {/* <LazyLoad> */}
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/whatsapp-desktop.png"
                        alt="whatsappicon"
                        width="60px"
                        height="60px"
                      ></img>
                      {/* </LazyLoad> */}
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      }


      {/* {
        !changeView ? (null) : (
          <div className="footerDetailsContainer white-background">
          <div className="footerDetailsCont1">
            <div className="footerDetailsHeading">Need Help</div>
            <hr className="footerDetailsLine"></hr>
            <div className="footerDetailsContentContainer">
              <div className="footer-details-content-call-mail">
                <div><img alt="icon" className="icon-desktop-call" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/more-contact-icon.png" /></div>
                <div><a className="footerDetailsLink" target="_blank" href="tel: 9009004473">900 900 44 73</a></div>
              </div>

              <div className="footer-details-content-call-mail">
                <div><img alt="icon" className="icon-desktop-mail" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-mail-icon.png" /></div>
                <div><a className="footerDetailsLink" target="_blank" href="mailto: customercare@petmojo.in">customercare@petmojo.in</a></div>
              </div>
              
            </div>
          </div>
          <div className="footerDetailsCont1">
            <div className="footerDetailsHeading">Services</div>
            <hr className="footerDetailsLine"></hr>
            <div className="footerDetailsContentContainer">
              <Link className="footerDetailsLink" to="/dog-running" state={{from : "training"}}><div>Daily Dog Exercise/Walking</div></Link>
              <Link className="footerDetailsLink" to="/dog-training" state={{from : "training"}}><div>Dog Training</div></Link>
              <Link className="footerDetailsLink" to="/grooming" state={{from : "training"}}><div>Pet Grooming</div></Link>
            </div>
          </div>
          <div className="footerDetailsCont1">
            <div className="footerDetailsHeading">More</div>
            <hr className="footerDetailsLine"></hr>
            <div className="footerDetailsContentContainer">
              <Link className="footerDetailsLink" to="/partner"><div>Become a Partner</div></Link>
              <Link className="footerDetailsLink" to="/terms"><div>Terms and Conditions</div></Link>
              <Link className="footerDetailsLink" to="/privacy"><div>Privacy Policy</div></Link>
              <Link className="footerDetailsLink" to="/"><div>Privacy Policy - Partner</div></Link>
            </div>
          </div>
        </div>
        )
      } */}


      {/* {
            !changeView ? (null) : (
              <div className="desktop-footer-buttons-container white-background">
                <div className="desktop-footer-buttons-left">
                  <div className="desktop-footer-buttons-left-btn1">
                    <img alt="icon" className="desktop-footer-buttons-left-btn1-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/play-store-download.png" />
                  </div>
                  <div className="desktop-footer-buttons-left-btn1">
                    <img alt="icon" className="desktop-footer-buttons-left-btn1-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/app-store-download.png" />
                  </div>
                  <div onClick={scrollToTop} className="desktop-footer-buttons-left-btn3">
                    <div>Go at the top</div>
                    
                      <svg className="top-arrow" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
                      </svg>
                    
                  </div>
                </div>

                <div className="desktop-footer-buttons-right">
                  Copyright 2024 Petmojo
                </div>
              </div>
            )
          } */}

      {
        !changeView ? (
          <div className="white-background">
            <div className='mobile-view-footer-1'>
              <div className='mobile-view-footer-1-left'>
                <div className='mobile-view-footer-1-left-header-container'>
                  <div className='mobile-view-footer-1-header'>Need Help</div>
                  <hr className='vector2-footer'></hr>
                </div>
                <div className='mobile-view-footer-1-content-left'>
                  <div className='mobile-view-footer-phone-container'>
                    <div className='mobile-view-footer-phone-icon'><img alt="telIcon" className="mobile-view-tel-icon" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-phone-icon.png" /></div>
                    <div className=''><a className='mobile-view-footer-phone-text' style={{ textDecoration: "none" }} href="tel: +91 8889991800" target="_blank" rel="nofollow">8889991800</a></div>
                  </div>
                  <div className='mobile-view-footer-mail-container'>
                    <div className='mobile-view-footer-mail-icon'><img alt="mailIcon" className='mobile-view-mail-icon' src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-mail-icon.png" /></div>
                    <div className=''><a className='mobile-view-footer-mail-text' style={{ textDecoration: "none" }} href="mailto: customercare@petmojo.in" target="_blank" rel="nofollow">customercare@petmojo.in</a></div>
                  </div>
                </div>
              </div>
              <div className='mobile-view-footer-1-right'>
                <div className='mobile-view-footer-1-header'>Services</div>
                <hr className='vector2-footer'></hr>
                <div className='mobile-view-footer-1-right-content'><Link className='mobile-view-footer-page-link' style={{ textDecoration: "none" }} to="/dog-running" state={{ from: "training" }}>Dog Exercise/Walking</Link></div>
                <div className='mobile-view-footer-1-right-content'><Link className='mobile-view-footer-page-link' style={{ textDecoration: "none" }} to="/dog-training" state={{ from: "training" }}>Dog Training</Link></div>
                <div className='mobile-view-footer-1-right-content'><Link className='mobile-view-footer-page-link' style={{ textDecoration: "none" }} to="/grooming" state={{ from: "training" }}>Pet Grooming</Link></div>
                <div className='mobile-view-footer-1-right-content'><Link className='mobile-view-footer-page-link' style={{ textDecoration: "none" }} to="/grooming-subscription" state={{ from: "about" }}>Grooming Subscription</Link></div>
                <div className='mobile-view-footer-1-right-content'><Link className='mobile-view-footer-page-link' style={{ textDecoration: "none" }} to="/pet-boarding" state={{ from: "training" }}>Pet Hotel</Link></div>
                {/* <div className='mobile-view-footer-1-right-content'><Link className='mobile-view-footer-page-link' style={{textDecoration: "none"}} to="/styling" state={{from : "training"}}>Pet Styling</Link></div> */}
              </div>
            </div>

            <div className='mobile-view-footer-2'>
              <div className='mobile-view-footer-2-left'>

                <div className="footerDetailsHeading-mobile">Serving In</div>
                <hr className="footerDetailsLine"></hr>
                <div className="footerDetailsContentContainer-mobile">
                  <div>
                    <div className="footerDetailsLink-mobile">Delhi</div>
                    <div className="footerDetailsLink-mobile">Gurgaon</div>
                    <div className="footerDetailsLink-mobile">Noida</div>
                    <div className="footerDetailsLink-mobile">Ghaziabad</div>
                  </div>
                  <div>
                    <div className="footerDetailsLink-mobile">Faridabad</div>
                    <div className="footerDetailsLink-mobile">Manesar</div>
                    <div className="footerDetailsLink-mobile">Mumbai</div>
                  </div>
                </div>

              </div>
              <div className='mobile-view-footer-2-right'>
                <div className='mobile-view-footer-2-right-header'>More</div>
                <hr className='vector2-footer'></hr>
                <div className="mobile-view-links-div">
                  <Link style={{ textDecoration: "none", color: "rgba(132, 132, 132, 1)" }} to="/terms"><div className='mobile-view-footer-2-tnc'>Terms and Conditions</div></Link>
                  <Link style={{ textDecoration: "none", color: "rgba(132, 132, 132, 1)" }} to="/privacy"><div className='mobile-view-footer-2-tnc'>Privacy Policy</div></Link>
                  <Link style={{ textDecoration: "none", color: "rgba(132, 132, 132, 1)" }} to="/partner" ><div className='mobile-view-footer-2-left-content'>Become a Partner</div></Link>              <div className='mobile-view-footer-2-left-content'>Privacy Policy - Partner</div>
                  <Link style={{ textDecoration: "none", color: "rgba(132, 132, 132, 1)" }} to="/customer-support-policy" ><div className='mobile-view-footer-2-left-content'>Customer Support Policy</div></Link>
                  <Link style={{ textDecoration: "none", color: "rgba(132, 132, 132, 1)" }} to="/about" ><div className='mobile-view-footer-2-left-content'>About Us</div></Link>
                </div>
              </div>
            </div>

            <div className="mobile-footer-wrap" ref={sectionTwoRef}>

              <div className='mobile-view-footer-3' style={{ gap: "5px" }}>
                <div className='mobile-view-footer-3-btn1' style={{ height: "33px" }}>
                  <a href="https://apps.apple.com/in/app/petmojo/id1609861328" target="_blank" rel="nofollow">

                    <img className='mobile-view-footer-appStore-icon ' id="section3" alt="appStore" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/app-store-2.png" style={{ height: "100%", width: "100%", objectFit: "contain" }} />
                  </a>
                </div>
                <div className='mobile-view-footer-3-btn2' style={{ height: "33px" }}>
                  <a href="https://play.google.com/store/apps/details?id=in.tamely.user" target="_blank" rel="nofollow">

                    <img className='mobile-view-footer-appStore-icon ' id="section4" alt="playStore" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/play-store-2.png" style={{ height: "100%", width: "100%", objectFit: "contain" }} />
                  </a>
                </div>
                <div onClick={() => goToTopHandler()} className='mobile-view-footer-3-btn3' style={{ height: "33px", padding: "5px", display: "flex", justifyContent: "center" }}>

                  <span>Go to the top</span>
                  <BsArrowUp />
                </div>
              </div>
            </div>

            <div className='mobile-view-footer-4' style={{ marginBottom: "20px" }}>
              <div>Copyright 2024 Petmojo</div>
              <div>All copyrights are reserved - any infringement will be legally pursued.</div>
              <div><br></br><br></br><br></br><br></br><br></br><br></br></div>
            </div>

          </div>
        ) : (
          null
        )
      }

      {
        !changeView ? (null) : (
          <div className="footerDetailsContainer white-background">
            <div className="footerDetailsCont1">
              <div className="footerDetailsHeading">Need Help</div>
              <hr className="footerDetailsLine"></hr>
              <div className="footerDetailsContentContainer">
                <div className="footer-details-content-call-mail">
                  <div><img alt="icon" className="icon-desktop-call" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/more-contact-icon.png" /></div>
                  <div><a className="footerDetailsLink" target="_blank" rel="nofollow" href="tel: 8889991800">8889991800</a></div>
                </div>

                <div className="footer-details-content-call-mail">
                  <div><img alt="icon" className="icon-desktop-mail" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-mail-icon.png" /></div>
                  <div><a className="footerDetailsLink" target="_blank" rel="nofollow" href="mailto: customercare@petmojo.in">customercare@petmojo.in</a></div>
                </div>

              </div>
            </div>
            <div className="footerDetailsCont1">
              <div className="footerDetailsHeading">Services</div>
              <hr className="footerDetailsLine"></hr>
              <div className="footerDetailsContentContainer">
                <Link className="footerDetailsLink" to="/dog-running" state={{ from: "training" }}><div>Daily Dog Exercise/Walking</div></Link>
                <Link className="footerDetailsLink" to="/dog-training" state={{ from: "training" }}><div>Dog Training</div></Link>
                <Link className="footerDetailsLink" to="/cat-training" state={{ from: "training" }}><div>Cat Training</div></Link>
                <Link className="footerDetailsLink" to="/grooming" state={{ from: "training" }}><div>Pet Grooming</div></Link>
                <Link className="footerDetailsLink" to="/grooming-subscription" state={{ from: "training" }}><div>Grooming Subscription</div></Link>
                <Link className="footerDetailsLink" to="/pet-boarding" state={{ from: "training" }}><div>Pet Hotel</div></Link>
                {/* <Link className="footerDetailsLink" to="/styling" state={{from : "training"}}><div>Pet Styling</div></Link> */}
              </div>
            </div>
            <div className='footerDetailsContr1'>
              <div className="footerDetailsHeading">Serving In</div>
              <hr className="footerDetailsLine"></hr>
              <div className="footerDetailsContentContainer">
                <div className="footerDetailsLink">Delhi</div>
                <div className="footerDetailsLink">Gurgaon</div>
                <div className="footerDetailsLink">Noida</div>
                <div className="footerDetailsLink">Ghaziabad</div>
                <div className="footerDetailsLink">Faridabad</div>
                <div className="footerDetailsLink">Manesar</div>
                <div className="footerDetailsLink">Mumbai</div>
              </div>
            </div>
            <div className="footerDetailsCont1">
              <div className="footerDetailsHeading">More</div>
              <hr className="footerDetailsLine"></hr>
              <div className="footerDetailsContentContainer">
                <Link className="footerDetailsLink" to="/partner"><div>Become a Partner</div></Link>
                <Link className="footerDetailsLink" to="/terms"><div>Terms and Conditions</div></Link>
                <Link className="footerDetailsLink" to="/privacy"><div>Privacy Policy</div></Link>
                <Link className="footerDetailsLink" to="/"><div>Privacy Policy - Partner</div></Link>
                <Link className="footerDetailsLink" to="/customer-support-policy"><div>Customer Support Policy</div></Link>
              </div>
            </div>
          </div>
        )
      }

      {
        !changeView ? (null) : (
          <div>


            <div className="desktop-footer-buttons-container white-background">
              <div className="desktop-footer-buttons-left">
                <div className="desktop-footer-buttons-left-btn1">
                  <a href="https://play.google.com/store/apps/details?id=in.tamely.user" target="_blank" rel="nofollow">

                    <img alt="icon" className="desktop-footer-buttons-left-btn1-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/play-store-download.png" />
                  </a>
                </div>
                <div className="desktop-footer-buttons-left-btn1">
                  <a href="https://apps.apple.com/in/app/petmojo/id1609861328" target="_blank" rel="nofollow">

                    <img alt="icon" className="desktop-footer-buttons-left-btn1-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/app-store-download.png" />
                  </a>
                </div>
                <div onClick={scrollToTop} className="desktop-footer-buttons-left-btn3">
                  <div>Go at the top</div>

                  <svg className="top-arrow" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z" />
                  </svg>

                </div>
              </div>

              <div className="desktop-footer-buttons-right">
                Copyright 2024 Petmojo
              </div>
            </div>
          </div>
        )
      }

    </div>
  );
}

export default Boarding;