// import {useState, useEffect, useRef} from 'react';
// import {Link} from 'react-router-dom';
// import axios from 'axios';
// import {useNavigate} from 'react-router-dom';
// import LoadingSpinner from '../../loadingSpinner/LoadingSpinner';

// const HomePageForm = (props) => {

//     const [walking, setWalking] = useState(true);
//     const [training, setTraining] = useState(false);
//     const [loading, setLoading] = useState(false);

//     const navigate = useNavigate();

//     const [phoneValidation, setPhoneValidation] = useState(true);

//     const date = new Date();

//     // declaration of refs
//     const phoneRef = useRef(0);
//     const dateRef = useRef("");
//     const addressRef = useRef("");

//     // --------------------form submission handler functions-----------------------

//     async function submitWalkingTrainingDataHandler(path){

//         let link = "";
//         const timeOfReg = `${date.toLocaleTimeString('en-US')}`;

//         const stringPhone = phoneRef.current.value.toString();

//         let data = {
//             ContactNo: phoneRef.current.value,
//             DateOfRegistration: dateRef.current.value,
//             TimeOfRegistration: timeOfReg,
//             FormType: "Direct Form"
//         };

//          if(stringPhone.length === 10){
//              setLoading(true);
//             if(path === "walking"){
//                 link = "https://api.apispreadsheets.com/data/QcQTxBvwdD8rfShs/";
//             }
//             else if(path === "training"){
//                 console.log("Submitting the training data!")
//                 link = "https://api.apispreadsheets.com/data/kR8JfKXyaxqMQyqt/";
//             }

//             await axios.post(link, data)
//             .then((response) => {
//                 console.log(response);
//             })
//             .catch((err) => {
//                 console.log(err);
//             })

//             window.dataLayer = window.dataLayer || [];
//             window.dataLayer.push({
//               "event": `${path} Registration Fired From Desktop Service Page Form`
//             });

//             phoneRef.current.value = "";
//             setLoading(false);
//             navigate('/thankPage')

//          }else{
//             setPhoneValidation(false);
//             phoneRef.current.value = "";
//         }
//     };

//     return (
//         <div className="home-page-desktop-intro-form-container2">

//             <div className="walking-page-desktop-intro-form-header">
//               <div>Book a FREE Daily Dog Walking/Exercise Session</div>
//             </div>
//             <div className='walking-page-desktop-intro-form-contents-container'>
//                 <div className='walking-page-desktop-intro-form-contents-upper' >
//                     <div className='walking-page-desktop-intro-form-contents-upper-left'>
//                     <div className='home-page-desktop-input-container'>
//                         <div>
//                             Phone Number
//                         </div>
//                         {
//                             phoneValidation ? (null) : (
//                             <div className="home-page-desktop-phone-validation-text">
//                                 Please enter a valid phone number
//                             </div>
//                             )
//                         }
//                         </div>
//                         <input ref={phoneRef} onChange={() => setPhoneValidation(true)} className='walking-page-phone-input' type ="number" placeholder='Enter phone number' name="phone" required />
//                     </div>
//                     <div className='walking-page-desktop-intro-form-contents-upper-right' >
//                         <div className='home-page-intro-bottom-btn' >
//                             {
//                                 loading ? (<LoadingSpinner />) : (<button onClick = {() => submitWalkingTrainingDataHandler("walking")} className="booksession-form" >Book 1st FREE Walk</button>)
//                             }
//                         </div>
//                     </div>
//                 </div>
//                 <div className='walking-page-desktop-intro-form-contents-lower'>
//                     <i>We are mission driven & a Family of Pet Lovers</i>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default HomePageForm;
import { BiArrowBack } from "@react-icons/all-files/bi/BiArrowBack";
import { FaArrowAltCircleRight } from "@react-icons/all-files/fa/FaArrowAltCircleRight";
import { FaCheck } from "@react-icons/all-files/fa/FaCheck";
import { MdClose } from "@react-icons/all-files/md/MdClose";
import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import iconsDesktop from "../../../images/trainingFooterDesktop.png";
import ImageSlider from "../../ImageSlider";
import ImageSliderWalking from "../../ImageSliderWalking";
import LoadingSpinner from "../../loadingSpinner/LoadingSpinner";

//import dotenv from 'dotenv'
const images = [
  {
    id: 1,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/service-image-desktop.webp",
    alt: "Image 1",
  },
  {
    id: 2,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/service-image-desktop-2.webp",
    alt: "Image 2",
  },
  {
    id: 3,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/service-image-desktop-3.webp",
    alt: "Image 3",
  },
];
const images1 = [
  {
    id: 1,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/training-desktop-1.webp",
    alt: "Image 1",
  },
  {
    id: 2,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/training-desktop-2.webp",
    alt: "Image 2",
  },
  {
    id: 3,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/training-desktop-3.webp",
    alt: "Image 3",
  },
  {
    id: 4,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/training-desktop-4.webp",
    alt: "Image 4",
  },
  {
    id: 5,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/training-desktop-5.webp",
    alt: "Image 5",
  },
  {
    id: 6,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/walking-desktop-1.webp",
    alt: "Image 2",
  },
  {
    id: 7,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/walking-desktop-2.webp",
    alt: "Image 3",
  },
  {
    id: 8,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/walking-desktop-3.webp",
    alt: "Image 4",
  },
  {
    id: 9,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/walking-desktop-4.webp",
    alt: "Image 5",
  },
  {
    id: 10,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-desktop-1.webp",
    alt: "Image 5",
  },
  {
    id: 11,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-desktop-2.webp",
    alt: "Image 5",
  },
  {
    id: 12,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-desktop-3.webp",
    alt: "Image 5",
  },
  {
    id: 13,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/boarding-desktop-1.webp",
    alt: "Image 5",
  },
  {
    id: 14,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/boarding-desktop-2.webp",
    alt: "Image 5",
  },
  {
    id: 15,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/boarding-desktop-3.webp",
    alt: "Image 5",
  },
  {
    id: 16,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/boarding-desktop-4.webp",
    alt: "Image 5",
  },
];
const HomePageForm = (props) => {
  const navigate = useNavigate();
  const [price, setPrice] = useState(3990);
  const [couponCode, setCouponCode] = useState("");
  const [couponValue, setCouponValue] = useState(0);
  const [couponErrorMsg, setCouponErrorMsg] = useState("");
  const [couponNotValid, setCouponNotValid] = useState(false);
  const [couponValid, setCouponValid] = useState(false);
  const [selectedService, setSelectedService] = useState("");
  const [seconds, setSeconds] = useState(0);
  const [countdown, setCountdown] = useState(true);
  const [serviceDropdown, setServiceDropdown] = useState(false);
  const [selectedCarousel, setSelectedCarousel] = useState(1);
  const [timeSlot, setTimeSlot] = useState(1);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [serviceChoice, setServiceChoice] = useState("ONCE ONE PET");
  const [monthlyPackage, setMonthlyPackage] = useState("Once a day");
  const [numberPets, setNumberPets] = useState("One");
  const [pet, setPet] = useState("Dog");
  const [trialPackage, setTrailPackage] = useState("Free");
  const [petChoice, setpetChoice] = useState(1);
  const [slotValue, setSlotValue] = useState(false);
  const [slotFill, setSlotFill] = useState("0");
  const freeSlots = ["05AM-11AM", "11AM-05PM", "05PM-09PM"];
  const monthlySlot = ["05AM-06AM"];
  const [inputSlot, setInputSlot] = useState("");
  const location = useLocation().pathname;
  const [overallChoice, setOverallChoice] = useState({
    package: "",
    pets: "",
    trial: "",
    date: formattedDate,
    slot: slotFill,
    name: "",
    address: "",
    phone: "",
    latitude: 0,
    longitude: 0,
    price: 0,
  });
  const [newNameValidation, setNewNameValidation] = useState(true);
  const [newPhoneValidation, setNewPhoneValidation] = useState(true);
  const [petValidation, setPetValidation] = useState(true);
  const [newAddressValidation, setNewAddressValidation] = useState(true);
  const [newAddressValidation1, setNewAddressValidation1] = useState(true);
  const [newDateValidation, setNewDateValidation] = useState(true);
  const [representationDate, setRepresentationDate] = useState("");
  const [representationDay, setRepresentationDay] = useState("");
  const [isVerified, setIsVerified] = useState(null);
  const [showEnterOtp, setShowEnterOtp] = useState(false);
  const [isWrongOtp, setIsWrongOtp] = useState(false);
  const [correctOTP, setCorrectOTP] = useState(true);
  const baseUrl =
    "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api";
  const [token, setToken] = useState("");
  const [bookingId, setBookingId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const newDateRef = useRef("");
  const newServiceRef = useRef("");
  const newTimeServiceRef = useRef("");
  const newNameRef = useRef("");
  const newAddressRef = useRef("");
  const newDescriptionRef = useRef("");
  const newAddressRef1 = useRef("");
  const newPhoneRef = useRef("");
  const groomingOtpRef = useRef("");
  const groomingSlotRef = useRef("");
  const petRef = useRef("");
  const [groomingSlotValidation, setGroomingSlotValidation] = useState(true);

  const [paymentData, setPaymentData] = useState({});
  const [flag, setFlag] = useState(false);
  // const { coords } = useGeolocated();
  const [address, setAddress] = useState("");
  const [startValue, setStartValue] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [startAddress, setStartAddress] = useState(false);
  const [lat, setLat] = useState(0);
  const [lon, setLon] = useState(0);
  const [nameValue, setnameValue] = useState(false);
  const [nameFill, setNameFill] = useState("");
  const [phoneValue, setPhoneValue] = useState(false);
  const [phoneFill, setPhoneFill] = useState(null);
  const [petFill, setPetFill] = useState("");
  const [petValue, setPetValue] = useState(false);
  const [addressValue, setAddressValue] = useState(false);
  const [adressFill, setAdressFill] = useState("");
  const [phone, setPhone] = useState("");
  const [walkerType, setWalkerType] = useState("pro");
  var currentDate = new Date();
  var year = currentDate.getFullYear();
  var month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
  var day = ("0" + currentDate.getDate()).slice(-2);
  var formattedDate = year + "-" + month + "-" + day;
  function setSelectedServiceHandler(service) {
    setSelectedService(service);
    setServiceDropdown(false);
  }
  const firstFormWalkingHandler = () => {
    if (phoneFill == null || phoneFill == "") {
      setNewPhoneValidation(false);
    } else {
      setSelectedCarousel(2);
    }
  };
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay(
    serviceAmount,
    inputPhone,
    SelectedService,
    DateOfReg,
    InputAddress
  ) {
    setIsLoading(false);

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // creating a new order
    const result = await axios.post(
      "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/generateGroomingRazorPayOrderId",
      {
        amount: serviceAmount,
      }
    );

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }

    // Getting the order details back
    const { amount, id: order_id, currency } = result.data;

    const options = {
      key: process.env.REACT_APP_KEY, // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: "Petmojo",
      description: "Dog Walking Payment",
      //image: { logo },
      order_id: order_id,
      handler: async function (response) {
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };

        //console.log("Grooming Page Function: ", data);

        const result = await axios.post(
          "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/generateGroomingRazorPaySuccess",
          data
        );
        //alert(result.data.msg);
        const Data = {
          ...result.data,
          SelectedService,
          DateOfReg,
          InputAddress,
        };

        const actualAmount = serviceAmount / 100;

        const dataToStore = {
          Date: DateOfReg,
          Address: InputAddress,
          Contact_No: inputPhone.toString(),
          Amount_Paid: actualAmount.toString(),
          Selected_Service: SelectedService,
          Order_id: order_id,
        };

        await axios
          .post(
            "https://api.apispreadsheets.com/data/hq3CiEAYcPfNXIJE/",
            dataToStore
          )
          .then((response) => {
            console.log(response);
            console.log("The walking payment has been completed!");
          })
          .catch((err) => {
            console.log(err);
            console.log(
              "There was some error in submitting the walking payment to backend!"
            );
          });

        const data2 = {
          bookingId: bookingId,
          transactionId: order_id,
          amount: actualAmount,
          type: "User",
        };
        data2["isFromWebsite"] = true;
        // {
        //     "bookingId" : "63d554eb156e83edfd5d1144",
        //     "transactionId" : "order_JCB9CaLSarOaTj",
        //     "amount":799,
        //     "type": "User"
        // }

        await axios
          .patch(
            "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/service/postGroomingPayment",
            data2,
            {
              "Content-Type": "application/json",
              headers: {
                Authorization:
                  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjYzZWY5ZWZkNTc1MGY5Y2Q4MmVmYmZmNyJ9.QOz1wBekeflAqD2ktWjDQLubM4snuMMDFV0Lk97z5UY",
              },
            }
          )
          .then((response) => {
            console.log(response);
            console.log(
              "The walking payment has been completed and info has been saved in the database!"
            );
          })
          .catch((err) => {
            console.log(err);
            console.log(
              "There was some error in saving the walking payment to database!"
            );
          });

        setPaymentData(Data);
        setFlag(true);
      },
      // prefill: {
      //     name: "Petmojo",
      //     email: "customercare@petmojo.in",
      //     contact: "+91 9009004473",
      // },
      notes: {
        address: "Petmojo",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    //console.log(paymentObject);
    paymentObject.open();
  }
  const [showDialog, setShowDialog] = useState(false);
  const checkPhone = (phoneString) => {
    if (
      phoneString.length > 10
      // &&
      // (phoneString.startsWith("+91") || phoneString.startsWith("91"))
    ) {
      // phoneString = phoneString.slice(-10);
      setPhone(phoneString);
      setShowDialog(true);
    }
    return phoneString;
  };
  const groomingFormDesktopHandler1 = () => {
    if (monthlyPackage === "") {
      console.log("Cant move to next card!");
    } else {
      setOverallChoice({
        ...overallChoice,
        trial: trialPackage,
        pets: numberPets,
        package: monthlyPackage,
        // latitude:lat,
        // longitude:lon
      });
      setSelectedCarousel(2);
      console.log(overallChoice);
    }
  };

  const groomingFormDesktopHandler2 = () => {
    overallChoice.date = newDateRef.current.value;
    if (overallChoice.date == "" || phoneFill == "" || phoneFill == null) {
      console.log("Cant move to next card!");
      setNewPhoneValidation(false);
      console.log(overallChoice);
    } else {
      // let time = "";
      // if(timeSlot === 1){
      //     time = "05AM-11AM";
      // }else if(timeSlot === 2){
      //     time = "11AM-05PM";
      // }else if(timeSlot === 3){
      //     time = "05PM-09PM";
      // }
      // else{
      //     time = "05PM-06AM";
      // }

      setSelectedDate(newDateRef.current.value);
      // setSelectedTime(time);
      setInputSlot(groomingSlotRef.current.value);
      //overallChoice.time

      setOverallChoice({
        ...overallChoice,
        package: monthlyPackage,
        pets: numberPets,
        date: newDateRef.current.value,
        slot: inputSlot,
        // latitude:lat,
        // longitude:lon
      });
      setSelectedCarousel(3);
      console.log(overallChoice);
    }
  };

  const getmobileOtp = useCallback(async () => {
    try {
      let phoneString = checkPhone(newPhoneRef.current.value.toString());
      const res = await axios.post(`${baseUrl}/auth/sendMobileOTP`, {
        phoneNumber: phoneString,
      });
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  }, []);
  const changeName = () => {
    document.getElementById("send-otp-desk").innerHTML = "Resend OTP";
  };
  const verifyMobileOTP = useCallback(async () => {
    try {
      let phoneString = checkPhone(newPhoneRef.current.value.toString());
      const res = await axios.post(
        "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/auth/verifyMobileOTP",
        {
          otp: groomingOtpRef.current.value.toString(),
          phoneNumber: phoneString,
          type: "User",
        }
      );
      console.log(res.status);
      console.log(res.data);

      if (res.status === 200) {
        setIsVerified(true);
        setToken(res.data.token);
        setCountdown(false);
        setCorrectOTP(true);
      }
    } catch (error) {
      console.log(error.response.status);
      console.log(error);
      if (error.response.status === 400) {
        setIsVerified(false);
        setIsWrongOtp(true);
        setCorrectOTP(false);
      }
    }
  }, []);
  const indicateAlert = () => {
    if (!correctOTP) {
      document.getElementById("send-otp-desk").style.borderColor = "red";
    }
  };
  const backArrow = () => {
    setSelectedCarousel(selectedCarousel - 1);
  };
  const decrementSeconds = () => {
    // if(seconds===0){
    //     document.getElementById("resend-countdown").style.display="none"
    // }
    // else{
    setTimeout(() => {
      if (seconds == 0) {
        // document.getElementById("countdown-seconds").style.display = "none";
      } else {
        setSeconds(seconds - 1);
      }
    }, 1000);
    // }
  };
  const paymentGatewayHit = async (data) => {
    const path = "walking";
    const inputPhone = data.ContactNo;
    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${date.getMonth() + 1
      }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;
    const inputAddress = newAddressRef.current.value;

    axios
      .post(
        "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/saveWalkingData",
        data
      )
      .then((response) => {
        console.log(response);
        console.log("The walking form is being submitted...");
      })
      .catch((err) => {
        console.log(err);
        console.log("There was some error in submitting the walking form...");
      });

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: `${path} Registration Fired From Desktop Service Page Form`,
    });
    // setIsLoading(false);
    displayRazorpay(
      (price * 118) / 100 - couponValue,
      inputPhone,
      monthlyPackage,
      dateOfReg,
      inputAddress
    );

    console.log(data);
  };

  const getSubscription = async () => {
    const phone = phoneFill;
    const phoneString = checkPhone(phone.toString());
    const name = newNameRef.current.value;
    const address = newAddressRef.current.value;

    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${date.getMonth() + 1
      }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;

    const enteredYear = parseInt(selectedDate.substring(0, 4));
    const enteredMonth = parseInt(selectedDate.substring(5, 7));
    const enteredDay = parseInt(selectedDate.substring(8, 10));

    if (phoneString.length < 10 || name === "" || address === "") {
      console.log("cannot proceed further!");
    } else {
      // if(enteredMonth === date.getMonth()+1){
      //     if(enteredDay <= date.getDate()){
      //       setNewDateValidation(false);
      //       return;
      //     }
      // }

      setIsLoading(true);

      setOverallChoice({
        package: monthlyPackage,
        pets: numberPets,
        name: name,
        address: address,
        phone: phone,
        date: selectedDate,
        time: selectedTime,
        latitude: lat,
        longitude: lon,
      });

      const data = {
        ContactNo: phone,
        FormType: "Direct Form",
        DateOfRegistration: dateOfReg,
        TimeOfRegistration: timeOfReg,
        Address: address,
        TrialDate: overallChoice.date,
        Name: nameFill,
        Slot: "05AM-06AM",
      };

      // ContactNo: inputPhone,
      // Services: selectedService,
      // Date: inputDate,
      // Time: inputTime,
      // Address: inputAddress,
      // DateOfRegistration: dateOfReg,
      // TimeOfRegistration: timeOfReg,
      // Email: inputEmail,
      // FormType: "Direct Form"

      var amount = 3990;

      const data2 = {
        address: address,
        numberOfPets: numberPets == "One" ? 1 : 2,
        isFree: petChoice === 1,
        phone: phoneString,
        startDate: startDate == "" ? formattedDate : startDate,
        runTime1: monthlySlot[slotFill],
        package: {
          description:
            petChoice === 1 ? "Free Session" : `Monthly ${monthlyPackage}`,
          amount: petChoice === 1 ? 0 : (price * 118) / 100 - couponValue,
          frequency: petChoice === 1 ? 1 : 26,
          dayfrequency:
            petChoice === 1
              ? 1
              : monthlyPackage == "Once a day"
                ? 1
                : monthlyPackage == "Twice a day"
                  ? 2
                  : 3,
        },
        type: "User",
        amount: petChoice === 1 ? 0 : price,
        isCouponCodeUsed: couponValue === 0 ? false : true,
        couponCode: couponValue !== 0 ? couponCode : "",
      };

      await axios
        .post(
          "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/websiteDogWalkingBookingService",
          data2,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: token,
            },
          }
        )
        .then((response) => {
          console.log(response);
          setBookingId(response.bookingId);
          console.log("The walking data is submitted to the database.");
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          console.log("There was some error in saving the data in database.");
        });
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: `Walking Registration Fired From Desktop Service Page Form`,
      });
      paymentGatewayHit(data);
      return;
    }

    if (phoneString.length < 10) {
      setNewPhoneValidation(false);
    }
    if (name === "") {
      setNewNameValidation(false);
    }
    if (address === "") {
      setNewAddressValidation(false);
    }
  };

  const bookFreeSession = async () => {
    const phone = phoneFill;
    if (phone == null || phone == "") {
      setNewPhoneValidation(false);
      return;
    }
    const phoneString = checkPhone(phone.toString());
    const name = newNameRef.current.value;
    const address = newAddressRef.current.value;

    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${date.getMonth() + 1
      }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;

    // const enteredYear = parseInt(selectedDate.substring(0, 4));
    // const enteredMonth = parseInt(selectedDate.substring(5, 7));
    // const enteredDay = parseInt(selectedDate.substring(8, 10));

    if (phoneString.length < 10 || name === "" || address === "") {
      console.log("cannot proceed further!");
    } else {
      // if(enteredMonth === date.getMonth()+1){
      //     if(enteredDay <= date.getDate()){
      //       setNewDateValidation(false);
      //       return;
      //     }
      // }

      setIsLoading(true);

      setOverallChoice({
        package: monthlyPackage,
        name: name,
        pets: numberPets,
        address: address,
        phone: phoneString,
        date: selectedDate,
        slot: inputSlot,
        latitude: lat,
        longitude: lon,
      });

      const data = {
        ContactNo: phoneString,
        DateOfRegistration: dateOfReg,
        TimeOfRegistration: timeOfReg,
        FormType: "Direct Form",
        TrialDate: overallChoice.date,
        Address: address,
        Name: nameFill,
        Slot: freeSlots[slotFill],
      };

      var amount = price;

      axios
        .post(
          "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/saveWalkingData",
          data
        )
        .then((response) => {
          console.log(response);
          console.log("The walking form is being submitted...");
        })
        .catch((err) => {
          console.log(err);
          console.log("There was some error in submitting the walking form...");
        });

      const data2 = {
        address: address,
        numberOfPets: numberPets == "One" ? 1 : 2,
        isFree: petChoice === 1,
        phone: phoneString,
        startDate: startDate == "" ? formattedDate : startDate,
        runTime1: petChoice === 1 ? "" : "05AM-06AM",
        package: {
          description:
            petChoice === 1 ? "Free Session" : `Monthly ${monthlyPackage}`,
          amount: petChoice === 1 ? 0 : price,
          frequency: petChoice === 1 ? 1 : 26,
          dayfrequency:
            petChoice === 1
              ? 1
              : monthlyPackage == "Once a day"
                ? 1
                : monthlyPackage == "Twice a day"
                  ? 2
                  : 3,
        },
        type: "User",
        amount: petChoice === 1 ? 0 : price,
      };
      console.log(data2);

      await axios
        .post(
          "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/websiteDogWalkingBookingService",
          data2,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: token,
            },
          }
        )
        .then((response) => {
          console.log(response);
          console.log("The walking data is submitted to the database.");
          navigate("/thankPage");
        })
        .catch((err) => {
          console.log(err);
          console.log("There was some error in saving the data in database.");
        });

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: `Walking Registration Fired From Desktop Service Page Form`,
      });
    }

    if (phoneString.length < 10) {
      setNewPhoneValidation(false);
    }
    if (name === "") {
      setNewNameValidation(false);
    }
    if (address === "") {
      setNewAddressValidation(false);
    }
  };

  const trainingSubmissionHandler = async () => {
    console.log(overallChoice);
    const inputPhone = newPhoneRef.current.value;

    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${date.getMonth() + 1
      }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;

    if (
      checkPhone(newPhoneRef.current.value.toString()).length >= 10 &&
      newAddressRef.current.value !== "" &&
      newNameRef.current.value !== ""
    ) {
      setIsLoading(true);
      const phone = overallChoice.phone;
      // overallChoice.phone.length > 10 &&
      // (overallChoice.phone.startsWith("+91") ||
      //   overallChoice.phone.startsWith("91"))
      //   ? overallChoice.phone.slice(-10)
      //   : overallChoice.phone;
      const data = {
        ContactNo: phone,
        DateOfRegistration: dateOfReg,
        TimeOfRegistration: timeOfReg,
        FormType: "Direct Form",
        Address: overallChoice.address,
        TrialDate: overallChoice.date,
      };

      await axios
        .post(
          "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/saveWalkingData",
          data
        )
        .then((response) => {
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
        });
      setIsLoading(false);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: `Walking Registration Fired From Mobile Service Page Form`,
      });
      navigate("/thankPage");
    } else {
      if (checkPhone(newPhoneRef.current.value.toString()).length < 10) {
        setNewPhoneValidation(false);
        newPhoneRef.current.value = "";
      }

      return;
    }
  };
  useEffect(() => {
    if (flag) navigate("/ordersummary", { state: paymentData });
  }, [paymentData, flag]);

  const applyCoupon = async () => {
    await axios
      .post(
        "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/websiteVerifyCoupon",
        {
          name: couponCode,
          serviceType: 2,
          package: "Pet Hotel",
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: token,
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response.data.value) {
          setCouponValue(response.data.value);
          setCouponErrorMsg("");
          setCouponNotValid(false);
          setCouponValid(true);
        } else {
          setCouponValid(false);
          setCouponNotValid(true);
          setCouponErrorMsg(response.data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const date = new Date();

  const phoneRef = useRef("");
  const emailRef = useRef("");
  const dateRef = useRef("");
  const addressRef = useRef("");
  const timeRef = useRef("");
  const [addressFill, setAddressFill] = useState(false);

  useEffect(() => {
    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${date.getMonth() + 1
      }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;

    var MonthName;
    if (date.getMonth() + 1 == 1) {
      MonthName = "January";
    } else if (date.getMonth() + 1 == 2) {
      MonthName = "February";
    } else if (date.getMonth() + 1 == 3) {
      MonthName = "March";
    } else if (date.getMonth() + 1 == 4) {
      MonthName = "April";
    } else if (date.getMonth() + 1 == 5) {
      MonthName = "May";
    } else if (date.getMonth() + 1 == 6) {
      MonthName = "June";
    } else if (date.getMonth() + 1 == 7) {
      MonthName = "July";
    } else if (date.getMonth() + 1 == 8) {
      MonthName = "August";
    } else if (date.getMonth() + 1 == 9) {
      MonthName = "September";
    } else if (date.getMonth() + 1 == 10) {
      MonthName = "October";
    } else if (date.getMonth() + 1 == 11) {
      MonthName = "November";
    } else if (date.getMonth() + 1 == 12) {
      MonthName = "December";
    }

    var DayName;
    if (date.getDay() + 1 == 1) DayName = "Sunday";
    else if (date.getDay() + 1 == 2) DayName = "Monday";
    else if (date.getDay() + 1 == 3) DayName = "Tuesday";
    else if (date.getDay() + 1 == 4) DayName = "Wednesday";
    else if (date.getDay() + 1 == 5) DayName = "Thursday";
    else if (date.getDay() + 1 == 6) DayName = "Friday";
    else if (date.getDay() + 1 == 7) DayName = "Saturday";

    const repDate =
      MonthName + " " + date.getDate() + ", " + date.getFullYear() + " | ";
    const repDay = DayName;
    setRepresentationDate(repDate);
    setRepresentationDay(repDay);
  }, []);
  useEffect(() => {
    indicateAlert();
  }, [correctOTP]);

  useEffect(() => {
    decrementSeconds();
  }, [seconds]);

  const changeColor = () => {
    console.log(phoneFill);
  };


  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [otpsent, setOtpsent] = useState(false);

  const [isOtpVerified, setOtpVerification] = useState(false);

  const sendOtp = async () => {
    try {
      // Make a POST request to send OTP to the provided phone number
      const response = await axios.post(
        'https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/auth/sendMobileOTP',
        {
          phoneNumber: phoneNumber,
        }
      );

      // Handle the response, maybe show a success message
      console.log('OTP Sent:', response.data);
    } catch (error) {
      // Handle error, show error message
      console.error('Error sending OTP:', error);
    }
  };

  const verifyOtp = async () => {
    try {
      // Make a POST request to verify the entered OTP
      console.log(otp, phoneNumber)
      const response = await axios.post(
        'https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/auth/verifyMobileOTP',
        {
          otp: otp,
          phoneNumber: phoneNumber,
          type: "User"
        }
      );
      console.log(response.data)

      // If OTP is verified successfully, update state
      if (response.data.user && response.data.user.confirmed) {
        setOtpVerification(true);
      } else {
        setOtpVerification(false)

        // Handle unsuccessful OTP verification, show error message
        console.error('OTP Verification failed:', response.data.message);
      }
    } catch (error) {
      if (error && error.response && error.response.data.success === false) {
        setOtpVerification(false)
      }
      // Handle error, show error message
      console.error('Error verifying OTP:', error.response);
    }
  };
  useEffect(() => {
    if (otp.length === 6) {
      verifyOtp()
    }
  }, [otp])


  return (
    <div
      className="home-page-desktop-intro-form-container2"
      style={{
        width: location == "/" ? "100%" : "",
        marginTop: location == "/" ? "0px" : "",
        border: location == "/" ? "0px" : "",
      }}
    >
      {(location == "/dog-running" || location == "/dog-walking-meta") && (
        <div className="walking-page-desktop-intro-form-header">
          <div style={{ display: "flex", alignItems: "start" }}>
            <div
              style={{
                width: selectedCarousel === 1 ? "0%" : "8%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <BiArrowBack
                style={{ display: selectedCarousel === 1 ? "none" : "block" }}
                onClick={backArrow}
              />
            </div>
            {petChoice === 1 ? (
              <div style={{ width: selectedCarousel === 1 ? "100%" : "90%" }}>
                {/* {
                        petChoice===1?"Book Your Walking Session Here!":"Monthly dog walking at just ₹2,940/- only"
                    } */}
                Book Your Walking Session Here!
              </div>
            ) : (
              <div style={{ width: selectedCarousel === 1 ? "100%" : "90%" }}>
                {/* {
                    petChoice===1?"Book Your Walking Session Here!":"Monthly dog walking at just ₹2,940/- only"
                } */}
                Monthly dog walking at just{" "}
                <span style={{ fontSize: "18px" }}>₹{price}/-</span> only
              </div>
            )}
          </div>
        </div>
      )}

      {selectedCarousel == 1 ? (
        <div
          className="grooming-new-form-section1-main-container"
          style={{ padding: "25px" }}
        >
          <div className="grooming-new-form-section1-main">
            <div
              className="grooming-new-form-section1-left"
              style={{ width: "40%" }}
            >
              <div className="">
                {/* <span style={{ color: "#5E5E5E", fontWeight: "700" }}>Type of your pet</span> */}
                <div
                  style={{
                    display: "flex",
                    padding: "10px",
                    width: "100%",
                    flexDirection: "column",
                  }}
                  className=""
                >
                  <div
                    className="option"
                    id="one"
                    style={{ display: "flex" }}
                    onClick={() => {
                      setpetChoice(1);
                    }}
                  >
                    <div className="heading" style={{ marginBottom: "5px" }}>
                      <div
                        className="left"
                        onClick={() => {
                          // console.log("h")
                          setTrailPackage("Free");
                          setOverallChoice({ trial: "Free" });
                        }}
                      >
                        <div className="selection-circle">
                          {petChoice == 1 && <div className="dot"></div>}
                        </div>
                        <div style={{ display: "flex", gap: "4px" }}>
                          {/* <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/freeTrial.png"
                            alt=""
                            style={{ width: "25px", height: "25px" }}
                          /> */}
                          <span
                            style={{
                              fontWeight: "700",
                              color: "rgba(88, 88, 88, 1)",
                            }}
                          >
                            One FREE Walk{" "}
                            <span style={{ color: "rgba(192, 192, 192, 1)" }}>
                              (meet the walker)
                            </span>{" "}
                            <small style={{ fontSize: "13px" }}>
                              {" "}
                              <br />
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="option"
                    id="two"
                    style={{ display: "flex" }}
                    onClick={() => {
                      setpetChoice(2);
                    }}
                  >
                    <div className="heading" style={{ marginBottom: "5px" }}>
                      <div
                        className="left"
                        onClick={() => {
                          // console.log("g");

                          setTrailPackage("Subscription");
                          setOverallChoice({ trial: "Subscription" });
                        }}
                      >
                        <div className="selection-circle">
                          {petChoice == 2 && <div className="dot"></div>}
                        </div>
                        <div style={{ display: "flex", gap: "4px" }}>
                          {/* <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/subscription.png"
                            alt=""
                            style={{ width: "25px", height: "25px" }}
                          /> */}
                          <span
                            style={{
                              fontWeight: "700",
                              color: "rgba(88, 88, 88, 1)",
                            }}
                          >
                            Get Monthly Subscription{" "}
                            <small style={{ fontSize: "13px" }}>
                              {" "}
                              <br />
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {petChoice === 2 ? (
                <>
                  <div className="grooming-new-form-section1-left-head">
                    Select Number of Pets
                  </div>
                  <div
                    className="grooming-new-form-section1-left-container"
                    style={{ display: "flex", justifyContent: "" }}
                  >
                    <div
                      className={
                        numberPets === "One"
                          ? "grooming-new-form-section1-left-choice-container-active"
                          : "grooming-new-form-section1-left-choice-container"
                      }
                      style={{ width: "50%" }}
                    >
                      <div
                        className="grooming-new-form-section1-left-choice-name-container"
                        onClick={() => setNumberPets("One")}
                      >
                        <div
                          className="selection-circle"
                          onClick={() => (
                            setNumberPets("One"),
                            setPrice(
                              monthlyPackage == "Once a day"
                                ? walkerType == "standard"
                                  ? 3440
                                  : 3990
                                : monthlyPackage === "Twice a day"
                                  ? walkerType == "standard"
                                    ? 6960
                                    : 7990
                                  : walkerType == "standard"
                                    ? 9690
                                    : 11970
                            )
                          )}
                        >
                          {numberPets == "One" && <div className="dot"></div>}
                        </div>
                        {/* <input onClick={() => setServiceChoice("BATH & BRUSH")} type="radio" id="bath&brush" className='radio-grooming-form-desktop'></input> */}
                        <div
                          onClick={() => (
                            setNumberPets("One"),
                            setWalkerType(walkerType),
                            setPrice(
                              monthlyPackage == "Once a day"
                                ? walkerType == "standard"
                                  ? 3440
                                  : 3990
                                : monthlyPackage === "Twice a day"
                                  ? walkerType == "standard"
                                    ? 6960
                                    : 7990
                                  : walkerType == "standard"
                                    ? 9690
                                    : 11970
                            )
                          )}
                          htmlFor="bath&brush"
                        >
                          <span className="grooming-new-form-section1-left-choice-name">
                            {" "}
                            One Pet
                          </span>
                        </div>
                      </div>
                    </div>

                    <div
                      className={
                        numberPets === "Two"
                          ? "grooming-new-form-section1-left-choice-container-active"
                          : "grooming-new-form-section1-left-choice-container"
                      }
                      style={{ width: "50%" }}
                    >
                      <div
                        className="grooming-new-form-section1-left-choice-name-container"
                        onClick={() => setNumberPets("Two")}
                      >
                        <div
                          className="selection-circle"
                          onClick={() => (
                            // setServiceChoice("ONCE TWO PET"),
                            // setOverallChoice({choice: "ONCE TWO PET"})
                            setNumberPets("Two"),
                            setPrice(
                              monthlyPackage == "Once a day"
                                ? walkerType == "standard"
                                  ? 4940
                                  : 5490
                                : monthlyPackage === "Twice a day"
                                  ? walkerType == "standard"
                                    ? 8460
                                    : 9490
                                  : walkerType == "standard"
                                    ? 11190
                                    : 13470
                            )
                          )}
                        >
                          {numberPets == "Two" && <div className="dot"></div>}
                        </div>
                        {/* <input onClick={() => setServiceChoice("HAIRCUT & STYLING")} type="radio" id="haircutstyling" className='radio-grooming-form-desktop'></input> */}
                        <div
                          onClick={() => (
                            // setServiceChoice("ONCE TWO PET"),
                            // setOverallChoice({choice: "ONCE TWO PET"})
                            setNumberPets("Two"),
                            setWalkerType(walkerType),
                            setPrice(
                              monthlyPackage == "Once a day"
                                ? walkerType == "standard"
                                  ? 4940
                                  : 5490
                                : monthlyPackage === "Twice a day"
                                  ? walkerType == "standard"
                                    ? 8460
                                    : 9490
                                  : walkerType == "standard"
                                    ? 11190
                                    : 13470
                            )
                          )}
                          htmlFor="haircutstyling"
                        >
                          <span className="grooming-new-form-section1-left-choice-name">
                            Two Pets
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                }}
              >
                <div
                  className="grooming-new-form-section1-left-head"
                  style={{ marginTop: "10px" }}
                >
                  Includes
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    fontSize: "15px",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "5px",
                  }}
                >
                  <div className="intro-desktop-home-page-points-p1">
                    <div>
                      <img
                        alt="tick"
                        className="tick-img"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                      />
                    </div>
                    <div style={{ fontSize: "15px" }}>
                      GPS LIVE tracking on app
                    </div>
                  </div>
                  <div className="intro-desktop-home-page-points-p1">
                    <div>
                      <img
                        alt="tick"
                        className="tick-img"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                      />
                    </div>
                    <div style={{ fontSize: "15px" }}>30 mins walk</div>
                  </div>
                  <div className="intro-desktop-home-page-points-p1">
                    <div>
                      <img
                        alt="tick"
                        className="tick-img"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                      />
                    </div>
                    <div style={{ fontSize: "15px" }}>Track Distance</div>
                  </div>
                  <div className="intro-desktop-home-page-points-p1">
                    <div>
                      <img
                        alt="tick"
                        className="tick-img"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                      />
                    </div>
                    <div style={{ fontSize: "15px" }}>
                      Walk Everyday (Sunday optional)
                    </div>
                  </div>
                  <div className="intro-desktop-home-page-points-p1">
                    <div>
                      <img
                        alt="tick"
                        className="tick-img"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                      />
                    </div>
                    <div style={{ fontSize: "15px" }}>Attendance on app</div>
                  </div>
                  <div className="intro-desktop-home-page-points-p1">
                    <div>
                      <img
                        alt="tick"
                        className="tick-img"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                      />
                    </div>
                    <div style={{ fontSize: "15px" }}>
                      Trusted & Certified Dog Trainers.
                    </div>
                  </div>
                  {/* Walk Everyday (Sunday optional), GPS LIVE tracking on app, Attendance on app, Trusted & Certified Dog Trainers, 30 mins walk, Track Distance */}
                </div>
              </div>
            </div>
            <div
              className="grooming-new-form-section1-right"
              style={{ alignItems: "flex-start" }}
            >
              {petChoice === 1 ? (
                <div style={{ width: "100%" }}>
                  <div className="grooming-new-form-section1-left-head">
                    Select Number of Pets
                  </div>
                  <div
                    className="grooming-new-form-section1-left-container"
                    style={{
                      display: "flex",
                      justifyContent: "",
                      width: "100%",
                    }}
                  >
                    <div
                      className={
                        numberPets === "One"
                          ? "grooming-new-form-section1-left-choice-container-active"
                          : "grooming-new-form-section1-left-choice-container"
                      }
                      style={{ width: "50%" }}
                    >
                      <div
                        className="grooming-new-form-section1-left-choice-name-container"
                        onClick={() => setNumberPets("One")}
                      >
                        <div
                          className="selection-circle"
                          onClick={() => (
                            setNumberPets("One"),
                            setPrice(
                              monthlyPackage == "Once a day"
                                ? walkerType == "standard"
                                  ? 3440
                                  : 3990
                                : monthlyPackage === "Twice a day"
                                  ? walkerType == "standard"
                                    ? 6960
                                    : 7990
                                  : walkerType == "standard"
                                    ? 9690
                                    : 11970
                            )
                          )}
                        >
                          {numberPets == "One" && <div className="dot"></div>}
                        </div>

                        <div
                          onClick={() => (
                            setNumberPets("One"),
                            setWalkerType(walkerType),
                            setPrice(
                              monthlyPackage == "Once a day"
                                ? walkerType == "standard"
                                  ? 3440
                                  : 3990
                                : monthlyPackage === "Twice a day"
                                  ? walkerType == "standard"
                                    ? 6960
                                    : 7990
                                  : walkerType == "standard"
                                    ? 9690
                                    : 11970
                            )
                          )}
                          htmlFor="bath&brush"
                        >
                          <span className="grooming-new-form-section1-left-choice-name">
                            {" "}
                            One Pet
                          </span>
                        </div>
                      </div>

                    </div>

                    <div
                      className={
                        numberPets === "Two"
                          ? "grooming-new-form-section1-left-choice-container-active"
                          : "grooming-new-form-section1-left-choice-container"
                      }
                      style={{ width: "50%" }}
                    >
                      <div
                        className="grooming-new-form-section1-left-choice-name-container"
                        onClick={() => setNumberPets("Two")}
                      >
                        <div
                          className="selection-circle"
                          onClick={() => (
                            // setServiceChoice("ONCE TWO PET"),
                            // setOverallChoice({choice: "ONCE TWO PET"})
                            setNumberPets("Two"),
                            setPrice(
                              monthlyPackage == "Once a day"
                                ? walkerType == "standard"
                                  ? 4940
                                  : 5490
                                : monthlyPackage === "Twice a day"
                                  ? walkerType == "standard"
                                    ? 8460
                                    : 9490
                                  : walkerType == "standard"
                                    ? 11190
                                    : 13470
                            )
                          )}
                        >
                          {numberPets == "Two" && <div className="dot"></div>}
                        </div>
                        {/* <input onClick={() => setServiceChoice("HAIRCUT & STYLING")} type="radio" id="haircutstyling" className='radio-grooming-form-desktop'></input> */}
                        <div
                          onClick={() => (
                            // setServiceChoice("ONCE TWO PET"),
                            // setOverallChoice({choice: "ONCE TWO PET"})
                            setNumberPets("Two"),
                            setWalkerType(walkerType),
                            setPrice(
                              monthlyPackage == "Once a day"
                                ? walkerType == "standard"
                                  ? 4940
                                  : 5490
                                : monthlyPackage === "Twice a day"
                                  ? walkerType == "standard"
                                    ? 8460
                                    : 9490
                                  : walkerType == "standard"
                                    ? 11190
                                    : 13470
                            )
                          )}
                          htmlFor="haircutstyling"
                        >
                          <span className="grooming-new-form-section1-left-choice-name">
                            Two Pets
                          </span>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              ) : null}
              {petChoice === 2 ? (
                <div style={{ width: "100%" }}>
                  {petChoice === 2 ? (
                    <div>
                      <div
                        className="grooming-new-form-section1-left-head"
                        style={{ marginTop: "10px" }}
                      >
                        Choose Monthly Package
                      </div>

                      <div
                        className="grooming-new-form-section1-left-container"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div
                          className={
                            monthlyPackage === "Once a day"
                              ? "grooming-new-form-section1-left-choice-container-active"
                              : "grooming-new-form-section1-left-choice-container"
                          }
                        >
                          <div
                            className="grooming-new-form-section1-left-choice-name-container"
                            onClick={() => setMonthlyPackage("Once a day")}
                          >
                            <div
                              className="selection-circle"
                              onClick={() => (
                                setMonthlyPackage("Once a day"),
                                setPrice(
                                  numberPets == "One"
                                    ? walkerType == "standard"
                                      ? 3440
                                      : 3990
                                    : walkerType == "standard"
                                      ? 4940
                                      : 5940
                                )
                              )}
                            >
                              {monthlyPackage == "Once a day" && (
                                <div className="dot"></div>
                              )}
                            </div>
                            {/* <input onClick={() => setServiceChoice("BATH & BRUSH")} type="radio" id="bath&brush" className='radio-grooming-form-desktop'></input> */}
                            <div
                              onClick={() => (
                                setMonthlyPackage("Once a day"),
                                setPrice(
                                  numberPets == "One"
                                    ? walkerType == "standard"
                                      ? 3440
                                      : 3990
                                    : walkerType == "standard"
                                      ? 4940
                                      : 5940
                                )
                              )}
                              htmlFor="bath&brush"
                            >
                              <span className="grooming-new-form-section1-left-choice-name">
                                {" "}
                                Once a day
                              </span>
                            </div>
                          </div>
                        </div>

                        <div
                          className={
                            monthlyPackage === "Twice a day"
                              ? "grooming-new-form-section1-left-choice-container-active"
                              : "grooming-new-form-section1-left-choice-container"
                          }
                        >
                          <div
                            className="grooming-new-form-section1-left-choice-name-container"
                            onClick={() => setMonthlyPackage("Twice a day")}
                          >
                            <div
                              className="selection-circle"
                              onClick={() => (
                                setMonthlyPackage("Twice a day"),
                                setPrice(
                                  numberPets == "One"
                                    ? walkerType == "standard"
                                      ? 6960
                                      : 7990
                                    : walkerType == "standard"
                                      ? 8460
                                      : 9490
                                )
                              )}
                            >
                              {monthlyPackage == "Twice a day" && (
                                <div className="dot"></div>
                              )}
                            </div>
                            <div
                              onClick={() => (
                                setMonthlyPackage("Twice a day"),
                                setPrice(
                                  numberPets == "One"
                                    ? walkerType == "standard"
                                      ? 6960
                                      : 7990
                                    : walkerType == "standard"
                                      ? 8460
                                      : 9490
                                )
                              )}
                              htmlFor="haircutstyling"
                            >
                              <span className="grooming-new-form-section1-left-choice-name">
                                Twice a day
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            monthlyPackage === "Thrice a day"
                              ? "grooming-new-form-section1-left-choice-container-active"
                              : "grooming-new-form-section1-left-choice-container"
                          }
                        >
                          <div
                            className="grooming-new-form-section1-left-choice-name-container"
                            onClick={() => setMonthlyPackage("Thrice a day")}
                          >
                            <div
                              className="selection-circle"
                              onClick={() => (
                                // setServiceChoice("ONCE TWO PET"),
                                // setOverallChoice({choice: "ONCE TWO PET"})
                                // setNumberPets("Two")
                                setMonthlyPackage("Thrice a day"),
                                setPrice(
                                  numberPets == "One"
                                    ? walkerType == "standard"
                                      ? 9690
                                      : 11970
                                    : walkerType == "standard"
                                      ? 11190
                                      : 13470
                                )
                              )}
                            >
                              {monthlyPackage == "Thrice a day" && (
                                <div className="dot"></div>
                              )}
                            </div>
                            {/* <input onClick={() => setServiceChoice("HAIRCUT & STYLING")} type="radio" id="haircutstyling" className='radio-grooming-form-desktop'></input> */}
                            <div
                              onClick={() => (

                                setMonthlyPackage("Thrice a day"),
                                setPrice(
                                  numberPets == "One"
                                    ? walkerType == "standard"
                                      ? 9690
                                      : 11970
                                    : walkerType == "standard"
                                      ? 11190
                                      : 13470
                                )
                              )}
                              htmlFor="haircutstyling"
                            >
                              <span className="grooming-new-form-section1-left-choice-name">
                                Thrice a day
                              </span>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  ) : null}


                  <div>
                    <div
                      className="grooming-new-form-section1-left-head"
                      style={{ marginTop: "10px" }}
                    >
                      Select type of walker
                    </div>
                    <div
                      className="grooming-new-form-section1-left-container"
                      style={{
                        display: "flex",
                        justifyContent: "",
                        width: "100%",
                      }}
                    >
                      <div
                        className={
                          walkerType === "standard"
                            ? "grooming-new-form-section1-left-choice-container-active"
                            : "grooming-new-form-section1-left-choice-container"
                        }
                        style={{ width: "50%" }}
                      >
                        <div
                          className="grooming-new-form-section1-left-choice-name-container"
                          onClick={() => setWalkerType("standard")}
                        >
                          <div
                            className="selection-circle"
                            onClick={() => (
                              // setMonthlyPackage("Once a day"),
                              setWalkerType("standard"),
                              setPrice(
                                numberPets == "One"
                                  ? monthlyPackage === "Once a day"
                                    ? 3440
                                    : monthlyPackage === "Twice a day"
                                      ? 6960
                                      : 9690
                                  : monthlyPackage === "Once a day"
                                    ? 4940
                                    : monthlyPackage === "Twice a day"
                                      ? 8460
                                      : 11190
                              )
                            )}
                          >
                            {walkerType == "standard" && (
                              <div className="dot"></div>
                            )}
                          </div>

                          <div
                            onClick={() => (
                              // setMonthlyPackage("Once a day"),
                              setWalkerType("standard"),
                              setPrice(
                                numberPets == "One"
                                  ? monthlyPackage === "Once a day"
                                    ? 3440
                                    : monthlyPackage === "Twice a day"
                                      ? 6960
                                      : 9690
                                  : monthlyPackage === "Once a day"
                                    ? 4940
                                    : monthlyPackage === "Twice a day"
                                      ? 8460
                                      : 11190
                              )
                            )}
                            htmlFor="bath&brush"
                          >
                            <span className="grooming-new-form-section1-left-choice-name">
                              {" "}
                              Standard Walker
                            </span>
                          </div>
                        </div>
                      </div>

                      <div
                        className={
                          walkerType === "pro"
                            ? "grooming-new-form-section1-left-choice-container-active"
                            : "grooming-new-form-section1-left-choice-container"
                        }
                      >
                        <div
                          className="grooming-new-form-section1-left-choice-name-container"
                          onClick={() => setWalkerType("pro")}
                        >
                          <div
                            className="selection-circle"
                            onClick={() => (
                              // setMonthlyPackage("Once a day"),
                              setWalkerType("pro"),
                              setPrice(
                                numberPets == "One"
                                  ? monthlyPackage === "Once a day"
                                    ? 3990
                                    : monthlyPackage === "Twice a day"
                                      ? 7990
                                      : 11970
                                  : monthlyPackage === "Once a day"
                                    ? 5490
                                    : monthlyPackage === "Twice a day"
                                      ? 9490
                                      : 13470
                              )
                            )}
                          >
                            {walkerType == "pro" && <div className="dot"></div>}
                          </div>

                          <div
                            onClick={() => (
                              // setMonthlyPackage("Once a day"),
                              setWalkerType("pro"),
                              setPrice(
                                numberPets == "One"
                                  ? monthlyPackage === "Once a day"
                                    ? 3990
                                    : monthlyPackage === "Twice a day"
                                      ? 7990
                                      : 11970
                                  : monthlyPackage === "Once a day"
                                    ? 5490
                                    : monthlyPackage === "Twice a day"
                                      ? 9490
                                      : 13470
                              )
                            )}
                            htmlFor="bath&brush"
                          >
                            <span className="grooming-new-form-section1-left-choice-name">
                              {" "}
                              Pro Walker
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {petChoice === 2 && (
                    <div style={{ padding: "10px 20px", textAlign: "end" }}>
                      <span style={{ fontWeight: "400", fontSize: "18px" }}>
                        Total:
                      </span>{" "}
                      <span style={{ fontWeight: "800", fontSize: "20px" }}>
                        ₹
                        <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                          {price}
                          <br />
                        </span>
                        <span style={{ fontSize: "13px" }}>+ GST</span>
                      </span>
                    </div>
                  )}
                </div>
              ) : null}

              <div
                className="grooming-new-form-section1-right-next-button-container"
                style={{ marginTop: "40px", justifyContent: "flex-start" }}
              >
                <div
                  onClick={() => {
                    // groomingFormDesktopHandler1();
                    // firstFormWalkingHandler();
                    setSelectedCarousel(2);
                    // displayLocation();px
                  }}
                  className="grooming-new-form-section1-right-next-button"
                  style={{ width: "100%", fontSize: "18px", color: "black" }}
                >
                  {trialPackage === "Free" ? "Book FREE Walk" : "Next"}
                </div>
              </div>
            </div>
          </div>

          <div className="grooming-new-form-section1-carousel-container">
            <div className="grooming-new-form-section1-carousel-active"></div>
            <div className="grooming-new-form-section1-carousel"></div>
            {petChoice === 2 && (
              <div className="grooming-new-form-section1-carousel"></div>
            )}
          </div>
          <div style={{ paddingTop: "2rem" }}>
            <img
              src={iconsDesktop}
              style={{ width: "40rem", height: "10rem" }}
            />
          </div>
          <div className="walking-page-desktop-intro-form-contents-lower">
            <i>We are mission driven & a Family of Pet Lovers</i>
          </div>
        </div>
      ) : null}

      {selectedCarousel == 2 ? (
        <div className="grooming-new-form-section1-main-container">
          <div className="grooming-new-form-section1-main">
            <div
              className="grooming-new-form-section2-left"
              style={{ justifyContent: "start" }}
            >
              <div
                className="grooming-new-form-section3-right-ph-section-2"
                style={{ width: "100%", marginBottom: "10px" }}
              >
                <div className="grooming-new-form-section3-right-ph-section-Phone-container">
                  <div>
                    <div className="grooming-new-form-validation-container-desktop">
                      <div className="grooming-new-form-section3-right-ph-text">
                        Phone Number
                      </div>
                      {newPhoneValidation ? null : (
                        <div className="home-page-desktop-phone-validation-text">
                          Required
                        </div>
                      )}
                    </div>
                    <div className="grooming-new-form-section3-right-ph-input-contact">
                      <input
                        disabled={isVerified}
                        onChange={() => {
                          setNewPhoneValidation(true);
                          setPhoneValue(true);
                          setPhoneFill(newPhoneRef.current.value);
                          // changeColor();
                          // setPhoneNumber(e.target.value);
                        }}
                        ref={newPhoneRef}
                        className="grooming-new-form-desktop-input-container-type1"
                        placeholder="Contact number"
                        type="number"
                        required
                        style={{
                          padding: "10px",
                          width: "100%",
                          height: "40px",
                        }}
                        value={phoneValue ? phoneFill : null}
                      ></input>
                      {/* <button
                        className="grooming-new-form-desktop-input-container-type1"
                        style={{
                          padding: "10px",
                          width: "40%",
                          height: "40px",
                          marginLeft:"10px"
                        }}
                        onClick={() => {
                          
                          sendOtp();
                          setOtpsent(true);
                        }}
                        // value={phoneValue ? phoneFill : null}
                      > Send OTP</button> */}
                    </div>

                    <div className="grooming-new-form-section3-right-ph-input-contact">
                      {/* <input
                        ref={newPhoneRef}
                        className="grooming-new-form-desktop-input-container-type1"
                        placeholder="Enter OTP"
                        type="number"
                        required
                        style={{
                          padding: "10px",
                          width: "100%",
                          height: "40px",
                          marginTop:"10px"
                        }}
                        hidden={!otpsent}
                        onChange={(e) => setOtp(e.target.value)}
                  
                        // value={phoneValue ? phoneFill : null}
                      ></input>
                      <button
                        className="grooming-new-form-desktop-input-container-type1"
                        onClick={() => {
                          verifyOtp();
                        }}
                        hidden={!isOtpVerified}
                        type="button"
                          ref={phoneRef}
                        style={{
                          padding: "10px",
                          width: "40%",
                          height: "40px",
                          marginLeft:"10px",
                          marginTop:"10px"
                        }}
                        // required
                        // value={phoneValue ? phoneFill : null}
                      ><img src={otptick} style={{width:"0.8rem", height:"0.8rem", marginTop:"1px"}}/> Verified</button> */}
                    </div>
                  </div>
                </div>
              </div>
              {location == "/dog-walking-meta" && (
                <div
                  className="grooming-new-form-section3-right-ph-section-2"
                  style={{ width: "100%", marginBottom: "10px" }}
                >
                  <div className="grooming-new-form-section3-right-ph-section-Phone-container">
                    <div>
                      <div className="grooming-new-form-validation-container-desktop">
                        <div className="grooming-new-form-section3-right-ph-text">
                          Pet Name
                        </div>
                        {newPhoneValidation ? null : (
                          <div className="home-page-desktop-phone-validation-text">
                            Required
                          </div>
                        )}
                      </div>
                      <div className="grooming-new-form-section3-right-ph-input-contact">
                        <input
                          // disabled={isVerified}
                          onChange={() => {
                            setPetValidation(true);
                            setPetValue(true);
                            setPetFill(petRef.current.value);
                            // changeColor();
                          }}
                          ref={petRef}
                          className="grooming-new-form-desktop-input-container-type1"
                          placeholder="Pet Name"
                          type="text"
                          required
                          style={{
                            padding: "10px",
                            width: "100%",
                            height: "40px",
                          }}
                          value={petValue ? petFill : ""}
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {petChoice === 2 && (
                <>
                  <div className="grooming-new-form-section2-left-date-container">
                    <div className="grooming-new-form-validation-container-desktop">
                      <div className="grooming-new-form-section3-right-ph-text">
                        {petChoice === 1 ? "Session Date" : "Start Date"}
                      </div>
                      {newDateValidation ? null : (
                        <div className="home-page-desktop-phone-validation-text">
                          Required
                        </div>
                      )}
                    </div>
                    <div
                      className="grooming-new-form-desktop-input-space1"
                      style={{ position: "relative" }}
                    >
                      <input
                        ref={newDateRef}
                        className="grooming-new-form-desktop-input-container-type1"
                        onChange={() => {
                          setStartValue(true);
                          setStartDate(newDateRef.current.value);
                        }}
                        placeholder="DD/MM/YY"
                        type="date"
                        style={{ padding: "10px", fontSize: "16px" }}
                        value={startValue ? startDate : formattedDate}
                      ></input>
                    </div>
                  </div>
                  <div
                    className="select-slot"
                    style={{ width: "100% ", marginTop: "10px" }}
                  >
                    <div
                      style={{
                        color: "rgba(114, 114, 114, 1)",
                        marginBottom: "5px",
                      }}
                    >
                      Time Slot -{" "}
                      {petChoice === 1
                        ? slotFill == "05AM - 11AM"
                          ? "Morning"
                          : slotFill == "11AM - 05PM"
                            ? "Afternoon"
                            : "Evening"
                        : "Morning"}
                    </div>
                    {!groomingSlotValidation ? (
                      <div className="home-page-walking-form-mobile-feedback">
                        Required
                      </div>
                    ) : null}
                    <select
                      name="slot"
                      id="slot"
                      ref={groomingSlotRef}
                      onChange={(e) => {
                        //   groomingFormDesktopHandler2();
                        setGroomingSlotValidation(true);
                        // setUserData({
                        //   ...userData,
                        //   Slot:groomingSlotRef.current.value
                        // })
                        setSlotValue(true);
                        setSlotFill(groomingSlotRef.current.value);
                        setOverallChoice({
                          ...overallChoice,
                          slot:
                            petChoice === 1
                              ? freeSlots[Number(e.target.value)]
                              : monthlySlot[Number(e.target.value)],
                        });
                      }}
                      // onSelect={()=>{
                      //   setSlotValue(true);
                      //   setSlotFill(groomingSlotRef.current.value);
                      // }}
                      style={{
                        fontSize: "18px",
                        border: "2px solid rgba(255, 94, 149, 1) ",
                        color: "rgba(255, 94, 149, 1)",
                        width: "60%",
                        marginTop: "5px",
                        padding: "0px 20px",
                        height: "56px",
                        borderRadius: "8px",
                        outline: "none",
                      }}
                      // value={userData.Slot}
                      value={slotValue ? slotFill : ""}
                    >
                      <option value="0" style={{ padding: "0px 2px 1px" }}>
                        {petChoice === 1 ? "05AM - 11AM" : "05AM - 06AM"}
                      </option>
                      {petChoice === 1 ? (
                        <>
                          <option value="1">11AM - 05PM</option>
                          <option value="2">05PM - 09PM</option>
                        </>
                      ) : null}
                    </select>
                  </div>
                </>
              )}
              {petChoice === 1 && (
                <div
                  className="grooming-new-form-section2-left-date-text-container"
                  style={{}}
                >
                  {representationDate}{" "}
                  <span className="pink-text">{representationDay}</span>
                </div>
              )}
              {/* <div className="grooming-new-form-section2-left-date-text-container">
                {representationDate}{" "}
                <span className="pink-text">{representationDay}</span>
              </div> */}
            </div>
            <div
              className="grooming-new-form-section1-right"
              style={{ alignItems: "flex-start" }}
            >
              <div style={{ width: "100%" }}>
                <ImageSlider images={images} />
              </div>
              {/* <div className='grooming-new-form-section2-right-time-slot-container'>
                            <div>{petChoice===1?"Time Slot":"Time Slot-Morning"}</div>
                            <div className='grooming-new-form-section2-right-time-slots' style={{justifyContent:"flex-start"}}>
                                {
                                    petChoice===1?<>
                                        <div onClick={() => setTimeSlot(1)} className={timeSlot == 1 ? 'grooming-new-form-section2-right-time-slot-active' : 'grooming-new-form-section2-right-time-slot'}>
                                            <div style={{textAlign:"center"}}>
                                                Morning
                                            </div>
                                            <div>
                                                05AM-11AM
                                            </div>
                                            </div>
                                <div onClick={() => setTimeSlot(2)} className={timeSlot == 2 ? 'grooming-new-form-section2-right-time-slot-active' : 'grooming-new-form-section2-right-time-slot'}><div style={{textAlign:"center"}}>
                                                Afternoon
                                            </div>
                                            <div>
                                                11AM-05PM
                                            </div></div>
                                <div onClick={() => setTimeSlot(3)} className={timeSlot == 3 ? 'grooming-new-form-section2-right-time-slot-active' : 'grooming-new-form-section2-right-time-slot'}><div style={{textAlign:"center"}}>
                                                Evening
                                            </div>
                                            <div>
                                                05PM-09PM
                                            </div></div>
                                    </>:<>
                                    <div onClick={() => setTimeSlot(4)} className='grooming-new-form-section2-right-time-slot-active' style={{padding:"15px"}}>
                                        <span>

                                        05AM-06AM
                                        </span>
                                        <span>
                                            <BiChevronDown/>
                                        </span>
                                    </div>
                                    </>
                                }
                            </div>
                        </div>   */}

              <div
                className="grooming-new-form-section2-right-next-button-container"
                style={{ justifyContent: "flex-end" }}
              >
                {petChoice === 1 ? (
                  isLoading ? (
                    <div className="grooming-page-loading-spinner-container-desktop">
                      <LoadingSpinner />
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        bookFreeSession();
                      }}
                      className="grooming-new-form-section1-right-next-button"
                      style={{ width: "100%", color: "black" }}
                    >
                      Book a FREE Session
                    </div>
                  )
                ) : (
                  <div
                    onClick={() => {
                      groomingFormDesktopHandler2();
                    }}
                    className="grooming-new-form-section1-right-next-button"
                    style={{ width: "100%", color: "black" }}
                  >
                    Select Address & Book
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="grooming-new-form-section1-carousel-container">
            <div className="grooming-new-form-section1-carousel-active"></div>
            <div className="grooming-new-form-section1-carousel-active"></div>
            {petChoice === 2 && (
              <div className="grooming-new-form-section1-carousel"></div>
            )}
          </div>
        </div>
      ) : null}

      {selectedCarousel == 3 ? (
        <div className="grooming-new-form-section1-main-container">
          <div className="grooming-new-form-section1-main">
            <div className="grooming-new-form-section3-left">
              <div className="grooming-new-form-section3-right-ph-section">
                <div className="grooming-new-form-validation-container-desktop">
                  <div className="grooming-new-form-section3-right-ph-text">
                    Pet Parent Name
                  </div>
                  {newNameValidation ? null : (
                    <div className="home-page-desktop-phone-validation-text">
                      Required
                    </div>
                  )}
                </div>

                <div className="grooming-new-form-section3-right-ph-input-contact">
                  <input
                    onChange={() => {
                      setNewNameValidation(true);
                      setnameValue(true);
                      setNameFill(newNameRef.current.value);
                    }}
                    ref={newNameRef}
                    className="grooming-new-form-desktop-input-container-type1"
                    placeholder="Enter your name"
                    type="text"
                    style={{ padding: "10px" }}
                    value={nameValue ? nameFill : ""}
                  ></input>
                </div>
              </div>
              <div style={{ width: "100%", marginTop: "20px" }}>
                <ImageSliderWalking images={images1} />
              </div>

              {/* <div className="grooming-new-form-section3-right-address-section">
                <div className="grooming-new-form-validation-container-desktop">
                  <div className="grooming-new-form-section3-right-ph-text">
                    Address
                  </div>
                  {newAddressValidation ? null : (
                    <div className="home-page-desktop-phone-validation-text">
                      Required
                    </div>
                  )}
                </div>
                <div className="grooming-new-form-section3-right-ph-input-contact">
                  <input
                    onChange={() => {
                      setNewAddressValidation(true);
                      setAddress(newAddressRef.current.value);
                      console.log(newAddressRef.current.value);
                    }}
                    onFocus={handleLocationAccess}
                    ref={newAddressRef}
                    className="grooming-new-form-desktop-input-container-type1"
                    placeholder="Enter Your Home Address"
                    type="text"
                    style={{ padding: "10px" }}
                    value={address}
                  ></input>
                </div>
              </div> */}
              {/* <div className='grooming-new-form-section3-right-address-section'>
                            <div className='grooming-new-form-validation-container-desktop'>
                                <div className='grooming-new-form-section3-right-ph-text'>Complete Address</div>
                                {
                                    newAddressValidation1 ? null : (
                                        <div className='home-page-desktop-phone-validation-text'>Required</div>
                                    )
                                }
                            </div>
                            <div className='grooming-new-form-section3-right-ph-input-contact'>
                                <input onChange={() => {setNewAddressValidation1(true);
                                setAddressValue(true);
                                setAddressFill(newAddressRef1.current.value)
                                }} ref={newAddressRef1} className='grooming-new-form-desktop-input-container-type1' placeholder='Enter Your Home Address' type="text" style={{padding:"10px"}} value={addressValue?addressFill:""} ></input>
                            </div>
                        </div>   */}
            </div>
            <div className="grooming-new-form-section3-right">
              <div
                className="grooming-new-form-section3-right-address-section"
                style={{ marginTop: "0px" }}
              >
                <div className="grooming-new-form-validation-container-desktop">
                  <div className="grooming-new-form-section3-right-ph-text">
                    Address
                  </div>
                  {newAddressValidation ? null : (
                    <div className="home-page-desktop-phone-validation-text">
                      Required
                    </div>
                  )}
                </div>
                <div className="grooming-new-form-section3-right-ph-input-contact">
                  <input
                    onChange={() => {
                      setNewAddressValidation(true);
                      setAddress(newAddressRef.current.value);
                      console.log(newAddressRef.current.value);
                    }}
                    // onFocus={handleLocationAccess}
                    ref={newAddressRef}
                    className="grooming-new-form-desktop-input-container-type1"
                    placeholder="Enter Your Home Address"
                    type="text"
                    style={{ padding: "10px" }}
                    value={address}
                  ></input>
                </div>
                <div
                  className="grooming-new-form-validation-container-desktop"
                  style={{ marginTop: "30px" }}
                >
                  <div className="grooming-new-form-section3-right-ph-text">
                    Coupon Code
                    <span style={{ fontStyle: "italic" }}>(If Available)</span>
                  </div>
                  {couponValid && (
                    <div style={{ color: "#618E41", fontSize: "15px" }}>
                      Code applied successfully
                    </div>
                  )}
                  {couponNotValid && (
                    <div style={{ color: "red", fontSize: "15px" }}>
                      Invalid Code
                    </div>
                  )}
                </div>
                <div className="grooming-new-form-section3-right-ph-input-contact">
                  <input
                    id="coupon"
                    type="text"
                    className="grooming-new-form-desktop-input-container-type1"
                    onChange={(e) => {
                      setCouponCode(e.target.value);
                    }}
                  />
                  {!couponValid && !couponNotValid && (
                    <div className="coupon-apply-button" onClick={applyCoupon}>
                      <span style={{ paddingRight: "0.5rem" }}>Apply</span>
                      <FaArrowAltCircleRight className="coupon-apply-button-icon" />
                    </div>
                  )}

                  {couponValid && (
                    <div
                      className="coupon-applied-text"
                      style={{ color: "#618E41;" }}
                    >
                      <span style={{ marginRight: "8px" }}>
                        ₹ {couponValue} /- OFF
                      </span>{" "}
                      <FaCheck className="check-mark" />
                    </div>
                  )}
                  {couponNotValid && (
                    <div className="coupon-not-valid">
                      <MdClose className="x-mark" />
                    </div>
                  )}
                </div>
              </div>
              <div
                className="grooming-new-form-section2-left-date-text-container"
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginBottom: "40px",
                }}
              >
                {representationDate}{" "}
                <span className="pink-text">{representationDay}</span>
              </div>
              {/* <div className="grooming-new-form-section3-right-ph-section-2">
                <div className="grooming-new-form-section3-right-ph-section-Phone-container">
                  <div>
                    <div className="grooming-new-form-validation-container-desktop">
                      <div className="grooming-new-form-section3-right-ph-text">
                        Phone Number
                      </div>
                      {newPhoneValidation ? null : (
                        <div className="home-page-desktop-phone-validation-text">
                          Required
                        </div>
                      )}
                    </div>
                    <div className="grooming-new-form-section3-right-ph-input-contact">
                      <div>
                      <input
                        disabled={isVerified}
                        onChange={() => {
                          setNewPhoneValidation(true);
                          setPhoneValue(true);
                          setPhoneFill(newPhoneRef.current.value);
                          // changeColor();
                        }}
                        ref={newPhoneRef}
                        className="grooming-new-form-desktop-input-container-type1"
                        placeholder="Contact number"
                        type="number"
                        required
                        style={{ padding: "10px", width: "100%" }}
                        value={phoneValue ? phoneFill : null}
                      ></input>
                      </div>
                      <div className='resend-otp-desktop' style={{display:"flex",flexDirection:"column"}}>
                                            {
                                                isVerified === true ? (null) : (
                                                    <>
                                                        <button id='send-otp-desk' disabled={isVerified} onClick={() => { getmobileOtp(); setShowEnterOtp(true) ; changeName();setSeconds(33) }} className='send-otp-button-desktop-container' style={{display:"block",borderColor:correctOTP?"white":"red",backgroundColor:phoneFill?.length>=10?"rgb(69, 163, 165)":"",color:phoneFill?.length>=10?"white":"",borderRadius:"8px"}} >
                                                        
                                                        Send OTP 
                                                    </button>
                                                    <div>
                                                    {seconds!=0 && countdown?<div className='countdown-seconds' style={{fontSize:"12px",textAlign:"center"}}>{seconds} sec</div>:null}
                                                    </div>
                                                    
                                                    </>
                                                )
                                            }
                                        </div>
                    </div>
                    {seconds!=0?<div className='countdown-seconds' style={{fontSize:"12px",textAlign:"center"}}>{seconds} sec</div>:null}
                  </div>

                  {  showEnterOtp && (newPhoneRef.current.value.toString().length === 10 || showDialog) && (<div className="enterotp">
                                    <div className='enterotp-heading' style={{marginBottom:"5px"}}>
                                        <div>Enter OTP</div>
                                        {

                                            (isWrongOtp && !isVerified) ? (
                                                <div className='home-page-walking-form-mobile-feedback'>
                                                    Wrong OTP
                                                </div>
                                            ) : null

                                        }
                                    </div>
                                    <input  ref={groomingOtpRef} onClick={() => { setIsWrongOtp(false) }} disabled={isVerified} type="number" placeholder='Enter Otp'
                                    style={{padding:"10px"}} className='grooming-new-form-desktop-input-container-type1'/>
                                            <button
                                            style={{padding:"5px",margin:"0px",height:"40px",border:"2px solid #45A5A3",backgroundColor:phoneFill?.length>=10?"rgb(69, 163, 165)":"",color:phoneFill?.length>=10?"white":""}}
                                            disabled={isVerified}
                                            onClick={() => { verifyMobileOTP();indicateAlert() }}
                                            >
                                                {isVerified ? (
                          <>
                         
                            <img src={greenTick} width={15} />{" "}
                            <span style={{ marginLeft: "5px",color:"white" }}>Verified</span>
                           </>
                        ) : (
                          "Verify"
                        )}
                        </button>
                                     
                                </div>)}
                </div>
              </div> */}
              <div className="grooming-form-tagline-text">
                <center>
                  Login into our Petmojo application using same number
                </center>
              </div>
              {isLoading ? (
                <div className="grooming-page-loading-spinner-container-desktop">
                  <LoadingSpinner />
                </div>
              ) : (
                <div className="grooming-new-form-section1-right-pay-button-container">
                  {petChoice === 1 ? (
                    <button
                      onClick={() => bookFreeSession()}
                      className="grooming-new-form-section1-right-pay-button"
                    // style={{backgroundColor:!isVerified?"rgba(131, 145, 161, 1)":"",border:!isVerified?"2px solid rgba(131, 145, 161, 1)":""}}
                    >
                      Avail your FREE Session
                    </button>
                  ) : (
                    <button
                      onClick={() => getSubscription()}
                      className="grooming-new-form-section1-right-pay-button"
                    // style={{backgroundColor:!isVerified?"rgba(131, 145, 161, 1)":"",border:!isVerified?"2px solid rgba(131, 145, 161, 1)":""}}
                    >
                      Proceed to Pay
                    </button>
                  )}
                  {/* {
                                            petChoice===2?<button disabled={isVerified === false || isVerified === null} onClick={() => paymentInitiationHandler()} className='grooming-new-form-section1-right-pay-button'>
                                            Pay Now
                                        </button>:null
                                        }
                                        <button disabled={isVerified === false || isVerified === null} onClick = {() => bookNowPayLaterHandler()} className={petChoice===2?'grooming-new-form-section1-right-pay-later-button':'grooming-new-form-section1-right-pay-button'}>
                                           {
                                            petChoice===1?"Book Now":" Book Now & Pay Later"
                                           }
                                        </button> */}
                </div>
              )}
            </div>
          </div>

          <div className="grooming-new-form-section1-carousel-container">
            <div className="grooming-new-form-section1-carousel-active"></div>
            <div className="grooming-new-form-section1-carousel-active"></div>
            <div className="grooming-new-form-section1-carousel-active"></div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default HomePageForm;

//displayRazorpay()

//onClick={() => groomingDataSubmitHandler("grooming")}

// /payment/success:

//     request body:
// {
//     orderCreationId: order_id,
//     razorpayPaymentId: response.razorpay_payment_id,
//     razorpayOrderId: response.razorpay_order_id,
//     razorpaySignature: response.razorpay_signature,
// }
//     response body:
//         {
//             msg: "success",
//             orderId: razorpayOrderId,
//             paymentId: razorpayPaymentId,
//         }

// /payment/orders:
//         request body:
//             {
//                 'amount': serviceAmount
//             }

//         response body:

//                 {
//                     id: 'order_Kjsc3E9EEaiS13',
//                     entity: 'order',
//                     amount: 100,
//                     amount_paid: 0,
//                     amount_due: 100,
//                     currency: 'INR',
//                     receipt: 'receipt_order_74394',
//                     offer_id: null,
//                     status: 'created',
//                     attempts: 0,
//                     notes: [],
//                     created_at: 1669293764
//                 }
