import React, { useRef, useEffect, useState } from 'react';
import 'tailwindcss/tailwind.css';

import Accordion from "../../../components/Accordion";
import AccordionDesktop from "../../../components/AccordionDesktop";

import { data } from "../../../mockData/mockDataTraining";
import { faqData } from '../../../mockData/faqData';


const FAQContainerNew = ({ type }) => {
    const rightSectionRef = useRef(null);
    const wrapperRef = useRef(null);

    const [topic, setTopic] = useState(Object.keys(faqData)[0]);

    useEffect(() => {
        const handleScroll = () => {
            const wrapper = wrapperRef.current;
            const rightSection = rightSectionRef.current;
            const boundingRect = wrapper.getBoundingClientRect();

            if (boundingRect.top <= 0 && window.scrollY < wrapper.scrollHeight - window.innerHeight) {
                rightSection.style.position = 'fixed';
                rightSection.style.top = '0';
                rightSection.style.height = 'auto';
            } else if (boundingRect.top > 0) {
                rightSection.style.position = 'static';
                rightSection.style.height = 'auto';
            } else {
                rightSection.style.position = 'static';
                rightSection.style.height = 'auto';
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    if (type === "mobile") {
        return (
            <div className="centerAlign">
                <div>
                    <div className="textbox2 faq-title">FAQ'S</div>
                    <hr className="vector2"></hr>

                    <div className="grey-text">Most frequent Questions and Answers</div>

                    <div className="flex flex-wrap col-span-3 gap-2 mt-4 mb-4 justify-center">
                        {
                            Object.keys(faqData).map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`rounded-xl border-sub_text text-gray_text text-md font-semibold border-2 p-2 cursor-pointer ${topic === item ? 'bg-brand text-white' : ''}`}
                                        onClick={() => {
                                            setTopic(item)
                                        }}
                                    >
                                        {item}
                                    </div>
                                )
                            })
                        }
                    </div>


                    <div className="accordion">
                        {faqData[topic].map(({ title, content }) => (
                            <Accordion view={"mobile"} title={title} content={content} />
                        ))}
                    </div>
                </div>

            </div>
        )
    }

    return (
        <div ref={wrapperRef} className="relative flex w-full px-[120px] ">
            <div className="w-2/5 ">
                <div className="sticky top-0 py-8">
                    <div className="textbox2-desktop">FAQ'S</div>
                    <div className="line-div">
                        <hr className="live-training-pink-line"></hr>
                    </div>
                    <div className="text-[#989898] text-[20px] mt-2">Most frequent Questions and Answers</div>
                    <div className="flex flex-wrap col-span-3 gap-4 mt-8    ">
                        {
                            Object.keys(faqData).map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`rounded-xl border-sub_text text-gray_text text-lg font-semibold border-2 py-5 px-4 cursor-pointer ${topic === item ? 'bg-brand text-white' : ''}`}
                                        onClick={() => {
                                            setTopic(item)
                                        }}
                                    >
                                        {item}
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
            </div>
            <div ref={rightSectionRef} className="w-3/5">
                <div className="py-8">
                    <div >
                        {faqData[topic].map(({ title, content }) => (
                            <AccordionDesktop title={title} content={content} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FAQContainerNew;
