import { BsArrowUp} from "@react-icons/all-files/bs/BsArrowUp";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import IntroContainerDesktopSocials from './container/introContainer/IntroContainerDesktopSocials';
import NavigationBarSocial from './container/introContainer/IntroContainerMobileSocials';
import GoToTop from "./GoToTop";
import "./Socials.css";

function Socials() {
  const [navDropdown, setNavDropdown] = useState(false);
  const [changeView, setChangeView] = useState(false);

  const dropdownRef = useRef(null);
  const [valueMobile, setValueMobile] = useState(10);
  const sectionOneRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // console.log('Section is now visible');
          const ele1 = document.getElementById('section1');
          const ele2 = document.getElementById('section2');
          // console.log(ele);
          ele1.classList.add('appstore')
          ele2.classList.add('playstore')
          // Trigger the section action
        }
      },
    );
    if (sectionOneRef.current) {
      observer.observe(sectionOneRef.current);
    }
    return () => {
      if (sectionOneRef.current) {
        observer.unobserve(sectionOneRef.current);
      }
    };
  }, []);
  const handleChangeMobile = (value) => {
    setValueMobile(value);
    const scrollableDiv = document.getElementById('scrollable-div-mobile');
    scrollableDiv.scrollLeft = (valueMobile / 100) * (scrollableDiv.scrollWidth - scrollableDiv.clientWidth);
  }
  useEffect(() => {
    if (window.innerWidth < 1000) {
      setChangeView(false);
    } else {
      setChangeView(true);
    }
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setNavDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    document.head.innerHTML += `
    <meta id="socials-1" property="og:title" content=Join our Community of Pet Parents - Petmojo/>
<meta id="socials-2" property="og:site_name" content=Petmojo/>
<meta id="socials-3" property="og:url" content=https://petmojo.care/>
<meta id="socials-4" property="og:description" content=A community for all the pet parents out there. A safe space to share and learn about your little buddies from experts and experienced pet parents./>
<meta id="socials-5" property="og:type" content=website/>
<meta id="socials-6" property="og:image" content=https://res.cloudinary.com/tamely-app/image/upload/v1662576550/petmojo-website/Updated_Logo_White_4_drxvnq.png/>
    `

    return (() => {
      document.getElementById("socials-1").remove();
      document.getElementById("socials-2").remove();
      document.getElementById("socials-3").remove();
      document.getElementById("socials-4").remove();
      document.getElementById("socials-5").remove();
      document.getElementById("socials-6").remove();
    })
  }, [])

  function goToTopHandler() {
    return window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }

  const scrollToTop = () => {
    return window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  return (
    <div className="socials">

      <Helmet>
        <title>Join our Community of Pet Parents - Petmojo</title>
        <meta name="description" content="A community for all the pet parents out there. A safe space to share and learn about your little buddies from experts and experienced pet parents." />
      </Helmet>

      {!changeView ? <NavigationBarSocial /> : null}

      {
        !changeView ? (
          <div>

            <div className="social-page-mobile-section-main-header">Our App Just Gets Pretty From Here...</div>
            <hr className="vector2-social-mobile"></hr>

            <div className="social-page-mobile-section-container">
              <div className="social-page-mobile-section">
                <img alt="socialImage" className="social-page-mobile-section-image" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8896_odcqvx.png" />
              </div>
              <div className="social-page-mobile-cursive-header">
                Express Love
              </div>
              <div className="social-page-mobile-cursive-content">
                Create your pet's profile on our app and share your joy with others.
              </div>
            </div>

            <div className="social-page-mobile-section-container">
              <div className="social-page-mobile-section">
                <img alt="socialImage" className="social-page-mobile-section-image" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8899_ykry61.png" />
              </div>
              <div className="social-page-mobile-cursive-header">
                Capture the happiness
              </div>
              <div className="social-page-mobile-cursive-content">
                Post memorable photos and videos of your pet buddy and explore your bond with your little angel in new ways.
              </div>
            </div>

            <div className="social-page-mobile-section-container">
              <div className="social-page-mobile-section">
                <img alt="socialImage" className="social-page-mobile-section-image" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8902_umlyak.png" />
              </div>
              <div className="social-page-mobile-cursive-header">
                Explore the community
              </div>
              <div className="social-page-mobile-cursive-content">
                Find play buddies for your pet and spot other Guardians taking care of strays in your neighborhood.
              </div>
            </div>

            <div className="social-page-mobile-section-container">
              <div className="social-page-mobile-section">
                <img alt="socialImage" className="social-page-mobile-section-image" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8904_nmv7mx.png" />
              </div>
              <div className="social-page-mobile-cursive-header">
                Explore the community
              </div>
              <div className="social-page-mobile-cursive-content">
                Find play buddies for your pet and spot other Guardians taking care of strays in your neighborhood.
              </div>
            </div>

            <div className="social-page-mobile-section-container">
              <div className="social-page-mobile-section">
                <img alt="socialImage" className="social-page-mobile-section-image" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8899_ypatal.png" />
              </div>
              <div className="social-page-mobile-cursive-header">
                Create business profiles
              </div>
              <div className="social-page-mobile-cursive-content">
                You can use our global platform to create powerful content for your business that stands out and monetise your platform!
              </div>
            </div>

            <div className="socials-page-mobile-view-carousel">
              <div className="social-page-mobile-view-header">Sweet Inspirations</div>
              <hr className="vector2"></hr>
              <div className="socials-page-mobile-view-subhead">Indulge in our latest tips and more!</div>
              <div className="social-page-slider-master">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className="social-page-slider-container" id="scrollable-div-mobile">
                    <div className="social-page-mobile-view-card-container">
                      <div className="social-page-mobile-view-card-image">
                        <img alt="cardImage" className="social-page-mobile-view-card-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Rectangle_902_lzvtfs.png" />
                      </div>
                      <div className="social-page-mobile-blog-text"></div>
                      <div className="social-page-mobile-view-card-title">Mental stimulation games for dogs.</div>
                      <div className="social-page-mobile-view-card-read"><Link to="/blog">Read More</Link></div>
                    </div>
                    <div className="social-page-mobile-view-card-container">
                      <div className="social-page-mobile-view-card-image">
                        <img alt="cardImage" className="social-page-mobile-view-card-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/socials-dog-2.png" />
                      </div>
                      <div className="social-page-mobile-view-card-title">How To Deal With Impatient Dog.</div>
                      <div className="social-page-mobile-view-card-read"><Link to="/blog">Read More</Link></div>
                    </div>
                  </div>
                  <div style={{ width: "100%", justifyContent: "center" }}>
                    {/* <Slider min={0} max={100} step={50} value={valueMobile} tooltip={false} onChange={handleChangeMobile} /> */}
                    {/* <center style={{}}>
                <Loader color="#ff5e95" />
                </center> */}
                  </div>
                </div>
              </div>
            </div>


            <div>
              <div className="textbox2 pink-container-family">We’ll Make Your Pet Smile</div>
              <hr className="vector2"></hr>
            </div>
            <div className="heroImage1Container">
              <div className="heroImage1">
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8580_hjildd.png"
                  alt="makepetsmile"
                  className="make-pet-smile"
                ></img>
                <div className='petmojo-partner-mobile-view-name'>Petmojo Partner - <span className='petmojo-partner-mobile-view-name-cursive'>Kartik Rai</span></div>
              </div>
            </div>

            <div className="socials-page-mobile-btn">
              <div className="bookingbutton">
                <a href="https://play.google.com/store/apps/details?id=in.tamely.user">
                  <button className="booksession-mobile-view">Download Our App</button>
                </a>
              </div>
            </div>


            <div>
              <div className="textbox2">Social Media Links</div>
              <hr className="vector2"></hr>

              <div className="ellipse-container">
                <div className="ellipse">
                  {/* <LazyLoad> */}
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/outer-ring.png"
                    alt="outerring"
                    className="outer-ring"
                  ></img>
                  {/* </LazyLoad> */}
                  {/* <LazyLoad> */}
                  <img src={"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/inner-circle.png"} alt="innerring" className="inner-ring"></img>
                  {/* </LazyLoad> */}
                  <button className="youtube-icon">
                    <a
                      href="https://youtube.com/channel/UCPQrCllizVvTOkSk7_1SIHw"
                      target="_blank"
                      rel="nofollow"
                    >
                      {/* <LazyLoad> */}
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/yt-icon.png"
                        alt="youtubeicon"
                      ></img>
                      {/* </LazyLoad> */}
                    </a>
                  </button>
                  <button className="facebook-icon">
                    <a
                      href="https://www.facebook.com/petmojo.care/"
                      target="_blank"
                      rel="nofollow"
                    >
                      {/* <LazyLoad> */}
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/fb-icoc.png"
                        alt="facebookicon"
                      ></img>
                      {/* </LazyLoad> */}
                    </a>
                  </button>
                  <button className="twitter-icon">
                    <a href="https://twitter.com/Petmojo_App" target="_blank" rel="nofollow">
                      {/* <LazyLoad> */}
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/twitter-icon.png"
                        alt="twittericon"
                      ></img>
                      {/* </LazyLoad> */}
                    </a>
                  </button>
                  <button className="instagram-icon">
                    <a
                      href="https://www.instagram.com/petmojo.care/"
                      target="_blank"
                      rel="nofollow"
                    >
                      {/* <LazyLoad> */}
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/insta-icon.png"
                        alt="instagramicon"
                      ></img>
                      {/* </LazyLoad> */}
                    </a>
                  </button>
                  <button className="phone-icon">
                    <a href="tel:+91 90090 04473" target="_blank" rel="nofollow">
                      {/* <LazyLoad> */}
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/phone-icon.png"
                        alt="phoneicon"
                      ></img>
                      {/* </LazyLoad> */}
                    </a>
                  </button>
                  <button className="gmail-icon">
                    <a
                      href="mailto: customercare@petmojo.in"
                      target="_blank"
                      rel="nofollow"
                    >
                      {/* <LazyLoad> */}
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/gm-icon.png"
                        alt="gmailicon"
                      ></img>
                      {/* </LazyLoad> */}
                    </a>
                  </button>
                  <button className="whatsapp-icon">
                    <a href="tel:+91 90090 04473" target="_blank" rel="nofollow">
                      {/* <LazyLoad> */}
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/whatsapp-icon.png"
                        alt="whatsappicon"
                      ></img>
                      {/* </LazyLoad> */}
                    </a>
                  </button>
                </div>
              </div>

            </div>


            <div>
              <div className='mobile-view-footer-1'>
                <div className='mobile-view-footer-1-left'>
                  <div className='mobile-view-footer-1-left-header-container'>
                    <div className='mobile-view-footer-1-header'>Need Help</div>
                    <hr className='vector2-footer'></hr>
                  </div>
                  <div className='mobile-view-footer-1-content-left'>
                    <div className='mobile-view-footer-phone-container'>
                      <div className='mobile-view-footer-phone-icon'><img alt="telIcon" className="mobile-view-tel-icon" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-phone-icon.png" /></div>
                      <div className=''><a className='mobile-view-footer-phone-text' style={{ textDecoration: "none" }} href="tel: +91 8889991800" target="_blank" rel="nofollow">8889991800</a></div>
                    </div>
                    <div className='mobile-view-footer-mail-container'>
                      <div className='mobile-view-footer-mail-icon'><img alt="mailIcon" className='mobile-view-mail-icon' src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-mail-icon.png" /></div>
                      <div className=''><a className='mobile-view-footer-mail-text' style={{ textDecoration: "none" }} href="mailto: customercare@petmojo.in" target="_blank" rel="nofollow">customercare@petmojo.in</a></div>
                    </div>
                  </div>
                </div>
                <div className='mobile-view-footer-1-right'>
                  <div className='mobile-view-footer-1-header'>Services</div>
                  <hr className='vector2-footer'></hr>
                  <div className='mobile-view-footer-1-right-content'><Link className='mobile-view-footer-page-link' style={{ textDecoration: "none" }} to="/dog-running" state={{ from: "socials" }}>Dog Exercise/Walking</Link></div>
                  <div className='mobile-view-footer-1-right-content'><Link className='mobile-view-footer-page-link' style={{ textDecoration: "none" }} to="/dog-training" state={{ from: "socials" }}>Dog Training</Link></div>
                  <div className='mobile-view-footer-1-right-content'><Link className='mobile-view-footer-page-link' style={{ textDecoration: "none" }} to="/grooming" state={{ from: "socials" }}>Pet Grooming</Link></div>
                  <div className='mobile-view-footer-1-right-content'><Link className='mobile-view-footer-page-link' style={{ textDecoration: "none" }} to="/pet-boarding" state={{ from: "socials" }}>Pet Hotel</Link></div>
                  {/* <div className='mobile-view-footer-1-right-content'><Link className='mobile-view-footer-page-link' style={{textDecoration: "none"}} to="/styling" state={{from : "socials"}}>Pet Styling</Link></div> */}
                </div>
              </div>

              <div className='mobile-view-footer-2'>
                <div className='mobile-view-footer-2-left'>
                  <div className="footerDetailsHeading-mobile">Serving In</div>
                  <hr className="footerDetailsLine"></hr>
                  <div className="footerDetailsContentContainer-mobile">
                    <div>
                      <div className="footerDetailsLink-mobile">Delhi</div>
                      <div className="footerDetailsLink-mobile">Gurgaon</div>
                      <div className="footerDetailsLink-mobile">Noida</div>
                      <div className="footerDetailsLink-mobile">Ghaziabad</div>
                    </div>
                    <div>
                      <div className="footerDetailsLink-mobile">Faridabad</div>
                      <div className="footerDetailsLink-mobile">Manesar</div>
                      <div className="footerDetailsLink-mobile">Mumbai</div>
                    </div>
                  </div>
                </div>
                <div className='mobile-view-footer-2-right'>
                  <div className='mobile-view-footer-2-right-header'>More</div>
                  <hr className='vector2-footer'></hr>
                  <div className="mobile-view-links-div">
                    <Link style={{ textDecoration: "none", color: "rgba(132, 132, 132, 1)" }} to="/terms"><div className='mobile-view-footer-2-tnc'>Terms and Conditions</div></Link>
                    <Link style={{ textDecoration: "none", color: "rgba(132, 132, 132, 1)" }} to="/privacy"><div className='mobile-view-footer-2-tnc'>Privacy Policy</div></Link>
                    <Link style={{ textDecoration: "none", color: "rgba(132, 132, 132, 1)" }} to="/partner" ><div className='mobile-view-footer-2-left-content'>Become a Partner</div></Link>              <div className='mobile-view-footer-2-left-content'>Privacy Policy - Partner</div>
                    <Link style={{ textDecoration: "none", color: "rgba(132, 132, 132, 1)" }} to="/customer-support-policy" ><div className='mobile-view-footer-2-left-content'>Customer Support Policy</div></Link>
                    <Link style={{ textDecoration: "none", color: "rgba(132, 132, 132, 1)" }} to="/about" ><div className='mobile-view-footer-2-left-content'>About Us</div></Link>
                  </div>
                </div>
              </div>

              <div className="mobile-footer-wrap" ref={sectionOneRef}>

                <div className='mobile-view-footer-3' style={{ gap: "5px" }}>
                  <div className='mobile-view-footer-3-btn1' style={{ height: "33px" }}>
                    <a href="https://apps.apple.com/in/app/petmojo/id1609861328" target="_blank">

                      <img className='mobile-view-footer-appStore-icon ' id="section1" alt="appStore" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/app-store-2.png" rel="nofollow" />
                    </a>
                  </div>
                  <div className='mobile-view-footer-3-btn2' style={{ height: "33px" }}>
                    <a href=" https://play.google.com/store/apps/details?id=in.tamely.user" target="_blank">

                      <img className='mobile-view-footer-appStore-icon ' id="section2" alt="playStore" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/play-store-2.png" rel="nofollow" />
                    </a>
                  </div>
                  <div onClick={() => goToTopHandler()} className='mobile-view-footer-3-btn3' style={{ height: "33px", padding: "5px", display: "flex", justifyContent: "center" }}>
                    {/* <div>
                Go to the top
              </div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
                </svg>
              </div> */}
                    <span>Go to the top</span>
                    <BsArrowUp />
                  </div>
                </div>
              </div>

              <div className='mobile-view-footer-4' style={{ marginBottom: "20px" }}>
                <div>Copyright 2024 Petmojo</div>
                <div>All copyrights are reserved - any infringement will be legally pursued.</div>
                <div><br></br><br></br><br></br><br></br><br></br><br></br></div>
              </div>

            </div>
          </div>
        ) : (
          <div className="socials-desktop">
            <IntroContainerDesktopSocials />

            <div className="desktop-head-line-combiner">
              <div className="socials-page-desktop-view-heading">
                Our App Just Gets Pretty From Here...
              </div>
              <div className="desktop-view-heading-line">
                <hr className="desktop-view-pink-line"></hr>
              </div>
            </div>

            <div className="socials-page-desktop-view-scrap-container">
              <div className="socials-page-desktop-view-scrap-left">
                <div className="socials-page-desktop-view-scrap-left-head">Express Love</div>
                <div className="socials-page-desktop-view-scrap-left-content">
                  Create your pet's profile on our app and share your joy with others.
                </div>
              </div>
              <div className="socials-page-desktop-view-scrap-right">
                <img alt="cardImage" className="socials-page-desktop-view-card" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/socials-card-1.png" />
              </div>
            </div>

            <div className="socials-page-desktop-view-scrap-container">
              <div className="socials-page-desktop-view-scrap-right">
                <img alt="cardImage" className="socials-page-desktop-view-card" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8899_jnp2ms.png" />
              </div>
              <div className="socials-page-desktop-view-scrap-left">
                <div className="socials-page-desktop-view-scrap-left-head"> Capture the happiness</div>
                <div className="socials-page-desktop-view-scrap-left-content">
                  Post memorable photos and videos of your pet buddy and explore your bond with your little angel in new ways.              </div>
              </div>
            </div>

            <div className="socials-page-desktop-view-scrap-container">
              <div className="socials-page-desktop-view-scrap-left">
                <div className="socials-page-desktop-view-scrap-left-head">Explore the community</div>
                <div className="socials-page-desktop-view-scrap-left-content">
                  Find play buddies for your pet and spot other Guardians taking care of strays in your neighborhood.
                </div>
              </div>
              <div className="socials-page-desktop-view-scrap-right">
                <img alt="cardImage" className="socials-page-desktop-view-card" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8902_y74pdu.png" />
              </div>
            </div>

            <div className="socials-page-desktop-view-scrap-container">
              <div className="socials-page-desktop-view-scrap-right">
                <img alt="cardImage" className="socials-page-desktop-view-card" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8899_copy_tsovgq.png" />
              </div>
              <div className="socials-page-desktop-view-scrap-left">
                <div className="socials-page-desktop-view-scrap-left-head">Connect like never before!</div>
                <div className="socials-page-desktop-view-scrap-left-content">
                  Follow the Groups you are interested in and ask questions or discuss the topics that are important to you.
                </div>
              </div>
            </div>

            <div className="socials-page-desktop-view-scrap-container">
              <div className="socials-page-desktop-view-scrap-left">
                <div className="socials-page-desktop-view-scrap-left-head">Create business profiles</div>
                <div className="socials-page-desktop-view-scrap-left-content">
                  You can use our global platform to create powerful content for your business that stands out and monetise your platform!
                </div>
              </div>
              <div className="socials-page-desktop-view-scrap-right">
                <img alt="cardImage" className="socials-page-desktop-view-card" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8905_j3wsmj.png" />
              </div>
            </div>

            <div className="socials-page-desktop-view-sweet-inspirations">
              <div className="dog-container-desktop">
                <div className="dog-slide-desktop-grooming">

                  <div className="grooming-page-behave-box1">
                    <div>Guide To Pet Parenting</div>
                  </div>
                  <div className="line-div">
                    <hr className="live-training-pink-line"></hr>
                  </div>
                  <div className="grooming-page-behave-box2">
                    <div>A Professionally Trained Dog is </div>
                    <div>well behaved and Happy.</div>
                    <div>Read our Dog Training blogs for more.</div>
                  </div>
                  <div className="training-page-behave-box3">We Can <span className="pink">Help!</span></div>

                </div>
                <div className="dog-box-desktop-training">

                  <div className="educational-content-card-training">
                    <div className="educational-content-card-training-upper">
                      <img className="educational-content-card-training-image" alt="eduCard" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/road-trip-small.png" />
                      <div className="educational-content-card-training-text">Blog</div>
                      <div className="educational-content-card-training-head">Road Tripping With Your Dog</div>
                    </div>
                    <div className="educational-content-card-training-lower">
                      <div className=""><Link className="educational-content-card-training-link" to="/blog">Read More</Link></div>
                    </div>
                  </div>

                  <div className="educational-content-card-training">
                    <div className="educational-content-card-training-upper">
                      <img className="educational-content-card-training-image" alt="eduCard" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/dsc_bfynwn.png" />
                      <div className="educational-content-card-training-text">Blog</div>
                      <div className="educational-content-card-training-head">Positive Pet Rewards</div>
                    </div>
                    <div className="educational-content-card-training-lower">
                      <div className=""><Link className="educational-content-card-training-link" to="/blog">Read More</Link></div>
                    </div>
                  </div>

                  <div className="educational-content-card-training">
                    <div className="educational-content-card-training-upper">
                      <img className="educational-content-card-training-image" alt="eduCard" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Rectangle_1123_gsnp7b.png" />
                      <div className="educational-content-card-training-text">Blog</div>
                      <div className="educational-content-card-training-head">Pet Separation Anxiety</div>
                    </div>
                    <div className="educational-content-card-training-lower">
                      <div className=""><Link className="educational-content-card-training-link" to="/blog">Read More</Link></div>
                    </div>
                  </div>

                  <div className="educational-content-card-training">
                    <div className="educational-content-card-training-upper">
                      <img className="educational-content-card-training-image" alt="eduCard" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Rectangle_1123_mxiu2p.png" />
                      <div className="educational-content-card-training-text">Blog</div>
                      <div className="educational-content-card-training-head">Guide to Pet Foods</div>
                    </div>
                    <div className="educational-content-card-training-lower">
                      <div className=""><Link className="educational-content-card-training-link" to="/blog">Read More</Link></div>
                    </div>
                  </div>

                  <div className="educational-content-card-training">
                    <div className="educational-content-card-training-upper">
                      <img className="educational-content-card-training-image" alt="eduCard" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Rectangle_1123_cu81jw.png" />
                      <div className="educational-content-card-training-text">Blog</div>
                      <div className="educational-content-card-training-head">Puppy and Teething</div>
                    </div>
                    <div className="educational-content-card-training-lower">
                      <div className=""><Link className="educational-content-card-training-link" to="/blog">Read More</Link></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="hero-image-container-desktop">
                <div className="hero-image-container-desktop-left">
                  <div className="pet-smile-home-set1">
                    <div>We'll Make Your</div>
                    <div>Pet Smile</div>
                    <div className="line-div">
                      <hr className="live-training-pink-line"></hr>
                    </div>
                  </div>
                  <div className="pet-smile-home-set2">
                    Your Pet Our Family!
                  </div>
                </div>
                <div className="hero-image-container-desktop-right">
                  <img className="pet-smile-img" alt="heroImg" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/desktop-petmojo-partner.webp" />
                  <div className='petmojo-partner-name-desktop'>Petmojo Partner - <span className='petmojo-partner-name-desktop-cursive'>Kartik Rai</span></div>
                </div>
              </div>
            </div>

            <div className="socials-page-desktop-view-connect">
              <div className="social-media-links-container">

                <div className="social-media-links-header">

                  <div className="social-media-links-text">
                    Connect With Us
                    <div className="line-div">
                      <hr className="live-training-pink-line"></hr>
                    </div>
                  </div>

                  <div className="home-page-walking-packages-desktop-container2">
                    <div>Connect on applications you use</div>
                    <div>daily to get Informative/Educational </div>
                    <div>contents on Petmojo.</div>
                  </div>




                </div>

                <div className="social-media-links-image">
                  <div className="ellipse-container">
                    <div className="ellipse-desktop">
                      {/* <LazyLoad> */}
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Ellipse_374_ijrumy.png"
                        alt="outerring"
                        className="outer-ring-desktop"
                      ></img>
                      {/* </LazyLoad> */}
                      {/* <LazyLoad> */}
                      <img src={"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Ellipse_373_iadrer.png"} alt="innerring" className="inner-ring-desktop"></img>
                      {/* </LazyLoad> */}
                      <button className="youtube-icon-desktop">
                        <a
                          href="https://youtube.com/channel/UCPQrCllizVvTOkSk7_1SIHw"
                          target="_blank"
                          rel="nofollow"
                        >
                          {/* <LazyLoad> */}
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/yt-icon.png"
                            alt="youtubeicon"
                            width="60px"
                            height="60px"
                          ></img>
                          {/* </LazyLoad> */}
                        </a>
                      </button>
                      <button className="facebook-icon-desktop">
                        <a
                          href="https://www.facebook.com/petmojo.care/"
                          target="_blank"
                          rel="nofollow"
                        >
                          {/* <LazyLoad> */}
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/fb-desktop.png"
                            alt="facebookicon"
                            width="60px"
                            height="60px"
                          ></img>
                          {/* </LazyLoad> */}
                        </a>
                      </button>

                      <button className="instagram-icon-desktop">
                        <a
                          href="https://www.instagram.com/petmojo.care/"
                          target="_blank"
                          rel="nofollow"
                        >
                          {/* <LazyLoad> */}
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/insta-desktop.png"
                            alt="instagramicon"
                            width="60px"
                            height="60px"
                          ></img>
                          {/* </LazyLoad> */}
                        </a>
                      </button>
                      <button className="phone-icon-desktop">
                        <a href="tel:+91 8889991800" target="_blank" rel="nofollow">
                          {/* <LazyLoad> */}
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/phone-blue-desktop.png"
                            alt="phoneicon"
                            width="60px"
                            height="60px"
                          ></img>
                          {/* </LazyLoad> */}
                        </a>
                      </button>
                      <button className="gmail-icon-desktop">
                        <a
                          href="mailto: customercare@petmojo.in"
                          target="_blank"
                          rel="nofollow"
                        >
                          {/* <LazyLoad> */}
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/gmail-desktop.png"
                            alt="gmailicon"
                            width="60px"
                            height="60px"
                          ></img>
                          {/* </LazyLoad> */}
                        </a>
                      </button>
                      <button className="whatsapp-icon-desktop">
                        <a href="tel:+91 8889991800" target="_blank" rel="nofollow">
                          {/* <LazyLoad> */}
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/whatsapp-desktop.png"
                            alt="whatsappicon"
                            width="60px"
                            height="60px"
                          ></img>
                          {/* </LazyLoad> */}
                        </a>
                      </button>
                    </div>
                  </div>
                </div>

              </div>
            </div>


            <div>
              <div className="footerDetailsContainer">
                <div className="footerDetailsCont1">
                  <div className="footerDetailsHeading">Need Help</div>
                  <hr className="footerDetailsLine"></hr>
                  <div className="footerDetailsContentContainer">
                    <div className="footer-details-content-call-mail">
                      <div><img alt="icon" className="icon-desktop-call" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/more-contact-icon.png" /></div>
                      <div><a className="footerDetailsLink" target="_blank" rel="nofollow" href="tel: 8889991800">8889991800</a></div>
                    </div>

                    <div className="footer-details-content-call-mail">
                      <div><img alt="icon" className="icon-desktop-mail" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-mail-icon.png" /></div>
                      <div><a className="footerDetailsLink" target="_blank" rel="nofollow" href="mailto: customercare@petmojo.in">customercare@petmojo.in</a></div>
                    </div>

                  </div>
                </div>
                <div className="footerDetailsCont1">
                  <div className="footerDetailsHeading">Services</div>
                  <hr className="footerDetailsLine"></hr>
                  <div className="footerDetailsContentContainer">
                    <Link className="footerDetailsLink" to="/dog-running" state={{ from: "socials" }}><div>Daily Dog Exercise/Walking</div></Link>
                    <Link className="footerDetailsLink" to="/dog-training" state={{ from: "socials" }}><div>Dog Training</div></Link>
                    <Link className="footerDetailsLink" to="/cat-training" state={{ from: "socials" }}><div>Cat Training</div></Link>
                    <Link className="footerDetailsLink" to="/grooming" state={{ from: "socials" }}><div>Pet Grooming</div></Link>
                    <Link className="footerDetailsLink" to="/grooming-subscription" state={{ from: "socials" }}><div>Grooming Subscription</div></Link>
                    <Link className="footerDetailsLink" to="/pet-boarding" state={{ from: "socials" }}><div>Pet Hotel</div></Link>
                    {/* <Link className="footerDetailsLink" to="/styling" state={{from : "socials"}}><div>Pet Styling</div></Link> */}
                  </div>
                </div>
                <div className='footerDetailsContr1'>
                  <div className="footerDetailsHeading">Serving In</div>
                  <hr className="footerDetailsLine"></hr>
                  <div className="footerDetailsContentContainer">
                    <div className="footerDetailsLink">Delhi</div>
                    <div className="footerDetailsLink">Gurgaon</div>
                    <div className="footerDetailsLink">Noida</div>
                    <div className="footerDetailsLink">Ghaziabad</div>
                    <div className="footerDetailsLink">Faridabad</div>
                    <div className="footerDetailsLink">Manesar</div>
                    <div className="footerDetailsLink">Mumbai</div>
                  </div>
                </div>
                <div className="footerDetailsCont1">
                  <div className="footerDetailsHeading">More</div>
                  <hr className="footerDetailsLine"></hr>
                  <div className="footerDetailsContentContainer">
                    <Link className="footerDetailsLink" to="/partner"><div>Become a Partner</div></Link>
                    <Link className="footerDetailsLink" to="/terms"><div>Terms and Conditions</div></Link>
                    <Link className="footerDetailsLink" to="/privacy"><div>Privacy Policy</div></Link>
                    <Link className="footerDetailsLink" to="/"><div>Privacy Policy - Partner</div></Link>
                    <Link className="footerDetailsLink" to="/customer-support-policy"><div>Customer Support Policy</div></Link>
                    <Link className="footerDetailsLink" to="/about"><div>About Us</div></Link>
                  </div>
                </div>
              </div>

              <div>
                <div className="desktop-footer-buttons-container-add" style={{ display: "flex", flexDirection: "column" }}>
                  <p style={{ fontWeight: "800" }}>MANGOPAW TECHNOLOGIES AND SOLUTIONS PRIVATE LIMITED</p>
                  <p style={{ marginTop: "0rem" }}> Ph no- +918889991800</p>
                </div>
                <div className="desktop-footer-buttons-container">
                  <div className="desktop-footer-buttons-left">
                    <div className="desktop-footer-buttons-left-btn1">
                      <a href="https://play.google.com/store/apps/details?id=in.tamely.user" target="_blank" rel="nofollow">

                        <img
                          alt="icon"
                          className="desktop-footer-buttons-left-btn1-img"
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/play-store-download.png"
                        />
                      </a>
                    </div>
                    <div className="desktop-footer-buttons-left-btn1">
                      <a href="https://apps.apple.com/in/app/petmojo/id1609861328" target="_blank" rel="nofollow">

                        <img
                          alt="icon"
                          className="desktop-footer-buttons-left-btn1-img"
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/app-store-download.png"
                        />
                      </a>
                    </div>
                    <div
                      onClick={scrollToTop}
                      className="desktop-footer-buttons-left-btn3"
                    >
                      <div>Go at the top</div>

                      <svg
                        className="top-arrow"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-arrow-up"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                        />
                      </svg>
                    </div>
                  </div>

                  <div className="desktop-footer-buttons-right">
                    Copyright 2024 Petmojo
                  </div>
                </div>
              </div>
            </div>

          </div>
        )
      }


      <GoToTop />
    </div>
  );
}

export default Socials;
