import { BiArrowBack } from "@react-icons/all-files/bi/BiArrowBack";
import { FaArrowAltCircleRight } from "@react-icons/all-files/fa/FaArrowAltCircleRight";
import { FaCheck } from "@react-icons/all-files/fa/FaCheck";
import { MdClose } from "@react-icons/all-files/md/MdClose";
import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { useGeolocated } from "react-geolocated";
import LazyLoad from "react-lazyload";
import { useLocation, useNavigate } from "react-router-dom";
import appleStore from "../../../images/app-store.png";
import downloadInvoice from "../../../images/download-invoice.png";
import greenTick from "../../../images/green-tick.png";
import groomingvector from "../../../images/grooming-vector.jpg";
import NoVan from "../../../images/NoVan.png";
import googlePlay from "../../../images/play-store.png";
import ImageSliderWalking from "../../ImageSliderWalking";
import LoadingSpinner from "../../loadingSpinner/LoadingSpinner";
import "./MobileFormsCSS.css";



const PackageToAmountMapping = {
  'Bath and Brush': 799,
  'Bath & Brush(with tick treatment)': 1199,
  'Haircut and Styling': 1249,
  'Bath, Full haircut & Styling': 1649,
  'Massage, Bath, Full haircut & Styling': 1949
}


const images = [
  {
    id: 1,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-mobile-1.webp",
    alt: "Image 1",
  },
  {
    id: 2,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-mobile-2.webp",
    alt: "Image 2",
  },
  {
    id: 3,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-mobile-3.webp",
    alt: "Image 3",
  },
  {
    id: 4,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-mobile-4.webp",
    alt: "Image 4",
  },
  {
    id: 5,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-mobile-5.webp",
    alt: "Image 5",
  },
  {
    id: 6,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-mobile-6.webp",
    alt: "Image 6",
  },
  {
    id: 7,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-mobile-7.webp",
    alt: "Image 7",
  },
  {
    id: 8,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-mobile-8.webp",
    alt: "Image 8",
  },
];

function useGetLocation() {
  const [location, setLocation] = useState(null);

  const { latitude, longitude } = useGeolocated();
  setLocation({ latitude, longitude });
  // useEffect(() => {
  // }, []);

  return location;
}

const GroomingPageForm = (props) => {

  const urlParams = new URLSearchParams(window.location.search);
  const urlSearchString = window.location.search || "";
  const name = urlParams.get("name") || "";
  const number = urlParams.get("number") || "";
  const groomingAddress = urlParams.get("address") || "";
  const amount = urlParams.get("amount") || "";
  const groomingDate = urlParams.get("date") || "";
  const packageType = urlParams.get("package") || "";
  const time = urlParams.get("time") || "";

  const [otp, setOtp] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [couponValue, setCouponValue] = useState(0);
  const [couponErrorMsg, setCouponErrorMsg] = useState("");
  const [couponNotValid, setCouponNotValid] = useState(false);
  const [couponValid, setCouponValid] = useState(false);
  const [bookNow, setBookNow] = useState(false);
  const otpRef = useRef(null);
  const navigate = useNavigate();
  const [sessionDate, setSessionDate] = useState();
  const location = useLocation().pathname;
  const [seconds, setSeconds] = useState(0);
  // console.log("location: ",location.pathname)
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCarousel, setSelectedCarousel] = useState(name && number && packageType && time && groomingDate ? 3 : 1);
  const [correctOtp, setCorrectOtp] = useState(true);
  const groomingPhoneRef = useRef(0);
  const groomingAddressRef = useRef("");
  const groomingAddressRef1 = useRef("");
  const groomingDateRef = useRef("");
  const groomingSlotRef = useRef("");
  const groomingOtpRef = useRef("");
  const [countdown, setCountdown] = useState(true);
  const [startValue, setStartValue] = useState(groomingDate ? true : false);
  const [startDate, setStartDate] = useState(groomingDate || "");
  const [nameValue, setnameValue] = useState(name ? true : false);
  const [nameFill, setNameFill] = useState(name || "");
  const [phoneValue, setPhoneValue] = useState(number ? true : false);
  const [phoneFill, setPhoneFill] = useState(number || null);
  const [addressValue, setAddressValue] = useState(false);
  const [adressFill, setAdressFill] = useState("");
  const [slotValue, setSlotValue] = useState(time ? true : false);
  const [slotFill, setSlotFill] = useState(time || "");
  const [price1, setPrice] = useState(PackageToAmountMapping[packageType] || 799);
  // const groomingEmailRef = useRef("");
  const groomingNameRef = useRef("");
  const [inputDate, setInputDate] = useState("");
  const [inputSlot, setInputSlot] = useState("");
  const [copySuccess, setCopySuccess] = useState("");
  const [lat, setLat] = useState(0);
  const [lon, setLon] = useState(0);
  const [copyMessage, setCopyMessage] = useState("");
  const [userData, setUserData] = useState({
    Pet: "Dog",
    Service: packageType || "",
    amount: PackageToAmountMapping[packageType] || 0,
    Date: groomingDate || "",
    Slot: time || "",
    Name: name || "",
    Phone: number || "",
    Address: groomingAddress || "",
    Latitude: 0,
    Longitude: 0,
  });

  const [address, setAddress] = useState(groomingAddress || "");
  const [selectedService, setSelectedService] = useState("Bath and Brush");
  const [selectedPet, setSelectedPet] = useState("Dog");
  const [serviceDropdown, setServiceDropdown] = useState(false);
  const baseUrl =
    "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api";

  const [groomingPhoneValidation, setGroomingPhoneValidation] = useState(true);
  const [groomingAddressValidation, setGroomingAddressValidation] =
    useState(true);
  const [groomingAddressValidation1, setGroomingAddressValidation1] =
    useState(true);

  const [groomingDateValidation, setGroomingDateValidation] = useState(true);
  const [groomingSlotValidation, setGroomingSlotValidation] = useState(true);
  const [groomingNameValidation, setGroomingNameValidation] = useState(true);
  const [groomingLoading, setGroomingLoading] = useState(false);
  const [selectedChoice, setSelectedChoice] = useState(packageType === "Bath and Brush"
    ? 1
    : packageType === "Bath & Brush(with tick treatment)"
      ? 2
      : packageType === "Haircut and Styling"
        ? 3
        : packageType === "Bath, Full haircut & Styling"
          ? 4
          : packageType === "Massage, Bath, Full haircut & Styling"
            ? 5
            : 1);
  const [selectedPetChoice, setSelectedPetChoice] = useState(1);
  const [navTab, setNavTab] = useState(1);
  const [formSection, setFormSection] = useState(1);
  const [showEnterOtp, setShowEnterOtp] = useState(false);
  const [showDownloadInvoice, setShowDownloadInvoice] = useState(null);
  const [isVerified, setIsVerified] = useState(null);
  const [isWrongOtp, setIsWrongOtp] = useState(false);
  const [razorpayOrderId, setRazorpayOrderId] = useState("");
  const [bookingId, setBookingId] = useState("");
  const [token, setToken] = useState("");
  const [phone, setPhone] = useState("");
  const sectionRef = useRef(null);
  function scrollToSection() {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  }
  var currentDate = new Date();
  var year = currentDate.getFullYear();
  var month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
  var day = ("0" + currentDate.getDate()).slice(-2);
  var formattedDate = year + "-" + month + "-" + day;
  function setSelectedServiceHandler(service) {
    setSelectedService(service);
    setServiceDropdown(false);
  }
  const groomingFirstFormHandler = () => {
    // if(nameFill==""){
    //   setGroomingNameValidation(false);
    // }
    // if(phoneFill==null || phoneFill==""){
    //   setGroomingPhoneValidation(false);
    // }
    // else{
    //   setSelectedCarousel(2);
    // }


    if (selectedChoice !== 0) setSelectedCarousel(2);
  };
  const firstFormDataHandler = () => {
    let groomingDateRefCurrValue = groomingDateRef.current.value;
    if (
      groomingDateRefCurrValue !== "" &&
      groomingSlotRef.current.value !== ""
    ) {
      setInputDate(groomingDateRefCurrValue);
      setInputSlot(groomingSlotRef.current.value);

      setUserData({
        ...userData,
        Pet: selectedPetChoice === 1 ? "Dog" : "Cat",
        Service:
          selectedChoice === 1
            ? "Bath and Brush"
            : selectedChoice === 2
              ? "Bath & Brush(with tick treatment)"
              : selectedChoice === 3
                ? "Haircut and Styling"
                : selectedChoice === 4
                  ? "Bath, Full haircut & Styling"
                  : "Massage, Bath, Full haircut & Styling",
        amount:
          selectedChoice === 1
            ? location === "/grooming"
              ? 799
              : 799
            : selectedChoice === 2
              ? 1199
              : selectedChoice === 3
                ? 1249
                : selectedChoice === 4
                  ? 1649
                  : 1949,
        Date: groomingDateRefCurrValue,
        Slot: groomingSlotRef.current.value,
        Latitude: lat,
        Longitude: lon,
      });
      //   setSelectedCarousel(2);
    } else {
      console.log("data coll err");
    }
  };
  const secondFormDataHandler = () => {
    if (
      groomingNameRef.current.value !== "" &&
      checkPhone(groomingPhoneRef.current.value.toString()).length === 10 &&
      groomingAddressRef.current.value !== ""
    ) {
      setUserData({
        ...userData,
        Name: groomingNameRef.current.value,
        Phone: phone,
        Address: groomingAddressRef.current.value,
      });

      //   setSelectedCarousel(3);
    } else {
      console.log("data col err");
    }
  };
  const [showDialog, setShowDialog] = useState(false);
  const checkPhone = (phoneString) => {
    if (
      phoneString.length > 10 &&
      (phoneString.startsWith("+91") || phoneString.startsWith("91"))
    ) {
      phoneString = phoneString.slice(-10);
      setPhone(phoneString);
      setShowDialog(true);
    }
    return phoneString;
  };
  const nextNavhandler = () => {
    let groomingDateRefCurrValue = groomingDateRef.current.value;
    if (
      groomingDateRefCurrValue !== "" &&
      groomingSlotRef.current.value !== "" &&
      nameFill != "" &&
      (phoneFill != null || phoneFill != "")
    ) {
      console.log(userData);
      setSelectedCarousel(3);
      // setFormSection(3);
    } else if (nameFill == "") {
      setGroomingNameValidation(false);
    } else if (phoneFill == null || phoneFill == "") {
      setGroomingPhoneValidation(false);
    } else if (groomingDateRefCurrValue === "") {
      console.log("error");
      setGroomingDateValidation(false);
    } else if (groomingSlotRef.current.value === "") {
      console.log("error");
      setGroomingSlotValidation(false);
    }

    if (
      groomingDateRefCurrValue === "" &&
      groomingSlotRef.current.value === ""
    ) {
      console.log("error");
      setGroomingSlotValidation(false);
      setGroomingDateValidation(false);
    }
  };

  function copyToClipboard(text) {
    text.select();
    document.execCommand("copy");
    text.focus();
    setCopySuccess("Copied!");
  }

  function copiedMessage() {
    setCopyMessage("Copied!");

    setTimeout(() => {
      setCopyMessage("");
    }, 2000);
  }
  const getmobileOtp = useCallback(async () => {
    try {
      let phoneString = checkPhone(groomingPhoneRef.current.value.toString());
      const res = await axios.post(`${baseUrl}/auth/sendMobileOTP`, {
        phoneNumber: phoneString,
      });
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  }, []);
  const changeName = () => {
    document.getElementById("send-otp").innerHTML = "Resend OTP";
  };

  const verifyMobileOTP = useCallback(async () => {
    try {
      let phoneString = checkPhone(groomingPhoneRef.current.value.toString());
      const res = await axios.post(
        "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/auth/verifyMobileOTP",
        {
          otp: groomingOtpRef.current.value.toString(),
          phoneNumber: phoneString,
          type: "User",
        }
      );
      console.log(res.status);
      console.log(res.data);

      if (res.status === 200) {
        setIsVerified(true);
        setCorrectOtp(true);
        setToken(res.data.token);
        setCountdown(false);
      }
    } catch (error) {
      console.log(error.response.status);
      console.log(error);
      if (error.response.status === 400) {
        setIsVerified(false);
        setCorrectOtp(false);
        setIsWrongOtp(true);
      }
    }
  }, []);
  const indicateAlert = () => {
    if (!correctOtp) {
      document.getElementById("send-otp").style.borderColor = "red";
    }
    // else{

    // }
  };

  // const displayLocation=()=>{
  //   console.log("Latitude:",coords.latitude);
  //   console.log("Longitude:",coords.longitude);
  //   console.log(address)
  // }
  // const removeAlert=()=>{
  //   if(correctOtp){
  //     document.getElementById("send-otp").style.borderColor="none"
  //   }
  // }
  const decrementSeconds = () => {
    setTimeout(() => {
      if (seconds == 0) {
        // document.getElementById("countdown-seconds").style?.display = "none";
      } else {
        setSeconds(seconds - 1);
      }
    }, 1000);
  };
  const handleOtpChange = (event) => {
    const { value } = event.target;
    setOtp(value);
  };

  // function to handle the form submission
  const handleSubmit = (event) => {
    // event.preventDefault();
    console.log(`Submitting OTP: ${otp}`);
  };

  // function to handle the "Paste" event and autofill OTP
  const handlePaste = (event) => {
    // event.preventDefault();
    const pastedText = event.clipboardData.getData("Text");
    setOtp(pastedText);
  };
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay(
    serviceAmount,
    SelectedService,
    DateOfReg,
    inputPhone,
    InputAddress,
    bookingId
  ) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      setIsLoading(false);
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }



    // creating a new order
    const result = await axios.post(
      "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/generateGroomingRazorPayOrderId",
      {
        amount: serviceAmount,
      }
    );

    if (!result) {
      setIsLoading(false);
      alert("Server error. Are you online?");
      return;
    }

    // Getting the order details back
    const { amount, id: order_id, currency } = result.data;

    const options = {
      key: process.env.REACT_APP_KEY, // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: "Petmojo",
      description: "Pet Grooming Payment",
      //image: { logo },
      order_id: order_id,
      handler: async function (response) {

        const Data = {
          orderId: order_id,
          SelectedService,
          DateOfReg,
          InputAddress,
        };

        navigate("/ordersummary" + urlSearchString, { state: Data });

        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };

        const [result, storeResponse, patchResponse] = await Promise.all([
          axios.post(
            "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/generateGroomingRazorPaySuccess",
            data
          ),
          axios.post(
            "https://api.apispreadsheets.com/data/hq3CiEAYcPfNXIJE/",
            {
              Date: DateOfReg,
              Address: InputAddress,
              Contact_No: inputPhone.toString(),
              Amount_Paid: serviceAmount.toString(),
              Selected_Service: SelectedService,
              Order_id: order_id,
            }
          ),
          axios.patch(
            "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/service/postGroomingPayment",
            {
              bookingId: bookingId,
              transactionId: order_id,
              amount: serviceAmount,
              type: "User",
              isFromWebsite: true,
            },
            {
              "Content-Type": "application/json",
              headers: {
                Authorization:
                  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjYzZWY5ZWZkNTc1MGY5Y2Q4MmVmYmZmNyJ9.QOz1wBekeflAqD2ktWjDQLubM4snuMMDFV0Lk97z5UY",
              },
            }
          )
        ]);

        console.log(result, storeResponse, patchResponse);
        console.log("The grooming payment has been completed and info has been saved in the database!");

      },
      // prefill: {
      //     name: "Petmojo",
      //     email: "customercare@petmojo.in",
      //     contact: "+91 9009004473",
      // },
      notes: {
        address: "Petmojo",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    setIsLoading(false);
  }

  let UTM_Source, UTM_Medium, UTM_Campaign = "";

  UTM_Source = urlParams.get('utm_source');
  UTM_Medium = urlParams.get('amp;utm_medium');
  UTM_Campaign = urlParams.get('amp;utm_campaign')

  const finalFormSubmitHandler = async (flag) => {
    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${date.getMonth() + 1
      }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;

    if (
      nameFill !== "" &&
      checkPhone(phoneFill).length === 10 &&
      groomingAddressRef.current.value !== ""
    ) {
      // console.log(userData);
      // console.log(userData.Phone)
      const config = {
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
      };
      setIsLoading(true);
      try {
        // console.log("hey1")
        const phone =
          userData.Phone.length > 10 &&
            (userData.Phone.startsWith("+91") || userData.Phone.startsWith("91"))
            ? userData.Phone.slice(-10)
            : userData.Phone;
        // console.log(phone)
        setSelectedService(userData.Service);
        const res = await axios.post(
          `${baseUrl}/serviceBooking/websiteDogGroomingBookingService`,
          {
            fullName: userData.Name,
            address: address,
            phone: phone,
            startDate: userData.Date,
            sessionTime: userData.Slot,
            pet: userData.Pet,
            latitude: lat,
            longitude: lon,
            package: {
              description: userData.Service,
              amount: userData.amount - couponValue,
              frequency: 1,
            },
            isCouponCodeUsed: couponValue === 0 ? false : true,
            couponCode: couponValue !== 0 ? couponCode : "",
            type: "User",
            UTM_Source: UTM_Source || "",
            UTM_Medium: UTM_Medium || "",
            UTM_Campaign: UTM_Campaign || "",
          },
          config
        );
        // console.log("hey2")
        console.log(res.data);
        console.log(res.status);
        if (flag) {
          let UTM_Source,
            UTM_Medium,
            UTM_Campaign = "";

          const urlParams = new URLSearchParams(window.location.search);
          UTM_Source = urlParams.get("utm_source");
          UTM_Medium = urlParams.get("amp;utm_medium");
          UTM_Campaign = urlParams.get("amp;utm_campaign");

          setSelectedService(userData.Service);

          const data = {
            ContactNo: userData.Phone,
            Services: userData.Service,
            Date: userData.Date,
            Time: userData.Slot,
            Email: "",
            FormType: "Direct Form",
            DateOfRegistration: dateOfReg,
            TimeOfRegistration: timeOfReg,
            Address: userData.Address,
            Name: userData.Name,
            Pet: userData.Pet,
            latitude: lat,
            longitude: lon,
            Amount: userData.amount - couponValue,
            UTM_Source: UTM_Source || "",
            UTM_Medium: UTM_Medium || "",
            UTM_Campaign: UTM_Campaign || "",
          };
          data["paymentStatus"] = 1;

          setSelectedService(userData.Service);

          // await axios
          //   .post(
          //     "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/saveGroomingData",
          //     data
          //   )
          //   .then((response) => {
          //     console.log(response);
          //     console.log("The grooming form is being submitted...");
          //     setIsLoading(false);
          //   })
          //   .catch((err) => {
          //     console.log(err);
          //     console.log(
          //       "There was some error in submitting the grooming form..."
          //     );
          //     setIsLoading(false);
          //   });
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: `Grooming Registration Fired From Mobile Service Page Form`,
          });
          setShowDownloadInvoice(true);
          displayRazorpay(
            userData.amount - couponValue,
            userData.Service,
            dateOfReg,
            userData.Phone,
            userData.Address,
            res.data.bookingId
          );
        } else {
          // console.log("hello guys")
          let UTM_Source,
            UTM_Medium,
            UTM_Campaign = "";

          const urlParams = new URLSearchParams(window.location.search);
          UTM_Source = urlParams.get("utm_source");
          UTM_Medium = urlParams.get("amp;utm_medium");
          UTM_Campaign = urlParams.get("amp;utm_campaign");

          setSelectedService(userData.Service);
          const data = {
            ContactNo: userData.Phone,
            Services: userData.Service,
            Date: userData.Date,
            Time: userData.Slot,
            Email: "",
            FormType: "Direct Form",
            DateOfRegistration: dateOfReg,
            TimeOfRegistration: timeOfReg,
            Address: address,
            Name: userData.Name,
            Pet: userData.Pet,
            Amount: userData.amount - couponValue,
            Latitude: lat,
            Longitude: lon,
            UTM_Source: UTM_Source || "",
            UTM_Medium: UTM_Medium || "",
            UTM_Campaign: UTM_Campaign || "",
          };
          data["paymentStatus"] = 2;

          await axios
            .post(
              "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/saveGroomingData",
              data
            )
            .then((response) => {
              console.log(response);
              console.log("The grooming form is being submitted...");
            })
            .catch((err) => {
              console.log(err);
              console.log(
                "There was some error in submitting the grooming form..."
              );
            });
          setShowDownloadInvoice(false);
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: `Grooming Registration Fired From Mobile Service Page Form`,
          });
          setNavTab(2);
        }
      } catch (error) {
        console.log("error------->", error);
        setIsLoading(false);
      }
    } else if (nameFill === "") {
      console.log("err");
      setGroomingNameValidation(false);
    } else if (checkPhone(phoneFill).length !== 10) {
      console.log("err123");
      setGroomingPhoneValidation(false);
      alert("Please enter a valid phone number");
    } else if (groomingAddressRef.current.value === "") {
      console.log("err");
      setGroomingAddressValidation(false);
    }

  };
  const [changeColor, setChangeColor] = useState(false);
  const formShiftHandler = () => {
    window.scrollTo(0, 300);
  };

  const backArrow = () => {
    setSelectedCarousel(selectedCarousel - 1);
  };

  const changeButtonColor = (e) => {
    console.log(e);
    // const ele=document.getElementById("send-otp");
    // if(e.target.value.length()>=10){
    //   ele.style.backgroundColor="red";
    // }
  };

  useEffect(() => {
    indicateAlert();
  }, [correctOtp]);

  const applyMOJO = async () => {
    applyCoupon("MOJO5");
    setCouponCode("MOJO5");
  }

  const applyCoupon = async (cd) => {
    await axios
      .post(
        "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/websiteVerifyCoupon",
        {
          name: cd ?? couponCode,
          serviceType: 2,
          package: selectedService,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: token,
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response.data.value) {
          setCouponValue(response.data.value);
          setCouponErrorMsg("");
          setCouponNotValid(false);
          setCouponValid(true);
        } else {
          setCouponValid(false);
          setCouponNotValid(true);
          setCouponErrorMsg(response.data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const params = {};
    for (let param of searchParams.entries()) {
      params[param[0]] = param[1];
    }
    if (
      params["amp;utm_campaign"] === "Grooming 499" &&
      params["amp;utm_medium"] === "Whatsapp Campaign" &&
      params["utm_source"] === "Whatsapp"
    ) {
      setCouponCode("WLCMBK");
      applyCoupon("WLCMBK")
    }
  }, []);

  useEffect(() => {
    decrementSeconds();
  }, [seconds]);
  // useEffect(() => {
  //   if (coords) {
  //     const { latitude, longitude } = coords;
  //     const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCeoWpdd6YOVu3wEqqA0qpY6P3B5rUumm8`;

  //     fetch(url)
  //           .then(response => response.json())
  //           .then(data => {
  //             setAddress(data.results[0].formatted_address);
  //           })
  //           .catch(error => console.error(error));
  //       },
  //       (error) => {
  //         console.error(error);
  //       }
  //     );
  //   } else {
  //     console.error('Geolocation is not supported by this browser.');
  //   }
  // };

  // useEffect(() => {
  //   if (coords) {
  //     const { latitude, longitude } = coords;
  //     const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCeoWpdd6YOVu3wEqqA0qpY6P3B5rUumm8`;

  //     fetch(url)
  //       .then(response => response.json())
  //       .then(data => {
  //         const { results } = data;
  //         if (results && results.length > 0) {
  //           setAddress(results[0].formatted_address);
  //           // console.log(address)
  //         }
  //       })
  //       .catch(error => {
  //         console.log('Error:', error);
  //       });
  //   }
  // }, [coords]);
  const useLocation123 = () => {
    // const { coords } = useGeolocated();
    // console.log(coords);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        // setLocation({ latitude, longitude });
        console.log("asdf", latitude, longitude);
        setLat(latitude);
        setLon(longitude);
        // const result=getAddress(latitude,longitude);
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCeoWpdd6YOVu3wEqqA0qpY6P3B5rUumm8`;
        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            const { results } = data;
            if (results && results.length > 0) {
              setAddress(results[0].formatted_address);
              setUserData({
                ...userData,
                Address: results[0].formatted_address
              })
              setGroomingAddressValidation(true);
              // console.log(address)
            }
          })
          .catch((error) => {
            console.log("Error:", error);
          });
      },
      (error) => {
        console.log(error);
      }
    );
    // useEffect(()=>{
    //   if(address!=''){
    //     setGroomingAddressValidation(true);
    //   }
    // },[address])
    // const getAddress=async(latitude,longitude)=>{
    //   console.log("Latitude:",latitude);
    //   console.log("Longitude:",longitude);
    //   const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCeoWpdd6YOVu3wEqqA0qpY6P3B5rUumm8`;
    //   fetch(url)
    //     .then(response => response.json())
    //     .then(data => {
    //       const { results } = data;
    //       if (results && results.length > 0) {
    //         setAddress(results[0].formatted_address);
    //         // console.log(address)
    //       }
    //     })
    //     .catch(error => {
    //       console.log('Error:', error);
    //     });

    // }
    // const location = useGeoLocated();
    // console.log("asdfasdfasdfasdf",location)
    // if (coords) {
    //   const { latitude, longitude } = coords;
    //   const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCeoWpdd6YOVu3wEqqA0qpY6P3B5rUumm8`;

    //   fetch(url)
    //     .then(response => response.json())
    //     .then(data => {
    //       const { results } = data;
    //       if (results && results.length > 0) {
    //         setAddress(results[0].formatted_address);
    //         // console.log(address)
    //       }
    //     })
    //     .catch(error => {
    //       console.log('Error:', error);
    //     });
    // }
  };
  return (
    <>
      {navTab === 1 && (
        <div
          className="grooming-page-mobile-view-form-container"
          style={{
            marginTop: location == "/" ? "0px" : "",
            width: location == "/" ? "100%" : "",
            border: location == "/" ? "0px" : "",
            borderRadius: location == "/" ? "0px" : "",
          }}
        >
          {location == "/grooming" && (
            <div className="grooming-page-mobile-view-form-section-1">
              <div style={{ display: "flex", textAlign: "start" }}>
                <div style={{ width: selectedCarousel === 1 ? "0%" : "10%" }}>
                  <BiArrowBack
                    style={{
                      display: selectedCarousel === 1 ? "none" : "block",
                    }}
                    onClick={backArrow}
                  />
                </div>
                <div
                  style={{
                    width: selectedCarousel === 1 ? "100%" : "90%",
                    fontSize: "17px",
                    textAlign: "center",
                  }}
                  onClick={scrollToSection}
                >
                  Book Your Grooming Session Here! <br />
                  <span style={{ color: "#FF5E95", fontSize: "17px" }}>
                    FLAT 50% OFF on all Grooming Packages
                  </span>
                </div>
              </div>
            </div>
          )}

          {selectedCarousel == 1 ? (
            <div
              className="grooming-page-mobile-view-services-container"
              ref={sectionRef}
            >
              <div className="">
                <span style={{ color: "#5E5E5E", fontWeight: "700" }}>
                  Type of Pet
                </span>
                <div
                  style={{ display: "flex", padding: "10px", width: "100%" }}
                  className=""
                >
                  <div
                    className="option"
                    id="one"
                    style={{ width: "50%", display: "flex" }}
                    onClick={() => {
                      setSelectedPetChoice(1);
                    }}
                  >
                    <div className="heading" style={{ marginBottom: "5px" }}>
                      <div
                        className="left"
                        onClick={() => {
                          setSelectedPet("Dog");
                          setUserData({ Pet: "Dog" });
                          // console.log("dog");
                        }}
                      >
                        <div className="selection-circle">
                          {selectedPetChoice == 1 && (
                            <div className="dot"></div>
                          )}
                        </div>
                        <div style={{ display: "flex", gap: "5px" }}>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Dog.png"
                            alt=""
                            style={{ width: "25px", height: "25px" }}
                          />
                          <span style={{ fontWeight: "500" }}>
                            Dog{" "}
                            <small style={{ fontSize: "13px" }}>
                              {" "}
                              <br />
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="option"
                    id="two"
                    style={{ width: "50%", display: "flex" }}
                    onClick={() => {
                      setSelectedPetChoice(2);
                    }}
                  >
                    <div className="heading" style={{ marginBottom: "5px" }}>
                      <div
                        className="left"
                        onClick={() => {
                          setSelectedPet("Cat");
                          setUserData({ Pet: "Cat" });
                          // console.log("cat");
                        }}
                      >
                        <div className="selection-circle">
                          {selectedPetChoice == 2 && (
                            <div className="dot"></div>
                          )}
                        </div>
                        <div style={{ display: "flex", gap: "5px" }}>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Cat.png"
                            alt=""
                            style={{ width: "25px", height: "25px" }}
                          />
                          <span style={{ fontWeight: "500" }}>
                            Cat{" "}
                            <small style={{ fontSize: "13px" }}>
                              {" "}
                              <br />
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="select-service">
                <span style={{ color: "#5E5E5E", fontWeight: "700" }}>
                  Select service of your choice
                </span>
                <div className="options-box" defaultValue={1}>
                  <div
                    className="option"
                    id="one"
                    onClick={() => {
                      setSelectedChoice(1);
                      setPrice(799);
                    }}
                  >
                    <div className="heading" style={{ marginBottom: "5px" }}>
                      <div className="left">
                        <div className="selection-circle">
                          {selectedChoice == 1 && <div className="dot"></div>}
                        </div>
                        <span style={{ fontWeight: "800", fontSize: "15px" }}>BATH & BRUSH</span>
                      </div>
                      <div className="right">
                        <span style={{ fontWeight: "500" }}>
                          <span style={{ textDecoration: "line-through" }}>
                            Rs. 1598
                          </span>

                          <span style={{ fontWeight: "900", paddingLeft: "2px" }}>
                            ₹799/- Only
                          </span>
                        </span>
                      </div>
                    </div>
                    {selectedChoice == 1 && (
                      <div className="desc">
                        <ul>
                          <li style={{ fontSize: "15px" }}>Bath</li>
                          <li style={{ fontSize: "15px" }}>Nail Trim</li>
                          <li style={{ fontSize: "15px" }}>Brushing</li>
                          <li style={{ fontSize: "15px" }}>Shampoo</li>
                          <li style={{ fontSize: "15px" }}>Scrubbing</li>
                          <li style={{ fontSize: "15px" }}>Coat Conditioner</li>
                          <li style={{ fontSize: "15px" }}>
                            Ear Cleaning or Flushing
                          </li>
                          <li style={{ fontSize: "15px" }}>Blow Dry</li>
                          {/* <li style={{fontSize:"13px"}}>Scissoring/Trimming feet hair</li> */}
                        </ul>
                      </div>
                    )}
                  </div>
                  <div
                    className="option"
                    id="two"
                    onClick={() => {
                      setSelectedChoice(2);
                      setPrice(1199);
                    }}
                  >
                    <div
                      className="heading"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "5px",
                      }}
                    >
                      <div className="left">
                        <div className="selection-circle">
                          {selectedChoice == 2 && <div className="dot"></div>}
                        </div>
                        <span style={{ fontWeight: "800", fontSize: "15px" }}>
                          BATH & BRUSH <br />
                          <small style={{ fontSize: "13px" }}>
                            (With Tick Treatment)
                          </small>
                        </span>
                      </div>
                      <div className="right">
                        <span style={{ fontWeight: "500" }}>
                          <span style={{ textDecoration: "line-through" }}>
                            Rs. 2398
                          </span>

                          <span style={{ fontWeight: "900", paddingLeft: "2px" }}>
                            ₹1199/- Only
                          </span>
                        </span>
                      </div>
                    </div>
                    {selectedChoice == 2 && (
                      <div className="desc">
                        <ul>
                          <li style={{ fontSize: "15px" }}>Bath</li>
                          <li style={{ fontSize: "15px" }}>Nail Trim</li>
                          <li style={{ fontSize: "15px" }}>Brushing</li>
                          <li style={{ fontSize: "15px" }}>
                            Shampoo(vet approved)
                          </li>
                          <li style={{ fontSize: "15px" }}>Scrubbing</li>
                          <li style={{ fontSize: "15px" }}>Coat Conditioner</li>
                          <li style={{ fontSize: "15px" }}>
                            Ear Cleaning or Flushing
                          </li>
                          <li style={{ fontSize: "15px" }}>Blow Dry</li>

                          <li style={{ fontSize: "15px" }}>
                            Plus Tick Treatment
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div
                    className="option"
                    id="three"
                    onClick={() => {
                      setSelectedChoice(3);
                      setPrice(1249);
                    }}
                  >
                    <div
                      className="heading"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "5px",
                      }}
                    >
                      <div className="left">
                        <div className="selection-circle">
                          {selectedChoice == 3 && <div className="dot"></div>}
                        </div>
                        <span style={{ fontWeight: "800", fontSize: "15px" }}>
                          HAIRCUT & STYLING
                        </span>
                      </div>
                      <div className="right">
                        <span style={{ fontWeight: "500" }}>
                          <span style={{ textDecoration: "line-through" }}>
                            Rs. 2498
                          </span>

                          <span style={{ fontWeight: "900", paddingLeft: "2px" }}>
                            ₹1249/- Only
                          </span>
                        </span>
                      </div>
                    </div>
                    {selectedChoice == 3 && (
                      <div className="desc">
                        <ul>
                          <li style={{ fontSize: "15px" }}>Full Haircut</li>
                          <li style={{ fontSize: "15px" }}>
                            Breed Specific Haircut
                          </li>
                          <li style={{ fontSize: "15px" }}>Fanny Hair Trim</li>
                          <li style={{ fontSize: "15px" }}>
                            Hairstyling(Breed Specific)
                          </li>
                          <li style={{ fontSize: "15px" }}>Paw Hair Trim</li>
                          <li style={{ fontSize: "15px" }}>
                            Tail Hair Shaping
                          </li>
                          <li style={{ fontSize: "15px" }}>Brushing</li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div
                    className="option"
                    id="four"
                    onClick={() => {
                      setSelectedChoice(4);
                      setPrice(1649)
                    }}
                  >
                    <div
                      className="heading"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "5px",
                      }}
                    >
                      <div className="left">
                        <div className="selection-circle">
                          {selectedChoice == 4 && <div className="dot"></div>}
                        </div>
                        <span style={{ fontWeight: "800", fontSize: "15px" }}>
                          BATH & FULL HAIRCUT
                        </span>
                      </div>
                      <div className="right">
                        <span style={{ fontWeight: "500" }}>
                          <span style={{ textDecoration: "line-through" }}>
                            Rs. 3298
                          </span>

                          <span style={{ fontWeight: "900", paddingLeft: "2px" }}>
                            ₹1649/- Only
                          </span>
                        </span>
                      </div>
                    </div>
                    {selectedChoice == 4 && (
                      <div className="desc">
                        <ul>
                          <li style={{ fontSize: "15px" }}>Bath</li>
                          <li style={{ fontSize: "15px" }}>Nail Trim</li>
                          <li style={{ fontSize: "15px" }}>Brushing</li>
                          <li style={{ fontSize: "15px" }}>
                            Shampoo(vet approved)
                          </li>
                          <li style={{ fontSize: "15px" }}>Scrubbing</li>
                          <li style={{ fontSize: "15px" }}>Coat Conditioner</li>
                          <li style={{ fontSize: "15px" }}>
                            Ear Cleaning or Flushing
                          </li>
                          <li style={{ fontSize: "15px" }}>Blow Dry</li>
                          <li style={{ fontSize: "15px" }}>Full Haircut</li>
                          <li style={{ fontSize: "15px" }}>
                            Breed Specific Haircut
                          </li>
                          <li style={{ fontSize: "15px" }}>Fanny Hair Trim</li>
                          <li style={{ fontSize: "15px" }}>
                            Hairstyling(Breed Specific)
                          </li>
                          <li style={{ fontSize: "15px" }}>Paw Hair Trim</li>
                          <li style={{ fontSize: "15px" }}>
                            Tail Hair Shaping
                          </li>
                          {/* <li style={{fontSize:"13px"}}>Brushing</li> */}
                        </ul>
                      </div>
                    )}
                  </div>
                  <div
                    className="option"
                    id="five"
                    onClick={() => {
                      setSelectedChoice(5);
                      setPrice(1949);
                    }}
                  >
                    <div
                      className="heading"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "5px",
                      }}
                    >
                      <div className="left">
                        <div className="selection-circle">
                          {selectedChoice == 5 && <div className="dot"></div>}
                        </div>
                        <span style={{ fontWeight: "800", fontSize: "15px" }}>
                          MASSAGE, BATH, FULL  <br />HAIRCUT & STYLING
                        </span>
                      </div>
                      <div className="right">
                        <span style={{ fontWeight: "500" }}>
                          <span style={{ textDecoration: "line-through" }}>
                            Rs. 3898
                          </span>

                          <span style={{ fontWeight: "900", paddingLeft: "2px" }}>
                            ₹1949/- Only
                          </span>
                        </span>
                      </div>
                    </div>
                    {selectedChoice == 5 && (
                      <div className="desc">
                        <ul>
                          <li style={{ fontSize: "15px" }}>
                            Oil massage of Feet and back
                          </li>
                          <li style={{ fontSize: "15px" }}>Bath</li>
                          <li style={{ fontSize: "15px" }}>Nail Trim</li>
                          <li style={{ fontSize: "15px" }}>Brushing</li>
                          <li style={{ fontSize: "15px" }}>
                            Shampoo(vet approved)
                          </li>
                          <li style={{ fontSize: "15px" }}>Scrubbing</li>
                          <li style={{ fontSize: "15px" }}>Coat Conditioner</li>
                          <li style={{ fontSize: "15px" }}>
                            Ear Cleaning or Flushing
                          </li>
                          <li style={{ fontSize: "15px" }}>Blow Dry</li>
                          <li style={{ fontSize: "15px" }}>Full Haircut</li>
                          <li style={{ fontSize: "15px" }}>
                            Breed Specific Haircut
                          </li>
                          <li style={{ fontSize: "15px" }}>Fanny Hair Trim</li>
                          <li style={{ fontSize: "15px" }}>
                            Hairstyling(Breed Specific)
                          </li>
                          <li style={{ fontSize: "15px" }}>Paw Hair Trim</li>
                          <li style={{ fontSize: "15px" }}>
                            Tail Hair Shaping
                          </li>
                          {/* <li style={{fontSize:"13px"}}>Brushing</li> */}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* <div
                style={{
                  padding: "10px",
                  marginTop: "10px",
                  marginBottom: "30px",
                  width: "100%",
                }}
              >
                <ImageSliderWalking images={images} />
              </div> */}
              <center>
                <div className="grooming-new-form-section1-carousel-container">
                  <div className="grooming-new-form-section1-carousel-active"></div>
                  <div className="grooming-new-form-section1-carousel"></div>
                  <div className="grooming-new-form-section1-carousel"></div>
                </div>
              </center>
              <div className="button-group">
                {groomingLoading ? (
                  <center>
                    <LoadingSpinner />
                  </center>
                ) : (
                  <button
                    onClick={() => {
                      // firstFormDataHandler();
                      // setFormSection(2);
                      groomingFirstFormHandler();

                      // setSelectedCarousel(2);
                      // displayLocation();
                    }}
                    className="next"
                    style={{ fontSize: "18px", color: "black" }}
                  >
                    Next
                  </button>
                )}
              </div>
              <div
                style={{
                  padding: "10px",
                  marginTop: "10px",
                  marginBottom: "30px",
                  width: "100%",
                }}
              >
                <ImageSliderWalking images={images} />
              </div>
            </div>
          ) : null}

          {/* 2nd form section */}

          {selectedCarousel == 2 ? (
            <div className="grooming-page-mobile-view-services-container">
              <div className="name" style={{ marginTop: "10px" }}>
                <div
                  className="label"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>Enter Name</span>
                  {!groomingNameValidation ? (
                    <div className="home-page-walking-form-mobile-feedback">
                      required
                    </div>
                  ) : null}
                </div>
                <div>
                  <input
                    ref={groomingNameRef}
                    onChange={(e) => {
                      // secondFormDataHandler();
                      setGroomingNameValidation(true);
                      setnameValue(true);
                      setNameFill(e.target.value);
                      setUserData({
                        ...userData,
                        Name: e.target.value,
                      });
                      console.log(e.target.value);
                    }}
                    type="text"
                    placeholder="Enter your name"
                    required
                    style={{ fontSize: "17px" }}
                    value={nameValue ? nameFill : ""}
                  />
                </div>
              </div>
              <div className="phone" style={{ marginTop: "10px" }}>
                <div
                  className="label"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>Phone number</span>
                  {!groomingPhoneValidation ? (
                    <div className="home-page-walking-form-mobile-feedback">
                      required
                    </div>
                  ) : null}
                </div>
                <div className="number-box">
                  <input
                    ref={groomingPhoneRef}
                    // disabled={isVerified}
                    onChange={(e) => {
                      // secondFormDataHandler();
                      setGroomingPhoneValidation(true);
                      // setUserData({
                      //   ...userData,
                      //   Phone:groomingPhoneRef.current.value
                      // })
                      setPhoneValue(true);
                      setPhoneFill(e.target.value);
                      setUserData({
                        ...userData,
                        Phone: e.target.value,
                      });
                      // changeButtonColor();
                    }}
                    type="number"
                    placeholder="Enter phone number"
                    required
                    style={{ fontSize: "17px", width: "100%" }}
                    value={phoneValue ? phoneFill : ""}

                  // value={userData.Phone}
                  />
                  {/* <button id="send-otp"
                    disabled={isVerified}
                    onClick={() => {
                      setChangeColor(true);
                      getmobileOtp();
                      setShowEnterOtp(true);
                      changeName();
                      {seconds===0?setSeconds(33):setSeconds(0)}
                      handleSubmit();
                      
                    }}
                    style={{borderColor: correctOtp ? "white" : "red",backgroundColor:phoneFill?.length>=10?"rgb(69, 163, 165)":"",color:phoneFill?.length>=10?"white":""}}
                  >
                    Send OTP
                  </button> */}
                </div>
                {/* {countdown && seconds!=0?<div id="countdown-seconds" style={{textAlign:"right",fontSize:"15px",position:"relative",right:"30px"}}>
                   {seconds} sec
                  </div>:null} */}
                {/* {showEnterOtp &&
                  (groomingPhoneRef.current.value.toString().length === 10 || showDialog) && (
                    <div className="enterotp">
                      <span>Enter OTP</span>
                      <br />
                      {isWrongOtp && !isVerified ? (
                        <>
                        <div className="home-page-walking-form-mobile-feedback">
                        
                          Wrong OTP
                        </div>
                        </>
                      ) : null}
                      <input
                        ref={groomingOtpRef}
                        onClick={() => {
                          setIsWrongOtp(false);
                        }}
                        disabled={isVerified}
                        type="number"
                        placeholder="Enter Otp"
                        value={otp}
                        onChange={handleOtpChange}
                        onPaste={handlePaste}
                        autoComplete="one-time-code"
                        style={{fontSize:"17px"}}
                      />
                      
                      <button
                        disabled={isVerified}
                        onClick={() => {
                          // handleSubmit();
                          verifyMobileOTP();
                          indicateAlert();
                          
                        }}
                        id="verifyButton"
                        style={{border:"2px solid #DEC269",backgroundColor:phoneFill?.length>=10?"rgb(69, 163, 165)":"",color:phoneFill?.length>=10?"white":""}}
                      >
                        {isVerified ? (
                          <>
                         
                            <img src={greenTick} width={15} />{" "}
                            <span style={{ marginLeft: "5px",color:"white" }}>Verified</span>
                           </>
                        ) : (
                          "Verify"
                        )}
                      </button>
                    </div>
                  )} */}
              </div>
              <div className="dateandtime">
                <div className="select-date">
                  <div style={{ color: "#5E5E5E", fontWeight: "700" }}>
                    Session Date
                  </div>
                  {!groomingDateValidation ? (
                    <div className="home-page-walking-form-mobile-feedback">
                      Required
                    </div>
                  ) : null}

                  <div
                    className="walking-section-form-input-date-mobile"
                    style={{
                      marginBottom: "20px",
                      position: "relative",
                      border: "none",
                      height: "56px",
                    }}
                  >
                    <input
                      ref={groomingDateRef}
                      onChange={() => {
                        firstFormDataHandler();
                        // secondFormDataHandler();
                        setGroomingDateValidation(true);
                        setStartValue(true);
                        setStartDate(groomingDateRef.current.value);
                      }}
                      type="date"
                      className="walking-section-form-input-date-mobile"
                      placeholder="DD/MM/YYYY"
                      required
                      style={{
                        width: "100%",
                        height: "100%",
                        fontSize: "17px",
                      }}
                      value={startValue ? startDate : formattedDate}
                    />
                  </div>

                  {/* <DatePicker ref={groomingDateRef} dateFormat="yyyy-MM-dd"  onChange={(date) => { firstFormDataHandler(); 
                                        setGroomingDateValidation(true); 
                                        
                                        setSessionDate(date) }} className='walking-section-form-input-date-mobile' placeholderText='DD/MM/YYYY'  id='inputId' selected={sessionDate}  /> */}
                </div>
                <div className="select-slot">
                  <div style={{ color: "#5E5E5E", fontWeight: "700" }}>
                    Time Slot
                  </div>
                  {!groomingSlotValidation ? (
                    <div className="home-page-walking-form-mobile-feedback">
                      Required
                    </div>
                  ) : null}
                  <select
                    name="slot"
                    id="slot"
                    ref={groomingSlotRef}
                    onChange={() => {
                      firstFormDataHandler();
                      setGroomingSlotValidation(true);
                      // setUserData({
                      //   ...userData,
                      //   Slot:groomingSlotRef.current.value
                      // })
                      setSlotValue(true);
                      setSlotFill(groomingSlotRef.current.value);
                    }}
                    // onSelect={()=>{
                    //   setSlotValue(true);
                    //   setSlotFill(groomingSlotRef.current.value);
                    // }}
                    style={{ fontSize: "16px" }}
                    // value={userData.Slot}
                    value={slotValue ? slotFill : ""}
                  >
                    <option value="">Select Slot</option>
                    {["8:00am-10:00am", "10:00am-12:00pm", "12:00pm-2:00pm", "2:00pm-4:00pm", "4:00pm-6:00pm", "6:00pm-8:00pm"].map(
                      (slot, index) => (
                        <option key={index} value={slot}>{slot}</option>
                      )
                    )}
                  </select>
                </div>
              </div>
              <center>
                <div className="grooming-new-form-section1-carousel-container">
                  <div className="grooming-new-form-section1-carousel-active"></div>
                  <div className="grooming-new-form-section1-carousel-active"></div>
                  <div className="grooming-new-form-section1-carousel"></div>
                </div>
              </center>
              <div className="button-group">
                <button
                  className="next"
                  onClick={() => {
                    // secondFormDataHandler();
                    // firstFormDataHandler();
                    nextNavhandler();

                    // setFormSection(3);
                    // setSelectedCarousel(3);
                  }}
                  style={{ color: "black" }}
                >
                  Next
                </button>
              </div>
            </div>
          ) : null}

          {/*3rd form section */}
          {selectedCarousel == 3 ? (
            <div className="second-form-section">
              <div className="address">
                <div
                  className="label"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>Your Location</span>
                  {!groomingAddressValidation ? (
                    <div className="home-page-walking-form-mobile-feedback">
                      Enter your home address
                    </div>
                  ) : null}
                </div>
                <div>
                  <input
                    ref={groomingAddressRef}
                    onChange={(e) => {
                      // secondFormDataHandler();
                      setGroomingAddressValidation(true);
                      // setAdressFill(true);
                      setAddress(e.target.value);
                      setUserData({
                        ...userData,
                        Address: e.target.value
                      })
                      // console.log(e.target.value)
                    }}
                    type="text"
                    placeholder="Enter your Home address"
                    required
                    style={{ fontSize: "17px" }}
                    // onFocus={handleLocationAccess}
                    value={address}
                    onClick={useLocation123}
                  />
                </div>
              </div>
              <div className="address">
                <div className="label">
                  <span>
                    Coupon Code
                    <span style={{ fontStyle: "italic" }}>(If Available)</span>
                  </span>
                  {couponValid && (
                    <span
                      style={{
                        color: "#618E41",
                        fontSize: "10px",
                        display: "inline-block",
                      }}
                    >
                      &nbsp;Code applied successfully
                    </span>
                  )}
                  {couponNotValid && (
                    <div
                      style={{
                        color: "red",
                        fontSize: "10px",
                        display: "inline-block",
                      }}
                    >
                      &nbsp;Invalid Code
                    </div>
                  )}
                </div>

                <div className="grooming-new-form-section3-right-ph-input-contact">
                  <input
                    id="coupon"
                    type="text"
                    className="grooming-new-form-desktop-input-container-type1"
                    onChange={(e) => {
                      setCouponCode(e.target.value);
                    }}
                    value={couponCode}
                    style={{ fontSize: "17px" }}
                  />


                  {!couponValid && !couponNotValid && (
                    <div className="coupon-apply-button" onClick={() => applyCoupon()}>
                      <span style={{ paddingRight: "0.5rem" }}>Apply</span>
                      <FaArrowAltCircleRight className="coupon-apply-button-icon" />
                    </div>
                  )}

                  {couponValid && (
                    <div
                      className="coupon-applied-text"
                      style={{ color: "#618E41;" }}
                    >
                      <span style={{ marginRight: "8px" }}>
                        ₹ {couponValue} /- OFF
                      </span>{" "}
                      <FaCheck className="check-mark" />
                    </div>
                  )}
                  {couponNotValid && (
                    <div className="coupon-not-valid">
                      <MdClose className="x-mark"
                        onClick={() => {
                          setCouponValid(false);
                          setCouponNotValid(false)
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="grooming-new-form-section3-right-ph-input-contact">
                  <button
                    id="coupon"
                    hidden={couponCode.trim() !== ""}
                    type="text"
                    className="grooming-new-form-desktop-input-container-type1-button"
                    style={{ width: "100%", borderStyle: "dotted", font: "DM Sans", fontStyle: "italic", fontSize: "1.1rem" }}
                    onClick={() => applyMOJO()}
                  >Save <span style={{ color: "green" }}>5%</span>  more with <span style={{ color: "#FF5E95", font: "Lato", fontWeight: "800" }}> MOJO5</span></button>
                </div>
              </div>
              {/* <div className="address">
                <div className="label">
                  <span>Complete Address</span>
                  {!groomingAddressValidation1 ? (
                    <div className="home-page-walking-form-mobile-feedback">
                      Enter your home address
                    </div>
                  ) : null}
                </div>
                <div>
                  <input
                    ref={groomingAddressRef1}
                    onChange={() => {
                      secondFormDataHandler();
                      setGroomingAddressValidation1(true);
                      setAddressValue(true);
                      setAdressFill(groomingAddressRef1.current.value)
                    }}
                    type="text"
                    placeholder="Enter your Home address"
                    required
                    style={{fontSize:"17px"}}
                    value={addressValue?adressFill:""}
                  />
                </div>
              </div> */}
              <div className="grooming-form-tagline-text"
                hidden={couponCode.trim() === ""}
              >
                <center style={{ fontSize: "1.5rem" }}>
                  Total Payable :
                  {!couponValid && !couponNotValid && <span> ₹{price1}</span>}
                  {couponValid && (
                    <span>
                      <span style={{ textDecoration: "line-through" }}>
                        ₹{price1}
                      </span>
                      <span style={{ color: "#618E41", paddingLeft: "0.5rem", fontWeight: "800" }}> ₹{price1 - couponValue}</span>
                    </span>
                  )}
                  {couponNotValid && <span>₹{price1}</span>}
                </center>
              </div>
              <center>
                <div
                  className="grooming-new-form-section1-carousel-container"
                  style={{ paddingRight: "20px" }}
                >
                  <div className="grooming-new-form-section1-carousel-active"></div>
                  <div className="grooming-new-form-section1-carousel-active"></div>
                  <div className="grooming-new-form-section1-carousel-active"></div>
                </div>
              </center>
              <div className="grooming-form-tagline-text-mobile-view">
                <center>
                  Paid Sessions are given time & elite <br /> groomers
                  preference!
                </center>
              </div>

              {isLoading ? (
                <center>
                  <LoadingSpinner />
                </center>
              ) : (
                <div className="button-group">
                  <button
                    // disabled={isVerified === false || isVerified === null}
                    className="pay-now"
                    onClick={() => {
                      finalFormSubmitHandler(true);
                    }}
                  // style={{backgroundColor:!isVerified?"rgba(131, 145, 161, 1)":"",border:!isVerified?"2px solid rgba(131, 145, 161, 1)":""}}
                  >
                    Pay Now
                  </button>
                  {/* {location==="/grooming"? */}
                  {/* <button
                    onClick={() => {
                      finalFormSubmitHandler(false);
                      setBookNow(true);
                    }}
                    className="pay-later"
                    // style={{backgroundColor:!isVerified?"white":"",border:!isVerified?"2px solid rgba(131, 145, 161, 1)":"",color:!isVerified?"rgba(131, 145, 161, 1)":""}}
                  >
                    Book Now & Pay Later
                  </button> */}
                  {/* // :null} */}
                </div>
              )}
            </div>
          ) : null}
          <center>
            {selectedCarousel === 3 ? (
              <div
                style={{
                  padding: "10px",
                  fontWeight: "700",
                  lineHeight: "18.75px",
                }}
              >
                <h3
                  style={{ color: "rgba(114, 114, 114, 1)", fontSize: "15px" }}
                >
                  Your Data Privacy matters.
                </h3>
                <p
                  style={{ color: "rgba(131, 145, 161, 1)", fontSize: "15px" }}
                >
                  We only use your contact number to reach you regarding your
                  service request
                </p>
              </div>
            ) : null}
            <h2 style={{ color: "#FF5E95", fontStyle: "italic", display: "flex", flexDirection: "row", marginTop: "-1rem", marginBottom: "1rem", marginLeft: "2rem" }}><img src={NoVan} style={{ height: "1.5rem", width: "1.5rem" }} />We do not offer Van Services</h2>
            <center>
              <LazyLoad>
                <img src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/petmojo_black_bg_green_logo.png" width={170} height={70} />
              </LazyLoad>
            </center>
            <div
              style={{
                fontWeight: "500",
                fontSize: "10px",
                lineHeight: "20px",
              }}
            >
              <p style={{ color: "rgba(93, 93, 93, 1)" }}>
                Copyright 2024 Petmojo <br />
                All copyrights are reserved - any infringement will be legally
                pursued
              </p>
            </div>
          </center>
        </div>
      )}
      {navTab === 2 && (
        <div className="order-placed-container">
          <div className="container">
            <div className="first-box">
              <span>
                {" "}
                <strong> Order Successfully Placed:</strong>
              </span>
              <div className="middle">{userData.Service}</div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <span>Number of pets: 1</span>
                  <br />
                  <span>
                    Date: {userData.Date.split("-").reverse().join("-")}
                  </span>

                  <br />
                </div>
                {!bookNow ? (
                  <div>
                    <img
                      src={downloadInvoice}
                      width={50}
                      style={{
                        objectFit: "contain",
                        position: "relative",
                        top: "-40px",
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="second-box">
              <div className="left">
                <span>
                  <strong> Billing Address: </strong>
                </span>
                <div>
                  <p>{address}</p>
                </div>
              </div>
              <div className="right" style={{ backgroundColor: "white" }}>
                <img
                  src={groomingvector}
                  alt=""
                  style={{
                    objectFit: "contain",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
            </div>
            <div className="third-box">
              <div
                onClick={() => {
                  console.log(userData);
                }}
                className="tick"
              >
                <img src={greenTick} alt="" />
              </div>
              <p>
                Your information has been sent to our pet counselor, We will
                reach you soon.
              </p>

              <a
                href="https://play.google.com/store/apps/details?id=in.tamely.user"
                style={{ textDecoration: "none" }}
              >
                <div className="download" style={{ marginBottom: "15px" }}>
                  Download App to Contact Groomer
                </div>
              </a>
              <div className="button-group">
                <a href="https://play.google.com/store/apps/details?id=in.tamely.user">
                  <img src={googlePlay} alt="" className="playstore" />
                </a>
                <a href="https://apps.apple.com/in/app/petmojo/id1609861328">
                  <img src={appleStore} alt="" className="appstore" />
                </a>
              </div>
            </div>
          </div>
        </div>
        // <div className="order-summary-mobile-master-container">
        //   <div className="order-summary-mobile-grey-container">
        //     <div>Order Successfully Placed:</div>
        //     <div className="order-summary-mobile-grey-heading">{userData.Service}</div>
        //     <div className="order-summary-mobile-grey-container-details-container">
        //       <div>
        //         <div>Number of pets: 1</div>
        //         <div>Date: {userData.Date.split("-").reverse().join("-")}</div>
        //       </div>

        //       <div>
        //         <img
        //           src={downloadInvoice}
        //           width={100}
        //           style={{
        //             objectFit: "contain",
        //             position: "relative",
        //             top: "-20px",
        //           }}
        //         />
        //       </div>
        //     </div>
        //     <div className="order-confirmation-mobile-container">
        //       Order Confirmation Number: {''}
        //       <span
        //         onClick={() => {
        //           navigator.clipboard.writeText(`${''}`);
        //           copiedMessage();
        //         }}
        //       >
        //         <img
        //           alt="copy"
        //           className="copy-icon-mobile"
        //           src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/copy-icon-desktop.png"
        //         />
        //       </span>
        //       <span className="copy-popup-text">{copyMessage}</span>
        //     </div>
        //   </div>

        //   <div
        //     style={{
        //       display: "flex",
        //       justifyContent: "space-between",
        //       marginTop: "10px",
        //       padding: "15px",
        //     }}
        //   >
        //     <div className="order-summary-mobile-white-container">
        //       <div className="billing-address-heading">Billing Address:</div>
        //       <div className="billing-address-ad">{userData.Address}</div>
        //     </div>
        //     <div>
        //       <img
        //         src={groomingvector}
        //         alt=""
        //         style={{ objectFit: "contain" }}
        //       />
        //     </div>
        //   </div>

        //   <div className="order-confirmation-mobile-summary-container">
        //     <div>
        //       <img
        //         alt="tickImage"
        //         className="order-confirmation-mobile-summary-image"
        //         src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/tick-order-summary-mobile.png"
        //       />
        //     </div>
        //     <div className="order-confirmation-mobile-summary-text">
        //       Your information has been sent to our pet counselor, We will reach
        //       you soon.
        //     </div>
        //   </div>

        //   <div className="order-summary-mobile-download-button">
        //     Download Receipt
        //   </div>

        //   <div className="order-summary-mobile-footer-container">
        //     <div>
        //       <img
        //         alt="petmojoLogo"
        //         className="order-summary-mobile-footer-logo"
        //         src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/petmojo-order-summary-mobile.png"
        //       />
        //     </div>
        //     <div className="order-summary-mobile-footer-text">
        //       Copyright 2024 Petmojo
        //     </div>
        //     <div className="order-summary-mobile-footer-text">
        //       All copyrights are reserved - any infringement will be legally
        //       pursued
        //     </div>
        //     <div>
        //       <br />
        //       <br />
        //       <br />
        //       <br />
        //     </div>
        //   </div>
        // </div>
      )}

    </>
  );
};

export default GroomingPageForm;
