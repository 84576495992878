import { BiArrowBack } from "@react-icons/all-files/bi/BiArrowBack";
import { FaArrowAltCircleRight } from "@react-icons/all-files/fa/FaArrowAltCircleRight";
import { FaCheck } from "@react-icons/all-files/fa/FaCheck";
import { MdClose } from "@react-icons/all-files/md/MdClose";
import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import LazyLoad from "react-lazyload";
import { useLocation, useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import appleStore from "../../../images/app-store.png";
import blackLogo from "../../../images/BlackLogo.png";
import downloadInvoice from "../../../images/download-invoice.png";
import greenTick from "../../../images/green-tick.png";
import groomingvector from "../../../images/grooming-vector.jpg";
import googlePlay from "../../../images/play-store.png";
import ImageSliderWalking from "../../ImageSliderWalking";
import LoadingSpinner from "../../loadingSpinner/LoadingSpinner";
import "./MobileFormsCSS.css";

const images = [
  {
    id: 1,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/boarding-mobile-1.webp",
    alt: "Image 1",
  },
  {
    id: 2,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/boarding-mobile-2.webp",
    alt: "Image 2",
  },
  {
    id: 3,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/boarding-mobile-3.webp",
    alt: "Image 3",
  },
  {
    id: 4,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/boarding-mobile-4.webp",
    alt: "Image 4",
  },
  {
    id: 5,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/boarding-mobile-5.webp",
    alt: "Image 5",
  },
  {
    id: 6,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/boarding-mobile-6.webp",
    alt: "Image 6",
  },
  {
    id: 7,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/boarding-mobile-7.webp",
    alt: "Image 7",
  },
  {
    id: 8,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/boarding-mobile-8.webp",
    alt: "Image 8",
  },
  {
    id: 9,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/boarding-mobile-9.webp",
    alt: "Image 9",
  },
  {
    id: 10,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/boarding-mobile-10.webp",
    alt: "Image 10",
  },
];

const BoardingPageForm = (props) => {
  const [otp, setOtp] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [couponValue, setCouponValue] = useState(0);
  const [couponErrorMsg, setCouponErrorMsg] = useState("");
  const [couponNotValid, setCouponNotValid] = useState(false);
  const [couponValid, setCouponValid] = useState(false);
  const [bookNow, setBookNow] = useState(false);
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const [seconds, setSeconds] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCarousel, setSelectedCarousel] = useState(1);
  const [correctOtp, setCorrectOtp] = useState(true);
  const groomingPhoneRef = useRef(0);
  const groomingAddressRef = useRef("");
  const groomingDateRef = useRef("");
  const groomingEndDateRef = useRef("");
  const groomingSlotRef = useRef("");
  const groomingOtpRef = useRef("");
  const [countdown, setCountdown] = useState(true);
  const [startValue, setStartValue] = useState(false);
  const [endValue, setEndValue] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [nameValue, setnameValue] = useState(false);
  const [nameFill, setNameFill] = useState("");
  // const [petnameValue, setPetnameValue] = useState(false);
  // const [petnameFill, setPetnameFill] = useState("");
  const [phoneValue, setPhoneValue] = useState(false);
  const [phoneFill, setPhoneFill] = useState(null);
  // const [addressValue, setAddressValue] = useState(false);
  // const [adressFill, setAdressFill] = useState("");
  // const [slotValue, setSlotValue] = useState(false);
  // const [slotFill, setSlotFill] = useState("");
  const [dropOff, setDropOff] = useState(false);
  //   const [dropOffSlot,setDropOffSlot]=useState("10AM-02PM");
  const [pickup, setPickup] = useState(false);
  //   const [pickupSlot,setPickupSlot]=useState("10AM-02PM");
  // const groomingEmailRef = useRef("");
  const groomingNameRef = useRef("");
  const groomingPetNameRef = useRef("");
  const [inputDate, setInputDate] = useState("");
  const [inputSlot, setInputSlot] = useState("");
  const [copySuccess, setCopySuccess] = useState("");
  const [lat, setLat] = useState(0);
  const [lon, setLon] = useState(0);
  const [copyMessage, setCopyMessage] = useState("");
  // const [timeSlot, setTimeSlot] = useState(1);
  const [isStartDateSelected, setIsStartDateSelected] = useState(false);
  const [isEndDateSelected, setIsEndDateSelected] = useState(false);
  const [pickupSlot, setPickupSlot] = useState(0);
  const [dropOffSlot, setDropOffSlot] = useState(0);

  const pickupTime = ["", "10AM-02PM", "02PM-06PM", "06PM-10PM"];
  const dropOffTime = ["", "10AM-02PM", "02PM-06PM", "06PM-10PM"];

  const [userData, setUserData] = useState({
    Pet: "",
    Service: "",
    amount: 0,
    PickupSlot: "",
    DropoffSlot: "",
    Name: "",
    Phone: "",
    Address: "",
    Latitude: 0,
    Longitude: 0,
    StartDate: "",
    EndDate: "",
    PetName: "",
    Package: "",
    Days: 0,
    Location: "",
  });

  const [paymentData, setPaymentData] = useState({});
  const [flag, setFlag] = useState(false);
  const [address, setAddress] = useState("");
  const [selectedPet, setSelectedPet] = useState("Small Pet");
  const baseUrl =
    "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api";

  const [groomingPhoneValidation, setGroomingPhoneValidation] = useState(true);
  const [groomingAddressValidation, setGroomingAddressValidation] =
    useState(true);

  const [groomingDateValidation, setGroomingDateValidation] = useState(true);
  const [groomingEndDateValidation, setGroomingEndDateValidation] =
    useState(true);
  const [groomingSlotValidation, setGroomingSlotValidation] = useState(true);
  const [groomingNameValidation, setGroomingNameValidation] = useState(true);
  // const [groomingPetNameValidation, setGroomingPetNameValidation] =
  //   useState(true);
  const [groomingLoading, setGroomingLoading] = useState(false);
  const [selectedChoice, setSelectedChoice] = useState(0);
  const [selectedService, setSelectedService] = useState("Health Resort Stay");
  const [selectedPackage, setSelectedPackage] = useState("");
  const [selectedPetChoice, setSelectedPetChoice] = useState(1);
  const [navTab, setNavTab] = useState(1);
  const [formSection, setFormSection] = useState(1);
  const [showEnterOtp, setShowEnterOtp] = useState(false);
  const [showDownloadInvoice, setShowDownloadInvoice] = useState(null);
  const [isVerified, setIsVerified] = useState(null);
  const [isWrongOtp, setIsWrongOtp] = useState(false);
  const [razorpayOrderId, setRazorpayOrderId] = useState("");
  const [bookingId, setBookingId] = useState("");
  const [token, setToken] = useState("");
  const [phone, setPhone] = useState("");
  const [daysDiff, setDaysDiff] = useState(0);
  const [nonvegMeal, setNonvegMeal] = useState(false);
  const [thirdMeal, setThirdMeal] = useState(false);
  const [displaySlots, setDisplaySlots] = useState(true);
  const [isPayNow, setIsPayNow] = useState(false);

  const [slotValue, setSlotValue] = useState(false);
  const [slotFill, setSlotFill] = useState("GURGAON (SEC 58)");
  var currentDate = new Date();
  var year = currentDate.getFullYear();
  var month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
  var day = ("0" + currentDate.getDate()).slice(-2);
  var formattedDate = year + "-" + month + "-" + day;
  const sectionRef = useRef(null);
  function scrollToSection() {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  }
  useEffect(() => {
    if (isStartDateSelected || isEndDateSelected) {
      var date1;
      var date2;
      if (!isStartDateSelected) {
        date1 = new Date(formattedDate);
      } else {
        date1 = new Date(startDate);
      }
      if (!isEndDateSelected) {
        date2 = new Date(formattedDate);
      } else {
        date2 = new Date(endDate);
      }
      // Calculate the time difference in milliseconds
      var timeDiff = date2.getTime() - date1.getTime();

      // Convert the time difference to days
      var daysDifference = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      console.log(daysDifference + 1);
      setDaysDiff(daysDifference + 1);
    }
  }, [startDate, endDate]);
  useEffect(() => {
    if (!pickup) {
      setPickupSlot(0);
    }
    if (!dropOff) {
      setDropOffSlot(0);
    }
  }, [pickup, dropOff]);

  const applyCoupon = async () => {
    await axios
      .post(
        "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/websiteVerifyCoupon",
        {
          name: couponCode,
          serviceType: 2,
          package: "Pet Hotel",
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: token,
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response.data.value) {
          setCouponValue(response.data.value);
          setCouponErrorMsg("");
          setCouponNotValid(false);
          setCouponValid(true);
        } else {
          setCouponValid(false);
          setCouponNotValid(true);
          setCouponErrorMsg(response.data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (daysDiff > 10) {
      setDisplaySlots(false);
    } else {
      setDisplaySlots(true);
    }
  }, [daysDiff]);
  const firstFromBoardingHandler = () => {
    if (phoneFill == null || phoneFill == "") {
      setGroomingPhoneValidation(false);
    } else {
      setSelectedCarousel(2);
    }
  };
  const secondFormBoardingHandler = () => {
    if (nameFill == "") {
      setGroomingNameValidation(false);
    } else {
      setSelectedCarousel(3);
    }
  };
  const firstFormDataHandler = () => {
    let groomingDateRefCurrValue = groomingDateRef.current.value;
    if (
      groomingDateRefCurrValue !== "" &&
      groomingSlotRef.current.value !== ""
    ) {
      setInputDate(groomingDateRefCurrValue);
      setInputSlot(groomingSlotRef.current.value);

      setUserData({
        ...userData,
        Pet: selectedPetChoice === 1 ? "Small Pet" : "Big Pet",
        Service: selectedService,
        Package: selectedPackage,
        amount:
          selectedChoice === 1
            ? 18100
            : selectedChoice === 2
              ? 28200
              : selectedChoice === 3
                ? 36300
                : selectedChoice === 4
                  ? 28200
                  : selectedChoice === 5
                    ? 36300
                    : 0,
        StartDate: groomingDateRefCurrValue,
        EndDate: selectedChoice !== 0 ? "" : groomingEndDateRef.current.value,
        Latitude: lat,
        Longitude: lon,
        Days: daysDiff,
      });
      //   setSelectedCarousel(2);
    } else {
      console.log("data coll err");
    }
  };
  const secondFormDataHandler = () => {
    if (
      groomingNameRef.current.value !== "" &&
      checkPhone(groomingPhoneRef.current.value.toString()).length === 10 &&
      groomingAddressRef.current.value !== ""
    ) {
      setUserData({
        ...userData,
        Name: groomingNameRef.current.value,
        Phone: phone,
        Address: groomingAddressRef.current.value,
        PetName: groomingPetNameRef.current.value,
      });

      //   setSelectedCarousel(3);
    } else {
      console.log("data col err");
    }
  };
  const [showDialog, setShowDialog] = useState(false);
  const checkPhone = (phoneString) => {
    if (
      phoneString.length > 10 &&
      (phoneString.startsWith("+91") || phoneString.startsWith("91"))
    ) {
      phoneString = phoneString.slice(-10);
      setPhone(phoneString);
      setShowDialog(true);
    }
    return phoneString;
  };
  const nextNavhandler = () => {
    console.log(pickupTime[pickupSlot]);
    console.log(dropOffTime[dropOffSlot]);
    setUserData({
      ...userData,
      PickupSlot: pickupTime[pickupSlot],
      DropoffSlot: dropOffTime[dropOffSlot],
    });
    let groomingDateRefCurrValue = groomingDateRef.current.value;
    if (
      groomingDateRefCurrValue !== "" &&
      groomingEndDateRef.current.value != ""
    ) {
      console.log(userData);
      setSelectedCarousel(3);
      // setFormSection(3);
    } else if (groomingDateRefCurrValue === "") {
      console.log("error");
      setGroomingDateValidation(false);
    } else if (groomingSlotRef.current.value === "") {
      console.log("error");
      setGroomingSlotValidation(false);
    }

    if (
      groomingDateRefCurrValue === "" &&
      groomingSlotRef.current.value === ""
    ) {
      console.log("error");
      setGroomingSlotValidation(false);
      setGroomingDateValidation(false);
    }
  };

  const getmobileOtp = useCallback(async () => {
    try {
      let phoneString = checkPhone(groomingPhoneRef.current.value.toString());
      const res = await axios.post(`${baseUrl}/auth/sendMobileOTP`, {
        phoneNumber: phoneString,
      });
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  }, []);
  const changeName = () => {
    document.getElementById("send-otp").innerHTML = "Resend OTP";
  };

  const verifyMobileOTP = useCallback(async () => {
    try {
      let phoneString = checkPhone(groomingPhoneRef.current.value.toString());
      const res = await axios.post(
        "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/auth/verifyMobileOTP",
        {
          otp: groomingOtpRef.current.value.toString(),
          phoneNumber: phoneString,
          type: "User",
        }
      );
      console.log(res.status);
      console.log(res.data);

      if (res.status === 200) {
        setIsVerified(true);
        setCorrectOtp(true);
        setToken(res.data.token);
        setCountdown(false);
      }
    } catch (error) {
      console.log(error.response.status);
      console.log(error);
      if (error.response.status === 400) {
        setIsVerified(false);
        setCorrectOtp(false);
        setIsWrongOtp(true);
      }
    }
  }, []);
  const indicateAlert = () => {
    if (!correctOtp) {
      document.getElementById("send-otp").style.borderColor = "red";
    }
  };
  const decrementSeconds = () => {
    setTimeout(() => {
      if (seconds == 0) {
        if (document.getElementById("countdown-seconds")) {
          document.getElementById("countdown-seconds").style.display = "none";
        }
      } else {
        setSeconds(seconds - 1);
      }
    }, 1000);
  };
  const handleOtpChange = (event) => {
    const { value } = event.target;
    setOtp(value);
  };

  // function to handle the form submission
  const handleSubmit = (event) => {
    // event.preventDefault();
    console.log(`Submitting OTP: ${otp}`);
  };

  // function to handle the "Paste" event and autofill OTP
  const handlePaste = (event) => {
    // event.preventDefault();
    const pastedText = event.clipboardData.getData("Text");
    setOtp(pastedText);
  };
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay(
    serviceAmount,
    SelectedService,
    DateOfReg,
    inputPhone,
    InputAddress,
    bookingId
  ) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // creating a new order
    const result = await axios.post(
      "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/generateGroomingRazorPayOrderId",
      {
        amount: serviceAmount,
      }
    );

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }

    // Getting the order details back
    const { amount, id: order_id, currency } = result.data;

    const options = {
      key: process.env.REACT_APP_KEY, // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: "Petmojo",
      description: "Pet Grooming Payment",
      //image: { logo },
      order_id: order_id,
      handler: async function (response) {
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };

        // const result = await axios.post(
        //   "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/generateGroomingRazorPaySuccess",
        //   data
        // );
        // //alert(result.data.msg);
        // console.log(result.data);
        //setRazorpayOrderId(result.data.orderId)

        const postGroomingPayment = async (bId, oId, amt) => {
          const config = {
            headers: {
              "Content-Type": "application/json",
              authorization: token,
            },
          };
          try {
            console.log(bId, oId, amt);
            const res = await axios.patch(
              "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/service/postPetBoardingPayment",
              {
                bookingId: bId,
                status: isPayNow ? 1 : 2,
                amount: amt,
              },
              config
            );
            console.log(res.data);
            if (res.data.success == true) {
              setIsPayNow(true);
              navigate("/thankPageGroom");
              // navigate('/ordersummaryboarding',{
              //   state:{
              //     data:{
              //       ContactNo: inputPhone,
              //       Address: address,
              //       StartDate: startDate==""?formattedDate:startDate,
              //       Service: userData.Service,
              //       Package: userData.Package,
              //       ParentName: userData.Name,
              //       PetName: userData.PetName,
              //       PickupSlot:pickupTime[pickupSlot],
              //       DropoffSlot:dropOffTime[dropOffSlot],
              //       NonVegMealAdded: nonvegMeal ? "Yes" : "No",
              //       ThirdMealAdded: thirdMeal ? "Yes" : "No",
              //       PetSize: selectedPetChoice === 1 ? "Small Pet" : "Big Pet",
              //       PackageAmount: selectedPetChoice===1?499:599,
              //       Days: daysDiff,
              //       PayNow:isPayNow
              //     }
              //   }
              // })
            }
          } catch (error) {
            console.log(bId, oId, amt);
            console.log(error);
          }
        };

        if (result.status === 200) {
          postGroomingPayment(bookingId, result.data.orderId, serviceAmount);
          // setNavTab(2);
        }
        // const Data = {
        //   ...result.data,
        //   SelectedService,
        //   DateOfReg,
        //   InputAddress,
        // };

        const actualAmount = serviceAmount / 100;

        const dataToStore = {
          Date: DateOfReg,
          Address: InputAddress,
          Contact_No: inputPhone.toString(),
          Amount_Paid: actualAmount.toString(),
          Selected_Service: SelectedService,
          Order_id: order_id,
        };

        await axios
          .post(
            "https://api.apispreadsheets.com/data/dHCUmWHZrnyATifS/",
            dataToStore
          )
          .then((response) => {
            console.log(response);
            console.log("The boarding payment has been completed!");
          })
          .catch((err) => {
            console.log(err);
            console.log(
              "There was some error in submitting the boarding payment to backend!"
            );
          });

        // setPaymentData(Data);
        // setFlag(true);
      },
      // prefill: {
      //     name: "Petmojo",
      //     email: "customercare@petmojo.in",
      //     contact: "+91 9009004473",
      // },
      notes: {
        address: "Petmojo",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  const fetchIpAddress = async () => {
    try {
      const response = await axios.get('https://api64.ipify.org?format=json');
      const ipAddress = response.data.ip;
      console.log(ipAddress);
      // Fetch geolocation information using the obtained IP address
      const geoResponse = await axios.get(`https://ipinfo.io/${ipAddress}?token=ebe17d64fb1b22`);
      console.log(geoResponse.data)
      // setIpInfo(geoResponse.data);
      setAddress(geoResponse.data.region);
    } catch (error) {
      console.error('Error fetching IP information:', error);
    }
  };
  console.log("city", address)
  useEffect(() => {
    // Fetch the user's dynamic IP address


    fetchIpAddress();
  }, []);

  const finalFormSubmitHandler = async (flag) => {
    console.log(userData.Phone);
    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${date.getMonth() + 1
      }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;
    // const inputPhone = groomingPhoneRef.current.value;

    if (userData.Name !== "" && checkPhone(userData.Phone).length === 10) {
      // console.log(userData);
      // console.log(userData.Phone)
      const config = {
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
      };
      setIsLoading(true);
      try {
        // console.log("hey1")
        const phone =
          userData.Phone.length > 10 &&
            (userData.Phone.startsWith("+91") || userData.Phone.startsWith("91"))
            ? userData.Phone.slice(-10)
            : userData.Phone;
        // console.log(phone)
        const res = await axios.post(
          `${baseUrl}/serviceBooking/postPetBoardingDetails`,
          {
            petDetails: [{ size: selectedPetChoice === 1 ? "small" : "big" }],
            phoneNumber: phone,
            address: address,
            startDate: startDate == "" ? formattedDate : startDate,
            endDate: endDate == "" ? formattedDate : endDate,
            isThirdMeal: thirdMeal,
            isNonVegMeal: nonvegMeal,
            petParentName: nameFill,
            isCouponCodeUsed: couponValue === 0 ? false : true,
            couponCode: couponValue !== 0 ? couponCode : "",
            couponValue: couponValue,
          },
          config
        );
        // console.log("hey2")
        console.log(res.data);
        console.log(res.status);
        if (flag) {
          const data = {
            ContactNo: userData.Phone,

            DateOfRegistration: dateOfReg,
            TimeOfRegistration: timeOfReg,
            Address: address,
            FormType: "Direct Form",
            ParentName: userData.Name,
            StartDate: startDate == "" ? formattedDate : startDate,
            EndDate: endDate == "" ? formattedDate : endDate,
            Service: userData.Service,
            PickupSlot: pickupTime[pickupSlot - 1],
            DropoffSlot: dropOffTime[dropOffSlot - 1],
            NonVegMealAdded: nonvegMeal ? "Yes" : "No",
            ThirdMealAdded: thirdMeal ? "Yes" : "No",
            PetSize: selectedPetChoice === 1 ? "Small Pet" : "Big Pet",
          };

          await axios
            .post(
              "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/saveBoardingData",
              data
            )
            .then((response) => {
              console.log(response);
              console.log("The boarding form is being submitted...");
              setIsLoading(false);
            })
            .catch((err) => {
              console.log(err);
              console.log(
                "There was some error in submitting the boarding form..."
              );
              setIsLoading(false);
            });
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: `Boarding Registration Fired From Mobile Service Page Form`,
          });
          setShowDownloadInvoice(true);
          var amount = 0;

          if (selectedPetChoice === 1) {
            amount += daysDiff * 499;
          } else {
            amount += daysDiff * 599;
          }

          if (daysDiff <= 10) {
            amount += 990;
          }

          if (thirdMeal) {
            amount += daysDiff * 59;
          }
          if (nonvegMeal) {
            amount += daysDiff * 99;
          }
          amount += 990;

          displayRazorpay(
            amount - couponValue,
            selectedService,
            dateOfReg,
            userData.Phone,
            userData.Address,
            res.data.bookingId
          );
        } else {
          // console.log("hello guys")
          const data = {
            ContactNo: userData.Phone,

            DateOfRegistration: dateOfReg,
            TimeOfRegistration: timeOfReg,
            Address: "",
            FormType: "Direct Form",
            ParentName: nameFill,
            StartDate: startDate == "" ? formattedDate : startDate,
            EndDate: endDate == "" ? formattedDate : endDate,
            Service: selectedService,
            PickupSlot: pickupTime[pickupSlot - 1],
            DropoffSlot: dropOffTime[dropOffSlot - 1],
            NonVegMealAdded: nonvegMeal ? "Yes" : "No",
            ThirdMealAdded: thirdMeal ? "Yes" : "No",
            PetSize: selectedPetChoice === 1 ? "Small Pet" : "Big Pet",
            location: address
          };

          await axios
            .post(
              "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/saveBoardingData",
              data
            )
            .then((response) => {
              console.log(response);
              console.log("The boarding form is being submitted...");
            })
            .catch((err) => {
              console.log(err);
              console.log(
                "There was some error in submitting the boarding form..."
              );
            });
          setShowDownloadInvoice(false);
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: `Boarding Registration Fired From Mobile Service Page Form`,
          });
          // setNavTab(2);
          // navigate("/ordersummaryboarding", {
          //   state: {
          //     data: {
          //       ContactNo: phone,
          //       Address: address,
          //       StartDate: startDate==""?formattedDate:startDate,
          //       Service: selectedService,
          //       Package: userData.Package,
          //       ParentName: userData.Name,
          //       PetName: userData.PetName,
          //       PickupSlot:pickupTime[pickupSlot],
          //       DropoffSlot:dropOffTime[dropOffSlot],
          //       NonVegMealAdded: nonvegMeal ? "Yes" : "No",
          //       ThirdMealAdded: thirdMeal ? "Yes" : "No",
          //       PetSize: selectedPetChoice === 1 ? "Small Pet" : "Big Pet",
          //       PackageAmount: selectedPetChoice===1?499:599,
          //       Days: daysDiff,
          //       PayNow:isPayNow
          //     },
          //   },
          // });
          navigate("/thankPage");
        }
      } catch (error) {
        console.log("error------->", error);
      }
    } else if (groomingNameRef.current.value === "") {
      console.log("err");
      setGroomingNameValidation(false);
    } else if (
      checkPhone(groomingPhoneRef.current.value.toString()).length !== 10
    ) {
      console.log("err123");
      setGroomingPhoneValidation(false);
    } else if (groomingAddressRef.current.value === "") {
      console.log("err");
      setGroomingAddressValidation(false);
    }

    if (nameFill === "" && checkPhone(userData.Phone).length !== 10) {
      console.log("err");
      setGroomingNameValidation(false);
      setGroomingPhoneValidation(false);
      setGroomingAddressValidation(false);
    }
  };
  const [changeColor, setChangeColor] = useState(false);

  const backArrow = () => {
    setSelectedCarousel(selectedCarousel - 1);
  };

  useEffect(() => {
    indicateAlert();
  }, [correctOtp]);

  useEffect(() => {
    decrementSeconds();
  }, [seconds]);

  const useLocation123 = () => {
    // const { coords } = useGeolocated();
    // console.log(coords);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        // setLocation({ latitude, longitude });
        console.log("asdf", latitude, longitude);
        setLat(latitude);
        setLon(longitude);
        // const result=getAddress(latitude,longitude);
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCeoWpdd6YOVu3wEqqA0qpY6P3B5rUumm8`;
        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            const { results } = data;
            if (results && results.length > 0) {
              setAddress(results[9].formatted_address);
              setGroomingAddressValidation(true);
              // console.log(address)
            }
          })
          .catch((error) => {
            console.log("Error:", error);
          });
      },
      (error) => {
        console.log(error);
      }
    );
  };
  return (
    <>
      {navTab === 1 && (
        <div
          className="grooming-page-mobile-view-form-container"
          style={{
            marginTop: location == "/" ? "0px" : "",
            width: location == "/" ? "100%" : "",
            border: location == "/" ? "0px" : "",
            borderRadius: location == "/" ? "0px" : "",
          }}
        >
          {location == "/pet-boarding" && (
            <div className="grooming-page-mobile-view-form-section-1">
              <div style={{ display: "flex", textAlign: "start" }}>
                <div style={{ width: selectedCarousel === 1 ? "0%" : "10%" }}>
                  <BiArrowBack
                    style={{
                      display: selectedCarousel === 1 ? "none" : "block",
                    }}
                    onClick={backArrow}
                  />
                </div>
                <div
                  style={{
                    width: selectedCarousel === 1 ? "100%" : "90%",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                  onClick={scrollToSection}
                >
                  {selectedCarousel == 1 || selectedCarousel == 2 ? (
                    <>
                      Book Here!{" "}
                      <span
                        className="pink"
                        style={{ fontSize: "14px", textTransform: "none" }}
                      >
                        {" "}
                        Limited Slots Available
                      </span>
                    </>
                  ) : (
                    <>
                      <div style={{ fontSize: "12px" }}>
                        Choose Start Date / Select Pick-Up & Drop-Off
                      </div>{" "}
                      <div className="pink" style={{ fontSize: "12px" }}>
                        {" "}
                        Free Pick up (for more than 40 days of stay)
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}

          {selectedCarousel == 1 ? (
            <div
              className="grooming-page-mobile-view-services-container"
              ref={sectionRef}
            >
              <div className="select-service">
                <div className="dateandtime">
                  <div className="select-slot">
                    <div
                      style={{
                        color: "#5E5E5E",
                        fontWeight: "700",
                        marginBottom: "5px",
                      }}
                    >
                      Select Resort Location
                    </div>
                    {!groomingSlotValidation ? (
                      <div className="home-page-walking-form-mobile-feedback">
                        Required
                      </div>
                    ) : null}
                    <select
                      name="slot"
                      id="slot"
                      ref={groomingSlotRef}
                      onChange={() => {
                        // firstFormDataHandler();
                        setGroomingSlotValidation(true);

                        setSlotValue(true);
                        setSlotFill(groomingSlotRef.current.value);
                        setUserData({
                          ...userData,
                          Location: groomingSlotRef.current.value,
                        });
                      }}
                      required
                      style={{ fontSize: "14px" }}
                      // value={userData.Slot}
                      value={slotValue ? slotFill : ""}
                    >
                      <option value="GURGAON (SEC 58)">GURGAON (SEC 58)</option>
                      <option value="FARIDABAD (SEC 72)">
                        FARIDABAD (SEC 72)
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="phone" style={{ marginTop: "10px" }}>
                <div
                  className="label"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>Phone number</span>
                  {!groomingPhoneValidation ? (
                    <div className="home-page-walking-form-mobile-feedback">
                      required
                    </div>
                  ) : null}
                </div>
                <div className="number-box">
                  <input
                    ref={groomingPhoneRef}
                    // disabled={isVerified}
                    onChange={(e) => {
                      // secondFormDataHandler();
                      setGroomingPhoneValidation(true);
                      // setUserData({
                      //   ...userData,
                      //   Phone:groomingPhoneRef.current.value
                      // })
                      setPhoneValue(true);
                      setPhoneFill(e.target.value);
                      setUserData({
                        ...userData,
                        Phone: e.target.value,
                      });
                      // changeButtonColor();
                    }}
                    type="number"
                    placeholder="Enter phone number"
                    required
                    style={{ fontSize: "17px", width: "100%" }}
                    value={phoneValue ? phoneFill : ""}

                  // value={userData.Phone}
                  />
                </div>
              </div>
              <div style={{ width: "100%", marginTop: "20px" }}>
                <span
                  style={{
                    color: "#5E5E5E",
                    fontWeight: "700",
                    fontSize: "15px",
                  }}
                >
                  Includes
                </span>
                <div className="desc">
                  <ul>
                    <li
                      style={{
                        fontSize: "13px",
                        color: "rgba(131, 145, 161, 1)",
                        fontWeight: "500",
                      }}
                    >
                      Boarding for{" "}
                      <span
                        style={{
                          fontWeight: "700",
                          color: "rgba(88, 88, 88, 1)",
                        }}
                      >
                        Rabbit, Guinea Pigs, Cats & Dogs
                      </span>{" "}
                    </li>
                    <li
                      style={{
                        fontSize: "13px",
                        color: "rgba(131, 145, 161, 1)",
                        fontWeight: "500",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "700",
                          color: "rgba(88, 88, 88, 1)",
                        }}
                      >
                        7 Times Water
                      </span>{" "}
                      change and{" "}
                      <span
                        style={{
                          fontWeight: "700",
                          color: "rgba(88, 88, 88, 1)",
                        }}
                      >
                        2 Meals
                      </span>{" "}
                      included
                    </li>
                    <li
                      style={{
                        fontSize: "13px",
                        color: "rgba(131, 145, 161, 1)",
                        fontWeight: "500",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "700",
                          color: "rgba(88, 88, 88, 1)",
                        }}
                      >
                        CCTV Cameras
                      </span>{" "}
                      all around{" "}
                    </li>
                    <li
                      style={{
                        fontSize: "13px",
                        color: "rgba(131, 145, 161, 1)",
                        fontWeight: "500",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "700",
                          color: "rgba(88, 88, 88, 1)",
                        }}
                      >
                        24x7 Vets
                      </span>{" "}
                      on Resort
                    </li>
                    <li
                      style={{
                        fontSize: "13px",
                        color: "rgba(131, 145, 161, 1)",
                        fontWeight: "500",
                      }}
                    >
                      Daily{" "}
                      <span
                        style={{
                          fontWeight: "700",
                          color: "rgba(88, 88, 88, 1)",
                        }}
                      >
                        LIVE Updates
                      </span>{" "}
                      on Petmojo Application
                    </li>
                    <li
                      style={{
                        fontSize: "13px",
                        color: "rgba(131, 145, 161, 1)",
                        fontWeight: "500",
                      }}
                    >
                      {/* 24/7 day and night{" "} */}
                      <span
                        style={{
                          fontWeight: "700",
                          color: "rgba(88, 88, 88, 1)",
                        }}
                      >
                        Dedicated Caretakers
                      </span>
                    </li>
                    <li
                      style={{
                        fontSize: "13px",
                        color: "rgba(131, 145, 161, 1)",
                        fontWeight: "500",
                      }}
                    >
                      Empathetic & Friendly{" "}
                      <span
                        style={{
                          fontWeight: "700",
                          color: "rgba(88, 88, 88, 1)",
                        }}
                      >
                        Canine Handlers
                      </span>
                    </li>
                    <li
                      style={{
                        fontSize: "13px",
                        color: "rgba(131, 145, 161, 1)",
                        fontWeight: "500",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "700",
                          color: "rgba(88, 88, 88, 1)",
                        }}
                      >
                        11 Feet Fencing
                      </span>{" "}
                      {/* & <span style={{ fontWeight: "700",color:"rgba(88, 88, 88, 1)" }}>AC Van</span> Pick
                      up and Drop */}
                    </li>
                    <li
                      style={{
                        fontSize: "13px",
                        color: "rgba(131, 145, 161, 1)",
                        fontWeight: "500",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "700",
                          color: "rgba(88, 88, 88, 1)",
                        }}
                      >
                        AC Van
                      </span>{" "}
                      Pickup and Drop
                    </li>
                    <li
                      style={{
                        fontSize: "13px",
                        color: "rgba(131, 145, 161, 1)",
                        fontWeight: "500",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "700",
                          color: "rgba(88, 88, 88, 1)",
                        }}
                      >
                        Free Pick-Up
                      </span>{" "}
                      (for more than 40 days of stay)
                    </li>
                    <li
                      style={{
                        fontSize: "13px",
                        color: "rgba(131, 145, 161, 1)",
                        fontWeight: "500",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "700",
                          color: "rgba(88, 88, 88, 1)",
                        }}
                      >
                        Free Pick-Up not applicable for CBT Boarding & Training
                      </span>{" "}
                    </li>
                  </ul>
                </div>
              </div>
              <center>
                <div className="grooming-new-form-section1-carousel-container">
                  <div className="grooming-new-form-section1-carousel-active"></div>
                  <div className="grooming-new-form-section1-carousel"></div>
                  <div className="grooming-new-form-section1-carousel"></div>
                </div>
              </center>
              <div className="button-group">
                {groomingLoading ? (
                  <center>
                    <LoadingSpinner />
                  </center>
                ) : (
                  <button
                    onClick={() => {
                      // firstFormDataHandler();
                      // setFormSection(2);

                      firstFromBoardingHandler();
                      // displayLocation();
                    }}
                    className="next"
                  >
                    Next
                  </button>
                )}
              </div>

              <div>
                {/* <img src={iconsMobile} style={{ width: "21rem" }} /> */}
              </div>
              <div className='training-page-mobile-view-form-footer' style={{ margin: "10px 10px 10px 14px", }}>
                <i>We are mission driven & a Family of Pet Lovers</i>
              </div>
            </div>
          ) : null}

          {/* 2nd form section */}
          {selectedCarousel == 2 ? (
            <div className="grooming-page-mobile-view-services-container">
              <div className="select-service">
                <span style={{ color: "#5E5E5E", fontWeight: "700" }}>
                  {/* Resort Stay & Training */}
                  Select size of your pet
                </span>
                <div className="options-box">
                  <div
                    className="option"
                    id="one"
                    style={{ width: "100%", display: "flex" }}
                    onClick={() => {
                      setSelectedPetChoice(1);
                    }}
                  >
                    <div
                      className="heading"
                      style={{ marginBottom: "5px", width: "100%" }}
                    >
                      <div
                        className="left"
                        onClick={() => {
                          setSelectedPet("Small Pet");
                          setUserData({
                            ...userData,
                            Pet: "Small Pet",
                          });
                          // console.log("Small Pet");
                        }}
                      >
                        <div className="selection-circle">
                          {selectedPetChoice == 1 && (
                            <div className="dot"></div>
                          )}
                        </div>
                        <div style={{ display: "flex", gap: "5px" }}>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Dog.png"
                            alt=""
                            style={{ width: "25px", height: "25px" }}
                          />
                          <span style={{ fontWeight: "500" }}>
                            Small Pet{" "}
                            <small style={{ fontSize: "13px" }}>
                              {" "}
                              <br />
                            </small>
                          </span>
                        </div>
                      </div>
                      <div
                        className="right"
                        style={{ fontSize: "12px", width: "40%" }}
                      >
                        ₹ 499/-
                        <span style={{ color: "#FF5E95", fontSize: "12px" }}>
                          Per Night
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="option"
                    id="two"
                    style={{ width: "100%", display: "flex" }}
                    onClick={() => {
                      setSelectedPetChoice(2);
                    }}
                  >
                    <div
                      className="heading"
                      style={{ marginBottom: "5px", width: "100%" }}
                    >
                      <div
                        className="left"
                        onClick={() => {
                          setSelectedPet("Big Pet");
                          setUserData({
                            ...userData,
                            Pet: "Big Pet",
                          });
                          // console.log("Big Pet");
                        }}
                      >
                        <div className="selection-circle">
                          {selectedPetChoice == 2 && (
                            <div className="dot"></div>
                          )}
                        </div>
                        <div style={{ display: "flex", gap: "5px" }}>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/bigpet.png"
                            alt=""
                            style={{ width: "25px", height: "25px" }}
                          />
                          <span style={{ fontWeight: "500" }}>
                            Big Pet{" "}
                            <small style={{ fontSize: "13px" }}>
                              {" "}
                              <br />
                            </small>
                          </span>
                        </div>
                      </div>
                      <div
                        className="right"
                        style={{ fontSize: "12px", width: "40%" }}
                      >
                        ₹ 599/-
                        <span style={{ color: "#FF5E95", fontSize: "12px" }}>
                          Per Night
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="name"
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <div
                  className="label"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>Pet Parent Name</span>
                  {!groomingNameValidation ? (
                    <div className="home-page-walking-form-mobile-feedback">
                      required
                    </div>
                  ) : null}
                </div>
                <div>
                  <input
                    ref={groomingNameRef}
                    onChange={(e) => {
                      // secondFormDataHandler();
                      setGroomingNameValidation(true);
                      setnameValue(true);
                      setNameFill(e.target.value);
                      setUserData({
                        ...userData,
                        Name: e.target.value,
                      });
                      console.log(e.target.value);
                    }}
                    type="text"
                    placeholder="Enter your name"
                    required
                    style={{ fontSize: "17px" }}
                    value={nameValue ? nameFill : ""}
                  />
                </div>
              </div>
              <div
                style={{
                  padding: "10px",
                  marginTop: "10px",
                  marginBottom: "30px",
                  width: "100%",
                }}
              >
                <ImageSliderWalking images={images} />
              </div>
              {/* <div className="name">
                <div className="label">
                  <span>Pet Name</span>
                  {!groomingPetNameValidation ? (
                    <div className="home-page-walking-form-mobile-feedback">
                      required
                    </div>
                  ) : null}
                </div>
                <div>
                  <input
                    ref={groomingPetNameRef}
                    onChange={(e) => {
                      // secondFormDataHandler();
                      setGroomingPetNameValidation(true);
                      setPetnameValue(true);
                      setPetnameFill(e.target.value);
                      setUserData({
                        ...userData,
                        PetName: e.target.value,
                      });
                      console.log(e.target.value);
                    }}
                    type="text"
                    placeholder="Enter your name"
                    required
                    style={{ fontSize: "17px" }}
                    value={petnameValue ? petnameFill : ""}
                  />
                </div>
              </div> */}
              {/* <div className="phone">
                <div className="label">
                  <span>Phone number</span>
                  {!groomingPhoneValidation ? (
                    <div className="home-page-walking-form-mobile-feedback">
                      required
                    </div>
                  ) : null}
                </div>
                <div className="number-box">
                  <input
                    ref={groomingPhoneRef}
                    // disabled={isVerified}
                    onChange={(e) => {
                      secondFormDataHandler();
                      setGroomingPhoneValidation(true);
                      // setUserData({
                      //   ...userData,
                      //   Phone:groomingPhoneRef.current.value
                      // })
                      setPhoneValue(true);
                      setPhoneFill(e.target.value);
                      setUserData({
                        ...userData,
                        Phone: e.target.value,
                      });
                      // changeButtonColor();
                    }}
                    type="number"
                    placeholder="Enter phone number"
                    required
                    style={{ fontSize: "17px" }}
                    value={phoneValue ? phoneFill : ""}

                    // value={userData.Phone}
                  />
                  <button
                    id="send-otp"
                    disabled={isVerified}
                    onClick={() => {
                      setChangeColor(true);
                      getmobileOtp();
                      setShowEnterOtp(true);
                      changeName();
                      {
                        seconds === 0 ? setSeconds(33) : setSeconds(0);
                      }
                      handleSubmit();
                    }}
                    style={{
                      borderColor: correctOtp ? "white" : "red",
                      backgroundColor:
                        phoneFill?.length >= 10 ? "rgb(69, 163, 165)" : "",
                      color: phoneFill?.length >= 10 ? "white" : "",
                    }}
                  >
                    Send OTP
                  </button>
                </div>
                {countdown && seconds != 0 ? (
                  <div
                    id="countdown-seconds"
                    style={{
                      textAlign: "right",
                      fontSize: "15px",
                      position: "relative",
                      right: "30px",
                    }}
                  >
                    {seconds} sec
                  </div>
                ) : null}
                {showEnterOtp &&
                  (groomingPhoneRef.current.value.toString().length === 10 ||
                    showDialog) && (
                    <div className="enterotp">
                      <span>Enter OTP</span>
                      <br />
                      {isWrongOtp && !isVerified ? (
                        <>
                          <div className="home-page-walking-form-mobile-feedback">
                            Wrong OTP
                          </div>
                        </>
                      ) : null}
                      <input
                        ref={groomingOtpRef}
                        onClick={() => {
                          setIsWrongOtp(false);
                        }}
                        disabled={isVerified}
                        type="number"
                        placeholder="Enter Otp"
                        value={otp}
                        onChange={handleOtpChange}
                        onPaste={handlePaste}
                        autoComplete="one-time-code"
                        style={{ fontSize: "17px" }}
                      />

                      <button
                        disabled={isVerified}
                        onClick={() => {
                          // handleSubmit();
                          verifyMobileOTP();
                          indicateAlert();
                        }}
                        id="verifyButton"
                        style={{
                          border: "2px solid #DEC269",
                          backgroundColor:
                            phoneFill?.length >= 10 ? "rgb(69, 163, 165)" : "",
                          color: phoneFill?.length >= 10 ? "white" : "",
                        }}
                      >
                        {isVerified ? (
                          <>
                            <img src={greenTick} width={15} />{" "}
                            <span style={{ marginLeft: "5px", color: "white" }}>
                              Verified
                            </span>
                          </>
                        ) : (
                          "Verify"
                        )}
                      </button>
                    </div>
                  )}
              </div>
              <div className="address">
                <div className="label">
                  <span>Address</span>
                  {!groomingAddressValidation ? (
                    <div className="home-page-walking-form-mobile-feedback">
                      Enter your home address
                    </div>
                  ) : null}
                </div>
                <div>
                  <input
                    ref={groomingAddressRef}
                    onChange={(e) => {
                      // secondFormDataHandler();
                      setGroomingAddressValidation(true);
                      // setAdressFill(true);
                      setAddress(e.target.value);
                     
                    }}
                    type="text"
                    placeholder="Enter your Home address"
                    required
                    style={{ fontSize: "17px" }}
                    value={address}
                    onClick={useLocation123}
                  />
                </div>
              </div> */}

              <center>
                <div
                  className="grooming-new-form-section1-carousel-container"
                  style={{ paddingRight: "20px" }}
                >
                  <div className="grooming-new-form-section1-carousel-active"></div>
                  <div className="grooming-new-form-section1-carousel-active"></div>
                  <div className="grooming-new-form-section1-carousel-active"></div>
                </div>
              </center>

              <div className="button-group">
                <button
                  className="next"
                  onClick={() => {
                    // secondFormDataHandler();
                    // firstFormDataHandler();
                    // nextNavhandler();
                    secondFormBoardingHandler();
                    // setSelectedCarousel(3);

                    // setFormSection(3);
                    // setSelectedCarousel(3);
                  }}
                  style={{ color: "black" }}
                >
                  Next
                </button>
              </div>
            </div>
          ) : null}

          {selectedCarousel == 3 ? (
            <>
              <div className="grooming-page-mobile-view-services-container">
                <div className="dateandtime">
                  <div className="select-date">
                    <div style={{ color: "#5E5E5E", fontWeight: "700" }}>
                      Start Date
                    </div>
                    {!groomingDateValidation ? (
                      <div className="home-page-walking-form-mobile-feedback">
                        Required
                      </div>
                    ) : null}

                    <div
                      className="walking-section-form-input-date-mobile"
                      style={{
                        marginBottom: "20px",
                        position: "relative",
                        border: "none",
                        height: "56px",
                      }}
                    >
                      <input
                        ref={groomingDateRef}
                        onChange={() => {
                          // firstFormDataHandler();
                          // secondFormDataHandler();
                          setGroomingDateValidation(true);
                          setStartValue(true);
                          setStartDate(groomingDateRef.current.value);
                          setIsStartDateSelected(true);
                        }}
                        type="date"
                        className="walking-section-form-input-date-mobile"
                        placeholder="DD/MM/YYYY"
                        required
                        style={{
                          width: "100%",
                          height: "100%",
                          fontSize: "17px",
                        }}
                        value={startValue ? startDate : formattedDate}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    position: "relative",
                    bottom: "10px",
                  }}
                >
                  <div>
                    <input
                      type="checkbox"
                      id="pickup"
                      name="pickup"
                      value="pickup"
                      checked={pickup}
                      onChange={() => setPickup((prev) => !prev)}
                    // disabled={displaySlots}
                    />
                  </div>
                  <div>
                    <label style={{ fontSize: "12px" }}>
                      Pickup Required? (AC Van can be availed @ ₹990/-)
                    </label>
                  </div>
                </div>

                <div
                  className="grooming-new-form-section2-right-time-slot-container"
                  style={{
                    width: "100%",
                    display: !pickup ? "none" : "block",
                  }}
                >
                  <div>Pickup Time</div>
                  <div className="grooming-new-form-section2-right-time-slots">
                    <div
                      onClick={() => setPickupSlot(1)}
                      className={
                        pickupSlot == 1
                          ? "grooming-new-form-section2-right-time-slot-active"
                          : "grooming-new-form-section2-right-time-slot"
                      }
                      style={{ fontSize: "12px" }}
                    >
                      10AM-02PM
                    </div>
                    <div
                      onClick={() => setPickupSlot(2)}
                      className={
                        pickupSlot == 2
                          ? "grooming-new-form-section2-right-time-slot-active"
                          : "grooming-new-form-section2-right-time-slot"
                      }
                      style={{ fontSize: "12px" }}
                    >
                      02PM-06PM
                    </div>
                    <div
                      onClick={() => setPickupSlot(3)}
                      className={
                        pickupSlot == 3
                          ? "grooming-new-form-section2-right-time-slot-active"
                          : "grooming-new-form-section2-right-time-slot"
                      }
                      style={{ fontSize: "12px" }}
                    >
                      06PM-10PM
                    </div>
                  </div>
                </div>
                <div className="dateandtime">
                  <div className="select-date">
                    <div style={{ color: "#5E5E5E", fontWeight: "700" }}>
                      End Date
                    </div>
                    {!groomingEndDateValidation ? (
                      <div className="home-page-walking-form-mobile-feedback">
                        Required
                      </div>
                    ) : null}

                    <div
                      className="walking-section-form-input-date-mobile"
                      style={{
                        marginBottom: "20px",
                        position: "relative",
                        border: "none",
                        height: "56px",
                      }}
                    >
                      <input
                        ref={groomingEndDateRef}
                        onChange={() => {
                          // firstFormDataHandler();
                          // secondFormDataHandler();
                          setGroomingEndDateValidation(true);
                          setEndValue(true);
                          setEndDate(groomingEndDateRef.current.value);
                          setIsEndDateSelected(true);
                        }}
                        type="date"
                        className="walking-section-form-input-date-mobile"
                        placeholder="DD/MM/YYYY"
                        required
                        style={{
                          width: "100%",
                          height: "100%",
                          fontSize: "17px",
                        }}
                        value={endValue ? endDate : formattedDate}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    position: "relative",
                    bottom: "10px",
                  }}
                >
                  <div>
                    <input
                      type="checkbox"
                      id="pickup"
                      name="pickup"
                      value="pickup"
                      checked={dropOff}
                      onChange={() => setDropOff((prev) => !prev)}
                    // disabled={displaySlots}
                    />
                  </div>
                  <div>
                    <label style={{ fontSize: "12px" }}>
                      Dropoff Required? (AC Van can be availed @ ₹990/-)
                    </label>
                  </div>
                </div>
                <div
                  className="grooming-new-form-section2-right-time-slot-container"
                  style={{
                    width: "100%",
                    display: !dropOff ? "none" : "block",
                  }}
                >
                  <div>Dropoff Time</div>
                  <div className="grooming-new-form-section2-right-time-slots">
                    <div
                      onClick={() => setDropOffSlot(1)}
                      className={
                        dropOffSlot == 1
                          ? "grooming-new-form-section2-right-time-slot-active"
                          : "grooming-new-form-section2-right-time-slot"
                      }
                      style={{ fontSize: "12px" }}
                    >
                      10AM-02PM
                    </div>
                    <div
                      onClick={() => setDropOffSlot(2)}
                      className={
                        dropOffSlot == 2
                          ? "grooming-new-form-section2-right-time-slot-active"
                          : "grooming-new-form-section2-right-time-slot"
                      }
                      style={{ fontSize: "12px" }}
                    >
                      02PM-06PM
                    </div>
                    <div
                      onClick={() => setDropOffSlot(3)}
                      className={
                        dropOffSlot == 3
                          ? "grooming-new-form-section2-right-time-slot-active"
                          : "grooming-new-form-section2-right-time-slot"
                      }
                      style={{ fontSize: "12px" }}
                    >
                      06PM-10PM
                    </div>
                  </div>
                </div>
                <div
                  className="name"
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  <div className="label">
                    <span>
                      Coupon Code
                      <span style={{ fontStyle: "italic" }}>
                        (If Available)
                      </span>
                    </span>
                    {couponValid && (
                      <div
                        style={{
                          color: "#618E41",
                          fontSize: "10px",
                          display: "inline-block",
                        }}
                      >
                        &nbsp;Code applied successfully
                      </div>
                    )}
                    {couponNotValid && (
                      <div
                        style={{
                          color: "red",
                          fontSize: "10px",
                          display: "inline-block",
                        }}
                      >
                        &nbsp;Invalid Code
                      </div>
                    )}
                  </div>
                  <div className="grooming-new-form-section3-right-ph-input-contact">
                    <input
                      id="coupon"
                      type="text"
                      className="grooming-new-form-desktop-input-container-type1"
                      onChange={(e) => {
                        setCouponCode(e.target.value);
                      }}
                      style={{ fontSize: "17px" }}
                    />
                    {!couponValid && !couponNotValid && (
                      <div
                        className="coupon-apply-button"
                        onClick={applyCoupon}
                      >
                        <span style={{ paddingRight: "0.5rem" }}>Apply</span>{" "}
                        <FaArrowAltCircleRight className="coupon-apply-button-icon" />
                      </div>
                    )}

                    {couponValid && (
                      <div
                        className="coupon-applied-text"
                        style={{ color: "#618E41;" }}
                      >
                        <span style={{ marginRight: "8px" }}>
                          ₹ {couponValue} /- OFF
                        </span>{" "}
                        <FaCheck className="check-mark" />
                      </div>
                    )}
                    {couponNotValid && (
                      <div className="coupon-not-valid">
                        <MdClose className="x-mark" />
                      </div>
                    )}
                  </div>
                </div>
                <center>
                  <div className="grooming-new-form-section1-carousel-container">
                    <div className="grooming-new-form-section1-carousel-active"></div>
                    <div className="grooming-new-form-section1-carousel-active"></div>
                    <div className="grooming-new-form-section1-carousel"></div>
                  </div>
                </center>
                {isLoading ? (
                  <center style={{ marginTop: "10px" }}>
                    <LoadingSpinner />
                  </center>
                ) : (
                  <div className="button-group" style={{ marginTop: "20px" }}>
                    <button
                      // disabled={isVerified === false || isVerified === null}
                      className="pay-now"
                      onClick={() => {
                        finalFormSubmitHandler(true);
                      }}
                    // style={{backgroundColor:!isVerified?"rgba(131, 145, 161, 1)":"",border:!isVerified?"2px solid rgba(131, 145, 161, 1)":""}}
                    >
                      Book Now
                    </button>
                    {/* {location==="/grooming"? */}
                    <button
                      // onClick={() => {
                      // //   finalFormSubmitHandler(false);
                      // submitHandler();
                      //   setBookNow(true);
                      // }}
                      onClick={() => finalFormSubmitHandler(false)}
                      className="pay-later"
                    // style={{backgroundColor:!isVerified?"white":"",border:!isVerified?"2px solid rgba(131, 145, 161, 1)":"",color:!isVerified?"rgba(131, 145, 161, 1)":""}}
                    >
                      Book Now & Pay Later
                    </button>
                    {/* // :null} */}
                  </div>
                )}
              </div>
            </>
          ) : // <>
            //   {selectedChoice !== 0 ? (
            //     <div className="grooming-page-mobile-view-services-container">
            //       <div className="dateandtime">
            //         <div className="select-date">
            //           <div style={{ color: "#5E5E5E", fontWeight: "700" }}>
            //             Start Date
            //           </div>
            //           {!groomingDateValidation ? (
            //             <div className="home-page-walking-form-mobile-feedback">
            //               Required
            //             </div>
            //           ) : null}

            //           <div
            //             className="walking-section-form-input-date-mobile"
            //             style={{
            //               marginBottom: "20px",
            //               position: "relative",
            //               border: "none",
            //               height: "56px",
            //             }}
            //           >
            //             <input
            //               ref={groomingDateRef}
            //               onChange={() => {
            //                 firstFormDataHandler();
            //                 // secondFormDataHandler();
            //                 setGroomingDateValidation(true);
            //                 setStartValue(true);
            //                 setStartDate(groomingDateRef.current.value);
            //               }}
            //               type="date"
            //               className="walking-section-form-input-date-mobile"
            //               placeholder="DD/MM/YYYY"
            //               required
            //               style={{
            //                 width: "100%",
            //                 height: "100%",
            //                 fontSize: "17px",
            //               }}
            //               value={startValue ? startDate : formattedDate}
            //             />
            //           </div>

            //           {/* <DatePicker ref={groomingDateRef} dateFormat="yyyy-MM-dd"  onChange={(date) => { firstFormDataHandler();
            //                             setGroomingDateValidation(true);

            //                             setSessionDate(date) }} className='walking-section-form-input-date-mobile' placeholderText='DD/MM/YYYY'  id='inputId' selected={sessionDate}  /> */}
            //         </div>
            //         {/* <div className="select-slot">
            //       <div style={{ color: "#5E5E5E", fontWeight: "700" }}>
            //         Time Slot
            //       </div>
            //       {!groomingSlotValidation ? (
            //         <div className="home-page-walking-form-mobile-feedback">
            //           Required
            //         </div>
            //       ) : null}
            //       <select
            //         name="slot"
            //         id="slot"
            //         ref={groomingSlotRef}
            //         onChange={() => {
            //           firstFormDataHandler();
            //           setGroomingSlotValidation(true);
            //           // setUserData({
            //           //   ...userData,
            //           //   Slot:groomingSlotRef.current.value
            //           // })
            //           setSlotValue(true);
            //           setSlotFill(groomingSlotRef.current.value);
            //         }}
            //         // onSelect={()=>{
            //         //   setSlotValue(true);
            //         //   setSlotFill(groomingSlotRef.current.value);
            //         // }}
            //         style={{ fontSize: "16px" }}
            //         // value={userData.Slot}
            //         value={slotValue ? slotFill : ""}
            //       >
            //         <option value="">Select Slot</option>
            //         <option value="10AM - 02PM">10AM - 02PM</option>
            //         <option value="02PM - 06PM">02PM - 06PM</option>
            //         <option value="06PM - 10PM">06PM - 10PM</option>
            //       </select>
            //     </div> */}
            //       </div>
            //       <center>
            //         <div className="grooming-new-form-section1-carousel-container">
            //           <div className="grooming-new-form-section1-carousel-active"></div>
            //           <div className="grooming-new-form-section1-carousel-active"></div>
            //           <div className="grooming-new-form-section1-carousel"></div>
            //         </div>
            //       </center>
            //       <div className="button-group">
            //         <button
            //           className="next"
            //           onClick={() => {
            //             // secondFormDataHandler();
            //             // firstFormDataHandler();
            //             nextNavhandler();

            //             // setFormSection(3);
            //             // setSelectedCarousel(3);
            //           }}
            //         >
            //           Next
            //         </button>
            //       </div>
            //     </div>
            //   ) : (
            //     <div className="grooming-page-mobile-view-services-container">
            //       <div className="dateandtime">
            //         <div className="select-date">
            //           <div style={{ color: "#5E5E5E", fontWeight: "700" }}>
            //             Start Date
            //           </div>
            //           {!groomingDateValidation ? (
            //             <div className="home-page-walking-form-mobile-feedback">
            //               Required
            //             </div>
            //           ) : null}

            //           <div
            //             className="walking-section-form-input-date-mobile"
            //             style={{
            //               marginBottom: "20px",
            //               position: "relative",
            //               border: "none",
            //               height: "56px",
            //             }}
            //           >
            //             <input
            //               ref={groomingDateRef}
            //               onChange={() => {
            //                 firstFormDataHandler();
            //                 // secondFormDataHandler();
            //                 setGroomingDateValidation(true);
            //                 setStartValue(true);
            //                 setStartDate(groomingDateRef.current.value);
            //                 setIsStartDateSelected(true);
            //               }}
            //               type="date"
            //               className="walking-section-form-input-date-mobile"
            //               placeholder="DD/MM/YYYY"
            //               required
            //               style={{
            //                 width: "100%",
            //                 height: "100%",
            //                 fontSize: "17px",
            //               }}
            //               value={startValue ? startDate : formattedDate}
            //             />
            //           </div>
            //         </div>

            //       </div>
            //       <div
            //         style={{
            //           width: "100%",
            //           display: "flex",
            //           position: "relative",
            //           bottom: "10px",
            //         }}
            //       >
            //         <div>
            //           <input
            //             type="checkbox"
            //             id="pickup"
            //             name="pickup"
            //             value="pickup"
            //             onChange={() => setPickup((prev) => !prev)}
            //             disabled={displaySlots}
            //           />
            //         </div>
            //         <div>
            //           <label style={{ fontSize: "12px" }}>
            //             Pickup Required? (AV Van can be availed @ ₹899/-)
            //           </label>
            //         </div>
            //       </div>
            //       {/* <div style={{width:"100%",display:"flex",flexWrap:"wrap",justifyContent:"space-evenly"}}>

            //   </div> */}
            //       <div
            //         className="grooming-new-form-section2-right-time-slot-container"
            //         style={{
            //           width: "100%",
            //           display: pickup ? "none" : "block",
            //         }}
            //       >
            //         <div>Pickup Time</div>
            //         <div className="grooming-new-form-section2-right-time-slots">
            //           <div
            //             onClick={() => setPickupSlot(0)}
            //             className={
            //               pickupSlot == 0
            //                 ? "grooming-new-form-section2-right-time-slot-active"
            //                 : "grooming-new-form-section2-right-time-slot"
            //             }
            //             style={{ fontSize: "12px" }}
            //           >
            //             10AM-02PM
            //           </div>
            //           <div
            //             onClick={() => setPickupSlot(1)}
            //             className={
            //               pickupSlot == 1
            //                 ? "grooming-new-form-section2-right-time-slot-active"
            //                 : "grooming-new-form-section2-right-time-slot"
            //             }
            //             style={{ fontSize: "12px" }}
            //           >
            //             02PM-06PM
            //           </div>
            //           <div
            //             onClick={() => setPickupSlot(2)}
            //             className={
            //               pickupSlot == 2
            //                 ? "grooming-new-form-section2-right-time-slot-active"
            //                 : "grooming-new-form-section2-right-time-slot"
            //             }
            //             style={{ fontSize: "12px" }}
            //           >
            //             06PM-10PM
            //           </div>
            //         </div>
            //       </div>
            //       <div className="dateandtime">
            //         <div className="select-date">
            //           <div style={{ color: "#5E5E5E", fontWeight: "700" }}>
            //             End Date
            //           </div>
            //           {!groomingEndDateValidation ? (
            //             <div className="home-page-walking-form-mobile-feedback">
            //               Required
            //             </div>
            //           ) : null}

            //           <div
            //             className="walking-section-form-input-date-mobile"
            //             style={{
            //               marginBottom: "20px",
            //               position: "relative",
            //               border: "none",
            //               height: "56px",
            //             }}
            //           >
            //             <input
            //               ref={groomingEndDateRef}
            //               onChange={() => {
            //                 firstFormDataHandler();
            //                 // secondFormDataHandler();
            //                 setGroomingEndDateValidation(true);
            //                 setEndValue(true);
            //                 setEndDate(groomingEndDateRef.current.value);
            //                 setIsEndDateSelected(true);
            //               }}
            //               type="date"
            //               className="walking-section-form-input-date-mobile"
            //               placeholder="DD/MM/YYYY"
            //               required
            //               style={{
            //                 width: "100%",
            //                 height: "100%",
            //                 fontSize: "17px",
            //               }}
            //               value={endValue ? endDate : formattedDate}
            //             />
            //           </div>

            //           {/* <DatePicker ref={groomingDateRef} dateFormat="yyyy-MM-dd"  onChange={(date) => { firstFormDataHandler();
            //                             setGroomingDateValidation(true);

            //                             setSessionDate(date) }} className='walking-section-form-input-date-mobile' placeholderText='DD/MM/YYYY'  id='inputId' selected={sessionDate}  /> */}
            //         </div>
            //         {/* <div className="select-slot">
            //       <div style={{ color: "#5E5E5E", fontWeight: "700" }}>
            //         Time Slot
            //       </div>
            //       {!groomingSlotValidation ? (
            //         <div className="home-page-walking-form-mobile-feedback">
            //           Required
            //         </div>
            //       ) : null}
            //       <select
            //         name="slot"
            //         id="slot"
            //         ref={groomingSlotRef}
            //         onChange={() => {
            //           firstFormDataHandler();
            //           setGroomingSlotValidation(true);
            //           // setUserData({
            //           //   ...userData,
            //           //   Slot:groomingSlotRef.current.value
            //           // })
            //           setSlotValue(true);
            //           setSlotFill(groomingSlotRef.current.value);
            //         }}
            //         // onSelect={()=>{
            //         //   setSlotValue(true);
            //         //   setSlotFill(groomingSlotRef.current.value);
            //         // }}
            //         style={{ fontSize: "16px" }}
            //         // value={userData.Slot}
            //         value={slotValue ? slotFill : ""}
            //       >
            //         <option value="">Select Slot</option>
            //         <option value="10AM - 02PM">10AM - 02PM</option>
            //         <option value="02PM - 06PM">02PM - 06PM</option>
            //         <option value="06PM - 10PM">06PM - 10PM</option>
            //       </select>
            //     </div> */}
            //       </div>
            //       <div
            //         style={{
            //           width: "100%",
            //           display: "flex",
            //           position: "relative",
            //           bottom: "10px",
            //         }}
            //       >
            //         <div>
            //           <input
            //             type="checkbox"
            //             id="pickup"
            //             name="pickup"
            //             value="pickup"
            //             onChange={() => setDropOff((prev) => !prev)}
            //             disabled={displaySlots}
            //           />
            //         </div>
            //         <div>
            //           <label style={{ fontSize: "12px" }}>
            //             Dropoff Required? (AV Van can be availed @ ₹899/-)
            //           </label>
            //         </div>
            //       </div>
            //       <div
            //         className="grooming-new-form-section2-right-time-slot-container"
            //         style={{
            //           width: "100%",
            //           display: dropOff ? "none" : "block",
            //         }}
            //       >
            //         <div>Dropoff Time</div>
            //         <div className="grooming-new-form-section2-right-time-slots">
            //           <div
            //             onClick={() => setDropOffSlot(0)}
            //             className={
            //               dropOffSlot == 0
            //                 ? "grooming-new-form-section2-right-time-slot-active"
            //                 : "grooming-new-form-section2-right-time-slot"
            //             }
            //             style={{ fontSize: "12px" }}
            //           >
            //             10AM-02PM
            //           </div>
            //           <div
            //             onClick={() => setDropOffSlot(1)}
            //             className={
            //               dropOffSlot == 1
            //                 ? "grooming-new-form-section2-right-time-slot-active"
            //                 : "grooming-new-form-section2-right-time-slot"
            //             }
            //             style={{ fontSize: "12px" }}
            //           >
            //             02PM-06PM
            //           </div>
            //           <div
            //             onClick={() => setDropOffSlot(2)}
            //             className={
            //               dropOffSlot == 2
            //                 ? "grooming-new-form-section2-right-time-slot-active"
            //                 : "grooming-new-form-section2-right-time-slot"
            //             }
            //             style={{ fontSize: "12px" }}
            //           >
            //             06PM-10PM
            //           </div>
            //         </div>
            //       </div>
            //       <center>
            //         <div className="grooming-new-form-section1-carousel-container">
            //           <div className="grooming-new-form-section1-carousel-active"></div>
            //           <div className="grooming-new-form-section1-carousel-active"></div>
            //           <div className="grooming-new-form-section1-carousel"></div>
            //         </div>
            //       </center>
            //       <div className="button-group">
            //         <button
            //           className="next"
            //           onClick={() => {
            //             // secondFormDataHandler();
            //             // firstFormDataHandler();
            //             nextNavhandler();

            //             // setFormSection(3);
            //             // setSelectedCarousel(3);
            //           }}
            //         >
            //           Next
            //         </button>
            //       </div>
            //     </div>
            //   )}
            // </>

            null}

          {/*3rd form section */}

          <center>
            {selectedCarousel === 3 ? (
              <div
                style={{
                  padding: "10px",
                  fontWeight: "700",
                  lineHeight: "18.75px",
                }}
              >
                <h3
                  style={{ color: "rgba(114, 114, 114, 1)", fontSize: "15px" }}
                >
                  Your Data Privacy matters.
                </h3>
                <p
                  style={{ color: "rgba(131, 145, 161, 1)", fontSize: "15px" }}
                >
                  We only use your contact number to reach you regarding your
                  service request
                </p>
              </div>
            ) : null}
            <center>
              <LazyLoad>
                <img src={blackLogo} width={170} height={70} />
              </LazyLoad>
            </center>
            <div
              style={{
                fontWeight: "500",
                fontSize: "10px",
                lineHeight: "20px",
              }}
            >
              <p style={{ color: "rgba(93, 93, 93, 1)" }}>
                Copyright 2024 Petmojo <br />
                All copyrights are reserved - any infringement will be legally
                pursued
              </p>
            </div>
          </center>
        </div>
      )}
      {navTab === 2 && (
        <div className="order-placed-container">
          <div className="container">
            <div className="first-box">
              <span>
                {" "}
                <strong> Order Successfully Placed:</strong>
              </span>
              <div className="middle">{userData.Service}</div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <span>Number of pets: 1</span>
                  <br />
                  <span>
                    Date: {userData.Date.split("-").reverse().join("-")}
                  </span>

                  <br />
                </div>
                {!bookNow ? (
                  <div>
                    <img
                      src={downloadInvoice}
                      width={50}
                      style={{
                        objectFit: "contain",
                        position: "relative",
                        top: "-40px",
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="second-box">
              <div className="left">
                <span>
                  <strong> Billing Address: </strong>
                </span>
                <div>
                  <p>{address}</p>
                </div>
              </div>
              <div className="right" style={{ backgroundColor: "white" }}>
                <img
                  src={groomingvector}
                  alt=""
                  style={{
                    objectFit: "contain",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
            </div>
            <div className="third-box">
              <div
                onClick={() => {
                  console.log(userData);
                }}
                className="tick"
              >
                <img src={greenTick} alt="" />
              </div>
              <p>
                Your information has been sent to our pet counselor, We will
                reach you soon.
              </p>

              <a
                href="https://play.google.com/store/apps/details?id=in.tamely.user"
                style={{ textDecoration: "none" }}
              >
                <div className="download" style={{ marginBottom: "15px" }}>
                  Download App to Contact Groomer
                </div>
              </a>
              <div className="button-group">
                <a href="https://play.google.com/store/apps/details?id=in.tamely.user">
                  <img src={googlePlay} alt="" className="playstore" />
                </a>
                <a href="https://apps.apple.com/in/app/petmojo/id1609861328">
                  <img src={appleStore} alt="" className="appstore" />
                </a>
              </div>
            </div>
          </div>
        </div>
        // <div className="order-summary-mobile-master-container">
        //   <div className="order-summary-mobile-grey-container">
        //     <div>Order Successfully Placed:</div>
        //     <div className="order-summary-mobile-grey-heading">{userData.Service}</div>
        //     <div className="order-summary-mobile-grey-container-details-container">
        //       <div>
        //         <div>Number of pets: 1</div>
        //         <div>Date: {userData.Date.split("-").reverse().join("-")}</div>
        //       </div>

        //       <div>
        //         <img
        //           src={downloadInvoice}
        //           width={100}
        //           style={{
        //             objectFit: "contain",
        //             position: "relative",
        //             top: "-20px",
        //           }}
        //         />
        //       </div>
        //     </div>
        //     <div className="order-confirmation-mobile-container">
        //       Order Confirmation Number: {''}
        //       <span
        //         onClick={() => {
        //           navigator.clipboard.writeText(`${''}`);
        //           copiedMessage();
        //         }}
        //       >
        //         <img
        //           alt="copy"
        //           className="copy-icon-mobile"
        //           src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/copy-icon-desktop.png"
        //         />
        //       </span>
        //       <span className="copy-popup-text">{copyMessage}</span>
        //     </div>
        //   </div>

        //   <div
        //     style={{
        //       display: "flex",
        //       justifyContent: "space-between",
        //       marginTop: "10px",
        //       padding: "15px",
        //     }}
        //   >
        //     <div className="order-summary-mobile-white-container">
        //       <div className="billing-address-heading">Billing Address:</div>
        //       <div className="billing-address-ad">{userData.Address}</div>
        //     </div>
        //     <div>
        //       <img
        //         src={groomingvector}
        //         alt=""
        //         style={{ objectFit: "contain" }}
        //       />
        //     </div>
        //   </div>

        //   <div className="order-confirmation-mobile-summary-container">
        //     <div>
        //       <img
        //         alt="tickImage"
        //         className="order-confirmation-mobile-summary-image"
        //         src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/tick-order-summary-mobile.png"
        //       />
        //     </div>
        //     <div className="order-confirmation-mobile-summary-text">
        //       Your information has been sent to our pet counselor, We will reach
        //       you soon.
        //     </div>
        //   </div>

        //   <div className="order-summary-mobile-download-button">
        //     Download Receipt
        //   </div>

        //   <div className="order-summary-mobile-footer-container">
        //     <div>
        //       <img
        //         alt="petmojoLogo"
        //         className="order-summary-mobile-footer-logo"
        //         src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/petmojo-order-summary-mobile.png"
        //       />
        //     </div>
        //     <div className="order-summary-mobile-footer-text">
        //       Copyright 2024 Petmojo
        //     </div>
        //     <div className="order-summary-mobile-footer-text">
        //       All copyrights are reserved - any infringement will be legally
        //       pursued
        //     </div>
        //     <div>
        //       <br />
        //       <br />
        //       <br />
        //       <br />
        //     </div>
        //   </div>
        // </div>
      )}
    </>
  );
};

export default BoardingPageForm;
