import React, { useState, useEffect, useRef } from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import 'react-dots-loader/index.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Grooming from "./components/Grooming";
import GroomingUC from "./components/GroomingUC"
import GroomingNew from "./components/GroomingNew";
import GroomingSubscription from "./components/GroomingSubscription";
import Groomingwcc from "./components/Groomingwcc";
import PetGrooming from "./components/PetGrooming"
import DeleteAccount from "./components/DeleteAccount";
import Styling from "./components/Styling";
import LandingPage from "./pages/Landing/LandingPage";
import LandingPageComponent from "./components/LandingPageComponent";
import Training from "./components/Training";
import TrainingNew from "./components/TrainingNew";
import Training2 from "./components/Training2";
import CatTraining from "./components/CatTraining";
import Walking from "./components/Walking";
import WalkingNew from "./components/WalkingNew";
import Boarding from "./components/Boarding";
import LazyLoad from "react-lazyload";
import About from "./components/About";
import Review from "./pages/Review/Review"
import Help from "./components/Help";
import Socials from "./components/Socials";
import Services from "./components/Services";
import Popup from "./components/Popup";
import PopupBoarding from "./components/PopupBoarding";
import PopupAdoption from "./components/PopupAdoption";
import More from "./components/More";
import Partner from "./components/Partner";
import Blog from "./components/Blog";
import BlogShort from "./components/BlogShort";
import BlogDetail2 from "./components/BlogDetail";
import BlogDetailRevised from "./components/BlogDetailRevised";
import FooterNav from "./components/Navigation/footerNav/FooterNav";
import FooterCTAButton from "./components/footerCTAButton/FooterCTAButton";
import ThankYouPage from "./components/ThankYouPage";
import ThankYouPageGrooming from "./components/ThankYouPageGrooming"
import BlogSummary from "./blogs/blog1";
import Terms from "./components/Terms";
import OrderSummary from "./components/OrderSummary";
import OrderSummaryWalking from "./components/OrderSummaryWalking";
import OrderSummaryBoarding from "./components/OrderSummaryBoarding";
import RefundsPage from "./components/refundsPage/refundsPage";
import CustomerSupport from "./components/refundsPage/customersupport";
import Adoption from "./components/Adoption";
import { Link, useLocation } from "react-router-dom";

import BlogDetail from "./components/blogDetails/BlogDetail";
import Privacy from "./components/Privacy";
import BlogLandingPage from "./components/BlogLandingPage";
import Redirect from "./components/error404";
import DogGroomingBooking from "./components/DogGroomingBooking";
import CreateReview from "./pages/Review/CreateReview";
import VerifyUser from "./pages/Review/VerifyUser";
import RefundPolicy from "./components/refundsPage/refundPolicy";
// import Carousel from "./components/carousel";

function App() {

  const [connectCall, setConnectCall] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [displayCTA, setDisplayCTA] = useState(false);
  const [location, setLocation] = useState(window.location.pathname); // returns the path and filename of the current page
  const [wantedLocation, setWantedLocation] = useState(false);
  const [reloadState, setReloadState] = useState(false);
  const [changeView, setChangeView] = useState(false); // setting up the changeView state
  const [hide, setHide] = useState(false); //show popup
  const [showNavigationBar, setShowNavigationBar] = useState(true)



  useEffect(() => {

    if (localStorage.getItem("Visited") == null) {

      console.log("under if condition");
      var temp1 = setTimeout(() => {
        setHide(true);
        console.log("hide true", hide);
      }, 20000);

      var temp2 = setTimeout(() => {
        setHide(false);
        console.log("hide false", hide);
        localStorage.setItem("Visited", true);
      }, 25000);
    }

    return () => {
      clearTimeout(temp1);
      clearTimeout(temp2);
    }

  }, []);

  useEffect(() => {
    if (
      location === "/dog-training" ||
      location === "/dog-running" ||
      location === "/grooming"
    ) {
      setWantedLocation(true);
    } else {
      setWantedLocation(false);
    }
  }, []);

  const callbtnRef = useRef();

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY) {
        // if scroll down hide the navbar
        setDisplayCTA(true);
      } else {
        // if scroll up show the navbar
        // setDisplayCTA(false);
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  useEffect(() => {
    if (window.innerWidth <= 1000) {
      setChangeView(false);
    } else {
      setChangeView(true);
    }
  }, []);
  useEffect(() => {
    console.log("loc", window.location.pathname)
    if (window.location.pathname === "/pet-boarding" || window.location.pathname === "/dog-running" || window.location.pathname === "/dog-training" || window.location.pathname === "/grooming" || window.location.pathname === "/cat-training") {
      setShowNavigationBar(false)
    }
    if (window.location.pathname === "/") {
      setShowNavigationBar(true)
    }
    if (window.location.pathname === "/" || window.location.pathname === "/pet-boarding") {
      setDisplayCTA(true);
    }
    else {
      setDisplayCTA(false);
    }

  }, [window.location.pathname])

  // console.log("hello")
  if (window.location.search.includes('utm_')) {

    document.addEventListener('click', function (event) {

      // get the nearest anchor tag to where the user clicked (in case they clicked on something inside the anchor tag like an <img> or <span>
      let aTag = event.target.closest('a');

      if (aTag !== null) {

        let urlParams = new URLSearchParams(window.location.search);
        let aParams = new URLSearchParams(aTag.search);

        // update the <a> tag's params with UTM params
        for (let [key, value] of urlParams.entries()) {

          // skip duplicates and only add new params that belong to UTM
          if (!aParams.has(key) && key.includes('utm_')) {
            aParams.append(key, value);
          }
        }
        console.log(aParams);
        // reset the anchor's URL with all the query params added
        aTag.href = aTag.href.split('?')[0] + '?' + aParams.toString();
      }

    });

  }


  // scrolling part
  const [scrollDirection, setScrollDirection] = useState('down');
  const [prevScrollY, setPrevScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > prevScrollY) {
        setScrollDirection('down');
      } else if (currentScrollY < prevScrollY) {
        setScrollDirection('up');
      }

      setPrevScrollY(currentScrollY);

      const windowHeight = window.innerHeight;

      if (currentScrollY > windowHeight) {
        setDisplayCTA(true);
      } else {
        setDisplayCTA(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollY]);


  // scroll at certain height
  const [isScrollingUp, setScrollingUp] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setScrollingUp(scrollY > 800 ? false : true);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Router onUpdate={() => window.scrollTo(0, 0)}>
      <div className="App">
        <Routes>
          {/* <Route path="/" element={<LandingPageComponent />}></Route> */}
          <Route path="/" element={<LandingPage changeView={changeView} />}></Route>
          <Route path="/deleteacc" element={<DeleteAccount />}></Route>
          <Route path="/dog-training" element={<Training />}></Route>
          <Route path="/dog-training_2" element={<Training2 />}></Route>
          <Route path="/dog-training-meta" element={<TrainingNew />}></Route>
          <Route path="/cat-training" element={<CatTraining />}></Route>
          <Route path="/dog-running" element={<Walking />}></Route>
          <Route path="/dog-walking-meta" element={<WalkingNew />}></Route>
          <Route path="/pet-boarding" element={<Boarding />}></Route>
          <Route path="/grooming" element={<Grooming />}></Route>
          <Route path="/groomingUC" element={<GroomingUC />}></Route>
          <Route path="/groomingwcc" element={<Groomingwcc />}></Route>
          {/* <Route path="/grooming_booking" element={<GroomingBooking/>}></Route> */}
          <Route path="/grooming-subscription" element={<GroomingSubscription />}></Route>
          <Route path="/pet-grooming" element={<PetGrooming />}></Route>
          <Route path="/pet_grooming" element={<Grooming type={2} />}></Route>
          {/* <Route path="/styling" element={<Styling />}></Route> */}
          <Route path="/about" element={<About />}></Route>
          <Route path="/customer-care" element={<Help />}></Route>
          <Route path="/socials" element={<Socials />}></Route>
          <Route path="/services" element={<Services />}></Route>
          {/* <Route path="/reviews" element={<Review changeView={changeView} />}></Route> */}
          <Route path="/verify" element={<VerifyUser />}></Route>
          <Route path="/add-reviews" element={<CreateReview />}></Route>
          <Route path="/popup" element={<Popup />}></Route>
          <Route path="/popup-boarding" element={<PopupBoarding />}></Route>
          <Route path="/popup-adoption" element={<PopupAdoption />}></Route>
          <Route path="/more" element={<More />}></Route>
          <Route path="/partner" element={<Partner />}></Route>
          <Route path="/blog" element={<BlogLandingPage />}></Route>
          <Route path="/blog/:blogId/view" element={<BlogDetail2 />}></Route>
          <Route path="/thankPage" element={<ThankYouPage />}></Route>
          <Route path="/thankPageGroom" element={<ThankYouPageGrooming />}></Route>
          <Route path="/blogDetail" element={<BlogSummary />}></Route>
          <Route path="/terms" element={<Terms />}></Route>
          <Route path="/privacy" element={<Privacy />}></Route>
          <Route path={`/blog/:blogId`} element={<BlogDetailRevised />}></Route>
          <Route path={"/ordersummary"} element={<OrderSummary />}></Route>
          <Route path={"/ordersummarywalking"} element={<OrderSummaryWalking />}></Route>
          <Route path={"/ordersummaryboarding"} element={<OrderSummaryBoarding />}></Route>
          <Route path={"/refunds"} element={<RefundsPage />}></Route>
          <Route path={"/refund-policy"} element={<RefundPolicy />}></Route>
          <Route path={"/customer-support-policy"} element={<CustomerSupport />}></Route>
          <Route path={"/adoption"} element={<Adoption />}></Route>
          <Route path={"*"} element={<Redirect />} ></Route>
          <Route path="/dog-grooming" element={<DogGroomingBooking />}></Route>
        </Routes>
        {/* // window.location.pathname === "/grooming") && */}
        {(window.location.pathname === "/dog-training" ||
          window.location.pathname === "/dog-running" || window.location.pathname === "/grooming")
          && !isScrollingUp && displayCTA &&
          !changeView ? (
          <FooterCTAButton path={window.location.pathname} />
        ) : (
          <>
            {(window.location.pathname === "/" || window.location.pathname === "/pet-boarding") &&
              !changeView && <FooterNav showNavigationBar={showNavigationBar} path={window.location.pathname} />}

            {window.location.pathname !== "/popup" &&
              window.location.pathname !== "/partner" &&
              window.location.pathname !== "/more" &&
              window.location.pathname !== "/blogDetail" &&
              !changeView &&
              window.location.pathname !== "/grooming" && (
                <div className="floating-icons">
                  {hide && (
                    <div className="popup-box-mobile">
                      <div
                        className="popup-cross-mobile"
                        onClick={() => setHide(false)}
                      >
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/cross.png"
                          alt=""
                        />
                      </div>
                      <h2>Have a query?</h2>
                      <p>Answers are just a call away!</p>
                    </div>
                  )}
                  <a
                    className="call-floating-icon"
                    href={location === "/adoption" ? "tel: +91 8000000445" : "tel: +91 8889991800"}
                    target="_blank"
                    rel="noreferrer"
                    ref={callbtnRef}
                  >
                    <LazyLoad>
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/call-now.png"
                        className="calling-icon-button"
                        style={{ objectFit: "cover" }}
                      ></img>
                    </LazyLoad>
                  </a>
                </div>
              )}
          </>
        )}
      </div>
    </Router>
  );
}

export default App;
