import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import "./OrderSummary.css";
import download from "../images/download.png";
import downloadinvoice from "../images/download-invoice.png";
import groomingvector from "../images/grooming-vector.jpg";

const OrderSummaryWalking = (props) => {
  
  const location = useLocation();
  const [data,setData]=useState(null)
  // console.log(location.state);
  // setData(location.state.data);
  const [changeView, setChangeView] = useState(false);
  const [copySuccess, setCopySuccess] = useState("");

  const [copyMessage, setCopyMessage] = useState("");
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (window.innerWidth <= 1000) {
      setChangeView(false);
    } else {
      setChangeView(true);
    }
  }, []);
  useEffect(()=>{
    setData(location.state.data);
  console.log(data)

  })
  function copyToClipboard(text) {
    text.select();
    document.execCommand("copy");
    text.focus();
    setCopySuccess("Copied!");
  }

  function copiedMessage() {
    setCopyMessage("Copied!");

    setTimeout(() => {
      setCopyMessage("");
    }, 2000);
  }

  return (
    <div>
      {changeView ? (
        <div className="order-summary-desktop-master-container">
          <div>
            <img src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/petmojo-dark-logo-.png" alt="" style={{width:"270px",height:"70px",objectFit:"contain"}}/>
          </div>

          <div className="order-summary-desktop-master-container-bottom">
            <div className="order-summary-desktop-grey-container" style={{padding:"60px 120px",width:"100%"}}>
              <div className="order-summary-desktop-grey-left">
                <div className="order-summary-desktop-grey-left-order">
                  Booking Confirmed:
                </div>
                <div className="order-summary-desktop-grey-left-order-heading">
                Monthly Walking {data.package} <br/>
({data.pets} Pet)
                </div>
                <div className="order-summary-desktop-grey-left-order-details-container">
                  <div>Number of pets: {data.pets=="One"?"1":"2"}</div>
                  <div>Date: 
                    {/* {location.state.DateOfReg} */}
                    {data.bookingDate}
                    </div>
                  <div>
                   Your Registered Number:{data.phoneNumber}
                    
                  </div>
                </div>
              </div>
              <div className="order-summary-desktop-grey-right" style={{display:"flex",justifyContent:"center",alignItems:"end",flexDirection:"column",width:"50%",fontSize:"20px"}}>
                <div style={{display:"flex"}}>
                    <div style={{display:"flex",flexDirection:"column",textAlign:"right"}}>
<div style={{fontWeight:"700"}}>Package Amount :</div>
<div style={{fontWeight:"700",marginTop:"5px"}}>18% GST :</div>
<div style={{fontWeight:"700",borderTop:"2px solid grey",marginTop:"5px"}}>Total :</div>
                    </div>
                    <div style={{display:"flex",flexDirection:"column",textAlign:"right"}}>
<div style={{fontWeight:"400"}}>₹{data.amount}</div>
<div style={{fontWeight:"400",marginTop:"5px"}}>₹{(18*data.amount)/100}</div>
<div style={{fontWeight:"400",borderTop:"2px solid grey",marginTop:"5px"}}>₹{(data.amount*118)/100}</div>
                    </div>
                    
                </div>
              </div>
            </div>

            <div
              className="order-summary-desktop-white-container"
              
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  padding: "60px 60px",
                }}
              >
                <div className="order-summary-desktop-white-container-left">
                  <div>Billing Address:</div>
                  <div className="order-summary-desktop-white-container-left-address">
                    {/* {location.state.InputAddress} */}
                    {data.billingAddress}
                  </div>

                  
                </div>
              </div>
              <div className="order-summary-desktop-white-container-right">
                <div>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/invoice-vector-walking.png"
                    alt=""
                    style={{ objectFit: "contain",width:"200px" }}
                  />
                </div>
                
              </div>
            </div>
            <div className="order-confirmation-mobile-summary-container" style={{display:"flex",flexDirection:"row",width:"100%"}}>
            <div className="" style={{width:"50%"}}>
            <button type="" className="order-summary-desktop-white-container-left-download-button" style={{outline:"none",border:"none",width:"50%",fontSize:"20px"}}>
                Download Invoice
            </button>
          </div>
            <div style={{width:"50%",display:"flex",gap:"10px"}}>
              <img
                alt="tickImage"
                className="order-confirmation-mobile-summary-image"
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/tick-order-summary-mobile.png"
                style={{objectFit:"contain"}}
              />
              <div className="order-confirmation-mobile-summary-text" style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
              Your information has been sent to our pet counselor, <br/>
Download our Mobile app and start tracking <br/> immediately and connect with the walker.
            </div>
            </div>
            
          </div>

          </div>
        </div>
      ) : (
        <div className="order-summary-mobile-master-container">
          <div className="order-summary-mobile-grey-container">
            <div>Order Successfully Placed:</div>
            <div className="order-summary-mobile-grey-heading">
                {/* {location.state.SelectedService} */}
                </div>
            <div className="order-summary-mobile-grey-container-details-container">
              <div>
                <div>Number of pets: 1</div>
                <div>Date: 
                    {/* {location.state.DateOfReg} */}
                    </div>
              </div>

              <div>
                <img
                  src={downloadinvoice}
                  width={100}
                  style={{
                    objectFit: "contain",
                    position: "relative",
                    top: "-20px",
                  }}
                />
              </div>
            </div>
            <div className="order-confirmation-mobile-container">
              Order Confirmation Number: 
              {/* {location.state.orderId} */}
              <span
                // onClick={() => {
                //   navigator.clipboard.writeText(`${location.state.orderId}`);
                //   copiedMessage();
                // }}
              >
                <img
                  alt="copy"
                  className="copy-icon-mobile"
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/copy-icon-desktop.png"
                />
              </span>
              <span className="copy-popup-text">{copyMessage}</span>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
              padding: "15px",
            }}
          >
            <div className="order-summary-mobile-white-container">
              <div className="billing-address-heading">Billing Address:</div>
              <div className="billing-address-ad">
                {/* {location.state.InputAddress} */}
                </div>
            </div>
            <div>
              <img
                src={groomingvector}
                alt=""
                style={{ objectFit: "contain" }}
              />
            </div>
          </div>

          <div className="order-confirmation-mobile-summary-container">
            <div>
              <img
                alt="tickImage"
                className="order-confirmation-mobile-summary-image"
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/tick-order-summary-mobile.png"
              />
            </div>
            <div className="order-confirmation-mobile-summary-text">
              Your information has been sent to our pet counselor, We will reach
              you soon.
            </div>
          </div>

          <div className="order-summary-mobile-download-button">
            Download Receipt
          </div>

          <div className="order-summary-mobile-footer-container">
            <div>
              <img
                alt="petmojoLogo"
                className="order-summary-mobile-footer-logo"
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/petmojo-order-summary-mobile.png"
              />
            </div>
            <div className="order-summary-mobile-footer-text">
              Copyright 2024 Petmojo
            </div>
            <div className="order-summary-mobile-footer-text">
              All copyrights are reserved - any infringement will be legally
              pursued
            </div>
            <div>
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderSummaryWalking;
