import {useEffect, useState} from 'react';
import LazyLoad from "react-lazyload";
import HomePageForm from './HomeForm';
import NavigationDesktop from '../../../components-desktop/Navigation/NavigationDesktop';

function IntroContainer({ introImage }) {

  return (
        <div className="intro-desktop-home-page-master-container">
            {/* <video loop preload autoplay className='home-page-desktop-background-image' src="https://video.wixstatic.com/video/4e82bb_80fb0c4770334638a530f96539121a6c/720p/mp4/file.mp4" >
            </video> */}
            <div className='wrapper-class-intro-desktop-video' style={{position:"absolute"}}>
              {/* <video style={{width: "100vw"}} role="presentation" crossOrigin="anonymous" playsInline preload="auto" muted loop tabIndex="-1" autoPlay  className='home-page-desktop-background-video'>
                <source
                  src="https://video.wixstatic.com/video/4e82bb_80fb0c4770334638a530f96539121a6c/720p/mp4/file.mp4"
                  type="video/mp4"
                  style={{width: "100%"}}
                />
              </video> */}
              <img src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/homepagewebsite.webp" alt="" style={{width:"100vw",height:"170vh",objectFit:"cover"}}/>
            </div>

            <div className='intro-desktop-home-page-content-container' style={{position:"relative"}}>
              <NavigationDesktop submissionType={"dog-training"} submissionText={"Book a FREE Session"} location={"home"}/>
              <div className='intro-desktop-head-combiner-grooming'>
                <div className='intro-desktop-home-page-header'>
                  <h1 className='home-page-desktop-heading'>
                    <div className='intro-desktop-home-page-header1'>We Provide The Best <span className='pink-bold'>Pet Care</span> Services</div>
                    <div className='intro-desktop-home-page-header1'>At The <span className='pink-bold'>Comfort</span> Of Your Home!</div>
                  </h1>
                </div>
                <div className='intro-desktop-home-page-points-container'>
                  <div className='intro-desktop-home-page-points-p1'>
                    <div><img alt="tick" className="tick-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
                    <div>Door Step Services</div>
                  </div>
                  <div className='intro-desktop-home-page-points-p1'>
                    <div><img alt="tick" className="tick-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
                    <div>Experienced Service Partners</div>
                  </div>
                  <div className='intro-desktop-home-page-points-p1'>
                    <div><img alt="tick" className="tick-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
                    <div>Guaranteed Results</div>
                  </div>
                </div>
                  <HomePageForm />
              </div>
            </div>
        </div>
  );
}

export default IntroContainer;
