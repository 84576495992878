import React, { useState, useEffect } from "react";
import NavigationBar from "../Navigation/navigationBar/NavigationBar";
import { Helmet } from "react-helmet-async";
import NavigationDesktopBlogs from "../../components-desktop/Navigation/NavigationDesktopBlogs";
import "./refundsPage.css";

const CustomerSupport = () => {
  const [windowInnerWidth, setWindowInnerWidth] = useState("");
  const [changeView, setChangeView] = useState(false);

  
  const scrollToTop = () => {
    return window.scrollTo({top: 0, left: 0, behavior: "smooth"});
  }

  function goToTopHandler(){
    return window.scrollTo({top: 0, left: 0, behavior: "smooth"})
  }


  useEffect(() => {
    setWindowInnerWidth(window.innerWidth);
    window &&
      window.addEventListener("resize", (data) => {
        setWindowInnerWidth(data.target.screen.width);
      });
  }, []);

  
  useEffect(() => {
    if(window.innerWidth <= 1000){
      setChangeView(false);
    }else{
      setChangeView(true);
    }
  }, []);

  return (
    <>
    <div className="">
      <Helmet>
        <title>Book the most trusted Pet Hotel Service - Petmojo</title>
        <meta
          name="description"
          content="Pet Hotel - Going away for a vacation? But worried about your dog while you're away? Look no further, we are here to make sure all the needs of your pet are met"
        />
      </Helmet>
      {windowInnerWidth > 1150 ? (
        <NavigationDesktopBlogs
          submissionType={"none"}
          submissionText={"Download Our App"}
        />
      ) : (
        <NavigationBar />
      )}
{/* 

The first 24 sessions are non refundable in all services. E.g., if A Service opted for 48 sessions where 24 sessions have been delivered, the refund will be provided only for the remaining 24 sessions.
The refund amount will be made via Bank Transfer to the payee only
Petmojo employees may verify your refund claims/ perform an internal check before the refund is sanctioned
Once a refund request is raised it will take 14-21 days to process & sanction your refund claim */}
      <div className="refunds_text_wrapper">
        <div className="refunds_div">
          <h1 className="refunds_heading">PETMOJO CUSTOMER SUPPORT POLICY</h1>
          {/* <p className="refunds_gray_text">- Last updated 20 December 2022</p> */}
          <p className="refunds_text">
          Clients experiencing any issues with our services are encouraged to contact our dedicated customer service team at:
          </p>
          <div className="refunds_points">
            {/* <div className="refunds_dot"></div> */}
            <p className="refunds_text">
            Email:  customercare@petmojo.in  </p>
          </div>
          <div className="refunds_points">
            {/* <div className="refunds_dot"></div> */}
            <p className="refunds_text">
            Phone: +91 888 999 1800</p>
          </div>
          <div className="refunds_points">
            <div className="refunds_dot"></div>
            <p className="refunds_text">
            We prioritize prompt resolution of grievances to ensure that our clients receive the quality of service they expect and deserve.
            </p>
          </div>
          <div className="refunds_points">
            <div className="refunds_dot"></div>
            <p className="refunds_text">
            We encourage clients to provide detailed information and any supporting evidence to facilitate a swift resolution.</p>
          </div>
          <div className="refunds_points">
            <div className="refunds_dot"></div>
            <p className="refunds_text">
            At Petmojo, we are committed to providing exceptional pet care services, including dog walking, dog grooming, cat grooming, pet hotel, and dog training. 
            </p>
          </div>
          <div className="refunds_points">
            <div className="refunds_dot"></div>
            <p className="refunds_text">
            We understand that occasionally issues may arise, and we are dedicated to addressing them promptly and effectively.</p>
          </div>
          <div className="refunds_points">
            <div className="refunds_dot"></div>
            <p className="refunds_text">
            Our grievance policy outlines the steps to take in various situations and the solutions we offer to ensure customer satisfaction.</p>
            </div>
           
          {/* <div className="refunds_points">
            <div className="refunds_dot"></div>
            <p className="refunds_text">
              Petmojo employees may verify your refund claims/ perform an
              internal check before the refund is sanctioned
            </p>
          </div> */}
          {/* <div className="refunds_points">
            <div className="refunds_dot"></div>
            <p className="refunds_text">
              Once a refund request is raised it will take 14-21 days to process
              & sanction your refund claim
            </p>
          </div> */}
        </div>

        <div className="refunds_div">
          <h1 className="refunds_heading" style={{ marginBottom: "15px" }}>
          Trainer/Walker Not Available
          </h1>
          <p className="refunds_text">
            1. {"  "} If your assigned trainer or walker is unavailable, we will strive to provide a suitable replacement promptly.
          </p>
          <p className="refunds_text">
            2. {"  "} In cases where a replacement is not immediately available, we may offer complimentary free sessions to cover up course curriculum (up to maximum of 3 sessions) with the allocation as soon as possible.
          </p>
          {/* <p className="refunds_text">
            3. {"  "} Internal checks & verification can take up to 72 hours.
          </p>
          <p className="refunds_text">
            4. {"  "} If your refund claim adjudication is passed, we will
            sanction the final amount and will make a bank transfer to the
            details provided. This can take up to 7-14 days.
          </p> */}
        </div>

        <div className="refunds_div">
          <h1 className="refunds_heading" style={{ marginBottom: "15px" }}>
          Trainer/Walker Not Coming Regularly
          </h1>
          <p className="refunds_text">
            1. {"  "} We apologize if your trainer or walker is not adhering to the scheduled appointments. We will investigate the matter promptly and take appropriate action.
          </p>
          <p className="refunds_text">
            2. {"  "} Depending on the severity and frequency of the issue, we may offer complimentary sessions (up to maximum of 3 sessions)  or switch you to a new trainer/walker to cover up course curriculum.
          </p>
          {/* <p className="refunds_text">
            3. {"  "} Refunds are not available for Dog Walking services where
            only one month's service payment is done.
          </p> */}
        </div>

        <div className="refunds_div">
          <h1 className="refunds_heading" style={{ marginBottom: "15px" }}>
          Partner Behavioural Problems
          </h1>
          <p className="refunds_text">
            1. {"  "} We are family of pet lovers, and so far we have got 35000+ clients and there are zero complaints regarding this. Any reports of misbehaviour by our staff towards your pets will be taken very seriously. We will conduct a thorough investigation and take necessary disciplinary action along with the replacing the service partner and taking legal actions ourselves.
          </p>
          {/* <p className="refunds_text">
            2. {"  "} Refunds are not eligible where payment is directly made to
            the training partner & not to the Payment ways (Online, Bank
            Deposits, etc.) provided by Petmojo officials. We strongly
            discourage cash & cheque payments.
          </p>
          <p className="refunds_text">
            3. {"  "} Your refund claim will attract a 3% deduction for Bank
            Transfer fees, accounting fees, and Payment Gateway charges in
            total.
          </p> */}
        </div>

        <div className="refunds_div" style={{marginBottom:"100px"}}>
          <h1 className="refunds_heading" style={{ marginBottom: "15px" }}>
          Service Quality Issues
          </h1>
          <p className="refunds_text">
          1. {"  "} If you are dissatisfied with the quality of our service, we sincerely apologize and will do everything possible to rectify the situation.
          </p>
          <p className="refunds_text">
          2. {"  "} We will conduct a thorough investigation. Depending on the nature of the issue, we may offer to switch you to a new trainer/walker, along with complimentary sessions (up to maximum of 3 sessions) to cover course curriculum.
          </p>
          <p className="refunds_text">
          3. {"  "} Please note, in grievance cases of training services if satisfactory solution is not reached, first 24 sessions remains non-refundable and remaining sessions could be redeemed in form of other services.<span style={{fontWeight:"bolder"}}> Please note that the company has no refund policy as mentioned in applications terms and conditions.</span> 
          </p>
        </div>

        <div className="refunds_div" style={{marginBottom:"100px"}}>
          <h1 className="refunds_heading" style={{ marginBottom: "15px" }}>
          Exceptions to No Refund Policy
          </h1>
          <p className="refunds_text">
          1. {"  "} As mentioned in Petmojo`s terms and conditions, the company has a strict no refund policy. Although we do make some exceptions in the cases of untimely demise of the pet or if the online booking has come from unserviceable city through organic mediums. In these cases, proofs can be submitted to customercare@petmojo.in for faster processing. 
          </p>
          <p className="refunds_text">
          2. {"  "} At PetMojo, we value your trust and satisfaction above all else. Our grievance policy is designed to address any concerns promptly and effectively, while also providing appropriate compensations to make up for any inconvenience caused. Thank you for choosing us for your pet care needs. 
          </p>
          
        </div>
      </div>
      </div>

      </>
  );
};

export default CustomerSupport;
