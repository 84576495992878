import { BsArrowUp } from '@react-icons/all-files/bs/BsArrowUp';
import { React, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
function Footer({ view }) {
  const scrollToTop = () => {
    return window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const sectionOneRef = useRef(null);
  const sectionTwoRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // console.log('Section is now visible');
          const ele1 = document.getElementById('section1');
          const ele2 = document.getElementById('section2');
          // console.log(ele);
          ele1.classList.add('appstore')
          ele2.classList.add('playstore')
          // Trigger the section action
        }
      },
      { rootMargin: '0px 0px -50% 0px' }
    );
    if (sectionOneRef.current) {
      observer.observe(sectionOneRef.current);
    }
    return () => {
      if (sectionOneRef.current) {
        observer.unobserve(sectionOneRef.current);
      }
    };
  }, []);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // console.log('Section is now visible');
          const ele1 = document.getElementById('section3');
          const ele2 = document.getElementById('section4');
          // console.log(ele);
          ele1.classList.add('appstore')
          ele2.classList.add('playstore')
          // Trigger the section action
        }
      },
    );
    if (sectionTwoRef.current) {
      observer.observe(sectionTwoRef.current);
    }
    return () => {
      if (sectionTwoRef.current) {
        observer.unobserve(sectionTwoRef.current);
      }
    };
  }, []);
  function goToTopHandler() {
    return window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  return (
    <>
      {view === "mobile" ? (
        <div>
          <div className="textbox2 pink-container-family">
            We’ll Make Your Pet Smile
          </div>
          <hr className="vector2"></hr>
        </div>
      ) : null}

      {/* <LazyLoad> */}

      {view === "mobile" ? (
        <div className="heroImage1Container">
          <div className="heroImage1">
            <img
              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/homepage-petmojo-partner-mobile-outline.webp"
              alt="makepetsmile"
              className="make-pet-smile"
            ></img>
            <div className="petmojo-partner-mobile-view-name">
              Petmojo Partner -{" "}
              <span className="petmojo-partner-mobile-view-name-cursive">
                Kartik Rai
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className="hero-image-container-desktop">
          <div className="hero-image-container-desktop-left">
            <div className="pet-smile-home-set1">
              <div>
                <h5 className="fifty-font-size" >We'll Make Your Pet Smile</h5>
              </div>
              <div className="line-div relative-line">
                <hr className="live-training-pink-line"></hr>
              </div>
            </div>

            <div className="pet-smile-home-set2" style={{ position: "relative", bottom: "15px" }}>
              <h4 className="bold-none" style={{ marginTop: "2rem" }}>Your Pet Our Family!</h4>
            </div>
          </div>

          <div className="hero-image-container-desktop-right">
            <img
              className="pet-smile-img"
              alt="heroImg"
              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/desktop-petmojo-partner.webp"
            />
            <div className="petmojo-partner-name-desktop">
              Petmojo Partner -{" "}
              <span className="petmojo-partner-name-desktop-cursive">
                Kartik Rai
              </span>
            </div>
          </div>
        </div>
      )}

      {/* </LazyLoad> */}

      <div className="giveSpace">
        {view === "mobile" ? (
          <div className="pink-container-family">
            <div className="textbox2">Download Petmojo App</div>
            <hr className="vector2"></hr>
          </div>
        ) : null}

        {view === "mobile" ? (
          <div className="iphone-container">

            <div className="storeContainer_logo_container_mobile">
              <img
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/petmojo_black_bg_green_logo.png"
                alt=""
                style={{ objectFit: "contain" }}
              />
            </div>

            {/* <LazyLoad> */}
            <div className="storeContainer_main_image_mobile">
              <img
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/get-petmojo-application-mobile.webp"
                alt="iphoneicon"
                className="iphone-icon"
              ></img>
            </div>

            {/* </LazyLoad> */}

            <div className="download-btn-container" ref={sectionOneRef}>
              <div className="playstore-icon">
                <a
                  target="_blank" rel="nofollow"
                  href="https://play.google.com/store/apps/details?id=in.tamely.user"
                >
                  {/* <LazyLoad> */}
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/playstore_ordt0r.png"
                    alt="playstore"
                    id='section2'
                  ></img>
                  {/* </LazyLoad> */}
                </a>
              </div>
              <div className="appstore-icon">
                <a
                  target="_blank" rel="nofollow"
                  href="https://apps.apple.com/in/app/petmojo/id1609861328"
                >
                  {/* <LazyLoad> */}
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/app-store.png"
                    alt="appstore"
                    id='section1'
                  ></img>
                  {/* </LazyLoad> */}
                </a>
              </div>
            </div>
          </div>
        ) : (
          <div className="storeContainer">
            <div className="storeContainer_left">
              <h2 className="storeContainer_heading" >
                Get Petmojo Application
              </h2>

              <hr className="live-training-pink-line" style={{ position: "relative", top: "10px" }}></hr>

              <p className="storeContainer_text" style={{ position: "relative", top: "20px" }}>
                Download our application to get exciting offers on Pet Services.
              </p>
              <div className="pink-container-left-box3" style={{ position: "relative", top: "30px" }}>
                <div className="bookingbutton">
                  {/* <Link to="/dog-running" state={{ type: "dog-running" }}> */}
                  <button className="booksession" onClick={scrollToTop}>Book Now!</button>
                  {/* </Link> */}
                  <div className="limitedslots">
                    Hurry Up, Limited slots left!
                  </div>
                </div>
              </div>
            </div>

            <div className="storeContainer_right">
              <div className="storeContainer_logo_container">
                <img
                  src={"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/petmojo_black_bg_green_logo.png"}
                  alt=""
                  style={{ objectFit: "contain" }}
                />
              </div>
              <div className="storeContainer_main_image">
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/get-petmojo-application.webp"
                  alt=""
                />
              </div>

              <div className="storeContainer_button_container">
                <a
                  target="_blank" rel="nofollow"
                  href="https://play.google.com/store/apps/details?id=in.tamely.user"
                >
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/play-store-download.png"
                    alt=""
                  />
                </a>
                <a
                  target="_blank" rel="nofollow"
                  href="https://apps.apple.com/in/app/petmojo/id1609861328"
                >
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/app-store-download.png"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        )}
      </div>

      <div style={{ margin: "2rem 0rem" }}>
        {view === "mobile" ? (
          <div>
            <div className="textbox2">Connect With Us</div>
            <hr className="vector2"></hr>

            <div className="ellipse-container">
              <div className="ellipse">
                {/* <LazyLoad> */}
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/outer-ring.png"
                  alt="outerring"
                  className="outer-ring"
                ></img>
                {/* </LazyLoad> */}
                {/* <LazyLoad> */}
                <img
                  src={
                    "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/inner-circle.png"
                  }
                  alt="innerring"
                  className="inner-ring"
                ></img>
                {/* </LazyLoad> */}
                <button className="youtube-icon">
                  <a
                    href="https://youtube.com/channel/UCPQrCllizVvTOkSk7_1SIHw"
                    target="_blank"
                    rel="nofollow"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/yt-icon.png"
                      alt="youtubeicon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="facebook-icon">
                  <a
                    href="https://www.facebook.com/petmojo.care/"
                    target="_blank"
                    rel="nofollow"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/fb-icoc.png"
                      alt="facebookicon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="twitter-icon">
                  <a href="https://twitter.com/Petmojo_App">
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/twitter-icon.png"
                      alt="twittericon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="instagram-icon">
                  <a
                    href="https://www.instagram.com/petmojo.care/"
                    target="_blank"
                    rel="nofollow"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/insta-icon.png"
                      alt="instagramicon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="phone-icon">
                  <a
                    href="tel:+91 8889991800"
                    target="_blank"
                    rel="nofollow"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/phone-icon.png"
                      alt="phoneicon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="gmail-icon">
                  <a
                    href="https://petmojo.care/support/?v=c86ee0d9d7ed"
                    target="_blank"
                    rel="nofollow"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/gm-icon.png"
                      alt="gmailicon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="whatsapp-icon">
                  <a
                    href="tel:+91 8889991800"
                    target="_blank"
                    rel="nofollow"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/whatsapp-icon.png"
                      alt="whatsappicon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="social-media-links-container">
            <div className="social-media-links-header">
              <div className="social-media-links-text">
                Connect With Us
                <div className="line-div">
                  <hr className="live-training-pink-line"></hr>
                </div>
              </div>

              <div className="home-page-walking-packages-desktop-container2">
                <div>Connect on applications you use</div>
                <div>daily to get Informative/Educational </div>
                <div>contents on Petmojo.</div>
              </div>
            </div>

            <div className="social-media-links-image">
              <div className="ellipse-container">
                <div className="ellipse-desktop">
                  {/* <LazyLoad> */}
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Ellipse_374_ijrumy.png"
                    alt="outerring"
                    className="outer-ring-desktop"
                  ></img>
                  {/* </LazyLoad> */}
                  {/* <LazyLoad> */}
                  <img
                    src={
                      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Ellipse_373_iadrer.png"
                    }
                    alt="innerring"
                    className="inner-ring-desktop"
                  ></img>
                  {/* </LazyLoad> */}
                  <button className="youtube-icon-desktop">
                    <a
                      href="https://youtube.com/channel/UCPQrCllizVvTOkSk7_1SIHw"
                      target="_blank"
                      rel="nofollow"
                    >
                      {/* <LazyLoad> */}
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/yt-icon.png"
                        alt="youtubeicon"
                        width="60px"
                        height="60px"
                      ></img>
                      {/* </LazyLoad> */}
                    </a>
                  </button>
                  <button className="facebook-icon-desktop">
                    <a
                      href="https://www.facebook.com/petmojo.care/"
                      target="_blank"
                      rel="nofollow"
                    >
                      {/* <LazyLoad> */}
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/fb-desktop.png"
                        alt="facebookicon"
                        width="60px"
                        height="60px"
                      ></img>
                      {/* </LazyLoad> */}
                    </a>
                  </button>

                  <button className="instagram-icon-desktop">
                    <a
                      href="https://www.instagram.com/petmojo.care/"
                      target="_blank"
                      rel="nofollow"
                    >
                      {/* <LazyLoad> */}
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/insta-desktop.png"
                        alt="instagramicon"
                        width="60px"
                        height="60px"
                      ></img>
                      {/* </LazyLoad> */}
                    </a>
                  </button>
                  <button className="phone-icon-desktop">
                    <a
                      href="tel:+91 8889991800"
                      target="_blank"
                      rel="nofollow"
                    >
                      {/* <LazyLoad> */}
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/phone-blue-desktop.png"
                        alt="phoneicon"
                        width="60px"
                        height="60px"
                      ></img>
                      {/* </LazyLoad> */}
                    </a>
                  </button>
                  <button className="gmail-icon-desktop">
                    <a
                      href="mailto: customercare@petmojo.in"
                      target="_blank"
                      rel="nofollow"
                    >
                      {/* <LazyLoad> */}
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/gmail-desktop.png"
                        alt="gmailicon"
                        width="60px"
                        height="60px"
                      ></img>
                      {/* </LazyLoad> */}
                    </a>
                  </button>
                  <button className="whatsapp-icon-desktop">
                    <a
                      href="tel:+91 8889991800"
                      target="_blank"
                      rel="nofollow"
                    >
                      {/* <LazyLoad> */}
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/whatsapp-desktop.png"
                        alt="whatsappicon"
                        width="60px"
                        height="60px"
                      ></img>
                      {/* </LazyLoad> */}
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {view === "mobile" ? (
        <div>
          <div className="mobile-view-footer-1">
            <div className="mobile-view-footer-1-left">
              <div className="mobile-view-footer-1-left-header-container">
                <div className="mobile-view-footer-1-header">Need Help</div>
                <hr className="vector2-footer"></hr>
              </div>
              <div className="mobile-view-footer-1-content-left">
                <div className="mobile-view-footer-phone-container">
                  <div className="mobile-view-footer-phone-icon">
                    <img
                      alt="telIcon"
                      className="mobile-view-tel-icon"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-phone-icon.png"
                    />
                  </div>
                  <div className="">
                    <a
                      className="mobile-view-footer-phone-text"
                      style={{ textDecoration: "none" }}
                      href="tel: +91 8889991800"
                      target="_blank" rel="nofollow"
                    >
                      8889991800
                    </a>
                  </div>
                </div>
                <div className="mobile-view-footer-mail-container">
                  <div className="mobile-view-footer-mail-icon">
                    <img
                      alt="mailIcon"
                      className="mobile-view-mail-icon"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-mail-icon.png"
                    />
                  </div>
                  <div className="">
                    <a
                      className="mobile-view-footer-mail-text"
                      style={{ textDecoration: "none" }}
                      href="mailto: customercare@petmojo.in"
                      target="_blank" rel="nofollow"
                    >
                      customercare@petmojo.in
                    </a>
                  </div>
                </div>
                <div className="social-icons-mobile">
                  <a
                    href="https://www.facebook.com/petmojo.care/"
                    target="_blank" rel="nofollow"
                  >
                    <div>
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/fb.png"
                        alt=""
                      />
                    </div>
                  </a>
                  <a href="https://twitter.com/Petmojo_App" target="_blank" rel="nofollow">
                    <div>
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/tw.png"
                        alt=""
                      />
                    </div>
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCPQrCllizVvTOkSk7_1SIHw"
                    target="_blank" rel="nofollow"
                  >
                    <div>
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/yt.png"
                        alt=""
                      />
                    </div>
                  </a>
                  <a
                    href="https://www.instagram.com/petmojo.care/"
                    target="_blank" rel="nofollow"
                  >
                    <div>
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/ig.png"
                        alt=""
                      />
                    </div>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/petmojo/"
                    target="_blank" rel="nofollow"
                  >
                    <div>
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/linkedin.png"
                        alt=""
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="mobile-view-footer-1-right">
              <div className="mobile-view-footer-1-header">Services</div>
              <hr className="vector2-footer"></hr>
              <div className="mobile-view-footer-1-right-content">
                <Link
                  className="mobile-view-footer-page-link"
                  style={{ textDecoration: "none" }}
                  to="/dog-running"
                  state={{ from: "footer" }}
                >
                  Dog Exercise/Walking
                </Link>
              </div>
              <div className="mobile-view-footer-1-right-content">
                <Link
                  className="mobile-view-footer-page-link"
                  style={{ textDecoration: "none" }}
                  to="/dog-training"
                  state={{ from: "footer" }}
                >
                  Dog Training
                </Link>
              </div>
              <div className="mobile-view-footer-1-right-content">
                <Link
                  className="mobile-view-footer-page-link"
                  style={{ textDecoration: "none" }}
                  to="/grooming"
                  state={{ from: "footer" }}
                >
                  Pet Grooming
                </Link>
              </div>
              <div className="mobile-view-footer-1-right-content">
                <Link
                  className="mobile-view-footer-page-link"
                  style={{ textDecoration: "none" }}
                  to="/grooming-subscription"
                  state={{ from: "training" }}
                >
                  Grooming Subscription
                </Link>
              </div>
              <div className="mobile-view-footer-1-right-content">
                <Link
                  className="mobile-view-footer-page-link"
                  style={{ textDecoration: "none" }}
                  to="/pet-boarding"
                  state={{ from: "footer" }}
                >
                  Pet Hotel
                </Link>
              </div>
              {/* <div className="mobile-view-footer-1-right-content">
                <Link
                  className="mobile-view-footer-page-link"
                  style={{ textDecoration: "none" }}
                  to="/styling"
                  state={{ from: "footer" }}
                >
                  Pet Styling
                </Link>
              </div> */}
            </div>
          </div>

          <div className="mobile-view-footer-2">
            <div className="mobile-view-footer-2-left">
              {/* add serving in mobile start */}

              <div className="footerDetailsHeading-mobile">Serving In</div>
              <hr className="footerDetailsLine"></hr>
              <div className="footerDetailsContentContainer-mobile">
                <div>
                  <div className="footerDetailsLink-mobile">Delhi</div>
                  <div className="footerDetailsLink-mobile">Gurgaon</div>
                  <div className="footerDetailsLink-mobile">Noida</div>
                  <div className="footerDetailsLink-mobile">Ghaziabad</div>
                </div>
                <div>
                  <div className="footerDetailsLink-mobile">Faridabad</div>
                  <div className="footerDetailsLink-mobile">Manesar</div>
                  <div className="footerDetailsLink-mobile">Mumbai</div>
                </div>
              </div>

              {/* add serving in mobile end */}
            </div>
            <div className="mobile-view-footer-2-right">
              <div className="mobile-view-footer-2-right-header">More</div>
              <hr className="vector2-footer"></hr>
              <div className="mobile-view-links-div">
                <Link
                  style={{
                    textDecoration: "none",
                    color: "rgba(132, 132, 132, 1)",
                  }}
                  to="/terms"
                >
                  <div className="mobile-view-footer-2-tnc">
                    Terms and Conditions
                  </div>
                </Link>
                <Link
                  style={{
                    textDecoration: "none",
                    color: "rgba(132, 132, 132, 1)",
                  }}
                  to="/privacy"
                >
                  <div className="mobile-view-footer-2-tnc">Privacy Policy</div>
                </Link>
                <Link
                  style={{
                    textDecoration: "none",
                    color: "rgba(132, 132, 132, 1)",
                  }}
                  to="/partner"
                >
                  <div className="mobile-view-footer-2-left-content">
                    Become a Partner
                  </div>
                </Link>{" "}
                <div className="mobile-view-footer-2-left-content">
                  Privacy Policy - Partner
                </div>
                <Link
                  style={{
                    textDecoration: "none",
                    color: "rgba(132, 132, 132, 1)",
                  }}
                  to="/customer-support-policy"
                >
                  <div className="mobile-view-footer-2-left-content">
                    Customer Support Policy
                  </div>
                </Link>{" "}
                <Link
                  style={{
                    textDecoration: "none",
                    color: "rgba(132, 132, 132, 1)",
                  }}
                  to="/about"
                >
                  <div className="mobile-view-footer-2-left-content">
                    About Us
                  </div>
                </Link>{" "}
              </div>
            </div>
          </div>

          <div className="mobile-footer-wrap" ref={sectionTwoRef}>

            <div className="mobile-view-footer-3" style={{ gap: "5px" }}>

              <div className="mobile-view-footer-3-btn1" style={{ height: "33px" }}>
                <a href="https://apps.apple.com/in/app/petmojo/id1609861328" target="_blank" rel="nofollow">
                  <img
                    className="mobile-view-footer-appStore-icon "
                    alt="appStore"
                    id='section3'
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/app-store-2.png"
                    style={{ height: "100%", width: "100%", objectFit: "contain" }}
                  />
                </a>

              </div>
              <div className="mobile-view-footer-3-btn2" style={{ height: "33px" }}>
                <a href="https://play.google.com/store/apps/details?id=in.tamely.user" target="_blank" rel="nofollow" >
                  <img
                    className="mobile-view-footer-appStore-icon "
                    alt="playStore"
                    id='section4'
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/play-store-2.png"
                    style={{ height: "100%", width: "100%", objectFit: "contain" }}
                  />
                </a>

              </div>
              <div
                onClick={() => goToTopHandler()}
                className="mobile-view-footer-3-btn3" style={{ height: "33px", padding: "5px", display: "flex", justifyContent: "center" }}
              >
                <span>Go to the top</span>
                <BsArrowUp />
                {/* <div style={{}}>Go to the top</div>
              <div >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"  
                  // height="16"
                  fill="currentColor"
                  class="bi bi-arrow-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                  />
                </svg>
              </div> */}
              </div>
            </div>
          </div>

          <div className="mobile-view-footer-4" style={{ marginBottom: "20px" }}>
            <div>Copyright 2024 Petmojo</div>
            <div>
              All copyrights are reserved - any infringement will be legally
              pursued.
            </div>
            <div>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </div>
          </div>
        </div>
      ) : (
        <div className="footerDetailsContainer">
          <div className="footerDetailsCont1">
            <div className="footerDetailsHeading">Need Help</div>
            <hr className="footerDetailsLine"></hr>
            <div className="footerDetailsContentContainer">
              <div className="footer-details-content-call-mail">
                <div>
                  <img
                    alt="icon"
                    className="icon-desktop-call"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/more-contact-icon.png"
                  />
                </div>
                <div>
                  <a
                    className="footerDetailsLink"
                    target="_blank" rel="nofollow"
                    href="tel: 8889991800"
                  >
                    8889991800
                  </a>
                </div>
              </div>

              <div className="footer-details-content-call-mail">
                <div>
                  <img
                    alt="icon"
                    className="icon-desktop-mail"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-mail-icon.png"
                  />
                </div>
                <div>
                  <a
                    className="footerDetailsLink"
                    target="_blank" rel="nofollow"
                    href="mailto: customercare@petmojo.in"
                  >
                    customercare@petmojo.in
                  </a>
                </div>
              </div>
              <div className="social-icons">
                <a href="https://www.facebook.com/petmojo.care/" target="_blank" rel="nofollow">
                  <div>
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/fb.png"
                      alt=""
                    />
                  </div>
                </a>
                <a href="https://twitter.com/Petmojo_App" target="_blank">
                  <div>
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/tw.png"
                      alt=""
                    />
                  </div>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCPQrCllizVvTOkSk7_1SIHw"
                  target="_blank" rel="nofollow"
                >
                  <div>
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/yt.png"
                      alt=""
                    />
                  </div>
                </a>
                <a href="https://www.instagram.com/petmojo.care/" target="_blank" rel="nofollow">
                  <div>
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/ig.png"
                      alt=""
                    />
                  </div>
                </a>
                <a
                  href="https://www.linkedin.com/company/petmojo/"
                  target="_blank" rel="nofollow"
                >
                  <div>
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/linkedin.png"
                      alt=""
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="footerDetailsCont1">
            <div className="footerDetailsHeading">Services</div>
            <hr className="footerDetailsLine"></hr>
            <div className="footerDetailsContentContainer">
              <Link
                className="footerDetailsLink"
                to="/dog-running"
                state={{ from: "footer" }}
              >
                <div>Daily Dog Exercise/Walking</div>
              </Link>
              <Link
                className="footerDetailsLink"
                to="/dog-training"
                state={{ from: "footer" }}
              >
                <div>Dog Training</div>
              </Link>
              <Link
                className="footerDetailsLink"
                to="/cat-training"
                state={{ from: "training" }}
              >
                <div>Cat Training</div>
              </Link>
              <Link
                className="footerDetailsLink"
                to="/grooming"
                state={{ from: "footer" }}
              >
                <div>Pet Grooming</div>
              </Link>
              <Link
                className="footerDetailsLink"
                to="/grooming-subscription"
                state={{ from: "footer" }}
              >
                <div>Grooming Subscription</div>
              </Link>
              <Link
                className="footerDetailsLink"
                to="/pet-boarding"
                state={{ from: "footer" }}
              >
                <div>Pet Hotel</div>
              </Link>
              {/* <Link
                className="footerDetailsLink"
                to="/styling"
                state={{ from: "footer" }}
              >
                <div>Pet Styling</div>
              </Link> */}
            </div>
          </div>
          <div className="footerDetailsContr1">
            <div className="footerDetailsHeading">Serving In</div>
            <hr className="footerDetailsLine"></hr>
            <div className="footerDetailsContentContainer">
              <div className="footerDetailsLink">Delhi</div>
              <div className="footerDetailsLink">Gurgaon</div>
              <div className="footerDetailsLink">Noida</div>
              <div className="footerDetailsLink">Ghaziabad</div>
              <div className="footerDetailsLink">Faridabad</div>
              <div className="footerDetailsLink">Manesar</div>
              <div className="footerDetailsLink">Mumbai</div>
            </div>
          </div>
          <div className="footerDetailsCont1">
            <div className="footerDetailsHeading">More</div>
            <hr className="footerDetailsLine"></hr>
            <div className="footerDetailsContentContainer">
              <Link className="footerDetailsLink" to="/partner">
                <div>Become a Partner</div>
              </Link>
              <Link className="footerDetailsLink" to="/terms">
                <div>Terms and Conditions</div>
              </Link>
              <Link className="footerDetailsLink" to="/privacy">
                <div>Privacy Policy</div>
              </Link>
              <Link className="footerDetailsLink" to="/">
                <div>Privacy Policy - Partner</div>
              </Link>
              <Link className="footerDetailsLink" to="/customer-support-policy">
                <div>Customer Support Policy</div>
              </Link>
              <Link className="footerDetailsLink" to="/about">
                <div>About Us</div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Footer;
