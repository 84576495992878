import {useEffect, useState} from 'react';
import LazyLoad from "react-lazyload";
import GroomingPageFormNew from './GroomingUCPageFormNew';
import GroomingPageForm from './GroomingPageForm';
import NavigationDesktop from '../../../components-desktop/Navigation/NavigationDesktop';

function IntroContainer({ introImage }) {

  return (
        <div className="intro-desktop-home-page-master-container" style={{background:"white"}}>
            <div className='wrapper-class-intro-desktop-video' style={{width:"100%",position:"absolute"}}>
              {/* <video  role="presentation" autoPlay loop muted style={{width:"100vw",objectFit:"contain"}}>
                <source src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-vid-compress.mp4" type="video/mp4" style={{width:"100%"}}>
                </source>
              </video> */}
              {/* <img src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/groomingwebsite.webp" alt="" style={{width:"100vw",height:"160vh",objectFit:"cover"}}/> */}
            </div>

            <div className='intro-desktop-home-page-content-container' style={{position:"relative"}}>
              <NavigationDesktop submissionType={"/grooming"} submissionText={"Book Now"} />

              <div 
            //   className='intro-desktop-head-combiner-grooming'
            style={{position:"relative",width:"200vh"}}
              >
                  <GroomingPageFormNew />
              </div>
            </div>
        </div>
  );
}

export default IntroContainer;
