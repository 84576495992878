import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import IntroContainerDesktopTraining from "./container/introContainer/IntroContainerDesktopDeleteAccount";
import IntroContainerMobileTraining from "./container/introContainer/IntroContainerMobileDeleteAccount";
import "./Training.css";

function DeleteAccount() {
  const [isActive, setIsActive] = useState(false);
  const [changeView, setChangeView] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 1000) {
      setChangeView(false);
    } else {
      setChangeView(true);
    }
  }, []);

  return (
    <div className={!changeView ? "training" : "training-desktop"}>
      <Helmet>
        <title>Professional Dog Training at home - Dog Trainer near you - Petmojo</title>
        <meta
          name="description"
          content="Book Dog Training at the comfort of your home. Petmojo offers the best dog training services. Best Dog Trainers near you. "
        />
      </Helmet>

      {!changeView ? (
        <IntroContainerMobileTraining />
      ) : (
        <IntroContainerDesktopTraining />
      )}
    </div>
  )

}

export default DeleteAccount;