import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import deskBg from '../../images/reviewDesktopBg.png';
import mobileBg from '../../images/reviewMobileBg.png';
import NavigationDesktop from "../../components-desktop/Navigation/NavigationDesktop";
import NavigationBar from "../../components/Navigation/navigationBar/NavigationBar";

const baseUrl = "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api"

const inputClass = 'py-2 px-5 border border-1 border-gray-400 focus:border-gray-600 rounded-lg focus:outline-none';

const VerifyUser = () => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [otp, setOtp] = useState('');
    const [changeView, setChangeView] = useState(false);
    const [previousRoute, setPreviousRoute] = useState('');

    const userAuthToken = localStorage.getItem("token");

    const navigate = useNavigate();
    const location = useLocation();

    const getmobileOtp = async () => {
        try {
            const res = await axios.post(`${baseUrl}/auth/sendMobileOTP`, {
                phoneNumber: phone
            })
            console.log(res.data)
        } catch (error) {
            console.log(error)
        }
    }

    const verifyMobileOTP = async () => {
        try {
            const res = await axios.post(`${baseUrl}/auth/verifyMobileOTP`, {
                otp: otp,
                phoneNumber: phone,
                type: "User"
            })

            console.log("reqqqq", res.data)

            localStorage.setItem("token", res.data.token)
            localStorage.setItem("username", name)
            setOtpSent(false)
            navigate(previousRoute || '/'); // Navigate to the previous route if available, otherwise to the home page
        }
        catch (error) {
            if (error.response.status === 400) {
                alert(error.response.data.message)
            }
        }
    }

    useEffect(() => {
        if (window.innerWidth <= 1000) {
            setChangeView(false);
        } else {
            setChangeView(true);
        }
    }, []);

    const onVerify = () => {
        if (phone && phone.length === 10) {
            setOtpSent(true);
            getmobileOtp();
        } else {
            alert("Please enter a valid phone number")
        }
    }

    useEffect(() => {
        if (userAuthToken) {
            navigate('/reviews')
        }
    },[])

    useEffect(() => {
        setPreviousRoute(location.state?.from || '/reviews');
    }, [location.state]);

    return (
        <div className="w-screen h-screen relative overflow-y-auto" style={{ backgroundImage: `url(${changeView ? deskBg : mobileBg})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
            {changeView ? (
                <NavigationDesktop
                    submissionType={"none"}
                    submissionText={"Download Our App"} />
            ) : (
                <NavigationBar btn={"training"} homePage={true} page={"white"} />
            )}
            <div className="mx-auto max-w-7xl my-5">
                <div className='w-full mt-20 px-10 py-5 flex flex-col justify-center '>
                    {!otpSent ?
                        <div className={`w-full max-w-lg mx-auto flex flex-col bg-white gap-5 ${changeView ? 'border border-2 border-brand' : ''} p-5`}>
                            <div className='text-2xl font-semibold'>Please Enter Your Details</div>
                            <input className={inputClass} type='text' placeholder='Enter Your Name' onChange={(e) => setName(e.target.value)} value={name} required />
                            <input className={inputClass} type='text' placeholder='Phone Number' onChange={(e) => setPhone(e.target.value)} value={phone} required />
                            <div className='w-full flex justify-center'>
                                <button onClick={onVerify} className='py-2 px-20 bg-brand text-white font-bold rounded-lg'>Verify</button>
                            </div>
                        </div>
                        :
                        <div className={`w-full max-w-lg mx-auto flex flex-col bg-white gap-5 ${changeView ? 'border border-2 border-brand' : ''} p-5`}>
                            <div className='text-2xl font-semibold'>Enter OTP</div>
                            <input className={inputClass} name='otp' type='Number' placeholder='Enter Otp' required onChange={(e) => setOtp(e.target.value)} />
                            <button className='text-brand hover:underline' onClick={() => getmobileOtp()}>Resend OTP ?</button>
                            <div className='w-full flex justify-center'>
                                <button onClick={verifyMobileOTP} className='py-2 px-20 bg-brand text-white font-bold rounded-lg'>Submit</button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default VerifyUser;
