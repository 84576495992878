import { useEffect, useState } from 'react';
import NavigationBar from '../../Navigation/navigationBar/NavigationBar';
import DeleteAccountPageForm from '../mobileForms/DeleteAccountPageForm';

const IntroContainerMobileTraining = (props) => {
  const [showad, setShowad] = useState(true);
  const [isAndroid, setIsAndroid] = useState(false);
  const [isiOS, setIsiOS] = useState(false);
  // const removeAdd = () => {
  //   const ele = document.getElementById("grooming-popup-ad");
  //   ele.style.display = "none";
  //   setShowad(false);
  // };
  // setTimeout(() => {
  //   const ele = document.getElementById("grooming-popup-ad");
  //   ele.style.display = "flex";
  // }, 5000);
  let url;

  const userAgent = navigator.userAgent.toLowerCase();
  useEffect(() => {
    setIsAndroid(userAgent.indexOf("android") > -1);
    setIsiOS(
      userAgent.indexOf("iphone") > -1 ||
      userAgent.indexOf("ipad") > -1 ||
      userAgent.indexOf("ipod") > -1
    );
    // console.log(userAgent.indexOf("iphone"))
  }, [userAgent]);

  if (isAndroid) {
    url = "https://play.google.com/store/apps/details?id=in.tamely.user";
  } else if (isiOS) {
    url = "https://apps.apple.com/in/app/petmojo/id1609861328";
  }
  return (
    <div className='delete-page-intro-mobile-view'>

      <NavigationBar btn={"training"} homePage={true} page={"white"} className="home-page-mobile-navigation-container" />
      {/* <IntroContainer/> */}
      <div className='home-page-intro-mobile-view-container'>
        <h1 className='intro-heading-mobile-view-header'>
          <div className='home-page-intro-mobile-view-heading-training'>
            <div>Enter your <span className='pink-bold'>Phone </span></div>
            <div><span className='pink-bold'>Number</span> or <span className='pink-bold'>Email</span></div>
            <div >Delete account</div>
          </div>
        </h1>

        <div className="home-page-mobile-form-container">
          <DeleteAccountPageForm />
        </div>
      </div>
    </div>

  );
};

export default IntroContainerMobileTraining;