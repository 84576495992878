import {useEffect, useState} from 'react';
import NavigationBar from '../../Navigation/navigationBar/NavigationBar';
import HomePageForm from '../mobileForms/HomePageForm';


function IntroContainer({ introImage }) {
  const [isAndroid, setIsAndroid] = useState(false);
  const [isiOS, setIsiOS] = useState(false);
  let url;

  const userAgent = navigator.userAgent.toLowerCase();
  useEffect(() => {
    setIsAndroid(userAgent.indexOf("android") > -1);
    setIsiOS(
      userAgent.indexOf("iphone") > -1 ||
        userAgent.indexOf("ipad") > -1 ||
        userAgent.indexOf("ipod") > -1
    );
    // console.log(userAgent.indexOf("iphone"))
  }, [userAgent]);

  if (isAndroid) {
    url = "https://play.google.com/store/apps/details?id=in.tamely.user";
  } else if (isiOS) {
    url = "https://apps.apple.com/in/app/petmojo/id1609861328";
  }

  return (
    <div className='home-page-intro-mobile-view-test'>
      <NavigationBar btn={"training"} homePage={true} page={"white"} className="home-page-mobile-navigation-container" />
      <div className='home-page-intro-mobile-view-container'>
        <h1 className='intro-heading-mobile-view-header'>
          <div className='home-page-intro-mobile-view-heading-test'>
              <div style={{color:"white",fontWeight:"700"}}>We Provide The Best <span className='pink-bold'>
      Pet Care Services
                </span> </div>
              <div style={{color:"white",fontWeight:"700",fontFamily:"DM Sans"}}>at The <span className='pink-bold'>Comfort
                </span> Of Your Home!</div>
          </div>
        </h1>
        <div className="home-page-mobile-form-container" >
          <HomePageForm />
        </div>
      </div>

    </div>
  );
}

export default IntroContainer;
