export const faqData = {
    "General FAQ's": [
        {
            "title": "What areas do you serve?",
            "content": "We proudly provide our services in the bustling regions of Delhi NCR and Mumbai. Whether you are located in the heart of the city or in the surrounding suburbs, our dedicated team is ready to bring top-notch service for your beloved pet. If you are unsure whether your specific location falls within our service area, please don't hesitate to contact us directly for confirmation. We are committed to making our services accessible and convenient for all pet owners in these regions."
        },
        {
            "title": "How long is each training / grooming / walking session?",
            "content": "Typically, each training session takes about 30 minutes. This duration ensures that the time is sufficient for learning without overloading your dog."
        },
        {
            "title": "What training methods do you use",
            "content": "At our company, we strictly adhere to the positive reinforcement method for training your furry companions. This approach focuses on rewarding desirable behaviors to encourage their repetition, ensuring a joyful and stress-free learning experience. We believe in building trust and a strong bond between you and your pet, and thus, we strictly avoid any form of punishment or raising our voices. Our goal is to create a positive, supportive environment where your dog can thrive, learn, and develop good behavior naturally and happily."
        },
        {
            "title": "Can you train dogs of all ages and breeds?",
            "content": "Yes, we can train dogs of all ages and breeds. Our positive reinforcement methods are effective and adaptable, ensuring that every dog receives the best training experience tailored to their unique needs."
        },
        {
            "title": "How long are your dog walking sessions?",
            "content": "Typically, each walking session takes about 30 minutes."
        },
        {
            "title": "Can I request a specific time for dog walking?",
            "content": "Absolutely. We form our schedule according to your needs for your comfort, ensuring your dog is walked at a time that suits you best."
        },
        {
            "title": "How often should my dog be groomed",
            "content": "The frequency of grooming depends on your dog's breed, coat type, and lifestyle. Generally, most dogs benefit from grooming every 2-4 weeks to maintain a healthy coat and skin."
        },
    ],
    "Dog Boarding": [
        {
            "title": "Can I check in on my dog while they are boarding?",
            "content": "Yes, certainly. We welcome you to check in on your dog during their boarding stay to ensure their well-being and peace of mind."
        },
        {
            "title": "Do you provide any activities or exercise for dogs during boarding?",
            "content": "Yes, we provide a variety of activities and exercises for dogs during boarding. Our services include regular walks and socializing sessions to ensure your dog stays active and engaged while you're away. These activities are designed to keep your pet happy, healthy, and well-exercised."
        },
        {
            "title": "Can I check in on my dog while they are boarding?",
            "content": "Yes, certainly. We welcome you to check in on your dog during their boarding stay to ensure their well-being and peace of mind."
        },
    ],
    "Policies and Safety": [
        {
            "title": "Are your staff trained and certified?",
            "content": "Yes, our staff are trained and certified. We take pride in ensuring our team is well-qualified to provide the best care for your pets. Additionally, we regularly upgrade their knowledge and skills through ongoing education, training, and workshops."
        },
    ],
    "Service Issues": [
        {
            "title": "What should I do if my trainer/walker/groomer is late or not regular in attending sessions?",
            "content": "If your trainer, walker, or groomer is late or not regular in attending sessions, please reach out to our customer support team. They are ready to assist you and will be happy to provide various solutions to address your concerns. Your satisfaction is our priority!"
        },
        {
            "title": "How do I report a missed session?",
            "content": "Please reach out to our customer support team. They are ready to assist you and  help accommodate your needs."
        },
        {
            "title": "How do I report a fake session?",
            "content": "Please reach out to our customer support team. They are ready to assist you and  help accommodate your needs."
        },
        {
            "title": "How can I address concerns about the quality of training/walking/grooming I am receiving?",
            "content": "If our training, walking, or grooming services quality is not up to par, please reach out to our customer support team. They are ready to assist you and will be happy to provide various solutions to address your concerns. Your satisfaction is our priority!"
        },
        {
            "title": "Can I request a different service provider if I'm not happy with the current one?",
            "content": "If you`re not happy with your current service provider, please reach out to our customer support team. They are ready to assist you and will be happy to connect you with another service partner. Your comfort is our priority!"
        },
        {
            "title": "How should I handle a situation where a trainer/walker/gromer is behaving unprofessionally?",
            "content": "If you encounter a situation where your trainer, walker, or groomer is behaving unprofessionally, it is important to address it immediately. We recommend that you urgently contact our customer support team. Please note that any form of rudeness or unprofessional behavior is unacceptable, and we take such matters very seriously. Our team is here to ensure your experience is positive and will work swiftly to resolve the issue. Your satisfaction and well-being are our top priorities!"
        },
    ],
    "Booking and Payment": [
        {
            "title": "How do I book a service?",
            "content": "You can book a service directly through our app or by calling our sales department. Both options are designed to make the booking process as convenient and straightforward as possible for you."
        },
        {
            "title": "What payment methods do you accept?",
            "content": `We accept a variety of payment methods to accommodate your preferences:\nUPI: You can make payments easily using UPI.\nApplication: Payments can be processed through our official application.\nOfficial Payment Link: Use the provided payment link for secure transactions.\nAccount Transfer: Direct transfers to our bank account are also accepted.\nYou may opt to pay via cheque or cash if you prefer an offline method.`
        },
        {
            "title": "Can I reschedule my appointment?",
            "content": "Yes, you can reschedule your appointment by reaching out to our sales team if it's your first trial appointment. If you have already booked our service, please contact our customer support team, and they will be happy to assist you."
        },
        {
            "title": "What should I do if I need to temporarily suspend pause my service plan?",
            "content": "If you need to temporarily suspend or pause your service plan, please contact our customer support team. They will guide you through the process and help accommodate your needs."
        },
        {
            "title": "How do I provide feedback about the service?",
            "content": "You can provide feedback about the service by contacting our customer support team. We value your input and strive to continually improve based on your suggestions."
        },
    ],
}