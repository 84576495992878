import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import greenTick from "../../../images/green-tick.png";
import ImageSlider from "../../ImageSlider";
import catSelect from "./../../../images/catSelect.png";
import dogSelect from "./../../../images/dogSelect.png";
import handShake from "./../../../images/HandShake.png";
import handPercent from "./../../../images/percentHand.png";
import starImg from "./../../../images/star.png";
// import greenTick from "./../../../images/green-tick.png"
// import ImageSlider from "../../ImageSlider";
//import dotenv from 'dotenv'

const timeObj = {
  "10AM-02PM": 1,
  "02PM-06PM": 2,
  "06PM-10PM": 3,
};

const PackageToAmountMapping = {
  "Bath and Brush": 799,
  "Bath & Brush(with tick treatment)": 1199,
  "Haircut and Styling": 1249,
  "Bath, Full haircut & Styling": 1649,
  "Massage, Bath, Full haircut & Styling": 1949,
};

const style = {
  position: "relative",
  top: '5%',
  left: '30%',
  // transform: 'translate(-50%, -50%)',
  // width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  width: "35.3rem",
  overflowY: "auto", maxHeight: "80vh",
  margin: "0 2rem",
  height: "80vh"
};

const images = [
  {
    id: 1,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-1.webp",
    alt: "Image 1",
  },
  {
    id: 2,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-2.webp",
    alt: "Image 2",
  },
  {
    id: 3,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-3.webp",
    alt: "Image 3",
  },
  {
    id: 4,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-4.webp",
    alt: "Image 4",
  },
  {
    id: 5,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-5.webp",
    alt: "Image 5",
  },
  {
    id: 6,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-6.webp",
    alt: "Image 6",
  },
  {
    id: 7,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-7.webp",
    alt: "Image 7",
  },
  {
    id: 8,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-8.webp",
    alt: "Image 8",
  },
];

const HomePageForm = (props) => {
  const urlParams = new URLSearchParams(window.location.search);
  const name = urlParams.get("name") || "";
  const number = urlParams.get("number") || "";
  const groomingAddress = urlParams.get("address") || "";
  const amount = urlParams.get("amount") || "";
  const groomingDate = urlParams.get("date") || "";
  const packageType = urlParams.get("package") || "";
  const time = urlParams.get("time") || "";
  const navigate = useNavigate();
  const [selectedService, setSelectedService] = useState(packageType);
  const [couponCode, setCouponCode] = useState("");
  const [couponValue, setCouponValue] = useState(0);
  const [couponErrorMsg, setCouponErrorMsg] = useState("");
  const [couponNotValid, setCouponNotValid] = useState(false);
  const [couponValid, setCouponValid] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [countdown, setCountdown] = useState(true);
  const [serviceDropdown, setServiceDropdown] = useState(false);
  const [selectedCarousel, setSelectedCarousel] = useState(
    name && number && packageType && time && groomingDate ? 3 : 1
  );
  const [timeSlot, setTimeSlot] = useState(timeObj[time] || 1);
  const [selectedDate, setSelectedDate] = useState(groomingDate);
  const [selectedTime, setSelectedTime] = useState(time);
  const [serviceChoice, setServiceChoice] = useState(
    packageType || "Bath and Brush"
  );
  const [pet, setPet] = useState("Dog");
  const [petChoice, setpetChoice] = useState(1);
  const location = useLocation().pathname;
  const [overallChoice, setOverallChoice] = useState({
    choice: "",
    pet: "",
    date: "",
    time: "10AM-02PM",
    name: "",
    address: "",
    phone: "",
    latitude: 0,
    longitude: 0,
  });
  const [newNameValidation, setNewNameValidation] = useState(true);
  const [newPhoneValidation, setNewPhoneValidation] = useState(true);
  const [newAddressValidation, setNewAddressValidation] = useState(true);
  const [newAddressValidation1, setNewAddressValidation1] = useState(true);
  const [newDateValidation, setNewDateValidation] = useState(true);
  const [representationDate, setRepresentationDate] = useState("");
  const [representationDay, setRepresentationDay] = useState("");
  const [isVerified, setIsVerified] = useState(null);
  const [showEnterOtp, setShowEnterOtp] = useState(false);
  const [isWrongOtp, setIsWrongOtp] = useState(false);
  const [correctOTP, setCorrectOTP] = useState(true);

  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  const [open3, setOpen3] = useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);

  const [open4, setOpen4] = useState(false);
  const handleOpen4 = () => setOpen4(true);
  const handleClose4 = () => setOpen4(false);

  const [open5, setOpen5] = useState(false);
  const handleOpen5 = () => setOpen5(true);
  const handleClose5 = () => setOpen5(false);

  const baseUrl =
    "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api";
  const [token, setToken] = useState("");
  // const [bookingId, setBookingId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api
  const newDateRef = useRef("");
  const newServiceRef = useRef("");
  const newTimeServiceRef = useRef("");
  const newNameRef = useRef("");
  const newAddressRef = useRef("");
  const newAddressRef1 = useRef("");
  const newPhoneRef = useRef("");
  const groomingOtpRef = useRef("");

  const [paymentData, setPaymentData] = useState({});
  const [flag, setFlag] = useState(false);
  // const { coords } = useGeolocated();
  const [address, setAddress] = useState(groomingAddress || "");
  const [startValue, setStartValue] = useState(groomingDate ? true : false);
  const [startDate, setStartDate] = useState(groomingDate || "");
  const [startAddress, setStartAddress] = useState(false);
  const [lat, setLat] = useState(0);
  const [lon, setLon] = useState(0);
  const [nameValue, setnameValue] = useState(name ? true : false);
  const [nameFill, setNameFill] = useState(name || "");
  const [phoneValue, setPhoneValue] = useState(number ? true : false);
  const [phoneFill, setPhoneFill] = useState(number || null);
  const [addressValue, setAddressValue] = useState(false);
  const [city, setCity] = useState("");
  const [adressFill, setAdressFill] = useState("");
  const [phone, setPhone] = useState("");
  const [breed, setBreed] = useState();
  const [price1, setPrice] = useState(
    PackageToAmountMapping[packageType] || 799
  );
  let price = 0;
  var currentDate = new Date();
  var year = currentDate.getFullYear();
  var month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
  var day = ("0" + currentDate.getDate()).slice(-2);
  var formattedDate = year + "-" + month + "-" + day;
  function setSelectedServiceHandler(service) {
    setSelectedService(service);
    setServiceDropdown(false);
  }

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  // useEffect(() => {
  //   // This effect will run whenever bookingId changes
  //   console.log("Updated Booking ID:", bookingId);
  // }, [bookingId]);

  async function displayRazorpay(
    serviceAmount,
    inputPhone,
    SelectedService,
    DateOfReg,
    InputAddress
  ) {
    setIsLoading(false);

    // console.log("display");
    // console.log(bookingId);

    const bookingId = localStorage.getItem("bookingId");
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // creating a new order
    const result = await axios.post(
      "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/generateGroomingRazorPayOrderId",
      {
        amount: serviceAmount,
      }
    );

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }

    // Getting the order details back
    const { amount, id: order_id, currency } = result.data;

    const options = {
      key: process.env.REACT_APP_KEY, // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: "Petmojo",
      description: "Pet Grooming Payment",
      //image: { logo },
      order_id: order_id,
      handler: async function (response) {
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };

        console.log("Grooming Page Function: ", data);

        const result = await axios.post(
          "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/generateGroomingRazorPaySuccess",
          data
        );
        //alert(result.data.msg);
        const Data = {
          ...result.data,
          SelectedService,
          DateOfReg,
          InputAddress,
        };

        const actualAmount = serviceAmount;

        const dataToStore = {
          Date: DateOfReg,
          Address: InputAddress,
          Contact_No: inputPhone.toString(),
          Amount_Paid: actualAmount.toString(),
          Selected_Service: SelectedService,
          Order_id: order_id,
        };

        await axios
          .post(
            "https://api.apispreadsheets.com/data/hq3CiEAYcPfNXIJE/",
            dataToStore
          )
          .then((response) => {
            console.log(response);
            console.log("The grooming payment has been completed!");
          })
          .catch((err) => {
            console.log(err);
            console.log(
              "There was some error in submitting the grooming payment to backend!"
            );
          });

        const data2 = {
          bookingId: bookingId,
          transactionId: order_id,
          amount: actualAmount,
          type: "User",
        };

        data2["isFromWebsite"] = true;
        // {
        //     "bookingId" : "63d554eb156e83edfd5d1144",
        //     "transactionId" : "order_JCB9CaLSarOaTj",
        //     "amount":799,
        //     "type": "User"
        // }

        await axios
          .patch(
            "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/service/postGroomingPayment",
            data2,
            {
              "Content-Type": "application/json",
              headers: {
                Authorization:
                  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjYzZWY5ZWZkNTc1MGY5Y2Q4MmVmYmZmNyJ9.QOz1wBekeflAqD2ktWjDQLubM4snuMMDFV0Lk97z5UY",
              },
            }
          )
          .then((response) => {
            console.log(response);
            console.log(
              "The grooming payment has been completed and info has been saved in the database!"
            );
          })
          .catch((err) => {
            console.log(err);
            console.log(
              "There was some error in saving the grooming payment to database!"
            );
          });

        setPaymentData(Data);
        setFlag(true);
      },
      // prefill: {
      //     name: "Petmojo",
      //     email: "customercare@petmojo.in",
      //     contact: "+91 9009004473",
      // },
      notes: {
        address: "Petmojo",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    //console.log(paymentObject);
    paymentObject.open();
  }
  const [showDialog, setShowDialog] = useState(false);
  const checkPhone = (phoneString) => {
    if (
      phoneString.length > 10 &&
      (phoneString.startsWith("+91") || phoneString.startsWith("91"))
    ) {
      phoneString = phoneString.slice(-10);
      setPhone(phoneString);
      setShowDialog(true);
    }
    return phoneString;
  };
  const groomingFormDesktopHandler1 = () => {
    if (serviceChoice === "") {
      console.log("Cant move to next card!");
    }
    //    if(nameFill==""){
    //     setNewNameValidation(false);
    //   }
    //  if(phoneFill==null || phoneFill==""){
    //     setNewPhoneValidation(false);
    //   }
    else {
      setOverallChoice({
        pet: pet,
        choice: serviceChoice,
        name: "",
        address: "",
        phone: "",
        time: "",
        date: "",
        // latitude:lat,
        // longitude:lon
      });
      setSelectedCarousel(2);
      console.log(overallChoice);
    }
  };

  const groomingFormDesktopHandler2 = () => {
    overallChoice.date = newDateRef.current.value;
    if (
      overallChoice.date == "" ||
      nameFill == "" ||
      phoneFill == "" ||
      phoneFill == null
    ) {
      console.log("Cant move to next card!");
      console.log(overallChoice);
    }
    if (nameFill == "") {
      setNewNameValidation(false);
    }
    if (phoneFill == null || phoneFill == "") {
      setNewPhoneValidation(false);
    } else {
      let time = "";
      if (timeSlot === 1) {
        time = "10AM-02PM";
      } else if (timeSlot === 2) {
        time = "02PM-06PM";
      } else if (timeSlot === 3) {
        time = "06PM-10PM";
      }

      setSelectedDate(newDateRef.current.value);
      setSelectedTime(time);
      //overallChoice.time

      setOverallChoice({
        choice: serviceChoice,
        pet: petChoice === 1 ? "Dog" : "Cat",
        date: newDateRef.current.value,
        time: time,
        name: "",
        address: "",
        phone: "",
        // latitude:lat,
        // longitude:lon
      });
      setSelectedCarousel(3);
      console.log(overallChoice);
    }
  };

  const getmobileOtp = useCallback(async () => {
    try {
      let phoneString = checkPhone(newPhoneRef.current.value.toString());
      const res = await axios.post(`${baseUrl}/auth/sendMobileOTP`, {
        phoneNumber: phoneString,
      });
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  }, []);
  const changeName = () => {
    document.getElementById("send-otp-desk").innerHTML = "Resend OTP";
  };
  const verifyMobileOTP = useCallback(async () => {
    try {
      let phoneString = checkPhone(newPhoneRef.current.value.toString());
      const res = await axios.post(
        "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/auth/verifyMobileOTP",
        {
          otp: groomingOtpRef.current.value.toString(),
          phoneNumber: phoneString,
          type: "User",
        }
      );
      console.log(res.status);
      console.log(res.data);

      if (res.status === 200) {
        setIsVerified(true);
        setToken(res.data.token);
        setCountdown(false);
        setCorrectOTP(true);
      }
    } catch (error) {
      console.log(error.response.status);
      console.log(error);
      if (error.response.status === 400) {
        setIsVerified(false);
        setIsWrongOtp(true);
        setCorrectOTP(false);
      }
    }
  }, []);
  const indicateAlert = () => {
    if (!correctOTP) {
      document.getElementById("send-otp-desk").style.borderColor = "red";
    }
  };
  const backArrow = () => {
    setSelectedCarousel(selectedCarousel - 1);
  };
  const decrementSeconds = () => {
    // if(seconds===0){
    //     document.getElementById("resend-countdown").style.display="none"
    // }
    // else{
    setTimeout(() => {
      if (seconds == 0) {
        document.getElementById("countdown-seconds").style.display = "none";
      } else {
        setSeconds(seconds - 1);
      }
    }, 1000);
    // }
  };
  const paymentGatewayHit = async (data) => {
    const path = "grooming";
    const inputPhone = data.ContactNo;
    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${date.getMonth() + 1
      }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;
    const inputAddress = newAddressRef.current.value;

    var amount = 0;

    if (data.Services === "Bath and Brush") {
      //kartik
      amount = 799;
    } else if (data.Services === "Bath & Brush(with tick treatment)") {
      amount = 1199;
    } else if (data.Services === "Haircut and Styling") {
      amount = 1249;
    } else if (data.Services === "Bath, Full haircut & Styling") {
      amount = 1649;
    } else if (data.Services === "Massage, Bath, Full haircut & Styling") {
      amount = 1949;
    }

    // setPrice(amount);
    price = amount;
    data["Amount"] = amount;

    // axios
    //   .post(
    //     "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/saveGroomingData",
    //     data
    //   )
    //   .then((response) => {
    //     console.log(response);
    //     console.log("The grooming form is being submitted...");
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     console.log("There was some error in submitting the grooming form...");
    //   });

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: `${path} Registration Fired From Desktop Service Page Form`,
    });

    displayRazorpay(
      amount - couponValue,
      inputPhone,
      selectedService,
      dateOfReg,
      inputAddress
    );

    console.log(data);
  };

  const paymentInitiationHandler = async () => {
    const phone = phoneFill;
    const phoneString = checkPhone(phone.toString());
    const name = nameFill;
    const address = newAddressRef.current.value;

    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${date.getMonth() + 1
      }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;

    const enteredYear = parseInt(selectedDate.substring(0, 4));
    const enteredMonth = parseInt(selectedDate.substring(5, 7));
    const enteredDay = parseInt(selectedDate.substring(8, 10));

    if (phoneString.length !== 10 || name === "" || address === "") {
      console.log("cannot proceed further!");
    } else {
      // if(enteredMonth === date.getMonth()+1){
      //     if(enteredDay <= date.getDate()){
      //       setNewDateValidation(false);
      //       return;
      //     }
      // }

      setIsLoading(true);

      setOverallChoice({
        choice: serviceChoice,
        name: name,
        address: address,
        phone: phone,
        date: selectedDate,
        time: selectedTime,
        pet: petChoice === 1 ? "Dog" : "Cat",
        latitude: lat,
        longitude: lon,
      });

      let UTM_Source,
        UTM_Medium,
        UTM_Campaign = "";

      const urlParams = new URLSearchParams(window.location.search);
      UTM_Source = urlParams.get("utm_source");
      UTM_Medium = urlParams.get("amp;utm_medium");
      UTM_Campaign = urlParams.get("amp;utm_campaign");

      const data = {
        ContactNo: phone,
        Services: serviceChoice,
        Date: selectedDate,
        Time: selectedTime,
        Breed: breed?.label,
        Email: "",
        FormType: "Direct Form",
        DateOfRegistration: dateOfReg,
        TimeOfRegistration: timeOfReg,
        Address: address,
        Name: name,
        Pet: pet,
        latitude: lat,
        longitude: lon,
        UTM_Source: UTM_Source || "NA",
        UTM_Medium: UTM_Medium || "NA",
        UTM_Campaign: UTM_Campaign || "NA",
      };

      data["paymentStatus"] = 2;
      // ContactNo: inputPhone,
      // Services: selectedService,
      // Date: inputDate,
      // Time: inputTime,
      // Address: inputAddress,
      // DateOfRegistration: dateOfReg,
      // TimeOfRegistration: timeOfReg,
      // Email: inputEmail,
      // FormType: "Direct Form"

      var amount = 0;

      if (serviceChoice === "Bath and Brush") {
        //kartik
        amount = 799;
      } else if (serviceChoice === "Bath & Brush(with tick treatment)") {
        amount = 1199;
      } else if (serviceChoice === "Haircut and Styling") {
        amount = 1249;
      } else if (serviceChoice === "Bath, Full haircut & Styling") {
        amount = 1649;
      } else if (serviceChoice === "Massage, Bath, Full haircut & Styling") {
        amount = 1949;
      }
      // setPrice(amount);

      // let UTM_Source, UTM_Medium, UTM_Campaign = "";

      // const urlParams = new URLSearchParams(window.location.search);
      // UTM_Source = urlParams.get('utm_source');
      // UTM_Medium = urlParams.get('utm_medium');
      // UTM_Campaign = urlParams.get('utm_campaign')

      price = amount;
      const data2 = {
        fullName: name,
        address: address,
        phone: phone,
        Breed: breed?.label,
        startDate: selectedDate,
        sessionTime: selectedTime,
        latitude: lat,
        longitude: lon,
        package: {
          description: serviceChoice,
          price: amount - couponValue,
          frequency: 1,
        },
        type: "User",
        UTM_Source: UTM_Source || "",
        UTM_Medium: UTM_Medium || "",
        UTM_Campaign: UTM_Campaign || "",
      };

      data2["Amount"] = amount;
      await axios
        .post(
          "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/websiteDogGroomingBookingService",
          data2,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: token,
            },
          }
        )
        .then((response) => {
          console.log(response);
          console.log("checking");

          // setBookingId(response.data.bookingId);
          localStorage.setItem("bookingId", response.data.bookingId);
          console.log("The grooming data is submitted to the database.");
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          console.log("There was some error in saving the data in database.");
        });
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: `Grooming Registration Fired From Desktop Service Page Form`,
      });
      paymentGatewayHit(data);
      return;
    }

    if (phoneString.length !== 10) {
      setNewPhoneValidation(false);
    }
    if (name === "") {
      setNewNameValidation(false);
    }
    if (address === "") {
      setNewAddressValidation(false);
    }

    // setIsLoading(false);
  };
  // const displayLocation=()=>{
  //     console.log("Latitude:",lat);
  //     console.log("Longitude:",lon);
  //     console.log(address);
  //     // var ad=toString(lat);
  //     // console.log(ad)
  // }

  const bookNowPayLaterHandler = async () => {
    const phone = phoneFill;
    const phoneString = checkPhone(phone.toString());
    const name = nameFill;
    const address = newAddressRef.current.value;

    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${date.getMonth() + 1
      }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;

    const enteredYear = parseInt(selectedDate.substring(0, 4));
    const enteredMonth = parseInt(selectedDate.substring(5, 7));
    const enteredDay = parseInt(selectedDate.substring(8, 10));

    if (phoneString.length !== 10 || name === "" || address === "") {
      console.log("cannot proceed further!");
    } else {
      // if(enteredMonth === date.getMonth()+1){
      //     if(enteredDay <= date.getDate()){
      //       setNewDateValidation(false);
      //       return;
      //     }
      // }

      setIsLoading(true);

      setOverallChoice({
        choice: serviceChoice,
        name: name,
        address: address,
        phone: phoneString,
        date: selectedDate,
        time: selectedTime,
        pet: petChoice === 1 ? "Dog" : "Cat",
        latitude: lat,
        longitude: lon,
      });

      let UTM_Source,
        UTM_Medium,
        UTM_Campaign = "";

      const urlParams = new URLSearchParams(window.location.search);
      UTM_Source = urlParams.get("utm_source");
      UTM_Medium = urlParams.get("amp;utm_medium");
      UTM_Campaign = urlParams.get("amp;utm_campaign");

      console.log(breed);

      const data = {
        ContactNo: phoneString,
        Services: serviceChoice,
        Breed: breed?.label,
        Date: selectedDate,
        Time: selectedTime,
        Email: "",
        FormType: "Direct Form",
        DateOfRegistration: dateOfReg,
        TimeOfRegistration: timeOfReg,
        Address: address,
        Name: name,
        latitude: lat,
        longitude: lon,
        UTM_Source: UTM_Source || "",
        UTM_Medium: UTM_Medium || "",
        UTM_Campaign: UTM_Campaign || "",
        // paymentStatus:2,
      };

      var amount = 0;

      if (serviceChoice === "Bath and Brush") {
        //kartik
        amount = 799;
      } else if (serviceChoice === "Bath & Brush(with tick treatment)") {
        amount = 1199;
      } else if (serviceChoice === "Haircut and Styling") {
        amount = 1249;
      } else if (serviceChoice === "Bath, Full haircut & Styling") {
        amount = 1649;
      } else if (serviceChoice === "Massage, Bath, Full haircut & Styling") {
        amount = 1949;
      }
      // setPrice(amount);
      price = amount;
      data["Amount"] = amount;
      data["paymentStatus"] = 2;
      axios
        .post(
          "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/saveGroomingData",
          data
        )
        .then((response) => {
          console.log(response);
          console.log("The grooming form is being submitted...");
        })
        .catch((err) => {
          console.log(err);
          console.log(
            "There was some error in submitting the grooming form..."
          );
        });

      // let UTM_Source, UTM_Medium, UTM_Campaign = "";

      // const urlParams = new URLSearchParams(window.location.search);
      // UTM_Source = urlParams.get('utm_source');
      // UTM_Medium = urlParams.get('utm_medium');
      // UTM_Campaign = urlParams.get('utm_campaign')
      const data2 = {
        fullName: name,
        address: address,
        Breed: breed?.label,
        phone: phoneString,
        startDate: selectedDate,
        sessionTime: selectedTime,
        latitude: lat,
        longitude: lon,
        package: {
          description: serviceChoice,
          amount: amount - couponValue,
          frequency: 1,
        },
        type: "User",
        isCouponCodeUsed: couponValue === 0 ? false : true,
        couponCode: couponValue !== 0 ? couponCode : "",
        UTM_Source: UTM_Source || "",
        UTM_Medium: UTM_Medium || "",
        UTM_Campaign: UTM_Campaign || "",
      };

      console.log(data2);

      data2["Amount"] = amount;
      await axios
        .post(
          "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/websiteDogGroomingBookingService",
          data2,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: token,
            },
          }
        )
        .then((response) => {
          console.log(response);
          console.log("The grooming data is submitted to the database.");
        })
        .catch((err) => {
          console.log(err);
          console.log("There was some error in saving the data in database.");
        });

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: `Grooming Registration Fired From Desktop Service Page Form`,
      });

      navigate("/thankPageGroom");
    }

    if (phoneString.length !== 10) {
      setNewPhoneValidation(false);
    }
    if (name === "") {
      setNewNameValidation(false);
    }
    if (address === "") {
      setNewAddressValidation(false);
    }
  };

  useEffect(() => {
    if (flag) navigate("/ordersummary", { state: paymentData });
  }, [paymentData, flag]);

  const applyMOJO = async () => {
    applyCoupon("MOJO5");
    setCouponCode("MOJO5");
  };
  const applyCoupon = async (cd) => {
    await axios
      .post(
        "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/websiteVerifyCoupon",
        {
          name: cd ?? couponCode,
          serviceType: 2,
          package: serviceChoice,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: token,
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response.data.value) {
          setCouponValue(response.data.value);
          setCouponErrorMsg("");
          setCouponNotValid(false);
          setCouponValid(true);
        } else {
          setCouponValid(false);
          setCouponNotValid(true);
          setCouponErrorMsg(response.data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const params = {};
    for (let param of searchParams.entries()) {
      params[param[0]] = param[1];
    }
    console.log(params);
    if (
      params["amp;utm_campaign"] === "Grooming 499" &&
      params["amp;utm_medium"] === "Whatsapp Campaign" &&
      params["utm_source"] === "Whatsapp"
    ) {
      setCouponCode("WLCMBK");
      applyCoupon("WLCMBK");
    }
  }, []);

  const date = new Date();

  // declaration of refs
  const phoneRef = useRef("");
  const emailRef = useRef("");
  const dateRef = useRef("");
  const addressRef = useRef("");
  const timeRef = useRef("");
  const [addressFill, setAddressFill] = useState(false);

  useEffect(() => {
    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${date.getMonth() + 1
      }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;

    var MonthName;
    if (date.getMonth() + 1 == 1) {
      MonthName = "January";
    } else if (date.getMonth() + 1 == 2) {
      MonthName = "February";
    } else if (date.getMonth() + 1 == 3) {
      MonthName = "March";
    } else if (date.getMonth() + 1 == 4) {
      MonthName = "April";
    } else if (date.getMonth() + 1 == 5) {
      MonthName = "May";
    } else if (date.getMonth() + 1 == 6) {
      MonthName = "June";
    } else if (date.getMonth() + 1 == 7) {
      MonthName = "July";
    } else if (date.getMonth() + 1 == 8) {
      MonthName = "August";
    } else if (date.getMonth() + 1 == 9) {
      MonthName = "September";
    } else if (date.getMonth() + 1 == 10) {
      MonthName = "October";
    } else if (date.getMonth() + 1 == 11) {
      MonthName = "November";
    } else if (date.getMonth() + 1 == 12) {
      MonthName = "December";
    }

    var DayName;
    if (date.getDay() + 1 == 1) DayName = "Sunday";
    else if (date.getDay() + 1 == 2) DayName = "Monday";
    else if (date.getDay() + 1 == 3) DayName = "Tuesday";
    else if (date.getDay() + 1 == 4) DayName = "Wednesday";
    else if (date.getDay() + 1 == 5) DayName = "Thursday";
    else if (date.getDay() + 1 == 6) DayName = "Friday";
    else if (date.getDay() + 1 == 7) DayName = "Saturday";

    const repDate =
      MonthName + " " + date.getDate() + ", " + date.getFullYear() + " | ";
    const repDay = DayName;
    setRepresentationDate(repDate);
    setRepresentationDay(repDay);
  }, []);
  useEffect(() => {
    indicateAlert();
  }, [correctOTP]);

  useEffect(() => {
    decrementSeconds();
  }, [seconds]);

  const [loading, setLoading] = useState(false);
  const handleLocationAccess = () => {
    setLoading(true);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setLat(latitude);
          setLon(longitude);

          fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCeoWpdd6YOVu3wEqqA0qpY6P3B5rUumm8`
          )
            .then((response) => response.json())
            .then((data) => {
              console.log(data);
              setAddress(data.results[0].formatted_address);
              setLoading(false);
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
        },
        (error) => {
          console.error(error);
          setLoading(false);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      setLoading(false);
    }
  };

  // const getLocation=()=>{
  //     if (coords) {
  //         const { latitude, longitude } = coords;
  //         const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCeoWpdd6YOVu3wEqqA0qpY6P3B5rUumm8`;

  //         fetch(url)
  //           .then(response => response.json())
  //           .then(data => {
  //             const { results } = data;
  //             if (results && results.length > 0) {
  //               setAddress(results[0].formatted_address);
  //               console.log(address)
  //             }
  //           })
  //           .catch(error => {
  //             console.log('Error:', error);
  //           });
  //       }
  //       else{
  //         console.log("Location Access denied!")
  //       }
  // }
  // useEffect(() => {
  //   if (coords) {
  //     const { latitude, longitude } = coords;
  //     const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCeoWpdd6YOVu3wEqqA0qpY6P3B5rUumm8`;

  //     fetch(url)
  //       .then(response => response.json())
  //       .then(data => {
  //         const { results } = data;
  //         if (results && results.length > 0) {
  //           setAddress(results[0].formatted_address);
  //           console.log(address)
  //         }
  //       })
  //       .catch(error => {
  //         console.log('Error:', error);
  //       });
  //   }
  // }, [coords]);

  return (
    <div
    //   className="home-page-desktop-intro-form-container2"
    //   style={{
    //     width: location == "/" ? "100%" : "",
    //     marginTop: location == "/" ? "0px" : "",
    //     border: location == "/" ? "0px" : "",
    //   }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            display: "flex",
            flex: "0.3",
            flexDirection: "column",
            alignContent: "start",
            alignItems: "start",
            width: "100vh",
          }}
        >
          <div
            style={{
              fontFamily: "DM Sans",
              fontWeight: "2rem",
              fontSize: "2rem",
              lineHeight: "3rem",
              color: "black",
            }}
          >
            <h1>Fastest Pet</h1>
            <h1>Grooming Services</h1>
            <h1>Near You</h1>
          </div>

          <div>
            <p
              style={{
                fontFamily: "DM Sans",
                fontWeight: "0.2rem",
                fontSize: "0.8rem",
                display: "flex",
                flexDirection: "row",
                textDecoration: "underline",
                color: "black",
              }}
            >
              <img
                src={starImg}
                alt="star"
                style={{
                  width: "0.8rem",
                  height: "0.8rem",
                  marginTop: "0.2rem",
                  marginRight: "0.2rem",
                }}
              />
              4.90 (525K bookings)
            </p>
          </div>

          <div
            className="petType"
            style={{
              border: "1px solid black",
              borderRadius: "0.5rem",
              width: "90%",
              height: "11rem",
              marginTop: "1rem",
              color: "black",
            }}
          >
            <p style={{ margin: "1rem 0 0 0.5rem" }}>Which one is yours?</p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "1rem",
                marginTop: "1rem",
              }}
            >
              <div style={{ marginRight: "0.8rem" }}>
                <img
                  src={dogSelect}
                  style={{ width: "4rem", height: "4rem" }}
                />
                <p style={{ fontSize: "0.8rem" }}>Ist it a Dog?</p>
              </div>
              <div>
                <img
                  src={catSelect}
                  style={{ width: "4rem", height: "4rem" }}
                />
                <p style={{ fontSize: "0.8rem" }}>Ist it a Cat?</p>
              </div>
            </div>
          </div>

          <div
            style={{
              border: "1px solid black",
              borderRadius: "0.5rem",
              width: "90%",
              height: "5rem",
              marginTop: "1rem",
              display: "flex",
              flexDirection: "row",
              color: "black",
            }}
          >
            <img
              src={handShake}
              alt="Hand Shake"
              style={{
                width: "4rem",
                height: "4rem",
                margin: "0.5rem 0.5rem 0 0.5rem",
              }}
            />
            <p style={{ marginTop: "1.8rem" }}>Trusted by 1000+ Customers</p>
          </div>

          <div
            style={{
              border: "1px solid black",
              borderRadius: "0.5rem",
              width: "90%",
              height: "5rem",
              marginTop: "1rem",
              display: "flex",
              flexDirection: "row",
              color: "black",
            }}
          >
            <img
              src={handPercent}
              alt="Hand Shake"
              style={{
                width: "4rem",
                height: "4rem",
                margin: "0.5rem 0.5rem 0 0.5rem",
              }}
            />
            <p style={{ marginTop: "1.8rem" }}>Flat 50% Off on Pet Grooming</p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flex: "0.7",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <div style={{ width: "130vh" }}>
            <ImageSlider images={images} style={{ height: "50vh" }} />
          </div>

          <div
            className="packages"
            style={{
              padding: "4rem 3rem ",
              border: "1px solid black",
              borderRadius: "0.5rem",
              color: "black",
            }}
          >
            <div
              style={{
                color: "black",
                flexDirection: "column",
                alignContent: "start",
                alignItems: "start",
                width: "100vh",
                paddingRight: "5rem",
                textAlign: "left",
              }}
            >
              <p
                style={{
                  marginRight: "5rem",
                  paddingRight: "5rem",
                  fontSize: "0.8rem",
                  color: "#0B5459",
                }}
              >
                PACKAGE
              </p>
              <p>
                Bath + Brush{" "}
                <Button
                  variant="outlined"
                  style={{
                    backgroundColor: "white",
                    color: "pink",
                    borderColor: "#0F0F0F14",
                    marginLeft: "8rem",
                  }}
                >
                  {" "}
                  Add{" "}
                </Button>
              </p>
              <p
                style={{
                  fontFamily: "DM Sans",
                  fontWeight: "0.2rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  flexDirection: "row",
                  textDecoration: "underline",
                }}
              >
                <img
                  src={starImg}
                  alt="star"
                  style={{
                    width: "0.8rem",
                    height: "0.8rem",
                    marginTop: "0.2rem",
                    marginRight: "0.2rem",
                  }}
                />
                4.90 (525K bookings)
              </p>
              <p>₹1598 </p>
              <p>★     Bath & Shampoo</p>
              <p>★     Bath & Shampoo</p>
              <p>★     Bath & Shampoo</p>
              <Button
                variant="outlined"
                style={{ backgroundColor: "white", marginTop: "1rem" }}
                onClick={handleOpen1}
              >
                View More
              </Button>
              <Modal
                open={open1}
                onClose={handleClose1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <ImageSlider images={images} />
                  <div style={{ marginLeft: "0.5rem" }}>
                    <p
                      style={{
                        marginTop: "2rem",
                        fontSize: "1.7rem",
                        fontWeight: "bolder",
                      }}
                    >
                      Bath + Brush{" "}
                    </p>
                    <p
                      style={{
                        fontFamily: "DM Sans",
                        fontWeight: "0.2rem",
                        fontSize: "0.8rem",
                        display: "flex",
                        flexDirection: "row",
                        textDecoration: "underline",
                      }}
                    >
                      <img
                        src={starImg}
                        alt="star"
                        style={{
                          width: "0.8rem",
                          height: "0.8rem",
                          marginTop: "0.2rem",
                          marginRight: "0.2rem",
                        }}
                      />
                      4.90 (525K bookings)
                    </p>
                    <hr style={{ width: "20rem", margin: "1rem 0" }} />

                    <p style={{ fontSize: "1.2rem", fontWeight: "bolder" }}>
                      Includes
                    </p>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        className="left"
                        style={{
                          display: "flex",
                          flex: "0.35",
                          flexDirection: "column",
                        }}
                      >
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Includes Bath
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Shampoo
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Blow Dry
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Brushing
                        </p>
                      </div>
                      <div
                        className="right"
                        style={{
                          display: "flex",
                          flex: "0.65",
                          flexDirection: "column",
                        }}
                      >
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Nail Trim
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Ear Cleaning or Flushing
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Scissoring/Trimming feet hair
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Teeth Brushing
                        </p>
                      </div>
                    </div>
                    <p
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: "bolder",
                        margin: "0.8rem 0",
                      }}
                    >
                      Our Safe & Secure Pet Grooming Process
                    </p>
                    <div>
                      <p style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>
                        Pre-Grooming Consultation
                      </p>
                      <p style={{ fontSize: "0.8rem" }}>
                        First, our pet professionals will discuss your
                        requirements according to your pet’s bread, temperament
                        and physical appearance.
                      </p>
                    </div>

                    <div>
                      <p style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>
                        Pre-Grooming Consultation
                      </p>
                      <p style={{ fontSize: "0.8rem" }}>
                        First, our pet professionals will discuss your
                        requirements according to your pet’s bread, temperament
                        and physical appearance.
                      </p>
                    </div>
                    <div>
                      <p style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>
                        Pre-Grooming Consultation
                      </p>
                      <p style={{ fontSize: "0.8rem" }}>
                        First, our pet professionals will discuss your
                        requirements according to your pet’s bread, temperament
                        and physical appearance.
                      </p>
                    </div>
                    <p style={{ fontSize: "1.2rem", fontWeight: "bolder", margin: "0.8rem 0" }}>
                      FAQs
                    </p>

                    <div>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          Why do I need pet grooming services from Pet Mojo?
                        </AccordionSummary>
                        <AccordionDetails>
                          Pet grooming services are necessary to maintain your pet’s healthy shape. Pet Mojo offers a wide range of pet grooming services that come with several benefits, such as utilization of genuine products, pet-friendly environment, breed-focused training and a lot more
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          How often do I need pet grooming services?
                        </AccordionSummary>
                        <AccordionDetails>
                          It totally depends on the type of pet and their breed. Our pet grooming professionals will assist you to schedule pet grooming by analyzing your canine or feline’s health.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          How long does it take to groom my pet?
                        </AccordionSummary>
                        <AccordionDetails>
                          Minimum time that takes for your pet grooming will be 30 minutes. For customized solutions or any other package that requires more attention, then it might take up to 1-2 hours.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          What if I am not satisfied with the service my dog ​​is receiving?
                        </AccordionSummary>
                        <AccordionDetails>
                          Well, it is the rarest case we come to know about. However, when it appears, our pet grooming experts will take complete care of your concerns. You just need to tell us your issues within 2 working days from the pet grooming services. We focus on exceeding expectations by giving impressive pet care solutions.
                        </AccordionDetails>
                      </Accordion>

                    </div>
                  </div>
                </Box>
              </Modal>
            </div>

            <hr style={{ width: "20rem", marginTop: "3rem" }} />

            <div
              style={{
                color: "black",
                flexDirection: "column",
                alignContent: "start",
                alignItems: "start",
                width: "100vh",
                paddingRight: "5rem",
                textAlign: "left",
              }}
            >
              <p
                style={{
                  marginRight: "5rem",
                  paddingRight: "5rem",
                  fontSize: "0.8rem",
                  color: "#0B5459",
                  marginTop: "2rem"
                }}
              >
                PACKAGE
              </p>
              <p>
                Bath + Brush{" "}
                <Button
                  variant="outlined"
                  style={{
                    backgroundColor: "white",
                    color: "pink",
                    borderColor: "#0F0F0F14",
                    marginLeft: "8rem",
                  }}
                >
                  {" "}
                  Add{" "}
                </Button>
              </p>
              <p
                style={{
                  fontFamily: "DM Sans",
                  fontWeight: "0.2rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  flexDirection: "row",
                  textDecoration: "underline",
                }}
              >
                <img
                  src={starImg}
                  alt="star"
                  style={{
                    width: "0.8rem",
                    height: "0.8rem",
                    marginTop: "0.2rem",
                    marginRight: "0.2rem",
                  }}
                />
                4.90 (525K bookings)
              </p>
              <p>₹1598 </p>
              <p>★     Bath & Shampoo</p>
              <p>★     Bath & Shampoo</p>
              <p>★     Bath & Shampoo</p>
              <Button
                variant="outlined"
                style={{ backgroundColor: "white", marginTop: "1rem" }}
                onClick={handleOpen2}
              >
                View More
              </Button>
              <Modal
                open={open2}
                onClose={handleClose2}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <ImageSlider images={images} />
                  <div style={{ marginLeft: "0.5rem" }}>
                    <p
                      style={{
                        marginTop: "2rem",
                        fontSize: "1.7rem",
                        fontWeight: "bolder",
                      }}
                    >
                      Bath + Brush{" "}
                    </p>
                    <p
                      style={{
                        fontFamily: "DM Sans",
                        fontWeight: "0.2rem",
                        fontSize: "0.8rem",
                        display: "flex",
                        flexDirection: "row",
                        textDecoration: "underline",
                      }}
                    >
                      <img
                        src={starImg}
                        alt="star"
                        style={{
                          width: "0.8rem",
                          height: "0.8rem",
                          marginTop: "0.2rem",
                          marginRight: "0.2rem",
                        }}
                      />
                      4.90 (525K bookings)
                    </p>
                    <hr style={{ width: "20rem", margin: "1rem 0" }} />

                    <p style={{ fontSize: "1.2rem", fontWeight: "bolder" }}>
                      Includes
                    </p>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        className="left"
                        style={{
                          display: "flex",
                          flex: "0.35",
                          flexDirection: "column",
                        }}
                      >
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Includes Bath
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Shampoo
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Blow Dry
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Brushing
                        </p>
                      </div>
                      <div
                        className="right"
                        style={{
                          display: "flex",
                          flex: "0.65",
                          flexDirection: "column",
                        }}
                      >
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Nail Trim
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Ear Cleaning or Flushing
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Scissoring/Trimming feet hair
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Teeth Brushing
                        </p>
                      </div>
                    </div>
                    <p
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: "bolder",
                        margin: "0.8rem 0",
                      }}
                    >
                      Our Safe & Secure Pet Grooming Process
                    </p>
                    <div>
                      <p style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>
                        Pre-Grooming Consultation
                      </p>
                      <p style={{ fontSize: "0.8rem" }}>
                        First, our pet professionals will discuss your
                        requirements according to your pet’s bread, temperament
                        and physical appearance.
                      </p>
                    </div>

                    <div>
                      <p style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>
                        Pre-Grooming Consultation
                      </p>
                      <p style={{ fontSize: "0.8rem" }}>
                        First, our pet professionals will discuss your
                        requirements according to your pet’s bread, temperament
                        and physical appearance.
                      </p>
                    </div>
                    <div>
                      <p style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>
                        Pre-Grooming Consultation
                      </p>
                      <p style={{ fontSize: "0.8rem" }}>
                        First, our pet professionals will discuss your
                        requirements according to your pet’s bread, temperament
                        and physical appearance.
                      </p>
                    </div>
                    <p style={{ fontSize: "1.2rem", fontWeight: "bolder", margin: "0.8rem 0" }}>
                      FAQs
                    </p>

                    <div>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          Why do I need pet grooming services from Pet Mojo?
                        </AccordionSummary>
                        <AccordionDetails>
                          Pet grooming services are necessary to maintain your pet’s healthy shape. Pet Mojo offers a wide range of pet grooming services that come with several benefits, such as utilization of genuine products, pet-friendly environment, breed-focused training and a lot more
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          How often do I need pet grooming services?
                        </AccordionSummary>
                        <AccordionDetails>
                          It totally depends on the type of pet and their breed. Our pet grooming professionals will assist you to schedule pet grooming by analyzing your canine or feline’s health.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          How long does it take to groom my pet?
                        </AccordionSummary>
                        <AccordionDetails>
                          Minimum time that takes for your pet grooming will be 30 minutes. For customized solutions or any other package that requires more attention, then it might take up to 1-2 hours.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          What if I am not satisfied with the service my dog ​​is receiving?
                        </AccordionSummary>
                        <AccordionDetails>
                          Well, it is the rarest case we come to know about. However, when it appears, our pet grooming experts will take complete care of your concerns. You just need to tell us your issues within 2 working days from the pet grooming services. We focus on exceeding expectations by giving impressive pet care solutions.
                        </AccordionDetails>
                      </Accordion>

                    </div>
                  </div>
                </Box>
              </Modal>
            </div>

            <hr style={{ width: "20rem", marginTop: "3rem" }} />

            <div
              style={{
                color: "black",
                flexDirection: "column",
                alignContent: "start",
                alignItems: "start",
                width: "100vh",
                paddingRight: "5rem",
                textAlign: "left",
              }}
            >
              <p
                style={{
                  marginRight: "5rem",
                  paddingRight: "5rem",
                  fontSize: "0.8rem",
                  color: "#0B5459",
                  marginTop: "2rem"
                }}
              >
                PACKAGE
              </p>
              <p>
                Bath + Brush{" "}
                <Button
                  variant="outlined"
                  style={{
                    backgroundColor: "white",
                    color: "pink",
                    borderColor: "#0F0F0F14",
                    marginLeft: "8rem",
                  }}
                >
                  {" "}
                  Add{" "}
                </Button>
              </p>
              <p
                style={{
                  fontFamily: "DM Sans",
                  fontWeight: "0.2rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  flexDirection: "row",
                  textDecoration: "underline",
                }}
              >
                <img
                  src={starImg}
                  alt="star"
                  style={{
                    width: "0.8rem",
                    height: "0.8rem",
                    marginTop: "0.2rem",
                    marginRight: "0.2rem",
                  }}
                />
                4.90 (525K bookings)
              </p>
              <p>₹1598 </p>
              <p>★     Bath & Shampoo</p>
              <p>★     Bath & Shampoo</p>
              <p>★     Bath & Shampoo</p>
              <Button
                variant="outlined"
                style={{ backgroundColor: "white", marginTop: "1rem" }}
                onClick={handleOpen3}
              >
                View More
              </Button>
              <Modal
                open={open3}
                onClose={handleClose3}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <ImageSlider images={images} />
                  <div style={{ marginLeft: "0.5rem" }}>
                    <p
                      style={{
                        marginTop: "2rem",
                        fontSize: "1.7rem",
                        fontWeight: "bolder",
                      }}
                    >
                      Bath + Brush{" "}
                    </p>
                    <p
                      style={{
                        fontFamily: "DM Sans",
                        fontWeight: "0.2rem",
                        fontSize: "0.8rem",
                        display: "flex",
                        flexDirection: "row",
                        textDecoration: "underline",
                      }}
                    >
                      <img
                        src={starImg}
                        alt="star"
                        style={{
                          width: "0.8rem",
                          height: "0.8rem",
                          marginTop: "0.2rem",
                          marginRight: "0.2rem",
                        }}
                      />
                      4.90 (525K bookings)
                    </p>
                    <hr style={{ width: "20rem", margin: "1rem 0" }} />

                    <p style={{ fontSize: "1.2rem", fontWeight: "bolder" }}>
                      Includes
                    </p>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        className="left"
                        style={{
                          display: "flex",
                          flex: "0.35",
                          flexDirection: "column",
                        }}
                      >
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Includes Bath
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Shampoo
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Blow Dry
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Brushing
                        </p>
                      </div>
                      <div
                        className="right"
                        style={{
                          display: "flex",
                          flex: "0.65",
                          flexDirection: "column",
                        }}
                      >
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Nail Trim
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Ear Cleaning or Flushing
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Scissoring/Trimming feet hair
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Teeth Brushing
                        </p>
                      </div>
                    </div>
                    <p
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: "bolder",
                        margin: "0.8rem 0",
                      }}
                    >
                      Our Safe & Secure Pet Grooming Process
                    </p>
                    <div>
                      <p style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>
                        Pre-Grooming Consultation
                      </p>
                      <p style={{ fontSize: "0.8rem" }}>
                        First, our pet professionals will discuss your
                        requirements according to your pet’s bread, temperament
                        and physical appearance.
                      </p>
                    </div>

                    <div>
                      <p style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>
                        Pre-Grooming Consultation
                      </p>
                      <p style={{ fontSize: "0.8rem" }}>
                        First, our pet professionals will discuss your
                        requirements according to your pet’s bread, temperament
                        and physical appearance.
                      </p>
                    </div>
                    <div>
                      <p style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>
                        Pre-Grooming Consultation
                      </p>
                      <p style={{ fontSize: "0.8rem" }}>
                        First, our pet professionals will discuss your
                        requirements according to your pet’s bread, temperament
                        and physical appearance.
                      </p>
                    </div>
                    <p style={{ fontSize: "1.2rem", fontWeight: "bolder", margin: "0.8rem 0" }}>
                      FAQs
                    </p>

                    <div>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          Why do I need pet grooming services from Pet Mojo?
                        </AccordionSummary>
                        <AccordionDetails>
                          Pet grooming services are necessary to maintain your pet’s healthy shape. Pet Mojo offers a wide range of pet grooming services that come with several benefits, such as utilization of genuine products, pet-friendly environment, breed-focused training and a lot more
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          How often do I need pet grooming services?
                        </AccordionSummary>
                        <AccordionDetails>
                          It totally depends on the type of pet and their breed. Our pet grooming professionals will assist you to schedule pet grooming by analyzing your canine or feline’s health.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          How long does it take to groom my pet?
                        </AccordionSummary>
                        <AccordionDetails>
                          Minimum time that takes for your pet grooming will be 30 minutes. For customized solutions or any other package that requires more attention, then it might take up to 1-2 hours.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          What if I am not satisfied with the service my dog ​​is receiving?
                        </AccordionSummary>
                        <AccordionDetails>
                          Well, it is the rarest case we come to know about. However, when it appears, our pet grooming experts will take complete care of your concerns. You just need to tell us your issues within 2 working days from the pet grooming services. We focus on exceeding expectations by giving impressive pet care solutions.
                        </AccordionDetails>
                      </Accordion>

                    </div>
                  </div>
                </Box>
              </Modal>
            </div>

            <hr style={{ width: "20rem", marginTop: "3rem" }} />

            <div
              style={{
                color: "black",
                flexDirection: "column",
                alignContent: "start",
                alignItems: "start",
                width: "100vh",
                paddingRight: "5rem",
                textAlign: "left",
              }}
            >
              <p
                style={{
                  marginRight: "5rem",
                  paddingRight: "5rem",
                  fontSize: "0.8rem",
                  color: "#0B5459",
                  marginTop: "2rem"
                }}
              >
                PACKAGE
              </p>
              <p>
                Bath + Brush{" "}
                <Button
                  variant="outlined"
                  style={{
                    backgroundColor: "white",
                    color: "pink",
                    borderColor: "#0F0F0F14",
                    marginLeft: "8rem",
                  }}
                >
                  {" "}
                  Add{" "}
                </Button>
              </p>
              <p
                style={{
                  fontFamily: "DM Sans",
                  fontWeight: "0.2rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  flexDirection: "row",
                  textDecoration: "underline",
                }}
              >
                <img
                  src={starImg}
                  alt="star"
                  style={{
                    width: "0.8rem",
                    height: "0.8rem",
                    marginTop: "0.2rem",
                    marginRight: "0.2rem",
                  }}
                />
                4.90 (525K bookings)
              </p>
              <p>₹1598 </p>
              <p>★     Bath & Shampoo</p>
              <p>★     Bath & Shampoo</p>
              <p>★     Bath & Shampoo</p>
              <Button
                variant="outlined"
                style={{ backgroundColor: "white", marginTop: "1rem" }}
                onClick={handleOpen4}
              >
                View More
              </Button>
              <Modal
                open={open4}
                onClose={handleClose4}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <ImageSlider images={images} />
                  <div style={{ marginLeft: "0.5rem" }}>
                    <p
                      style={{
                        marginTop: "2rem",
                        fontSize: "1.7rem",
                        fontWeight: "bolder",
                      }}
                    >
                      Bath + Brush{" "}
                    </p>
                    <p
                      style={{
                        fontFamily: "DM Sans",
                        fontWeight: "0.2rem",
                        fontSize: "0.8rem",
                        display: "flex",
                        flexDirection: "row",
                        textDecoration: "underline",
                      }}
                    >
                      <img
                        src={starImg}
                        alt="star"
                        style={{
                          width: "0.8rem",
                          height: "0.8rem",
                          marginTop: "0.2rem",
                          marginRight: "0.2rem",
                        }}
                      />
                      4.90 (525K bookings)
                    </p>
                    <hr style={{ width: "20rem", margin: "1rem 0" }} />

                    <p style={{ fontSize: "1.2rem", fontWeight: "bolder" }}>
                      Includes
                    </p>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        className="left"
                        style={{
                          display: "flex",
                          flex: "0.35",
                          flexDirection: "column",
                        }}
                      >
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Includes Bath
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Shampoo
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Blow Dry
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Brushing
                        </p>
                      </div>
                      <div
                        className="right"
                        style={{
                          display: "flex",
                          flex: "0.65",
                          flexDirection: "column",
                        }}
                      >
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Nail Trim
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Ear Cleaning or Flushing
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Scissoring/Trimming feet hair
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Teeth Brushing
                        </p>
                      </div>
                    </div>
                    <p
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: "bolder",
                        margin: "0.8rem 0",
                      }}
                    >
                      Our Safe & Secure Pet Grooming Process
                    </p>
                    <div>
                      <p style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>
                        Pre-Grooming Consultation
                      </p>
                      <p style={{ fontSize: "0.8rem" }}>
                        First, our pet professionals will discuss your
                        requirements according to your pet’s bread, temperament
                        and physical appearance.
                      </p>
                    </div>

                    <div>
                      <p style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>
                        Pre-Grooming Consultation
                      </p>
                      <p style={{ fontSize: "0.8rem" }}>
                        First, our pet professionals will discuss your
                        requirements according to your pet’s bread, temperament
                        and physical appearance.
                      </p>
                    </div>
                    <div>
                      <p style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>
                        Pre-Grooming Consultation
                      </p>
                      <p style={{ fontSize: "0.8rem" }}>
                        First, our pet professionals will discuss your
                        requirements according to your pet’s bread, temperament
                        and physical appearance.
                      </p>
                    </div>
                    <p style={{ fontSize: "1.2rem", fontWeight: "bolder", margin: "0.8rem 0" }}>
                      FAQs
                    </p>

                    <div>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          Why do I need pet grooming services from Pet Mojo?
                        </AccordionSummary>
                        <AccordionDetails>
                          Pet grooming services are necessary to maintain your pet’s healthy shape. Pet Mojo offers a wide range of pet grooming services that come with several benefits, such as utilization of genuine products, pet-friendly environment, breed-focused training and a lot more
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          How often do I need pet grooming services?
                        </AccordionSummary>
                        <AccordionDetails>
                          It totally depends on the type of pet and their breed. Our pet grooming professionals will assist you to schedule pet grooming by analyzing your canine or feline’s health.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          How long does it take to groom my pet?
                        </AccordionSummary>
                        <AccordionDetails>
                          Minimum time that takes for your pet grooming will be 30 minutes. For customized solutions or any other package that requires more attention, then it might take up to 1-2 hours.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          What if I am not satisfied with the service my dog ​​is receiving?
                        </AccordionSummary>
                        <AccordionDetails>
                          Well, it is the rarest case we come to know about. However, when it appears, our pet grooming experts will take complete care of your concerns. You just need to tell us your issues within 2 working days from the pet grooming services. We focus on exceeding expectations by giving impressive pet care solutions.
                        </AccordionDetails>
                      </Accordion>

                    </div>
                  </div>
                </Box>
              </Modal>

            </div>

            <hr style={{ width: "20rem", marginTop: "3rem" }} />
            <div
              style={{
                color: "black",
                flexDirection: "column",
                alignContent: "start",
                alignItems: "start",
                width: "100vh",
                paddingRight: "5rem",
                textAlign: "left",
              }}
            >
              <p
                style={{
                  marginRight: "5rem",
                  paddingRight: "5rem",
                  fontSize: "0.8rem",
                  color: "#0B5459",
                  marginTop: "2rem"
                }}
              >
                PACKAGE
              </p>
              <p>
                Bath + Brush{" "}
                <Button
                  variant="outlined"
                  style={{
                    backgroundColor: "white",
                    color: "pink",
                    borderColor: "#0F0F0F14",
                    marginLeft: "8rem",
                  }}
                >
                  {" "}
                  Add{" "}
                </Button>
              </p>
              <p
                style={{
                  fontFamily: "DM Sans",
                  fontWeight: "0.2rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  flexDirection: "row",
                  textDecoration: "underline",
                }}
              >
                <img
                  src={starImg}
                  alt="star"
                  style={{
                    width: "0.8rem",
                    height: "0.8rem",
                    marginTop: "0.2rem",
                    marginRight: "0.2rem",
                  }}
                />
                4.90 (525K bookings)
              </p>
              <p>₹1598 </p>
              <p>★     Bath & Shampoo</p>
              <p>★     Bath & Shampoo</p>
              <p>★     Bath & Shampoo</p>
              <Button
                variant="outlined"
                style={{ backgroundColor: "white", marginTop: "1rem" }}
                onClick={handleOpen5}
              >
                View More
              </Button>
              <Modal
                open={open5}
                onClose={handleClose5}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <ImageSlider images={images} />
                  <div style={{ marginLeft: "0.5rem" }}>
                    <p
                      style={{
                        marginTop: "2rem",
                        fontSize: "1.7rem",
                        fontWeight: "bolder",
                      }}
                    >
                      Bath + Brush{" "}
                    </p>
                    <p
                      style={{
                        fontFamily: "DM Sans",
                        fontWeight: "0.2rem",
                        fontSize: "0.8rem",
                        display: "flex",
                        flexDirection: "row",
                        textDecoration: "underline",
                      }}
                    >
                      <img
                        src={starImg}
                        alt="star"
                        style={{
                          width: "0.8rem",
                          height: "0.8rem",
                          marginTop: "0.2rem",
                          marginRight: "0.2rem",
                        }}
                      />
                      4.90 (525K bookings)
                    </p>
                    <hr style={{ width: "20rem", margin: "1rem 0" }} />

                    <p style={{ fontSize: "1.2rem", fontWeight: "bolder" }}>
                      Includes
                    </p>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        className="left"
                        style={{
                          display: "flex",
                          flex: "0.35",
                          flexDirection: "column",
                        }}
                      >
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Includes Bath
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Shampoo
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Blow Dry
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Brushing
                        </p>
                      </div>
                      <div
                        className="right"
                        style={{
                          display: "flex",
                          flex: "0.65",
                          flexDirection: "column",
                        }}
                      >
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Nail Trim
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Ear Cleaning or Flushing
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Scissoring/Trimming feet hair
                        </p>
                        <p style={{ display: "flex", flexDirection: "row" }}>
                          <img
                            src={greenTick}
                            style={{
                              width: "0.8rem",
                              height: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          />{" "}
                          Teeth Brushing
                        </p>
                      </div>
                    </div>
                    <p
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: "bolder",
                        margin: "0.8rem 0",
                      }}
                    >
                      Our Safe & Secure Pet Grooming Process
                    </p>
                    <div>
                      <p style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>
                        Pre-Grooming Consultation
                      </p>
                      <p style={{ fontSize: "0.8rem" }}>
                        First, our pet professionals will discuss your
                        requirements according to your pet’s bread, temperament
                        and physical appearance.
                      </p>
                    </div>

                    <div>
                      <p style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>
                        Pre-Grooming Consultation
                      </p>
                      <p style={{ fontSize: "0.8rem" }}>
                        First, our pet professionals will discuss your
                        requirements according to your pet’s bread, temperament
                        and physical appearance.
                      </p>
                    </div>
                    <div>
                      <p style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>
                        Pre-Grooming Consultation
                      </p>
                      <p style={{ fontSize: "0.8rem" }}>
                        First, our pet professionals will discuss your
                        requirements according to your pet’s bread, temperament
                        and physical appearance.
                      </p>
                    </div>
                    <p style={{ fontSize: "1.2rem", fontWeight: "bolder", margin: "0.8rem 0" }}>
                      FAQs
                    </p>

                    <div>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          Why do I need pet grooming services from Pet Mojo?
                        </AccordionSummary>
                        <AccordionDetails>
                          Pet grooming services are necessary to maintain your pet’s healthy shape. Pet Mojo offers a wide range of pet grooming services that come with several benefits, such as utilization of genuine products, pet-friendly environment, breed-focused training and a lot more
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          How often do I need pet grooming services?
                        </AccordionSummary>
                        <AccordionDetails>
                          It totally depends on the type of pet and their breed. Our pet grooming professionals will assist you to schedule pet grooming by analyzing your canine or feline’s health.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          How long does it take to groom my pet?
                        </AccordionSummary>
                        <AccordionDetails>
                          Minimum time that takes for your pet grooming will be 30 minutes. For customized solutions or any other package that requires more attention, then it might take up to 1-2 hours.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          What if I am not satisfied with the service my dog ​​is receiving?
                        </AccordionSummary>
                        <AccordionDetails>
                          Well, it is the rarest case we come to know about. However, when it appears, our pet grooming experts will take complete care of your concerns. You just need to tell us your issues within 2 working days from the pet grooming services. We focus on exceeding expectations by giving impressive pet care solutions.
                        </AccordionDetails>
                      </Accordion>

                    </div>
                  </div>
                </Box>
              </Modal>
            </div>


          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageForm;
