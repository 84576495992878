import { useEffect, useState } from 'react';
import NavigationBarBoarding from '../../Navigation/navigationBar/NavigationBarBoarding';
import BoardingPageForm from '../mobileForms/BoardingPageForm';

const IntroContainerMobileTraining = (props) => {
  const [showad, setShowad] = useState(true);
  const [isAndroid, setIsAndroid] = useState(false);
  const [isiOS, setIsiOS] = useState(false);
  // const removeAdd = () => {
  //   const ele = document.getElementById("grooming-popup-ad");
  //   ele.style.display = "none";
  //   setShowad(false);
  // };
  // setTimeout(() => {
  //   const ele = document.getElementById("grooming-popup-ad");
  //   ele.style.display = "flex";
  // }, 5000);
  let url;

  const userAgent = navigator.userAgent.toLowerCase();
  useEffect(() => {
    setIsAndroid(userAgent.indexOf("android") > -1);
    setIsiOS(
      userAgent.indexOf("iphone") > -1 ||
      userAgent.indexOf("ipad") > -1 ||
      userAgent.indexOf("ipod") > -1
    );
    // console.log(userAgent.indexOf("iphone"))
  }, [userAgent]);

  if (isAndroid) {
    url = "https://play.google.com/store/apps/details?id=in.tamely.user";
  } else if (isiOS) {
    url = "https://apps.apple.com/in/app/petmojo/id1609861328";
  }
  return (

    <div className='boarding-page-intro-mobile-view'>
      {/* {showad && (
        <div
          style={{
            width: "100%",
            backgroundColor: "white",
            height: "60px",
            display: "none",
            gap: "5px",
            alignItems: "center",
            padding: "5px",
            justifyContent: "space-evenly",
          }}
          id="grooming-popup-ad"
        >
          <div onClick={removeAdd}>
            <RxCross2 color="#5E5E5E" />
          </div>
          <div style={{ height: "100%" }}>
            <img
              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-ad-logo.png"
              alt="logo"
              style={{ height: "100%" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontFamily: "DM Sans",
              height: "100%",
            }}
          >
            <div
              style={{
                fontSize: "14px",
                color: "rgba(94, 94, 94, 1)",
                fontWeight: "700",
              }}
            >
              Petmojo
            </div>
            <div
              style={{
                fontSize: "12px",
                color: "rgba(94, 94, 94, 1)",
                fontWeight: "400",
              }}
            >
              Download our app & get <br />{" "}
              <span style={{ color: "rgba(35, 175, 0, 1)" }}>₹1000</span> in
              your Wallet{" "}
            </div>
          </div>
          <a
            href={url}
            target="_blank"
            style={{
              textDecoration: "none",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="button-group" style={{ height: "80%" }}>
              <button
                className=""
                style={{
                  textAlign: "center",
                  borderRadius: "5px",
                  outline: "none",
                  border: "2px solid #DEC269",
                  color: "#DEC269",
                  fontFamily: "DM Sans",
                  backgroundColor: "white",
                  fontWeight: "600",
                  fontSize: "16px",
                }}
              >
                DOWNLOAD
              </button>
            </div>
          </a>
        </div>
      )} */}
      <NavigationBarBoarding btn={"training"} homePage={true} page={"white"} className="home-page-mobile-navigation-container" />
      <div className='boarding-page-intro-mobile-view-container-2 boarding-page-mobile-view-viewport'>
        <div className='home-page-intro-mobile-view-heading-training'>
          <h1 className='intro-heading-mobile-view-header'>
            <div>India's <span className='pink-bold'>Largest & Trusted</span> pet hotel chain</div>
            {/*  */}
          </h1>
        </div>
        <div className='boarding-page-intro-mobile-view-points-training'>
          <div className='home-page-intro-mobile-view-point-boarding'>
            <div><img alt="tick" className="tick-img-mobile" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
            <div>Get live updates twice a day (Morning & Evening)</div>
          </div>
          <div className='home-page-intro-mobile-view-point-boarding'>
            <div><img alt="tick" className="tick-img-mobile" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
            <div>Pickup & Drop Facility Available for Rabbit, Guinea Pigs, Cats & Dogs</div>
          </div>
          <div className='home-page-intro-mobile-view-point-boarding'>
            <div><img alt="tick" className="tick-img-mobile" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
            <div>Personalised Care with Nutritious Meals & Exercise</div>
          </div>
          <div className='home-page-intro-mobile-view-point-boarding'>
            <div><img alt="tick" className="tick-img-mobile" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
            <div>Safety Guaranteed (11 feet fencing)</div>
          </div>
        </div>
        <div className="home-page-mobile-form-container">
          <BoardingPageForm />
        </div>
      </div>
    </div>

  );
};

export default IntroContainerMobileTraining;