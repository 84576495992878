import styles from "../../pages/Landing/LandingPage.module.css";
import {Link} from 'react-router-dom';

// const blog1Content = "Content";
// const blog1Info = {
//   title: "Positive Pet Rewards",
//   date: "July 15th, 2022  |  263 Views",
//   tag: 2,
//   content: "Food rewards are what most people think of when they think of positive reinforcement. Treats are quick and easy to give and are a great way to reward behavior immediately. If you use clicker training, it's easy to make sure your dog gets a treat the moment he performs a certain behavior. Treats are ideal for training when you are trying to get...",
//   imageSource: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Rectangle_1123_jqjuvd.png",
//   imageSource2: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Rectangle_1123_v7ecrf.png",
//   fullContent: "Food rewards are what most people think of when they think of positive reinforcement. Treats are quick and easy to give and are a great way to reward behavior immediately. If you use clicker training, it's easy to make sure your dog gets a treat the moment he performs a certain behavior. Treats are ideal for training when you are trying to get your dog to repeat certain behaviors in quick succession. The disadvantage of using treats to reward the dog is that it is not always convenient to carry the treats. It can also be a problem if you are working with a dog with food allergies, a weight problem or digestive issues."
// };

// const blog2Info = {
//   title: "Pet Separation Anxiety",
//   date: "July 20th, 2022  |  188 Views",
//   tag: 3,
//   content: "Does your dog get nervous when he sees you about to leave the house? Will he go crazy with joy when you come home? Did they ruin your shoes, claw at the door, or bite the corner of the table while you were away? Your dog may have separation anxiety. What is it? Separation anxiety occurs...",
//   imageSource: "https://res.cloudinary.com/tamely-app/image/upload/v1661064622/petmojo-website/Rectangle_1123_htvj1x.png",
//   imageSource2: "https://res.cloudinary.com/tamely-app/image/upload/v1661064635/petmojo-website/Rectangle_1123_qiv3in.png",
//   fullContent: "Does your dog get nervous when he sees you about to leave the house? Will he go crazy with joy when you come home? Did they ruin your shoes, claw at the door, or bite the corner of the table while you were away? Your dog may have separation anxiety. What is it? Separation anxiety occurs when a dog that is hyper-attached to its owner gets super stressed when left alone. It's more than just a little whine when you leave or a bit of mischievousness when you're out and about. It is a serious condition and one of the main reasons why owners get frustrated with their dogs and give them up. But there are lots of things you can do to help."
// }

// const blog3Info = {
//   title: "Guide to Pet Foods",
//   date: "August 8th, 2022  |  761 Views",
//   tag: 4,
//   content: "Most people feed their dogs dry kibble or wet canned food. These processed foods may not be appealing to us, but they contain all the nutrients dogs need to stay healthy. Quality commercial dog food is strictly regulated and has undergone rigorous testing by veterinary specialists. So what exactly is in these dog foods?...",
//   imageSource: "https://res.cloudinary.com/tamely-app/image/upload/v1661064978/petmojo-website/Rectangle_1123_wclcno.png",
//   imageSource2: "https://res.cloudinary.com/tamely-app/image/upload/v1661064988/petmojo-website/Rectangle_1123_wwpxch.png",
//   fullContent: "Most people feed their dogs dry kibble or wet canned food. These processed foods may not be appealing to us, but they contain all the nutrients dogs need to stay healthy. Quality commercial dog food is strictly regulated and has undergone rigorous testing by veterinary specialists. So what exactly is in these dog foods? Dogs, unlike cats, are not strict carnivores. While meat makes up the majority of their diet, domestic dogs can also get their nutrients from grains, fruits and vegetables. These non-meat foods are not just fillers, but can be a valuable source of essential vitamins, minerals and fiber. A good dog food will contain meat, vegetables, grains and fruits. The best dog foods contain high-quality versions of these ingredients that are suitable for your dog's digestive system."
// }

// const blog4Info = {
//   title: "Puppy and Teething",
//   date: "August 14th, 2022  |  578 Views",
//   tag: 5,
//   content: "Puppies get their milk teeth at a much younger age than human babies - as early as two weeks old. As puppies grow, they explore their world with their mouths. When a puppy is about 3 to 4 months old, its teeth begin to fall out to make room for 42 adult teeth. (That's about 10 more teeth than humans have...",
//   imageSource: "https://res.cloudinary.com/tamely-app/image/upload/v1661065260/petmojo-website/Rectangle_1123_rlppf0.png",
//   imageSource2: "https://res.cloudinary.com/tamely-app/image/upload/v1661065271/petmojo-website/Rectangle_1123_hefhvp.png",
//   fullContent: "Puppies get their milk teeth at a much younger age than human babies - as early as two weeks old. As puppies grow, they explore their world with their mouths. When a puppy is about 3 to 4 months old, its teeth begin to fall out to make room for 42 adult teeth. (That's about 10 more teeth than humans have.) This process can be very painful for your puppy – their gums will hurt. When teething occurs, you may notice that your puppy is drooling or see small spots of blood on his toys, although if you notice anything unusual, talk to your vet as both of these symptoms can also be related to a more serious problem. Once your puppy is 6 months old, his baby teeth should fall out. According to AKC Chief Veterinary Officer Dr. Jerry Klein: “You may find puppy teeth on the floor, although the puppy will probably swallow most of the teeth. If you find that some won't fall out, be sure to tell your vet. They may need to be removed by a veterinary professional.'"
// }

// const blog5Info = {
//   title: "Road Tripping With Your Dog- What To Pack?",
//   date: "August 19th, 2022  |  2.1k Views",
//   tag: 1,
//   content: "What’s more fun than going on a vacay? Going on one with your beloved dog of course! I wouldn’t dream about going anywhere for a rip-roarin’ good time without my best buddy. However, the first time planning for a trip can be a harrowing experience. Not...",
//   imageSource: "https://res.cloudinary.com/tamely-app/image/upload/v1661065699/petmojo-website/tripBig_ulbxgu.png",
//   imageSource2: "https://res.cloudinary.com/tamely-app/image/upload/v1661065699/petmojo-website/tripSmall_ml2y2a.png",
//   fullContent: "What’s more fun than going on a vacay? Going on one with your beloved dog of course! I wouldn’t dream about going anywhere for a rip-roarin’ good time without my best buddy. However, the first time planning for a trip can be a harrowing experience. Not only do you have to figure out the logistics of the hotel room, but you’ll also have to pack for two.  There is nothing worse than arriving at an exciting new dog-friendly city and realizing that you forgot your dog’s bed or something equally likely to have your precious pooch glaring at you with those accusing eyes.  To make things easier, we suggest making a packing list. While many people have it all “in their heads” it never hurts to write stuff down."
// }

function ServiceBenefits(props) {
  return (
    <div className={ (props.view === "mobile") ? "dog-container" : "dog-container-desktop"}>

        {
          (props.view === "mobile") ? (null) : (
            <div className=" dog-slide-desktop">
              <div className="landingBlogsContainer">
                <div className="landingBlogs">
                  <div className="landingBlogsHeader">
                    Guide to 
                  </div>
                  <div className="landingBlogsHeader">
                    Pet Parenting
                  </div>
                  <hr className="desktopLine"></hr>
                </div>
                <div className="landingBlogsContent">
                <div className="landingBlogsContent1">A Professionally Trained Dog is </div>
                <div className="landingBlogsContent1">well behaved and Happy.</div>
                <div className="landingBlogsContent1">Read our Dog Training blogs for more.</div>
                </div>
              </div>
            </div>
          )
        }
    <div className={ (props.view === "mobile") ? "dog-box-2" : "dog-box-desktop"}>


      <div className="social-page-mobile-view-card-container">
          <div className="social-page-mobile-view-card-image"style={{width:"7rem"}} >
            <img alt="cardImage" className="social-page-mobile-view-card-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Rectangle_1408_yhwcji.png" />
          </div>
          <div className="social-page-mobile-blog-text">Trending</div>
          <div className="social-page-mobile-view-card-title">Road Trip With Your Dog - What to Pack?</div>
          <div className="social-page-mobile-view-card-read"><Link to="/blog">Read More</Link></div>
      </div>

      <div className="social-page-mobile-view-card-container">
          <div className="social-page-mobile-view-card-image" style={{width:"7rem"}}>
            <img alt="cardImage" className="social-page-mobile-view-card-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Rectangle_1409_fsrll2.png" />
          </div>
          <div className="social-page-mobile-blog-text">Trending</div>
          <div className="social-page-mobile-view-card-title">Positive Pet Awards</div>
          {/* <div className="social-page-mobile-view-card-subtext">Food rewards are what most people think of when they</div> */}
          <div className="social-page-mobile-view-card-read"><Link to="/blog">Read More</Link></div>
      </div>

      <div className="social-page-mobile-view-card-container">
          <div className="social-page-mobile-view-card-image" style={{width:"7rem"}}>
            <img alt="cardImage" className="social-page-mobile-view-card-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Rectangle_1414_tyipje.png" />
          </div>
          <div className="social-page-mobile-blog-text">Trending</div>
          <div className="social-page-mobile-view-card-title">Pet Separation Anxiety</div>
          <div className="social-page-mobile-view-card-subtext"></div>
          <div className="social-page-mobile-view-card-read"><Link to="/blog">Read More</Link></div>
      </div>

      <div className="social-page-mobile-view-card-container">
          <div className="social-page-mobile-view-card-image" style={{width:"7rem"}}>
            <img alt="cardImage" className="social-page-mobile-view-card-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Rectangle_1417_vrqsyn.png" />
          </div>
          <div className="social-page-mobile-blog-text">Trending</div>
          <div className="social-page-mobile-view-card-title">Guide to Pet Foods</div>
          {/* <div className="social-page-mobile-view-card-subtext">Most people feed their dogs dry kibble or wet canned food. These</div> */}
          <div className="social-page-mobile-view-card-read"><Link to="/blog">Read More</Link></div>
      </div>

      <div className="social-page-mobile-view-card-container">
          <div className="social-page-mobile-view-card-image" style={{width:"7rem"}}>
            <img alt="cardImage" className="social-page-mobile-view-card-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Rectangle_1420_ca6tpc.png" />
          </div>
          <div className="social-page-mobile-blog-text">Trending</div>
          <div className="social-page-mobile-view-card-title">Puppy and Teething</div>
          {/* <div className="social-page-mobile-view-card-subtext">Puppies get their milk teeth at a much younger age than human</div> */}
          <div className="social-page-mobile-view-card-read"><Link to="/blog">Read More</Link></div>
      </div>
      
 
      </div>
    </div>
  );
}

export default ServiceBenefits;
