import React, { useState } from 'react'
import NoVan from "../../../images/NoVan.png"
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const GroomingPageUpdatedForm = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const handleFormSubmit = async (event) => {
        try {

            event.preventDefault();
            setIsLoading(true);

            const formData = new FormData(event.target);
            const data = {
                Name: formData.get('fullName'),
                Phone: formData.get('phoneNumber'),
                Timestamp: new Date(),
                UTM_Source: UTM_Source || "NA",
                UTM_Medium: UTM_Medium || "NA",
                UTM_Campaign: UTM_Campaign || "NA",
            };

            const res = await axios.post(`${baseUrl}/service/saveGroomingClientDataToSheet`, {
                data: data,
            });

            setIsLoading(false);

            if (res.status === 201) {
                navigate("/thankpage");
            } else {
                alert("There was an error submitting the form. Please try again.");
            }

        } catch (err) {
            console.log(err);
            setIsLoading(false);
            alert("There was an error submitting the form. Please try again.");
        }
    };

    const baseUrl = "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api";

    const urlParams = new URLSearchParams(window.location.search);
    let UTM_Source = urlParams.get("utm_source");
    let UTM_Medium = urlParams.get("amp;utm_medium");
    let UTM_Campaign = urlParams.get("amp;utm_campaign");


    return (
        <div
            className="grooming-page-mobile-view-form-container"
        >
            <div className="grooming-page-mobile-view-form-section-1">
                <div style={{ display: "flex", alignItems: "start" }}>

                    <div
                        style={{
                            width: "100%",
                            fontSize: "17px",
                            textAlign: "center",
                        }}
                    >
                        Book Your Grooming Session Here!
                    </div>
                </div>
            </div>

            <div className="grooming-page-mobile-view-services-container" style={{ width: "100%" }}>
                <form className='flex text-gray_text flex-col justify-between px-6 py-4 gap-6' onSubmit={handleFormSubmit}>
                    <div>
                        <div className="grooming-new-form-section3-right-ph-text mb-1 font-semibold">
                            Enter Your Name
                        </div>
                        <input
                            className="grooming-new-form-desktop-input-container-type1 py-5 px-3"
                            placeholder="Enter full name"
                            type="text"
                            name="fullName"
                            required
                        ></input>
                    </div>
                    <div>
                        <div className="grooming-new-form-section3-right-ph-text mb-1 font-semibold">
                            Phone Number
                        </div>
                        <input
                            className="grooming-new-form-desktop-input-container-type1 py-5 px-3"
                            placeholder="Enter phone number"
                            type="text"
                            name="phoneNumber"
                            required
                            maxLength={10}
                        ></input>
                    </div>
                    <button
                        type='submit'
                        className="grooming-new-form-section1-right-next-button"
                        style={{ width: "100%", color: "black" }}
                    >
                        {isLoading ? "Submitting..." : "Book Appointment"}
                    </button>
                </form>
            </div>

            <div className='w-full bg-secondary rounded-b-[10px] flex justify-center p-2'>
                <h2 className='flex items-center gap-3 text-brand font-semibold text-lg italic'>
                    <img src={NoVan} style={{ height: "2rem", width: "2rem" }} alt='logo' />We do not offer Van Services
                </h2>
            </div>
        </div>
    )
}

export default GroomingPageUpdatedForm