import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import "./LandingPage.css";
import LazyLoad from "react-lazyload";
import classes from "./LandingPageComponent.css";
import TimelineComponent from "./TimelineComponent";
import { timelineData } from "../mockData/mockData";
import IntroContainer from "./container/introContainer/IntroContainer";
import IntroContainerDesktop from "./container/introContainer/IntroContainerDesktop";
import HeartContainer from "./container/heartContainer/HeartContainer";
import ServiceBenefits from "./serviceBenefits/ServiceBenefits";
import FAQContainer from "./container/faqContainer/FAQContainer";
import Footer from "./footer/Footer";
import Helmet from "helmet";

const testimonialArray = [
  {
    message:
      "Got myself Advanced Training Package for both my dog, German & Golden. I never realised what all they could learn if trained properly 🙏 Thank you so much",
    name: "Rudrang",
    img_src:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/rudrang.png",
    profession: "Tattoo Artist",
  },
  {
    message:
      "Petmojo has been a great partner in my new pet parenting journey. I worry less knowing my dog is getting trained and walks everyday.",
    name: "Ronak T",
    img_src:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/ronak-homepage.png",
    profession: "Shop Owner",
  },
  {
    message:
      "Quality Service from Petmojo Team. Definitely will go for it again 10/10",
    name: "Deepak B",
    img_src:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/deepak.png",
    profession: "Shop Owner",
  },
  {
    message:
      "The grooming process has been extremly easy and pocket-friendly! My pup looked amazing and didn’t seem distressed at all. I book their home service regurly.",
    name: "Vishal",
    img_src:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/sanket.png",
    profession: "Software Engineer",
  },
  {
    message:
      "Third time the charm. After trying 2 training from my local area, I got a very good trainer on this app. Really liked the defined approach.",
    name: "Jay Bhosle",
    img_src:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/jay.png",
    profession: "Proctor",
  },
];

function LandingPageComponent() {
  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState("Daily Dog Exercise/Walking");
  const [changeView, setChangeView] = useState(false);

  const [testmonialData, setTestimonialData] = useState({
    message:
      "Got myself Advanced Training Package for both my dog, German & Golden. I never realised what all they could learn if trained properly 🙏 Thank you so much",
    name: "Rudrang",
    img_src:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/rudrang.png",
    profession: "Tattoo Artist",
  });

  useEffect(() => {
    if (window.innerWidth <= 1000) {
      setChangeView(false);
    } else {
      setChangeView(true);
    }
  }, []);

  const scrollToTop = () => {
    return window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  let count = 0;

  useEffect(() => {
    const targetDiv = document.querySelector("#testimonialContainer");
    console.log(targetDiv);

    const interval = setInterval(() => {
      count++;
      if (count === 5) {
        count = 0;
      }
      setTestimonialData(testimonialArray[count]);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const script = document.createElement("script");

    script.type = "application/ld+json";
    script.innerText = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "Organization",
      name: "Petmojo",
      url: "https://www.petmojo.care",
      logo: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/petmojo-logo-white.png",
      contactPoint: {
        "@type": "ContactPoint",
        telephone: "900 900 44 73",
        contactType: "customer service",
        areaServed: "IN",
        availableLanguage: ["en", "Hindi"],
      },
      sameAs: [
        "https://www.facebook.com/petmojo.care/",
        "https://www.instagram.com/petmojo.care/",
        "https://www.youtube.com/channel/UCPQrCllizVvTOkSk7_1SIHw",
        "https://in.linkedin.com/company/petmojo",
        "https://twitter.com/Petmojo_App",
      ],
    });
    script.id = "homepage";
    document.body.appendChild(script);

    return () => {
      document.getElementById("homepage").remove();
    };
  }, []);

  useEffect(() => {
    document.head.innerHTML += `
    <meta property="og:title" content="Petmojo - Get Professional Dog Training, Walking & Pet Grooming Services At Home" id="homepage-1" />
    <meta property="og:site_name" content="Petmojo" id="homepage-2" />
    <meta property="og:url" content="https://petmojo.care" id="homepage-3" />
    <meta property="og:description" content="Our Pet Care services include Dog Walking, Training and Pet Grooming. A trusted partner for your pet parenting journey." id="homepage-4" />
    <meta property="og:type" content="website" id="homepage-5" />
    <meta property="og:image" content="https://res.cloudinary.com/tamely-app/image/upload/v1662576550/petmojo-website/Updated_Logo_White_4_drxvnq.png" id="homepage-6"
    />
    <title id="homepage-id">Petmojo - Get Professional Dog Training, Walking & Pet Grooming Services At Home.</title>
    <meta id="homepage-desc" name="description" content="Our Pet Care services include Dog Walking, Training and Pet Grooming. A trusted partner for your pet parenting journey." />
    `;
    // document.title="Petmojo - Get Professional Dog Training, Walking & Pet Grooming Services At Home.";
    // document.description = "Our Pet Care services include Dog Walking, Training and Pet Grooming. A trusted partner for your pet parenting journey.";

    return () => {
      document.getElementById("homepage-1").remove();
      document.getElementById("homepage-2").remove();
      document.getElementById("homepage-3").remove();
      document.getElementById("homepage-4").remove();
      document.getElementById("homepage-5").remove();
      document.getElementById("homepage-6").remove();
      document.getElementById("homepage-id").remove();
      document.getElementById("homepage-desc").remove();
      document.getElementById("homepage-id").remove();
    };
  }, []);

  return (
    <div className="landingpage">
      <Helmet>
        <title>
          Petmojo - Get Professional Dog Training, Walking & Pet Grooming
          Services At Home.
        </title>
        <meta
          name="description"
          content="Our Pet Care services include Dog Walking, Training and Pet Grooming. A trusted partner for your pet parenting journey."
        />
      </Helmet>

      {!changeView ? <IntroContainer /> : <IntroContainerDesktop />}

      {!changeView ? (
        <div className="landing-page-mobile-view-heart-container">
          <HeartContainer />
        </div>
      ) : (
        <div className={classes.heartSectionHomePage}>
          <div className={classes.heartSectionTextContainer}>
            <div className={classes.heartSectionTextHeading2}>
              <div className="heart-section-head-1">
                <h3 className={classes.heartSectionTextHeading}>
                  Your Pet, Our Family 💖
                </h3>
                <div className="line-div relative-line">
                  <hr className="live-training-pink-line"></hr>
                </div>
              </div>
            </div>

            <div className={classes.heartSectionText}>
              <h4 className={classes.heartSectionText}>
                <div>
                  <span className="pink">16000+</span> monthly happy pet
                </div>
                <div>parents on our platform!</div>
              </h4>
            </div>
          </div>
          <div className={classes.heartSectionImage}>
            <img
              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/desktop-heart-container.webp"
              alt="heart"
              className="hearticon"
            ></img>
          </div>
        </div>
      )}

      <div
        className={
          !changeView
            ? "sliderServiceContainer pink-container-family"
            : "slider-service-container-desktop"
        }
      >
        {!changeView ? (
          <div>
            <div className="textbox2">Our Dog Training Services</div>
            <hr className="vector2"></hr>
          </div>
        ) : null}

        <div className="slider-container">
          {!changeView ? null : (
            <div className={classes.landingTrainingContainer}>
              <div className={classes.landingGroomingHeading}>
                <div className={classes.leftHead}>
                  <Link to="/dog-training" style={{ textDecoration: "none" }}>
                    <h4 className={classes.leftHead}>
                      Our Dog Training Services
                    </h4>
                  </Link>
                </div>
                <div className="line-div relative-line">
                  <hr className="live-training-pink-line"></hr>
                </div>
              </div>

              <div className="home-page-training-packages-subtext">
                <h4 className="home-page-training-packages-subtext">
                  <div>Check out our Latest Training</div>
                  <div>Packages Including</div>
                  <div className="bold-grey">
                    Canine Misbehaviour Correction.
                  </div>
                </h4>
              </div>

              <div className={classes.gettingStartedBookingBtn1}>
                <div className="bookingbutton">
                  <Link to="/popup" state={{ type: "dog-training" }}>
                    <button className="booksession">Book a FREE Session</button>
                  </Link>
                  <div className="limitedslots">
                    Hurry Up, Limited slots left!
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            className={
              !changeView
                ? "slider home-packages"
                : "slider-desktop-home-page-training home-packages-desktop"
            }
          >
            {!changeView ? (
              <div className={classes.horizontalScroll}>
                <div className="slide home-training-package-1">
                  <div className="home-training-package-1-header">
                    <div className="unfaded-title">PUPPY TRAINING</div>
                    <div className="home-package-subtitle">
                      12 Sessions | 1 Month
                    </div>
                  </div>

                  <div className="mini-package-cards-content-container">
                    <div className="mini-package-cards-content-left">
                      <div className="mini-package-cards-content-left-upper">
                        <div>
                          <i>Potty Training, Instruction,</i>
                        </div>
                        <div>
                          <i>Feedback Intelligence...& more</i>
                        </div>
                      </div>
                      <div className="mini-package-cards-content-left-lower">
                        <Link to="/dog-training" state={{ from: "home" }}>
                          <LazyLoad>
                            <img
                              alt="arrowIcon"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/solid-pink-arrow.png"
                              className="mini-card-arrow-icon"
                            />
                          </LazyLoad>
                        </Link>
                      </div>
                    </div>

                    <div className="mini-package-cards-content-right">
                      <LazyLoad>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/puppy-training-icon.png"
                          alt="package icon"
                          className="packageicon"
                        ></img>
                      </LazyLoad>
                    </div>
                  </div>
                </div>

                <div className="slide home-training-package-1">
                  <div className="home-training-package-2-header">
                    <div className="unfaded-title">BASIC TRAINING</div>
                    <div className="home-package-subtitle">
                      24 Sessions | 2 Months
                    </div>
                  </div>

                  <div className="mini-package-cards-content-container">
                    <div className="mini-package-cards-content-left">
                      <div className="mini-package-cards-content-left-upper">
                        <div>
                          <i>Play Module, Greetings, Without</i>
                        </div>
                        <div>
                          <i>Leash Walking...& more</i>
                        </div>
                      </div>
                      <div className="mini-package-cards-content-left-lower">
                        <Link to="/dog-training" state={{ from: "home" }}>
                          <LazyLoad>
                            <img
                              alt="arrowIcon"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/faded-purple-arrow.png"
                              className="mini-card-arrow-icon"
                            />
                          </LazyLoad>
                        </Link>
                      </div>
                    </div>

                    <div className="mini-package-cards-content-right">
                      <LazyLoad>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/basic-training-icon.png"
                          alt="package icon"
                          className="packageicon"
                        ></img>
                      </LazyLoad>
                    </div>
                  </div>
                </div>

                <div className="slide home-training-package-1">
                  <div className="home-training-package-3-header">
                    <div className="unfaded-title">INTERMEDIATE TRAINING</div>
                    <div className="home-package-subtitle">
                      36 Sessions | 3 Months
                    </div>
                  </div>

                  <div className="mini-package-cards-content-container">
                    <div className="mini-package-cards-content-left">
                      <div className="mini-package-cards-content-left-upper">
                        <div>
                          <i>Lower Acrobat Module - Crawl</i>
                        </div>
                        <div>
                          <i>Rollover, Lie Down, Sleep...& more</i>
                        </div>
                      </div>
                      <div className="mini-package-cards-content-left-lower">
                        <Link to="/dog-training" state={{ from: "home" }}>
                          <LazyLoad>
                            <img
                              alt="arrowIcon"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/solid-green-arrow.png"
                              className="mini-card-arrow-icon"
                            />
                          </LazyLoad>
                        </Link>
                      </div>
                    </div>

                    <div className="mini-package-cards-content-right">
                      <LazyLoad>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/intermediate-training-icon.png"
                          alt="package icon"
                          className="packageicon"
                        ></img>
                      </LazyLoad>
                    </div>
                  </div>
                </div>

                <div className="slide home-training-package-1">
                  <div className="home-training-package-3-header">
                    <div className="unfaded-title">INTERMEDIATE TRAINING</div>
                    <div className="home-package-subtitle">
                      36 Sessions | <i>(For Canine Misbehaviours)</i>
                    </div>
                  </div>

                  <div className="mini-package-cards-content-container">
                    <div className="mini-package-cards-content-left">
                      <div className="mini-package-cards-content-left-upper">
                        <div>
                          <i>Aggressive Behaviour, Excessive</i>
                        </div>
                        <div>
                          <i>Leash Pulling, Excessive...& more</i>
                        </div>
                      </div>
                      <div className="mini-package-cards-content-left-lower">
                        <Link to="/dog-training" state={{ from: "home" }}>
                          <LazyLoad>
                            <img
                              alt="arrowIcon"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/solid-green-arrow.png"
                              className="mini-card-arrow-icon"
                            />
                          </LazyLoad>
                        </Link>
                      </div>
                    </div>

                    <div className="mini-package-cards-content-right">
                      <LazyLoad>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/intermediate-training-icon.png"
                          alt="package icon"
                          className="packageicon"
                        ></img>
                      </LazyLoad>
                    </div>
                  </div>
                </div>

                <div className="slide home-training-package-1">
                  <div className="home-training-package-4-header">
                    <div className="unfaded-title">ADVANCED TRAINING</div>
                    <div className="home-package-subtitle">
                      48 Sessions | 4 Months
                    </div>
                  </div>

                  <div className="mini-package-cards-content-container">
                    <div className="mini-package-cards-content-left">
                      <div className="mini-package-cards-content-left-upper">
                        <div>
                          <i>Guard House Training, Counting</i>
                        </div>
                        <div>
                          <i>Advanced Socialisation...& more</i>
                        </div>
                      </div>
                      <div className="mini-package-cards-content-left-lower">
                        <Link to="/dog-training" state={{ from: "home" }}>
                          <LazyLoad>
                            <img
                              alt="arrowIcon"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-orange-arrow.png"
                              className="mini-card-arrow-icon"
                            />
                          </LazyLoad>
                        </Link>
                      </div>
                    </div>

                    <div className="mini-package-cards-content-right">
                      <LazyLoad>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/advanced-training-icon.png"
                          alt="package icon"
                          className="packageicon"
                        ></img>
                      </LazyLoad>
                    </div>
                  </div>
                </div>

                <div className="slide home-training-package-1">
                  <div className="home-training-package-5-header">
                    <div className="unfaded-title">ADVANCED TRAINING</div>
                    <div className="home-package-subtitle">
                      12 Sessions | <i>(For Canine Misbehaviours)</i>
                    </div>
                  </div>

                  <div className="mini-package-cards-content-container">
                    <div className="mini-package-cards-content-left">
                      <div className="mini-package-cards-content-left-upper">
                        <div>
                          <i>Fear Anxiety, Hyper Activity,</i>
                        </div>
                        <div>
                          <i>Separation Anxiety...& more</i>
                        </div>
                      </div>
                      <div className="mini-package-cards-content-left-lower">
                        <Link to="/dog-training" state={{ from: "home" }}>
                          <LazyLoad>
                            <img
                              alt="arrowIcon"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/orange-pink-arrow.png"
                              className="mini-card-arrow-icon"
                            />
                          </LazyLoad>
                        </Link>
                      </div>
                    </div>

                    <div className="mini-package-cards-content-right">
                      <LazyLoad>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/advanced-training-icon.png"
                          alt="package icon"
                          className="packageicon"
                        ></img>
                      </LazyLoad>
                    </div>
                  </div>
                </div>

                <div className="slide home-training-package-1">
                  <div className="home-training-package-7-header">
                    <div className="unfaded-title">AGGRESSION TRAINING</div>
                    <div className="home-package-subtitle">
                      36/48 Sessions | 3/4 Months
                    </div>
                  </div>

                  <div className="mini-package-cards-content-container">
                    <div className="mini-package-cards-content-left">
                      <div className="mini-package-cards-content-left-upper">
                        <div>
                          <i>With Dedicated Canine Handling Unit,</i>
                        </div>
                        <div>
                          <i>Proper Safety, Training...& more</i>
                        </div>
                      </div>
                      <div className="mini-package-cards-content-left-lower">
                        <Link to="/dog-training" state={{ from: "home" }}>
                          <LazyLoad>
                            <img
                              alt="arrowIcon"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/yellow-training-icon.png"
                              className="mini-card-arrow-icon"
                            />
                          </LazyLoad>
                        </Link>
                      </div>
                    </div>

                    <div className="mini-package-cards-content-right">
                      <LazyLoad>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/aggression-correction-icon.png"
                          alt="package icon"
                          className="packageicon"
                        ></img>
                      </LazyLoad>
                    </div>
                  </div>
                </div>

                <div className="slide home-training-package-1">
                  <div className="home-training-package-6-header">
                    <div className="unfaded-title">PREMIUM TRAINING</div>
                    <div className="home-package-subtitle">
                      72 Sessions | 6 Months
                    </div>
                  </div>

                  <div className="mini-package-cards-content-container">
                    <div className="mini-package-cards-content-left">
                      <div className="mini-package-cards-content-left-upper">
                        <div>
                          <i>Hurdle Jump, Cone cross walk,</i>
                        </div>
                        <div>
                          <i>Between the legs walk...& more</i>
                        </div>
                      </div>
                      <div className="mini-package-cards-content-left-lower">
                        <Link to="/dog-training" state={{ from: "home" }}>
                          <LazyLoad>
                            <img
                              alt="arrowIcon"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/grooming-blue-arrow.png"
                              className="mini-card-arrow-icon"
                            />
                          </LazyLoad>
                        </Link>
                      </div>
                    </div>

                    <div className="mini-package-cards-content-right">
                      <LazyLoad>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/premium-trainin-icon.png"
                          alt="package icon"
                          className="packageicon"
                        ></img>
                      </LazyLoad>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className={classes.horizontalScroll}>
                <div className="slide home-training-desktop-package-1 ">
                  <div className="home-training-package-1-header">
                    <div className="unfaded-desktop-title">PUPPY TRAINING</div>
                    <div className="home-package-desktop-subtitle">
                      12 Sessions | 1 Month
                    </div>
                  </div>

                  <div className="mini-package-cards-content-container">
                    <div className="mini-package-cards-content-left">
                      <div className="mini-package-cards-desktop-content-left-upper">
                        <div>
                          <i>Potty Training, Instruction,</i>
                        </div>
                        <div>
                          <i>Feedback Intelligence...& more</i>
                        </div>
                      </div>
                      <div className="mini-package-cards-content-left-lower">
                        <Link to="/dog-training" state={{ from: "home" }}>
                          <LazyLoad>
                            <img
                              alt="arrowIcon"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/solid-pink-arrow.png"
                              className="mini-card-arrow-icon-desktop"
                            />
                          </LazyLoad>
                        </Link>
                      </div>
                    </div>

                    <div className="mini-package-cards-content-right">
                      <LazyLoad>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/puppy-training-icon.png"
                          alt="package icon"
                          className="packageicon-desktop1-1"
                        ></img>
                      </LazyLoad>
                    </div>
                  </div>
                </div>

                <div className="slide home-training-desktop-package-1 ">
                  <div className="home-training-package-2-header">
                    <div className="unfaded-desktop-title">BASIC TRAINING</div>
                    <div className="home-package-desktop-subtitle">
                      24 Sessions | 2 Months
                    </div>
                  </div>

                  <div className="mini-package-cards-content-container">
                    <div className="mini-package-cards-content-left">
                      <div className="mini-package-cards-desktop-content-left-upper">
                        <div>
                          <i>Play Module, Greetings, Without</i>
                        </div>
                        <div>
                          <i>Leash Walking...& more</i>
                        </div>
                      </div>
                      <div className="mini-package-cards-content-left-lower">
                        <Link to="/dog-training" state={{ from: "home" }}>
                          <LazyLoad>
                            <img
                              alt="arrowIcon"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/faded-purple-arrow.png"
                              className="mini-card-arrow-icon-desktop"
                            />
                          </LazyLoad>
                        </Link>
                      </div>
                    </div>

                    <div className="mini-package-cards-content-right">
                      <LazyLoad>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/basic-training-icon.png"
                          alt="package icon"
                          className="packageicon-desktop1"
                        ></img>
                      </LazyLoad>
                    </div>
                  </div>
                </div>

                <div className="slide home-training-desktop-package-1 ">
                  <div className="home-training-package-3-header">
                    <div className="unfaded-desktop-title">
                      INTERMEDIATE TRAINING
                    </div>
                    <div className="home-package-desktop-subtitle">
                      36 Sessions | 3 Months
                    </div>
                  </div>

                  <div className="mini-package-cards-content-container">
                    <div className="mini-package-cards-content-left">
                      <div className="mini-package-cards-desktop-content-left-upper">
                        <div>
                          <i>Lower Acrobat Module - Crawl,</i>
                        </div>
                        <div>
                          <i>Rollover, Lie Down, Sleep...& more</i>
                        </div>
                      </div>
                      <div className="mini-package-cards-content-left-lower">
                        <Link to="/dog-training" state={{ from: "home" }}>
                          <LazyLoad>
                            <img
                              alt="arrowIcon"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/solid-green-arrow.png"
                              className="mini-card-arrow-icon-desktop"
                            />
                          </LazyLoad>
                        </Link>
                      </div>
                    </div>

                    <div className="mini-package-cards-content-right">
                      <LazyLoad>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/intermediate-training-icon.png"
                          alt="package icon"
                          className="packageicon-desktop1"
                        ></img>
                      </LazyLoad>
                    </div>
                  </div>
                </div>

                <div className="slide home-training-desktop-package-1 ">
                  <div className="home-training-package-3-header">
                    <div className="unfaded-desktop-title">
                      INTERMEDIATE TRAINING
                    </div>
                    <div className="home-package-desktop-subtitle">
                      36 Sessions | 3 Months{" "}
                      <i className="canine-misbehave-small">
                        (For Canine Misbehaviours)
                      </i>
                    </div>
                  </div>

                  <div className="mini-package-cards-content-container">
                    <div className="mini-package-cards-content-left">
                      <div className="mini-package-cards-desktop-content-left-upper">
                        <div>
                          <i>Aggressive Behaviour, Excessive</i>
                        </div>
                        <div>
                          <i>Leash Pulling, Excessive & more</i>
                        </div>
                      </div>
                      <div className="mini-package-cards-content-left-lower">
                        <Link to="/dog-training" state={{ from: "home" }}>
                          <LazyLoad>
                            <img
                              alt="arrowIcon"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/solid-green-arrow.png"
                              className="mini-card-arrow-icon-desktop"
                            />
                          </LazyLoad>
                        </Link>
                      </div>
                    </div>

                    <div className="mini-package-cards-content-right">
                      <LazyLoad>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/intermediate-training-icon.png"
                          alt="package icon"
                          className="packageicon-desktop1"
                        ></img>
                      </LazyLoad>
                    </div>
                  </div>
                </div>

                <div className="slide home-training-desktop-package-1 ">
                  <div className="home-training-desktop-package-5-header">
                    <div className="unfaded-desktop-title">
                      ADVANCED TRAINING
                    </div>
                    <div className="home-package-desktop-subtitle">
                      12 Sessions | 48 Sessions
                    </div>
                  </div>

                  <div className="mini-package-cards-content-container">
                    <div className="mini-package-cards-content-left">
                      <div className="mini-package-cards-desktop-content-left-upper">
                        <div>
                          <i>Guard House Training, Counting</i>
                        </div>
                        <div>
                          <i>Advanced Socialisation...& more</i>
                        </div>
                      </div>
                      <div className="mini-package-cards-content-left-lower">
                        <Link to="/dog-training" state={{ from: "home" }}>
                          <LazyLoad>
                            <img
                              alt="arrowIcon"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-orange-arrow.png"
                              className="mini-card-arrow-icon-desktop"
                            />
                          </LazyLoad>
                        </Link>
                      </div>
                    </div>

                    <div className="mini-package-cards-content-right">
                      <LazyLoad>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/advanced-training-icon.png"
                          alt="package icon"
                          className="packageicon-desktop1"
                        ></img>
                      </LazyLoad>
                    </div>
                  </div>
                </div>

                <div className="slide home-training-desktop-package-1 ">
                  <div className="home-training-desktop-package-5-header">
                    <div className="unfaded-desktop-title">
                      ADVANCED TRAINING
                    </div>
                    <div className="home-package-desktop-subtitle">
                      12 Sessions | 48 Sessions{" "}
                      <i className="canine-misbehave-small">
                        (For Canine Misbehaviours)
                      </i>
                    </div>
                  </div>

                  <div className="mini-package-cards-content-container">
                    <div className="mini-package-cards-content-left">
                      <div className="mini-package-cards-desktop-content-left-upper">
                        <div>
                          <i>Fear Anxiety, Hyper Activity,</i>
                        </div>
                        <div>
                          <i>Seperation Anxiety...& more</i>
                        </div>
                      </div>
                      <div className="mini-package-cards-content-left-lower">
                        <Link to="/dog-training" state={{ from: "home" }}>
                          <LazyLoad>
                            <img
                              alt="arrowIcon"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/orange-pink-arrow.png"
                              className="mini-card-arrow-icon-desktop"
                            />
                          </LazyLoad>
                        </Link>
                      </div>
                    </div>

                    <div className="mini-package-cards-content-right">
                      <LazyLoad>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/advanced-training-icon.png"
                          alt="package icon"
                          className="packageicon-desktop1"
                        ></img>
                      </LazyLoad>
                    </div>
                  </div>
                </div>

                <div className="slide home-training-desktop-package-1 ">
                  <div className="home-training-desktop-package-7-header">
                    <div className="unfaded-desktop-title">
                      AGGRESSION CORRECTION
                    </div>
                    <div className="home-package-desktop-subtitle">
                      36/48 Sessions | 3/4 Months
                    </div>
                  </div>

                  <div className="mini-package-cards-content-container">
                    <div className="mini-package-cards-content-left">
                      <div className="mini-package-cards-desktop-content-left-upper">
                        <div>
                          <i>With Dedicated Canine Handling Unit</i>
                        </div>
                        <div>
                          <i>Proper Safety, Training...& more</i>
                        </div>
                      </div>
                      <div className="mini-package-cards-content-left-lower">
                        <Link to="/dog-training" state={{ from: "home" }}>
                          <LazyLoad>
                            <img
                              alt="arrowIcon"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/yellow-training-icon.png"
                              className="mini-card-arrow-icon-desktop"
                            />
                          </LazyLoad>
                        </Link>
                      </div>
                    </div>

                    <div className="mini-package-cards-content-right">
                      <LazyLoad>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/aggression-correction-icon.png"
                          alt="package icon"
                          className="packageicon-desktop1-agression"
                        ></img>
                      </LazyLoad>
                    </div>
                  </div>
                </div>

                <div className="slide home-training-desktop-package-1 ">
                  <div className="home-training-desktop-package-6-header">
                    <div className="unfaded-desktop-title">
                      PREMIUM TRAINING
                    </div>
                    <div className="home-package-desktop-subtitle">
                      72 Sessions | 6 Months
                    </div>
                  </div>

                  <div className="mini-package-cards-content-container">
                    <div className="mini-package-cards-content-left">
                      <div className="mini-package-cards-desktop-content-left-upper">
                        <div>
                          <i>Hurdle Jump, Cone cross walk,</i>
                        </div>
                        <div>
                          <i>Between the legs walk...& more</i>
                        </div>
                      </div>
                      <div className="mini-package-cards-content-left-lower">
                        <Link to="/dog-training" state={{ from: "home" }}>
                          <LazyLoad>
                            <img
                              alt="arrowIcon"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/grooming-blue-arrow.png"
                              className="mini-card-arrow-icon-desktop"
                            />
                          </LazyLoad>
                        </Link>
                      </div>
                    </div>

                    <div className="mini-package-cards-content-right">
                      <LazyLoad>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/premium-trainin-icon.png"
                          alt="package icon"
                          className="packageicon-desktop1"
                        ></img>
                      </LazyLoad>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {!changeView ? (
        <div className="bookingbutton">
          <Link to="/popup" state={{ type: "dog-training" }}>
            <button className="booksession-mobile-view">
              Book a FREE Session
            </button>
          </Link>

          <div className="limitedslots">Hurry Up, Limited slots left!</div>
        </div>
      ) : null}

      {!changeView ? (
        <div className="training-page-mobile-view-why-container">
          <div className="services-container2">
            <div className="service-box">
              <div
                className="textbox2"
                style={{ color: "#5D5D5D", fontWeight: 700 }}
              >
                Why Choose Petmojo?
              </div>
              <hr className="vector1"></hr>
              <div className="service-1">
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/trust-icon.png"
                    alt="trust icon"
                    className="trust-icon"
                  ></img>
                </LazyLoad>
                <div
                  className="service-info"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  Trusted & Caring Trainers
                </div>
              </div>
              <div className="service-2">
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/door-icon.png"
                    alt="door icon"
                    className="door-icon"
                  ></img>
                </LazyLoad>
                <div
                  className="service-info"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  Service at Your Door-Step{" "}
                </div>
              </div>
              <div className="service-3">
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/refund-icon.png"
                    alt="refund icon"
                    className="refund-icon"
                  ></img>
                </LazyLoad>
                <div
                  className="service-info"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  Guaranteed Satisfaction{" "}
                </div>
              </div>
              <div className="service-4">
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/team-icon.png"
                    alt="team icon"
                    className="team-icon"
                  ></img>
                </LazyLoad>
                <div
                  className="service-info"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  Local Onground Support
                </div>
              </div>

              {/* <div className="grey-background">
              <div className="bookingbutton">
                <Link to="/popup" state={{ type: "dog-training" }}>
                  <button className="booksession-mobile-view2">Book a FREE Session</button>
                </Link>
              </div>
            </div> */}
            </div>
          </div>
        </div>
      ) : (
        <div className="home-page-why-choose-container">
          <div className="home-page-why-choose-container4">
            <div className="home-page-why-choose-container1-heading-seo">
              <div>
                <h3 className="non-bold">Why Choose Petmojo?</h3>
              </div>
              <div className="line-div relative-line">
                <hr className="live-training-pink-line"></hr>
              </div>
            </div>

            <div className="home-page-why-choose-container1-content">
              <div>
                <h4>We are worlds most empathetic Pet Care Services.</h4>
              </div>
            </div>
          </div>

          <div className="home-page-why-choose-container2">
            <div className="service-1">
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/trust-icon.png"
                  alt="trust icon"
                  className="trust-icon"
                ></img>
              </LazyLoad>
              <div
                className="service-info"
                style={{ color: "white", fontWeight: 700 }}
              >
                Trusted & Caring Trainers
              </div>
            </div>
            <div className="service-2">
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/door-icon.png"
                  alt="door icon"
                  className="door-icon"
                ></img>
              </LazyLoad>
              <div
                className="service-info"
                style={{ color: "white", fontWeight: 700 }}
              >
                Service at Your Door-Step{" "}
              </div>
            </div>
            <div className="service-3">
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/refund-icon.png"
                  alt="refund icon"
                  className="refund-icon"
                ></img>
              </LazyLoad>
              <div
                className="service-info"
                style={{ color: "white", fontWeight: 700 }}
              >
                Guaranteed Satisfaction{" "}
              </div>
            </div>
            <div className="service-4">
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/team-icon.png"
                  alt="team icon"
                  className="team-icon"
                ></img>
              </LazyLoad>
              <div
                className="service-info"
                style={{ color: "white", fontWeight: 700 }}
              >
                Local Onground Support
              </div>
            </div>
          </div>

          <div className="home-page-why-choose-container3">
            <img
              className="home-page-why-choose-container3-img"
              alt="heroImg"
              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/desktop-bg-dog.webp"
            />
          </div>
        </div>
      )}

      <div
        className={
          !changeView
            ? "sliderServiceContainer pink-container-family"
            : "sliderServiceContainer-desktop"
        }
      >
        {!changeView ? (
          <div>
            <div className="textbox2">
              Our Daily Exercises & Walking Packages
            </div>
            <hr className="vector2"></hr>
          </div>
        ) : null}

        <div className="slider-container">
          {!changeView ? null : (
            <div className={classes.landingGroomingContainer}>
              <div className={classes.landingWalkingHeading}>
                <div className={classes.leftHead}>
                  <Link to="/dog-running" style={{ textDecoration: "none" }}>
                    <h4 className={classes.leftHead} style={{marginBottom:"2rem"}}>
                      Our Daily Exercise & Walking Packages
                    </h4>{" "}
                  </Link>
                </div>
                <div className="line-div relative-line">
                  <hr className="live-training-pink-line"></hr>
                </div>
              </div>

              <div className="home-page-walking-packages-desktop-container2">
                <h4>
                  <div>Check your pet's LIVE location on</div>
                  <div>our mobile application when</div>
                  <div>session is ongoing.</div>
                </h4>
              </div>

              <div className={classes.gettingStartedBookingBtn1}>
                <div className="bookingbutton">
                  <Link to="/dog-running" state={{ type: "dog-running" }}>
                    <button
                      className="booksession"
                      
                    >
                      Book 1st FREE Walk
                    </button>
                  </Link>
                  <div className="limitedslots">
                    Hurry Up, Limited slots left!
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            className={
              !changeView
                ? "slider home-packages"
                : "slider home-packages-desktop"
            }
          >
            {!changeView ? (
              <div className={classes.horizontalScroll}>
                <div className="slide home-training-package-1">
                  <div className="home-walking-package-1-header">
                    <div className="unfaded-title">ONCE A DAY, ONE PET</div>
                    <div className="home-package-subtitle">
                      Monthly | 26 Sessions
                    </div>
                  </div>

                  <div className="mini-package-cards-content-container">
                    <div className="mini-package-cards-content-left">
                      <div className="mini-package-cards-content-left-upper">
                        <div>
                          <i>30 mins walk each, GPS Live</i>
                        </div>
                        <div>
                          <i> Tracking, Photo Updates...& more</i>
                        </div>
                      </div>
                      <div className="mini-package-cards-content-left-lower">
                        <Link to="/dog-running" state={{ from: "home" }}>
                          <LazyLoad>
                            <img
                              alt="arrowIcon"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/walking-orange-arrow.png"
                              className="mini-card-arrow-icon"
                            />
                          </LazyLoad>
                        </Link>
                      </div>
                    </div>

                    <div className="mini-package-cards-content-right">
                      <LazyLoad>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/one-pet-once.png"
                          alt="package icon"
                          className="packageicon"
                        ></img>
                      </LazyLoad>
                    </div>
                  </div>
                </div>

                <div className="slide home-training-package-1">
                  <div className="home-walking-package-2-header">
                    <div className="unfaded-title">TWICE A DAY, ONE PET</div>
                    <div className="home-package-subtitle">
                      Monthly | 52 Sessions
                    </div>
                  </div>

                  <div className="mini-package-cards-content-container">
                    <div className="mini-package-cards-content-left">
                      <div className="mini-package-cards-content-left-upper">
                        <div>
                          <i>Recommended 60 mins each </i>
                        </div>
                        <div>
                          <i>day Overexcitement...& more</i>
                        </div>
                      </div>
                      <div className="mini-package-cards-content-left-lower">
                        <Link to="/dog-running" state={{ from: "home" }}>
                          <LazyLoad>
                            <img
                              alt="arrowIcon"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/walking-pink-arrow.png"
                              className="mini-card-arrow-icon"
                            />
                          </LazyLoad>
                        </Link>
                      </div>
                    </div>

                    <div className="mini-package-cards-content-right">
                      <LazyLoad>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/one-pet-twice.png"
                          alt="package icon"
                          className="packageicon"
                        ></img>
                      </LazyLoad>
                    </div>
                  </div>
                </div>

                <div className="slide home-training-package-1">
                  <div className="home-walking-package-1-header">
                    <div className="unfaded-title">ONCE A DAY, TWO PETS</div>
                    <div className="home-package-subtitle">
                      Monthly | 26 Sessions
                    </div>
                  </div>

                  <div className="mini-package-cards-content-container">
                    <div className="mini-package-cards-content-left">
                      <div className="mini-package-cards-content-left-upper">
                        <div>
                          <i>30 mins walk each, GPS Live</i>
                        </div>
                        <div>
                          <i> Tracking, Photo Updates...& more</i>
                        </div>
                      </div>
                      <div className="mini-package-cards-content-left-lower">
                        <Link to="/dog-running" state={{ from: "home" }}>
                          <LazyLoad>
                            <img
                              alt="arrowIcon"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/walking-orange-arrow.png"
                              className="mini-card-arrow-icon"
                            />
                          </LazyLoad>
                        </Link>
                      </div>
                    </div>

                    <div className="mini-package-cards-content-right">
                      <LazyLoad>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/two-pets-once.png"
                          alt="package icon"
                          className="packageicon"
                        ></img>
                      </LazyLoad>
                    </div>
                  </div>
                </div>

                <div className="slide home-training-package-1">
                  <div className="home-walking-package-2-header">
                    <div className="unfaded-title">TWICE A DAY, TWO PETS</div>
                    <div className="home-package-subtitle">
                      Monthly | 52 Sessions
                    </div>
                  </div>

                  <div className="mini-package-cards-content-container">
                    <div className="mini-package-cards-content-left">
                      <div className="mini-package-cards-content-left-upper">
                        <div>
                          <i>Recommended 60 mins each </i>
                        </div>
                        <div>
                          <i>day Overexcitement...& more</i>
                        </div>
                      </div>
                      <div className="mini-package-cards-content-left-lower">
                        <Link to="/dog-running" state={{ from: "home" }}>
                          <LazyLoad>
                            <img
                              alt="arrowIcon"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/walking-pink-arrow.png"
                              className="mini-card-arrow-icon"
                            />
                          </LazyLoad>
                        </Link>
                      </div>
                    </div>

                    <div className="mini-package-cards-content-right">
                      <LazyLoad>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/two-pets-twice.png"
                          alt="package icon"
                          className="packageicon"
                        ></img>
                      </LazyLoad>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className={classes.horizontalScroll}>
                <div className="slide home-training-desktop-package-1 scroll-div2">
                  <div className="home-walking-package-1-header">
                    <div className="unfaded-desktop-title">
                      ONCE A DAY, ONE PET
                    </div>
                    <div className="home-package-desktop-subtitle">
                      Monthly | 26 Sessions
                    </div>
                  </div>

                  <div className="mini-package-cards-content-container">
                    <div className="mini-package-cards-content-left">
                      <div className="mini-package-cards-content-left-upper">
                        <div>
                          <i>30 mins walk each, GPS Live</i>
                        </div>
                        <div>
                          <i> Tracking, Photo Updates...& more</i>
                        </div>
                      </div>
                      <div className="mini-package-cards-content-left-lower">
                        <Link to="/dog-running" state={{ from: "home" }}>
                          <LazyLoad>
                            <img
                              alt="arrowIcon"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/walking-orange-arrow.png"
                              className="mini-card-arrow-icon-desktop"
                            />
                          </LazyLoad>
                        </Link>
                      </div>
                    </div>

                    <div className="mini-package-cards-content-right">
                      <LazyLoad>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/one-pet-once.png"
                          alt="package icon"
                          className="packageicon-desktop1"
                        ></img>
                      </LazyLoad>
                    </div>
                  </div>
                </div>

                <div className="slide home-training-desktop-package-1 scroll-div2">
                  <div className="home-walking-package-2-header">
                    <div className="unfaded-desktop-title">
                      TWICE A DAY, ONE PET
                    </div>
                    <div className="home-package-desktop-subtitle">
                      Monthly | 52 Sessions
                    </div>
                  </div>

                  <div className="mini-package-cards-content-container">
                    <div className="mini-package-cards-content-left">
                      <div className="mini-package-cards-desktop-content-left-upper">
                        <div>
                          <i>Recommended 60 mins each </i>
                        </div>
                        <div>
                          <i>day Overexcitement...& more</i>
                        </div>
                      </div>
                      <div className="mini-package-cards-content-left-lower">
                        <Link to="/dog-running" state={{ from: "home" }}>
                          <LazyLoad>
                            <img
                              alt="arrowIcon"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/walking-pink-arrow.png"
                              className="mini-card-arrow-icon-desktop"
                            />
                          </LazyLoad>
                        </Link>
                      </div>
                    </div>

                    <div className="mini-package-cards-content-right">
                      <LazyLoad>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/one-pet-twice.png"
                          alt="package icon"
                          className="packageicon-desktop1"
                        ></img>
                      </LazyLoad>
                    </div>
                  </div>
                </div>

                <div className="slide home-training-desktop-package-1 scroll-div2">
                  <div className="home-walking-package-1-header">
                    <div className="unfaded-desktop-title">
                      ONCE A DAY, TWO PETS
                    </div>
                    <div className="home-package-desktop-subtitle">
                      Monthly | 26 Sessions
                    </div>
                  </div>

                  <div className="mini-package-cards-content-container">
                    <div className="mini-package-cards-content-left">
                      <div className="mini-package-cards-desktop-content-left-upper">
                        <div>
                          <i>30 mins walk each, GPS Live</i>
                        </div>
                        <div>
                          <i> Tracking, Photo Updates...& more</i>
                        </div>
                      </div>
                      <div className="mini-package-cards-content-left-lower">
                        <Link to="/dog-running" state={{ from: "home" }}>
                          <LazyLoad>
                            <img
                              alt="arrowIcon"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/walking-orange-arrow.png"
                              className="mini-card-arrow-icon-desktop"
                            />
                          </LazyLoad>
                        </Link>
                      </div>
                    </div>

                    <div className="mini-package-cards-content-right">
                      <LazyLoad>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/two-pets-once.png"
                          alt="package icon"
                          className="packageicon-desktop1"
                        ></img>
                      </LazyLoad>
                    </div>
                  </div>
                </div>

                <div className="slide home-training-desktop-package-1 scroll-div2">
                  <div className="home-walking-package-2-header">
                    <div className="unfaded-desktop-title">
                      TWICE A DAY, TWO PETS
                    </div>
                    <div className="home-package-desktop-subtitle">
                      Monthly | 52 Sessions
                    </div>
                  </div>

                  <div className="mini-package-cards-content-container">
                    <div className="mini-package-cards-content-left">
                      <div className="mini-package-cards-desktop-content-left-upper">
                        <div>
                          <i>Recommended 60 mins each </i>
                        </div>
                        <div>
                          <i>day Overexcitement...& more</i>
                        </div>
                      </div>
                      <div className="mini-package-cards-content-left-lower">
                        <Link to="/dog-running" state={{ from: "home" }}>
                          <LazyLoad>
                            <img
                              alt="arrowIcon"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/walking-pink-arrow.png"
                              className="mini-card-arrow-icon-desktop"
                            />
                          </LazyLoad>
                        </Link>
                      </div>
                    </div>

                    <div className="mini-package-cards-content-right">
                      <LazyLoad>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/two-pets-twice.png"
                          alt="package icon"
                          className="packageicon-desktop1"
                        ></img>
                      </LazyLoad>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {!changeView ? (
        <div className="bookingbutton">
          <Link to="/dog-running" state={{ type: "dog-running" }}>
            <button className="booksession-mobile-view" >
              Book 1st FREE Walk
            </button>
          </Link>
          <div className="limitedslots">Hurry Up, Limited slots left!</div>
        </div>
      ) : null}

      {!changeView ? (
        <div className="container-home pink-container-family">
          <div className="textbox2">How to Get Started?</div>
          <hr className="vector1"></hr>
          <div>
            <TimelineComponent version={"mobile"} timelineData={timelineData} />
          </div>
        </div>
      ) : (
        <div>
          <div className="container-home-desktop">
            <div className={classes.getStartedHeading}>
              <div className={classes.howToStart}>
                <div className="textbox2-desktop">
                  <h5 className="fifty-font-size"  style={{marginBottom:"2rem"}}>How to Start?</h5>
                </div>
                <div className="line-div relative-line">
                  <hr className="live-training-pink-line"></hr>
                </div>
              </div>

              <div className="container-home-desktop-subtext">
                <div>
                  <h4 className="twenty-font-size bold-none">
                    It's as easy as 1-2-3, Follow the steps given and you are
                    good to proceed.
                  </h4>
                </div>
              </div>
            </div>
            <div>
              <TimelineComponent
                version={"desktop"}
                timelineData={timelineData}
              />
            </div>
          </div>
        </div>
      )}

      <div
        className={
          !changeView
            ? "sliderServiceContainer pink-container-family"
            : "sliderServiceContainer-desktop"
        }
      >
        {!changeView ? (
          <div>
            <div className="textbox2">Our Grooming Sessions</div>
            <hr className="vector2"></hr>
          </div>
        ) : null}

        <div className="slider-container">
          {!changeView ? null : (
            <div className={classes.landingGroomingContainer}>
              <div className={classes.landingGroomingHeading}>
                <div>
                  <div className={classes.leftHead}>
                    <Link to={"/grooming"} style={{ textDecoration: "none" }}>
                      <h2 className={classes.leftHead}>
                        Our Grooming Sessions{" "}
                      </h2>
                    </Link>
                  </div>
                  <div className="line-div relative-line">
                    <hr className="live-training-pink-line"></hr>
                  </div>
                </div>
              </div>

              <div className="home-page-walking-packages-desktop-container2">
                <h4 className="bold-none">
                  <div>Our grooming products are </div>
                  <div>carefully selected to care for your</div>
                  <div>pet's skin coat and are non-allergic.</div>
                </h4>
              </div>

              <div className={classes.gettingStartedBookingBtn1}>
                <div className="bookingbutton">
                  <Link to="/popup" state={{ type: "grooming" }}>
                    <button className="booksession">Book Now</button>
                  </Link>
                  <div className="limitedslots">
                    Hurry Up, Limited slots left!
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            className={
              !changeView
                ? "slider home-packages"
                : "slider home-packages-desktop"
            }
          >
            {!changeView ? (
              <div className={classes.horizontalScroll}>
                <div className="slide home-training-package-1">
                  <div className="home-grooming-package-1-header">
                    <div className="unfaded-title">BATH & BRUSH</div>
                    <div className="home-package-subtitle">
                      Best for Puppies
                    </div>
                  </div>

                  <div className="mini-package-cards-content-container">
                    <div className="mini-package-cards-content-left">
                      <div className="mini-package-cards-content-left-upper">
                        <div>
                          <i>Includes Bath, Shampoo, Blow Dry</i>
                        </div>
                        <div>
                          <i>Brushing, Sanitary Trim...& more</i>
                        </div>
                      </div>
                      <div className="mini-package-cards-content-left-lower">
                        <Link to="/grooming" state={{ from: "home" }}>
                          <LazyLoad>
                            <img
                              alt="arrowIcon"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/grooming-blue-arrow.png"
                              className="mini-card-arrow-icon"
                            />
                          </LazyLoad>
                        </Link>
                      </div>
                    </div>

                    <div className="mini-package-cards-content-right">
                      <LazyLoad>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/grooming-purple-icon.png"
                          alt="package icon"
                          className="packageicon-grooming"
                        ></img>
                      </LazyLoad>
                    </div>
                  </div>
                </div>

                <div className="slide home-training-package-1">
                  <div className="home-grooming-package-2-header">
                    <div className="unfaded-title">BATH & BRUSH</div>
                    <div className="home-package-subtitle">
                      <i>(With Tick Treatement)</i>
                    </div>
                  </div>

                  <div className="mini-package-cards-content-container">
                    <div className="mini-package-cards-content-left">
                      <div className="mini-package-cards-content-left-upper">
                        <div>
                          <i>Includes Bath, Shampoo, Blow Dry</i>
                        </div>
                        <div>
                          <i>Brushing, Sanitary Trim...& more</i>
                        </div>
                      </div>
                      <div className="mini-package-cards-content-left-lower">
                        <Link to="/grooming" state={{ from: "home" }}>
                          <LazyLoad>
                            <img
                              alt="arrowIcon"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/grooming-pink-arrow.png"
                              className="mini-card-arrow-icon"
                            />
                          </LazyLoad>
                        </Link>
                      </div>
                    </div>

                    <div className="mini-package-cards-content-right">
                      <LazyLoad>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/grooming-pink-icon.png"
                          alt="package icon"
                          className="packageicon-grooming"
                        ></img>
                      </LazyLoad>
                    </div>
                  </div>
                </div>

                <div className="slide home-training-package-1">
                  <div className="home-grooming-package-3-header">
                    <div className="unfaded-title">HAIRCUT & STYLING</div>
                    <div className="home-package-subtitle">
                      Best for all Pets
                    </div>
                  </div>

                  <div className="mini-package-cards-content-container">
                    <div className="mini-package-cards-content-left">
                      <div className="mini-package-cards-content-left-upper">
                        <div>
                          <i>Full Haircut, Shaving , Hairstyling</i>
                        </div>
                        <div>
                          <i>Sanitary trim...& more</i>
                        </div>
                      </div>
                      <div className="mini-package-cards-content-left-lower">
                        <Link to="/grooming" state={{ from: "home" }}>
                          <LazyLoad>
                            <img
                              alt="arrowIcon"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/grooming-purple-arrow.png"
                              className="mini-card-arrow-icon"
                            />
                          </LazyLoad>
                        </Link>
                      </div>
                    </div>

                    <div className="mini-package-cards-content-right">
                      <LazyLoad>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/grooming-purple.png"
                          alt="package icon"
                          className="packageicon-grooming"
                        ></img>
                      </LazyLoad>
                    </div>
                  </div>
                </div>

                <div className="slide home-training-package-1">
                  <div className="home-grooming-package-4-header">
                    <div className="unfaded-title">BATH & FULL HAIRCUT</div>
                    <div className="home-package-subtitle">Most Popular</div>
                  </div>

                  <div className="mini-package-cards-content-container">
                    <div className="mini-package-cards-content-left">
                      <div className="mini-package-cards-content-left-upper">
                        <div>
                          <i>Anal Gland Cleaning, Scissoring Feet</i>
                        </div>
                        <div>
                          <i> Shaving Pads...& more</i>
                        </div>
                      </div>
                      <div className="mini-package-cards-content-left-lower">
                        <Link to="/grooming" state={{ from: "home" }}>
                          <LazyLoad>
                            <img
                              alt="arrowIcon"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/grooming-green-arrow.png"
                              className="mini-card-arrow-icon"
                            />
                          </LazyLoad>
                        </Link>
                      </div>
                    </div>

                    <div className="mini-package-cards-content-right">
                      <LazyLoad>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/grooming-green-icon.png"
                          alt="package icon"
                          className="packageicon-grooming-4"
                        ></img>
                      </LazyLoad>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className={classes.horizontalScroll}>
                <div className="slide home-training-desktop-package-1">
                  <div className="home-grooming-package-1-header">
                    <div className="unfaded-desktop-title">BATH & BRUSH</div>
                    <div className="home-package-desktop-subtitle">
                      Best for Puppies
                    </div>
                  </div>

                  <div className="mini-package-cards-content-container">
                    <div className="mini-package-cards-content-left">
                      <div className="mini-package-cards-desktop-content-left-upper">
                        <div>
                          <i>Includes Bath, Shampoo, Blow Dry</i>
                        </div>
                        <div>
                          <i>Brushing, Sanitary Trim...& more</i>
                        </div>
                      </div>
                      <div className="mini-package-cards-content-left-lower">
                        <Link to="/grooming" state={{ from: "home" }}>
                          <LazyLoad>
                            <img
                              alt="arrowIcon"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/grooming-blue-arrow.png"
                              className="mini-card-arrow-icon-desktop"
                            />
                          </LazyLoad>
                        </Link>
                      </div>
                    </div>

                    <div className="mini-package-cards-content-right">
                      <LazyLoad>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/grooming-purple-icon.png"
                          alt="package icon"
                          className="packageicon-grooming-desktop"
                        ></img>
                      </LazyLoad>
                    </div>
                  </div>
                </div>

                <div className="slide home-training-desktop-package-1">
                  <div className="home-grooming-package-2-header">
                    <div className="unfaded-desktop-title">BATH & BRUSH</div>
                    <div className="home-package-desktop-subtitle">
                      <i>(With Tick Treatement)</i>
                    </div>
                  </div>

                  <div className="mini-package-cards-content-container">
                    <div className="mini-package-cards-content-left">
                      <div className="mini-package-cards-desktop-content-left-upper">
                        <div>
                          <i>Includes Bath, Shampoo, Blow Dry</i>
                        </div>
                        <div>
                          <i>Brushing, Sanitary Trim...& more</i>
                        </div>
                      </div>
                      <div className="mini-package-cards-content-left-lower">
                        <Link to="/grooming" state={{ from: "home" }}>
                          <LazyLoad>
                            <img
                              alt="arrowIcon"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/grooming-pink-arrow.png"
                              className="mini-card-arrow-icon-desktop"
                            />
                          </LazyLoad>
                        </Link>
                      </div>
                    </div>

                    <div className="mini-package-cards-content-right">
                      <LazyLoad>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/grooming-pink-icon.png"
                          alt="package icon"
                          className="packageicon-grooming-desktop"
                        ></img>
                      </LazyLoad>
                    </div>
                  </div>
                </div>

                <div className="slide home-training-desktop-package-1">
                  <div className="home-grooming-package-3-header">
                    <div className="unfaded-desktop-title">
                      HAIRCUT & STYLING
                    </div>
                    <div className="home-package-desktop-subtitle">
                      Best for all Pets
                    </div>
                  </div>

                  <div className="mini-package-cards-content-container">
                    <div className="mini-package-cards-content-left">
                      <div className="mini-package-cards-desktop-content-left-upper">
                        <div>
                          <i>Full Haircut, Shaving , Hairstyling</i>
                        </div>
                        <div>
                          <i>Sanitary trim...& more</i>
                        </div>
                      </div>
                      <div className="mini-package-cards-content-left-lower">
                        <Link to="/grooming" state={{ from: "home" }}>
                          <LazyLoad>
                            <img
                              alt="arrowIcon"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/grooming-purple-arrow.png"
                              className="mini-card-arrow-icon-desktop"
                            />
                          </LazyLoad>
                        </Link>
                      </div>
                    </div>

                    <div className="mini-package-cards-content-right">
                      <LazyLoad>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/grooming-purple.png"
                          alt="package icon"
                          className="packageicon-grooming-desktop"
                        ></img>
                      </LazyLoad>
                    </div>
                  </div>
                </div>

                <div className="slide home-training-desktop-package-1">
                  <div className="home-grooming-package-4-header">
                    <div className="unfaded-desktop-title">
                      BATH & FULL HAIRCUT
                    </div>
                    <div className="home-package-desktop-subtitle">
                      Most Popular
                    </div>
                  </div>

                  <div className="mini-package-cards-content-container">
                    <div className="mini-package-cards-content-left">
                      <div className="mini-package-cards-desktop-content-left-upper">
                        <div>
                          <i>Anal Gland Cleaning, Scissoring Feet</i>
                        </div>
                        <div>
                          <i> Shaving Pads...& more</i>
                        </div>
                      </div>
                      <div className="mini-package-cards-content-left-lower">
                        <Link to="/grooming" state={{ from: "home" }}>
                          <LazyLoad>
                            <img
                              alt="arrowIcon"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/grooming-green-arrow.png"
                              className="mini-card-arrow-icon-desktop"
                            />
                          </LazyLoad>
                        </Link>
                      </div>
                    </div>

                    <div className="mini-package-cards-content-right">
                      <LazyLoad>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/grooming-green-icon.png"
                          alt="package icon"
                          className="packageicon-grooming-4-desktop"
                        ></img>
                      </LazyLoad>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {!changeView ? (
        <div className="bookingbutton">
          <Link to="/popup" state={{ type: "grooming" }}>
            <button className="booksession-mobile-view">Book Now</button>
          </Link>
          <div className="limitedslots">Hurry Up, Limited slots left!</div>
        </div>
      ) : null}

      <div
        className={
          !changeView ? "container pink-container-family" : "container-desktop"
        }
      >
        {!changeView ? (
          <div>
            <div className="textbox2">Words From Pet Parents</div>
            <hr className="vector2"></hr>
          </div>
        ) : null}

        <div class="review-container">
          <div class={!changeView ? "review-box" : "review-box-desktop"}>
            {!changeView ? null : (
              <div className="home-page-customers-head">
                <div className="home-page-customer-heading2">
                  <div>
                    <h3 className="fifty-font-size">Our Customers</h3>
                  </div>
                  <div className="line-div relative-line">
                    <hr className="live-training-pink-line"></hr>
                  </div>
                </div>

                <div className="home-page-customers-contents">
                  <h4 className="bold-none">
                    <div>Our customers trust us for having</div>
                    <div>
                      <span className="pink">Joyful</span> and{" "}
                      <span className="pink">Positive</span> Reinforcements
                    </div>
                    <div>only, "Your Pet is Our Family!"</div>
                  </h4>
                </div>
              </div>
            )}

            <div
              id="testimonialContainer"
              className={!changeView ? "review-box" : "review-box-desktop-3"}
            >
              <div
                className={
                  !changeView
                    ? "testimonial-card-mastercontainer-mobile-view"
                    : "testimonial-card-mastercontainer-2"
                }
              >
                <div className="testimonial-card-upper-container">
                  <div className="testimonial-card-quote-image">
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/quote-icon.png"
                      alt="quoteIcon"
                      className="quote-icon"
                    ></img>
                  </div>
                  <div className="testimonial-card-review-content">
                    {testmonialData.message}
                  </div>
                  <hr className="grey-line"></hr>
                </div>
                <div className="testimonial-card-lower-container">
                  <div className="testimonial-card-reviewer-image">
                    <img
                      alt="rudrangImage"
                      className="testimonial-user-image"
                      src={testmonialData.img_src}
                    />
                  </div>
                  <div className="testimonial-card-reviewer-name">
                    {testmonialData.name}
                  </div>
                  <div className="testimonial-card-reviewer-profession">
                    {testmonialData.profession}
                  </div>
                  <div className="testimonial-card-review-star-box">
                    <div>
                      <img
                        alt="starIcon"
                        className="star-icon-desktop-image"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                      />
                    </div>
                    <div>
                      <img
                        alt="starIcon"
                        className="star-icon-desktop-image"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                      />
                    </div>
                    <div>
                      <img
                        alt="starIcon"
                        className="star-icon-desktop-image"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                      />
                    </div>
                    <div>
                      <img
                        alt="starIcon"
                        className="star-icon-desktop-image"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                      />
                    </div>
                    <div>
                      <img
                        alt="starIcon"
                        className="star-icon-desktop-image"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={!changeView ? "sliderServiceContainer" : ""}
        style={{ marginTop: "" }}
      >
        {!changeView ? (
          <div>
            <div className="textbox2">Guide To Pet Parenting</div>
            <hr className="vector2"></hr>
          </div>
        ) : null}

        {!changeView ? (
          <ServiceBenefits view={"mobile"} />
        ) : (
          <div className="dog-container-desktop">
            <div className="dog-slide-desktop-grooming">
              <div className="grooming-page-behave-box1">
                <div>
                  <h2 className="fifty-font-size">Guide To Pet Parenting</h2>
                </div>
                <div className="line-div relative-line">
                  <hr className="live-training-pink-line"></hr>
                </div>
              </div>

              <div className="grooming-page-behave-box2">
                <h4 className="bold-none">
                  <div>A Professionally Trained Dog is </div>
                  <div>well behaved and Happy.</div>
                  <div>Read our Dog Training blogs for more.</div>
                </h4>
              </div>
              <div className="training-page-behave-box3">
                We Can <span className="pink">Help!</span>
              </div>
            </div>
            <div className="dog-box-desktop-training">
              <div className="educational-content-card-training">
                <div className="educational-content-card-training-upper">
                  <img
                    className="educational-content-card-training-image"
                    alt="eduCard"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/road-trip-with-dog.png"
                  />
                  <div className="educational-content-card-training-text">
                    Blog
                  </div>
                  <div className="educational-content-card-training-head">
                    Road Tripping With Your Dog
                  </div>
                </div>
                <div className="educational-content-card-training-lower">
                  <div className="">
                    <Link
                      className="educational-content-card-training-link"
                      to="/blog/road-tripping-with-your-dog"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>

              <div className="educational-content-card-training">
                <div className="educational-content-card-training-upper">
                  <img
                    className="educational-content-card-training-image"
                    alt="eduCard"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/positive-pet-rewards.png"
                  />
                  <div className="educational-content-card-training-text">
                    Blog
                  </div>
                  <div className="educational-content-card-training-head">
                    Positive Pet Rewards
                  </div>
                </div>
                <div className="educational-content-card-training-lower">
                  <div className="">
                    <Link
                      className="educational-content-card-training-link"
                      to="/blog/positive-pet-rewards"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>

              <div className="educational-content-card-training">
                <div className="educational-content-card-training-upper">
                  <img
                    className="educational-content-card-training-image"
                    alt="eduCard"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pet-separation.png"
                  />
                  <div className="educational-content-card-training-text">
                    Blog
                  </div>
                  <div className="educational-content-card-training-head">
                    Pet Separation Anxiety
                  </div>
                </div>
                <div className="educational-content-card-training-lower">
                  <div className="">
                    <Link
                      className="educational-content-card-training-link"
                      to="/blog/pet-separation-anxiety"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>

              <div className="educational-content-card-training">
                <div className="educational-content-card-training-upper">
                  <img
                    className="educational-content-card-training-image"
                    alt="eduCard"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/guide-to-pet-foods.png"
                  />
                  <div className="educational-content-card-training-text">
                    Blog
                  </div>
                  <div className="educational-content-card-training-head">
                    Guide to Pet Foods
                  </div>
                </div>
                <div className="educational-content-card-training-lower">
                  <div className="">
                    <Link
                      className="educational-content-card-training-link"
                      to="/blog/guide-to-pet-foods"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>

              <div className="educational-content-card-training">
                <div className="educational-content-card-training-upper">
                  <img
                    className="educational-content-card-training-image"
                    alt="eduCard"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/puppy-and-teething.png"
                  />
                  <div className="educational-content-card-training-text">
                    Blog
                  </div>
                  <div className="educational-content-card-training-head">
                    Puppy and Teething
                  </div>
                </div>
                <div className="educational-content-card-training-lower">
                  <div className="">
                    <Link
                      className="educational-content-card-training-link"
                      to="/blog/puppy-and-teething"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {!changeView ? (
        <FAQContainer view={"mobile"} />
      ) : (
        <FAQContainer view={"desktop"} />
      )}

      {!changeView ? <Footer view={"mobile"} /> : <Footer view={"desktop"} />}

      {!changeView ? null : (
        <div>
        <div className="desktop-footer-buttons-container-add" style={{display:"flex", flexDirection:"column"}}>
              <p style={{fontWeight:"800"}}>MANGOPAW TECHNOLOGIES AND SOLUTIONS PRIVATE LIMITED</p>
              <p style={{marginTop:"0rem"}}> Ph no- +918889991800</p>
            </div>
        <div className="desktop-footer-buttons-container">
          <div className="desktop-footer-buttons-left">
            <div className="desktop-footer-buttons-left-btn1">
              <a
                href="https://play.google.com/store/apps/details?id=in.tamely.user"
                target="_blank" rel="nofollow"
              >
                <img
                  alt="icon"
                  className="desktop-footer-buttons-left-btn1-img"
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/play-store-download.png"
                />
              </a>
            </div>
            <div className="desktop-footer-buttons-left-btn1">
              <a
                href="https://apps.apple.com/in/app/petmojo/id1609861328"
                target="_blank" rel="nofollow"
              >
                <img
                  alt="icon"
                  className="desktop-footer-buttons-left-btn1-img"
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/app-store-download.png"
                />
              </a>
            </div>
            <div
              onClick={scrollToTop}
              className="desktop-footer-buttons-left-btn3"
            >
              <div>Go at the top</div>

              <svg
                className="top-arrow"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-arrow-up"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                />
              </svg>
            </div>
          </div>

          <div className="desktop-footer-buttons-right">
            Copyright 2024 Petmojo
          </div>
        </div>
        </div>
      )}
    </div>
  );
}

export default LandingPageComponent;
