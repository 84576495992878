import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ReactStars from 'react-stars'
import deskBg from '../../images/reviewDesktopBg.png';
import mobileBg from '../../images/reviewMobileBg.png';
import NavigationDesktop from "../../components-desktop/Navigation/NavigationDesktop";
import NavigationBar from "../../components/Navigation/navigationBar/NavigationBar";

const baseUrl = "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api"

const inputClass = 'text-white bg-transparent py-2 px-5 border border-1 border-white focus:border-2 rounded-lg focus:outline-none';

const CreateReview = () => {

    const [changeView, setChangeView] = useState(false);
    const [rating, setRating] = useState(0);
    const [review, setReview] = useState('');
    const [name, setName] = useState('');
    const [location, setLoaction] = useState('');

    const userAuthToken = localStorage.getItem("token");

    const navigate = useNavigate();

    useEffect(() => {
        if (!userAuthToken) {
            navigate("/verify",{ state: { from: '/add-reviews' } })
        }
    }, []);

    useEffect(() => {
        if (window.innerWidth <= 1000) {
            setChangeView(false);
        } else {
            setChangeView(true);
        }
    }, []);


    const submitReview = async () => {
        try {
            const res = await axios.post(
                `${baseUrl}/website/postAReview`,
                {
                    username: name,
                    rating: rating,
                    review: review,
                    type: 'User',
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': userAuthToken,
                    }
                }
            );
            navigate("/reviews")
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className="w-screen h-screen relative overflow-y-auto" style={{ backgroundImage: `url(${changeView ? deskBg : mobileBg})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
            {changeView ? (
                <NavigationDesktop
                    submissionType={"none"}
                    submissionText={"Download Our App"} />
            ) : (
                <NavigationBar btn={"training"} homePage={true} page={"white"} />
            )}
            <div className="mx-auto max-w-7xl my-5">
                <div className='w-full mt-10 px-10 py-5 justify-center flex flex-col gap-5 sm:gap-6'>
                    <div className="flex flex-col sm:flex-row gap-5 sm:gap-10">
                        <input className={inputClass} type='text' placeholder='Full Name' value={name} onChange={(e) => setName(e.target.value)} />
                        <input className={inputClass} type='text' placeholder='Location' value={location} onChange={(e) => setLoaction(e.target.value)} />
                    </div>
                    <div className="flex flex-row gap-5 items-center">
                        <div className='text-2xl text-white font-bold hidden md:block'>Ratings</div>
                        <ReactStars
                            count={5}
                            value={rating}
                            onChange={(rating) => setRating(rating)}
                            size={40}
                            color1={'#D3D3D3'}
                            color2={'#ffd700'}
                            edit={true}
                            half={true} />
                    </div>
                    <div>
                        <textarea rows={8} className='w-full text-white bg-transparent text-lg py-2 px-4 rounded-lg border border-1 border-white focus:border-2 focus:outline-none' placeholder='Type here .....' value={review} onChange={(e) => setReview(e.target.value)} />
                    </div>
                    <div className='w-full flex justify-center md:justify-end'>
                        <button className='py-2 px-20 bg-brand text-white font-bold rounded-lg' onClick={() => submitReview()}>Submit</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateReview