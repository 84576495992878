import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useRef } from "react";
import "./Popup.css";
import LazyLoad from "react-lazyload";
import GoToTop from "./GoToTop";
import LoadingSpinner from "./loadingSpinner/LoadingSpinner";

function PopupBoarding() {
  const [selectedFirst, setSelectedFirst] = useState(true);
  const [selectedSecond, setSelectedSecond] = useState(false);
  const [selectedThird, setSelectedThird] = useState(false);
  const [selectedNew, setSelectedNew] = useState(false);
  const [timeSlot, setTimeSlot] = useState("Time Slot");
  const [showTimeSlots, setShowTimeSlots] = useState(false);
  const navigate = useNavigate(); // navigation hook initialization --------------
  const [loading, setLoading] = useState(false);
  const [groomingLoading, setGroomingLoading] = useState(false);
  const [changeView, setChangeView] = useState(false);

  const phoneRef = useRef("");
  const emailRef = useRef("");
  const nameRef = useRef("");
  const dateRef = useRef();
  const addressRef = useRef("");

  const [phNo, setPhNo] = useState("");

  const [inputText, setInputText] = useState("");
  const [submit, setSubmit] = useState(false);

  const [inputPhone, setInputPhone] = useState("");
  const [inputName, setInputName] = useState("");
  const [inputEmail, setInputEmail] = useState("");
  const [inputDate, setInputDate] = useState("");
  const [inputAddress, setInputAddress] = useState("");

  const [validPhone, setValidPhone] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [validName, setValidName] = useState(true);
  const [validDate, setValidDate] = useState(true);
  const [validTime, setValidTime] = useState(true);
  const [validAddress, setValidAddress] = useState(true);

  useEffect(() => {
    if (window.innerWidth <= 1000) {
      setChangeView(false);
    } else {
      setChangeView(true);
    }
  }, []);

  const [valid, setValid] = useState(true); // setting up the phone number validation

  const [selectedService, setSelectedService] = useState("HAIRCUT & STYLING");
  const [serviceDropdown, setServiceDropdown] = useState(false);
  const [paymentData, setPaymentData] = useState({});
  const [flag, setFlag] = useState(false);

  function setSelectedServiceHandler(service) {
    setSelectedService(service);
    setServiceDropdown(false);
  }

  const submitData = async (path) => {
    const phNoString = phNo.toString();

    if (phNoString.length !== 10) {
      setValid(false);
      return 0;
    } else {
      setLoading(true);

      const elapsed = Date.now();
      const date = new Date(elapsed);
      const dateOfReg = `${date.getDate()}-${
        date.getMonth() + 1
      }-${date.getFullYear()}`;
      const timeOfReg = `${date.toLocaleTimeString("en-US")}`;

      const data = {
        ContactNo: phNo,
        DateOfRegistration: dateOfReg,
        TimeOfRegistration: timeOfReg,
        FormType: "CTA Page Form",
      };
      let link = "";

      if (path === "dog-training") {
        link = "https://api.apispreadsheets.com/data/kR8JfKXyaxqMQyqt/";
        console.log("you are on dog-training page");
      } else if (path === "dog-running") {
        link = "https://api.apispreadsheets.com/data/QcQTxBvwdD8rfShs/";
        console.log("you are on dog-running page");
      }

      await axios.post(link, data).then((response) => {
        console.log(response);
        setPhNo("");
      });

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: `${path} Registration Fired`,
      });

      setLoading(false);
      navigate("/thankPage");
    }
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  useEffect(() => {
    if (flag) navigate("/ordersummary", { state: paymentData });
  }, [paymentData, flag]);

  const submitGroomingData = async () => {
    console.log("Function is called!");
    console.log(inputEmail);
    console.log(inputText);

    if (
      inputPhone.toString().length === 10 &&
      inputEmail !== ""
    ) {
      setGroomingLoading(true);


      const data = {
      "Date":"dfsfd",
      "Name":"",
      "Time":"",
      "Email":inputEmail,
      "Address":"",
      "Services":"",
      "ContactNo":inputPhone,
      "DateOfRegistration":""
      }
      

      // const data = {
      //   ContactNo: inputPhone,
      //   Services: selectedService,
      //   Name: "inputName",
      //   Date: "inputDate",
      //   Time: "timeSlot",
      //   Address: "inputAddress",
      //   DateOfRegistration: "dateOfReg",
      //   Email: inputEmail,
      //   FormType: "CTA Page Form"
      // };

      var amount = 0;

      if (selectedService === "HAIRCUT & STYLING") {
        amount = 1249;
      } else if (selectedService === "BATH & FULL HAIRCUT") {
        amount = 1799;
      }

      await axios
        .post("https://api.apispreadsheets.com/data/aYIX7MPoj3uPID6m/", data,{
          headers: {
            accessKey:"edcef7d1e385edf1edcb137652bf120d", secretKey:"ad85cb82a0edb6b4856e577ae48863fd"
          }
        })
        .then((response) => {
          console.log(response);
        })
        .catch((err)=>{
          console.log(err);
        })

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "Grooming Registration Fired",
      });

      setGroomingLoading(false);

      navigate("/thankPage");

      //   displayRazorpay(amount, inputPhone, selectedService, dateOfReg, inputAddress);

      //navigate("/thankPage");
    } else {
      if (inputPhone.toString().length !== 10) {
        setValidPhone(false);
      }
      if (inputEmail === "") {
        setValidEmail(false);
      }

      return;
    }
  };

  const location = useLocation();
  const { type } = location.state;
  console.log(type);

  return (
    <div>
      {!changeView ? (
        <div>
          {type === "dog-training" || type === "dog-running" ? (
            <div>
              {submit ? null : (
                <div className="popup" >
                  <div className="popup-header-container">
                    <div className="popup-header">
                      <span className="header-pink">FREE</span>{" "}
                      {type === "dog-training" ? "Training" : "Walking"} Session
                    </div>
                    <div className="disclaimer">
                      <div className="disclaimer-heading">
                        Your Data Privacy Matters
                      </div>
                      <div className="disclaimer-text">
                        We only use your contact number to reach you regarding
                        your service request.
                      </div>
                    </div>
                  </div>
                  <div className="popup-input-container">
                    <form id="contactForm">
                      <div className="phone-number-text">Phone Number one</div>
                      {valid ? null : (
                        <div className="validationText">
                          Please enter a valid phone number!
                        </div>
                      )}
                      <input
                        type="number"
                        name="phoneNoTraining/Walking"
                        className={
                          valid
                            ? "popup-phone-input"
                            : "popup-phone-input-invalid"
                        }
                        onChange={(e) => {
                          setValid(true);
                          setPhNo(e.target.value);
                        }}
                        placeholder="Enter Phone Number (10 digits)"
                        maxLength="10"
                        minLength="10"
                      ></input>
                    </form>
                    {loading ? (
                      <div className="spinnerContainer">
                        <LoadingSpinner />
                      </div>
                    ) : (
                      <div>
                        <div className="bookingbutton popup-cta">
                          <button
                            className="booksession"
                            onClick={() => submitData(type)}
                          >
                            Book a FREE Session
                          </button>
                        </div>
                        <div className="limited-container">
                          <div className="limited-message">
                            Limited Slots Left. Book Now!
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="more-footer-container partner-footer">
                      <LazyLoad>
                        <img
                          className="petmojo-logo"
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/petmojo-logo-white.png"
                        ></img>
                      </LazyLoad>

                      <div className="footer">
                        <div>Copyright 2024 Petmojo</div>
                        <div>
                          All copyrights are reserved - any infringement will be
                          legally pursued
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="desktop-view">
              {submit ? null : (
                <div className="popup">
                  <div className="popup-header-container">
                    {type === "boarding" && (
                      <div className="popup-header-grooming">
                        <span className="header-pink">BOOK</span> Boarding
                        Session
                      </div>
                    )}

                    {type === "styling" && (
                      <div className="popup-header-grooming">
                        <span className="header-pink">BOOK</span> Styling
                        Session
                      </div>
                    )}

                    <div className="disclaimer">
                      <div className="disclaimer-heading">
                        Your Data Privacy Matters
                      </div>
                      <div className="disclaimer-text">
                        We only use your contact number to reach you regarding
                        your service request.
                      </div>
                    </div>
                  </div>
                  {/* //////////////////////////////////////////////// */}

                  <form id="groomingFormData">
                    <div className="popup-input-container">
                      <div className="options-title">
                        Select service of your choice
                      </div>
                      <div className="options-container-grooming">
                        {type==="boarding"&&
                        <>
                        <div className="option-box-grooming">
                          <div className="grooming-option">
                            {selectedFirst ? (
                              <LazyLoad>
                                <img
                                  src={
                                    "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/radio-selected_thqfzr.png"
                                  }
                                  className="radio-icon"
                                ></img>
                              </LazyLoad>
                            ) : (
                              <LazyLoad>
                                <img
                                  src={
                                    "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/radio-unselected_zoagnz.png"
                                  }
                                  className="radio-icon"
                                  onClick={() => {
                                    setSelectedFirst(true);
                                    setSelectedSecond(false);
                                    setSelectedThird(false);
                                    setSelectedNew(false);
                                  }}
                                ></img>
                              </LazyLoad>
                            )}
                            <div className="option-name">BATH & BRUSH</div>
                          </div>
                          <div className="option-price">₹799</div>
                        </div>

                        <div className="option-box-grooming">
                          <div className="grooming-option">
                            {selectedNew ? (
                              <LazyLoad>
                                <img
                                  src={
                                    "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/radio-selected_thqfzr.png"
                                  }
                                  className="radio-icon"
                                ></img>
                              </LazyLoad>
                            ) : (
                              <LazyLoad>
                                <img
                                  src={
                                    "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/radio-unselected_zoagnz.png"
                                  }
                                  className="radio-icon"
                                  onClick={() => {
                                    setSelectedFirst(false);
                                    setSelectedSecond(false);
                                    setSelectedThird(false);
                                    setSelectedNew(true);
                                  }}
                                ></img>
                              </LazyLoad>
                            )}
                            <div className="option-name">
                              BATH & BRUSH{" "}
                              <span>
                                <i>(With Tick Treatment)</i>
                              </span>
                            </div>
                          </div>
                          <div className="option-price">₹1199</div>
                        </div>

                        <div className="option-box-grooming">
                          <div className="grooming-option">
                            {selectedSecond ? (
                              <LazyLoad>
                                <img
                                  src={
                                    "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/radio-selected_thqfzr.png"
                                  }
                                  className="radio-icon"
                                ></img>
                              </LazyLoad>
                            ) : (
                              <LazyLoad>
                                <img
                                  src={
                                    "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/radio-unselected_zoagnz.png"
                                  }
                                  className="radio-icon"
                                  onClick={() => {
                                    setSelectedFirst(false);
                                    setSelectedSecond(true);
                                    setSelectedThird(false);
                                    setSelectedNew(false);
                                  }}
                                ></img>
                              </LazyLoad>
                            )}
                            <div className="option-name">HAIRCUT & STYLING</div>
                          </div>
                          <div className="option-price">₹1249</div>
                        </div>

                        <div className="option-box-grooming">
                          <div className="grooming-option">
                            {selectedThird ? (
                              <LazyLoad>
                                <img
                                  src={
                                    "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/radio-selected_thqfzr.png"
                                  }
                                  className="radio-icon"
                                ></img>
                              </LazyLoad>
                            ) : (
                              <LazyLoad>
                                <img
                                  src={
                                    "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/radio-unselected_zoagnz.png"
                                  }
                                  className="radio-icon"
                                  onClick={() => {
                                    setSelectedFirst(false);
                                    setSelectedSecond(false);
                                    setSelectedThird(true);
                                    setSelectedNew(false);
                                  }}
                                ></img>
                              </LazyLoad>
                            )}
                            <div className="option-name">
                              BATH & FULL HAIRCUT
                            </div>
                          </div>
                          <div className="option-price">₹1799</div>
                        </div>
                        </>}


                        
                      </div>



                      <div className="name-input-container">
                        <div className="phone-number-text-grooming">
                          Phone Number
                          {validPhone ? null : (
                            <div className="validationText-grooming-phone">
                              Please enter a valid phone number!
                            </div>
                          )}
                        </div>
                        <input
                          type="number"
                          className={
                            validPhone
                              ? "popup-phone-input"
                              : "popup-phone-input-invalid"
                          }
                          ref={phoneRef}
                          onChange={(e) => {
                            setInputText(e.target.value);
                            setInputPhone(e.target.value);
                            setValidPhone(true);
                          }}
                          placeholder="Enter Your Number"
                        ></input>
                      </div>
{type==="boarding"&&
                      <div className="name-input-container">
                        <div className="phone-number-text-grooming">
                          Name
                          {validName ? null : (
                            <div className="validationText-grooming-name">
                              Required!
                            </div>
                          )}
                        </div>
                        <input
                          type="text"
                          className={
                            validName
                              ? "popup-phone-input"
                              : "popup-phone-input-invalid"
                          }
                          ref={nameRef}
                          onChange={(e) => {
                            setInputText(e.target.value);
                            setInputName(e.target.value);
                            setValidName(true);
                          }}
                          placeholder="Enter Your Name"
                        ></input>
                      </div>
}
                      <div className="name-input-container">
                        <div className="phone-number-text-grooming">
                          Email
                          {validEmail ? null : (
                            <div className="validationText-grooming-address">
                              Required!
                            </div>
                          )}
                        </div>
                        <input
                          type="text"
                          ref={emailRef}
                          className={
                            validEmail
                              ? "popup-phone-input"
                              : "popup-phone-input-invalid"
                          }
                          onChange={(e) => {
                            setInputText(e.target.value);
                            setInputEmail(e.target.value);
                          }}
                          placeholder="Enter your email"
                        ></input>
                      </div>

{type==="boarding"&&
                      <div className="date-time-container">
                        <div className="name-input-container-1">
                          <div className="phone-number-text-grooming-1">
                            Date
                            {validDate ? null : (
                              <div className="validationText-grooming-date">
                                Required!
                              </div>
                            )}
                          </div>
                          <input
                            type="date"
                            className={
                              validDate
                                ? "popup-phone-input date-time-input"
                                : "popup-phone-input-invalid date-time-input"
                            }
                            ref={dateRef}
                            style={{ color: "#5E5E5E" }}
                            onChange={(e) => {
                              setInputText(e.target.value);
                              setInputDate(e.target.value);
                              setValidDate(true);
                            }}
                            placeholder="DD/MM/YYYY"
                          ></input>
                        </div>

                        {/* <div className="name-input-container">
                    <div className="phone-number-text-grooming">Time</div>
                    <div
                      style={{color: "#5E5E5E"}}
                      onClick={() => {
                        setShowTimeSlots(!showTimeSlots);
                        setValidTime(true);
                      }}
                      className={validTime ? "popup-phone-input date-time-input time-input" : "popup-phone-input-invalid date-time-input time-input"}
                    >
                      <div className={(timeSlot === "Time Slot") ? "timeSlotText" : ""}>{timeSlot}</div>
                      <div className="timeSlotIcon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock-fill" viewBox="0 0 16 16">
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
                        </svg>
                      </div>
                    </div>
                  </div> */}
                        {/* {
                    validTime ? null : (
                      <div className="validationText-grooming-time">
                        Required!
                      </div>
                    )
                  } */}
                      </div>
}
                      {/* {
                    !showTimeSlots ? null : (
                      <div className="timeSlot-container">
                        <div className="timeSlot-upper">
                          <div onClick={() => {
                            setTimeSlot("09 - 11 AM")
                            setShowTimeSlots(false);
                          }} className="timeSlot">09 - 11 AM</div>
                          <div onClick={() => {
                            setTimeSlot("11 - 01 PM")
                            setShowTimeSlots(false);
                          }} className="timeSlot">11 - 01 PM</div>
                          <div onClick={() => {
                            setTimeSlot("01 - 03 PM")
                            setShowTimeSlots(false);
                          }} className="timeSlot">01 - 03 PM</div>
                        </div>
                        <div className="timeSlot-lower">
                          <div onClick={() => {
                            setTimeSlot("03 - 05 PM")
                            setShowTimeSlots(false);
                          }} className="timeSlot">03 - 05 PM</div>
                          <div onClick={() => {
                            setTimeSlot("05 - 07 PM")
                            setShowTimeSlots(false);
                          }} className="timeSlot">05 - 07 PM</div>
                        </div>
                      </div>
                    )
                  } */}
{type==="boarding"&&
                      <div className="name-input-container">
                        <div className="phone-number-text-grooming">
                          Address
                          {validAddress ? null : (
                            <div className="validationText-grooming-address">
                              Required!
                            </div>
                          )}
                        </div>
                        <input
                          type="text"
                          ref={addressRef}
                          className={
                            validAddress
                              ? "popup-phone-input"
                              : "popup-phone-input-invalid"
                          }
                          onChange={(e) => {
                            setInputText(e.target.value);
                            setInputAddress(e.target.value);
                          }}
                          placeholder="Enter Your Home Address"
                        ></input>
                      </div>
}
                    </div>
                        
                  </form>

                  {groomingLoading ? (
                    <div className="spinnerContainer">
                      <LoadingSpinner />
                    </div>
                  ) : (
                    <div>
                      <div className="bookingbutton popup-cta">
                        <button
                          className="booksession-grooming-form"
                          style={{marginTop:"0px",marginBottom:"-40px"}}
                          onClick={() => submitGroomingData()}
                        >
                          Book Now
                        </button>
                      </div>
                    </div>
                  )}

                  <br />
                  <br />

                  <div className="more-footer-container partner-footer">
                    <LazyLoad>
                      <img
                        className="petmojo-logo"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_6_7_v4zlsi.png"
                      ></img>
                    </LazyLoad>

                    <div className="footer">
                      <div>Copyright 2024 Petmojo</div>
                      <div>
                        All copyrights are reserved - any infringement will be
                        legally pursued
                      </div>
                    </div>
                  </div>
                  <div>{""} </div>
                  <div>{""} </div>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div>
          {type === "dog-training" || type === "dog-running" ? (
            <div>
              {submit ? null : (
                <div className="popup">
                  <div className="popup-desktop-training-container">
                    <div className="popup-training-walking-left">
                      <div className="popup-header-container">
                        <div className="popup-header">
                          <span className="header-pink">FREE</span>{" "}
                          {type === "dog-training" ? "Training" : "Walking"}{" "}
                          Session
                        </div>
                        <div className="disclaimer">
                          <div className="disclaimer-heading">
                            Your Data Privacy Matters
                          </div>
                          <div className="disclaimer-text">
                            We only use your contact number to reach you
                            regarding your service request.
                          </div>
                        </div>
                      </div>

                      <div className="popup-input-container-desktop">
                        <form id="contactForm">
                          <div className="phone-number-text">Phone Number</div>
                          {valid ? null : (
                            <div className="validationText">
                              Please enter a valid phone number!
                            </div>
                          )}
                          <input
                            type="number"
                            name="phoneNoTraining/Walking"
                            className={
                              valid
                                ? "popup-phone-input"
                                : "popup-phone-input-invalid"
                            }
                            onChange={(e) => {
                              setValid(true);
                              setPhNo(e.target.value);
                            }}
                            placeholder="Enter Phone Number (10 digits)"
                            maxLength="10"
                            minLength="10"
                          ></input>
                        </form>
                        {loading ? (
                          <div className="spinnerContainer">
                            <LoadingSpinner />
                          </div>
                        ) : (
                          <div>
                            <div className="bookingbutton popup-cta">
                              <button
                                className="booksession"
                                onClick={() => submitData(type)}
                              >
                                Book a FREE Session
                              </button>
                            </div>
                            <div className="limited-container">
                              <div className="limited-message">
                                Limited Slots Left. Book Now!
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="popup-desktop-image-train">
                      <img
                        alt="popupImage"
                        className="popup-image-desktop-train"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/2V3A7175-removebg-preview_1_k0jy5a.png"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div>
              {submit ? null : (
                <div className="popup">
                  <div className="popup-grooming-desktop-master-container">
                    <div className="popup-grooming-desktop-left">
                      <div className="popup-header-container">
                        {type === "boarding" && (
                          <div className="popup-header-grooming-desktop">
                            <span className="header-pink">BOOK</span> Boarding
                            Session
                          </div>
                        )}
                         {type === "styling" && (
                          <div className="popup-header-grooming-desktop">
                            <span className="header-pink">BOOK</span> Styling
                            Session
                          </div>
                        )}
                        <div className="disclaimer">
                          <div className="disclaimer-heading">
                            Your Data Privacy Matters
                          </div>
                          <div className="disclaimer-text">
                            We only use your contact number to reach you
                            regarding your service request.
                          </div>
                        </div>
                      </div>

                      <div
                        onClick={() => setServiceDropdown(!serviceDropdown)}
                        className="grooming-popup-page-desktop-view-service-form"
                      >
                    {type==="boarding"&&
                    <>
                    
                    <div>Select service of your choice</div>
                        <div className="groming-page-desktop-view-services-container">
                          <div className="grooming-page-mobile-view-selected-service">
                            {selectedService}
                          </div>
                          <div className="home-page-form-mobile-view-dropdown-arrow">
                            <svg
                              className="grooming-page-form-mobile-view-dropdown-arrow-img"
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              fill="currentColor"
                              class="bi bi-caret-down-fill"
                              viewBox="0 0 16 16"
                              >
                              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                          </div>
                        </div>
                        {serviceDropdown ? (
                          <div className="grooming-popup-page-mobile-view-service-drop-container">
                            <div
                              onClick={() =>
                                setSelectedServiceHandler("BATH & BRUSH")
                              }
                              className="grooming-page-desktop-view-drop-item"
                            >
                              <div className="grooming-page-mobile-view-drop-item-text">
                                BATH & BRUSH
                              </div>
                              <div className="grooming-page-mobile-view-drop-item-price">
                                ₹799
                              </div>
                            </div>
                            <div
                              onClick={() =>
                                setSelectedServiceHandler(
                                  "BATH & BRUSH (With Tick Treatment)"
                                )
                              }
                              className="grooming-page-desktop-view-drop-item"
                            >
                              <div className="grooming-page-mobile-view-drop-item-text">
                                BATH & BRUSH
                                <span>
                                  <i>(With Tick Treatment)</i>&nbsp;&nbsp;
                                </span>
                              </div>
                              <div className="grooming-page-mobile-view-drop-item-price">
                                ₹1199
                              </div>
                            </div>
                            <div
                              onClick={() =>
                                setSelectedServiceHandler("HAIRCUT & STYLING")
                              }
                              className="grooming-page-desktop-view-drop-item"
                            >
                              <div className="grooming-page-mobile-view-drop-item-text">
                                HAIRCUT & STYLING
                              </div>
                              <div className="grooming-page-mobile-view-drop-item-price">
                                ₹1249
                              </div>
                            </div>
                            <div
                              onClick={() =>
                                setSelectedServiceHandler("BATH & FULL HAIRCUT")
                              }
                              className="grooming-page-desktop-view-drop-item"
                            >
                              <div className="grooming-page-mobile-view-drop-item-text">
                                BATH & FULL HAIRCUT
                              </div>
                              <div className="grooming-page-mobile-view-drop-item-price">
                                ₹1799
                              </div>
                            </div>
                          </div>
                        ) : null}
                        </>}
                     

                        </div>

                        {type==="boarding"&&
                      <div className="date-time-container">
                        <div className="name-input-container-1">
                          <div className="phone-number-text-grooming-1">
                            Date
                            {validDate ? null : (
                              <div className="validationText-grooming-date">
                                Required!
                              </div>
                            )}
                          </div>
                          <input
                            type="date"
                            className={
                              validDate
                                ? "popup-phone-input date-time-input"
                                : "popup-phone-input-invalid date-time-input"
                            }
                            ref={dateRef}
                            style={{ color: "#5E5E5E" }}
                            onChange={(e) => {
                              setInputText(e.target.value);
                              setInputDate(e.target.value);
                              setValidDate(true);
                            }}
                            placeholder="DD/MM/YYYY"
                          ></input>
                        </div>
                            </div>
}


{type==="boarding"&&
                      <div className="name-input-container">
                        <div className="phone-number-text-grooming">
                          Name
                          {validName ? null : (
                            <div className="validationText-grooming-name">
                              Required!
                            </div>
                          )}
                        </div>
                        <input 
                        style={{width:'100%',height:'48px',borderRadius:'12px'}}
                          type="text"
                          className={
                            validName
                              ? "popup-phone-input"
                              : "popup-phone-input-invalid"
                          }
                          ref={nameRef}
                          onChange={(e) => {
                            setInputText(e.target.value);
                            setInputName(e.target.value);
                            setValidName(true);
                          }}
                          placeholder="Enter Your Name"
                        ></input>
                      </div>
}



                    </div>
                    <div className="popup-grooming-desktop-right">


{type==="boarding"&&
                      <div className="name-input-container">
                        <div className="phone-number-text-grooming">
                          Address
                          {validAddress ? null : (
                            <div className="validationText-grooming-address">
                              Required!
                            </div>
                          )}
                        </div>
                        <input
                          type="text"
                          ref={addressRef} 
                          style={{width:'100%',height:'48px',borderRadius:'12px'}}
                          className={
                            validAddress
                              ? "popup-phone-input"
                              : "popup-phone-input-invalid"
                          }
                          onChange={(e) => {
                            setInputText(e.target.value);
                            setInputAddress(e.target.value);
                          }}
                          placeholder="Enter Your Home Address"
                        ></input>
                      </div>
}


                      
                      <div className="popup-grooming-desktop-phone">
                        <div className="phone-number-desktop-bundle">
                          <div className="phone-number-text-grooming">
                            Email
                          </div>
                          {validEmail ? null : (
                            <div className="red-feedback-text-desktop">
                              Please enter your email address!
                            </div>
                          )}
                        </div>

                        <input
                          // kartik
                          type="email"
                          className={
                            validEmail
                              ? "popup-phone-input-desktop"
                              : "popup-phone-input-desktop-invalid"
                          }
                          ref={emailRef}
                          onChange={(e) => {
                            setInputText(e.target.value);
                            setInputEmail(e.target.value);
                            setValidEmail(true);
                          }}
                          placeholder="Enter your email address"
                        ></input>
                      </div>
                      <div className="popup-grooming-desktop-phone">
                        <div className="phone-number-desktop-bundle">
                          <div className="phone-number-text-grooming">
                            Phone Number
                          </div>
                          {validPhone ? null : (
                            <div className="red-feedback-text-desktop">
                              Please enter a valid phone number!
                            </div>
                          )}
                        </div>

                        <input
                          type="number"
                          className={
                            validPhone
                              ? "popup-phone-input-desktop"
                              : "popup-phone-input-desktop-invalid"
                          }
                          ref={phoneRef}
                          onChange={(e) => {
                            setInputText(e.target.value);
                            setInputPhone(e.target.value);
                            setValidPhone(true);
                          }}
                          placeholder="Enter your number"
                        ></input>
                      </div>
 

                    
                      {groomingLoading ? (
                        <div className="spinnerContainer">
                          <LoadingSpinner />
                        </div>
                      ) : (
                        <div>
                          <div className="bookingbutton popup-cta">
                            <button
                              className="booksession-grooming-form"
                              onClick={() => submitGroomingData()}
                            >
                              Book Now
                            </button>
                          </div>
                          <div className="limited-slots-left-desktop-grooming-popup">
                            Limited Slot Left Book Now!
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <br />
                  <br />
                  <div>{""} </div>
                  <div>{""} </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}

      <GoToTop />
    </div>
  );
}

export default PopupBoarding;
